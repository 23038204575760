import { statisticsAPI } from "../api/api";

const SET_MODULE_SALES_STATISTICS = "SET_MODULE_SALES_STATISTICS";
const SET_STATISTICS_BRANCH = "SET_STATISTICS_BRANCH";
const SET_STATISTICS_BRANCH_SUM = "SET_STATISTICS_BRANCH_SUM";
const SET_PAID_CHECKS_YEAR = "SET_PAID_CHECKS_YEAR";
const SET_AVERAGE_COLL_MODULS_IN_CHECK = "SET_AVERAGE_COLL_MODULS_IN_CHECK";
const SET_AVERAGE_COLL_POSITION_MODULS_IN_CHECK = "SET_AVERAGE_COLL_POSITION_MODULS_IN_CHECK";
const SET_AVERAGE_CHECK = "SET_AVERAGE_CHECK";
const SET_NEW_CUSTOMER = "SET_NEW_CUSTOMER";
const SET_CUSTOMER_FOR_YEAR = "SET_CUSTOMER_FOR_YEAR";
const SET_MODULES_FOR_YEAR = "SET_MODULES_FOR_YEAR";
const SET_ACTIVE_SALES_WITH_COMPANY = "SET_ACTIVE_SALES_WITH_COMPANY";


let initialState = {
    moduleSalesStatistics: [],
    statisticsBranch: [],
    statisticsBranchSum: 0,
    paidChecksYear: [],
    averageCollModulsInCheck: [],
    averageCollPositionModulsInCheck: [],
    averageCheck: [],
    newCustomer: [],
    customerForYear: [],
    activeSalesWithCompany: [],
    activeSalesWithPaidCompany: [],
}

const statisticsReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_MODULE_SALES_STATISTICS:
            return {
                ...state,
                moduleSalesStatistics: action.moduleSalesStatistics
            }
        case SET_STATISTICS_BRANCH:
            return {
                ...state,
                statisticsBranch: action.statisticsBranch
            }
        case SET_STATISTICS_BRANCH_SUM:
            return {
                ...state,
                statisticsBranchSum: action.statisticsBranchSum
            }
        case SET_PAID_CHECKS_YEAR:
            return {
                ...state,
                paidChecksYear: action.paidChecksYear
            }
        case SET_AVERAGE_COLL_MODULS_IN_CHECK:
            return {
                ...state,
                averageCollModulsInCheck: action.averageCollModulsInCheck
            }
        case SET_AVERAGE_COLL_POSITION_MODULS_IN_CHECK:
            return {
                ...state,
                averageCollPositionModulsInCheck: action.averageCollPositionModulsInCheck
            }
        case SET_AVERAGE_CHECK:
            return {
                ...state,
                averageCheck: action.averageCheck
            }
        case SET_NEW_CUSTOMER:
            return {
                ...state,
                newCustomer: action.data
            }
        case SET_CUSTOMER_FOR_YEAR:
            return {
                ...state,
                customerForYear: action.data
            }
        case SET_MODULES_FOR_YEAR:
            return {
                ...state,
                modulesForYear: action.data
            }
        case SET_ACTIVE_SALES_WITH_COMPANY:
            return {
                ...state,
                activeSalesWithCompany: action.data[0],
                activeSalesWithPaidCompany: action.data[1],
            }
        default:
            return state
    }
}

export const setModuleSalesStatistics= (moduleSalesStatistics) => ({type: SET_MODULE_SALES_STATISTICS, moduleSalesStatistics});
export const setStatisticsBranch= (statisticsBranch) => ({type: SET_STATISTICS_BRANCH, statisticsBranch});
export const setStatisticsBranchSum= (statisticsBranchSum) => ({type: SET_STATISTICS_BRANCH_SUM, statisticsBranchSum});
export const setPaidChecksYear= (paidChecksYear) => ({type: SET_PAID_CHECKS_YEAR, paidChecksYear});
export const setAverageCollModulsInCheck= (averageCollModulsInCheck) => ({type: SET_AVERAGE_COLL_MODULS_IN_CHECK, averageCollModulsInCheck});
export const setAverageCollPositionModulsInCheck= (averageCollPositionModulsInCheck) => ({type: SET_AVERAGE_COLL_POSITION_MODULS_IN_CHECK, averageCollPositionModulsInCheck});
export const setAverageCheck= (averageCheck) => ({type: SET_AVERAGE_CHECK, averageCheck});
export const setNewCustomer= (data) => ({type: SET_NEW_CUSTOMER, data});
export const setCustomerForYear= (data) => ({type: SET_CUSTOMER_FOR_YEAR, data});
export const setModulesForYear= (data) => ({type: SET_MODULES_FOR_YEAR, data});
export const setActiveSalesWithCompany= (data) => ({type: SET_ACTIVE_SALES_WITH_COMPANY, data});


export const getModuleSalesStatistics = (year = "0") =>{
    return (dispatch) => {
        statisticsAPI.getModuleSalesStatistics(year)
        .then(data => {
            dispatch(setModuleSalesStatistics(data));
        });
    }
}
export const getPaidChecksYear = () =>{
    return (dispatch) => {
        statisticsAPI.getPaidChecksYear()
        .then(data => {
            dispatch(setPaidChecksYear(data));
        });
    }
}
export const getStatisticsBranch = (year = "0") =>{
    return (dispatch) => {
        statisticsAPI.getStatisticsBranch(year)
        .then(data => {
            dispatch(setStatisticsBranch(data));
        });
    }
}
export const getStatisticsBranchSum = (year = "0") =>{
    return (dispatch) => {
        statisticsAPI.getStatisticsBranchSum(year)
        .then(data => {
            dispatch(setStatisticsBranchSum(data[0]["sum"]));
        });
    }
}
export const getAverageCollModulsInCheck = () =>{
    return (dispatch) => {
        statisticsAPI.getAverageCollModulsInCheck()
        .then(data => {
            dispatch(setAverageCollModulsInCheck(data));
        });
    }
}
export const getAverageCollPositionModulsInCheck = () =>{
    return (dispatch) => {
        statisticsAPI.getAverageCollPositionModulsInCheck()
        .then(data => {
            dispatch(setAverageCollPositionModulsInCheck(data));
        });
    }
}
export const getAverageCheck = () =>{
    return (dispatch) => {
        statisticsAPI.getAverageCheck()
        .then(data => {
            dispatch(setAverageCheck(data));
        });
    }
}
export const getNewCustomer = () =>{
    return (dispatch) => {
        statisticsAPI.getNewCustomer()
        .then(data => {
            dispatch(setNewCustomer(data));
        });
    }
}
export const getCustomerForYear = () =>{
    return (dispatch) => {
        statisticsAPI.getCustomerForYear()
        .then(data => {
            dispatch(setCustomerForYear(data));
        });
    }
}
export const getActiveSalesWithCompany = () =>{
    return (dispatch) => {
        statisticsAPI.getActiveSalesWithCompany()
        .then(data => {
            dispatch(setActiveSalesWithCompany(data));
        });
    }
}
export const getModulesForYear = () =>{
    return (dispatch) => {
        statisticsAPI.setModulesForYear()
        .then(data => {
            dispatch(setModulesForYear(data));
        });
    }
}


export default statisticsReducer;