import React from 'react';
import s from './DescriptionPersonalModule.module.css';


const DescriptionPersonalModule = (props) =>{
    console.log(props.nomenclaturesData)
    return(
        <div className={s.main}>
           <div className={s.name}>{props.nomenclaturesData && <p>{props.nomenclaturesData.NAME}-{props.productId} {props.nomenclaturesData.price!==null && "("+props.nomenclaturesData.price+" долл)"}</p>}</div>
            <div className={s.description}>
                {props.nomenclaturesData && <p>{props.nomenclaturesData.DESCRIPTION_UA+" "+props.nomenclaturesData.desc}{props.nomenclaturesData.ADD_OPTIONS!=="" && " Опції: "+props.nomenclaturesData.ADD_OPTIONS}</p>}
            </div>
        </div>
    )
}
export default DescriptionPersonalModule;