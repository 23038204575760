import React, { useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import s from './Specifications.module.css';

const Specifications = (props) => {
    const navigate = useNavigate();
    const { productId } = useParams();

    console.log(props.nomenclaturesData);
    let [mainColl, setMainColl] = useState(1);

    const setMainCollF = (e) => {
        e.target.value >= 0 ? setMainColl(e.target.value) : setMainColl(1);
    }

    const allComponentsFromStorage = props.allComponentsFromStorage;

    const getCollWithStorage = (id) => {
        return allComponentsFromStorage.map(p => {
            if (p.id === id) {
                return p.coll;
            }
            return null;
        });
    }

    const comparisonColl = (id, coll) => {
        return allComponentsFromStorage.map(p => {
            if (p.id === id) {
                if (Number(p.coll) >= Number(coll)) {
                    return "Да";
                } else {
                    return "Нужно купить";
                }
            }
            return null;
        });
    }

    let [searchNomenclatures, setSearchNomenclatures] = useState(productId);

    const setSearchNomenclaturesF = (e) => {
        setSearchNomenclatures(e.target.value);
    }

    const goToNewNomenclatures = () => {
        navigate(`/specifications/${searchNomenclatures}`);
    }

    const sumTotal = (arr) => arr.reduce((sum, { unit_price }) => Number(sum) + Number(unit_price), 0);
    const total = sumTotal(props.specificationList);

    let handleKeyPress = (e) => {
        if (e.charCode === 13) {
            goToNewNomenclatures();
        }
    }

    return (
        <>
            <div className={s.main + " " + s.mainThreeHeader}>
                <div><NavLink to="/add_specifications">Добавить новую</NavLink></div>
                <div className={s.name}>{props.nomenclaturesData && <p>{props.nomenclaturesData.NAME}-{props.productId}</p>}</div>
                <div className={s.description}>
                    {props.nomenclaturesData && <p>{props.nomenclaturesData.DESCRIPTION_UA + " " + props.nomenclaturesData.desc}</p>}
                </div>
                <div className={s.inputColl}>
                    <h3>Необходимое кол.</h3>
                    <input value={mainColl} type="number" onChange={setMainCollF} />
                </div>
                <div className={s.inputColl}>
                    <h3>Поиск по номенклатурам</h3>
                    <input type="number" value={searchNomenclatures} onChange={setSearchNomenclaturesF} onKeyPress={handleKeyPress} />
                    <button onClick={goToNewNomenclatures}>Search</button>
                </div>
                <div>#</div>
                <div>Компонент</div>
                <div>Необходимое кол.</div>
                <div>На складе</div>
                <div>Хватает?</div>
                <div>Активация</div>
            </div>
            {
                props.specificationList.map((p, index) => {
                    return (
                        <div className={s.main} key={"specificationList_" + index}>
                            <div>{index + 1}</div>
                            <div><NavLink to={"../suppliers/" + p.storage_component_id}>{p.storage_component_name}</NavLink></div>
                            <div>{p.coll * mainColl}</div>
                            <div>{getCollWithStorage(p.storage_component_id)}</div>
                            <div>{comparisonColl(p.storage_component_id, p.coll * mainColl)}</div>
                            <div><input type="checkbox" /></div>
                        </div>
                    )
                })
            }
        </>
    );
}

export default Specifications;
