import React, { useState } from 'react';
import s from './CheckTest.module.css';

const ChangeDate = (props) =>{
    const change = ()=>{
        if(newDate!==""){
            props.changeDateReminder(props.showChangeDateReminder, newDate)
            props.setShowChangeDateReminder(false)
        } 
    }
    let [newDate, setNewDate] = useState("")
    const setNewDateF = (e)=>{
        setNewDate(e.target.value)
    }
    return(
        <div className={s.changeDate}>
           <div><h3>Изменение даты напоминания по заявке №{props.showChangeDateReminder}</h3></div>
           <div><input type="date" onChange={setNewDateF} /></div>
           <div className={s.center}><button onClick={()=>change()}>Изменить</button></div>
        </div>
    )
}
export default ChangeDate;