import React from 'react';
import s from './PersonalModuleCategoryCompany.module.css';
import {NavLink} from "react-router-dom";


const PersonalModuleCategoryCompany = (props) =>{
    console.log(props.personalModuleCategoryCompany)
    return(
        <div>
            {props.personalModuleCategoryCompany.map((k,index)=>{
                return(
                    <div className={s.main} key={"personalModuleCategoryCompany_"+index}> 
                        <div><NavLink to={"/company/profile/"+k.id} target="_blank">{k.name_with_akon}</NavLink></div>
                        <div>{k.sum}</div>
                    </div>
                )
            })}
        </div>
    )
}
export default PersonalModuleCategoryCompany;