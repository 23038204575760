import React from 'react';

const AnyFormatDate = (props) => {
    let splitFullDate = (date) => {
        if (date.includes(" ")) {
            date = date.split(" ");
            let time = date[1] === "00:00:00" ? "" : date[1];
            date = time + " " + date[0].split("-").reverse().join("-");
        }
        return date
    }
    let turnDate = (date) => {
        if (date) {
            if (date.includes(" ")) {
                return splitFullDate(date);
            } else {
                date = date.split("-");
                date = (date[2] + "-" + date[1] + "-" + date[0]);
            }
        } else {
            date = "не указана"
        }
        return date
    }
    return turnDate(props.date)
}
export default AnyFormatDate;
