import React, { useState } from 'react';
import s from '../../../Task/Task.module.css';

const AddWorker = (props) =>{
    let [newWorkers, setNewWorkers] = useState(0)
    const setNewWorkersF = (e)=>{
        setNewWorkers(e.target.value)
    }
    const setNewWorker = ()=>{
        props.setNewWorkerInRepair(props.showAddWorker, newWorkers)
        props.setShowAddWorker(false)
    }
    const workersList = [
        {id: 9, name: "Панченко"},
        {id: 8, name: "Марченко"},
        {id: 10, name: "Квачёв"},
        {id: 11, name: "Поляков"}
    ]
    return(
        <div className={s.taskMain}>
            <h2>Назначить сотрудника</h2>
            <div className={s.twoBlock}>
                <div>Выбрать сотрудника: </div>
                <div>
                    <select onChange={setNewWorkersF}>
                        <option value="0">Не выбран</option>
                        {workersList.map(k=>{
                            return <option value={k.id} key={k.id}>{k.name}</option>
                        })}
                    </select>
                </div>
                <div><button onClick={()=>setNewWorker()}>Назначить сотрудника</button></div>
            </div>
        </div>
    )
}
export default AddWorker;