import { adminAPI } from "../api/api";

const SET_ALL_ACTIVITY = "SET_ALL_ACTIVITY";
const SET_LIST_WITH_COMPANY_UNPIN = "SET_LIST_WITH_COMPANY_UNPIN";


let initialState = {
    allActivity: [],
    listWithCompanyUnpin: [],
}

const salesAdminReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_ALL_ACTIVITY:
            return {
                ...state,
                allActivity: action.data
            }
        case SET_LIST_WITH_COMPANY_UNPIN:
            return {
                ...state,
                listWithCompanyUnpin: action.data
            }
        default:
            return state
    }
}

export const setAllActivity = (data) => ({type: SET_ALL_ACTIVITY, data});
export const setListWithCompanyUnpin = (data) => ({type: SET_LIST_WITH_COMPANY_UNPIN, data});

export const getAllActivityAdmin= (idManager, dateStart="", dateEnd="") =>{
    return (dispatch) => {
        adminAPI.getAllActivityAdmin(idManager, dateStart, dateEnd)
        .then(data => {
            dispatch(setAllActivity(data));
        });
    }
}
export const getListWithCompanyUnpin= () =>{
    return (dispatch) => {
        adminAPI.getListWithCompanyUnpin()
        .then(data => {
            dispatch(setListWithCompanyUnpin(data));
        });
    }
}


export default salesAdminReducer;

