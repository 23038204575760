import React from 'react';
import s from './Distribution.module.css';
import main from '../../Main.module.css';
import { useState } from 'react';

const ChangeDate = (props) =>{
        let [nom, setNom] = useState("")
        const setNomF = (e)=>{
            setNom(e.target.value)
        }
        const change = ()=>{
            if(nom!="" && nom!=0){
                props.changeDateDistribution(nom, props.active, props.personalId)
                props.setActive(false)
            }
        }
        return(
            <div className={s.addDesc}>
                <h2 className={main.center}>Меняем дату рассылки</h2>
                <div className={s.twoBlock}>
                    <div>Новая дата:* </div>
                    <div><input type="date" value={nom} onChange={setNomF} /></div>
                </div>
                <div className={main.center}><button onClick={()=>change()}>Добавить</button></div>
            </div>
        )
}
export default ChangeDate;