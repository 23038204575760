import React, { useState } from 'react';
import {
    Typography,
    Button,
    TextField
} from '@mui/material';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './DynamicGrid.css';
import CardComponent from './CardComponent';
import {toast} from 'react-toastify';
import {dashboardAPI} from "../../api/api";

const ResponsiveGridLayout = WidthProvider(Responsive);

const DynamicGrid = ({ onSave }) => {
    const [cards, setCards] = useState([]);
    const [pageName, setPageName] = useState('');
    const [nextId, setNextId] = useState(0);

    // Function to add a new card
    const addCard = () => {
        const newY = cards.length > 0 ? Math.max(...cards.map(card => card.y + card.h)) : 0; // Position new card below the last one
        const newCard = {
            i: nextId.toString(),
            x: 0,
            y: newY,
            w: 4,
            h: 4,
            title: `График № ${nextId}`,
            children: []
        };
        setCards([...cards, newCard]);
        setNextId(nextId + 1);
    };

    // Function to remove a card by id
    const removeCard = (id) => {
        setCards(cards.filter(card => card.i !== id));
    };

    // Function to update a card's properties
    const updateCard = (id, key, value) => {
        setCards(cards.map(card => card.i === id ? { ...card, [key]: value } : card));
    };

    // Function to update a card's children
    const updateCardChildren = (id, children) => {
        setCards(cards.map(card => card.i === id ? { ...card, children } : card));
    };

    // Function to save the layout
    const saveLayout = async () => {
        try {
            const response = await dashboardAPI.saveNewDashboard({
                name: pageName,
                layout: cards,
            });
            toast.info('Дашборд успешно сохранён');
        } catch (error) {
            toast.error('Error saving dashboard:', error);
        }
    };

    return (
        <div className="container">
            <Typography variant="h4" gutterBottom>Редактор дашбордов</Typography>
            <div className="control-panel">
                <TextField
                    id="standard-basic"
                    label="Название страницы"
                    variant="standard"
                    value={pageName}
                    onChange={(e) => setPageName(e.target.value)}
                    style={{ marginRight: '20px' }}
                />
                <Button variant="contained" color="primary" onClick={addCard}>
                    Добавить карточку
                </Button>
                <Button variant="contained" color="secondary" onClick={saveLayout} style={{ marginLeft: '20px' }}>
                    Сохранить шаблон
                </Button>
            </div>
            <div className="grid-container">
                <ResponsiveGridLayout
                    className="layout"
                    layout={cards}
                    breakpoints={{ lg: 1200 }}
                    cols={{ lg: 12 }}
                    rowHeight={100} // Set a fixed row height
                    onLayoutChange={(newLayout) => {
                        const updatedCards = newLayout.map(layoutItem => {
                            const card = cards.find(c => c.i === layoutItem.i);
                            return { ...card, ...layoutItem };
                        });
                        setCards(updatedCards);
                    }}
                    useCSSTransforms={false}
                    draggableHandle=".dragHandle"
                >
                    {cards.map((card) => (
                        <div key={card.i} data-grid={card}>
                            <CardComponent
                                card={card}
                                removeCard={removeCard}
                                updateCard={updateCard}
                                updateCardChildren={updateCardChildren}
                            />
                        </div>
                    ))}
                </ResponsiveGridLayout>
            </div>
        </div>
    );
};

export default DynamicGrid;
