import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllArticles, getSubentries, addNewCash, getMoneyCash, getCashFlowForMonth, getSumCashFlowForMonth } from '../../redux/cash-reducer';
import Cash from './Cash';
import MonthBlock from '../Production/common/MonthBlock/MonthBlock';

const CashContainer = ({
                           articles,
                           subentries,
                           cash,
                           cashFlowForMonth,
                           sumCashFlowForMonth,
                           getAllArticles,
                           getSubentries,
                           addNewCash,
                           getMoneyCash,
                           getCashFlowForMonth,
                           getSumCashFlowForMonth
                       }) => {
    const { month } = useParams();

    useEffect(() => {
        getAllArticles();
        getMoneyCash();
        getCashFlowForMonth(month);
        getSumCashFlowForMonth(month);
    }, [month, getAllArticles, getMoneyCash, getCashFlowForMonth, getSumCashFlowForMonth]);

    return (
        <div>
            <MonthBlock path={"cash"} month={month} />
            <Cash
                articles={articles}
                getSubentries={getSubentries}
                subentries={subentries}
                addNewCash={addNewCash}
                cash={cash}
                cashFlowForMonth={cashFlowForMonth}
                sumCashFlowForMonth={sumCashFlowForMonth}
                month={month}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    articles: state.cash.articles,
    subentries: state.cash.subentries,
    cash: state.cash.cash,
    cashFlowForMonth: state.cash.cashFlowForMonth,
    sumCashFlowForMonth: state.cash.sumCashFlowForMonth,
});

export default connect(mapStateToProps, {
    getAllArticles,
    getSubentries,
    addNewCash,
    getMoneyCash,
    getCashFlowForMonth,
    getSumCashFlowForMonth
})(CashContainer);
