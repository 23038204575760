import React from 'react';
import s from './Status.module.css'

const CheckNameColl = (props) =>{
    const getModulesType = () =>{

        if(props.moduls_type_in_production === 0){
            return props.number;
        }else if(props.moduls_type_in_production === 1){
            return props.number + " - РФ";
        }else if(props.moduls_type_in_production === 2){
            return "Склад";
        }else if(props.moduls_type_in_production === 3){
            return "На тест";
        }
    }
    //console.log(props.moduls_type_in_production)

    return(
    <>
        <div>{getModulesType()}</div>
        {/* <div>{props.check == 0 ? "ВР" : props.check}</div> */}
        <div>{props.name} {/* props.newDevice=="0" && <span className={s.red}>- новое изделие</span> */}</div>
        <div>{props.coll}</div>
    </>
    )
}
export default CheckNameColl;