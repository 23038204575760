import React from 'react';
import s from './PersonalKp.module.css';

const DeleteKp = (props) =>{
    const change = ()=>{
        props.changeAnything(props.active, props.personalId)
        props.setActive(false)
    }
    return(
        <div>
            <h2>Вы точно хотите скрыть КП?</h2>
            <div className={s.modal}>
                <div><button onClick={()=>change()}>Скрыть</button></div>
            </div>
        </div>
    )
}
export default DeleteKp;