import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Use useParams to access URL parameters
import { connect } from 'react-redux';
import { getModulsWithComponent } from '../../../redux/moduls-reducer';
import ModulesWithComponent from './ModulesWithComponent';

const ModulesWithComponentContainer = ({ getModulsWithComponent, modulsWithComponent, id }) => {
    const { modulsWithComponent: modulsWithComponentId } = useParams(); // Get the modulsWithComponent param from the URL

    useEffect(() => {
        const componentId = modulsWithComponentId || id;
        getModulsWithComponent(componentId);
    }, [modulsWithComponentId, id, getModulsWithComponent]);

    return <ModulesWithComponent modulsWithComponent={modulsWithComponent} />;
};

const mapStateToProps = (state) => ({
    modulsWithComponent: state.moduls.modulsWithComponent,
});

export default connect(mapStateToProps, { getModulsWithComponent })(ModulesWithComponentContainer);
