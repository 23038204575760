import { salesCheckAPI } from "../api/api";

const SET_SALES_CHECK = "SET_SALES_CHECK";
const SET_SALES_CHECK_ARCHIVE = "SET_SALES_CHECK_ARCHIVE";
const SET_SALES_CHECK_COMMENT = "SET_SALES_CHECK_COMMENT";


let initialState = {
    salesCheck: [],
    salesCheckArchive: [],
    salesInvoceComment: [],
}

const salesCheckReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_SALES_CHECK:
            return {
                ...state,
                salesCheck: action.data
            }
        case SET_SALES_CHECK_ARCHIVE:
            return {
                ...state,
                salesCheckArchive: action.data
            }
        case SET_SALES_CHECK_COMMENT:
            return {
                ...state,
                salesInvoceComment: action.data
            }
        default:
            return state
    }
}

export const setSalesCheck = (data) => ({type: SET_SALES_CHECK, data});
export const setSalesCheckArchive = (data) => ({type: SET_SALES_CHECK_ARCHIVE, data});
export const setSalesInvoceComment = (data) => ({type: SET_SALES_CHECK_COMMENT, data});

export const getSalesCheck= () =>{
    return (dispatch) => {
        salesCheckAPI.getSalesCheck()
        .then(data => {
            dispatch(setSalesCheck(data));
        });
    }
}
export const getSalesCheckArchive= () =>{
    return (dispatch) => {
        salesCheckAPI.getSalesCheckArchive()
        .then(data => {
            dispatch(setSalesCheckArchive(data));
        });
    }
}
export const setDateInSalesCheck= (id, who) =>{
    return (dispatch) => {
        salesCheckAPI.setDateInSalesCheck(id, who)
        .then(data => {
            dispatch(getSalesCheck());
        });
    }
}
export const getSalesInvoceComment= () =>{
    return (dispatch) => {
        salesCheckAPI.getSalesInvoceComment()
        .then(data => {
            dispatch(setSalesInvoceComment(data));
        });
    }
}
export const addCommentForSalesCheck= (array) =>{
    return (dispatch) => {
        salesCheckAPI.addCommentForSalesCheck(array)
        .then(data => {
            dispatch(getSalesInvoceComment());
        });
    }
}
export const deleteCommentForSalesCheck= (id) =>{
    return (dispatch) => {
        salesCheckAPI.deleteCommentForSalesCheck(id)
        .then(data => {
            dispatch(getSalesInvoceComment());
        });
    }
}


export default salesCheckReducer;

