import React from 'react';
import { useState } from 'react';
import s from './CheckTest.module.css';
import main from '../../Main.module.css'
import AnyFormatDate from '../../Production/common/AnyFormatDate/AnyFormatDate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt} from '@fortawesome/free-solid-svg-icons'
import ChangeDate from './ChangeDate'
import BuyModuls from './BuyModuls'
import NewNumberReturnTest from './NewNumberReturnTest'
import ReturnModuls from './ReturnModuls'
import CommentModuls from './CommentModuls'
import Modal from '../../Production/common/Modal/Modal'
import {NavLink} from "react-router-dom";


const CheckTest = (props) =>{
    console.log(props.returnFromTest)
    let dateNow = new Date()
    const dateComparison = (date_reminder)=>{
        let date = new Date(date_reminder)
        if(date<dateNow){
            return s.red
        }else{
            return ""
        }
    }
    //изменить дату напоминания
    let [showChangeDateReminder, setShowChangeDateReminder] = useState(false)
    //модуль купили
    let [showBuyModulsTest, setShowBuyModulsTest] = useState(false)
    //модуль вернули
    let [showReturnModulsTest, setShowReturnModulsTest] = useState(false)
    //комментарий
    let [commentModulsTest, setCommentModulsTest] = useState(false)
    let [newNumberReturnFromTest, setNewNumberReturnFromTest] = useState(false)
    let [comment, setcomment] = useState("")
    const setCommentModulsTestF=(id, text)=>{
        setCommentModulsTest(id);
        setcomment(text);
    }
    //ищем совпадения заявок от заказчика и заявок от продажника
    const findCoincidence = (number)=>{
        let index = props.checkTest.find(i=>Number(i.id) == Number(number))
        if(index){
            return <div>Есть совпадение</div>
        }else{
            return <span className={s.red}>нет совпадений</span>
        }
    }
    let [archive, setArchive] = useState(1)
    const getArchive=(type)=>{
        setArchive(type)
        props.getCheckTest(type);
        props.getCheckTestModuls(type);
    }
    return(
        <div>
            {showChangeDateReminder &&
            <Modal active={showChangeDateReminder} setActive={setShowChangeDateReminder}>
                <ChangeDate changeDateReminder={props.changeDateReminder} setShowChangeDateReminder={setShowChangeDateReminder} showChangeDateReminder={showChangeDateReminder}/>
            </Modal>
            }
            {showBuyModulsTest &&
            <Modal active={showBuyModulsTest} setActive={setShowBuyModulsTest}>
                <BuyModuls buyModulsTest={props.buyModulsTest} setShowBuyModulsTest={setShowBuyModulsTest} showBuyModulsTest={showBuyModulsTest}/>
            </Modal>
            }
            {showReturnModulsTest &&
            <Modal active={showReturnModulsTest} setActive={setShowReturnModulsTest}>
                <ReturnModuls returnModulsTest={props.returnModulsTest} setShowReturnModulsTest={setShowReturnModulsTest} showReturnModulsTest={showReturnModulsTest}/>
            </Modal>
            }
            {commentModulsTest &&
            <Modal active={commentModulsTest} setActive={setCommentModulsTest}>
                <CommentModuls comment={comment} changeCommentModulsTest={props.changeCommentModulsTest} setCommentModulsTest={setCommentModulsTest} commentModulsTest={commentModulsTest}/>
            </Modal>
            }
            {newNumberReturnFromTest &&
            <Modal active={newNumberReturnFromTest} setActive={setNewNumberReturnFromTest}>
                <NewNumberReturnTest active={newNumberReturnFromTest} setActive={setNewNumberReturnFromTest} newFunction={props.changeNumberReturnFromTest}/>
            </Modal>
            }
            <h1 className={main.center}>Модули на тестах</h1>
            <div className={s.archive}>{archive==1 ? <span onClick={()=>getArchive(3)}>Архив</span> : <span onClick={()=>getArchive(1)}>Текущий список</span>}</div>
            <h2>Заявки отдела продаж</h2>
            <div className={s.main+" "+s.mainSection}>
                <div>#</div>
                <div>Название компании</div>
                <div>Модули на тестах</div>
                <div>Отгрузка/ТТН</div>
                <div>Дата окончания теста</div>
                <div>Комментарий</div>
                <div>Действия</div>
            </div>
            {props.checkTest.map(k=>{
                return(
                    <div className={s.main} key={k.id}>
                        <div>{k.id}</div>
                        <div>
                            <div><NavLink to={"/company/profile/"+k.company_id}>{k.name_with_akon}</NavLink></div>
                        </div>
                        <div>
                        {props.checkTestModuls.map((j,index)=>{
                            if(j.id_invoice==k.id) 
                                return(
                                    <div className={s.mainTwoBlocks} key={"checkTestModuls_"+index}>
                                        <div>{j.moduls_name}</div>
                                        <div>{j.moduls_coll}</div>
                                    </div>
                                )
                        })
                        }
                        </div>
                        <div>
                            <div><AnyFormatDate date={k.date_shipping} /></div>
                            <div>ТТН: {k.TTN==0 ? "Самовывоз" : k.TTN}</div>
                        </div>
                        <div className={dateComparison(k.date_reminder)}><AnyFormatDate date={k.date_reminder} />{archive!==3 && <span className={s.icon}><FontAwesomeIcon icon={faPencilAlt} onClick={()=>setShowChangeDateReminder(k.id)} /></span>}</div>
                        <div className={s.comment}>
                            <div>{k.comments}</div>
                            <div><span className={s.icon} onClick={()=>setCommentModulsTestF(k.id, k.comments)}><FontAwesomeIcon icon={faPencilAlt} /></span></div>
                        </div>
                        {archive!==3 &&
                        <div className={s.active}>
                        
                            <div onClick={()=>setShowBuyModulsTest(k.id)}>Купили</div>
                            <div onClick={()=>setShowReturnModulsTest(k.id)}>Вернули</div>
                        </div>
                        }
                    </div>
                )
            })  
            }
            <h2>Заявки на возврат</h2>
            <div className={s.mainReturn+" "+s.mainSection}>
                <div>#</div>
                <div>ФИО/Компания</div>
                <div>Телефон</div>
                <div>Кол.</div>
                <div>Номер ТТН</div>
                <div>Комментарий</div>
                <div>Действия</div>
            </div>
            {props.returnFromTest.map((k,index)=>{
                return(
                    <div key={"returnFromTest"+index} className={s.mainReturn}>
                        <div>{k.numberReturnFromTest}<span className={s.icon} onClick={()=>setNewNumberReturnFromTest(k.id)}><FontAwesomeIcon icon={faPencilAlt} /></span></div>
                        <div>{k.fio+"/"+k.companyNameFromRequest}</div>
                        <div>{k.tell}</div>
                        <div>{k.coll}</div>
                        <div>{k.ttn}</div>
                        <div>{k.comment}</div>
                        <div className={s.active}>{findCoincidence(k.numberReturnFromTest)}</div>
                    </div>
                )
            })
                
            }
        </div>
        
    )
}
export default CheckTest;