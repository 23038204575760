import React from 'react';
import { connect } from 'react-redux';
import {getRepair, setNewWorkerInRepair, setCollInRepair, addModuleFromRepair, addCompanyFromRepair, setDateClose, setTTNInRepair} from '../../../redux/repair-reducer';
import {getCompanyList} from '../../../redux/other-reducer';
import RepairMain from './RepairMain';

class RepairContainer extends React.Component{
    componentDidMount(){
        this.props.getRepair();
        this.props.getCompanyList();
    }
    
    render(){
        return (
            <div>
                <RepairMain  repair={this.props.repair} setNewWorkerInRepair={this.props.setNewWorkerInRepair} setCollInRepair={this.props.setCollInRepair} addModuleFromRepair={this.props.addModuleFromRepair} companyList={this.props.companyList} addCompanyFromRepair={this.props.addCompanyFromRepair} setDateClose={this.props.setDateClose} setTTNInRepair={this.props.setTTNInRepair}/>
            </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    repair: state.repair.repair,
    companyList: state.other.companyList,
})
export default connect( mapStateToProps, {getRepair, setNewWorkerInRepair, setCollInRepair, addModuleFromRepair, getCompanyList, addCompanyFromRepair, setDateClose, setTTNInRepair})(RepairContainer);