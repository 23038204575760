import React from 'react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import MonthBlock from '../../../Production/common/MonthBlock/MonthBlock';
import s from '../Costs.module.css';
import sDDC from '../../DDC/DDC.module.css';
import AnyFormatDate from '../../../Production/common/AnyFormatDate/AnyFormatDate';

const CostsList = (props) =>{
    console.log(props.checkCostsList)
    let mainSum = props.checkCostsList.reduce(function(p,c){return Number(p)+Number(c.numeral);},'');
    let sum = props.checkCostsList.map(k=>Number(k.numeral));
    return(
        <div>
            <div><NavLink to={"/admin/ddc/"+props.year+"/"+props.month}>к ДДС</NavLink></div>
            <div className={sDDC.main}>
                <div className={props.year==="2021" ? sDDC.active : ""}><NavLink to={"/admin/costslist/2021/"+props.month}>2021</NavLink></div>
                <div className={props.year==="2022" ? sDDC.active : ""}><NavLink to={"/admin/costslist/2022/"+props.month}>2022</NavLink></div>
                <div className={props.year==="2023" ? sDDC.active : ""}><NavLink to={"/admin/costslist/2023/"+props.month}>2023</NavLink></div>
            </div>
            <MonthBlock path={"admin/costslist/"+props.year} month={props.month}/>
            <div>Общая сумма - {mainSum.toLocaleString()}</div>
            <div className={s.main+" "+s.font}>
                <div>Дата</div>
                <div>Подстатья</div>
                <div>Комментарий</div>
                <div>Сумма</div>
                <div></div>
            </div>
            <div>
                {props.checkCostsList.map((k,index)=>{
                    return(
                        <div key={"checkCostsList"+index} className={s.main}>
                            <div><AnyFormatDate date={k.date} /></div>
                            <div>{k.subentries}</div>
                            <div>{k.name}</div>
                            <div>{k.numeral}</div>
                            <div><input type="checkbox" /></div>
                        </div>
                    )
                })

                }
            </div>
        </div>
    )
}
export default CostsList;
