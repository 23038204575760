import { combineReducers, createStore, applyMiddleware } from "redux";
import productionMainReducer from './productionMain-reducer';
import modulsPaidReducer from './modulsPaid-reducer';
import addStorage from './addStorage-reducer';
import companyReducer from './company-reducer';
import invoiceReducer from './invoice-reducer';
import workerReducer from './worker-reducer';
import suppliersReducer from './suppliers-reducer';
import specificationReducer from './specification-reducer';
import adminReducer from './admin-reducer';
import salesReducer from './sales-reducer';
import modulsReducer from './moduls-reducer';
import statisticsReducer from './statistics-reducer';
import personalareaReducer from './personalArea-reducer';
import cashReducer from './cash-reducer';
import shipmentReducer from './shipment-reducer';
import mainSupplierReducer from './mainSupplier-reducer';
import planReducer from './plan-reducer';
import salesAdminReducer from './salesAdmin-reducer';
import techTaskReducer from './tech-task-reducer';
import thunkMiddleware from 'redux-thunk';
import privateOfficeReducer from './privateOffice-reducer';
import CheckReducer from './check-reducer';
import repairReducer from './repair-reducer';
import otherReducer from './other-reducer';
import futureReducer from './future-reducer';
import salesPersonalReducer from './salesPersonal-reducer';
import salesCheckReducer from './salesCheck-reducer';
import projectReducer from './project-reducer';
import accessoriesReducer from './accessories-reducer';
import {reducer as formReducer} from 'redux-form';

let reducers = combineReducers({
    productionMain: productionMainReducer,
    modulsPaid: modulsPaidReducer,
    addStorage: addStorage,
    company: companyReducer,
    invoice: invoiceReducer,
    worker: workerReducer,
    suppliers: suppliersReducer,
    specification: specificationReducer,
    admin: adminReducer,
    sales: salesReducer,
    mainSupplier: mainSupplierReducer,
    moduls: modulsReducer,
    statistics: statisticsReducer,
    personalarea: personalareaReducer,
    cash: cashReducer,
    shipment: shipmentReducer,
    plan: planReducer,
    salesAdmin: salesAdminReducer,
    techTask: techTaskReducer,
    privateOffice: privateOfficeReducer,
    check: CheckReducer,
    repair: repairReducer,
    other: otherReducer,
    future: futureReducer,
    salesPersonal: salesPersonalReducer,
    salesCheck: salesCheckReducer,
    project: projectReducer,
    accessories: accessoriesReducer,
    form: formReducer
})

let store = createStore(reducers, applyMiddleware(thunkMiddleware));

window.store = store;

export default store;