import React, { useState } from 'react';
import s from '../../../../Task/Task.module.css';

const ChangeMinColl = (props) =>{
    console.log(props.active)
    let [newValue, setNewValue] = useState(0)
    const setNewValueF = (e)=>{
        setNewValue(e.target.value)
    }
    const setNew = ()=>{
        props.changeMinColl(props.active, newValue, props.section)
        props.setActive(false)
    }
    return(
        <div className={s.taskMain}>
            <h2>Изменить мин. количество</h2>
            <div className={s.twoBlock}>
                <div><input type="number" value={newValue} onChange={setNewValueF} className={s.input}/></div>
                <div><button onClick={()=>setNew()}>Изменить</button></div>
            </div>
        </div>
    )
}
export default ChangeMinColl;