import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

const FileUpload = (props) => {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleFileUpload = async (file) => {
        try {
            setIsLoading(true);

            const formData = new FormData();
            formData.append('file', file);

            const result = await props.uploadHandler(formData);
            // Handle the result from the server if needed
            console.log('Запит успішний: ', result);

            // Pass the result to the parent component
            props.handleResponse(result);

            setIsLoading(false);
        } catch (error) {
            setError(error.message || 'Виникла помилка при завантаженні файлу');
            setIsLoading(false);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: async (acceptedFiles) => {
            setUploadedFiles(acceptedFiles);
            setError(null);
            setIsLoading(true);

            try {
                if (props.uploadHandler) {
                    const fileToUpload = acceptedFiles[0]; // Assuming you want to upload only the first file if multiple are selected
                    await handleFileUpload(fileToUpload);
                } else {
                    throw new Error('uploadHandler is not defined');
                }
                setIsLoading(false);
            } catch (error) {
                setError(error.message || 'Ошибка при загрузке файла');
                setIsLoading(false);
            }
        },
    });

    return (
        <div style={{ border: '1px dashed' }}>
            <div {...getRootProps()} style={{ marginLeft: '15px' }}>
                <input {...getInputProps()} />
                {props && props.label && <p>{props.label}</p>}
            </div>

            {isLoading && <p>Загрузка...</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
    );
};

export default FileUpload;
