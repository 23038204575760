import React from 'react';
import {NavLink} from 'react-router-dom';
import AnyFormatDate from '../../../Production/common/AnyFormatDate/AnyFormatDate';
import s from '../SalesPersonal.module.css';


const Check = (props) => {
    let paymentPercent = Math.round(props?.paid/props?.total_money*100 , 2) ?? 0;
    let paymentColor = function () {
        if (paymentPercent == 100) {
            return s.superGreenText
        } else if (paymentPercent >50) {
            return s.orangeText
        } else if (paymentPercent >10) {
            return s.brownText
        } else {
            return s.redText
        }
    }

    return (
        <div className={s.main}>
            <div>Счёт {props.paid != null
                ?
                <>
                    <span className={s.greenText}>- оплачен на </span>
                    <span className={paymentColor()}>{paymentPercent}% </span>
                    (<AnyFormatDate date={props.paymentDate}/>)
                </>
                :
                <span className={s.redText}>- не оплачен</span>}
                {props.ttn !== "" && <div className={s.ttn}>Отгружен (<AnyFormatDate date={props.date_op}/>) - <span
                    className={s.greenText}>{props.ttn}</span></div>}
            </div>
            <div><NavLink to={"/check/" + props.id + "/0"} target="_blank">{props.name}</NavLink></div>
            <div><AnyFormatDate date={props.dateMain}/></div>
        </div>
    )
}
export default Check;