import React, { useState } from 'react';
import s from './s.module.css';
import main from '../../Main.module.css'
import Modal from '../../Production/common/Modal/Modal';
import Form from './Form/Form';
import Change from './Form/Change';
import AnyFormatDate from '../../Production/common/AnyFormatDate/AnyFormatDate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'

const Project = (props) =>{
    
    let [add, showAdd] = useState(false)
    let [remove, showRemove] = useState(false)
    let [change, showChange] = useState(false)
    const showChangeF = (name, desc, id)=>{
        showChange([{name, desc, id}])
    }
    return(
        <div>
            <h1 className={main.center}>Страница проектов</h1>
            <div className={s.add} onClick={()=>showAdd(!add)}><span>Добавить новый проект</span></div>
            <div className={s.m20}>
                <div className={s.main+" "+s.fwb}>
                    <div>#</div>
                    <div>Название</div>
                    <div>Описание</div>
                    <div>Дата создания</div>
                    <div>Действия</div>
                </div>
                {props.allProject.map(k=>{
                    return(
                        <div className={s.main} key={"allProject"+k.id}>
                            <div>{k.id}</div>
                            <div>{k.name}</div>
                            <div>{k.description}</div>
                            <div><AnyFormatDate date={k.date_add}/></div>
                            <div >
                                <FontAwesomeIcon icon={faPencilAlt} onClick={()=>showChangeF(k.name, k.description, k.id)} className={s.change} />
                                <FontAwesomeIcon icon={faTrash} onClick={()=>showRemove(k.id)} className={s.remove} />
                            </div>
                        </div>
                    )
                })}
            </div>
           
            {add &&
                <Modal active={add} setActive={showAdd}>
                    <Form setActive={showAdd} addNewProject={props.addNewProject}/>
                </Modal>
            }
            {remove &&
                <Modal active={remove} setActive={showRemove}>
                    <h1>Удалить проект №{remove} ?</h1>
                    <div className={main.center}><input type="button" value={"Удалить"} onClick={()=>props.removeProject(remove)} className={main.inputAdd} /></div>
                </Modal>
            }
            {change &&
                <Modal active={change} setActive={showChange}>
                    <Change setActive={showChange} change={change} changeProject={props.changeProject}/>
                </Modal>
            }
        </div>
        
    )
}
export default Project;