import React from 'react';
import AnyFormatDate from '../../../Production/common/AnyFormatDate/AnyFormatDate';
import s from '../SalesPersonal.module.css';

const TechConsultation = (props) =>{
    return(
        <div className={s.main}>
            <div>Тех. консультация</div>
            <div>
                <p><a href="https://www.akon.com.ua/admin/techconsultation.php" target="_blank" rel="noopener noreferrer">{"№"+props.id+" "+props.name_with_akon}</a></p>
                <p>{props.name}</p>
            </div>
            <div><AnyFormatDate date={props.dateMain}/></div>
        </div>
    )
}
export default TechConsultation;