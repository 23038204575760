import React from "react";
import { useState } from "react";
import main from '../../../../Main.module.css'

const ChangeAgents = (props)=>{
    let [fio, setFio] = useState(props.companyAgents.find(k=>k.id==props.active).name)
    const setFioF = (e)=>setFio(e.target.value)
    let [email, setEmail] = useState(props.companyAgents.find(k=>k.id==props.active).email)
    const setEmailF = (e)=>setEmail(e.target.value)
    let [tell, setTell] = useState(props.companyAgents.find(k=>k.id==props.active).tell)
    const setTellF = (e)=>setTell(e.target.value)
    let [position, setPosition] = useState(props.companyAgents.find(k=>k.id==props.active).position)
    const setPositionF = (e)=>setPosition(e.target.value)
    const go=()=>{
        let array = []
        array.push(fio)
        array.push(email)
        array.push(tell)
        array.push(position)
        array.push(props.active)
        props.changeCompanyAgent(array, props.companyId)
        props.setActive(false)
    }
    return(
        <div>
            <h2 className={main.center}>Добавить сотрудника</h2>
            <div className={main.twoBlocksGridOneOfTwo}>
                <div className={main.p20+" "+main.bold}>ФИО</div>
                <div><input type="text" value={fio} onChange={setFioF} className={main.input} /></div>
            </div>
            <div className={main.twoBlocksGridOneOfTwo}>
                <div className={main.p20+" "+main.bold}>Email</div>
                <div><input type="text" value={email} onChange={setEmailF} className={main.input}/></div>
            </div>
            <div className={main.twoBlocksGridOneOfTwo}>
                <div className={main.p20+" "+main.bold}>Телефон</div>
                <div><input type="text" value={tell} onChange={setTellF} className={main.input}/></div>
            </div>
            <div className={main.twoBlocksGridOneOfTwo}>
                <div className={main.p20+" "+main.bold}>Должность</div>
                <div><input type="text" value={position} onChange={setPositionF} className={main.input}/></div>
            </div>
            <div className={main.center}><input type="button" value="Записать" className={main.inputAdd} onClick={()=>go()}/></div>
            
            
        </div>
    )
} 
export default ChangeAgents;