import React, {useState} from 'react';
import s from './../AllCompanyWithManager/AllCompanyWithManager.module.css';
import main from '../../Main.module.css';
import Cookies from 'js-cookie'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {faSortUp, faSortDown} from '@fortawesome/free-solid-svg-icons';
import MenuManager from '../common/MenuManager/MenuManager';
import ManagerName from "../../global/ManagerName";
import {NavLink} from "react-router-dom";

const AllCompanyOutdatedWithManager = (props) => {
    const [sortConfig, setSortConfig] = useState({key: null, direction: 'asc'});
    const [managerNameFilters, setManagerNameFilters] = useState({});
    const [dateFilters, setDateFilters] = useState({});

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({key, direction});
    };

    const handleManagerNameFilter = (columnKey, event) => {
        setManagerNameFilters({
            ...managerNameFilters,
            [columnKey]: event.target.value
        });
    };


    const handleDateFilter = (columnKey, event) => {
        setDateFilters({
            ...dateFilters,
            [columnKey]: event.target.value
        });
    };

    const handleCancelFilters = () => {
        setManagerNameFilters({});
        setDateFilters({});
    };

    const filterByDate = (date, columnKey) => {
        if (!dateFilters[columnKey]) {
            return true;
        }

        const currentDate = new Date();
        const companyDate = new Date(date);

        switch (dateFilters[columnKey]) {
            case 'olderThan12Months':
                return currentDate - companyDate > 365 * 24 * 60 * 60 * 1000;
            case 'olderThan9Months':
                return currentDate - companyDate > 9 * 30 * 24 * 60 * 60 * 1000;
            case 'olderThan6Months':
                return currentDate - companyDate > 6 * 30 * 24 * 60 * 60 * 1000;
            case 'olderThan3Months':
                return currentDate - companyDate > 3 * 30 * 24 * 60 * 60 * 1000;
            case 'olderThan1Month':
                return currentDate - companyDate > 1 * 30 * 24 * 60 * 60 * 1000;
            default:
                return true;
        }
    };

    const renderDateFilter = (columnKey) => (
        <div  style={{border: "none"}}>
            <select value={dateFilters[columnKey] || ''} onChange={(e) => handleDateFilter(columnKey, e)}>
                <option value="">сбросить</option>
                <option value="olderThan12Months">12м+</option>
                <option value="olderThan9Months">9м+</option>
                <option value="olderThan6Months">6м+</option>
                <option value="olderThan3Months">3м+</option>
                <option value="olderThan1Month">1м+</option>
            </select>
        </div>
    );
    const distinctManagerValues = Array.from(
        new Set([
            ...props.allCompanyOutdatedWithManager.map(company => company.whose_company),
            ...props.allCompanyOutdatedWithManager.map(company => company.whose_company2),
        ])
    ).filter(Boolean);

    const managerOptions = [
        { value: '', label: 'Сбросить' },
        ...distinctManagerValues.map(managerValue => ({
            value: managerValue,
            label: <ManagerName managerID={managerValue} />,
        })),
    ];

    const renderManagerFilter = (columnKey) => (
        <div>
            <label>Фильтр по менеджеру:</label>
            <select value={managerNameFilters[columnKey] || ''} onChange={(e) => handleManagerNameFilter(columnKey, e)}>
                <option value="">Все</option>
                {managerOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );

    const tableHeaders = [
        {key: 'id', label: '#'},
        {key: 'name', label: 'Название'},
        {key: 'whose_company', label: 'Менеджер 1'},
        {key: 'whose_company2', label: 'Менеджер 2'},
        {key: 'last_message_date', label: 'Последний контакт', filter: renderDateFilter},
        {key: 'last_schet', label: 'Последний счёт', filter: renderDateFilter},
        {key: 'last_kp', label: 'Последнее КП', filter: renderDateFilter},
    ];
    const filteredCompanies = props.allCompanyOutdatedWithManager
        .filter(company =>
            (company.name.toLowerCase().includes(managerNameFilters['name'] || '') ||
                company.whose_company.includes(managerNameFilters['whose_company']) ||
                company.whose_company2.includes(managerNameFilters['whose_company2'])) &&
            filterByDate(company.last_message_date, 'last_message_date') &&
            filterByDate(company.last_schet, 'last_schet') &&
            filterByDate(company.last_kp, 'last_kp')
        )
        .sort((a, b) => {
            const aValue = a[sortConfig.key];
            const bValue = b[sortConfig.key];

            if (aValue === null && bValue === null) {
                return a.id - b.id;
            }

            if (aValue === null) {
                return 1;
            }

            if (bValue === null) {
                return -1;
            }

            if (sortConfig.direction === 'asc') {
                return aValue > bValue ? 1 : -1;
            } else {
                return aValue < bValue ? 1 : -1;
            }
        });


    return (
        <div>
            {
                (Cookies.get('id') == "44" || Cookies.get('id') == "34")
                &&
                <MenuManager link={"/sales/allCompanyOutdated"}
                             idNow={props.managerID}/>
            }
            <h1>Список компаний без длительного контакта
                {managerNameFilters.length > 0 || dateFilters.length > 0 ?
                    <button onClick={handleCancelFilters}>
                        <FontAwesomeIcon icon={faTimes}/> Сбросить фильтр
                    </button>
                    : ""
                }</h1>

            <div className={s.main + " " + main.bold} style={{ gridTemplateColumns: "75px 1fr 1fr 1fr 1fr 1fr 1fr" }}>
                {tableHeaders.map(header => (
                    <div key={header.key} >

                        <div onClick={() => handleSort(header.key)} style={{border: "none"}}>
                            {sortConfig.key === header.key && sortConfig.direction === 'asc' &&
                                <FontAwesomeIcon icon={faSortUp}/>}
                            {sortConfig.key === header.key && sortConfig.direction === 'desc' &&
                                <FontAwesomeIcon icon={faSortDown}/>}
                            {header.label}
                        </div>
                        {header.filter && header.filter(header.key)}
                    </div>
                ))}
            </div>

            {filteredCompanies.map((k, index) => {
                return (
                    <div key={"allCompanyWithManager" + index} className={s.main} style={{ gridTemplateColumns: "75px 1fr 1fr 1fr 1fr 1fr 1fr" }}>
                        <div>{index + 1}</div>
                        <div>
                            <NavLink to={`/company/profile/${k.id}`} target="_blank">{k.name}</NavLink>
                        </div>
                        <div><ManagerName managerID={k.whose_company}/></div>
                        <div><ManagerName managerID={k.whose_company2}/></div>
                        <div>{k.last_message_date}</div>
                        <div>{k.last_schet}</div>
                        <div>{k.last_kp}</div>
                    </div>
                );
            })}

        </div>

    )
}
export default AllCompanyOutdatedWithManager;
