import React from 'react';
import { connect } from 'react-redux';
import {getPersonalModuleCategoryCompany} from '../../../redux/moduls-reducer';
import PersonalModuleCategoryCompany from './PersonalModuleCategoryCompany';

class PersonalModuleCategoryCompanyContainer extends React.Component{
    componentDidMount(){
        this.props.getPersonalModuleCategoryCompany(this.props.id, this.props.category);
    }
    componentDidUpdate(prevProps){
        if(prevProps.id !== this.props.id || prevProps.category !== this.props.category){
            this.props.getPersonalModuleCategoryCompany(this.props.id, this.props.category);
        }
    }
    render(){
        return <PersonalModuleCategoryCompany personalModuleCategoryCompany={this.props.personalModuleCategoryCompany}/>
    } 
}

let mapStateToProps = (state) =>({
    personalModuleCategoryCompany: state.moduls.personalModuleCategoryCompany,
})
export default connect( mapStateToProps, {getPersonalModuleCategoryCompany})(PersonalModuleCategoryCompanyContainer);