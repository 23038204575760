import React, { useState } from 'react';
import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { toast } from "react-toastify";
import { storageAPI } from "../../../api/api";

const StorageComponentChangeName = ({ p, updateComponentName }) => {
    const [open, setOpen] = useState(false);
    const [newName, setNewName] = useState(p.name);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = async () => {
        try {
            await storageAPI.changeStorageComponentName({ id: p.id, name: newName })
                .then((response) => {
                    if (response?.status === 1) {
                        toast.success('Название успешно изменено');
                        updateComponentName(p.id, newName); // Update the name in the parent component
                        handleClose();
                    }
                })
                .catch((error) => {
                    console.error('Failed to update name:', error);
                    toast.error('Ошибка при изменении названия');
                });
        } catch (error) {
            console.error('Failed to update name:', error);
        }
    };

    return (
        <>
            <IconButton onClick={handleClickOpen}>
                <EditIcon />
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Редактирование названия</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Новое название"
                        type="text"
                        fullWidth
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSave} color="primary">
                        Сохранить
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default StorageComponentChangeName;
