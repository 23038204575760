import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getAllMainSupplier, setNewMainSupplier, changeMainSupplier } from '../../../redux/mainSupplier-reducer';
import MainSupplier from './MainSupplier';

class MainSupplierContainer extends React.Component {
    componentDidMount() {
        this.props.getAllMainSupplier();
    }

    render() {
        return (
            <div>
                <MainSupplier
                    setNewMainSupplier={this.props.setNewMainSupplier}
                    mainSupplier={this.props.mainSupplier}
                    changeMainSupplier={this.props.changeMainSupplier}
                />
            </div>
        );
    }
}

let mapStateToProps = (state) => ({
    mainSupplier: state.mainSupplier.mainSupplier,
});

export default compose(
    connect(mapStateToProps, { getAllMainSupplier, setNewMainSupplier, changeMainSupplier })
)(MainSupplierContainer);
