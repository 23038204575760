import {companyAPI, salesAPI} from "../api/api";

import { toast } from 'react-toastify';

const SET_ALL_COMPANY = "SET_ALL_COMPANY";
const SET_COMPANY_PROFILE = "SET_COMPANY_PROFILE";
const SET_COMPANY_LIST = "SET_COMPANY_LIST";
const SET_SEGMENTATION_LIST = "SET_SEGMENTATION_LIST";
const SET_CLIENT_SEGMENTATION_LIST = "SET_CLIENT_SEGMENTATION_LIST";
const SET_REGIONS_LIST = "SET_REGIONS_LIST";
const SET_CITY_LIST = "SET_CITY_LIST";
const SET_AGENTS = "SET_AGENTS";
const SET_ALL_AGENTS = "SET_ALL_AGENTS";
const SET_LAST_ID_COMPANY = "SET_LAST_ID_COMPANY";


let initialState = {
    company: [],
    companyList: [],
    segmentationsList: [],
    clientSegmentationsList: [],
    regionsList: [],
    cityList: [],
    companyProfile: [],
    companyAgents: [],
    allAgents: [],
    lastIdCompany: null,
}

const CompanyReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_COMPANY:
            return {
                ...state,
                company: action.company
            }
        case SET_COMPANY_LIST:
            return {
                ...state,
                companyList: action.data
            }
        case SET_SEGMENTATION_LIST:
            return {
                ...state,
                segmentationsList: action.data
            }
        case SET_CLIENT_SEGMENTATION_LIST:
            return {
                ...state,
                clientSegmentationsList: action.data
            }
        case SET_REGIONS_LIST:
            return {
                ...state,
                regionsList: action.data
            }
        case SET_CITY_LIST:
            return {
                ...state,
                cityList: action.data
            }
        case SET_COMPANY_PROFILE:
            return {
                ...state,
                companyProfile: action.data
            }
        case SET_AGENTS:
            return {
                ...state,
                companyAgents: action.data
            }
        case SET_ALL_AGENTS:
            return {
                ...state,
                allAgents: action.data
            }
        case SET_LAST_ID_COMPANY:
            return {
                ...state,
                lastIdCompany: action.data
            }
        default:
            return state
    }
}

export const setAllCompany = (company) => ({type: SET_ALL_COMPANY, company});
export const setCompanyProfile = (data) => ({type: SET_COMPANY_PROFILE, data});
export const setCompanyList = (data) => ({type: SET_COMPANY_LIST, data});
export const setSegmentationList = (data) => ({type: SET_SEGMENTATION_LIST, data});
export const setClientSegmentationList = (data) => ({type: SET_CLIENT_SEGMENTATION_LIST, data});
export const setRegionsList = (data) => ({type: SET_REGIONS_LIST, data});
export const setCityList = (data) => ({type: SET_CITY_LIST, data});
export const setAgentsPersonalCompany = (data) => ({type: SET_AGENTS, data});
export const setAllAgents = (data) => ({type: SET_ALL_AGENTS, data});
export const setLastIdCompany = (data) => ({type: SET_LAST_ID_COMPANY, data});

export const getCompany = () => {
    return (dispatch) => {
        companyAPI.getCompany()
            .then(data => {
                dispatch(setAllCompany(data));
            });
    }
}
export const getCompanyList = () => {
    return (dispatch) => {
        companyAPI.getCompanyList()
            .then(data => {
                    dispatch(setCompanyList(data));
            });
    }
}
export const getSegmentationsList = () => {
    return (dispatch) => {
        companyAPI.getSegmentationsList()
            .then(data => {
                dispatch(setSegmentationList(data));
            });
    }
}
export const getClientSegmentationsList = () => {
    return (dispatch) => {
        companyAPI.getClientSegmentationList()
            .then(data => {
                dispatch(setClientSegmentationList(data));
            });
    }
}

export const getRegionsList = () => {
    return (dispatch) => {
        companyAPI.getRegionsList()
            .then(data => {
                dispatch(setRegionsList(data));
            });
    }
}
export const getCityList = (request) => {
    return (dispatch) => {
        companyAPI.getCityList(request)
            .then(data => {
                console.log(data)
                dispatch(setCityList(data));
            });
    }
}

export const getCompanyProfile = (data) => {
    return (dispatch) => {
        companyAPI.getCompanyProfile(data)
            .then(data => {
                if (data.status === 1) {
                    dispatch(setCompanyProfile(data));
                } else {
                    toast.error("Ошибка обновления данных, сообщите Администратору!");
                    console.log(data)
                }

            });
    }
}
export const unbindTag = (request) => {
    return (dispatch) => {
        companyAPI.unbindTag(request)
            .then(data => {
                if (data.status === 1) {
                    dispatch(setCompanyProfile(data.company_data));
                    toast.success("Тег удалён");
                } else {
                    toast.error("Ошибка обновления данных, сообщите Администратору!");
                    toast.error(data?.message);
                    console.log(data?.message);
                }
            });
    }
}
export const bindNewCategory = (request) => {
    return (dispatch) => {
        companyAPI.bindNewCategory(request)
            .then(data => {
                if (data.status === 1) {
                    dispatch(setCompanyProfile(data.company_data));
                    toast.success("Категория привязана");
                } else {
                    toast.error("Ошибка обновления данных, сообщите Администратору!");
                    toast.error(data?.message);
                    console.log(data?.message);
                }
            });
    }
}
export const unbindNewCategory = (request) => {
    return (dispatch) => {
        companyAPI.unbindNewCategory(request)
            .then(data => {
                if (data.status === 1) {
                    dispatch(setCompanyProfile(data.company_data));
                    toast.success("Категория отвязана");
                } else {
                    toast.error("Ошибка обновления данных, сообщите Администратору!");
                    toast.error(data?.message);
                    console.log(data?.message);
                }
            });
    }
}

export const bindNewIndustry = (request) => {
    return (dispatch) => {
        companyAPI.bindNewIndustry(request)
            .then(data => {
                if (data.status === 1) {
                    dispatch(setCompanyProfile(data.company_data));
                    toast.success("Отрасль привязана");
                } else {
                    toast.error("Ошибка обновления данных, сообщите Администратору!");
                    toast.error(data?.message);
                    console.log(data?.message);
                }
            });
    }
}

export const unbindNewIndustry = (request) => {
    return (dispatch) => {
        companyAPI.unbindNewIndustry(request)
            .then(data => {
                if (data.status === 1) {
                    dispatch(setCompanyProfile(data.company_data));
                    toast.success("Отрасль отвязана");
                } else {
                    toast.error("Ошибка обновления данных, сообщите Администратору!");
                    toast.error(data?.message);
                    console.log(data?.message);
                }
            });
    }
}

export const unbindCompanyPartner = (request) => {
    return (dispatch) => {
        companyAPI.unbindCompanyPartner(request)
            .then(data => {
                if (data.status === 1) {
                    dispatch(setCompanyProfile(data.company_data));
                    toast.success("Изменения внесены");
                } else {
                    toast.error("Ошибка обновления данных, сообщите Администратору!");
                    console.log(data.message);
                }
            });
    }
}

export const unbindMainManager = (request) => {
    return (dispatch) => {
        companyAPI.unbindMainManager(request)
            .then(data => {
                if (data.status === 1) {
                    dispatch(setCompanyProfile(data.company_data));
                    toast.success("Менеджер отвязан от компании. Теперь она ничейная.");
                } else {
                    toast.error("Ошибка обновления данных, сообщите Администратору!");
                    console.log(data.message);
                }
            });
    }
}

export const bindSupplier = (request) => {
    return (dispatch) => {
        companyAPI.bindSupplier(request)
            .then(data => {
                if (data.status === 1) {
                    dispatch(setCompanyProfile(data.company_data));
                    toast.success("Поставщик привязан");
                } else {
                    toast.error("Ошибка обновления данных, сообщите Администратору!");
                    console.log(data.message);
                }
            });
    }
}
export const bindTag = (request) => {
    return (dispatch) => {
        companyAPI.bindTag(request)
            .then(data => {
                if (data.status === 1) {
                    dispatch(setCompanyProfile(data.company_data));
                    toast.success("Тег привязан");
                } else {
                    toast.error("Ошибка обновления данных, сообщите Администратору!");
                    console.log(data.message);
                }
            });
    }
}

export const unbindSupplier = (request) => {
    return (dispatch) => {
        companyAPI.unbindSupplier(request)
            .then(data => {
                if (data.status === 1) {
                    dispatch(setCompanyProfile(data.company_data));
                    toast.success("Поставщик отвязан");
                } else {
                    toast.error("Ошибка обновления данных, сообщите Администратору!");
                    console.log(data.message);
                }
            });
    }
}

export const setCompanyEdrpou = (request) => {
    return (dispatch) => {
        companyAPI.setCompanyEdrpou(request)
            .then(data => {
                if (data.status === 1) {
                    if (data.company_data != null) {
                        dispatch(setCompanyProfile(data.company_data));
                        toast.success("Изменения внесены");
                    } else {
                        toast.error("Ошибка обновления данных, сообщите Администратору!");
                        console.log('company_data is null')
                    }
                } else {
                    console.log(data.message);
                }
            });
    }
}

export const deleteMessageFromAgent = (request) => {
    return (dispatch) => {
        companyAPI.deleteMessageFromAgent(request)
            .then(data => {
                if (data.status === 1) {
                    if (data?.company_data != null) {
                        dispatch(setCompanyProfile(data?.company_data));
                        toast.success("Сообщение перенесено в архив");
                    } else {
                        toast.error("Ошибка, сообщите Администратору!");
                        console.log('company_data is null')
                    }
                } else {
                    toast.error("Ошибка, сообщите Администратору!");
                    console.log(data.message);
                }
            });
    }
}

export const setNewCompanyMessage = (request) => {
    return (dispatch) => {
        companyAPI.setNewCompanyMessage(request)
            .then(data => {
                if (data.status === 1) {
                    if (data?.company_data != null) {
                        dispatch(setCompanyProfile(data?.company_data));
                        toast.success("Сообщение успешно сохранено");
                    } else {
                        toast.error("Ошибка, сообщите Администратору!");
                        console.log('data.company_data is null')
                    }
                } else {
                    toast.error("Ошибка, сообщите Администратору!");
                    console.log(data.message);
                }
            });
    }
}

export const updateCompanyAgent = (request) => {
    return (dispatch) => {
        companyAPI.updateCompanyAgent(request)
            .then(data => {
                if (data.status === 1) {
                    if (data?.company_data != null) {
                        dispatch(setCompanyProfile(data?.company_data));
                        toast.success("Изменения сохранены");
                    } else {
                        toast.error("Ошибка, сообщите Администратору!");
                        console.log('data.company_data is null')
                    }
                } else {
                    toast.error("Ошибка, сообщите Администратору!");
                    console.log(data.message);
                }
            });
    }
}

export const deleteCompanyAgent = (request) => {
    return (dispatch) => {
        companyAPI.deleteCompanyAgent(request)
            .then(data => {
                if (data.status === 1) {
                    if (data?.company_data != null) {
                        dispatch(setCompanyProfile(data?.company_data));
                        toast.success("Агент перенесён в архив");
                    } else {
                        toast.error("Ошибка, сообщите Администратору!");
                        toast.error(data?.message);
                    }
                } else {
                    toast.error("Ошибка, сообщите Администратору!");
                    console.log(data?.message);
                }
            });
    }
}

export const addNewContact = (request) => {
    return (dispatch) => {
        companyAPI.addNewContact(request)
            .then(data => {
                if (data.status === 1) {
                    if (data?.company_data != null) {
                        dispatch(setCompanyProfile(data?.company_data));
                        toast.success("Новый контакт добавлен");
                    } else {
                        toast.error("Ошибка, сообщите Администратору!");
                        console.log('data.company_data is null')
                    }
                } else {
                    toast.error("Ошибка, сообщите Администратору!");
                    console.log(data.message);
                }
            });
    }
}

export const setAgentSubscription = (request) => {
    return (dispatch) => {
        companyAPI.setAgentSubscription(request)
            .then(data => {
                if (data.status === 1) {
                    if (data?.company_data != null) {
                        dispatch(setCompanyProfile(data?.company_data));
                        toast.success("Статус подписки изменён");
                    } else {
                        toast.error("Ошибка, сообщите Администратору!");
                        console.log('data.company_data is null')
                    }
                } else {
                    toast.error("Ошибка, сообщите Администратору!");
                    console.log(data.message);
                }
            });
    }
}

export const setCompanyMessage = (request) => {
    return (dispatch) => {
        companyAPI.setCompanyMessage(request)
            .then(data => {
                if (data.status === 1) {
                    if (data?.company_data != null) {
                        dispatch(setCompanyProfile(data?.company_data));
                        toast.success("Сообщение успешно обновлено");
                    } else {
                        toast.error("Ошибка, сообщите Администратору!");
                        console.log('data.company_data is null')
                    }
                } else {
                    toast.error("Ошибка, сообщите Администратору!");
                    console.log(data.message);
                }
            });
    }
}

export const setCompanyRegion = (request) => {
    return (dispatch) => {
        companyAPI.setCompanyRegion(request)
            .then(data => {
                if (data.status === 1) {
                    if (data.company_data != null) {
                        dispatch(setCompanyProfile(data.company_data));
                        toast.success("Изменения внесены");
                    } else {
                        toast.error("Ошибка обновления данных, сообщите Администратору!");
                        console.log('company_data is null')
                    }
                } else {
                    console.log(data.message);
                }
            });
    }
}

export const setCompanyCity = (request) => {
    return (dispatch) => {
        companyAPI.setCompanyCity(request)
            .then(data => {
                if (data.status === 1) {
                    if (data.company_data != null) {
                        dispatch(setCompanyProfile(data.company_data));
                        toast.success("Изменения внесены");
                    } else {
                        toast.error("Ошибка обновления данных, сообщите Администратору!");
                        console.log('company_data is null')
                    }
                } else {
                    console.log(data.message);
                }
            });
    }
}

export const setCompanyName = (request) => {
    return (dispatch) => {
        companyAPI.setCompanyName(request)
            .then(data => {
                if (data.status === 1) {
                    if (data?.company_data != null) {
                        //toast.success("Изменения внесены");
                        dispatch(setCompanyProfile(data.company_data));
                    } else {
                        toast.error("Ошибка обновления данных, сообщите Администратору!");
                        console.log('company_data is null')
                    }
                } else {
                    console.log(data?.message);
                }
            });
    }
}

export const setCompanySite = (request) => {
    return (dispatch) => {
        companyAPI.setCompanySite(request)
            .then(data => {
                if (data.status === 1) {
                    if (data?.company_data != null) {
                        dispatch(setCompanyProfile(data.company_data));
                        toast.success("Изменения внесены");
                    } else {
                        toast.error("Ошибка обновления данных, сообщите Администратору!");
                        console.log('company_data is null')
                    }
                } else {
                    console.log(data?.message);
                }
            });
    }
}

export const editCompanyDiscount = (request) => {
    return (dispatch) => {
        companyAPI.editCompanyDiscount(request)
            .then(data => {
                if (data.status === 1) {
                    if (data?.company_data != null) {
                        dispatch(setCompanyProfile(data.company_data));
                        toast.success("Изменения внесены");
                    } else {
                        toast.error("Ошибка обновления данных, сообщите Администратору!");
                        console.log('company_data is null')
                    }
                } else {
                    console.log(data?.message);
                }
            });
    }
}

export const editCompanyManager1 = (request) => {
    return (dispatch) => {
        companyAPI.editCompanyManager1(request)
            .then(data => {
                if (data.status === 1) {
                    if (data?.company_data != null) {
                        dispatch(setCompanyProfile(data.company_data));
                        toast.success("Изменения внесены");
                    } else {
                        toast.error("Ошибка обновления данных, сообщите Администратору!");
                        console.log('company_data is null')
                    }
                } else {
                    console.log(data?.message);
                }
            });
    }
}

export const setCompanyManager2 = (request) => {
    return (dispatch) => {
        companyAPI.setCompanyManager2(request)
            .then(data => {
                if (data.status === 1) {
                    if (data?.company_data != null) {
                        dispatch(setCompanyProfile(data.company_data));
                        toast.success("Предложение передачи компании отправлено.");
                    } else {
                        toast.error("Ошибка обновления данных, сообщите Администратору!");
                        console.log('company_data is null')
                    }
                } else {
                    console.log(data?.message);
                }
            });
    }
}

export const setCompanyToPlannerFromCompanyProfile=(request)=>{
    return (dispatch)=>{
        companyAPI.setCompanyToPlanner(request)
            .then(data => {
                if (data.status === 1) {
                    if (data?.company_data != null) {
                        dispatch(setCompanyProfile(data.company_data));
                        toast.success("Событие запланировано");
                    } else {
                        toast.error("Ошибка обновления данных, сообщите Администратору!");
                        console.log('company_data is null')
                    }
                } else {
                    console.log(data?.message);
                }
            });
    }
}

export const setClientCompanySegmentation = (request) => {
    return (dispatch) => {
        companyAPI.setClientCompanySegmentation(request)
            .then(data => {
                if (data.status === 1) {
                    if (data?.company_data != null) {
                        dispatch(setCompanyProfile(data.company_data));
                        toast.success("Изменения внесены");
                    } else {
                        toast.error("Ошибка обновления данных, сообщите Администратору!");
                        console.log('company_data is null')
                    }
                } else {
                    console.log(data?.message);
                }
            });
    }
}
export const setCompany2Segmentation = (request) => {
    return (dispatch) => {
        companyAPI.setCompany2Segmentation(request)
            .then(data => {
                if (data.status === 1) {
                    if (data?.company_data != null) {
                        dispatch(setCompanyProfile(data.company_data));
                        toast.success("Изменения внесены");
                    } else {
                        toast.error("Ошибка обновления данных, сообщите Администратору!");
                        console.log('company_data is null')
                    }
                } else {
                    console.log(data?.message);
                }
            });
    }
}
export const forceRejectCompanySecondManager = (request) => {
    return (dispatch) => {
        salesAPI.rejectCompany2ndManager(request)
            .then(data => {
                if (data.status === 1) {
                    if (data?.company_data != null) {
                        dispatch(setCompanyProfile(data.company_data));
                        toast.success("Передача компании отменена.");
                    } else {
                        toast.error("Ошибка обновления данных, сообщите Администратору!");
                        console.log('company_data is null')
                    }
                } else {
                    console.log(data?.message);
                }
            });
    }
}

export const setCompanySegmentation = (request) => {
    return (dispatch) => {
        return companyAPI.setCompanySegmentation(request)
            .then(data => {
                if (data.status === 1) {
                    if (data?.company_data != null) {
                        dispatch(setCompanyProfile(data.company_data));
                        toast.success("Изменения внесены");
                    } else {
                        toast.error("Ошибка обновления данных, сообщите Администратору!");
                        console.log('company_data is null')
                    }
                } else {
                    console.log(data?.message);
                }
            });
    }
}

export const addCompanyPartner = (request) => {
    return (dispatch) => {
        return companyAPI.addCompanyPartner(request)
            .then(data => {
                if (data.status === 1) {
                    if (data?.company_data != null) {
                        dispatch(setCompanyProfile(data.company_data));
                        toast.success("Изменения внесены");
                    } else {
                        toast.error("Ошибка обновления данных, сообщите Администратору!");
                        console.log('company_data is null')
                    }
                } else {
                    console.log(data?.message);
                }
            });
    }
}

export const editCompanyComment = (request) => {
    return (dispatch) => {
        return companyAPI.editCompanyComment(request)
            .then(data => {
                if (data.status === 1) {
                    if (data?.company_data != null) {
                        dispatch(setCompanyProfile(data.company_data));
                        toast.success("Изменения внесены");
                    } else {
                        toast.error("Ошибка обновления данных, сообщите Администратору!");
                        console.log('company_data is null')
                    }
                } else {
                    console.log(data?.message);
                }
            });
    }
}

export const setCompanyDeliveryAddress = (request) => {
    return (dispatch) => {
        return companyAPI.setCompanyDeliveryAddress(request)
            .then(data => {
                if (data.status === 1) {
                    if (data?.company_data != null) {
                        dispatch(setCompanyProfile(data.company_data));
                        toast.success("Изменения внесены");
                    } else {
                        toast.error("Ошибка обновления данных, сообщите Администратору!");
                        console.log('company_data is null')
                    }
                } else {
                    console.log(data?.message);
                }
            });
    }
}

export const setCompanyAkonName = (request) => {
    return (dispatch) => {
        return companyAPI.setCompanyAkonName(request)
            .then(data => {
                if (data.status === 1) {
                    if (data?.company_data != null) {
                        dispatch(setCompanyProfile(data.company_data));
                        toast.success("Изменения внесены");
                    } else {
                        toast.error("Ошибка обновления данных, сообщите Администратору!");
                        console.log('company_data is null');
                    }
                } else {
                    console.log(data?.message);
                }
            });
    }
}

export const setCompanyContract = (request) => {
    return (dispatch) => {
        return companyAPI.setCompanyContract(request)
            .then(data => {
                if (data.status === 1) {
                    if (data?.company_data != null) {
                        dispatch(setCompanyProfile(data.company_data));
                        toast.success("Изменения внесены");
                    } else {
                        toast.error("Ошибка обновления данных, сообщите Администратору!");
                        console.log('company_data is null')
                    }
                } else {
                    console.log(data?.message);
                }
            });
    }
}


export const getAllAgents = () => {
    return (dispatch) => {
        companyAPI.getAllAgents()
            .then(data => {
                dispatch(setAllAgents(data));
            });
    }
}
export const getAgentsPersonalCompany = (companyId) => {
    return (dispatch) => {
        companyAPI.getAgentsPersonalCompany(companyId)
            .then(data => {
                dispatch(setAgentsPersonalCompany(data));
            });
    }
}
export const setNewCompanyAgent = (array, companyId) => {
    return (dispatch) => {
        companyAPI.setNewCompanyAgent(array)
            .then(data => {
                dispatch(getAgentsPersonalCompany(companyId));
            });
    }
}
export const changeCompanyAgent = (array, companyId) => {
    return (dispatch) => {
        companyAPI.changeCompanyAgent(array)
            .then(data => {
                dispatch(getAgentsPersonalCompany(companyId));
            });
    }
}
export const addNewCompany = (array) => {
    return (dispatch) => {
        companyAPI.addNewCompany(array)
            .then(data => {
                if (data.status === 1) {
                    //console.log(data);
                    dispatch(setLastIdCompany([data.data.id, data.data.name, data.data.name_with_akon]));
                } else {
                    console.log(data)
                }
            });
    }
}
export const getCompanyByEdrpou = (edrpou) => {
    return (dispatch) => {
        companyAPI.getCompanyByEdrpou(edrpou)

            .then(data => {
                setLastIdCompany(null)
                if (data.status === 1) {
                    console.log(data);
                    dispatch(setLastIdCompany([data.data.id, data.data.name, data.data.name_with_akon]));
                } else {
                    console.log(data);
                    dispatch(setLastIdCompany(null)); // Set lastIdCompany to null when data is not found or an error occurs.
                }
            })
            .catch(error => {
                console.log(error);
                // Handle any errors that occur during the API call if needed.
                dispatch(setLastIdCompany(null)); // Set lastIdCompany to null when an error occurs.
            });
    }
}


export default CompanyReducer;
