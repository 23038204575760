import React, {useState} from 'react';
import {TextField, Autocomplete} from '@mui/material';
import {techAPI} from "../../../api/api";
import {toast} from "react-toastify";

export default function TechConsultation({onChange}) {
    const [companies, setCompanies] = useState([]); // Ensure this is always an array
    const [loading, setLoading] = useState(false);

    const fetchCompanies = async (query) => {
        setLoading(true);
        try {
            await techAPI.getCompanyListNameWithAkon({query}).then((response) => {
                    console.log('Server response:', response); // Log the server response
                    if (Array.isArray(response)) {
                        let uniqueCompanies = response.filter((company, index, self) =>
                            index === self.findIndex((t) => (
                                t.id === company.id
                            ))
                        );
                        //sort by id
                        uniqueCompanies.sort((a, b) => a.id - b.id);




                        setCompanies(uniqueCompanies);
                        console.log('Companies set:', uniqueCompanies); // Log the companies being set
                    } else {
                        console.error('Expected an array but received:', response);
                        setCompanies([]); // Fallback to an empty array
                    }
                }
            );
        } catch (error) {
            console.error('Ошибка получения списка компаний:', error);
            toast.error('Ошибка получения списка компаний');
            setCompanies([]); // Fallback to an empty array on error
        } finally {
            setLoading(false);
        }
    };

    return (
        <Autocomplete
            options={companies}
            getOptionLabel={(option) => {
                console.log('Rendering option:', option); // Log each option being rendered
                return option.id + '. ' + option.name_with_akon || '';
            }}
            onInputChange={(event, value) => {
                console.log('Input changed:', value); // Log input changes
                if (value.length > 2) {
                    fetchCompanies(value);
                } else {
                    setCompanies([]); // Clear the options when the input is too short
                }
            }}
            onChange={(event, value) => {
                console.log('Selected company:', value); // Log the selected company
                onChange(value);
            }}
            loading={loading}
            noOptionsText={loading ? "Loading..." : "No data"} // Show "Loading..." if data is still being fetched
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Выберите компанию"
                    variant="outlined"
                    fullWidth
                />
            )}
        />
    );
}
