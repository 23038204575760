import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getAllDistribution, getAllDistributionNomenclatures, addNewDistribution, addNewNom, addNewDistributionStatistics, changeDateDistribution, deleteDistribution, changeNameDistribution } from '../../../redux/sales-reducer';
import Distribution from './Distribution';
import Cookies from "js-cookie";
import { useParams } from 'react-router-dom';

class DistributionContainer extends React.Component {
    currentPersonalId = this.props.params.personalId;

    componentDidMount() {
        let isAdmin = !!Cookies.get('adminID');
        if (!isAdmin) {
            if (Cookies.get('id') !== this.currentPersonalId) {
                this.currentPersonalId = Cookies.get('id');
            }
        }

        this.props.getAllDistribution(this.currentPersonalId);
        this.props.getAllDistributionNomenclatures(this.currentPersonalId);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.params.personalId !== this.props.params.personalId) {
            this.props.getAllDistribution(this.currentPersonalId);
            this.props.getAllDistributionNomenclatures(this.currentPersonalId);
        }
    }

    render() {
        return (
            <div>
                <Distribution
                    allDistribution={this.props.allDistribution}
                    allDistributionNomenclatures={this.props.allDistributionNomenclatures}
                    addNewDistribution={this.props.addNewDistribution}
                    personalId={this.props.params.personalId}
                    addNewNom={this.props.addNewNom}
                    addNewDistributionStatistics={this.props.addNewDistributionStatistics}
                    changeDateDistribution={this.props.changeDateDistribution}
                    deleteDistribution={this.props.deleteDistribution}
                    changeNameDistribution={this.props.changeNameDistribution}
                />
            </div>
        );
    }
}

let mapStateToProps = (state) => ({
    allDistribution: state.sales.allDistribution,
    allDistributionNomenclatures: state.sales.allDistributionNomenclatures,
});

// Custom withRouter replacement using useParams
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const params = useParams();
        return <Component {...props} params={params} />;
    }

    return ComponentWithRouterProp;
}

export default compose(
    connect(mapStateToProps, {
        getAllDistribution,
        getAllDistributionNomenclatures,
        addNewDistribution,
        addNewNom,
        addNewDistributionStatistics,
        changeDateDistribution,
        deleteDistribution,
        changeNameDistribution
    }),
    withRouter
)(DistributionContainer);
