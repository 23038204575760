import React from 'react';
import {connect} from 'react-redux';
import {
    getAverageCollModulsInCheck,
    getAverageCollPositionModulsInCheck,
    getAverageCheck,
    getNewCustomer,
    getCustomerForYear,
    getActiveSalesWithCompany, getModulesForYear
} from '../../../../redux/statistics-reducer';
import CheckValue from './CheckValue';

class CheckValueContainer extends React.Component {
    componentDidMount() {
        this.props.getAverageCollModulsInCheck();//среднее значение модулей в счёте
        this.props.getAverageCollPositionModulsInCheck();//среднее значение позиций модулей в счёте
        this.props.getAverageCheck();//средний чек
        this.props.getNewCustomer();//новые компании за год
        this.props.getCustomerForYear();//уникальные компании за год
        this.props.getModulesForYear();//проданные модули по годам
        // this.props.getActiveSalesWithCompany();//уникальные компании за год
    }

    render() {
        return (
            <div>
                <CheckValue {...this.props}/>
            </div>
        )
    }
}

let mapStateToProps = (state) => ({
    averageCollModulsInCheck: state.statistics.averageCollModulsInCheck,
    averageCollPositionModulsInCheck: state.statistics.averageCollPositionModulsInCheck,
    averageCheck: state.statistics.averageCheck,
    newCustomer: state.statistics.newCustomer,
    customerForYear: state.statistics.customerForYear,
    modulesForYear: state.statistics.modulesForYear,
    // activeSalesWithCompany: state.statistics.activeSalesWithCompany,
    // activeSalesWithPaidCompany: state.statistics.activeSalesWithPaidCompany,
})
export default connect(mapStateToProps, {
    getAverageCollModulsInCheck,
    getAverageCollPositionModulsInCheck,
    getAverageCheck,
    getNewCustomer,
    getCustomerForYear,
    getActiveSalesWithCompany,
    getModulesForYear
})(CheckValueContainer);