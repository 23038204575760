import React from 'react';
import { connect } from 'react-redux';
import {getAgentsPersonalCompany, setNewCompanyAgent, changeCompanyAgent} from '../../../../redux/company-reducer';
import {getMessagesPersonalKp, setNewKpMessage} from '../../../../redux/check-reducer';
import MessagesCheck from './MessagesCheck';

class MessagesCheckContainer extends React.Component{
    componentDidMount(){
        this.props.getAgentsPersonalCompany(this.props.companyId);
        this.props.getMessagesPersonalKp(this.props.checkId);
    }
    
    render(){
        return (
            <div>
                <MessagesCheck {...this.props}/>
            </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    companyAgents: state.company.companyAgents,
    messagesPersonalKp: state.check.messagesPersonalKp,
})
export default connect( mapStateToProps, {getAgentsPersonalCompany, getMessagesPersonalKp, setNewCompanyAgent, setNewKpMessage, changeCompanyAgent})(MessagesCheckContainer);