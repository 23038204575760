import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import s from '../Task.module.css';

const AddManager = (props) =>{
    let [newWorkers, setNewWorkers] = useState(0)
    const setNewWorkersF = (e)=>{
        setNewWorkers(e.target.value)
    }
    const setNewWorker = ()=>{
        props.setNewWorkerInTask(props.idTaskForChange, newWorkers, props.personalId)
        props.setShowAddManager(false)
    }
    return(
        <div>
            <h2>Назначить сотрудника</h2>
            <div className={s.twoBlock}>
                <div>Выбрать сотрудника: </div>
                <div>
                    <select onChange={setNewWorkersF}>
                        <option value="0">Не выбран</option>
                        {props.workerList.map(k=>{
                            return <option value={k.id} key={k.id}>{k.name}</option>
                        })}
                    </select>
                </div>
                <div><button onClick={()=>setNewWorker()}>Назначить сотрудника</button></div>
            </div>
        </div>
    )
}
export default AddManager;