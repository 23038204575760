import React, {useState, useEffect} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Button,
    ButtonGroup,
    CircularProgress,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    ToggleButtonGroup, ToggleButton, Grid, Icon, Tabs, Tab
} from '@mui/material';
import axios from 'axios';
import MonthSelector from "../Salary/Assembler/MonthSelector";
import {toast} from 'react-toastify';
import {adminAPI} from "../../../api/api";
import CurrencyFormatter from "../../Sales/common/CurrencyFormatter/CurrencyFormatter";
import {faEdit} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import Cookie from "js-cookie";


const VATPage = () => {
        const currentYear = new Date().getFullYear();
        const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, '0'); // формат MM

        const [year, setYear] = useState(currentYear);
        const [month, setMonth] = useState(currentMonth);
        const [monthName, setMonthName] = useState(''); // Російська назва місяця
        const [data, setData] = useState(null);
        const [loading, setLoading] = useState(false);

        // State для діалогового вікна
        const [openDialog, setOpenDialog] = useState(false);
        const [additionalVATAmount, setAdditionalVATAmount] = useState('');
        const [additionalVATDate, setAdditionalVATDate] = useState(new Date().toISOString().split('T')[0]); // Today’s date


        // Функція для завантаження даних
        const fetchVATData = async (selectedYear, selectedMonth) => {
            setLoading(true);
            try {
                await adminAPI.receiveVATInfo({
                    params: {year: selectedYear, month: selectedMonth}
                }).then(response => {
                    if (response?.status === 1) {
                        setData(response?.data); // Зберігаємо дані в стан
                        toast.success('Дані отримано');
                    } else {
                        toast.error('Ошибка получения данных: ' + response?.data.message || 'неизвестная ошибка');
                    }
                }).catch(error => {
                    toast.error('Помилка отримання даних: ' + error);
                });
            } catch (error) {
                console.error("Ошибка получения данных:", error);
            } finally {
                setLoading(false);
            }
        };

        // Завантаження даних для поточного місяця при першому рендерингу
        useEffect(() => {
            if([1,34,44].includes(parseInt(Cookie.get('id')))) {
                fetchVATData(year, month);
            }else{
                toast.error('У вас недостаточно прав для просмотра этой страницы');
            }
        }, [year, month]);

        // Генерація кнопок для вибору року
        const renderYearButtons = () => {
            const startYear = 2024;
            const years = [];
            for (let y = startYear; y <= currentYear; y++) {
                years.push(
                    <Button
                        key={y}
                        variant={y === year ? 'contained' : 'outlined'}
                        onClick={() => setYear(y)}
                    >
                        {y}
                    </Button>
                );
            }
            return years;
        };

        const [addPayment, setAddPayment] = useState(false);
        const [monthAddPayment, setMonthAddPayment] = useState(1);
        const [monthPart, setMonthPart] = useState(1);
        const [yearAddPayment, setYearAddPayment] = useState(currentYear);
        const yearList = () => {
            let years = [];
            for (let y = 2024; y <= currentYear; y++) {
                years.push(y);
            }
            return years;
        }
        const monthList = () => {
            let months = [];
            for (let m = 1; m <= 12; m++) {
                months.push(m);
            }
            return months;
        }
        const closeDialog = () => {
            setAdditionalVATAmount('');
            setAdditionalVATDate(new Date().toISOString().split('T')[0]);
            setAddPayment(false);
            setMonthPart(1);
            setYearAddPayment(currentYear);
            setOpenDialog(false);
        }

        const handleChangeYear = (event, newYearAddPayment) => {
            setYearAddPayment(newYearAddPayment);
        }
        const handleChangeMonthPart = (event, newMonthPart) => {
            setMonthPart(newMonthPart);
        }
        const handleChangeMonth = (event, newMonthAddPayment) => {
            setMonthAddPayment(newMonthAddPayment);
        }

        const isFilled = (val) => {
            return val !== '' && val !== null && val !== undefined;
        }
        // Обробка збереження додаткового НДС
        const handleSaveAdditionalVAT = async () => {
            let newDate = yearAddPayment + '-' + monthAddPayment.toString().padStart(2, '0') + '-' + (monthPart === 1 ? '15' : '28')
            let date = addPayment ? newDate : additionalVATDate;

            let newVal = parseFloat(additionalVATAmount) * (addPayment ? (-1) : 1);

            if (!isFilled(additionalVATAmount) || !isFilled(date)) {
                toast.error('Заполните все поля');
                return;
            }

            //console.log(newVal, date); return;

            try {
                await adminAPI.saveAdditionalVAT({
                    money: newVal,
                    created_at: date,
                });
                toast.success('Дополнительный платёж добавлен');
                closeDialog();
                fetchVATData(year, month); // Оновити дані після додавання
            } catch (error) {
                toast.error('Ошибка при сохранении платежа.');
            }
        };

        const currentMonthPart = () => {
            return (new Date()).getDate() < 16 ? 1 : 2;
        };

        console.log(data)

        // Додатковий state для редагування
        const [openEditDialog, setOpenEditDialog] = useState(false);
        const [editTransaction, setEditTransaction] = useState(null);

// Відкрити діалогове вікно для редагування
        const handleOpenEditDialog = (transaction) => {
            setEditTransaction(transaction);
            setAdditionalVATAmount(Math.abs(transaction.amount));
            setOpenEditDialog(true);
        };

// Закрити діалогове вікно
        const handleCloseEditDialog = () => {
            setOpenEditDialog(false);
            setEditTransaction(null);
            setAdditionalVATAmount('');
        };

// Зберегти відредаговану транзакцію
        let [isPayment, setIsPayment] = useState(false);
        const handleSaveEditedTransaction = async () => {
            if (!isFilled(additionalVATAmount)) {
                toast.error('Заповніть всі поля');
                return;
            }

            try {
                await adminAPI.updateAdditionalVAT({
                    id: editTransaction.id,
                    money: parseFloat(additionalVATAmount) * (isPayment ? -1 : 0),
                });
                toast.success('Транзакцію успішно оновлено');
                handleCloseEditDialog();
                fetchVATData(year, month); // Оновити дані після редагування
            } catch (error) {
                toast.error('Помилка при оновленні транзакції');
            }
        };

// Видалити транзакцію
        const handleDeleteTransaction = async () => {
            try {
                await adminAPI.deleteAdditionalVAT({id: editTransaction.id});
                toast.success('Транзакцію успішно видалено');
                handleCloseEditDialog();
                fetchVATData(year, month); // Оновити дані після видалення
            } catch (error) {
                toast.error('Помилка при видаленні транзакції');
            }
        };
        const [activeTab, setActiveTab] = useState(0);

        const handleTabChange = (event, newValue) => {
            setActiveTab(newValue);
        };

        return (
            <div style={{padding: '20px'}}>
                <Dialog open={openEditDialog} onClose={handleCloseEditDialog} fullWidth={true}>
                    <DialogTitle>Редактировать платеж</DialogTitle>
                    <DialogContent>
                        <TextField
                            label="Сума платежу"
                            type="number"
                            fullWidth
                            variant="outlined"
                            value={additionalVATAmount}
                            onChange={(e) => setAdditionalVATAmount(e.target.value)}
                            sx={{marginTop: '20px'}}
                            InputProps={{inputProps: {step: 0.01}}}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Box sx={{flexGrow: 1}}>
                            <Button onClick={handleDeleteTransaction} color="error">Удалить</Button>
                        </Box>
                        <Button onClick={handleSaveEditedTransaction} color="primary">Сохранить</Button>
                    </DialogActions>
                </Dialog>

                <Typography variant="h4" gutterBottom>
                    НДС за периоды
                </Typography>

                {/* Вибір року */}
                {currentYear > 2024 &&
                    <>
                        <ButtonGroup variant="outlined" color="primary" aria-label="year selector"
                                     style={{marginBottom: '20px'}}>
                            {renderYearButtons()}
                        </ButtonGroup>
                        <br/>
                    </>
                }


                {/* Вибір місяця */}
                <MonthSelector selectedMonth={month} onSelect={setMonth} onSelectName={setMonthName}/>

                <br/>
                {/* Кнопка для додавання додаткового НДС */}
                <Button variant="contained" color="primary" onClick={() => {
                    setAdditionalVATDate(new Date().toISOString().split('T')[0]);
                    setOpenDialog(true)
                }} style={{marginTop: '20px'}}>
                    Добавить дополнительный платёж (сумма с НДС!)
                </Button>
                {/* Кнопка для додавання оплати */}
                <Button variant="contained" color="success" onClick={() => {
                    //setAdditionalVATDate(new Date().toISOString().split('T')[0]);
                    setYearAddPayment(currentYear);
                    setMonthAddPayment((new Date()).getMonth() + 1);
                    setMonthPart(currentMonthPart);
                    setOpenDialog(true)
                    setAddPayment(true);
                }} style={{marginTop: '20px', marginLeft: '20px'}}>
                    Добавить оплату НДС
                </Button>




                {/* Діалогове вікно для введення додаткового НДС */}
                <Dialog open={openDialog} onClose={() => closeDialog()} fullWidth={true}>
                    <DialogTitle>Добавить {addPayment ? 'оплату' : 'платёж НДС'}</DialogTitle>
                    <DialogContent>
                        <TextField
                            label="Сумма счета с НДС"
                            type="number"
                            fullWidth
                            variant="outlined"
                            value={additionalVATAmount}
                            onChange={(e) => setAdditionalVATAmount(e.target.value)}
                            sx={{marginTop: '20px'}}
                            InputProps={{inputProps: {step: 0.01}}}
                        />
                        {addPayment ?
                            <>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={yearAddPayment}
                                    exclusive
                                    onChange={handleChangeYear}
                                    aria-label="Platform"
                                    sx={{marginTop: '8px'}}
                                >
                                    {currentYear > 2024 && yearList().map((year) => (
                                        <ToggleButton key={year} value={year}>{year}</ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                                <br/>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={monthAddPayment}
                                    exclusive
                                    onChange={handleChangeMonth}
                                    aria-label="Platform"
                                    sx={{marginTop: '8px'}}
                                >
                                    {monthList().map((month) => (
                                        <ToggleButton key={month} value={month}>{month}</ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                                <br/>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={monthPart}
                                    exclusive
                                    onChange={handleChangeMonthPart}
                                    aria-label="Platform"
                                    sx={{marginTop: '8px'}}
                                >
                                    <ToggleButton value={1}>1-15</ToggleButton>
                                    <ToggleButton value={2}>16-31</ToggleButton>
                                </ToggleButtonGroup>
                            </>
                            :
                            <TextField
                                label="Дата"
                                type="date"
                                fullWidth
                                variant="outlined"
                                value={additionalVATDate}
                                onChange={(e) => setAdditionalVATDate(e.target.value)}
                                style={{marginTop: '20px'}}
                            />
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => closeDialog()} color="secondary">Отмена</Button>
                        <Button onClick={handleSaveAdditionalVAT} color="primary">Сохранить</Button>
                    </DialogActions>
                </Dialog>

                {/* Плейсхолдер під час завантаження */}
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" style={{marginTop: '20px'}}>
                        <CircularProgress/>
                    </Box>
                ) : (
                    data && (
                        <>
                            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>
                                            <TableCell><b>Период</b></TableCell>
                                            <TableCell align="right"><b>Сумма НДС мы должны</b></TableCell>
                                            <TableCell align="right"><b>Сумма НДС нам должны</b></TableCell>
                                            <TableCell align="right"><b>Сумма НДС нам должны доп.</b></TableCell>
                                            <TableCell align="right"><b>Сумма НДС нам должны всего</b></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>1-15 {monthName}</TableCell>
                                            <TableCell align="right">
                                                <CurrencyFormatter withoutCurrency={true} amount={data.we.firstHalf} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <CurrencyFormatter withoutCurrency={true} amount={data.government.firstHalf} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <CurrencyFormatter withoutCurrency={true} amount={data.additionalVAT.firstHalf} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <CurrencyFormatter
                                                    withoutCurrency={true}
                                                    amount={parseFloat(data.additionalVAT.firstHalf) + parseFloat(data.government.firstHalf)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow style={{ backgroundColor: '#f9f9f9' }}>
                                            <TableCell>16-31 {monthName}</TableCell>
                                            <TableCell align="right">
                                                <CurrencyFormatter withoutCurrency={true} amount={data.we.secondHalf} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <CurrencyFormatter withoutCurrency={true} amount={data.government.secondHalf} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <CurrencyFormatter withoutCurrency={true} amount={data.additionalVAT.secondHalf} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <CurrencyFormatter
                                                    withoutCurrency={true}
                                                    amount={parseFloat(data.additionalVAT.secondHalf) + parseFloat(data.government.secondHalf)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow style={{ backgroundColor: '#dfe6e9', fontWeight: 'bold' }}>
                                            <TableCell><b>Всего за {monthName}</b></TableCell>
                                            <TableCell align="right">
                                                <b>
                                                    <CurrencyFormatter
                                                        withoutCurrency={true}
                                                        amount={parseFloat(data.we.firstHalf) + parseFloat(data.we.secondHalf)}
                                                    />
                                                </b>
                                            </TableCell>
                                            <TableCell align="right">
                                                <b>
                                                    <CurrencyFormatter
                                                        withoutCurrency={true}
                                                        amount={parseFloat(data.government.firstHalf) + parseFloat(data.government.secondHalf)}
                                                    />
                                                </b>
                                            </TableCell>
                                            <TableCell align="right">
                                                <b>
                                                    <CurrencyFormatter
                                                        withoutCurrency={true}
                                                        amount={parseFloat(data.additionalVAT.firstHalf) + parseFloat(data.additionalVAT.secondHalf)}
                                                    />
                                                </b>
                                            </TableCell>
                                            <TableCell align="right">
                                                <b>
                                                    <CurrencyFormatter
                                                        withoutCurrency={true}
                                                        amount={
                                                            parseFloat(data.additionalVAT.firstHalf) +
                                                            parseFloat(data.additionalVAT.secondHalf) +
                                                            parseFloat(data.government.firstHalf) +
                                                            parseFloat(data.government.secondHalf)
                                                        }
                                                    />
                                                </b>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Grid container spacing={2} style={{marginTop: '20px'}}>
                                {/* Вкладки для керування */}
                                <Grid item xl={12} xs={12} xm={12}>
                                    <Tabs
                                        value={activeTab}
                                        onChange={handleTabChange}
                                        sx={{
                                            borderBottom: '1px solid #ddd',
                                            marginBottom: '20px',
                                            '& .MuiTab-root': { minWidth: 'unset', padding: '0 20px' },
                                        }}
                                    >
                                        <Tab
                                            label={
                                                <Button
                                                    variant={activeTab === 0 ? 'contained' : 'text'}
                                                    sx={{
                                                        color: activeTab === 0 ? 'white' : 'blue', // Білий текст для активної, синій для неактивної
                                                        backgroundColor: activeTab === 0 ? 'blue' : 'transparent', // Фон синій для активної
                                                        textTransform: 'none', // Вимикає капіталізацію
                                                    }}
                                                >
                                                    Дополнительный НДС ({data?.additionalVAT?.positiveTransactions?.length ?? 0} шт)
                                                </Button>
                                            }
                                            sx={{
                                                minHeight: '48px',
                                            }}
                                        />

                                        <Tab
                                            label={
                                                <Button
                                                    variant={activeTab === 1 ? 'contained' : 'text'}
                                                    sx={{
                                                        color: activeTab === 1 ? 'white' : 'green', // Білий текст для активної, зелений для неактивної
                                                        backgroundColor: activeTab === 1 ? 'green' : 'transparent', // Фон зелений для активної
                                                        textTransform: 'none', // Вимикає капіталізацію
                                                    }}
                                                >
                                                    Оплаты НДС ({Object.keys(data?.additionalVAT?.negativeTransactions || {}).length} шт)
                                                </Button>
                                            }
                                            sx={{
                                                minHeight: '48px',
                                            }}
                                        />
                                    </Tabs>



                                </Grid>

                                {/* Таблиці для першого гріда */}
                                <Grid item xl={6} xs={12} xm={12}>
                                    {activeTab === 0 && (
                                        <TableContainer component={Paper} style={{marginTop: '0px'}}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>
                                                        <TableCell><b>Дата</b></TableCell>
                                                        <TableCell align="right"><b>Сумма c НДС</b></TableCell>
                                                        <TableCell align="right"><b>Действия</b></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {Object.values(data.additionalVAT.positiveTransactions).map((transaction, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>{transaction.date < 16 ? '1-15' : '16-31'}</TableCell>
                                                            <TableCell align="right">
                                                                <CurrencyFormatter withoutCurrency={true} amount={transaction.amount}/>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <Button
                                                                    variant="outlined"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        setIsPayment(false);
                                                                        handleOpenEditDialog(transaction);
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon icon={faPencilAlt}/>
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    )}

                                    {activeTab === 1 && (
                                        <TableContainer component={Paper} style={{marginTop: '0px'}}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>
                                                        <TableCell><b>Дата</b></TableCell>
                                                        <TableCell align="right"><b>Сумма платежа</b></TableCell>
                                                        <TableCell align="right"><b>Действия</b></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {Object.values(data.additionalVAT.negativeTransactions).map((transaction, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>{transaction.date < 16 ? '1-15' : '16-31'}</TableCell>
                                                            <TableCell align="right">
                                                                <CurrencyFormatter withoutCurrency={true} amount={Math.abs(transaction.amount)}/>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <Button
                                                                    variant="outlined"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        setIsPayment(true);
                                                                        handleOpenEditDialog(transaction);
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon icon={faPencilAlt}/>
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    )}
                                </Grid>

                                {/* Таблиця другого гріда */}
                                <Grid item xl={6} xs={12} xm={12}>
                                    <TableContainer component={Paper} style={{ marginTop: '0px' }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow style={{ backgroundColor: '#f5f5f5' }}>
                                                    <TableCell><b>Период</b></TableCell>
                                                    <TableCell align="right"><b>Сумма НДС</b></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {[
                                                    {
                                                        period: '1-15',
                                                        amount: parseFloat(data.we.firstHalf) -
                                                            parseFloat(data.government.firstHalf) +
                                                            parseFloat(data.additionalVAT.firstHalf)
                                                    },
                                                    {
                                                        period: '16-31',
                                                        amount: parseFloat(data.we.secondHalf) +
                                                            parseFloat(data.government.secondHalf) +
                                                            parseFloat(data.additionalVAT.secondHalf),
                                                    },
                                                    {
                                                        period: `Сумма НДС на конец месяца ${monthName}`,
                                                        amount: parseFloat(data.onVatAccount.endOfMonthSaldo),
                                                    },
                                                    {
                                                        period: `Всего НДС за ${monthName}`,
                                                        amount: (parseFloat(data.we.firstHalf) + parseFloat(data.we.secondHalf)) -
                                                            (parseFloat(data.additionalVAT.firstHalf) +
                                                                parseFloat(data.additionalVAT.secondHalf) +
                                                                parseFloat(data.government.firstHalf) +
                                                                parseFloat(data.government.secondHalf)) -
                                                            Math.abs(parseFloat(data.additionalVAT.negativeMonthlySum)),
                                                    },
                                                    {
                                                        period: 'Остаток НДС на данный момент',
                                                        amount: data.onVatAccount?.result,
                                                    },
                                                ].map((row, index) => (
                                                    <TableRow
                                                        key={index}
                                                        style={{
                                                            backgroundColor: index % 2 === 0 ? '#ffffff' : '#f9f9f9',
                                                        }}
                                                    >
                                                        <TableCell>
                                                            {index === 4 ? <b>{row.period}</b> : row.period}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {index === 4 ? (
                                                                <b>
                                                                    <CurrencyFormatter withoutCurrency={true} amount={row.amount} />
                                                                </b>
                                                            ) : (
                                                                <CurrencyFormatter withoutCurrency={true} amount={row.amount} />
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>


                        </>
                    )
                )}
            </div>
        );
    }
;

export default VATPage;
