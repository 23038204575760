import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';
import TechConsultation from "./TechConsultation";

function TechConsultationDialog({ open, onClose }) {
    const [description, setDescription] = useState('');

    const handleSubmit = () => {
        // Тут відправляємо форму
        console.log('Form Data:', { description });
        onClose(); // Закриваємо діалог після відправки
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>Создание заявки</DialogTitle>
            <DialogContent>
                <TechConsultation onChange={(company) => console.log(company)} />
                <TextField
                    label="Описание задачи"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    inputProps={{
                        style: { textAlign: 'left' },
                    }}
                    required
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button onClick={handleSubmit} color="primary" variant="contained">
                    Отправить
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default TechConsultationDialog;
