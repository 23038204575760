import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getPersonalAreaSalesCheck } from '../../../redux/personalArea-reducer';
import { getCountOverdueCheckTest } from '../../../redux/sales-reducer';
import PersonalAreaSalesCheck from './PersonalAreaSalesCheck';
import Cookies from "js-cookie";
import { useParams } from 'react-router-dom';

class PersonalAreaSalesContainer extends React.Component {
    currentPersonalId = this.props.params.personalId;

    componentDidMount() {
        let isAdmin = !!Cookies.get('adminID');
        if (!isAdmin) {
            if (Cookies.get('id') !== this.currentPersonalId) {
                this.currentPersonalId = Cookies.get('id');
            }
        }

        this.props.getPersonalAreaSalesCheck(this.currentPersonalId);
        this.props.getCountOverdueCheckTest(1);
    }

    render() {
        return (
            <PersonalAreaSalesCheck
                personalId={this.currentPersonalId}
                personalAreaSalesCheck={this.props.personalAreaSalesCheck}
            />
        );
    }
}

let mapStateToProps = (state) => ({
    personalAreaSalesCheck: state.personalarea.personalAreaSalesCheck,
    countOverdueCheckTest: state.sales.countOverdueCheckTest,
});

// Custom withRouter replacement using useParams
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const params = useParams();
        return <Component {...props} params={params} />;
    }

    return ComponentWithRouterProp;
}

export default compose(
    connect(mapStateToProps, {
        getPersonalAreaSalesCheck,
        getCountOverdueCheckTest,
    }),
    withRouter
)(PersonalAreaSalesContainer);
