import React, { useState } from 'react';
import main from '../../../Main.module.css';

const SetStatusWorkingKp = (props) =>{
    let [comment, setComment] = useState("")
    const setCommentF = (e) => setComment(e.target.value)
    let [workingOffKp, setWorkingOffKp] = useState(0)
    const setWorkingOffKpF = (e)=>{
        setError(false)
        setWorkingOffKp(e.target.value);
    }
    let [workingOffKpStatus, setWorkingOffKpStatus] = useState(1)
    let workingOffKpStatusArray = [{status: "Цена не устраивает"}, {status: "Срок поставки"}, {status: "Выбрали альтернативу"}]
    const setWorkingOffKpStatusF = (e)=>{
        setWorkingOffKpStatus(e.target.value);
    }
    //если выбрано, что счёт купили, вводим номер счёта по которому это сделано
    let [checkNumber, setCheckNumber] = useState(0)
    const setCheckNumberF=(e)=>{setCheckNumber(e.target.value);setError(false)}
    //выставлять счёт или нет
    let [createCheck, setCreateCheck] = useState(1)
    const go=()=>{
        if(workingOffKp!==0){
            let array = {};
            if(checkNumber===0 && workingOffKp==1){
                setError("Не выбран номер счета")
            }else{
                array =
                    {
                        'workingOffKp'          : workingOffKp,
                        'checkNumber'           : checkNumber,
                        'createCheck'           : createCheck,
                        'workingOffKpStatus'    : workingOffKpStatus,
                        'comment'               : comment,
                        'idKp'                  : props.active,
                        'type'                  : props.type,
                        'personalId'            : props.personalId,
                        'dateStart'             : props.dateStart,
                        'dateEnd'               : props.dateEnd,
                    }
                console.log(array)
                props.statusWorkingKp(array)
                props.setActive(false)
            }
        }else{
            setError("Не выбран статус КП")
            console.log('Не выбран статус КП')
        }
    }
    let [error, setError] = useState(false)
    return(
        <div className={main.center}>
            {props.type==2 ? <h1>КП {props.kpNumber}</h1> : <h1>Счёт {props.kpNumber}</h1>}
            <div>
                <select onChange={setWorkingOffKpF} className={main.input}>
                    <option value="0">Статус не выбран</option>
                    {props.type==2 && <option value="1">Купили</option>}
                    <option value="2">Отмена</option>
                    <option value="3">Не актуально</option>
                </select>
            </div>
            <div>
                {workingOffKp === "1" && 
                    <div>
                        <input type="number" value={checkNumber} onChange={setCheckNumberF} className={main.input} placeholder="Введите номер счета" />
                        <div className={main.twoBlocksGrid}>
                            <div className={createCheck===2 ? main.redBg : main.redBg+" "+main.notChecked} onClick={()=>setCreateCheck(2)}>Не выставлять счёт</div>
                            <div className={createCheck===1 ? main.greenBg : main.greenBg+" "+main.notChecked} onClick={()=>setCreateCheck(1)}>Выставить счёт</div>
                        </div>
                    </div>
                }
                {workingOffKp === "3" &&
                <select className={main.input} onChange={setWorkingOffKpStatusF}>
                    {workingOffKpStatusArray.map((j, index)=>{
                        return <option value={index} key={index}>{j.status}</option>
                    })}
                </select>
                }
            </div>
            <div>
                <textarea value={comment} onChange={setCommentF} className={main.textarea} placeholder="Завершающий комментарий"/>
            </div>
            {error && <div className={main.red}>{error}</div>}
            <div><input type="button" className={main.inputAdd} value="Записать" onClick={()=>go()}/></div>
        </div>
    )
}
export default SetStatusWorkingKp;