import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import s from '../Task.module.css';

const AddTask = (props) => {
    const [cookies] = useCookies();
    let [newWorkers, setNewWorkers] = useState(0);
    let [textTask, setTextTask] = useState("");

    const setNewWorkersF = (e) => {
        setNewWorkers(e.target.value);
    };

    const setTextTaskF = (e) => {
        setTextTask(e.target.value);
    };

    const setNewTaskF = () => {
        if (textTask !== "") {
            let array = [];
            // Get the current user worker ID from cookies
            let worker = props.workerList.find(k => Number(k.login_id) === Number(cookies.id)).id;
            array.push(textTask, newWorkers, worker, props.mainTask);
            props.setNewTask(array);
            setTextTask("");
            props.setShowTask(false);
        }
    };

    return (
        <div className={s.newTask}>
            {props.mainTask === 0
                ? <h2>Новая задача</h2>
                : <h2>Подзадача для задачи №{props.mainTask}</h2>
            }
            <div className={s.twoBlock}>
                <div>Выбрать сотрудника: </div>
                <div>
                    <select onChange={setNewWorkersF}>
                        <option value="0">Не выбран</option>
                        {props.workerList.map(k => (
                            <option value={k.id} key={k.id}>{k.name}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div>
                <textarea rows="2" onChange={setTextTaskF} value={textTask}></textarea>
            </div>
            <div>
                <button onClick={setNewTaskF}>Добавить задачу</button>
            </div>
        </div>
    );
};

export default AddTask;
