import React, { PureComponent } from 'react';
import { useState } from 'react';
import s from './Income.module.css';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import ReceivablesContainer from '../Receivables/Receivables/ReceivablesContainer';
import { NavLink } from 'react-router-dom';

const Income = (props) =>{
    let mainSumUa = props.sum.reduce(function(p,c){return Number(p)+Number(c.sum);},'');
    let mainSumRu = props.sumRu.reduce(function(p,c){return Number(p)+Number(c.sum);},'');
    let mainSum = mainSumUa+mainSumRu;
    let sumRu = props.sumRu.map(k=>k.sum);
    let [incomeList,setIncomeList] = useState(false);
    const setIncomeListF = () =>{
        setIncomeList(!incomeList)
    }
    let turnDate = (date) =>{
        if(date){
            date = date.split("-");
            date = (date[2]+"-"+date[1]+"-"+date[0]);
        }else{
            date = "не указана"
        }
        return date;
    }
    const data = [];
    props.sum.map((k,index)=>{
        data.push({
            name: k.date,
            "Укр": Number(k.sum),
            "План": Number(k.plan),
            "Другое": sumRu[index],
        })
    })
    let sum = props.sum.map(k=>k.sum);
    let num = Number(props.month)-1
    sum = Number(sum[num])+Number(sumRu[num])
    sum = sum.toLocaleString()
    return(
        <div>
            <h2 onClick={()=>setIncomeListF()} ><span className={s.title}>Приход: {sum+" грн"}</span></h2>
            <h3><span className={s.title}><NavLink to="/admin/receivables"><ReceivablesContainer /></NavLink></span></h3>
            <h3><span className={s.title}>Всего за текущий год: {mainSum.toLocaleString()+" грн"}. (Укр - {mainSumUa.toLocaleString()}, Другое - {mainSumRu.toLocaleString()})</span></h3>
            <ResponsiveContainer width="95%" height={250}>
                <BarChart width={150} height={40} data={data} >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                    <XAxis dataKey="name" />
                    <YAxis domain={[0, 4800000]}/>
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Укр" fill="#ffc658" stackId="a" isAnimationActive={false}/>
                    <Bar dataKey="Другое" fill="#1281ca" stackId="a" label={{ position: 'top'}} isAnimationActive={false}/>
                    {/* <Bar dataKey="Росс" fill="#1281ca" stackId="a" label={<CustomizedLabel />} /> */}
                    <Bar dataKey="План" fill="#eee" label={{ position: 'top' }} isAnimationActive={false}/>
                </BarChart>
            </ResponsiveContainer>
            {incomeList &&
                props.check.map((k, index)=>{
                    return(k.money !== "0.00" &&
                        <div className={s.main} key={"check_"+index}>
                            <div>{k.number === "0" ? "Другое" : k.number}</div>
                            <div>{turnDate(k.date)}</div>
                            <div>{k.money}</div>
                        </div>
                    )
                })
            }
        </div>
    )
}
export default Income;

class CustomizedLabel extends PureComponent {
    render() {
      const { x, y, stroke, value } = this.props;
      return (
        <text x={x} y={y} dx={30} dy={-4} fill={stroke} textAnchor="middle" fontSize={16} fontWeight={900} >
          {value.toLocaleString()}
        </text>
      );
    }
  }