import {checkAPI} from "../api/api";
import {toast} from 'react-toastify';


const SET_ALL_INFO_FROM_CHECK = "SET_ALL_INFO_FROM_CHECK";
const SET_ALL_MODULES_FROM_CHECK = "SET_ALL_MODULES_FROM_CHECK";
const SET_COST_PRICE_ALL_PRODUCTS = "SET_COST_PRICE_ALL_PRODUCTS";
const SET_MESSAGES_PERSONAL_KP = "SET_MESSAGES_PERSONAL_KP";
const SET_LAST_ID_KP = "SET_LAST_ID_KP";
const SET_KP_INFO_BY_ID = "SET_KP_INFO_BY_ID";

let initialState = {
    allInfoFromCheck: [],
    allModulesFromCheck: [],
    costPriceAllProducts: [],
    messagesPersonalKp: [],
    lastIdKp: 0,
}

const CheckReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_INFO_FROM_CHECK:
            return {
                ...state,
                allInfoFromCheck: action.allInfoFromCheck
            }
        case SET_ALL_MODULES_FROM_CHECK:
            return {
                ...state,
                allModulesFromCheck: action.allModulesFromCheck
            }
        case SET_COST_PRICE_ALL_PRODUCTS:
            return {
                ...state,
                costPriceAllProducts: action.costPriceAllProducts
            }
        case SET_MESSAGES_PERSONAL_KP:
            return {
                ...state,
                messagesPersonalKp: action.data
            }
        case SET_LAST_ID_KP:
            return {
                ...state,
                lastIdKp: action.data
            }
        case SET_KP_INFO_BY_ID:
            return {
                ...state,
                kpInfoByID: action.data
            }
        default:
            return state
    }
}

export const setAllInfoFromCheck = (allInfoFromCheck) => ({type: SET_ALL_INFO_FROM_CHECK, allInfoFromCheck});
export const setAllModulsFromCheck = (allModulesFromCheck) => ({type: SET_ALL_MODULES_FROM_CHECK, allModulesFromCheck});
export const setCostPriceAllProducts = (costPriceAllProducts) => ({
    type: SET_COST_PRICE_ALL_PRODUCTS,
    costPriceAllProducts
});
export const setMessagesPersonalKp = (data) => ({type: SET_MESSAGES_PERSONAL_KP, data});
export const setLastIdKp = (data) => ({type: SET_LAST_ID_KP, data});
export const setKpInfoByID = (data) => ({type: SET_KP_INFO_BY_ID, data});

export const getAllInfoFromCheck = (idCheck, type) => {
    return (dispatch) => {
        checkAPI.getAllInfoFromCheck(idCheck, type)
            .then(data => {
                dispatch(setAllInfoFromCheck(data));
            });
    }
}
export const getAllModulsFromCheck = (idCheck, type) => {
    return (dispatch) => {
        checkAPI.getAllModulsFromCheck(idCheck, type)
            .then(data => {
                dispatch(setAllModulsFromCheck(data));
            });
    }
}
export const setNewCourseAndSumCheck = (array) => {
    return (dispatch) => {
        checkAPI.setNewCourseAndSumCheck(array)
            .then(data => {
                dispatch(getAllInfoFromCheck(array[0], array[3]));
            });
    }
}
export const getCostPriceAllProducts = () => {
    return (dispatch) => {
        checkAPI.getCostPriceAllProducts()
            .then(data => {
                dispatch(setCostPriceAllProducts(data));
            });
    }
}
export const setDaysManagerFromCheck = (idCheck, daysManager, type) => {
    return (dispatch) => {
        checkAPI.setDaysManagerFromCheck(idCheck, daysManager, type)
            .then(data => {
                dispatch(getAllInfoFromCheck(idCheck, type));
            });
    }
}
export const setPlanDateFromCheck = (idCheck, datePlan, type) => {
    return (dispatch) => {
        checkAPI.setPlanDateFromCheck(idCheck, datePlan, type)
            .then(data => {
                dispatch(getAllInfoFromCheck(idCheck, type));
            });
    }
}
export const setCommentForCheck = (idCheck, comment, type) => {
    return (dispatch) => {
        checkAPI.setCommentForCheck(comment)
            .then(data => {
                dispatch(getAllInfoFromCheck(idCheck, type));
            });
    }
}
export const setDontShowKp = (idCheck, showKp, type) => {
    return (dispatch) => {
        checkAPI.setDontShowKp(idCheck, showKp)
            .then(data => {
                dispatch(getAllInfoFromCheck(idCheck, type));
            });
    }
}
export const changeCollModulsInCheck = (idModuls, coll, idCheck, type) => {
    return (dispatch) => {
        checkAPI.changeCollModulsInCheck(idModuls, coll)
            .then(data => {
                dispatch(getAllModulsFromCheck(idCheck, type));
            });
    }
}
export const requestForKpStatus = (idCheck, type) => {
    return (dispatch) => {
        checkAPI.requestForKpStatus(idCheck, type)
            .then(data => {
                dispatch(getAllInfoFromCheck(idCheck, type));
            });
    }
}
export const getMessagesPersonalKp = (idCheck) => {
    return (dispatch) => {
        checkAPI.getMessagesPersonalKp(idCheck)
            .then(data => {
                dispatch(setMessagesPersonalKp(data));
            });
    }
}
export const setNewKpMessage = (array, idCheck) => {
    return (dispatch) => {
        checkAPI.setNewKpMessage(array)
            .then(data => {
                dispatch(getMessagesPersonalKp(idCheck));
            });
    }
}
export const getLastIdKp = (request) => {
    return (dispatch) => {
        checkAPI.getLastIdKp(request)
            .then(data => {
                dispatch(setLastIdKp(data?.data?.id));
            });
    }
}
export const getKpById = (request) => {
    return async (dispatch) => {
        return new Promise(async (resolve, reject) => {
            try {
                const data = await checkAPI.getKpById(request);

                if (data?.status === 1) {
                    toast.success("КП успешно загружено");
                    dispatch(setKpInfoByID(data?.data));
                    resolve(data);
                } else {
                    toast.error("Ошибка при загрузке КП. Попробуйте еще раз.");
                    console.log(data);
                    resolve([]);
                }
            } catch (error) {
                console.error("Error:", error);
                toast.error(
                    "Ошибка при загрузке КП. Попробуйте еще раз или сообщите Алексею. Выпишет люлей Владу."
                );
                reject(0);
            }
        });
    };
};
export const deleteCheck = (idCheck, type) => {
    return (dispatch) => {
        checkAPI.deleteCheck(idCheck, type)
            .then(data => {
                dispatch(getAllInfoFromCheck(idCheck, type));
            });
    }
}
export const addNewKp = (array) => {
    return (dispatch) => { // Use async function
        try {
            checkAPI.addNewKp(array)
                .then(data => {
                    if (data?.status === 1) {
                        toast.success("КП успешно создано");
                        return data; // КП добавлено
                    } else {
                        toast.error("Ошибка при создании КП. Попробуйте еще раз.");
                        toast.error(data?.message);
                        console.log(data);
                        return 0; // КП не добавлено
                    }
                })
        } catch (error) {
            console.error("Error:", error);
            toast.error("Ошибка при создании КП. Попробуйте еще раз или сообщите Алексею. Выпишет люлей Владу.");
            return 0;
        }
    };
};
export const editKp = (array) => {
    return async (dispatch) => { // Use async function
        try {
            const data = await checkAPI.editKp(array); // Use await to wait for the promise to resolve
            if (data?.status === 1) {
                toast.success("КП успешно сохранено");
                console.log(data);
                return data; // КП добавлено
            } else {
                toast.error("Ошибка при создании КП. Попробуйте еще раз.");
                toast.error(data?.message);
                console.log(data);
                return 0; // КП не добавлено
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("Ошибка при создании КП. Попробуйте еще раз или сообщите Алексею. Выпишет люлей Владу.");
            return 0;
        }
    };
};
export const updateKp = (array) => {
    return async (dispatch) => { // Use async function
        try {
            const data = await checkAPI.updateKp(array); // Use await to wait for the promise to resolve
            if (data?.status === 1) {
                toast.success("Успешно сохранено");
                console.log(data);
                return data; // КП добавлено
            } else {
                toast.error("Возникла ошибка. Попробуйте еще раз.");
                toast.error(data?.message);
                console.log(data);
                return 0; // КП не добавлено
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("Ошибка при изменении КП. Попробуйте еще раз или сообщите Алексею.");
            return 0;
        }
    };
};
export const deleteKp = (request) => {
    return async (dispatch) => { // Use async function
        try {
            const data = await checkAPI.deleteKp(request); // Use await to wait for the promise to resolve
            if (data?.status === 1) {
                toast.success("КП успешно удалено из черновиков.");
                return data; // КП удалено
            } else {
                toast.error("Ошибка при удалении КП. Возможно, оно уже было удалено. Если нет - сообщите Алексею.");
                toast.error(data?.message);
                console.log(data);
                return 0; // КП не добавлено
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("Ошибка при удалении КП. Попробуйте еще раз или сообщите Алексею. Выпишет люлей Владу.");
            return 0;
        }
    };
};



export default CheckReducer;

