import React, { useState } from 'react';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import MainSupplierList from './MainSupplierList/MainSupplierList';
import s from './Sales.module.css';
import { useCookies } from 'react-cookie';

const Sales = (props) => {
    let [showEmail, setShowEmail] = useState(false);
    let [mainSup, setMainSup] = useState([]);
    let [segment, setSegment] = useState(0);
    let [category, setCategory] = useState(0);
    const navigate = useNavigate();
    const [cookies] = useCookies();

    if (props.mainSuppliers && mainSup.length < 1) {
        let array = props.mainSuppliers.split(",");
        setMainSup(array);
    }

    const getCompanySegment = (e) => {
        setSegment(e.target.value);
        getCompany(e.target.value, category);
    };

    const getCompanyCategory = (e) => {
        setCategory(e.target.value);
        getCompany(segment, e.target.value);
    };

    const newArray = (segmentNow = segment, categoryNow = category) => {
        return [segmentNow, categoryNow, mainSup];
    };

    const getCompany = (segmentNow, categoryNow) => {
        let array = newArray(segmentNow, categoryNow);
        props.getCompanyInSegmentationCategories(array);
    };

    const getSegment = (id) => {
        return props.companySegmentation.map(p => (id == p.id ? p.name : null));
    };

    const getCategory = (id) => {
        return props.companyCategories.map(p => (id == p.id ? p.name : null));
    };

    const setCompanyNotActiveF = (id) => {
        let array = newArray();
        props.setCompanyNotActive(id, array);
    };

    const getHistoryForFiltr = (id) => {
        let check = checkMainSup(id);
        if (check !== -1) {
            mainSup.length <= 1 ? setMainSup([]) : mainSup.splice(check, 1);
        } else {
            mainSup.push(id);
            setMainSup(mainSup);
        }

        let eArray = mainSup.join();
        navigate(`/sales/${eArray}`);
        getCompany(segment, category);
    };

    const checkMainSup = (id) => {
        return mainSup.indexOf(id);
    };

    const getEmail = (companyId) => {
        return props.emailCompanyAgents.map(k => (k.company_id == companyId ? <tr key={k.email}><td>{k.email}</td></tr> : null));
    };

    return (
        <div>
            <div>
                {(cookies.id == 44 || cookies.id == 987) && (
                    <>
                        <NavLink to="/statistics">Статистика</NavLink> <Link to="/maps">Карта</Link> <Link to="/company">Компании</Link>
                    </>
                )}
            </div>
            <div className={s.mainFilter}>
                <div>
                    <h3>Выбрать Сегмент:</h3>
                    <div>
                        <select onChange={getCompanySegment}>
                            <option value="0">Все компании</option>
                            {props.companySegmentation.map((p, i) => (
                                <option value={p.id} key={`companySegmentation${i}`}>
                                    {p.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div>
                    <h3>Выбрать Отрасль:</h3>
                    <div>
                        <select onChange={getCompanyCategory}>
                            <option value="0">Все компании</option>
                            {props.companyCategories.map((p, i) => (
                                <option value={p.id} key={`companyCategories_${i}`}>
                                    {p.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <div>
                <input type="checkbox" onClick={() => setShowEmail(!showEmail)} />Показать все email
            </div>
            {!showEmail ? (
                <>
                    <h3>Список исключений</h3>
                    <div className={s.mainSupplier}>
                        {props.mainSupplier.map((k, i) => (
                            <div
                                key={`mainSupplier_${i}`}
                                onClick={() => getHistoryForFiltr(k.id)}
                                className={checkMainSup(k.id) !== -1 ? s.active : ''}
                            >
                                {k.supplier}
                            </div>
                        ))}
                    </div>
                    <h2 className={s.center}>Информация собрана за период начиная с апреля 2020 года</h2>
                    <div className={s.main + ' ' + s.bold}>
                        <div>Название</div>
                        <div>Сегмент</div>
                        <div>Отрасль</div>
                        <div>Модули</div>
                        <div>Поставщики</div>
                        <div>Действия</div>
                    </div>
                    {props.companyInSegmentCategory.length > 0 &&
                        props.companyInSegmentCategory.map((p, i) => (
                            <div className={s.main} key={`companyInSegmentCategory_${i}`}>
                                <div>
                                    <NavLink to={`/company/profile/${p.id}`} target="_blank">
                                        {p.name_with_akon}
                                    </NavLink>
                                </div>
                                <div>{getSegment(p.p_company_segmentation)}</div>
                                <div>{getCategory(p.p_client_segmentation)}</div>
                                <div>
                                    {props.moduls.map((k, index) =>
                                        p.id == k.company_id ? (
                                            <div className={s.mainFilter + ' ' + s.modulsList} key={`moduls_${index}`}>
                                                <div>{k.moduls_name}</div>
                                                <div>{k.sum_moduls_coll}</div>
                                            </div>
                                        ) : null
                                    )}
                                </div>
                                <div>
                                    <MainSupplierList id={p.id} supplierList={props.supplierList} />
                                </div>
                                <div>
                                    <input type="button" value="Не рабочая компания" onClick={() => setCompanyNotActiveF(p.id)} />
                                </div>
                            </div>
                        ))}
                </>
            ) : (
                <table>
                    {props.companyInSegmentCategory.length > 0 &&
                        props.companyInSegmentCategory.map((p, i) => getEmail(p.id))}
                </table>
            )}
        </div>
    );
};

export default Sales;
