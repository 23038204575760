import React, { useState } from 'react';
import main from '../../../Main.module.css';

const DeleteMeeting = (props) =>{
    const go = () =>{
        props.deleteMeeting(props.active, props.idManager)
        // console.log(props.active, dateReminder)
        props.setActive(false)
    }
    return(
        <div>
            <h3 className={main.center}>Удалить встречу №{props.active}</h3>
            <div className={main.center}><input type="button" value="Удалить" onClick={()=>go()} className={main.inputAdd} /></div>
        </div>
    )
}
export default DeleteMeeting;