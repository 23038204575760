import React from 'react';
import s from '../Repair.module.css';
import AnyFormatDate from '../../common/AnyFormatDate/AnyFormatDate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faCheck, faPencilAlt, faLink, faListUl} from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react';
import Modal from '../../common/Modal/Modal';
import { NavLink } from 'react-router-dom';
import AddWorker from '../Modal/AddWorker';
import AddModuleFromRepair from '../Modal/AddModuleFromRepair';
import ModulsListContainer from '../Modal/ModulsList/ModulsListContainer';
import main from '../../../Main.module.css'

const RepairTech = (props) =>{
    let [showAddWorker, setShowAddWorker] = useState(false)
    let [moduleFromRepair, setModuleFromRepair] = useState(false)
    let [modulsList, setModulsList] = useState(false)
    let [assurance, setAssurance] = useState(false)
    let [defects, setDefects] = useState(false)
    const setDefectsF = (id, defect)=>{
        setDefects(id)
        setNewDefects(defect)
    }
    let [newDefects, setNewDefects] = useState("")
    const setNewDefectsF = (e)=>{
        setNewDefects(e.target.value)
    }
    const setNewDefectsMain=()=>{
        let array = [];
        array.push(defects)
        array.push(newDefects)
        props.setNewDefects(array)
        setDefects(false)
        setNewDefects("")
    }
    const setAssuranceF=(assuranceType)=>{
        props.setAssurance(assurance, assuranceType)
        setAssurance(false)
    }
    const getDelay = (date)=>{
        let now = new Date();let day=now.getDate();let month = now.getUTCMonth()+1;
        if(Number(day)<10){
            day = "0"+day
        }
        if(Number(month)<10){
            month = "0"+month
        }
        now = now.getFullYear()+"-"+month+"-"+day
        if(date < now){
            return true
        }else{
            return false
        }
    }
    return(
        <div>
            {showAddWorker &&
                <Modal active={showAddWorker} setActive={setShowAddWorker}>
                    <AddWorker showAddWorker={showAddWorker} setNewWorkerInRepair={props.setNewWorkerInRepair} setShowAddWorker={setShowAddWorker}/>
                </Modal>
            }
            {moduleFromRepair &&
                <Modal active={moduleFromRepair} setActive={setModuleFromRepair}>
                    <AddModuleFromRepair id={moduleFromRepair} addModuleFromRepair={props.addModuleFromRepair} setShow={setModuleFromRepair}/>
                </Modal>
            }
            {modulsList &&
                <Modal active={modulsList} setActive={setModulsList}>
                    <ModulsListContainer id={modulsList} setShow={setModulsList}/>
                </Modal>
            }
            {assurance &&
                <Modal active={assurance} setActive={setAssurance}>
                    <h1>Гарантийный случай</h1>
                    <div className={s.twoBlocks}>
                        <div onClick={()=>setAssuranceF(1)}>Гарантия</div>
                        <div onClick={()=>setAssuranceF(2)}>Не гарантия</div>
                    </div>
                </Modal>
            }
            {defects &&
                <Modal active={defects} setActive={setDefects}>
                    <h1>Причина неисправности</h1>
                    <textarea onChange={setNewDefectsF} value={newDefects} className={s.textarea} />
                    <div className={s.button}><button onClick={()=>setNewDefectsMain()}>Привязать</button></div>
                </Modal>
            }
            <h1>Ремонтная вкладка тех. отдела</h1>
            <div><NavLink to="/repair">К общему списку</NavLink></div>
            <div className={s.main}>
                <div>#</div>
                <div>Дата окончания</div>
                <div>Ответственный</div>
                <div>Кол. в посылке</div>
                <div>Заявленая неисправность</div>
                <div>Информация</div>
                <div>Действия</div>
            </div>
            {props.repair.map(k=>{
                return(
                    k.date_close!=null && k.assurance==null&&
                    <div className={s.main} key={"repair"+k.id}>
                        <div>{k.id}</div>
                        <div><AnyFormatDate date={k.date_close}/>{getDelay(k.date_close) && <p className={main.red}>Просрочено</p>}</div>
                        <div>{k.responsibleName}<span className={s.pensil} onClick={()=>setShowAddWorker(k.id)}><FontAwesomeIcon icon={faPencilAlt}/></span></div>
                        <div>{k.coll} {k.countModuls!= k.coll && <span className={s.countModuls+" "+s.tooltip} data-tooltip="Доб. модули" onClick={()=>setModuleFromRepair(k.id)}>!</span>}</div>
                        <div>{k.defect}</div>
                        <div>
                            {props.personalRepairsList.map(j=>{
                                return(
                                    j.repairsFromSite_id == k.id && 
                                    <div className={s.personalRepairsList} key={"personalRepairsList_"+j.id}>
                                        <div>{j.moduls_name+"-"+j.nomenclatures+"("+j.serial_number+")"}</div>
                                        <div className={s.iconHover}>
                                            <div data-tooltip="Беда в чём?" className={s.tooltip} onClick={()=>setDefectsF(j.id, j.defects)}><span><FontAwesomeIcon icon={faListUl}/></span></div>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                        <div className={s.iconHover}>
                        {k.responsible!=null && k.countModuls== k.coll &&
                            <div data-tooltip="Гарантия" className={s.tooltip} onClick={()=>setAssurance(k.id)}><span><FontAwesomeIcon icon={faCheck}/></span></div>
                        }
                        </div>
                    </div>
                )
            })
                
            }
        </div>
        
    )
}
export default RepairTech;