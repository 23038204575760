import * as React from 'react';
import Avatar from '@mui/joy/Avatar';
import AvatarGroup from '@mui/joy/AvatarGroup';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {useState} from "react";
import {Switch, TextField} from "@mui/material";
import {faEdit, faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Modal from "../../../Production/common/Modal/Modal";
import main from "../../../Main.module.css";
import ReactPhoneNumberInput from "react-phone-number-input/input";
import 'react-phone-number-input/style.css'
import PhoneNumberFormatter from '../../common/PhoneFormatter/PhoneNumberFormatter';
import ButtonGroup from "@mui/material/ButtonGroup";
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import {toast} from 'react-toastify';

function normalizePhoneNumber(phoneNumber = '') {
    if (!phoneNumber || phoneNumber === '') return '';
    console.log('before:' + phoneNumber + '(' + phoneNumber.length + ')');

    let numericPhoneNumber = phoneNumber?.replace(/\D/g, '');
    if (numericPhoneNumber.startsWith('+38')) numericPhoneNumber = numericPhoneNumber.substr(3)
    if (numericPhoneNumber.startsWith('38')) numericPhoneNumber = numericPhoneNumber.substr(2)
    let formattedPhoneNumber =
        `+38 (${numericPhoneNumber.substr(0, 3)}) ` +
        `${numericPhoneNumber.substr(3, 3)}-${numericPhoneNumber.substr(6, 2)}-${numericPhoneNumber.substr(8)}`;
    console.log('after:' + formattedPhoneNumber + '(' + formattedPhoneNumber.length + ')');
    return formattedPhoneNumber;
}

function phoneFormatRules() {
    toast.info('Верный формат номера телефона: +38 (XXX) XXX-XX-XX или +38ХХХХХХХХХХ (21 или 19 знаков)');
}

export default function CompanyAgent(props) {

    //підписка на розсилку
    let [agentSubscribedVal, setAgentSubscribedVal] = useState(props?.agentSubscribed) //comment value

    const handleChange = (event) => { //subscription
        setAgentSubscribedVal(event.target.checked);
        console.log(event.target.checked ? 'email_active=true' : 'email_active=false')

        let request = {
            'contactID': props?.agentID,
            'email_active': event.target.checked ? 1 : null,
            'managerID': props?.currentManagerID,
            'companyID': props?.companyID,
        };
        props.setAgentSubscription(request)
    };

    //вікно редагування контакту
    let [deleteWindow, setDeleteWindow] = useState(false); //modal
    let [editWindow, setEditWindow] = useState(false); //modal
    const [name, setName] = React.useState(props?.agentName);
    const [email, setEmail] = React.useState(props?.agentEmail);
    const [vacancy, setVacancy] = React.useState(props?.agentVacancy);

    const [phone, setPhone] = React.useState(() => normalizePhoneNumber(props?.agentPhone) || '');
    const [phone2, setPhone2] = React.useState(() => normalizePhoneNumber(props?.agentPhone2) || '');
    const [phone3, setPhone3] = React.useState(() => normalizePhoneNumber(props?.agentPhone3) || '');

    const editAgentProfile = () => {
        let tell = '';
        let tell2 = '';
        let tell3 = '';
        let errCount = 0;
        if (phone == '') {
            tell = null
        } else {
            tell = normalizePhoneNumber(phone);
            if (tell.length !== 21 && tell.length !== 13 && tell.length !== 19){
                toast.error("Номер основного телефона введён не корректно. Символов:" + tell.length + " " + tell);
                errCount++;
            }
        }
        if (phone2 == '') {
            tell2 = null
        } else {
            tell2 = phone2.startsWith("+38") ? phone2 : normalizePhoneNumber(phone2);
            if (tell2.length !== 21 && tell2.length !== 13 && tell2.length !== 19) {
                toast.error("Номер телефона 2 введён не корректно. Символов:" + tell2.length);
                errCount++;
            }
        }
        if (phone3 == '') {
            tell3 = null
        } else {
            tell3 = phone.startsWith("+38") ? phone3 : normalizePhoneNumber(phone3);
            if (tell3.length !== 21 && tell3.length !== 13  && tell3.length !== 19) {
                toast.error("Номер телефона 3 введён не корректно. Символов:" + tell3.length);
                errCount++;
            }
        }
        if(errCount > 0) {
            phoneFormatRules()
            return;
        }

        let request = {
            'agentID': props?.agentID,
            'name': name,
            'position': vacancy,
            'email': email,
            'tell': phone != '' ? phone : null,
            'tell2': phone2 != '' ? phone2 : null,
            'tell3': phone3 != '' ? phone3 : null,
            'managerID': props?.currentManagerID
        };


        console.log(request)
        props.updateCompanyAgent(request)
        setEditWindow(false)
    }


    const deleteAgentProfile = () => {
        let request = {
            'agentID': props?.agentID,
            'id_manager': props?.currentManagerID,
        };
        props.deleteCompanyAgent(request)
        setDeleteWindow(false)
    }


    return (
        <>
            {editWindow &&
            <Modal active={editWindow} setActive={setEditWindow}>
                <h3 className={main.center}>Редактирование контакта</h3>
                <div>
                    <TextField
                        id="standard-basic"
                        label="Имя"
                        variant="standard"
                        value={name}
                        onChange={(event) => {
                            setName(event.target.value);
                        }}
                    />
                </div>
                <div>
                    <TextField
                        id="standard-basic"
                        label="Должность"
                        variant="standard"
                        value={vacancy}
                        onChange={(event) => {
                            setVacancy(event.target.value);
                        }}
                    />
                </div>
                <div>
                    <TextField
                        id="standard-basic"
                        label="email"
                        variant="standard"
                        value={email}
                        onChange={(event) => {
                            setEmail(event.target.value);
                        }}
                    />
                </div>

                <div>
                    <TextField
                        id="standard-basic"
                        label="Основной телефон"
                        variant="standard"
                        placeholder="+380123456789"
                        value={phone}
                        onChange={(event) => {
                            setPhone(event.target.value);
                        }}
                    />
                </div>
                <div>
                    <TextField
                        id="standard-basic"
                        label="Дополнительный телефон 1"
                        variant="standard"
                        placeholder="+380123456789"
                        value={phone2}
                        onChange={(event) => {
                            setPhone2(event.target.value);
                        }}
                    />
                </div>
                <div>
                    <TextField
                        id="standard-basic"
                        label="Дополнительный телефон 2"
                        variant="standard"
                        placeholder="+380123456789"
                        value={phone3}
                        onChange={(event) => {
                            setPhone3(event.target.value);
                        }}
                    />
                </div>

                <div className={main.center}>
                    <input
                        type="button"
                        id={'saveCompanyNameBtn'}
                        value="Сохранить"
                        onClick={() => editAgentProfile()}
                        className={main.inputAdd}
                    />
                </div>
            </Modal>
            }
            {deleteWindow &&
            <Modal active={deleteWindow} setActive={setDeleteWindow}>
                <h3 className={main.center}>Удаление контакта</h3>
                <div>
                    <Typography>Вы действительно хотите переместить контакт в архив?</Typography>
                </div>

                <div className={main.center}>
                    <input
                        type="button"
                        id={'saveCompanyNameBtn'}
                        value="Архивировать"
                        onClick={() => deleteAgentProfile()}
                        className={main.inputDelete}
                    />
                </div>
            </Modal>
            }
            <Card
                variant="outlined"
                sx={{
                    overflow: 'auto',
                    margin: '0 auto',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
                xs={6}
                sm={3}
                md={3}
                lg={3}
            >
                <Box
                    sx={{
                        width: '300px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    {name}
                    <ButtonGroup variant="contained" aria-label="outlined primary button group">
                        <FontAwesomeIcon
                            icon={faEdit}
                            color='green'
                            onClick={() => {
                                //setCompanyNameVal(company?.data?.name);
                                setEditWindow(1);
                            }}
                            pull="right"
                            style={{marginRight: '8px'}}
                        />
                        <FontAwesomeIcon
                            icon={faTrashAlt}
                            color='red'
                            onClick={() => {
                                //setCompanyNameVal(company?.data?.name);
                                setDeleteWindow(1);
                            }}
                            pull="right"
                        />
                    </ButtonGroup>

                </Box>
                <CardContent>

                    <Typography level="body-sm">
                        <b>Основной телефон: </b>{phone}<br/>
                        <b>email: </b>{props?.agentEmail}<br/>
                        <b>Должность: </b>{props?.agentVacancy}<br/>
                        {phone ? (<><b>Телефон&nbsp;&nbsp;: </b><PhoneNumberFormatter phoneNumber={phone}/><br/></>) : ''}
                        {phone2 ? (<><b>Доп. телефон 1: </b><PhoneNumberFormatter phoneNumber={phone2}/><br/></>) : ''}
                        {phone3 ? (<><b>доп. телефон 2: </b><PhoneNumberFormatter phoneNumber={phone3}/><br/></>) : ''}
                        <b>Рассылка: </b>
                        <Switch
                            checked={Boolean(agentSubscribedVal)}
                            onChange={handleChange}
                        />
                    </Typography>
                </CardContent>
            </Card>
        </>
    );

    /* buttons to use in the future. Place before card close tag
    <CardActions buttonFlex="0 1 120px">
                <IconButton variant="outlined" color="neutral" sx={{mr: 'auto'}}>
                    <FavoriteBorder/>
                </IconButton>
                <Button variant="outlined" color="neutral">
                    View
                </Button>
                <Button variant="solid" color="primary">
                    Join
                </Button>
            </CardActions>
     */
}
