import React, { useState } from 'react';
import s from './NomenclaturesName.module.css';
import Menu from '../Menu/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../../Production/common/Modal/Modal';
import ChangePosition from './Modal/ChangePosition';

const NomenclaturesName = (props) =>{
    let [showModal, setShowModal] = useState(false)
    return(
        <div>
            {showModal && 
            <Modal active={showModal} setActive={setShowModal}>
                <ChangePosition active={showModal} setActive={setShowModal} {...props}/>
            </Modal>
            }
            <Menu />
            <h2>Все каталожные позиции</h2>
            <FontAwesomeIcon icon={faPlus} onClick={()=>setShowModal("0")}/>
            <div className={s.hei}>
                <div>id</div>
                <div>Название</div>
                <div>Кол. каналов</div>
                <div>Описание росс</div>
                <div>Описание укр</div>
                <div></div>
            </div>
            {props.nomenclaturesName.map((k,index)=>{
                return(
                    <div key={"nomenclaturesName"+index} className={s.main}>
                        <div>{k.ID}</div>
                        <div>{k.NAME}</div>
                        <div className={s.collChannel}>
                            <div>мин.</div><div>{k.CHANNELSMIN}</div>
                            <div>стандарт</div><div>{k.CHANNELSDEFAULT}</div>
                            <div>макс.</div><div>{k.CHANNELSMAX}</div>
                        </div>
                        <div>{k.DESCRIPTION}</div>
                        <div>{k.DESCRIPTION_UA}</div>
                        <div><FontAwesomeIcon icon={faPen} onClick={()=>setShowModal(k.ID)}/></div>
                    </div>
                )
            })

            }
        </div>
    )
    
}
export default NomenclaturesName;