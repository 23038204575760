import { Button, Snackbar } from '@mui/material'
import { useState } from 'react'
import {faCopy} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toast} from "react-toastify";

const CopyToClipboardButton = (textToCopy) => {
    const [open, setOpen] = useState(false)
    const handleClick = () => {
        setOpen(true)
        navigator.clipboard.writeText(textToCopy.textToCopy)
        {toast.success('Скопировано')}
}

return (
    <>
        <Button onClick={handleClick}><FontAwesomeIcon icon={faCopy} color='grey'/></Button>

    </>
)
}

export default CopyToClipboardButton
