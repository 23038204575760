import React, { useState, useEffect } from 'react';
import s from './Future.module.css';
import main from '../../Main.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faTrash} from '@fortawesome/free-solid-svg-icons'

const Filter = (props) =>{
    let [allNomenclaturesData, setAllNomenclaturesData] = useState(props.allNomenclaturesDataMain)
    useEffect(() => {
        setAllNomenclaturesData(props.allNomenclaturesDataMain);
    }, [props.allNomenclaturesDataMain])
    //каталожные названия
    let [nameArray, setNameArray] = useState(props.nomenclaturesName)
    useEffect(() => {
        setNameArray(props.nomenclaturesName)
    }, [props.nomenclaturesName])
    //после выбора названия модуля, все остальные фильтры нужно сбросить
    const setNameArrayF = (e)=>{
        if(e.target.value==="0"){
            props.getAllNomenclaturesData("")
        }else{
            props.getAllNomenclaturesData(e.target.value)
        }
        setArrayMasive([])
    }
    //выбираем список параметров для каждого выпадающего списка
    const getListParams = (params)=>{
        let a = [];
        //проходим весь массив, смотрим на каждую цепочку каналов и если есть совпадение записываем в новый массив
        allNomenclaturesData.map((k) =>{
            k.channelsData.some((item)=>{a.push({...item});})
        })
        const uniqueTypes = a
        .map(item => item[params])
        .filter((value, index, self) => self.indexOf(value) === index);
        return uniqueTypes
    }
    //массив с фильтром
    let [arrayMasive, setArrayMasive] = useState([])
    //добавляем ещё одну цепочку для фильтра
    const addNewLineInArrayMasive = () =>{
        let newLine = {typeChannel:false, typeChannelList: getListParams("type"), typeSignal:false, typeSignalList: getListParams("signal_types"), ranges:false, rangesList: getListParams("ranges"), wire:false, wireList:getListParams("wire")}
        const newArrayMasive = [...arrayMasive, newLine];
        setArrayMasive(newArrayMasive);
    } 
    //после выбора фильтра изменяем все остальные списки в конкретной цепочке и фильтруем список
    const updateArrayMasive = (indexChange, fieldName, e) => {
        // Create a new array with the updated values
        let newArray = arrayMasive.map((item, index) => {
        //меняем значение, которое выбрали
        if(index === indexChange) {
            return {
            ...item,
            [fieldName]: e.target.value==="0" ? false : e.target.value
            };
            
        }
        // Leave all other items unchanged
        return item;
        });

        //выбираем остальные списки для каждого фильтра в цепочке
        newArray = newArray.map((item, index) => { 
        if (index === indexChange) {
            let data = []
            //выбираем все параметры, которые сейчас доступны
            props.allNomenclaturesDataMain.forEach(k=>{ data = [...data, ...k.channelsData];})
            if(item.typeChannel) data = data.filter(obj => obj.type === item.typeChannel);
            if(item.typeSignal) data = data.filter(obj => obj.signal_types === item.typeSignal);
            if(item.ranges) data = data.filter(obj => obj.ranges === item.ranges);
            if(item.wire) data = data.filter(obj => obj.wire === item.wire);
            let uniqueTypes = [];let uniqueSignalTypes = [];let uniqueRanges = [];let uniqueWires = [];
            uniqueTypes = [...new Set(data.map(k => k.type))]
            uniqueSignalTypes = [...new Set(data.map(item => item.signal_types))]
            uniqueRanges = [...new Set(data.map(item => item.ranges))]
            uniqueWires = [...new Set(data.map(item => item.wire))]
            return {
            ...item,
            typeChannelList: uniqueTypes,
            typeSignalList: uniqueSignalTypes,
            rangesList: uniqueRanges,
            wireList: uniqueWires,
            };
        }
        return item;
        })
        // Update the state array with the new array
        setArrayMasive(newArray);
        //фильтруем все позиции с новыми параметрами фильтра
        getFilter(newArray)
    };
    //удалить цепочку канала с фильтром
    const deleteChannel = (index)=>{
        arrayMasive.splice(index, 1);
        setArrayMasive(arrayMasive);
        getFilter(arrayMasive)
    }
    //копируем цепочку канала с фильтром
    const cloneChannel = (index)=>{
        const duplicatedObject = {...arrayMasive[index]};
        arrayMasive.splice(index + 1, 0, duplicatedObject);
        const newArrayMasive = [...arrayMasive];
        setArrayMasive(newArrayMasive);
        getFilter(newArrayMasive)
    }
    //из массива выбираем только полные совпадения
    const checkArrays=(arrayMain, arrayFilter)=>{
        const newArr = arrayMain.map(obj => {
            return {
                ...obj,
                find: false
            };
        });
        for (let filter of arrayFilter) {
          let found = false;
          for (let main of newArr) {
            if ((!filter.typeChannel || filter.typeChannel.includes(main.type)) &&
                (!filter.typeSignal || filter.typeSignal.includes(main.signal_types)) &&
                (!filter.ranges || filter.ranges.includes(main.ranges)) &&
                (!filter.wire || filter.wire.includes(main.wire)) && !main.find) {
              found = true;
              main.find = true
              break;
            }
          }
          if (!found) {
            return false;
          }
        }
        
        return true;
    }
    //записываем отфильтрованный массив в переменную для вывода пользователю
    const getFilter = (arrayMasiveNew)=>{
        const params = []
        allNomenclaturesData.map(k=>{
            if(checkArrays(k.channelsData, arrayMasiveNew)){
                params.push(k)
            }
        })
        props.setAllNomenclaturesDataWithFilter(params)
    }
    return(
        <div>
            <div className={s.twoBlocksFilter}>
                <div>
                    <select onChange={setNameArrayF} className={main.fs1rem+" "+main.input}>
                        <option value="0">Все модели</option>
                        {nameArray.map((k,index)=><option key={"NAME"+index} id={k.ID}>{k.NAME}</option>)}
                    </select>
                </div>
                <div className={main.right}><span className={s.addChannelFilter+" "+main.hover} onClick={()=>addNewLineInArrayMasive()}>Добавить канал</span></div>
            </div>
            <div>
                {arrayMasive && arrayMasive.map((k,index)=>{
                return(
                <div className={s.search} key={"arrayMasive"+index}>
                    <div>{index+1+" канал"}</div>
                    <div>
                        {k.typeChannel
                        ? 
                        <div className={s.filterx}>
                            <div className={s.filterxDiv}>{k.typeChannel}</div>
                            <span className={s.refresh} value="0" onClick={(e)=>updateArrayMasive(index, 'typeChannel', e)}>х</span>
                        </div> 
                        :
                        <select onChange={(e)=>updateArrayMasive(index, 'typeChannel', e)} value={k.typeChannel}>
                            <option value="0">Не выбран тип канала</option>
                            {k.typeChannelList.map((k,index)=><option key={"NAME"+index}>{k}</option>)}
                        </select>
                        }
                    </div>
                    <div>
                        {k.typeSignal
                        ? 
                        <div className={s.filterx}>
                            <div className={s.filterxDiv}>{k.typeSignal}</div>
                            <span className={s.refresh} value="0" onClick={(e)=>updateArrayMasive(index, 'typeSignal', e)}>х</span>
                        </div> 
                        :
                        <select onChange={(e)=>updateArrayMasive(index, 'typeSignal', e)} value={k.typeSignal}>
                            <option value="0">Не выбран тип сигнала</option>
                            {k.typeSignalList.map((k,index)=><option key={"NAME"+index}>{k}</option>)}
                        </select>
                        }
                    </div>
                    <div>
                        {k.ranges
                        ? 
                        <div className={s.filterx}>
                            <div className={s.filterxDiv}>{k.ranges}</div>
                            <span className={s.refresh} value="0" onClick={(e)=>updateArrayMasive(index, 'ranges', e)}>х</span>
                        </div> 
                        :
                        <select onChange={(e)=>updateArrayMasive(index, 'ranges', e)} value={k.ranges}>
                            <option value="0">Не выбран диапазон</option>
                            {k.rangesList.map((k,index)=><option key={"NAME"+index}>{k}</option>)}
                        </select>
                        }
                    </div>
                    <div>
                        {k.wire
                        ? 
                        <div className={s.filterx}>
                            <div className={s.filterxDiv}>{k.wire}</div>
                            <span className={s.refresh} value="0" onClick={(e)=>updateArrayMasive(index, 'wire', e)}>х</span>
                        </div> 
                        :
                        <select onChange={(e)=>updateArrayMasive(index, 'wire', e)} value={k.wire}>
                            <option value="0">Не выбрана схема подключения</option>
                            {k.wireList.map((k,index)=><option key={"NAME"+index}>{k}</option>)}
                        </select>
                        }
                    </div>
                    <div><span className={s.refresh} onClick={()=>cloneChannel(index)}><FontAwesomeIcon icon={faCopy} className={main.green} /></span></div>
                    <div><span className={s.refresh} onClick={()=>deleteChannel(index)}><FontAwesomeIcon icon={faTrash} /></span></div>
                </div>
                )})
                }
            </div>
        </div>
    )

    
    
    
}
export default Filter;