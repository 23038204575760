import React from 'react';
import main from '../../../Main.module.css';

const AreYouSure = (props) => {
    const go = () => {
        props.setDateInSalesCheck(props.id, 1);
        props.setActive(false);
    };

    return (
        <div>
            <div className={main.center}><h3>Проводим расходную?</h3></div>
            <div><p>Счёт {props.name}</p></div>
            <div className={main.inputAdd} onClick={() => go()}>Да</div>
        </div>
    );
};

export default AreYouSure;
