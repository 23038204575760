import React from "react";
import { useState } from "react";
import main from '../../../../Main.module.css'

const AddAgents = (props)=>{
    let [fio, setFio] = useState("")
    const setFioF = (e)=>setFio(e.target.value)
    let [email, setEmail] = useState("")
    const setEmailF = (e)=>setEmail(e.target.value)
    let [tell, setTell] = useState("")
    const setTellF = (e)=>setTell(e.target.value)
    let [position, setPosition] = useState("")
    const setPositionF = (e)=>setPosition(e.target.value)
    const go=()=>{
        let array = []
        array.push(fio)
        array.push(email)
        array.push(tell)
        array.push(position)
        array.push(props.companyId)
        props.setNewCompanyAgent(array, props.companyId)
        back()
    }
    const back = ()=>{
        props.setActive(false)
        props.setAddMessage && props.setAddMessage(true)
    }
    return(
        <div>
            <h2 className={main.center}>Добавить сотрудника</h2>
            <div className={main.twoBlocksGridOneOfTwo}>
                <div className={main.p20+" "+main.bold}>ФИО</div>
                <div><input type="text" value={fio} onChange={setFioF} className={main.input} /></div>
            </div>
            <div className={main.twoBlocksGridOneOfTwo}>
                <div className={main.p20+" "+main.bold}>Email</div>
                <div><input type="text" value={email} onChange={setEmailF} className={main.input}/></div>
            </div>
            <div className={main.twoBlocksGridOneOfTwo}>
                <div className={main.p20+" "+main.bold}>Телефон</div>
                <div><input type="text" value={tell} onChange={setTellF} className={main.input}/></div>
            </div>
            <div className={main.twoBlocksGridOneOfTwo}>
                <div className={main.p20+" "+main.bold}>Должность</div>
                <div><input type="text" value={position} onChange={setPositionF} className={main.input}/></div>
            </div>
            {props.setAddMessage 
            ?
            <div className={main.twoBlocksGrid}>
                <div className={main.center}><input type="button" value="Назад" className={main.inputAdd+" "+main.orangeBg} onClick={()=>back()}/></div>
                <div className={main.center}><input type="button" value="Записать" className={main.inputAdd} onClick={()=>go()}/></div>
            </div>
            : <div className={main.center}><input type="button" value="Записать" className={main.inputAdd} onClick={()=>go()}/></div>}
            
        </div>
    )
} 
export default AddAgents;