import React, { useState } from 'react';
import main from '../../../Main.module.css';

const ChangeMeetingText = (props) =>{
    let [text, setText] = useState("")
    const setTextF = (e) => setText(e.target.value)
    const go = () =>{
        props.changeMeetingText(props.active, text, props.idManager)
        // console.log(props.active, dateReminder)
        props.setActive(false)
    }
    return(
        <div>
            <h3 className={main.center}>Изменить задачу на встречу</h3>
            <div><textarea className={main.textarea} value={text} onChange={setTextF}/></div>
            <div className={main.center}><input type="button" value="Изменить" onClick={()=>go()} className={main.inputAdd} /></div>
        </div>
    )
}
export default ChangeMeetingText;