import React, {useState} from 'react';
import s from './ModulesWithComponent.module.css';

const ModulesWithComponent = (props) =>{

    //sort modulsWithComponent by nomenclatures
    props.modulsWithComponent.sort((a,b)=>{
        if(a.nomenclatures > b.nomenclatures){
            return 1;
        }else{
            return -1;
        }
    })

    return(
    <div>
        {/*<h1>{props.modulsWithComponent.length > 0 && props.modulsWithComponent[0].storage_component_name+" используют такие модули:"}</h1>
        */}
        <div className={s.main+" "+s.header}>
            <div>Конфигурация</div>
            <div>Шт. в этом модуле</div>
        </div>
        {
            props.modulsWithComponent.map(p=>{
                return(
                    <div key={p.id} className={s.main}>
                        <div>{p.name+"-"+p.nomenclatures}</div>
                        <div>{p.coll}</div>
                    </div>
                ) 
            })
        }
    </div>)
}
export default ModulesWithComponent;