import React, { useState } from 'react';
import s from './FiltrWants.module.css';
import { useCookies } from 'react-cookie';

const FiltrBlocks = (props) =>{
    const [cookies, setCookie] = useCookies();
    let [text, setText] = useState("");
    const setTextF = (e)=>{
        setText(e.target.value)
    }
    const addNewFiltrBlockF = ()=>{
        let array = [];
        array.push(text)
        array.push(cookies.id)
        props.addNewFiltrBlock(array, cookies.id)
        props.showModal(false)
    }
    return(
        <div className={s.center}>
            <h1>Добавление нового блока</h1>
            <div><input type="text" value={text} onChange={setTextF} placeholder="Название блока" className={s.input} /></div>
            <div><input type="button" onClick={()=>addNewFiltrBlockF()} value="Добавить новый блок" className={s.button}/></div>
        </div>
        
    )
}
export default (FiltrBlocks);