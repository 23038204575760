import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
    getSumAndNameSubetriesForMonth,
    getSumPaidCostsForMonth,
    getAllPaidCostsListForMonthFromSubetries,
    getSumAndNameArticlesForMonth
} from '../../../../redux/admin-reducer';
import CostsList from './CostsList';
import { useParams } from 'react-router-dom';

class CostsListContainer extends React.Component {
    componentDidMount() {
        const { month, year } = this.props.params;
        this.props.getAllPaidCostsListForMonthFromSubetries(month, year);
    }

    componentDidUpdate(prevProps) {
        const { month, year } = this.props.params;
        if (prevProps.params.month !== month || prevProps.params.year !== year) {
            this.props.getAllPaidCostsListForMonthFromSubetries(month, year);
        }
    }

    render() {
        return (
            <div>
                <CostsList
                    checkCostsList={this.props.checkCostsList}
                    sumCosts={this.props.sumCosts}
                    subentries={this.props.subentries}
                    month={this.props.params.month}
                    articles={this.props.articles}
                    getSumAndNameSubetriesForMonth={this.props.getSumAndNameSubetriesForMonth}
                    year={this.props.params.year}
                />
            </div>
        );
    }
}

let mapStateToProps = (state) => ({
    checkCostsList: state.admin.checkCostsList,
    // sumCosts: state.admin.sumCosts,
    // subentries: state.admin.subentries,
    // articles: state.admin.articles
});

// Custom withRouter replacement using useParams
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const params = useParams();
        return <Component {...props} params={params} />;
    }

    return ComponentWithRouterProp;
}

export default compose(
    connect(mapStateToProps, {
        getSumAndNameSubetriesForMonth,
        getSumPaidCostsForMonth,
        getAllPaidCostsListForMonthFromSubetries,
        getSumAndNameArticlesForMonth
    }),
    withRouter
)(CostsListContainer);
