import React, { useState } from 'react';
import s from '../../Tech/Tech.module.css';
import { NavLink } from 'react-router-dom';

const StatisticsMenu = (props) =>{
    return(
        <div>
            <h2 className={s.title}>Разнообразная статистика по счетам/изделиям/отраслям</h2>
            <div className={s.main}>
                <NavLink to={"/statisticsModuls/averageValue"}><div className={s.blueBack}><div>Среднее значение в счетах</div></div></NavLink>
                <NavLink to={"/statistics"} ><div className={s.blueMarketing}><div>Табличные значения</div></div></NavLink>
                {/* <NavLink to={"/tech/tasks"}><div className={s.greenBack}><div>Задачник</div></div></NavLink>
                <NavLink to={"/add_specifications"}><div className={s.yellowBack}><div>BOM</div></div></NavLink>
                <NavLink to={"/tech/techStorage"}><div className={s.blueMarketing}><div>Склад</div></div></NavLink> */}
            </div>
        </div>
    )
}
export default StatisticsMenu;