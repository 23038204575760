import React from 'react';
import Engineer from './Engineer';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom'; // Use useParams instead of withRouter
import {
    getWorkersModuls,
    getPersonalWorkersModuls,
    setModulsStatus,
    cancelAssembly,
    changeAssembler,
    setModulsStatusNew
} from '../../../redux/worker-reducer';
import { getWorkersList } from '../../../redux/productionMain-reducer';

class EngineerContainer extends React.Component {
    componentDidMount() {
        this.props.getWorkersModuls();
        this.props.getWorkersList();
    }

    filterModulesByWorkerID = (workerId) => {
        if (workerId === undefined) return this.props.workersModuls;
        console.log(this.props.workersModuls);
        return this.props.workersModuls.filter((modul) => modul.who_make == workerId);
    };

    getWorkerModules = (workerId) => {
        return this.filterModulesByWorkerID(workerId);
    };

    getUniqueWhoMakeWithModulesCount = () => {
        let uniqueWhoMake = [];
        this.props.workersModuls.forEach((modul) => {
            if (!uniqueWhoMake.includes(modul.who_make)) {
                uniqueWhoMake.push(modul.who_make);
            }
        });
        return uniqueWhoMake;
    };

    render() {
        return (
            <Engineer
                uniqueWhomake={this.getUniqueWhoMakeWithModulesCount()}
                workers={this.props.workers}
                workersModuls={this.getWorkerModules(this.props.params.workerId)}
                rawWorkersModuls={this.getWorkerModules()}
                workerId={this.props.params.workerId} // Use params injected by the HOC
                setModulsStatus={this.props.setModulsStatus}
                setModulsStatusNew={this.props.setModulsStatusNew}
                cancelAssembly={this.props.cancelAssembly}
                changeAssembler={this.props.changeAssembler}
            />
        );
    }
}

// Custom HOC to pass route params into class component
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const params = useParams(); // Get route params using useParams hook
        return <Component {...props} params={params} />;
    }

    return ComponentWithRouterProp;
}

let mapStateToProps = (state) => {
    return {
        workers: state.productionMain.workers,
        workersModuls: state.worker.workersModuls
    };
};

export default compose(
    connect(mapStateToProps, {
        getWorkersModuls,
        getPersonalWorkersModuls,
        setModulsStatus,
        cancelAssembly,
        changeAssembler,
        setModulsStatusNew,
        getWorkersList
    }),
    withRouter
)(EngineerContainer);
