import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import s from '../Task.module.css';

const AddTask = (props) => {
    const [cookies] = useCookies();
    let [newWorkers, setNewWorkers] = useState(0);
    let [textTask, setTextTask] = useState("");
    let [dateReminder, setDateReminder] = useState("");

    const setNewWorkersF = (e) => {
        setNewWorkers(e.target.value);
    };

    const setTextTaskF = (e) => {
        setTextTask(e.target.value);
    };

    const setDateReminderF = (e) => {
        setDateReminder(e.target.value);
    };

    const setNewTaskF = () => {
        if (textTask !== "") {
            let array = [];
            let worker = props.workerList.find(k => Number(k.login_id) === Number(cookies.id)).id;
            array.push(textTask, newWorkers, worker, props.mainTask, dateReminder);
            props.setNewTask(array, cookies.id);
            setTextTask("");
            props.setShowTask(false);
        }
    };

    return (
        <div className={s.newTask}>
            {props.mainTask === 0 ? <h2>Новая задача</h2> : <h2>Подзадача для задачи №{props.mainTask}</h2>}
            <div className={s.twoBlock}>
                <div>Выбрать сотрудника: </div>
                <div>
                    <select onChange={setNewWorkersF}>
                        <option value="0">Не выбран</option>
                        {props.workerList.map(k => <option value={k.id} key={k.id}>{k.name}</option>)}
                    </select>
                </div>
                <div className={s.mt10}>Напомнить: </div>
                <div><input type="date" className={s.input} value={dateReminder} onChange={setDateReminderF} /></div>
            </div>
            <div><textarea rows="2" value={textTask} onChange={setTextTaskF}></textarea></div>
            <div><button onClick={setNewTaskF}>Добавить задачу</button></div>
        </div>
    );
};

export default AddTask;
