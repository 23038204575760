import React, { useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import s from './ShipmentStatistics.module.css';

const ShipmentStatistics = (props) => {
    const { year } = useParams(); // Access route params
    const navigate = useNavigate(); // Use navigate instead of history

    // Total module count
    const mainColl = props.moduleSalesStatistics.reduce((p, c) => p + Number(c.sum_coll), 0);

    // Filter nomenclatures and compute counts
    let coll = 0;
    let collNo = 0;
    let nomCollArray = [];

    props.moduleSalesStatistics.forEach((k) => {
        if (props.nomenclatures.includes(k.nomenclatures_id)) {
            coll += Number(k.sum_coll);
            nomCollArray.push(k);
        } else {
            collNo += Number(k.sum_coll);
        }
    });

    // Filter components by matched nomenclatures and multiply by module count
    let componentNomenclatures = props.allComponentFromNomenclatures.filter(k =>
        nomCollArray.some(i => i.nomenclatures_id === k.nomenclatures)
    ).map(k => {
        const moduleColl = nomCollArray.find(i => i.nomenclatures_id === k.nomenclatures).sum_coll;
        return { ...k, coll: k.coll * moduleColl };
    });

    // Aggregate components by storage_component_id
    let componentMain = [];
    componentNomenclatures.forEach(k => {
        const existingComponent = componentMain.find(i => i.storage_component_id === k.storage_component_id);
        if (existingComponent) {
            existingComponent.coll += k.coll;
        } else {
            componentMain.push({ storage_component_id: k.storage_component_id, coll: k.coll });
        }
    });

    // Add price and calculate total spent
    let componentMainPrice = componentMain.map(k => {
        const componentPrice = props.storageAndAveragePrice.find(j => j.id === k.storage_component_id);
        return componentPrice
            ? {
                ...k,
                name: componentPrice.specification_id,
                sum: Math.round(k.coll * componentPrice.price)
            }
            : null;
    }).filter(Boolean);

    // Sort components by total sum spent
    componentMainPrice.sort((a, b) => b.sum - a.sum);

    // Calculate the total sum
    const mainSum = componentMainPrice.reduce((p, c) => p + c.sum, 0);

    const getAllComponentsFromStorage = (idComponent) => {
        const component = props.allComponentsFromStorage.find(k => k.id === idComponent);
        return component ? component.coll : 0;
    };

    return (
        <div>
            <div className={s.mainYear}>
                <NavLink to="/admin/shipmentStatistics/0"><div>Все</div></NavLink>
                <NavLink to="/admin/shipmentStatistics/2020"><div>2020</div></NavLink>
                <NavLink to="/admin/shipmentStatistics/2021"><div>2021</div></NavLink>
                <NavLink to="/admin/shipmentStatistics/2022"><div>2022</div></NavLink>
            </div>
            <div>Всего модулей {mainColl}</div>
            <div>Модулей посчитано {coll} - не посчитано {collNo}</div>
            <div>Всего сумма {mainSum.toLocaleString()} грн</div>
            <div className={s.main + " " + s.weight}>
                <div>ID компонента</div>
                <div>Название компонента</div>
                <div>Потрачено шт. за год</div>
                <div>Остатки шт.</div>
                <div>Цена грн без НДС потрачено за год</div>
            </div>
            {componentMainPrice.map((k, index) => (
                <div key={`moduleSalesStatistics_${index}`} className={s.main}>
                    <div>{k.storage_component_id}</div>
                    <div><NavLink to={`/suppliers/${k.storage_component_id}`} target="_blank">{k.name}</NavLink></div>
                    <div>{k.coll}</div>
                    <div>{getAllComponentsFromStorage(k.storage_component_id)}</div>
                    <div>{k.sum.toLocaleString()}</div>
                </div>
            ))}
        </div>
    );
};

export default ShipmentStatistics;
