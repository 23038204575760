import React from 'react';
import { useState } from 'react';
import s from './CheckTest.module.css';
import AnyFormatDate from '../../Production/common/AnyFormatDate/AnyFormatDate'


const CheckTest = (props) =>{
    return(
        <div>
            <h2>Модули на тестах</h2>
            <div className={s.main+" "+s.mainSection}>
                <div>#</div>
                <div>Название компании</div>
                <div>Модули на тестах</div>
                <div>Дата возврата/ТТН</div>
                <div>Действия</div>
            </div>
            {props.checkTest.map(k=>{
                return(
                    <div className={s.main} key={k.id}>
                        <div>{k.id}</div>
                        <div>{k.name_with_akon}</div>
                        <div>
                        {props.checkTestModuls.map((j,index)=>{
                            if(j.id_invoice==k.id) 
                                return(
                                    <div className={s.mainTwoBlocks} key={"checkTestModuls_"+index}>
                                        <div>{j.moduls_name}</div>
                                        <div>{j.moduls_coll}</div>
                                    </div>
                                )
                        })
                        }
                        </div>
                        <div>
                            <div><AnyFormatDate date={k.date_reminder} /></div>
                            <div>ТТН: {k.TTN}</div>
                        </div>
                        <div className={s.active}>
                            <div onClick={()=>props.getReturnModulsTest(k.id)}>Получил</div>
                        </div>
                    </div>
                )
            })
                
            }
        </div>
        
    )
}
export default CheckTest;