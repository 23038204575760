import React from 'react';
import { connect } from 'react-redux';
import {getTechStorage} from '../../../redux/addStorage-reducer';
import TechStorage from './TechStorage';

class TechStorageContainer extends React.Component{
    
    componentDidMount(){
        this.props.getTechStorage();
    }
    componentDidUpdate(prevProps){
        // if(prevProps.tasksComment!=this.props.tasksComment){
        //     this.props.getTasksComment();
        // }
    }
    
    render(){
        return <TechStorage techStorage={this.props.techStorage}/>
    } 
}

let mapStateToProps = (state) =>({
    techStorage: state.addStorage.techStorage,
})
export default connect( mapStateToProps, {getTechStorage})(TechStorageContainer);