import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getAllComponentsFromAllPaidNomenclatures, getMoneyDelivery } from '../../../redux/admin-reducer';
import DDC from './DDC';

class DDCContainer extends React.Component {
    componentDidMount() {
        this.props.getAllComponentsFromAllPaidNomenclatures();
        this.props.getMoneyDelivery();
    }

    componentDidUpdate(prevProps) {
        // Any logic you want in componentDidUpdate can go here
    }

    render() {
        const { month } = this.props.params; // Use params from props
        return (
            <div>
                {!month && <Navigate to="/admin/ddc/2024/1" />} {/* Use Navigate instead of Redirect */}
                <DDC
                    components={this.props.components}
                    sumCosts={this.props.sumCosts}
                    sum={this.props.sum}
                    sumRu={this.props.sumRu}
                    sumMoneyDelivery={this.props.sumMoneyDelivery}
                />
            </div>
        );
    }
}

// Hook to inject params into class component
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let params = useParams();
        return <Component {...props} params={params} />;
    }

    return ComponentWithRouterProp;
}

let mapStateToProps = (state) => ({
    articles: state.admin.articles,
    sumCosts: state.admin.sumCosts,
    sum: state.admin.sum,
    sumRu: state.admin.sumRu,
    sumMoneyDelivery: state.admin.sumMoneyDelivery,
});

export default compose(
    connect(mapStateToProps, { getAllComponentsFromAllPaidNomenclatures, getMoneyDelivery }),
    withRouter
)(DDCContainer);
