import React from "react";
import { useState } from "react";
import main from '../../../Main.module.css'

const Settings = (props)=>{
    let [project, setProject] = useState("")
    const setProjectF = (e)=>{setProject(e.target.options[e.target.selectedIndex].id)}
    const bindProject = ()=>{
        console.log(project)
    }
    return(
        <div>
            <h1>Настройки и привязки</h1>
            <h3>Привязка к проекту</h3>
            <select className={main.input} onChange={setProjectF}>
            <option id="0">Нет привязки</option>
            {props.allProject.map(k=><option id={k.id} key={"allProject"+k.id}>{k.name}</option>)}
            </select>
            <div className={main.center}><input type="button" value="Привязать" onClick={()=>bindProject()} className={main.inputAdd} /></div>
        </div>
    )
} 
export default Settings;