import React, {useState, useEffect} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Button,
    Collapse,
    Box,
    Tooltip
} from '@mui/material';
import {KeyboardArrowDown, KeyboardArrowUp, ArrowDropUp, ArrowDropDown} from '@mui/icons-material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faArrowDown, faCalendar, faExclamation} from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import {NavLink, useParams} from 'react-router-dom';
import main from '../../Main.module.css';
import Modal from '../../Production/common/Modal/Modal';
import SetCompanyToPlanner from './Modal/SetCompanyToPlanner';
import AnyFormatDate from '../../Production/common/AnyFormatDate/AnyFormatDate';
import {salesAPI} from '../../../api/api';
import CurrencyFormatter from '../common/CurrencyFormatter/CurrencyFormatter';
import MenuManager from "../common/MenuManager/MenuManager";
import {toast} from "react-toastify";
import Paper from "@mui/material/Paper";

const AllCompanyWithManagerNew = () => {
    const [allData, setAllData] = useState([]);
    const [expanded, setExpanded] = useState({});
    const [planner, setPlanner] = useState(false);
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);
    const [personalId, setPersonalId] = useState(null);
    const [sortIcon, setSortIcon] = useState('sum');
    const [filteredData, setFilteredData] = useState([]);

    const {manager} = useParams();

    useEffect(() => {
        const fetchData = async (id) => {
            try {
                await salesAPI.getNewCompanyYearlyStats(id).then((response) => {
                    sortByKey('sum', response)
                });
            } catch (error) {
                toast.error('Не удалось загрузить данные: ' + error.message)
            }
        };

        const isAdmin = !!Cookies.get('adminID');
        let currentPersonalId = manager;

        if (!isAdmin && Cookies.get('id') !== currentPersonalId) {
            currentPersonalId = Cookies.get('id');
        }

        setPersonalId(currentPersonalId);
        fetchData(currentPersonalId);
    }, [manager]);


    const handleExpandClick = (id) => {
        setExpanded((prev) => ({...prev, [id]: !prev[id]}));
    };

    const getClass = (sum) => {
        sum = Number(sum);
        if (1000000 <= sum) return main.red;
        if (400000 <= sum) return main.blue;
        if (100000 <= sum) return main.green;
        return '';
    };

    const getYearlySum = (sums, year) => {
        if (!sums || !Array.isArray(sums)) return 0;
        const sumObj = sums.find((s) => s.year === year);
        return Math.round(sumObj ? sumObj.sum : 0);
    };

    const calculateGrowth = (current, previous) => {
        if (previous === 0 || current === null || previous === null) return null;
        return Math.round(((current - previous) / (current > previous ? current : previous)) * 100);
    };

    const calculateGroupSum = (company) => {
        if (!company) return 0;
        const mainCompanySum = (company.sum || []).reduce((acc, cur) => acc + (cur.sum || 0), 0);
        const childCompaniesSum = (company.companies || []).reduce((acc, childCompany) => acc + (childCompany.sum || []).reduce((childAcc, cur) => childAcc + (cur.sum || 0), 0), 0);
        return Math.round(mainCompanySum + childCompaniesSum);
    };

    const calculateGroupYearlySum = (company, year) => {
        if (!company) return null;
        const mainCompanySum = getYearlySum(company.sum, year) || 0;
        const childCompaniesSum = (company.companies || []).reduce((acc, childCompany) => acc + (getYearlySum(childCompany.sum, year) || 0), 0);
        let sum = Math.round(mainCompanySum + childCompaniesSum);
        return sum > 0 ? sum : null;
    };

    const sortByKey = (key, data = null) => {
        if (data) {
            setAllData(data);
            setFilteredData(data);
        }
        const companySums = (data ?? filteredData).map(company => ({
            company,
            sum: key === 'sum' ? calculateGroupSum(company) : calculateGroupYearlySum(company, key)
        }));

        companySums.sort((a, b) => b.sum - a.sum);

        const sorted = companySums.map(item => item.company);

        setFilteredData(sorted);
        setSortIcon(key);
    };

    const getPlannerDates = (company) => {
        if (!company || !company.companies) return company.checkToPlanner ?
            <AnyFormatDate date={company.date_plan}/> : null;
        const dates = company.companies
            .filter(child => child.date_plan)
            .filter(child => child.checkToPlanner)
            .map(child => <><FontAwesomeIcon color='grey' icon={faCalendar}/> <AnyFormatDate key={child.id}
                                                                                            date={child.date_plan}/>
                <br/></>);
        return dates.length ? dates : null;
    };

    return (
        <div>
            {planner && (
                <Modal active={planner} setActive={setPlanner}>
                    <SetCompanyToPlanner
                        active={selectedCompanyId ?? null}
                        setActive={setPlanner}
                        setCompanyToPlanner={salesAPI.setCompanyToPlanner}
                        personalId={personalId}
                        countCompanyPlanerInThisDate="0"
                        getAllCompanyPlanerInThisDate={salesAPI.getAllCompanyPlanerInThisDate}
                    />
                </Modal>
            )}
            <Box display="flex" justifyContent="flex-end" marginBottom={2}>
                <Button component={NavLink} to={`/sales/allCompany/${Cookies.get('id')}`} sx={{marginRight: 1}}>
                    Старый вариант странички :(
                </Button>
                <Button component={NavLink} to={`/sales/meeting/${Cookies.get('id')}`} sx={{marginRight: 1}}>
                    Запланировать встречу
                </Button>
                <Button component={NavLink} to={`/kp/${Cookies.get('id')}`} sx={{marginRight: 1}}>
                    Планировщик
                </Button>
            </Box>
            <h1>Страница всех закрепленных компаний за менеджером</h1>
            {
                (Cookies.get('id') == "44" || Cookies.get('id') == "34" || Cookies.get('adminID') || Cookies.get('id') == 1)
                &&
                <><MenuManager link={"/sales/allCompanyNew"} idNow={personalId}
                               showAll={true}/><br/></>
            }
            <TableContainer component={Paper} sx={{ overflowX: 'initial' }}>
                <Table size="small" stickyHeader>
                    <TableHead sx={{
                        "& th": {
                            color: "rgba(96, 96, 96)",
                            fontStyle: "bold",
                            fontSize: "1.1rem",
                            background: "#d2d2d2"
                        }
                    }}>
                        <TableRow>
                            <TableCell><h4>#</h4></TableCell>
                            <TableCell sx={{width: '30%'}}><h4>Название</h4></TableCell>
                            <TableCell><h4>Планировщик</h4></TableCell>
                            <TableCell>
                                <span onClick={() => sortByKey(2022)} className={main.sort}>
                                    <h4>2022 {parseInt(sortIcon) === 2022 ?
                                        <FontAwesomeIcon color='green' icon={faArrowDown}/> :
                                        <FontAwesomeIcon color='blue' icon={faArrowDown}/>}</h4>
                                </span>
                            </TableCell>
                            <TableCell>
                                <span onClick={() => sortByKey(2023)} className={main.sort}>
                                    <h4>2023 {parseInt(sortIcon) === 2023 ?
                                        <FontAwesomeIcon color='green' icon={faArrowDown}/> :
                                        <FontAwesomeIcon color='blue' icon={faArrowDown}/>}</h4>
                                </span>
                            </TableCell>
                            <TableCell>
                                <span onClick={() => sortByKey(2024)} className={main.sort}>
                                    <h4>2024 {parseInt(sortIcon) === 2024 ?
                                        <FontAwesomeIcon color='green' icon={faArrowDown}/> :
                                        <FontAwesomeIcon color='blue' icon={faArrowDown}/>}</h4>
                                </span>
                            </TableCell>
                            <TableCell>
                                <span onClick={() => sortByKey('sum')} className={main.sort}>
                                    <h4>Сумма за все время {sortIcon === 'sum' ?
                                        <FontAwesomeIcon color='green' icon={faArrowDown}/> :
                                        <FontAwesomeIcon color='blue' icon={faArrowDown}/>}</h4>
                                </span>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.map((company, index) => (
                            <React.Fragment key={company.id}>
                                <TableRow>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>
                                        {company.companies && company.companies.length > 0 ? (
                                            <IconButton onClick={() => handleExpandClick(company.id)}>
                                                {expanded[company.id] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                            </IconButton>
                                        ) : null}
                                        <NavLink to={`/company/profile/${company.id}`} target="_blank" rel="noopener noreferrer">
                                            {company.show_warning && (
                                                <FontAwesomeIcon icon={faExclamation} color="red" style={{ marginRight: 5 }} />
                                            )}
                                            {company.companies && company.companies.length > 0 ? `Группа компаний ${company.name_with_akon}` : company.name_with_akon}
                                        </NavLink>
                                    </TableCell>

                                    <TableCell>
                                        {company.companies && company.companies.length > 0 ? (
                                            getPlannerDates(company)
                                        ) : (
                                            company.checkToPlanner == null
                                                ? <FontAwesomeIcon color='green' icon={faPlus} onClick={() => {
                                                    setPlanner(true);
                                                    setSelectedCompanyId(company.id);
                                                }}/>
                                                : <span onClick={() => {
                                                    setPlanner(true);
                                                    setSelectedCompanyId(company.id);
                                                }}><FontAwesomeIcon color='grey' icon={faCalendar}/> <AnyFormatDate
                                                    date={company.date_plan}/></span>
                                        )}
                                    </TableCell>
                                    <TableCell className={getClass(calculateGroupYearlySum(company, 2022))}>
                                        <CurrencyFormatter amount={calculateGroupYearlySum(company, 2022)}
                                                           withSpaces={true} onlyHRN={true}/>
                                    </TableCell>
                                    <TableCell className={getClass(calculateGroupYearlySum(company, 2023))}>
                                        <CurrencyFormatter amount={calculateGroupYearlySum(company, 2023)}
                                                           withSpaces={true} onlyHRN={true}/>
                                        {calculateGroupYearlySum(company, 2022) !== null && calculateGroupYearlySum(company, 2023) !== null && (
                                            <Tooltip
                                                title={`${
                                                    Math.round(calculateGroupYearlySum(company, 2023) - calculateGroupYearlySum(company, 2022)).toLocaleString('uk-UA', {
                                                        style: 'currency',
                                                        currency: 'UAH',
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0,
                                                    }).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                                }`}
                                            >
                                                <span
                                                    style={{color: calculateGrowth(calculateGroupYearlySum(company, 2023), calculateGroupYearlySum(company, 2022)) > 0 ? 'green' : 'red'}}>
                                                    {calculateGrowth(
                                                        calculateGroupYearlySum(company, 2023),
                                                        calculateGroupYearlySum(company, 2022)
                                                    ) > 0 ? (
                                                        <ArrowDropUp/>
                                                    ) : (
                                                        <ArrowDropDown/>
                                                    )}
                                                    {Math.round(
                                                        calculateGrowth(
                                                            calculateGroupYearlySum(company, 2023),
                                                            calculateGroupYearlySum(company, 2022)
                                                        )
                                                    )}
                                                    %
                                                </span>
                                            </Tooltip>
                                        )}
                                    </TableCell>
                                    <TableCell className={getClass(calculateGroupYearlySum(company, 2024))}>
                                        <CurrencyFormatter amount={Math.round(calculateGroupYearlySum(company, 2024))}
                                                           onlyHRN={true}/>
                                        {calculateGroupYearlySum(company, 2023) !== null && calculateGroupYearlySum(company, 2024) !== null && (
                                            <Tooltip
                                                title={`${
                                                    Math.round(calculateGroupYearlySum(company, 2024) - calculateGroupYearlySum(company, 2023)).toLocaleString('uk-UA', {
                                                        style: 'currency',
                                                        currency: 'UAH',
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0,
                                                    }).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                                }`}
                                            >
                                                <span
                                                    style={{color: calculateGrowth(calculateGroupYearlySum(company, 2024), calculateGroupYearlySum(company, 2023)) > 0 ? 'green' : 'red'}}>
                                                    {calculateGrowth(
                                                        calculateGroupYearlySum(company, 2024),
                                                        calculateGroupYearlySum(company, 2023)
                                                    ) > 0 ? (
                                                        <ArrowDropUp/>
                                                    ) : (
                                                        <ArrowDropDown/>
                                                    )}
                                                    {Math.round(
                                                        calculateGrowth(
                                                            calculateGroupYearlySum(company, 2024),
                                                            calculateGroupYearlySum(company, 2023)
                                                        )
                                                    )}
                                                    %
                                                </span>
                                            </Tooltip>
                                        )}
                                    </TableCell>
                                    <TableCell className={getClass(calculateGroupSum(company))}>
                                        <CurrencyFormatter amount={calculateGroupSum(company)} onlyHRN={true}/>
                                    </TableCell>
                                </TableRow>
                                {company.companies && company.companies.length > 0 && expanded[company.id] && (
                                    <>
                                        {[company, ...company.companies].map((childCompany, childIndex) => (
                                            <TableRow key={childCompany.id}>
                                                <TableCell></TableCell>
                                                <TableCell style={{paddingLeft: 40}}>
                                                    <NavLink
                                                        to={`/company/profile/${childCompany.id}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {childCompany.show_warning && (
                                                            <FontAwesomeIcon icon={faExclamation} color="red" style={{ marginRight: 5 }} />
                                                        )}
                                                        {childCompany.name_with_akon}
                                                    </NavLink>
                                                </TableCell>
                                                <TableCell>
                                                    {childCompany.checkToPlanner == null
                                                        ? <FontAwesomeIcon icon={faPlus}
                                                                           onClick={() => {
                                                                               setPlanner(true);
                                                                               setSelectedCompanyId(childCompany.id);
                                                                           }}/>
                                                        : <span
                                                            onClick={() => {
                                                                setPlanner(true);
                                                                setSelectedCompanyId(childCompany.id);
                                                            }}><AnyFormatDate
                                                            date={childCompany.date_plan}/></span>
                                                    }
                                                </TableCell>
                                                <TableCell className={getClass(getYearlySum(childCompany.sum, 2022))}>
                                                    <CurrencyFormatter amount={getYearlySum(childCompany.sum, 2022)}
                                                                       onlyHRN={true}/>
                                                </TableCell>
                                                <TableCell className={getClass(getYearlySum(childCompany.sum, 2023))}>
                                                    <CurrencyFormatter amount={getYearlySum(childCompany.sum, 2023)}
                                                                       onlyHRN={true}/>
                                                    {getYearlySum(childCompany.sum, 2022) !== null && getYearlySum(childCompany.sum, 2023) !== null && getYearlySum(childCompany.sum, 2023) !== 0 && (
                                                        <Tooltip
                                                            title={`${
                                                                getYearlySum(childCompany.sum, 2023) -
                                                                getYearlySum(childCompany.sum, 2022)
                                                            } грн`}
                                                        >
                                                            <span
                                                                style={{color: calculateGrowth(getYearlySum(childCompany.sum, 2023), getYearlySum(childCompany.sum, 2022)) > 0 ? 'green' : 'red'}}>
                                                                {calculateGrowth(
                                                                    getYearlySum(childCompany.sum, 2023),
                                                                    getYearlySum(childCompany.sum, 2022)
                                                                ) > 0 ? (
                                                                    <ArrowDropUp/>
                                                                ) : (
                                                                    <ArrowDropDown/>
                                                                )}
                                                                {Math.round(
                                                                    calculateGrowth(
                                                                        getYearlySum(childCompany.sum, 2023),
                                                                        getYearlySum(childCompany.sum, 2022)
                                                                    )
                                                                )}
                                                                %
                                                            </span>
                                                        </Tooltip>
                                                    )}
                                                </TableCell>
                                                <TableCell className={getClass(getYearlySum(childCompany.sum, 2024))}>
                                                    <CurrencyFormatter amount={getYearlySum(childCompany.sum, 2024)}
                                                                       onlyHRN={true}/>
                                                    {getYearlySum(childCompany.sum, 2023) !== null && getYearlySum(childCompany.sum, 2024) !== null && getYearlySum(childCompany.sum, 2024) !== 0 && (
                                                        <Tooltip
                                                            title={`${
                                                                getYearlySum(childCompany.sum, 2024) -
                                                                getYearlySum(childCompany.sum, 2023)
                                                            } грн`}
                                                        >
                                                            <span
                                                                style={{color: calculateGrowth(getYearlySum(childCompany.sum, 2024), getYearlySum(childCompany.sum, 2023)) > 0 ? 'green' : 'red'}}>
                                                                {calculateGrowth(
                                                                    getYearlySum(childCompany.sum, 2024),
                                                                    getYearlySum(childCompany.sum, 2023)
                                                                ) > 0 ? (
                                                                    <ArrowDropUp/>
                                                                ) : (
                                                                    <ArrowDropDown/>
                                                                )}
                                                                {Math.round(
                                                                    calculateGrowth(
                                                                        getYearlySum(childCompany.sum, 2024),
                                                                        getYearlySum(childCompany.sum, 2023)
                                                                    )
                                                                )}
                                                                %
                                                            </span>
                                                        </Tooltip>
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    className={getClass(
                                                        (childCompany.sum || []).reduce((acc, cur) => acc + (cur.sum || 0), 0)
                                                    )}
                                                >
                                                    <CurrencyFormatter
                                                        amount={(childCompany.sum || []).reduce((acc, cur) => acc + (cur.sum || 0), 0)}
                                                        onlyHRN={true}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </>
                                )}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default AllCompanyWithManagerNew;
