import React from 'react';
import s from './Distribution.module.css';
import main from '../../Main.module.css';
import { useState } from 'react';

const ChangeName = (props) =>{
        let [nom, setNom] = useState(props.newName)
        const setNomF = (e)=>{
            setNom(e.target.value)
        }
        const AddNewNom = ()=>{
            if(nom!="" && nom!=0){
                props.changeNameDistribution(nom, props.active, props.personalId)
                props.setActive(false)
            }
        }
        return(
            <div className={s.addDesc}>
                <h2 className={main.center}>Меняем название рассылки №{props.active}</h2>
                <div><textarea value={nom} onChange={setNomF} className={s.textarea}/></div>
                <div className={main.center}><button onClick={()=>AddNewNom()}>Добавить</button></div>
            </div>
        )
}
export default ChangeName;