import React from 'react';
import AnyFormatDate from '../../common/AnyFormatDate/AnyFormatDate';
import s from './AccessoriesList.module.css';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

const AccessoriesList = (props) =>{
    console.log(props.accessoriesList)
    const groupedData = props.accessoriesList.reduce((acc, item) => {
        const { specification_id, ...rest } = item;
        if (!acc[specification_id]) {
            acc[specification_id] = [];
        }
        acc[specification_id].push(rest);
        return acc;
    }, {});
    let [search, setSearch] = useState("")
    const getSearch = (e)=>{
        setSearch(e.target.value)
    }
    //сумма всех закупок по каждому спец номеру
    const totalSum = (items) =>{
        return items.reduce((sum, item) => {
        return Number(sum) + (Number(item.coll) * Number(item.unit_type_id));
        }, 0);
    }
    return(
        <div>
            <h1>Заказанные компоненты</h1>
            <div className={s.search}>Поиск по закупком <input type="search" value={search} onChange={getSearch} /></div>
            <div className={s.main}>
            {Object.entries(groupedData).map(([specification_id, items]) => {
                if(specification_id.includes(search)){
                    return(
                    <div key={specification_id} className={s.mainMin}>
                        <h3>Спец номер: <NavLink to={"/suppliers/"+items[0].storage_component_id}>{specification_id}</NavLink> (на складе {items[0].storageColl})</h3>
                        <div className={s.sum}>{items[0].storageComponentName}</div>
                        <div>
                            {items.map((item, index) => (
                                <div key={index} className={s.item}>
                                    {/* Здесь выводите нужные свойства элемента */}
                                    <div>Счёт №: <a href={"https://www.akon.com.ua/admin/accessories/accessories_component.php?check="+item.accessories_id}>{item.accessories_id}</a></div>
                                    <div>Количество: <b>{Number(item.coll)*Number(item.unit_type_id)} штук</b></div>
                                    <div>Дата прибытия: <AnyFormatDate date={item.date_receiving} /></div>
                                    <div>{item.name_with_akon}</div>
                                    {/* Добавьте другие свойства по вашему усмотрению */}
                                </div>
                            ))}
                            <div className={s.sum}>Сумма всех: {totalSum(items)}</div>
                        </div>
                    </div>
                    )}
                    return null
                })}
            </div>
        </div>
    )
    
}
export default AccessoriesList;