import React, { useState } from 'react';
import main from '../../../Main.module.css';
import s from './CompanyAndAgents.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus} from '@fortawesome/free-solid-svg-icons'
import AddAgents from '../../../Production/Check/Messages/Modal/AddAgents';
import Modal from '../../../Production/common/Modal/Modal';

const CompanyAndAgents = (props) =>{
    // let [companyId, setCompanyId] = useState("")
    let [companyName, setCompanyName] = useState("")
    function setCompanyIdF(event){
        const selectedCompanyName = event.target.value;
        setCompanyName(selectedCompanyName);
        props.setContact("")
        const selectedOption = props.companyList.find(k => k.name_with_akon === selectedCompanyName);
        if (selectedOption) {
            const selectedCompanyId= selectedOption.id;
            props.setCompanyId(selectedCompanyId);
            props.getAgentsPersonalCompany(selectedCompanyId)//получаем контакты выбранной компании
        }
    }
    const setContactF=(e)=>{props.setContact(e.target.value)}
    const setAddAgentF=()=>{
        props.setAddAgent(true)
    }
    return(
        <div>
            <div>
                <div className={main.bold+" "+main.center}>Выбор компании</div>
                <div>
                <input type="text" list="company" value={companyName} placeholder="Не выбрано" onChange={setCompanyIdF} className={main.input} />
                <datalist id="company">
                {props.companyList.map(k => (
                    <option key={"company" + k.id} value={k.name_with_akon} data={k.id}>
                    {k.name_with_akon}
                    </option>
                ))}
                </datalist>
                </div>
            </div>
            {props.companyId!=="" &&
            <div>
                <div className={main.bold+" "+main.center}>Выбор контакта</div>
                <div className={s.selectPlus}>
                    <div>
                        <select onChange={setContactF} className={s.select}>
                            <option value="">Не выбрано</option>
                            {props.companyAgents.map(k=><option key={"companyAgents"+k.id} value={k.id}>{k.name}</option>)}
                        </select>
                    </div>
                    <div><FontAwesomeIcon icon={faPlus} onClick={()=>setAddAgentF()} className={s.ahref}/></div>
                </div>
            </div>
            }
        </div>
    )
}
export default CompanyAndAgents;