import React, {useState} from 'react';
import Cookies from 'js-cookie'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {loginAPI} from "../../api/api";
import {toast} from "react-toastify";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'ДУнаев & КУзьменко production ☺ © '}
            <Link color="inherit" href="https://akon.com.ua/">
                АКОН
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

function SignIn() {
    const [errorMessage, setErrorMessage] = useState('');
    const tokenToCookie = (token) => {
        if (token && token.length > 0) {
            Cookies.set('token', token);
            return true;
        }
        setErrorMessage('Ошибка входа')
        return false;
    }

    const [forgotPassword, setForgotPassword] = useState(false);
    const [email, setEmail] = useState('');
//kusem4uk@gmail.com
    const sendEmail = async () => {
        try {
            const response = await loginAPI.forgotPassword(email).then((response) => {
                if (response.status == 1) {
                    setErrorMessage('Письмо отправлено на ваш email')
                    toast.success(response.message);
                    setForgotPassword(false)
                } else {
                    setErrorMessage('Ошибка входа. Обратитесь к Администратору')
                    toast.error(response.message);
                }
            }).catch((error) => {
                setErrorMessage('Ошибка входа. Обратитесь к Администратору')
                toast.error(error.message);
            });
            console.log(response);

        } catch (error) {
            setErrorMessage(error.message); // Handle error messages
            toast.error(error.message)
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = data.get('email');
        const pass = data.get('password');
        try {
            const response = await loginAPI.login({
                email: email,
                pass: pass
            }).then((response) => {

                if (response && response?.user !== null && tokenToCookie(response.token)) {
                    console.log(response?.user)
                    console.log(response?.user?.is_admin)
                    Cookies.set('id', response?.user?.id);
                    if (response?.user?.is_admin === 1) {
                        Cookies.set('adminID', response?.user?.id);
                    }
                    window.location.reload();
                } else {
                    setErrorMessage('Ошибка входа')
                    toast.error(response.message);
                }
            }).catch((error) => {
                setErrorMessage('Ошибка входа')
                toast.error(error.message);
            });
            //console.log(response); // Handle the response accordingly
            // If login is successful, you might want to store user data in cookies or state
        } catch (error) {
            setErrorMessage(error.message); // Handle error messages
        }
    };

    return (
        <>
            {/*MUI dialog with email input for pass restore*/}
            <Dialog open={forgotPassword} onClose={() => setForgotPassword(false)}>
                <DialogTitle>Восстановление пароля</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Введите ваш email, на который будет отправлено письмо с инструкциями по восстановлению пароля
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Email"
                        type="email"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setForgotPassword(false)} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={() => sendEmail()} color="primary">
                        Отправить
                    </Button>
                </DialogActions>
            </Dialog>


            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Войти
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Пароль"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        {errorMessage && <Typography variant="body2" color="error">{errorMessage}</Typography>}
                        <Grid container>
                            <Grid item xl={6}>
                                <Link href="#" variant="body2" onClick={() => setForgotPassword(true)}>
                                    Забыли пароль?
                                </Link>
                            </Grid>
                            <Grid item xl={6}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{mt: 3, mb: 2}}
                                >
                                    Войти
                                </Button>
                            </Grid>
                        </Grid>

                        {/*<Grid container>*/}
                        {/*    <Grid item xs>*/}
                        {/*        <Link href="#" variant="body2">*/}
                        {/*            Не помните пароль?*/}
                        {/*        </Link>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}
                    </Box>
                </Box>
                <Copyright sx={{mt: 8, mb: 4}}/>
            </Container>
        </>
    );
}

const Login = (props) => {
    return (
        <div>
            {(!Cookies.get('id') || !Cookies.get('token')) ?
                <SignIn/>
                :
                //redirect to privateOffice/Cookies.get('id')
                window.location.href = process.env.REACT_APP_HOMEPAGE + 'privateOffice/' + Cookies.get('id')
            }
        </div>
    )
}

export default Login;
