import React, { useState } from 'react';
import main from '../../../Main.module.css';

const ChangeDateReminder = (props) =>{
    let [dateReminder, setDateReminder] = useState("")
    const setDateReminderF = (e) => setDateReminder(e.target.value)
    const go = () =>{
        props.changeDateReminderKp(props.active, dateReminder, props.type, props.personalId, props.dateStart, props.dateEnd)
        // console.log(props.active, dateReminder)
        props.setActive(false)
    }
    return(
        <div>
            <h3 className={main.center}>Дата напоминания</h3>
            <div><input type="date" className={main.input} value={dateReminder} onChange={setDateReminderF} /></div>
            <div className={main.center}><input type="button" value="Изменить" onClick={()=>go()} className={main.inputAdd} /></div>
        </div>
    )
}
export default ChangeDateReminder;