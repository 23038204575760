import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import UseCaseTable from './UseCaseTable';
import Statistics from './Statistics';
import { adminAPI } from "../../../api/api";

const UseCaseAdmin = () => {
    const [statistics, setStatistics] = useState([]);

    useEffect(() => {
        fetchStatistics();
    }, []);

    const fetchStatistics = async () => {
        await adminAPI.getAllUseCasesStats()
            .then(response => {
                setStatistics(response?.data);
            })
            .catch(error => {
                console.error("Error fetching statistics:", error);
            });
    };

    return (
        <Container maxWidth={false} style={{ width: '100%' }}>
            <Typography variant="h4" gutterBottom>Управление Use Cases</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Statistics data={statistics} />
                </Grid>
                <Grid item xs={12}>
                    <UseCaseTable />
                </Grid>
            </Grid>
        </Container>
    );
};

export default UseCaseAdmin;
