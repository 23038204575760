import React, { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
    acceptCompany2ndManager,
    getAllSharedCompanyWithManager,
    rejectCompany2ndManager
} from '../../../redux/sales-reducer';
import AllSharedCompanyWithManager from './AllSharedCompanyWithManager';
import Cookies from 'js-cookie';

// Debounce function to reduce API call frequency
function debounce(func, delay) {
    let timer;
    return function (...args) {
        clearTimeout(timer);
        timer = setTimeout(() => func.apply(this, args), delay);
    };
}

const AllSharedCompanyWithManagerContainer = (props) => {
    const { manager } = useParams();
    const [lastManagerId, setLastManagerId] = useState(null); // Track the last manager ID to avoid duplicate calls

    const fetchData = useCallback(debounce((personalId) => {
        props.getAllSharedCompanyWithManager(personalId);
    }, 300), [props]);

    useEffect(() => {
        let currentPersonalId = manager;
        const isAdmin = !!Cookies.get('adminID');

        if (!isAdmin && Cookies.get('id') !== currentPersonalId) {
            currentPersonalId = Cookies.get('id');
        }

        if (currentPersonalId !== lastManagerId) {
            setLastManagerId(currentPersonalId);
            fetchData(currentPersonalId);
        }
    }, [manager, fetchData, lastManagerId]);

    return (
        <div>
            <AllSharedCompanyWithManager {...props} />
        </div>
    );
};

const mapStateToProps = (state) => ({
    allSharedCompanyWithManager: state.sales.allSharedCompanyWithManager,
});

export default compose(
    connect(mapStateToProps, {
        getAllSharedCompanyWithManager,
        acceptCompany2ndManager,
        rejectCompany2ndManager
    })
)(AllSharedCompanyWithManagerContainer);
