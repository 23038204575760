import React, { useState, PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import s from './KpPlan.module.css';

const KpPlan = (props) =>{
    console.log(props.kpPlan)

    const data = []
    props.sumMonthKpPlan.map(k=>{
        data.push(
            {
                name:k.date,
                "Сумма": Number(k.sum),
            })
    })
    const getSumStatus = (arrayMain)=>{
        const array = arrayMain.reduce((acc, curr) => {
            const { status_by_importance } = curr;
            if (!acc[status_by_importance]) {
                acc[status_by_importance] = 1;
            } else {
                acc[status_by_importance]++;
            }
            return acc;
        }, {});
        return array
    }
    
    // console.log(getSumStatus)
    console.log(props.statusKp)
    return(
        <div>
            <h1>Статистика КП</h1>
            {/* <ResponsiveContainer width="95%" height={250}>
                <BarChart width={150} height={40} data={data} >
                    <XAxis dataKey="name" />
                    <YAxis/>
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Сумма" fill="#1281ca" label={{ position: 'top'}} stackId="a"/>
                </BarChart>
            </ResponsiveContainer> */}
            <div className={s.mt30}>
                <div className={s.mainCheck+" "+s.bold}>
                    <div><h3>Статус</h3></div>
                    <div><h3>всего шт.</h3></div>
                    <div><h3>купили</h3></div>
                    <div><h3>отмена</h3></div>
                    <div><h3>не актуально</h3></div>
                </div>    
                {/* {getSumStatus(props.kpPlan).forEach(k=>{
                    return(
                    <div className={s.mainCheckDiv}>
                        <div></div>
                    </div>
                    )
                })} */}
            </div>
        </div>
    )
}
export default (KpPlan);

