import React from "react";

const PhoneNumberFormatter = ({ phoneNumber }) => {
    let formattedPhoneNumber = phoneNumber.replace(/\D/g, "");
    formattedPhoneNumber = formattedPhoneNumber.startsWith("38")
        ? formattedPhoneNumber.substr(2, formattedPhoneNumber.length - 2)
        : formattedPhoneNumber;

    if (formattedPhoneNumber.length >= 8) {
        formattedPhoneNumber = `(${formattedPhoneNumber.substr(0, 3)}) `+
            `${formattedPhoneNumber.substr(3, 3)} ${formattedPhoneNumber.substr(6, 2)} ${formattedPhoneNumber.substr(8, 2)}`;
    }

    return formattedPhoneNumber;
};

export default PhoneNumberFormatter;
