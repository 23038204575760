import React from 'react';
import { connect } from 'react-redux';
import {getPersonalModuleCategory} from '../../../redux/moduls-reducer';
import PersonalModuleCategory from './PersonalModuleCategory';

class PersonalModuleCategoryContainer extends React.Component{
    componentDidMount(){
        this.props.getPersonalModuleCategory(this.props.id);
    }
    componentDidUpdate(prevProps){
        if(prevProps.id !== this.props.id){
            this.props.getPersonalModuleCategory(this.props.id);
        }
    }
    render(){
        return <PersonalModuleCategory personalModuleCategory={this.props.personalModuleCategory} setCategory={this.props.setCategory} category={this.props.category}/>
    } 
}

let mapStateToProps = (state) =>({
    personalModuleCategory: state.moduls.personalModuleCategory,
})
export default connect( mapStateToProps, {getPersonalModuleCategory})(PersonalModuleCategoryContainer);