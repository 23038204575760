import { personalAreaAPI } from "../api/api";

const SET_PERSONAL_AREA_SALES_CHECK = "SET_PERSONAL_AREA_SALES_CHECK";
const SET_PERSONAL_AREA_SALES_CHECK_ON_7_DAY = "SET_PERSONAL_AREA_SALES_CHECK_ON_7_DAY";
const SET_PERSONAL_KP_FOR_PERIOD = "SET_PERSONAL_KP_FOR_PERIOD";


let initialState = {
    personalAreaSalesCheck: [],
    personalAreaSalesCheckOn7Day: [],
    personalKpForPeriod: [],
}

const personalArea = (state = initialState, action) =>{
    switch(action.type){
        case SET_PERSONAL_AREA_SALES_CHECK:
            return {
                ...state,
                personalAreaSalesCheck: action.personalAreaSalesCheck
            }
        case SET_PERSONAL_AREA_SALES_CHECK_ON_7_DAY:
            return {
                ...state,
                personalAreaSalesCheckOn7Day: action.personalAreaSalesCheckOn7Day
            }
        case SET_PERSONAL_KP_FOR_PERIOD:
            return {
                ...state,
                personalKpForPeriod: action.personalKpForPeriod
            }
        default:
            return state
    }
}

export const setPersonalAreaSalesCheck = (personalAreaSalesCheck) => ({type: SET_PERSONAL_AREA_SALES_CHECK, personalAreaSalesCheck});
export const setPersonalAreaSalesCheckOn7Day = (personalAreaSalesCheckOn7Day) => ({type: SET_PERSONAL_AREA_SALES_CHECK_ON_7_DAY, personalAreaSalesCheckOn7Day});
export const setPersonalKpForPeriod = (personalKpForPeriod) => ({type: SET_PERSONAL_KP_FOR_PERIOD, personalKpForPeriod});

export const getPersonalAreaSalesCheck= (personalId) =>{
    return (dispatch) => {
        personalAreaAPI.getPersonalAreaSalesCheck(personalId)
        .then(data => {
            dispatch(setPersonalAreaSalesCheck(data));
        });
    }
}
export const getPersonalAreaSalesCheckOn7Day= (personalId) =>{
    return (dispatch) => {
        personalAreaAPI.getPersonalAreaSalesCheckOn7Day(personalId)
        .then(data => {
            dispatch(setPersonalAreaSalesCheckOn7Day(data));
        });
    }
}
export const getPersonalKpForPeriod= (personalId) =>{
    return (dispatch) => {
        personalAreaAPI.getPersonalKpForPeriod(personalId)
        .then(data => {
            dispatch(setPersonalKpForPeriod(data));
        });
    }
}
export const changeDatePlanKp= (idKp, date, personalId) =>{
    return (dispatch) => {
        personalAreaAPI.changeDatePlanKp(idKp, date)
        .then(data => {
            dispatch(getPersonalKpForPeriod(personalId));
        });
    }
}
export const setShowKp= (idKp, personalId) =>{
    return (dispatch) => {
        personalAreaAPI.setShowKp(idKp)
        .then(data => {
            dispatch(getPersonalKpForPeriod(personalId));
        });
    }
}


export default personalArea;

