// components/RenderGrid.jsx
import React from 'react';
import { Container, Grid, Card, CardContent, Typography } from '@mui/material';
import { BarChart, LineChart } from '@mui/x-charts';

const RenderGrid = ({ layout }) => {
    const renderChart = (chartType, data) => {
        switch (chartType) {
            case 'bar':
                return <BarChart {...data} />;
            case 'line':
                return <LineChart {...data} />;
            default:
                return null;
        }
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>Rendered Grid</Typography>
            <Grid container spacing={3}>
                {layout.map((card, index) => (
                    <Grid item xs={12} md={card.w * 2} key={index} style={{ height: card.h * 30 }}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5">Card {index + 1}</Typography>
                                {card.chartType ? (
                                    renderChart(card.chartType, card.chartData)
                                ) : (
                                    <Typography variant="body2">No Chart</Typography>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default RenderGrid;
