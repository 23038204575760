import React, { useState, useEffect } from 'react';
import s from '../Task.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment } from '@fortawesome/free-regular-svg-icons'
import { faArrowUp, faPencilAlt, faProjectDiagram } from '@fortawesome/free-solid-svg-icons'
import AnyFormatDate from '../../../Production/common/AnyFormatDate/AnyFormatDate';

const ShowTask = (props) =>{
    let id = props.id
    return(<>
        <div className={props.type!=3 ? s.main : s.main+" "+s.archive} key={"tsks_"+id}>
            <div className={s.center}>{id}</div>
            <div className={s.twoBlockMin} onClick={()=>props.setShowCommentF(id)}>
                <div className={s.hover}>{props.task}</div>
                <div className={s.hover}><FontAwesomeIcon icon={faComment} />{props.getCountComment(id)}</div>
            </div>
            <div className={s.center}>
                <h4>{props.getWorkerName(props.who_added)}</h4>
            </div>
            <div className={s.center}>
                <h4>{props.getWorkerName(props.responsible_id)}<FontAwesomeIcon icon={faPencilAlt} onClick={()=>props.setShowAddManagerF(id)} /></h4>
            </div>
            <div className={s.threeBlock}>
                <div>
                    {props.provisional_date
                    ?<AnyFormatDate date={props.provisional_date} />
                    :<input type='date' onBlur={props.setDate} name="3" id={id} />
                    }
                </div>
                <div>
                    {props.date_start 
                    ? <AnyFormatDate date={props.date_start} /> 
                    : <input type='date' onBlur={props.setDate} name="1" id={id} />
                    }
                </div>
                <div>{props.date_end ? <AnyFormatDate date={props.date_end} /> : <input type='date' onChange={props.setDate} name="2" id={id} />}</div>
            </div>
            <div>
                <button onClick={()=>props.setShowAddCommentF(id)} data-tooltip="Комент" className={s.tooltip}><FontAwesomeIcon icon={faComment} /></button>
                {props.type != 2 && props.type != 3
                ? <button onClick={()=>props.setShowTaskF(id)} data-tooltip="Подзадача" className={s.tooltip}><FontAwesomeIcon icon={faProjectDiagram} /></button>
                : ""
                }
                {props.type == 3
                ? <button onClick={()=>props.setTaskWithoutArchive(id)} data-tooltip="Из архива" className={s.tooltip}><FontAwesomeIcon icon={faArrowUp} /></button>
                : ""
                }
            </div>
        </div>
        <div>
            {id==props.showComment && props.tasksComment.map((j, index)=>{
                if(id==j.technical_tasks_id){
                    return(
                        <div className={s.mainComment} key={"tasksComment"+index}>
                            <div className={s.center}><FontAwesomeIcon icon={faComment} /></div>
                            <div>{j.comment}</div>
                            <div className={s.center}>{props.getWorkerName(j.tech_id)}</div>
                            <div className={s.center}><AnyFormatDate date={j.date} /></div>
                            <div></div>
                        </div>
                    )
                }
            })   
            }
        </div>
        </>
    )
}
export default ShowTask;