import { modulsAPI } from "../api/api";

const SET_MODULS_WITH_COMPONENT = "SET_MODULS_WITH_COMPONENT";
const SET_STATISTICS_PAID_FROM_MODULES_NOM = "SET_STATISTICS_PAID_FROM_MODULES_NOM";
const SET_PERSONAL_MODULE_CATEGORY = "SET_PERSONAL_MODULE_CATEGORY";
const SET_PERSONAL_MODULE_CATEGORY_COMPANY = "SET_PERSONAL_MODULE_CATEGORY_COMPANY";
const SET_PERSONAL_BALANCES_IN_STOCK = "SET_PERSONAL_BALANCES_IN_STOCK";
const SET_MODULES_FINANCE_INFO = "SET_MODULES_FINANCE_INFO";


let initialState = {
    modulsWithComponent: [],
    statisticsPaidFromModulesNomenclature: [],
    personalModuleCategory: [],
    personalModuleCategoryCompany: [],
    personalBalancesInStock: 0,
    modulesFinanceInfo: []
}

const modulsReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_MODULES_FINANCE_INFO:
            return {
                ...state,
                modulesFinanceInfo: action.data
            }
        case SET_MODULS_WITH_COMPONENT:
            return {
                ...state,
                modulsWithComponent: action.modulsWithComponent
            }
        case SET_STATISTICS_PAID_FROM_MODULES_NOM:
            return {
                ...state,
                statisticsPaidFromModulesNomenclature: action.statisticsPaidFromModulesNomenclature
            }
        case SET_PERSONAL_MODULE_CATEGORY:
            return {
                ...state,
                personalModuleCategory: action.personalModuleCategory
            }
        case SET_PERSONAL_MODULE_CATEGORY_COMPANY:
            return {
                ...state,
                personalModuleCategoryCompany: action.personalModuleCategoryCompany
            }
        case SET_PERSONAL_BALANCES_IN_STOCK:
            return {
                ...state,
                personalBalancesInStock: action.data
            }
        default:
            return state
    }
}

export const setModulsWithComponent= (modulsWithComponent) => ({type: SET_MODULS_WITH_COMPONENT, modulsWithComponent});
export const setStatisticsPaidFromModulesNomenclature = (statisticsPaidFromModulesNomenclature ) => ({type: SET_STATISTICS_PAID_FROM_MODULES_NOM, statisticsPaidFromModulesNomenclature});
export const setPersonalModuleCategory = (personalModuleCategory ) => ({type: SET_PERSONAL_MODULE_CATEGORY, personalModuleCategory});
export const setPersonalModuleCategoryCompany = (personalModuleCategoryCompany ) => ({type: SET_PERSONAL_MODULE_CATEGORY_COMPANY, personalModuleCategoryCompany});
export const setPersonalBalancesInStock = (data) => ({type: SET_PERSONAL_BALANCES_IN_STOCK, data});
export const setModulesFinanceInfo = (data) => ({type: SET_MODULES_FINANCE_INFO, data});


export const getModulsWithComponent = (componentId) =>{
    return (dispatch) => {
        modulsAPI.getModulsWithComponent(componentId)
        .then(data => {
            dispatch(setModulsWithComponent(data));
        });
    }
}
export const getStatisticsPaidFromModulesNomenclature = (nomenclature) =>{
    return (dispatch) => {
        modulsAPI.getStatisticsPaidFromModulesNomenclature(nomenclature)
        .then(data => {
            dispatch(setStatisticsPaidFromModulesNomenclature(data));
        });
    }
}
export const getPersonalModuleCategory = (nomenclature) =>{
    return (dispatch) => {
        modulsAPI.getPersonalModuleCategory(nomenclature)
        .then(data => {
            dispatch(setPersonalModuleCategory(data));
        });
    }
}
export const getPersonalBalancesInStock = (nomenclature) =>{
    return (dispatch) => {
        modulsAPI.getPersonalBalancesInStock(nomenclature)
        .then(data => {
            dispatch(setPersonalBalancesInStock(data));
        });
    }
}
export const getModulesFinanceInfo = (nomenclature) =>{
    return (dispatch) => {
        modulsAPI.getModulesFinanceInfo(nomenclature)
        .then(data => {
            dispatch(setModulesFinanceInfo(data));
        });
    }
}

export const getPersonalModuleCategoryCompany = (nomenclature, category) =>{
    return (dispatch) => {
        modulsAPI.getPersonalModuleCategoryCompany(nomenclature, category)
        .then(data => {
            dispatch(setPersonalModuleCategoryCompany(data));
        });
    }
}


export default modulsReducer;