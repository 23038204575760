import React from 'react';
import Production from './Production';
import {connect} from 'react-redux';
import {
    getModulsPaid,
    getlistProduction,
    changeStorageFromTech,
    setNewTTN,
    setModulsStatus,
    setModulsStatusNew
} from '../../../redux/productionMain-reducer';
import Cookies from "js-cookie";


class ProductionMainContainer extends React.Component {
    componentDidMount() {
        let managerID = Cookies.get('id');
        this.props.getlistProduction(managerID);
        this.props.getModulsPaid();
    }


    render() {
        return (
            <Production
                moduls={this.props.moduls}
                listProduction={this.props.listProduction}
                changeStorageFromTech={this.props.changeStorageFromTech}
                setNewTTN={this.props.setNewTTN}
                setModulsStatus={this.props.setModulsStatus}
                setModulsStatusNew={this.props.setModulsStatusNew}
            />
        )
    }
}


let mapStateToProps = (state) => {
    return {
        moduls: state.productionMain.moduls,
        listProduction: state.productionMain.listProduction
    }
}

export default connect(mapStateToProps, {
    getModulsPaid,
    getlistProduction,
    changeStorageFromTech,
    setNewTTN,
    setModulsStatus,
    setModulsStatusNew
})(ProductionMainContainer);
