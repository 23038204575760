import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getPersonalKpForPeriod, changeDatePlanKp, setShowKp } from '../../../../redux/personalArea-reducer';
import PersonalKp from './PersonalKp';

class PersonalKpContainer extends React.Component {
    componentDidMount() {
        this.props.getPersonalKpForPeriod(this.props.personalId);
    }

    render() {
        return (
            <PersonalKp
                personalKpForPeriod={this.props.personalKpForPeriod}
                personalId={this.props.personalId}
                changeDatePlanKp={this.props.changeDatePlanKp}
                setShowKp={this.props.setShowKp}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    personalKpForPeriod: state.personalarea.personalKpForPeriod,
});

export default compose(
    connect(mapStateToProps, { getPersonalKpForPeriod, changeDatePlanKp, setShowKp })
)(PersonalKpContainer);
