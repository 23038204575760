import React, { useState, useEffect } from 'react';
import Modal from '../../Production/common/Modal/Modal';
import AddComment from './Modal/AddComment';
import s from './Task.module.css';
import AddTask from './Modal/AddTask';
import AddManager from './Modal/AddManager';
import ShowTask from './ShowTask/ShowTask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faHeadSideMask } from '@fortawesome/free-solid-svg-icons';

const Task = (props) => {
    let [workerList, setWorkerList] = useState(props.workersListTech);
    useEffect(() => {
        setWorkerList([...props.workersListTech]);
    }, [props.workersListTech]);

    let [tasksComment, setTasksComment] = useState(props.tasksComment);
    useEffect(() => {
        setTasksComment([...props.tasksComment]);
    }, [props.tasksComment]);

    let [showAddComment, setShowAddComment] = useState(false);
    let [idForComment, setIdForComment] = useState(0);
    const setShowAddCommentF = (id) => {
        setIdForComment(id);
        setShowAddComment(!showAddComment);
    };

    const getWorkerName = (workerId) => {
        return workerList.map((k) => {
            if (Number(k.id) === Number(workerId)) return k.name;
        });
    };

    const setDate = (e) => {
        props.addNewDateInTask(e.target.id, e.target.value, e.target.name);
    };

    let [showAddManager, setShowAddManager] = useState(false);
    let [idTaskForChange, setIdTaskForChange] = useState(0);
    const setShowAddManagerF = (id) => {
        setIdTaskForChange(id);
        setShowAddManager(true);
    };

    let [showTaskForWorker, setShowTaskForWorker] = useState(0);
    const setShowTaskForWorkerF = (e) => {
        setShowTaskForWorker(e.target.value);
        props.getTasks(e.target.value);
    };

    let [showComment, setShowComment] = useState(0);
    const setShowCommentF = (id) => {
        if (showComment === id) {
            setShowComment(0);
        } else {
            setShowComment(id);
        }
    };

    const getCountComment = (idComment) => {
        let i = 0;
        props.tasksComment.map((k) => {
            if (idComment === k.technical_tasks_id) i++;
        });
        return i;
    };

    let [mainTask, setMainTask] = useState(0);
    let [showTask, setShowTask] = useState(false);
    const setShowTaskF = (idMainTask = 0) => {
        setShowTask(!showTask);
        setMainTask(idMainTask);
    };

    let [numberSort, setNumberSort] = useState("1");
    const setNumberSortF = (e) => {
        setNumberSort(e.target.value);
    };

    let [tasks, setTasks] = useState(props.tasks);
    useEffect(() => {
        setTasks([...props.tasks]);
    }, [props.tasks]);

    const getSort = () => {
        switch (numberSort) {
            case "1":
                return tasks.sort((a, b) => Number(b.id) - Number(a.id));
            case "2":
                return tasks.sort((a, b) => Number(a.id) - Number(b.id));
            case "3":
                return tasks.sort((a, b) => {
                    let d1 = a.provisional_date ? new Date(a.provisional_date) : new Date("2030-01-01");
                    let d2 = b.provisional_date ? new Date(b.provisional_date) : new Date("2030-01-01");
                    return d1 - d2;
                });
            case "4":
                return tasks.sort((a, b) => {
                    let d1 = a.provisional_date ? new Date(a.provisional_date) : new Date("2030-01-01");
                    let d2 = b.provisional_date ? new Date(b.provisional_date) : new Date("2030-01-01");
                    return d2 - d1;
                });
            case "5":
                return tasks.sort((a, b) => {
                    let d1 = a.date_start ? new Date(a.date_start) : new Date("2030-01-01");
                    let d2 = b.date_start ? new Date(b.date_start) : new Date("2030-01-01");
                    return d1 - d2;
                });
            case "6":
                return tasks.sort((a, b) => {
                    let d1 = a.date_start ? new Date(a.date_start) : new Date("2030-01-01");
                    let d2 = b.date_start ? new Date(b.date_start) : new Date("2030-01-01");
                    return d2 - d1;
                });
            default:
                return props.tasks;
        }
    };

    return (
        <div className={s.taskMain}>
            <div className={s.threeBlock}>
                <div><button onClick={() => setShowTaskF()}>Добавить задачу</button></div>
                <div className={s.filter}>
                    <FontAwesomeIcon icon={faHeadSideMask} />
                    <select onChange={setShowTaskForWorkerF}>
                        <option value="0">Выбрать сотрудника</option>
                        {props.workersListTech.map((k) => (
                            <option value={k.id} key={k.id}>{k.name}</option>
                        ))}
                    </select>
                </div>
                <div className={s.filter}>
                    <FontAwesomeIcon icon={faFilter} />
                    <select onChange={setNumberSortF}>
                        <option value="1">От большего №</option>
                        <option value="2">От меньшего №</option>
                        <option value="3">От большего План</option>
                        <option value="4">От меньшего План</option>
                        <option value="5">От большего Начало</option>
                        <option value="6">От меньшего Начало</option>
                    </select>
                </div>
            </div>

            {showTask && (
                <Modal active={showTask} setActive={setShowTask}>
                    <AddTask workerList={workerList} setNewTask={props.setNewTask} setShowTask={setShowTask} mainTask={mainTask} />
                </Modal>
            )}

            {showAddComment && (
                <Modal active={showAddComment} setActive={setShowAddComment}>
                    <AddComment tasksId={idForComment} addCommentForTask={props.addCommentForTask} setShowAddComment={setShowAddComment} workerList={workerList} />
                </Modal>
            )}

            {showAddManager && (
                <Modal active={showAddManager} setActive={setShowAddManager}>
                    <AddManager workerList={workerList} idTaskForChange={idTaskForChange} setNewWorkerInTask={props.setNewWorkerInTask} setShowAddManager={setShowAddManager} />
                </Modal>
            )}

            <div>
                <h1>Задачи</h1>
                <div className={s.main + " " + s.hei}>
                    <div onClick={() => setNumberSort(1)} className={s.row}>№</div>
                    <div onClick={() => setNumberSort(2)}>Задача</div>
                    <div>Добавил</div>
                    <div>Ответственный</div>
                    <div>
                        <div className={s.threeBlock}>
                            <div>План</div>
                            <div>Начало</div>
                            <div>Конец</div>
                        </div>
                    </div>
                    <div></div>
                </div>

                {getSort(tasks).map((k, index) => {
                    if (k.date_end == null && k.mainTaskId == null) {
                        return (
                            <div key={"tasks_" + index}>
                                <ShowTask
                                    {...k}
                                    getCountComment={getCountComment}
                                    getWorkerName={getWorkerName}
                                    setShowCommentF={setShowCommentF}
                                    setShowAddManagerF={setShowAddManagerF}
                                    setDate={setDate}
                                    setShowAddCommentF={setShowAddCommentF}
                                    setShowTaskF={setShowTaskF}
                                    tasksComment={tasksComment}
                                    showComment={showComment}
                                />
                                {tasks.map((g, subIndex) => {
                                    if (g.mainTaskId == k.id) {
                                        return (
                                            <div key={"tasks_" + subIndex} className={s.notMainTask}>
                                                <ShowTask
                                                    {...g}
                                                    getCountComment={getCountComment}
                                                    getWorkerName={getWorkerName}
                                                    setShowCommentF={setShowCommentF}
                                                    setShowAddManagerF={setShowAddManagerF}
                                                    setDate={setDate}
                                                    setShowAddCommentF={setShowAddCommentF}
                                                    type="2"
                                                    tasksComment={tasksComment}
                                                    showComment={showComment}
                                                />
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        );
                    }
                })}

                {getSort(tasks).map((k, index) => {
                    if (k.date_end != null && k.mainTaskId == null) {
                        return (
                            <div key={"tasks_" + index}>
                                <ShowTask
                                    {...k}
                                    getCountComment={getCountComment}
                                    getWorkerName={getWorkerName}
                                    setShowCommentF={setShowCommentF}
                                    setShowAddManagerF={setShowAddManagerF}
                                    setDate={setDate}
                                    setShowAddCommentF={setShowAddCommentF}
                                    type="3"
                                    tasksComment={tasksComment}
                                    showComment={showComment}
                                    setTaskWithoutArchive={props.setTaskWithoutArchive}
                                />
                            </div>
                        );
                    }
                })}
            </div>
        </div>
    );
}

export default Task;
