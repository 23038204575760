import React from 'react';
import { NavLink } from 'react-router-dom';
import AnyFormatDate from '../../../Production/common/AnyFormatDate/AnyFormatDate';
import s from '../SalesPersonal.module.css';
import Cookie from "js-cookie";
import {getManagerName} from "../../../global/ManagersList";


const TestCheck = (props) =>{

    let showManagersName = !!Cookie.get('adminID') && props.whose_company !== props.personalId ;
    if(props.whose_company == 2 && props.personalId == 486 && showManagersName) showManagersName = false

    console.log(props)

    return(
        <div className={s.main}>
            <div>Референс счёта #{props.number} ({props.year}год) - {props?.filled_usecase == 1
                ? <span className={s.greenText}>заполнен</span>
                : <span className={s.redText}>не заполнен</span>
            }</div>
            {/* <div>Тест {props.paid!=null ? <span className={s.greenText}>- купили (<AnyFormatDate date={props.paymentDate} />)</span> : <span className={s.redText}>- ещё на тесте</span>}
            {props.ttn!=="" && <div className={s.ttn}>Отгружен (<AnyFormatDate date={props.date_op}/>) - <span className={s.greenText}>{props.ttn}</span></div>}
            </div> */}
            <div><NavLink to={"/check/"+props.id+"/0"} target="_blank">{props?.invoice_name}</NavLink>
                {showManagersName &&  <>
                    < br/>
                    Менеджер: { getManagerName(props.whose_company)}
                </>}
            </div>
            <div><AnyFormatDate date={props.date_invoce_added}/></div>
        </div>
    )
}
export default TestCheck;