import React, { useState } from 'react';
import s from '../SalesCheck.module.css';
import main from '../../../Main.module.css';
import AnyFormatDate from "../../../Production/common/AnyFormatDate/AnyFormatDate"
import { useCookies } from 'react-cookie';
import { NavLink } from 'react-router-dom';

const SalesCheck = (props) =>{
    return(
        <div>
            <div className={s.archive}><NavLink to="/salesCheck">Cписок активных расходок</NavLink></div>
            <h1 className={main.center}>Список счетов по которым уже приехали расходные</h1>
            <div className={s.main+" "+s.header}>
                <div>Номер счёта</div>
                <div>Сумма</div>
                <div>Дата отгрузки</div>
                <div>Дата получения расходной</div>
            </div>
            {props.salesCheckArchive.sort((a, b) => new Date(b.buh_status) - new Date(a.buh_status)).map((k,index)=>{
                return(
                    <div key={"salesCheck"+index} className={s.main}>
                        <div>{k.name}</div>
                        <div className={main.center}>{k.numeral}</div>
                        <div className={main.center}><AnyFormatDate date={k.date_delivery} /></div>
                        <div className={main.center}><AnyFormatDate date={k.buh_status} /></div>
                    </div> 
                )
            })}
        </div>
        
    )
}
export default SalesCheck;