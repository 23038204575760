import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getCompanyList } from '../../../../redux/other-reducer';
import { getAgentsPersonalCompany, setNewCompanyAgent } from '../../../../redux/company-reducer';
import { getCourse } from '../../../../redux/admin-reducer';
import { getStatusKp } from '../../../../redux/sales-reducer';
import CompanyAndAgents from './CompanyAndAgents';

const CompanyAndAgentsContainer = (props) => {
    const { idManager } = useParams(); // Access route params directly

    useEffect(() => {
        props.getCompanyList();
        props.getCourse();
        props.getStatusKp();
    }, []); // Runs only on component mount

    return (
        <div>
            <CompanyAndAgents {...props} personalId={idManager} />
        </div>
    );
}

let mapStateToProps = (state) => ({
    companyList: state.other.companyList,
    companyAgents: state.company.companyAgents,
    course: state.admin.course,
    statusKp: state.sales.statusKp,
});

export default compose(
    connect(mapStateToProps, {
        getCompanyList,
        getAgentsPersonalCompany,
        getCourse,
        setNewCompanyAgent,
        getStatusKp
    })
)(CompanyAndAgentsContainer);
