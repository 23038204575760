import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Typography,
    TextField,
    Button,
    Chip,
    FormControlLabel,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    ListItemText
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { companyAPI } from '../../../../api/api';
import Cookies from 'js-cookie';
import { salesManagersList } from '../../../global/ManagersList';

const FilterCategoriesCompanies = () => {
    const [companies, setCompanies] = useState({});
    const [categories, setCategories] = useState({});
    const [industries, setIndustries] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedIndustries, setSelectedIndustries] = useState([]);
    const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);
    const [industryDialogOpen, setIndustryDialogOpen] = useState(false);
    const [filterMode, setFilterMode] = useState(false);
    const [showEmails, setShowEmails] = useState(false);
    const [emailOnly, setEmailOnly] = useState(false);
    const [selectedManagerID, setSelectedManagerID] = useState(null);

    const isAdmin = !!Cookies.get('adminID');
    const currentUserID = Cookies.get('userid');

    const loadCompanies = async () => {
        const watchAsUserID = currentUserID;
        const watchUserID = selectedManagerID || currentUserID;

        await companyAPI.getCompanyListWithCategoriesAndIndustries({
            watchAsUserID,
            watchUserID,
        }).then((response) => {
            setCompanies(response.data);
            setCategories(response.categories);
            setIndustries(response.industries);
        });
    };

    useEffect(() => {
        loadCompanies();
    }, [selectedManagerID]);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleCategoryChipDelete = (chipToDelete) => () => {
        setSelectedCategories((chips) => chips.filter((chip) => chip !== chipToDelete));
    };

    const handleIndustryChipDelete = (chipToDelete) => () => {
        setSelectedIndustries((chips) => chips.filter((chip) => chip !== chipToDelete));
    };

    const handleCategoryDialogOpen = () => setCategoryDialogOpen(true);
    const handleCategoryDialogClose = () => setCategoryDialogOpen(false);
    const handleIndustryDialogOpen = () => setIndustryDialogOpen(true);
    const handleIndustryDialogClose = () => setIndustryDialogOpen(false);

    const handleFilterModeChange = (event) => setFilterMode(event.target.checked);
    const handleShowEmailsChange = () => {
        if (showEmails) setEmailOnly(false);
        setShowEmails((prev) => !prev);
    };

    const handleEmailOnlyChange = () => setEmailOnly((prev) => !prev);

    const handleManagerChange = (id) => setSelectedManagerID(id === 'all' ? null : id);

    const rows = [];
    const filteredCompanies = Object.keys(companies).filter((key) => {
        const company = companies[key];
        const matchesSearchQuery = company.name_with_akon.toLowerCase().includes(searchQuery.toLowerCase());

        const companyCategoryIDs = company.categories ? Object.keys(company.categories) : [];
        const companyIndustryIDs = company.industries ? Object.keys(company.industries) : [];

        let matchesCategories = true;
        let matchesIndustries = true;

        if (selectedCategories.length > 0) {
            if (filterMode) {
                matchesCategories = selectedCategories.every((id) => companyCategoryIDs.includes(id));
            } else {
                matchesCategories = selectedCategories.some((id) => companyCategoryIDs.includes(id));
            }
        }

        if (selectedIndustries.length > 0) {
            if (filterMode) {
                matchesIndustries = selectedIndustries.every((id) => companyIndustryIDs.includes(id));
            } else {
                matchesIndustries = selectedIndustries.some((id) => companyIndustryIDs.includes(id));
            }
        }

        return matchesSearchQuery && matchesCategories && matchesIndustries;
    });

    filteredCompanies.forEach((key) => {
        const company = companies[key];
        const companyInfo = {
            id: key,
            name_with_akon: company.name_with_akon,
            categories: Object.values(company.categories || {}).join(', '),
            industries: Object.values(company.industries || {}).join(', '),
        };

        if (showEmails && company.contacts && Object.keys(company.contacts).length > 0) {
            Object.values(company.contacts).forEach((contact) => {
                rows.push({
                    ...companyInfo,
                    email: contact.email,
                });
            });
        } else if (!emailOnly) {
            rows.push({
                ...companyInfo,
                email: '',
            });
        }
    });

    return (
        <Box p={2}>
            <Typography variant="h6">Список компаний (всего: {Object.keys(companies).length})</Typography>

            {isAdmin && (
                <Box display="flex" alignItems="center" mt={2} flexWrap="wrap">
                    <Button
                        variant={selectedManagerID === null ? 'contained' : 'outlined'}
                        onClick={() => handleManagerChange('all')}
                        style={{ marginRight: '8px' }}
                    >
                        Все
                    </Button>
                    {salesManagersList.map((manager) => (
                        <Button
                            key={manager.id}
                            variant={selectedManagerID === manager.id ? 'contained' : 'outlined'}
                            onClick={() => handleManagerChange(manager.id)}
                            style={{ marginRight: '8px', marginBottom: '8px' }}
                        >
                            {manager.name}
                        </Button>
                    ))}
                </Box>
            )}

            <Box display="flex" alignItems="center" flexWrap="wrap" mt={2}>
                <TextField
                    label="Поиск по названию компании"
                    variant="outlined"
                    value={searchQuery}
                    onChange={handleSearch}
                    size="small"
                    style={{ marginRight: '16px', flexGrow: 1 }}
                />
                <FormControlLabel
                    control={<Checkbox checked={filterMode} onChange={handleFilterModeChange} />}
                    label="Жёсткий фильтр"
                />
                <Button variant="contained" onClick={handleShowEmailsChange} style={{ marginLeft: '16px' }}>
                    {showEmails ? 'Скрыть Email' : 'Показать Email'}
                </Button>
                <Button
                    variant="contained"
                    onClick={handleEmailOnlyChange}
                    style={{ marginLeft: '16px', display: showEmails ? 'block' : 'none' }}
                >
                    {emailOnly ? 'Показать все поля' : 'Показать только Email'}
                </Button>
            </Box>

            <Box mt={2} display="flex" alignItems="center" flexWrap="wrap">
                <Typography variant="body1" style={{ marginRight: '8px' }}>Категории:</Typography>
                {selectedCategories.map((id) => (
                    <Chip
                        key={id}
                        label={categories[id]}
                        onDelete={handleCategoryChipDelete(id)}
                        style={{ margin: '4px' }}
                    />
                ))}
                <Chip
                    label="Добавить категории"
                    style={{ backgroundColor: '#4caf50', color: 'white', margin: '4px' }}
                    onClick={handleCategoryDialogOpen}
                />
            </Box>

            <Box mt={2} display="flex" alignItems="center" flexWrap="wrap">
                <Typography variant="body1" style={{ marginRight: '8px' }}>Отрасли:</Typography>
                {selectedIndustries.map((id) => (
                    <Chip
                        key={id}
                        label={industries[id]}
                        onDelete={handleIndustryChipDelete(id)}
                        style={{ margin: '4px' }}
                    />
                ))}
                <Chip
                    onClick={handleIndustryDialogOpen}
                    label="Добавить отрасли"
                    style={{ backgroundColor: '#4caf50', color: 'white', margin: '4px' }}
                />
            </Box>

            <Dialog open={categoryDialogOpen} onClose={handleCategoryDialogClose} maxWidth="sm" fullWidth>
                <DialogTitle>Выберите категории</DialogTitle>
                <DialogContent>
                    <FormControl variant="outlined" fullWidth margin="normal">
                        <InputLabel>Категории</InputLabel>
                        <Select
                            multiple
                            value={selectedCategories}
                            onChange={(e) => setSelectedCategories(e.target.value)}
                            renderValue={(selected) => selected.map(id => categories[id]).join(', ')}
                            label="Категории"
                        >
                            {Object.keys(categories).map((id) => (
                                <MenuItem key={id} value={id}>
                                    <Checkbox checked={selectedCategories.indexOf(id) > -1} />
                                    <ListItemText primary={categories[id]} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCategoryDialogClose} color="primary">
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={industryDialogOpen} onClose={handleIndustryDialogClose} maxWidth="sm" fullWidth>
                <DialogTitle>Выберите отрасли</DialogTitle>
                <DialogContent>
                    <FormControl variant="outlined" fullWidth margin="normal">
                        <InputLabel>Отрасли</InputLabel>
                        <Select
                            multiple
                            value={selectedIndustries}
                            onChange={(e) => setSelectedIndustries(e.target.value)}
                            renderValue={(selected) => selected.map(id => industries[id]).join(', ')}
                            label="Отрасли"
                        >
                            {Object.keys(industries).map((id) => (
                                <MenuItem key={id} value={id}>
                                    <Checkbox checked={selectedIndustries.indexOf(id) > -1} />
                                    <ListItemText primary={industries[id]} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleIndustryDialogClose} color="primary">
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>

            <TableContainer component={Paper} style={{ marginTop: '16px' }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {!emailOnly && <TableCell>Название компании</TableCell>}
                            {!emailOnly && <TableCell>Категории</TableCell>}
                            {!emailOnly && <TableCell>Отрасли</TableCell>}
                            {showEmails && <TableCell>Email</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={index}>
                                {!emailOnly && (
                                    <TableCell>
                                        <NavLink to={`/company/profile/${row.id}`}>
                                            {row.name_with_akon}
                                        </NavLink>
                                    </TableCell>
                                )}
                                {!emailOnly && <TableCell>{row.categories}</TableCell>}
                                {!emailOnly && <TableCell>{row.industries}</TableCell>}
                                {showEmails && <TableCell>{row.email}</TableCell>}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default FilterCategoriesCompanies;
