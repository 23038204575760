import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import s from './Specifications.module.css';

const Specifications = (props) => {
    console.log(props.allComponentsFromStorage);
    console.log(props.specificationList);

    let [mainColl, setMainColl] = useState(1);
    const setMainCollF = (e) => {
        setMainColl(e.target.value >= 0 ? e.target.value : 1);
    };

    const allComponentsFromStorage = props.allComponentsFromStorage;

    const getCollWithStorage = (id) => {
        const component = allComponentsFromStorage.find(p => p.id === id);
        return component ? component.coll : '-';
    };

    const comparisonColl = (id, coll) => {
        const component = allComponentsFromStorage.find(p => p.id === id);
        if (component) {
            return Number(component.coll) >= Number(coll) ? "Да" : "Нужно купить";
        }
        return '-';
    };

    let [searchNomenclatures, setSearchNomenclatures] = useState(props.productId);
    const setSearchNomenclaturesF = (e) => {
        setSearchNomenclatures(e.target.value);
    };

    const navigate = useNavigate();
    const goToNewNomenclatures = () => {
        navigate(`/admin/specification/${searchNomenclatures}`);
    };

    // Высчитываем общую сумму для спецификации
    const sumTotal = arr => arr.reduce((sum, { unit_price }) => sum + Number(unit_price), 0);
    const total = sumTotal(props.specificationList);

    // Поиск по нажатию клавиши Enter
    let handleKeyPress = (e) => {
        if (e.charCode === 13) { // Клавиша Enter
            goToNewNomenclatures();
        }
    };

    return (
        <>
            <div className={s.main + " " + s.mainThreeHeader}>
                <div></div>
                <div className={s.name}>{props.nomenclaturesData && <p>{props.nomenclaturesData.NAME} - {props.productId}</p>}</div>

                <div className={s.inputColl}>
                    <h3>Поиск по номенклатурам</h3>
                    <input type="number" value={searchNomenclatures} onChange={setSearchNomenclaturesF} onKeyPress={handleKeyPress} />
                    <button onClick={goToNewNomenclatures}>Search</button>
                </div>
                <div className={s.inputColl}>
                    <h3>Общая сумма</h3>
                    <p>{total.toFixed(2)} грн</p>
                </div>
                <div>#</div>
                <div>Компонент</div>
                <div>Необходимое кол.</div>
                <div>Сумма за позицию</div>
            </div>
            {
                props.specificationList.map((p, index) => (
                    <div className={s.main} key={`specificationList_${index}`}>
                        <div>{index + 1}</div>
                        <div>
                            <NavLink to={`/suppliers/${p.storage_component_id}`}>{p.storage_component_name}</NavLink>
                        </div>
                        <div>{p.coll * mainColl}</div>
                        <div>{Number(p.unit_price).toFixed(2)}</div>
                    </div>
                ))
            }
        </>
    );
};

export default Specifications;
