import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getListWithCompanyUnpin } from '../../../redux/salesAdmin-reducer';
import Sales from './Sales';
import Cookies from "js-cookie";
import { useParams, useNavigate } from 'react-router-dom';

class SalesContainer extends React.Component {
    componentDidMount() {
        let isAdmin = !!Cookies.get('adminID');
        let currentPersonalId = this.props.params.personalId;
        const navigate = this.props.navigate;

        if (!isAdmin) {
            navigate(`/privateOffice/${Cookies.get('personalId')}`);
            if (Cookies.get('personalId') !== currentPersonalId) {
                currentPersonalId = Cookies.get('id');
            }
        }
        this.props.getListWithCompanyUnpin();
    }

    render() {
        return <Sales listWithCompanyUnpin={this.props.listWithCompanyUnpin} />;
    }
}

let mapStateToProps = (state) => ({
    listWithCompanyUnpin: state.salesAdmin.listWithCompanyUnpin
});

// Custom withRouter replacement using useParams and useNavigate
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const params = useParams();
        const navigate = useNavigate();
        return <Component {...props} params={params} navigate={navigate} />;
    }

    return ComponentWithRouterProp;
}

export default compose(
    connect(mapStateToProps, { getListWithCompanyUnpin }),
    withRouter
)(SalesContainer);
