import { repairAPI } from "../api/api";

const SET_REPAIR = "SET_REPAIR";
const SET_PERSONAL_REPAIRS_LIST = "SET_PERSONAL_REPAIRS_LIST";


let initialState = {
    repair: [],
    personalRepairsList: [],
}

const repairReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_REPAIR:
            return {
                ...state,
                repair: action.repair
            }
        case SET_PERSONAL_REPAIRS_LIST:
            return {
                ...state,
                personalRepairsList: action.personalRepairsList
            }
        default:
            return state
    }
}

export const setRepair = (repair) => ({type: SET_REPAIR, repair});
export const setPersonalRepairsList = (personalRepairsList) => ({type: SET_PERSONAL_REPAIRS_LIST, personalRepairsList});

export const getRepair= () =>{
    return (dispatch) => {
        repairAPI.getRepair()
        .then(data => {
            dispatch(setRepair(data));
        });
    }
}
export const setNewWorkerInRepair= (idRepair, idWorker) =>{
    return (dispatch) => {
        repairAPI.setNewWorkerInRepair(idRepair, idWorker)
        .then(data => {
            dispatch(getRepair());
        });
    }
}
export const setCollInRepair= (idRepair, value) =>{
    return (dispatch) => {
        repairAPI.setCollInRepair(idRepair, value)
        .then(data => {
            dispatch(getRepair());
        });
    }
}
export const setTTNInRepair= (idRepair, value, value2) =>{
    return (dispatch) => {
        repairAPI.setTTNInRepair(idRepair, value, value2)
        .then(data => {
            dispatch(getRepair());
        });
    }
}
export const addModuleFromRepair= (array) =>{
    return (dispatch) => {
        repairAPI.addModuleFromRepair(array)
        .then(data => {
            dispatch(getPersonalRepairsList());
        });
    }
}
export const addCompanyFromRepair= (idRepair, value) =>{
    return (dispatch) => {
        repairAPI.addCompanyFromRepair(idRepair, value)
        .then(data => {
            dispatch(getRepair());
        });
    }
}
export const setAssurance= (idRepair, value) =>{
    return (dispatch) => {
        repairAPI.setAssurance(idRepair, value)
        .then(data => {
            dispatch(getRepair());
        });
    }
}
export const setDateClose= (idRepair) =>{
    return (dispatch) => {
        repairAPI.setDateClose(idRepair)
        .then(data => {
            dispatch(getRepair());
        });
    }
}
export const getPersonalRepairsList= (idRepair=null) =>{
    return (dispatch) => {
        repairAPI.getPersonalRepairsList(idRepair)
        .then(data => {
            dispatch(setPersonalRepairsList(data));
        });
    }
}
export const setNewDefects= (array) =>{
    return (dispatch) => {
        repairAPI.setNewDefects(array)
        .then(data => {
            dispatch(getPersonalRepairsList(null));
        });
    }
}
export const setBindRepairToCheck= (checkId, repairId) =>{
    return (dispatch) => {
        repairAPI.setBindRepairToCheck(checkId, repairId)
        .then(data => {
            dispatch(getRepair());
        });
    }
}

export default repairReducer;

