import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
    getPrivateOffice,
    getPrivateOfficeBlocks,
    addNewPersonalFiltrBlock,
    deletePersonalFiltrBlock,
    setShowPersonalOfficeField,
    setColorBgInPersonalFilter,
    getCountRepairFromSite,
    editPersonalFiltrBlockName
} from '../../redux/privateOffice-reducer';
import { getCourse } from '../../redux/admin-reducer';
import { getCountOverdueCheckTest } from '../../redux/sales-reducer';
import PrivateOffice from './PrivateOffice';
import Cookies from 'js-cookie';

const PrivateOfficeContainer = (props) => {
    const personalId = parseInt(Cookies.get('id')) ?? 0;

    const [isFetched, setIsFetched] = useState(false);

    // Винесені функції запитів
    const fetchInitialData = () => {
        if (!isFetched) {
            props.getPrivateOffice(personalId);
            props.getPrivateOfficeBlocks(personalId);
            props.getCountOverdueCheckTest(1);
            props.getCountOverdueCheckTest(2);
            props.getCourse();
            props.getCountRepairFromSite();

            setIsFetched(true);
        }
    };

    // Виклик функції один раз при першому рендері
    if (!isFetched) {
        fetchInitialData();
    }

    return (
        <div>
            <PrivateOffice
                privateOffice={props.privateOffice}
                privateOfficeBlocks={props.privateOfficeBlocks}
                addNewPersonalFiltrBlock={props.addNewPersonalFiltrBlock}
                personalId={personalId}
                deletePersonalFiltrBlock={props.deletePersonalFiltrBlock}
                setShowPersonalOfficeField={props.setShowPersonalOfficeField}
                setColorBgInPersonalFilter={props.setColorBgInPersonalFilter}
                countOverdueCheckTest={props.countOverdueCheckTest}
                countOverdueCheckTestBack={props.countOverdueCheckTestBack}
                course={props.course}
                countRepairFromSite={props.countRepairFromSite}
                editPersonalFiltrBlockName={props.editPersonalFiltrBlockName}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    privateOffice: state.privateOffice.privateOffice,
    privateOfficeBlocks: state.privateOffice.privateOfficeBlocks,
    countRepairFromSite: state.privateOffice.countRepairFromSite,
    countOverdueCheckTest: state.sales.countOverdueCheckTest,
    countOverdueCheckTestBack: state.sales.countOverdueCheckTestBack,
    course: state.admin.course,
});

export default compose(
    connect(mapStateToProps, {
        getPrivateOffice,
        getPrivateOfficeBlocks,
        addNewPersonalFiltrBlock,
        deletePersonalFiltrBlock,
        setShowPersonalOfficeField,
        setColorBgInPersonalFilter,
        getCountOverdueCheckTest,
        getCourse,
        getCountRepairFromSite,
        editPersonalFiltrBlockName
    })
)(PrivateOfficeContainer);
