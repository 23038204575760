import React, { useState } from 'react';
import s from './FiltrWants.module.css';
import { useCookies } from 'react-cookie';
import Modal from '../../Production/common/Modal/Modal';
import FiltrBlocks from './FiltrBlocks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash} from '@fortawesome/free-solid-svg-icons'
import {NavLink} from "react-router-dom";

const FiltrWants = (props) =>{
    console.log(props.filtrWants)
    const [cookies, setCookie] = useCookies();
    //модальное окно
    let [modal, showModal] = useState(false)
    let [modalAddForBlock, showModalAddForBlock] = useState(false)
    const setShowFieldWantsF = (id, block, personalId)=>{
        props.setShowFieldWants(id,block,personalId)
        showModalAddForBlock(false)
    }
    //вопрос об удалении блока
    let [deleteBlock, setDeleteBlock] = useState(false);
    const setDeleteBlockF = ()=>{
        props.deleteFiltrBlock(deleteBlock, cookies.id)
        setDeleteBlock(false)
    }
    return(
        <div>
            <div onClick={()=>showModal(true)} className={s.button}>Добавить блок</div>
            {//выводим блоки и в них все фильтры 
            }
            <div className={s.filtrBlock}>
            {props.filtrBlock.map(k=>{
                return(
                    <div key={"filtrBlock_"+k.id}>
                        <div className={s.icon}>
                            <div><h3>{k.name}</h3></div>
                            <div>
                                <FontAwesomeIcon icon={faPlus} onClick={()=>showModalAddForBlock(k.id)} className={s.green} />
                                <FontAwesomeIcon icon={faTrash} className={s.red} onClick={()=>setDeleteBlock(k.id)} />
                            </div>
                        </div>
                        {props.filtrWants.map(j=>{
                            return j.company_segmentation_wants_blocks_id == k.id && <div className={s.filtrWants} key={"filtrWants"+j.id} onClick={()=>props.getCompanyWithFiltrWants(j.id, cookies.id)}>{j.wants}</div>
                        })}
                        {/* <div className={s.button}><button  >Добавить статус</button></div> */}
                    </div>
                )
            })
            }
            </div>
            {//выводим список компаний по нажатию на статус
            }
            <div>
               {props.companyWithFiltrWants.map((k,index)=>{
                   return(
                        <div key={index} className={s.main+" "+s.bold}>
                            <div className={s.center}>{index+1}</div>
                            <div><NavLink to={"/company/profile/"+k.id} target="_blank">{k.name_with_akon}</NavLink></div>
                        </div>
                   ) 
               })
               }
            </div>
           {modalAddForBlock && //окно вывода всех статусов
           <Modal active={modalAddForBlock} setActive={showModalAddForBlock}>
                <div className={s.filtr}>
                    {props.filtrWants.map(k=><div key={"filtrWants"+k.id} onClick={()=>setShowFieldWantsF(k.id,modalAddForBlock,cookies.id)} className={k.company_segmentation_wants_blocks_id==modalAddForBlock ? s.field__active : ""}>{k.wants}</div>)}
               </div>
           </Modal>}
           {modal && //окно добавления нового блока
           <Modal active={modal} setActive={showModal}>
               <FiltrBlocks addNewFiltrBlock={props.addNewFiltrBlock} showModal={showModal}/>
           </Modal>}
           {deleteBlock && //окно добавления нового блока
           <Modal active={deleteBlock} setActive={setDeleteBlock}>
               <div className={s.addComment}>
                    <div><h3>Удаление блока</h3></div>
                    <div className={s.center}><button onClick={()=>setDeleteBlockF()} className={s.button}>Удалить</button></div>
                </div>
           </Modal>}
        </div>
        
    )
}
export default (FiltrWants);