import React, { useState, useEffect } from 'react';
import s from './AllCompanyWithManager.module.css';
import main from '../../Main.module.css';
import Cookies from 'js-cookie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../Production/common/Modal/Modal';
import SetCompanyToPlanner from './Modal/SetCompanyToPlanner';
import AnyFormatDate from '../../Production/common/AnyFormatDate/AnyFormatDate';
import { NavLink } from 'react-router-dom';
import MenuManager from '../common/MenuManager/MenuManager';

const AllCompanyWithManager = (props) =>{
    //общий массив
    let [allData, setAllData] = useState([])
    //записываем данные в объеденённый массив, если один из пропсов поменялся
    useEffect(() => {
        setAllData(mergeDataById(props.allCompanyWithManager, props.allSumCompanyWithManager));
    }, [props.allSumCompanyWithManager, props.allCompanyWithManager])

    // Функция для объединения массивов по id
    function mergeDataById(company, sum) {
        return company.map(companyItem => {
        const matchingSum = sum.find(sumItem => sumItem.id === companyItem.id);
        return matchingSum ? {...companyItem, ...matchingSum} : companyItem;
        });
    }

    let personalId;
    if(Cookies.get('id')=="44" || Cookies.get('id')=="34"){
        personalId = props.match.params.manager
    }else{
        personalId = Cookies.get('id')
    }
    const getClass = (sum)=>{
        if(1000000<=Number(sum)) return main.red
        if(400000<=Number(sum)) return main.blue
        if(100000<=Number(sum)) return main.green
        if(Number(sum)<1000000) return ""
    }
    let [planner, setPlanner] = useState(false)
    
    //флажок, какая сортировка в данный момент
    let [sortIcon, setSortIcon] = useState("sum")

    //сортировка
    const sortByKey = (key) => {
        const sorted = [...allData].sort((a, b) => (b[key] || 0) - (a[key] || 0));
        setAllData(sorted);
        setSortIcon(key)
    };
    //делаем красивую цифру, читабельную для глаза
    const getBeatifulNumber = (number) => {
        if(number) return number.toFixed(2)
        return <span>-</span>
    }
    return(
        <div>
            {planner &&
            <Modal active={planner} setActive={setPlanner}>
                <SetCompanyToPlanner
                    active={planner}
                    setActive={setPlanner}
                    setCompanyToPlanner={props.setCompanyToPlanner}
                    personalId={personalId}
                    countCompanyPlanerInThisDate = {props.countCompanyPlanerInThisDate}
                    getAllCompanyPlanerInThisDate = {props.getAllCompanyPlanerInThisDate}
                />
            </Modal>
            }
            {
                (Cookies.get('id') == "44" || Cookies.get('id') == "34")
                &&
                <MenuManager link={"/sales/allCompanyNew"} idNow={props.match.params.manager}/>
            }
            <div className={s.dostup+" "+main.right}>
                <NavLink to={"/sales/allCompanyNew/"+Cookies.get('id')}><span>Новый вариант странички</span></NavLink>
                <NavLink to={"/sales/meeting/"+Cookies.get('id')}><span>Запланировать встречу</span></NavLink>
                <NavLink to={"/kp/"+Cookies.get('id')}><span>Планировщик</span></NavLink>
            </div>
            <h1>Страница всех закрепленных компаний за менеджером</h1>
            
            <div className={s.main+" "+main.bold}>
                <div>#</div>
                <div>Название</div>
                <div><span onClick={()=>sortByKey("2022")} className={s.sort}>2022 {sortIcon==='2022' && <FontAwesomeIcon icon={faArrowDown} />}</span></div>
                <div><span onClick={()=>sortByKey("2023")} className={s.sort}>2023 {sortIcon==='2023' && <FontAwesomeIcon icon={faArrowDown} />}</span></div>
                <div><span onClick={()=>sortByKey("2024")} className={s.sort}>2024 {sortIcon==='2024' && <FontAwesomeIcon icon={faArrowDown} />}</span></div>
                <div><span onClick={() =>sortByKey('sum')} className={s.sort}>Сумма за все время {sortIcon==='sum' && <FontAwesomeIcon icon={faArrowDown} />}</span></div>
            </div>
            {allData.map((k,index)=>{
                return(
                    <div key={"allCompanyWithManager"+index} className={s.main}>
                        <div>{index+1}</div>
                        <div className={s.namePlusPlanner}>
                            <div><NavLink to={"/company/profile/"+k.id} target={"_blank"}>{k.name_with_akon}</NavLink></div>
                            <div className={main.green+" "+main.center}>
                                {k.checkToPlanner==null 
                                ? <FontAwesomeIcon icon={faPlus} onClick={()=>setPlanner(k.id)} />
                                : <span onClick={()=>setPlanner(k.id)}>(<AnyFormatDate date={k.date_plan} />)</span>
                                }
                            </div>
                        </div>
                        <div className={getClass(k["2022"])}>{getBeatifulNumber(k["2022"])}</div>
                        <div className={getClass(k["2023"])}>{getBeatifulNumber(k["2023"])}</div>
                        <div className={getClass(k["2024"])}>{getBeatifulNumber(k["2024"])}</div>
                        <div className={getClass(k.sum)}>{k.sum}</div>
                    </div>
                )
            })
            }
        </div>
        
    )
}
export default AllCompanyWithManager;
