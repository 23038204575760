import React from 'react';
import s from '../Application.module.css';

const ApplicationPersonal = (props) =>{
    console.log(props.applicationPersonal)
    return(
        <div>
            <h2 className={s.center}>Заявка №{props.idApp}</h2>
            {props.applicationPersonal.map((j,indexMain)=>{
                return(
                    <div key={"applicationPersonalMain"+indexMain}>
                        <h2>Модуль {indexMain+1}</h2>
                        <div className={s.main+" "+s.weight}>
                            <div>Кол. канал</div>
                            <div>Тип сигнала</div>
                            <div>Тип канала</div>
                            <div>Диапазон</div>
                            <div>Проводность</div>
                        </div>
                        {j.map((k, index)=>{
                            return(
                                <div key={"applicationPersonal"+index} className={s.main}>
                                    <div>{k.coll}</div>
                                    <div>{k.chanellName}</div>
                                    <div>{k.typeName}</div>
                                    <div>{k.rangeName}</div>
                                    <div>{k.wireName}</div>
                                </div>
                            )
                        })}
                    </div>
                ) 
            })}
        </div>
    )
}
export default ApplicationPersonal;