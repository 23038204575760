import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
    getSentInvoicesForMonth,
    getSumSentInvoicesForMonth,
    getSumSentInvoicesForYear,
    getSentInvoiceForMonthWithDateManager,
    setNewDateManagerInScheta
} from '../../../../redux/shipment-reducer';
import Shipment from './Shipment';
import { useParams } from 'react-router-dom';

class ShipmentContainer extends React.Component {
    componentDidMount() {
        const { month} = this.props.params;
        const year = new Date().getFullYear();
        this.props.getSentInvoiceForMonthWithDateManager(month, year);
    }

    componentDidUpdate(prevProps) {
        const { month} = this.props.params;
        // set year to current year
        const year = new Date().getFullYear();
        if (prevProps.params.month !== month) {
            this.props.getSentInvoiceForMonthWithDateManager(month, year);
        }
    }

    render() {
        const { invoiceForMonthWithDateManager, month, year, getSentInvoiceForMonthWithDateManager, setNewDateManagerInScheta } = this.props;
        return (
            <Shipment
                invoiceForMonthWithDateManager={invoiceForMonthWithDateManager}
                year={this.props?.params?.year}
                month={this.props?.params?.month}
                getSentInvoiceForMonthWithDateManager={getSentInvoiceForMonthWithDateManager}
                setNewDateManagerInScheta={setNewDateManagerInScheta}
            />
        );
    }
}

let mapStateToProps = (state) => ({
    invoiceForMonthWithDateManager: state.shipment.invoiceForMonthWithDateManager,
});

// Custom withRouter replacement using useParams
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const params = useParams();
        return <Component {...props} params={params} />;
    }

    return ComponentWithRouterProp;
}

export default compose(
    connect(mapStateToProps, {
        getSentInvoicesForMonth,
        getSumSentInvoicesForMonth,
        getSumSentInvoicesForYear,
        getSentInvoiceForMonthWithDateManager,
        setNewDateManagerInScheta
    }),
    withRouter
)(ShipmentContainer);
