import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getWorkersModulsOld, setModulsStatus } from '../../../../redux/worker-reducer';
import TechEngineer from './TechEngineer';
import { useParams } from 'react-router-dom';

class TechEngineerContainer extends React.Component {
    componentDidMount() {
        this.props.getWorkersModulsOld();
    }

    render() {
        return (
            <TechEngineer
                workersModuls={this.props.workersModuls}
                setModulsStatus={this.props.setModulsStatus}
            />
        );
    }
}

let mapStateToProps = (state) => {
    return {
        workersModuls: state.worker.workersModuls
    };
};

// Custom withRouter replacement using useParams
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const params = useParams();
        return <Component {...props} params={params} />;
    }

    return ComponentWithRouterProp;
}

export default compose(
    connect(mapStateToProps, { getWorkersModulsOld, setModulsStatus }),
    withRouter
)(TechEngineerContainer);
