import React, { useState } from 'react';
import s from '../../../Task/Task.module.css';
import s2 from '../Repair.module.css';

const TTN = (props) =>{
    let [newValue, setNewValue] = useState(0)
    const setNewValueF = (e)=>{
        setNewValue(e.target.value)
    }
    let [newValue2, setNewValue2] = useState("")
    const setNewValue2F = (e)=>{
        setNewValue2(e.target.value)
    }
    const setNew = ()=>{
        if(newValue2!="" && newValue!=0){
            props.setNewValue(props.id, newValue, newValue2)
            props.setShow(false)
        }
    }
    return(
        <div className={s.taskMain}>
            <h2>Изменить количество в посылке</h2>
            <div>
                <div><input type="number" value={newValue} onChange={setNewValueF} className={s.input+" "+s2.w100}/></div>
                <div><input type="date" onChange={setNewValue2F} className={s.input+" "+s2.w100}/></div>
                <div className={s.center+" "+s.mt20}><button onClick={()=>setNew()}>Изменить</button></div>
            </div>
        </div>
    )
}
export default TTN;