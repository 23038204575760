import React from 'react';
import Menu from '../Menu/Menu';
import Cookies from "js-cookie";
import ManagerName from "../global/ManagerName";

const Header = (props) => {
    return (
        <div>
            {process.env.REACT_APP_ENV === 'staging'
                &&
                <div className="header">
                    <h1>Т Е С Т О В Ы Й    С Е Р В Е Р</h1>
                </div>
            }
            {props.isAdmin === true && Cookies.get('id') !== Cookies.get('adminID')
                &&
                <div style={{textAlign: 'center', fontSize: '14px'}}>
                    <h1>
                        <b>Просмотр сайта от имени:</b>
                        <i>{<ManagerName managerID={Cookies.get('id')}/>} (id: {Cookies.get('id')})</i>
                    </h1>
                </div>
            }
            <Menu {...props} />
        </div>
    )
}

export default Header;