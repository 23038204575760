import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
    addCompanyPartner,
    addNewContact,
    deleteCompanyAgent,
    deleteMessageFromAgent,
    editCompanyComment,
    editCompanyDiscount,
    editCompanyManager1,
    getClientSegmentationsList,
    getCompanyList,
    getCompanyProfile,
    getRegionsList,
    getSegmentationsList,
    setAgentSubscription,
    setClientCompanySegmentation,
    setCompany2Segmentation,
    setCompanyAkonName,
    setCompanyContract,
    setCompanyDeliveryAddress,
    updateCompanyAgent,
    setCompanyEdrpou,
    setCompanyManager2,
    setCompanyMessage,
    setCompanyName,
    setCompanyRegion,
    setCompanySegmentation,
    setCompanySite,
    setCompanyCity,
    setNewCompanyMessage,
    unbindCompanyPartner,
    unbindSupplier,
    bindSupplier,
    bindTag,
    bindNewCategory,
    unbindNewCategory,
    bindNewIndustry,
    unbindNewIndustry,
    unbindMainManager,
    setCompanyToPlannerFromCompanyProfile,
    unbindTag,
    forceRejectCompanySecondManager,
    getCityList
} from '../../../../redux/company-reducer';
import Cookies from 'js-cookie';
import CompanyProfile from "./CompanyProfile";
import { rejectCompany2ndManager, setCompanyToPlanner, getAllCompanyPlanerInThisDate } from "../../../../redux/sales-reducer";

const CompanyProfileContainer = (props) => {
    const { companyID } = useParams(); // Отримання companyID з параметрів
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const loadData = async () => {
            setIsLoading(true);

            const request = {
                'companyID': companyID,
                'managerID': Cookies.get('id'),
            };

            try {
                // Використовуємо Promise.all для одночасного виконання запитів
                await Promise.all([
                    props.getCompanyProfile(request),
                    props.getCompanyList(),
                    props.getRegionsList(),
                    props.getSegmentationsList(),
                    props.getClientSegmentationsList(),
                    props.getCityList(),
                ]);
            } catch (error) {
                console.error('Error fetching company data:', error);
            } finally {
                setIsLoading(false); // Вимикаємо стан завантаження після отримання всіх даних
            }
        };

        loadData();
    }, [companyID]); // Викликаємо ефект тільки при зміні companyID

    return (
        <CompanyProfile {...props} isLoading={isLoading} />
    );
};

let mapStateToProps = (state) => ({
    data: state.company.companyProfile ?? null,
    companyList: state.company.companyList.data ?? null,
    regionsList: state.company.regionsList.data ?? null,
    segmentationsList: state.company.segmentationsList.data ?? null,
    clientSegmentationsList: state.company.clientSegmentationsList.data ?? null,
    cityList: state.company.cityList.data ?? null,
    countCompanyPlanerInThisDate: state.sales.countCompanyPlanerInThisDate,
});

export default compose(
    connect(mapStateToProps, {
        getCompanyProfile,
        getCompanyList,
        getRegionsList,
        getCityList,
        getSegmentationsList,
        getClientSegmentationsList,
        unbindCompanyPartner,
        unbindSupplier,
        bindSupplier,
        bindTag,
        bindNewCategory,
        unbindNewCategory,
        bindNewIndustry,
        unbindNewIndustry,
        unbindMainManager,
        addNewContact,
        setAgentSubscription,
        updateCompanyAgent,
        deleteCompanyAgent,
        deleteMessageFromAgent,
        setCompanyEdrpou,
        setCompanyToPlannerFromCompanyProfile,
        setCompanyRegion,
        unbindTag,
        setCompanyName,
        setCompanySite,
        setCompanyAkonName,
        setCompanyCity,
        editCompanyDiscount,
        editCompanyComment,
        editCompanyManager1,
        setCompanyManager2,
        addCompanyPartner,
        setCompanyDeliveryAddress,
        setCompanyContract,
        setClientCompanySegmentation,
        setCompanySegmentation,
        setCompany2Segmentation,
        setNewCompanyMessage,
        setCompanyMessage,
        forceRejectCompanySecondManager,
        getAllCompanyPlanerInThisDate
    })
)(CompanyProfileContainer);
