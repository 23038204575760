import React, { useState, useEffect } from 'react';
import {
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
    Box,
    CircularProgress,
    CardActions,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import TechConsultationDialog from './TechConsultationDialog';
import TechConsultation from './TechConsultation';
import { techAPI } from '../../../api/api';
import { toast } from 'react-toastify';
import {NavLink} from "react-router-dom";

function TechConsultationList() {
    const [projects, setProjects] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [filter, setFilter] = useState('all');
    const [loading, setLoading] = useState(true);
    const [commentDialogOpen, setCommentDialogOpen] = useState(false);
    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const [currentProject, setCurrentProject] = useState(null);
    const [newComment, setNewComment] = useState('');
    const [newStatus, setNewStatus] = useState(null);
    const [dataScope, setDataScope] = useState('currentYear'); // default is current year
    const [engineerFilter, setEngineerFilter] = useState('');
    const [managerFilter, setManagerFilter] = useState('');

    // States for handling companies
    const [companies, setCompanies] = useState([]);
    const [loadingCompanies, setLoadingCompanies] = useState(false);

    // New states for adding new consultation
    const [newRequestDialogOpen, setNewRequestDialogOpen] = useState(false);
    const [newCompanyName, setNewCompanyName] = useState('');
    const [newCompanyID, setNewCompanyID] = useState(null); // This is the ID of the selected company from the autocomplete
    const [newDescription, setNewDescription] = useState('');

    useEffect(() => {
        fetchProjects();
    }, [dataScope]);
    const formattedDescription = (text) => {
        return text?.split('\r\n')?.map((line, index) => (
            <div key={index}>{line.trim()}</div>
        ));
    };
    const fetchProjects = () => {
        setLoading(true);
        const params = {
            fullStory: dataScope === 'all',
            engineer: engineerFilter || undefined,
            manager: managerFilter || undefined,
        };
        techAPI.getListOfTechConsultations(params)
            .then(response => {
                setProjects(response);
                setLoading(false);
            })
            .catch(error => {
                console.error('Ошибка при загрузке проектов:', error);
                setLoading(false);
            });
    };

    const fetchCompanies = async (query) => {
        setLoadingCompanies(true);
        try {
            const response = await techAPI.getCompanyListNameWithAkon({ query });
            if (Array.isArray(response.data)) {
                setCompanies(response.data);
            } else {
                setCompanies([]);
            }
        } catch (error) {
            console.error('Ошибка получения списка компаний:', error);
            toast.error('Ошибка получения списка компаний');
            setCompanies([]);
        } finally {
            setLoadingCompanies(false);
        }
    };

    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleCommentDialogOpen = (project) => {
        setCurrentProject(project);
        setCommentDialogOpen(true);
    };

    const statusToText = (status) => {
        if (!status || status === 'null') return 'В работе';
        if (status === '1') return 'Закрыт';
        if (status === '2') return 'Доп сложности';
        return status;
    };

    const handleCommentDialogClose = () => {
        setCommentDialogOpen(false);
        setNewComment('');
        setCurrentProject(null);
    };

    const handleStatusDialogOpen = (project) => {
        setCurrentProject(project);
        setNewStatus(project.status);
        setStatusDialogOpen(true);
    };

    const handleStatusDialogClose = () => {
        setStatusDialogOpen(false);
        setNewStatus(null);
        setCurrentProject(null);
    };

    const handleAddComment = () => {
        if (currentProject && newComment.trim()) {
            techAPI.addComment(currentProject.id, newComment)
                .then(response => {
                    if (response.status === 0) {
                        toast.error('Ошибка при добавлении комментария');
                        return;
                    }
                    window.location.reload();
                    handleCommentDialogClose();
                })
                .catch(error => {
                    console.error('Ошибка при добавлении комментария:', error);
                });
        }
    };

    const handleStatusChange = () => {
        if (currentProject && newStatus) {
            techAPI.updateStatus(currentProject.id, newStatus)
                .then(response => {
                    if (response.status === 0) {
                        toast.error('Ошибка при изменении статуса');
                        return;
                    }
                    if (response.status === 1) {
                        setProjects(projects.map(project =>
                            project.id === currentProject.id
                                ? { ...project, status: newStatus }
                                : project
                        ));
                        handleStatusDialogClose();
                    }
                })
                .catch(error => {
                    console.error('Ошибка при изменении статуса:', error);
                });
        }
    };

    // New request dialog handlers
    const handleOpenNewRequestDialog = () => {
        setNewRequestDialogOpen(true);
    };

    const handleCloseNewRequestDialog = () => {
        setNewRequestDialogOpen(false);
        setNewCompanyName('');
        setNewDescription('');
    };

    const handleSubmitConsultationForm = () => {
        if (newCompanyName?.name_with_akon && newDescription.trim()) {
            const newConsultation = {
                company_name: newCompanyName.name_with_akon,
                company_id: newCompanyName.id,
                description: newDescription,
                status: null,
            };

            techAPI.createTechConsultation(newConsultation)
                .then(response => {
                    if (response.status === 1) {
                        toast.success('Заявка успешно создана');
                        fetchProjects(); // Refresh the list after creating a new request
                        handleCloseNewRequestDialog();
                    } else {
                        toast.error('Ошибка при создании заявки');
                    }
                })
                .catch(error => {
                    console.error('Ошибка при создании заявки:', error);
                    toast.error('Ошибка при создании заявки');
                });
        }
    };

    const filteredProjects = projects.filter(project => {
        if (filter === 'all') return true;
        if (filter === 'closed') return project.status === 'Закрыт';
        if (filter === 'active') return project.status !== 'Закрыт';
        return false;
    });

    const totalProjectsCount = projects.length;
    const totalClosedProjectsCount = projects.filter(project => project.status === 'Закрыт').length;
    const totalActiveProjectsCount = projects.filter(project => project.status !== 'Закрыт').length;

    return (
        <Box p={3} maxWidth="xl" mx="auto">
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh" sx={{ width: '100%' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={handleOpenNewRequestDialog}
                                sx={{ marginBottom: 2, width: '100%' }}
                            >
                                Создать заявку
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => window.open('https://master.akon.com.ua/admin/techconsultation.php', '_blank')}
                                sx={{ marginBottom: 2, width: '100%' }}
                            >
                                На старую страничку
                            </Button>
                        </Grid>
                        <FormControl variant="outlined" fullWidth margin="normal">
                            <InputLabel id="data-scope-label">Загрузить</InputLabel>
                            <Select
                                labelId="data-scope-label"
                                id="data-scope-select"
                                value={dataScope}
                                onChange={(e) => setDataScope(e.target.value)}
                                label="Загрузить"
                            >
                                <MenuItem value="currentYear">Текущий год</MenuItem>
                                <MenuItem value="all">Все</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl variant="outlined" fullWidth margin="normal">
                            <InputLabel id="status-filter-label">Статус</InputLabel>
                            <Select
                                labelId="status-filter-label"
                                id="status-filter-select"
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                                label="Статус"
                            >
                                <MenuItem value="all">Все ({totalProjectsCount})</MenuItem>
                                <MenuItem value="active">Актуальные ({totalActiveProjectsCount})</MenuItem>
                                <MenuItem value="closed">Закрытые ({totalClosedProjectsCount})</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={9}>
                        <Grid container spacing={2}>
                            {filteredProjects.map((project, index) => (
                                <Grid item xs={12} key={index}>
                                    <Card
                                        variant="outlined"
                                        style={{
                                            backgroundColor: project.status === 'Закрыт' ? '#e6ffe6' : '#fff',
                                            opacity: project.status === 'Закрыт' ? 0.8 : 1,
                                            borderColor: project.status !== 'Закрыт' ? '#ffeb3b' : 'transparent',
                                            borderWidth: project.status !== 'Закрыт' ? 2 : 1,
                                            borderStyle: 'solid',
                                        }}
                                    >
                                        <CardContent>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6} lg={6}>
                                                    <Typography variant="h6">
                                                        {project.id} - <NavLink to={'/company/profile/' + project.company_id} target='_blank'> {project.company_name}</NavLink>
                                                    </Typography>
                                                    <Typography color="textSecondary">
                                                        {project.time}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={3} lg={3}>
                                                    <Typography
                                                        variant="body1"
                                                        onClick={() => handleStatusDialogOpen(project)}
                                                        sx={{
                                                            cursor: 'pointer',
                                                            color: 'blue',
                                                            textDecoration: 'underline'
                                                        }}
                                                    >
                                                        Статус: {statusToText(project.status)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={3} lg={3}>
                                                    <Typography variant="body1">
                                                        Создал: {project.creator}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        {formattedDescription(project.description)}
                                                    </Typography>
                                                    {project?.old_comment && project.old_comment.length > 0 && (
                                                        <Box
                                                            mt={2}
                                                            p={2}
                                                            sx={{
                                                                backgroundColor: '#f0f4c3',
                                                                borderRadius: '8px',
                                                                maxWidth: '80%',
                                                                boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
                                                            }}
                                                        >
                                                            <Typography variant="body2" color="textPrimary">
                                                                Старый комментарий:
                                                            </Typography>
                                                            <Typography variant="body2" color="textSecondary">
                                                                {formattedDescription(project.old_comment)}
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                    {project.comments?.map((comment, i) => (
                                                        <Box
                                                            key={i}
                                                            mt={2}
                                                            p={2}
                                                            sx={{
                                                                backgroundColor: '#e3f2fd',
                                                                borderRadius: '8px',
                                                                maxWidth: '80%',
                                                                boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
                                                            }}
                                                        >
                                                            <Typography variant="body2" color="textPrimary">
                                                                {comment.user || 'Неизвестный пользователь'} - {new Date(comment.created_at).toLocaleString()}:
                                                            </Typography>
                                                            <Typography variant="body2" color="textSecondary">
                                                                {comment.text}
                                                            </Typography>
                                                        </Box>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        {project.status !== 'Закрыт' && (
                                            <CardActions>
                                                <Button size="small" color="primary"
                                                        onClick={() => handleCommentDialogOpen(project)}>
                                                    Добавить комментарий
                                                </Button>
                                            </CardActions>
                                        )}
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            )}

            <TechConsultationDialog open={dialogOpen} onClose={handleCloseDialog} />

            {/* Dialog for adding a comment */}
            <Dialog open={commentDialogOpen} onClose={handleCommentDialogClose}>
                <DialogTitle>Добавление комментария</DialogTitle>
                <DialogContent sx={{ width: '500px' }}>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Комментарий"
                        type="text"
                        multiline
                        inputProps={{
                            style: { textAlign: 'left' },
                        }}
                        rows={5}
                        fullWidth
                        variant="outlined"
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCommentDialogClose} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleAddComment} color="primary">
                        Добавить
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog for changing status */}
            <Dialog open={statusDialogOpen} onClose={handleStatusDialogClose}>
                <DialogTitle>Изменить статус заявки</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="status-select-label">Статус</InputLabel>
                        <Select
                            labelId="status-select-label"
                            id="status-select"
                            value={newStatus}
                            onChange={(e) => setNewStatus(e.target.value)}
                        >
                            <MenuItem value='null'>В работе</MenuItem>
                            <MenuItem value='1'>Закрыт</MenuItem>
                            <MenuItem value="2">Доп сложности</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleStatusDialogClose} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleStatusChange} color="primary">
                        Изменить
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog for creating a new request */}
            <Dialog open={newRequestDialogOpen} onClose={handleCloseNewRequestDialog} fullWidth>
                <DialogTitle>Создание заявки</DialogTitle>
                <DialogContent>
                    <TechConsultation
                        companies={companies}
                        loading={loadingCompanies}
                        fetchCompanies={fetchCompanies}
                        onChange={(company) => {
                            setNewCompanyID(company?.id)
                            setNewCompanyName(company)
                        }}
                    />
                    <TextField
                        label="Описание задачи"
                        value={newDescription}
                        onChange={(e) => setNewDescription(e.target.value)}
                        multiline
                        rows={4}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        inputProps={{
                            style: { textAlign: 'left' },
                        }}
                        required
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseNewRequestDialog} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleSubmitConsultationForm} color="primary" variant="contained">
                        Отправить
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default TechConsultationList;
