import { adminAPI } from "../api/api";

const SET_ALL_COMPONENTS = "SET_ALL_COMPONENTS";
const SET_ALL_PAID_CHECK_FOR_MONTH = "SET_ALL_PAID_CHECK_FOR_MONTH";
const SET_SUM_ALL_PAID_CHECK_FOR_MONTH = "SET_SUM_ALL_PAID_CHECK_FOR_MONTH";
const SET_ALL_PAID_COSTS_FOR_MONTH_FROM_SUBENTRIES = "SET_ALL_PAID_COSTS_FOR_MONTH_FROM_SUBENTRIES";
const SET_SUM_PAID_COSTS_FOR_MONTH = "SET_SUM_PAID_COSTS_FOR_MONTH";
const SET_SUM_AND_NAME_SUBENTRIES_FOR_MONTH = "SET_SUM_AND_NAME_SUBENTRIES_FOR_MONTH";
const SET_SUM_AND_NAME_ARTICLES_FOR_MONTH = "SET_SUM_AND_NAME_ARTICLES_FOR_MONTH";
const SET_SUM_ALL_PAID_CHECK_FOR_MONTH_RU = "SET_SUM_ALL_PAID_CHECK_FOR_MONTH_RU";
const SET_SUM_MONEY_DELIVERY = "SET_SUM_MONEY_DELIVERY";
const SET_SUBENTRIES_FOR_MONEY_DELIVERY = "SET_SUBENTRIES_FOR_MONEY_DELIVERY";
const SET_SPECIFICATION_LIST = "SET_SPECIFICATION_LIST";
const SET_RECEIVABLES = "SET_RECEIVABLES";
const SET_ALL_BOM_NOMENCLATURES = "SET_ALL_BOM_NOMENCLATURES";
const SET_ALL_COMPONENT_FROM_NOMENCLATURES = "SET_ALL_COMPONENT_FROM_NOMENCLATURES";
const SET_STORAGE_AND_PRICE = "SET_STORAGE_AND_PRICE";
const SET_ACCOUNTS_PAYABLE = "SET_ACCOUNTS_PAYABLE";
const SET_COURSE = "SET_COURSE";
const SET_CVO_MONEY = "SET_CVO_MONEY";
const SET_ALL_PAID_COSTS_LIST_FOR_MONTH_FROM_SUBENTRIES = "SET_ALL_PAID_COSTS_LIST_FOR_MONTH_FROM_SUBENTRIES";
const SET_SUM_SUBENTRIES_GRUP_MONTH = "SET_SUM_SUBENTRIES_GRUP_MONTH";


let initialState = {
    components: [],
    check: [],
    sum: [],
    sumRu: [],
    checkCosts: [],
    sumCosts: [],
    subentries: [],
    articles: [],
    sumMoneyDelivery:0,
    articlesSubentriesForMoneyDelivery: [],
    specificationList: [],
    receivables:[],
    nomenclatures:[],
    allComponentFromNomenclatures:[],
    storageAndAveragePrice:[],
    accountsPayable:[],
    course: [],
    cvoMoney: 0,
    checkCostsList: [],
    sumSubentriesGrupMonth: [],
}

const AdminReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_ALL_COMPONENTS:
            return {
                ...state,
                components: action.components
            }
        case SET_ALL_PAID_CHECK_FOR_MONTH:
            return {
                ...state,
                check: action.check
            }
        case SET_SUM_ALL_PAID_CHECK_FOR_MONTH:
            return {
                ...state,
                sum: action.sum
            }
        case SET_ALL_PAID_COSTS_FOR_MONTH_FROM_SUBENTRIES:
            return {
                ...state,
                checkCosts: action.checkCosts
            }
        case SET_SUM_PAID_COSTS_FOR_MONTH:
            return {
                ...state,
                sumCosts: action.sumCosts
            }
        case SET_SUM_AND_NAME_SUBENTRIES_FOR_MONTH:
            return {
                ...state,
                subentries: action.subentries
            }
        case SET_SUM_AND_NAME_ARTICLES_FOR_MONTH:
            return {
                ...state,
                articles: action.articles
            }
        case SET_SUM_ALL_PAID_CHECK_FOR_MONTH_RU:
            return {
                ...state,
                sumRu: action.sumRu
            }
        case SET_SUM_MONEY_DELIVERY:
            return {
                ...state,
                sumMoneyDelivery: action.sumMoneyDelivery
            }
        case SET_SUBENTRIES_FOR_MONEY_DELIVERY:
            return {
                ...state,
                articlesSubentriesForMoneyDelivery: action.articlesSubentriesForMoneyDelivery
            }
        case SET_SPECIFICATION_LIST:
            return {
                ...state,
                specificationList: action.specificationList
            }
        case SET_RECEIVABLES:
            return {
                ...state,
                receivables: action.receivables
            }
        case SET_ALL_BOM_NOMENCLATURES:
            return {
                ...state,
                nomenclatures: action.nomenclatures
            }
        case SET_ALL_COMPONENT_FROM_NOMENCLATURES:
            return {
                ...state,
                allComponentFromNomenclatures: action.allComponentFromNomenclatures
            }
        case SET_STORAGE_AND_PRICE:
            return {
                ...state,
                storageAndAveragePrice: action.storageAndAveragePrice
            }
        case SET_ACCOUNTS_PAYABLE:
            return {
                ...state,
                accountsPayable: action.accountsPayable
            }
        case SET_COURSE:
            return {
                ...state,
                course: action.course
            }
        case SET_CVO_MONEY:
            return {
                ...state,
                cvoMoney: action.cvoMoney
            }
        case SET_ALL_PAID_COSTS_LIST_FOR_MONTH_FROM_SUBENTRIES:
            return {
                ...state,
                checkCostsList: action.checkCostsList
            }
        case SET_SUM_SUBENTRIES_GRUP_MONTH:
            return {
                ...state,
                sumSubentriesGrupMonth: action.sumSubentriesGrupMonth
            }
        default:
            return state
    }
}

export const setAllComponents = (components) => ({type: SET_ALL_COMPONENTS, components});
export const setAllPaidCheckForMonth = (check) => ({type: SET_ALL_PAID_CHECK_FOR_MONTH, check});
export const setSumAllPaidCheckForMonth = (sum) => ({type: SET_SUM_ALL_PAID_CHECK_FOR_MONTH, sum});
export const setSumAllPaidCheckForMonthRu = (sumRu) => ({type: SET_SUM_ALL_PAID_CHECK_FOR_MONTH_RU, sumRu});
export const setAllPaidCostsForMonthFromSubetries = (checkCosts) => ({type: SET_ALL_PAID_COSTS_FOR_MONTH_FROM_SUBENTRIES, checkCosts});
export const setAllPaidCostsListForMonthFromSubetries = (checkCostsList) => ({type: SET_ALL_PAID_COSTS_LIST_FOR_MONTH_FROM_SUBENTRIES, checkCostsList});
export const setSumPaidCostsForMonth = (sumCosts) => ({type: SET_SUM_PAID_COSTS_FOR_MONTH, sumCosts});
export const setSumAndNameSubetriesForMonth = (subentries) => ({type: SET_SUM_AND_NAME_SUBENTRIES_FOR_MONTH, subentries});
export const setSumAndNameArticlesForMonth = (articles) => ({type: SET_SUM_AND_NAME_ARTICLES_FOR_MONTH, articles});
export const setMoneyDelivery = (sumMoneyDelivery) => ({type: SET_SUM_MONEY_DELIVERY, sumMoneyDelivery});
export const setAllArticlesSubentriesForMoneyDelivery = (articlesSubentriesForMoneyDelivery) => ({type: SET_SUBENTRIES_FOR_MONEY_DELIVERY, articlesSubentriesForMoneyDelivery});
export const setSpecificationList= (specificationList) => ({type: SET_SPECIFICATION_LIST, specificationList});
export const setReceivablesAdmin= (receivables) => ({type: SET_RECEIVABLES, receivables});
export const setAllBomNomenclatures= (nomenclatures) => ({type: SET_ALL_BOM_NOMENCLATURES, nomenclatures});
export const setAllComponentFromNomenclatures= (allComponentFromNomenclatures) => ({type: SET_ALL_COMPONENT_FROM_NOMENCLATURES, allComponentFromNomenclatures});
export const setStorageAndAveragePrice= (storageAndAveragePrice) => ({type: SET_STORAGE_AND_PRICE, storageAndAveragePrice});
export const setAccountsPayable= (accountsPayable) => ({type: SET_ACCOUNTS_PAYABLE, accountsPayable});
export const setCourse= (course) => ({type: SET_COURSE, course});
export const setCvoMoney= (cvoMoney) => ({type: SET_CVO_MONEY, cvoMoney});
export const setSumSubentriesGrupMonth= (sumSubentriesGrupMonth) => ({type: SET_SUM_SUBENTRIES_GRUP_MONTH, sumSubentriesGrupMonth});

export const getAllPaidCheckForMonth= (month, year=2023) =>{
    return (dispatch) => {
        adminAPI.getAllPaidCheckForMonth(month, year)
        .then(data => {
            dispatch(setAllPaidCheckForMonth(data));
        });
    }
}
export const getSumSubentriesGrupMonth= (year=2023) =>{
    return (dispatch) => {
        adminAPI.getSumSubentriesGrupMonth(year)
        .then(data => {
            dispatch(setSumSubentriesGrupMonth(data));
        });
    }
}
export const getSumAndNameSubetriesForMonth= (id, month, year) =>{
    return (dispatch) => {
        adminAPI.getSumAndNameSubetriesForMonth(id, month, year)
        .then(data => {
            dispatch(setSumAndNameSubetriesForMonth(data));
        });
    }
}
export const getSumAndNameArticlesForMonth= (month, year=2023) =>{
    return (dispatch) => {
        adminAPI.getSumAndNameArticlesForMonth(month, year)
        .then(data => {
            dispatch(setSumAndNameArticlesForMonth(data));
        });
    }
}
export const getSumAllPaidCheckForMonth= (month, year=2023) =>{
    return (dispatch) => {
        adminAPI.getSumAllPaidCheckForMonth(month, year)
        .then(data => {
            dispatch(setSumAllPaidCheckForMonth(data));
        });
    }
}
export const getSumAllPaidCheckForMonthRu= (month, year=2023) =>{
    return (dispatch) => {
        adminAPI.getSumAllPaidCheckForMonthRu(month, year)
        .then(data => {
            dispatch(setSumAllPaidCheckForMonthRu(data));
        });
    }
}
export const getAllComponentsFromAllPaidNomenclatures= () =>{
    return (dispatch) => {
        adminAPI.getAllComponentsFromAllPaidNomenclatures()
        .then(data => {
            dispatch(setAllComponents(data));
        });
    }
}
export const getAllPaidCostsForMonthFromSubetries= (id, month, year) =>{
    return (dispatch) => {
        adminAPI.getAllPaidCostsForMonthFromSubetries(id, month, year)
        .then(data => {
            dispatch(setAllPaidCostsForMonthFromSubetries(data));
        });
    }
}
export const getAllPaidCostsListForMonthFromSubetries= (month, year) =>{
    return (dispatch) => {
        adminAPI.getAllPaidCostsListForMonthFromSubetries(month, year)
        .then(data => {
            dispatch(setAllPaidCostsListForMonthFromSubetries(data));
        });
    }
}
export const getSumPaidCostsForMonth= (month, year=2023) =>{
    return (dispatch) => {
        adminAPI.getSumPaidCostsForMonth(month, year)
        .then(data => {
            dispatch(setSumPaidCostsForMonth(data));
        });
    }
}
export const getMoneyDelivery= () =>{
    return (dispatch) => {
        adminAPI.getMoneyDelivery()
        .then(data => {
            dispatch(setMoneyDelivery(data));
        });
    }
}
export const setNewMoneyDelivery= (sum, subentries) =>{
    return (dispatch) => {
        adminAPI.setNewMoneyDelivery(sum, subentries)
        .then(data => {
            dispatch(getMoneyDelivery(data));
        });
    }
}
export const getAllArticlesSubentriesForMoneyDelivery= () =>{
    return (dispatch) => {
        adminAPI.getAllArticlesSubentriesForMoneyDelivery()
        .then(data => {
            dispatch(setAllArticlesSubentriesForMoneyDelivery(data));
        });
    }
}
export const getSpecificationListAdmin= (id) =>{
    return (dispatch) => {
        adminAPI.getSpecificationListAdmin(id)
        .then(data => {
            dispatch(setSpecificationList(data));
        });
    }
}
export const getReceivablesAdmin= () =>{
    return (dispatch) => {
        adminAPI.getReceivablesAdmin()
        .then(data => {
            dispatch(setReceivablesAdmin(data));
        });
    }
}
export const getAllBomNomenclatures= () =>{
    return (dispatch) => {
        adminAPI.getAllBomNomenclatures()
        .then(data => {
            dispatch(setAllBomNomenclatures(data));
        });
    }
}
export const getAllComponentFromNomenclatures= () =>{
    return (dispatch) => {
        adminAPI.getAllComponentFromNomenclatures()
        .then(data => {
            dispatch(setAllComponentFromNomenclatures(data));
        });
    }
}
export const getStorageAndAveragePrice= () =>{
    return (dispatch) => {
        adminAPI.getStorageAndAveragePrice()
        .then(data => {
            dispatch(setStorageAndAveragePrice(data));
        });
    }
}
export const getAccountsPayable= () =>{
    return (dispatch) => {
        adminAPI.getAccountsPayable()
        .then(data => {
            dispatch(setAccountsPayable(data));
        });
    }
}
export const addAccountsPayable= (idCompany, coll) =>{
    return (dispatch) => {
        adminAPI.addAccountsPayable(idCompany, coll)
        .then(data => {
            dispatch(getAccountsPayable());
        });
    }
}
export const deleteAccountsPayable= (id) =>{
    return (dispatch) => {
        adminAPI.deleteAccountsPayable(id)
        .then(data => {
            dispatch(getAccountsPayable());
        });
    }
}
export const getCourse = () => {
    return async (dispatch) => {
        return new Promise(async (resolve, reject) => {
            try {
                const data = await adminAPI.getCourse();
                dispatch(setCourse(data));
                resolve(data);
            } catch (error) {
                console.error("Error:", error);
                reject(error);
            }
        });
    };
};

export const getCvoMoney= (month) =>{
    return (dispatch) => {
        adminAPI.getCvoMoney(month)
        .then(data => {
            dispatch(setCvoMoney(data));
        });
    }
}


export default AdminReducer;

