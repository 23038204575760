import React from 'react';
import s from './Modal.module.css';

const Modal = ({active, setActive, children}) => {
    return(
        <div className={active ? s.modal+" "+s.active : s.modal} >
            <div className={active ? s.modal__content+" "+s.active : s.modal__content} onClick={e => e.stopPropagation()}>
                <div onClick={()=>setActive(false)} className={s.close}><span>X</span></div>
                {children}
            </div>
        </div>
    )
}
export default Modal;