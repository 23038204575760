import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
    getAllCompanySegmentation,
    getAllCompanyCategories,
    getCompanyInSegmentationCategories,
    getPayModulsInCompany,
    setCompanyNotActive,
    getEmailCompanyAgents
} from '../../redux/sales-reducer';
import {
    getAllMainSupplier,
    getMainSupplierList
} from '../../redux/mainSupplier-reducer';
import Sales from './Sales';

class SalesContainer extends React.Component {
    componentDidMount() {
        this.props.getAllCompanySegmentation();
        this.props.getAllCompanyCategories();
        this.props.getMainSupplierList();
        this.props.getAllMainSupplier();
        this.props.getEmailCompanyAgents();
    }

    componentDidUpdate(prevProps) {
        // After company list changes, fetch all modules by the accounts of these companies
        if (prevProps.companyInSegmentCategory !== this.props.companyInSegmentCategory) {
            this.props.getPayModulsInCompany(this.props.companyInSegmentCategory);
        }
    }

    render() {
        return (
            <div>
                <Sales
                    companySegmentation={this.props.companySegmentation}
                    getCompanyInSegmentationCategories={this.props.getCompanyInSegmentationCategories}
                    companyInSegmentCategory={this.props.companyInSegmentCategory}
                    companyCategories={this.props.companyCategories}
                    moduls={this.props.moduls}
                    setCompanyNotActive={this.props.setCompanyNotActive}
                    mainSupplier={this.props.mainSupplier}
                    supplierList={this.props.supplierList}
                    mainSuppliers={this.props.params?.arrayMainSuppliers}
                    emailCompanyAgents={this.props.emailCompanyAgents}
                />
            </div>
        );
    }
}

let mapStateToProps = (state) => ({
    companySegmentation: state.sales.companySegmentation,
    companyCategories: state.sales.companyCategories,
    companyInSegmentCategory: state.sales.companyInSegmentCategory,
    moduls: state.sales.moduls,
    emailCompanyAgents: state.sales.emailCompanyAgents,
    mainSupplier: state.mainSupplier.mainSupplier,
    supplierList: state.mainSupplier.supplierList,
});

export default compose(
    connect(mapStateToProps, {
        getAllCompanySegmentation,
        getCompanyInSegmentationCategories,
        getAllCompanyCategories,
        getPayModulsInCompany,
        setCompanyNotActive,
        getMainSupplierList,
        getAllMainSupplier,
        getEmailCompanyAgents
    })
)(SalesContainer);
