import { projectAPI } from "../api/api";

const SET_ALL_PROJECT = "SET_ALL_PROJECT";


let initialState = {
    allProject: [],
}

const projectReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_ALL_PROJECT:
            return {
                ...state,
                allProject: action.data
            }
        default:
            return state
    }
}

export const setAllProject = (data) => ({type: SET_ALL_PROJECT, data});

export const getAllProject= () =>{
    return (dispatch) => {
        projectAPI.getAllProject()
        .then(data => {
            dispatch(setAllProject(data));
        });
    }
}
export const addNewProject= (array) =>{
    return (dispatch) => {
        projectAPI.addNewProject(array)
        .then(data => {
            dispatch(getAllProject());
        });
    }
}
export const changeProject= (array) =>{
    return (dispatch) => {
        projectAPI.changeProject(array)
        .then(data => {
            dispatch(getAllProject());
        });
    }
}
export const removeProject= (id) =>{
    return (dispatch) => {
        projectAPI.removeProject(id)
        .then(data => {
            dispatch(getAllProject());
        });
    }
}


export default projectReducer;

