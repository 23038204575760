import React, { useState, useEffect } from 'react';
import Modal from '../Production/common/Modal/Modal';
import AddComment from './Modal/AddComment';
import s from './Task.module.css';
import AddTask from './Modal/AddTask';
import AddManager from './Modal/AddManager';
import ShowTask from './ShowTask/ShowTask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import DeleteTask from './Modal/DeleteTask';

const Task = (props) => {
    let [workerList, setWorkerList] = useState(props.workersListTech);
    useEffect(() => {
        setWorkerList([...props.workersListTech]);
    }, [props.workersListTech]);

    let [tasksComment, setTasksComment] = useState(props.tasksComment);
    useEffect(() => {
        setTasksComment([...props.tasksComment]);
    }, [props.tasksComment]);

    let [showAddComment, setShowAddComment] = useState(false);
    let [idForComment, setIdForComment] = useState(0);
    const setShowAddCommentF = (id) => {
        setIdForComment(id);
        setShowAddComment(!showAddComment);
    };

    let [showDeleteTask, setShowDeleteTask] = useState(false);
    let [statusDeleteTask, setStatusDeleteTask] = useState(false);
    const setShowDeleteTaskF = (id, status) => {
        setStatusDeleteTask(status);
        setShowDeleteTask(id);
    };

    const getWorkerName = (workerId) => {
        return workerList.map((k) => {
            if (Number(k.id) === Number(workerId)) return k.name;
        });
    };

    const setDate = (e) => {
        props.addNewDateInTask(e.target.id, e.target.value, e.target.name, props.personalId);
    };

    let [showAddManager, setShowAddManager] = useState(false);
    let [idTaskForChange, setIdTaskForChange] = useState(0);
    const setShowAddManagerF = (id) => {
        setIdTaskForChange(id);
        setShowAddManager(true);
    };

    let [showTaskForWorker, setShowTaskForWorker] = useState(0);
    const setShowTaskForWorkerF = (e) => {
        setShowTaskForWorker(e.target.value);
        props.getTasks(e.target.value);
    };

    let [showComment, setShowComment] = useState(0);
    const setShowCommentF = (id) => {
        setShowComment(showComment === id ? 0 : id);
    };

    const getCountComment = (idComment) => {
        let i = 0;
        tasksComment.map((k) => {
            if (idComment === k.tasks_id) i++;
        });
        return i;
    };

    let [mainTask, setMainTask] = useState(0);
    let [showTask, setShowTask] = useState(false);
    const setShowTaskF = (idMainTask = 0) => {
        setShowTask(!showTask);
        setMainTask(idMainTask);
    };

    let [numberSort, setNumberSort] = useState('3');
    const setNumberSortF = (e) => {
        setNumberSort(e.target.value);
    };

    let [tasks, setTasks] = useState(props.tasks);
    useEffect(() => {
        setTasks([...props.tasks]);
    }, [props.tasks]);

    const getSort = () => {
        switch (numberSort) {
            case '1':
                return tasks.sort((a, b) => Number(b.id) - Number(a.id));
            case '2':
                return tasks.sort((a, b) => Number(a.id) - Number(b.id));
            case '3':
                return tasks.sort((a, b) => {
                    let d1 = a.provisional_date ? new Date(a.provisional_date) : new Date('2030-01-01');
                    let d2 = b.provisional_date ? new Date(b.provisional_date) : new Date('2030-01-01');
                    return d1 - d2;
                });
            case '4':
                return tasks.sort((a, b) => {
                    let d1 = a.provisional_date ? new Date(a.provisional_date) : new Date('2030-01-01');
                    let d2 = b.provisional_date ? new Date(b.provisional_date) : new Date('2030-01-01');
                    return d2 - d1;
                });
            case '5':
                return tasks.sort((a, b) => {
                    let d1 = a.date_start ? new Date(a.date_start) : new Date('2030-01-01');
                    let d2 = b.date_start ? new Date(b.date_start) : new Date('2030-01-01');
                    return d1 - d2;
                });
            case '6':
                return tasks.sort((a, b) => {
                    let d1 = a.date_start ? new Date(a.date_start) : new Date('2030-01-01');
                    let d2 = b.date_start ? new Date(b.date_start) : new Date('2030-01-01');
                    return d2 - d1;
                });
            default:
                return props.tasks;
        }
    };

    return (
        <div className={s.taskMain}>
            <div className={s.threeBlock}>
                <div>
                    <button onClick={() => setShowTaskF()}>Добавить задачу</button>
                </div>
                <div className={s.filter}>
                    <FontAwesomeIcon icon={faFilter} />
                    <select onChange={setNumberSortF}>
                        <option value="1">От большего №</option>
                        <option value="2">От меньшего №</option>
                        <option value="3">раньше дата План</option>
                        <option value="4">позже дата План</option>
                        <option value="5">От большего Начало</option>
                        <option value="6">От меньшего Начало</option>
                    </select>
                </div>
            </div>

            {showTask && (
                <Modal active={showTask} setActive={setShowTask}>
                    <AddTask
                        workerList={workerList}
                        setNewTask={props.setNewTask}
                        setShowTask={setShowTask}
                        mainTask={mainTask}
                    />
                </Modal>
            )}

            {showAddComment && (
                <Modal active={showAddComment} setActive={setShowAddComment}>
                    <AddComment
                        tasksId={idForComment}
                        addCommentForTask={props.addCommentForTask}
                        setShowAddComment={setShowAddComment}
                        workerList={workerList}
                    />
                </Modal>
            )}

            {showDeleteTask && (
                <Modal active={showDeleteTask} setActive={setShowDeleteTask}>
                    <DeleteTask
                        tasksId={showDeleteTask}
                        deleteTask={props.deleteTask}
                        statusDeleteTask={statusDeleteTask}
                        personalId={props.personalId}
                        setShowDeleteTask={setShowDeleteTask}
                    />
                </Modal>
            )}

            {showAddManager && (
                <Modal active={showAddManager} setActive={setShowAddManager}>
                    <AddManager
                        workerList={workerList}
                        idTaskForChange={idTaskForChange}
                        setNewWorkerInTask={props.setNewWorkerInTask}
                        personalId={props.personalId}
                        setShowAddManager={setShowAddManager}
                    />
                </Modal>
            )}

            <div>
                <h1>Задачи</h1>
                <div className={s.main + ' ' + s.hei}>
                    <div className={s.row}>№</div>
                    <div>Задача</div>
                    <div>Связь с сотрудником</div>
                    <div>
                        <div className={s.twoBlock}>
                            <div>План</div>
                            <div>Конец</div>
                        </div>
                    </div>
                    <div></div>
                </div>
                {getSort(tasks).map((k, index) => {
                    if (k.date_end == null && k.mainTaskId == null) {
                        return (
                            <div key={'tasks_' + index}>
                                <ShowTask
                                    {...k}
                                    getCountComment={getCountComment}
                                    getWorkerName={getWorkerName}
                                    setShowCommentF={setShowCommentF}
                                    setShowAddManagerF={setShowAddManagerF}
                                    setDate={setDate}
                                    setShowAddCommentF={setShowAddCommentF}
                                    setShowTaskF={setShowTaskF}
                                    tasksComment={tasksComment}
                                    showComment={showComment}
                                    setShowDeleteTaskF={setShowDeleteTaskF}
                                    type="1"
                                    setDateReminder={props.setDateReminder}
                                    personalId={props.personalId}
                                    changeDateForTask={props.changeDateForTask}
                                    changeTextTask={props.changeTextTask}
                                />
                                {tasks.map((g, subIndex) => {
                                    if (g.mainTaskId == k.id) {
                                        return (
                                            <div key={'tasks_' + subIndex} className={s.notMainTask}>
                                                <ShowTask
                                                    {...g}
                                                    getCountComment={getCountComment}
                                                    getWorkerName={getWorkerName}
                                                    setShowCommentF={setShowCommentF}
                                                    setShowAddManagerF={setShowAddManagerF}
                                                    setDate={setDate}
                                                    setShowAddCommentF={setShowAddCommentF}
                                                    type="2"
                                                    tasksComment={tasksComment}
                                                    showComment={showComment}
                                                    setShowDeleteTaskF={setShowDeleteTaskF}
                                                    setDateReminder={props.setDateReminder}
                                                    personalId={props.personalId}
                                                    changeDateForTask={props.changeDateForTask}
                                                    changeTextTask={props.changeTextTask}
                                                />
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        );
                    }
                })}
                {getSort(tasks).map((k, index) => {
                    if (k.date_end != null && k.mainTaskId == null) {
                        return (
                            <div key={'tasks_' + index}>
                                <ShowTask
                                    {...k}
                                    getCountComment={getCountComment}
                                    getWorkerName={getWorkerName}
                                    setShowCommentF={setShowCommentF}
                                    setShowAddManagerF={setShowAddManagerF}
                                    setDate={setDate}
                                    setShowAddCommentF={setShowAddCommentF}
                                    type="3"
                                    tasksComment={tasksComment}
                                    showComment={showComment}
                                    setTaskWithoutArchive={props.setTaskWithoutArchive}
                                    personalId={props.personalId}
                                    setDateReminder={props.setDateReminder}
                                    changeDateForTask={props.changeDateForTask}
                                />
                            </div>
                        );
                    }
                })}
            </div>
        </div>
    );
};

export default Task;
