import React from 'react';
import Chief from './Chief';
import {connect} from 'react-redux';
import {
    getModulsPaidAllocated,
    setWhoMakeWithSpecs,
    setWhoMakeWithoutSpecs,
    setModulsForAkon,
    setOtherProducts,
    setSeparatorInBase
} from '../../../redux/modulsPaid-reducer';
import {getWorkersList} from '../../../redux/productionMain-reducer';
import {getStorageModulsReady, checkingAvailabilityComponents} from '../../../redux/addStorage-reducer';

class ChiefContainer extends React.Component {
    componentDidMount() {
        this.props.getModulsPaidAllocated();
        this.props.getStorageModulsReady();
        this.props.getWorkersList();
    }

    // componentDidUpdate(prevProps){
    //     if(prevProps.modulsPaid !== this.props.modulsPaid){
    //         this.props.getModulsPaidAllocated();
    //     }
    // }

    render() {
        return (
            <Chief workers={this.props.workers}
                   modulsReady={this.props.modulsReady}
                   modulsPaid={this.props.modulsPaid}
                   setWhoMakeWithSpecs={this.props.setWhoMakeWithSpecs}
                   setWhoMakeWithoutSpecs={this.props.setWhoMakeWithoutSpecs}
                   setModulsForAkon={this.props.setModulsForAkon}
                   setOtherProducts={this.props.setOtherProducts}
                   checkingAvailabilityComponents={this.props.checkingAvailabilityComponents}
                   missingComponents={this.props.missingComponents}
                   setSeparatorInBase={this.props.setSeparatorInBase}/>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        workers: state.productionMain.workers,
        modulsPaid: state.modulsPaid.modulsPaid,
        modulsReady: state.addStorage.component,
        missingComponents: state.addStorage.missingComponents
    }
}


export default connect(mapStateToProps, {
    getModulsPaidAllocated,
    setWhoMakeWithSpecs,
    setWhoMakeWithoutSpecs,
    setModulsForAkon,
    setOtherProducts,
    getStorageModulsReady,
    getWorkersList,
    checkingAvailabilityComponents,
    setSeparatorInBase
})(ChiefContainer);