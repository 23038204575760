import React, { useState, useEffect } from 'react';
import s from './Future.module.css';
import main from '../../Main.module.css';
import Filter from './Filter'
import {NavLink} from "react-router-dom";

const Future = (props) =>{
    
    //делаем расшифровку цепочки и вставляем в общий массив
    const getChannels = (array)=>{
        let newArray = [];
        array.map(k=>{
            const getChannel = (number)=>{
                let res = [];
                let channel = number.split("/");
                for(let i=0;i<channel.length;i++){
                    let index = props.allChannelsData.findIndex(k => k.ID == channel[i])
                    if(index !== -1){
                        res.push({"typeId":props.allChannelsData[index].typeId, "type": props.allChannelsData[index].type, "signal_typesId": props.allChannelsData[index].signal_typesId, "signal_types": props.allChannelsData[index].signal_types, "rangesId": props.allChannelsData[index].rangesId, "ranges": props.allChannelsData[index].ranges, "wireId": props.allChannelsData[index].wireId, "wire": props.allChannelsData[index].wire})
                    }
                }
                return res
            }
            let arrayChannels = k.CHANNELS.split(",");
            let result = [];
            for(let i=0;i<arrayChannels.length;i++){
                let a = getChannel(arrayChannels[i])
                a.map(k=>result.push(k))
            }
            newArray.push({...k, "channelsData": result})
        })
        return newArray
    }
    //для каждого модуля мы прикрепляем массив channelsData с расшифровкой по каждому каналу и будем по нему применять фильтр
    let [allNomenclaturesDataMain, setAllNomenclaturesDataMain] = useState([])
    useEffect(() => {
        setAllNomenclaturesDataMain(getChannels(props.allNomenclaturesData));
    }, [props.allNomenclaturesData])

    //отфильтрованный массив с модулями для вывода
    let [allNomenclaturesDataWithFilter, setAllNomenclaturesDataWithFilter] = useState([])
    useEffect(() => {
        setAllNomenclaturesDataWithFilter(getChannels(props.allNomenclaturesData));
    }, [props.allNomenclaturesData])
    
    //описание для каждого канала в читаемой форме
    const getDescChannel = (channels)=>{
        const getChannel = (number)=>{
            let res = "";
            //проверяем сколько диапазонов в канале и выводим несколько, если они заложены
            let channel = number.split("/");
            for(let i=0;i<channel.length;i++){
                let index = props.allChannelsData.findIndex(k => k.ID == channel[i])
                if(index === -1){//если не нашёл такой номер
                    res += "помилка в зчитуванні каналу"
                }else{
                    res += props.allChannelsData[index].type+", "+props.allChannelsData[index].signal_types+", "+props.allChannelsData[index].ranges+", "+props.allChannelsData[index].wire+"<br>"
                }
            }
            return res
        }
        let arrayChannels = channels.split(",");
        let result = "";
        for(let i=0;i<arrayChannels.length;i++){
            let number = i+1
            result += "<div>"+number+" каналу: </div><div>"+getChannel(arrayChannels[i])+"</div>"
        }
        return result
    }
    
    return(
        <div>
            <h1>Фьючер АКОН</h1>
            {/* <Filter {...props} setAllNomenclaturesDataMain={setAllNomenclaturesData}/> */}
            <Filter {...props} allNomenclaturesDataMain={allNomenclaturesDataMain} setAllNomenclaturesDataWithFilter={setAllNomenclaturesDataWithFilter}/>
            <div className={s.twoBlocksFilter}>
            {0<allNomenclaturesDataWithFilter.length && allNomenclaturesDataWithFilter.map((k,index)=>{
                return(
                    <div key={"allNomenclaturesData"+index} className={s.nomBlock}>
                        <div>{index+1}</div>
                        <div>
                            <div className={main.bold}><NavLink to={"/personalModule/"+k.ID} target="_blank" rel="noopener noreferrer">{k.NAME}-{k.ID}</NavLink></div>
                            <div><div dangerouslySetInnerHTML={{__html: getDescChannel(k.CHANNELS)}} className={s.twoBlocks}></div></div>
                            <div className={main.bold}>{k.ADD_OPTIONS!=="" && "Опції: "+k.ADD_OPTIONS }</div>
                        </div>
                    </div>
                )
            })
            }
            </div>
        </div>
    )
    
}
export default Future;