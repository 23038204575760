import React, {useState} from 'react';
import s from './Production.module.css';
import Status from '../common/MainTable/Status';
import styleMain from '../common/style.module.css';
import AnyFormatDate from '../common/AnyFormatDate/AnyFormatDate';
import Modal from '../common/Modal/Modal';
import AddTTN from './AddTTN';
import {useCookies} from 'react-cookie';
import {NavLink} from 'react-router-dom';
import LongText from "../common/LongText/LongText";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt, faComment} from "@fortawesome/free-solid-svg-icons";
import Separator from "../common/Separator/Separator";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import {TextField} from "@mui/material";
import {manufactureAPI} from "../../../api/api";
import {toast} from "react-toastify";
import {faCommentAlt} from "@fortawesome/free-regular-svg-icons/faCommentAlt";
import {faCommentMedical} from "@fortawesome/free-solid-svg-icons/faCommentMedical";

const Production = (props) => {
    const [cookies] = useCookies();

    const [TTN, setTTN] = useState(false);
    const [typeCheck, setTypeCheck] = useState(false);
    const [separator, setSeparator] = useState(0);
    const [separatorId, setSeparatorId] = useState(0);
    const [shippingToday, setShippingToday] = useState([]);

    // Для діалогового вікна коментаря
    const [open, setOpen] = useState(false);
    const [newTextComment, setNewTextComment] = useState("");
    const [currentModuleId, setCurrentModuleId] = useState(null);

    const handleClickOpen = (id) => {
        setCurrentModuleId(id);
        setNewTextComment(props.moduls.find(m => m.id === id)?.comment || "");
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setNewTextComment("");
    };

    const handleSaveComment = async () => {
        try {
            await manufactureAPI.setManufactureComment({
                moduleID: currentModuleId,
                text: newTextComment
            }).then((response) => {
                if (response?.status === 1) {
                    props.setModulsStatusNew(currentModuleId, 0);
                    setOpen(false);
                    setNewTextComment("");
                    toast.success('Комментарий успешно сохранен')
                } else {
                    toast.error('Ошибка сохранения комментария: ' + response?.message)
                }
            });
        } catch (error) {
            console.error("Error saving comment:", error);
        }
    };

    // Відновлюємо функцію для форматування дати
    const getDateFormatYmd = (date) => {
        let month = date.getMonth() + 1;
        month = "" + month;
        if (month.length === 1) month = "0" + month;
        let day = date.getDate();
        day = "" + day;
        if (day.length === 1) day = "0" + day;
        return `${date.getFullYear()}-${month}-${day}`;
    };

    // Відновлюємо функцію для перевірки дати
    const colorDate = (date) => {
        let now = new Date();
        now = getDateFormatYmd(now);
        date = new Date(date);
        date = getDateFormatYmd(date);
        if (date < now) {
            return styleMain.red;
        } else if (date === now) {
            return styleMain.green;
        } else {
            return "";
        }
    };

    // Відновлюємо функцію для створення URL-адреси
    const ahref = (id, type) => {
        return `/check/${id}/${type}`;
    };

    // Відновлюємо функцію для додавання нової ТТН
    const setTTNF = (ttn, paid) => {
        setTTN(ttn);
        setTypeCheck(paid ? 1 : 2);
    };

    // Відновлюємо функцію для редагування кількості
    const redactionColl = (number, id) => {
        setSeparator(number);
        setSeparatorId(id);
    };

    // Модуль діалогового вікна для коментаря
    let dialogSetComment = () => (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Комментарий</DialogTitle>
            <DialogContent>
                <TextField
                    id="outlined-multiline-static"
                    label="Коментар"
                    InputProps={{
                        inputProps: {
                            style: {textAlign: "left"},
                        }
                    }}
                    sx={{width: '100%', marginTop: '20px', alignItems: 'left'}}
                    multiline
                    rows={4}
                    value={newTextComment}
                    onChange={(e) => setNewTextComment(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">Отмена</Button>
                <Button onClick={handleSaveComment} color="primary" autoFocus>Сохранить</Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <>
            {dialogSetComment()}

            {TTN && (
                <Modal active={TTN} setActive={setTTN}>
                    <AddTTN active={TTN} setActive={setTTN} setNewTTN={props.setNewTTN} typeCheck={typeCheck}/>
                </Modal>
            )}

            {separator !== 0 && (
                <Separator number={separator} setSeparator={setSeparator} id={separatorId}/>
            )}

            <div className={s.main + " " + s.bold + (separator === 0 ? (" " + s.mainSection) : '')}>
                <div className={s.blue}>
                    Сьогодні ми відвантажуємо рахунки: {shippingToday.length > 0 && shippingToday.map(k => k + " ")}
                </div>
                <div className={s.mainModuls}>
                    <div>Назва - кількість</div>
                    <div className={s.main_stage}>
                        <div><NavLink to="/chief">Розподіл</NavLink></div>
                        <div><NavLink to="/engineer">Монтаж</NavLink></div>
                        <div><NavLink to="/techEngineer">Первинка</NavLink></div>
                        <div><NavLink to="/engineer">Корпусування</NavLink></div>
                        <div><NavLink to="/techEngineer">Вих. контроль</NavLink></div>
                        <div>Склад</div>
                    </div>
                </div>
            </div>

            {props?.listProduction
                .sort((a, b) => new Date(a?.date_manager) - new Date(b?.date_manager))
                .map((p, index) => (
                    props.moduls
                        .filter(k => k.id_invoice === p.id)
                        .filter(k => ![6].includes(k.status)).length > 0 &&
                    <div className={s.main} key={"listProduction_" + index}>
                        <div>
                            <NavLink to={ahref(p.id, p.type_check)} target="_blank">
                                <span className={s.bold}>{p.type_check === 0 ? p.number : p.number + " - Т"}</span>
                                <br/>
                            </NavLink>

                            <span className={colorDate(p.date_manager)}>
                            <AnyFormatDate date={p.date_manager}/>
                            <br/>
                        </span>
                            <LongText text={p.company} maxLength={15} shawAllText={'...'}/>
                            <br/>

                            {p.numeral === p.paid ? (
                                <button onClick={() => setTTNF(p.id, p.paid)} className={s.buttonTTN}>Додати
                                    ТТН</button>
                            ) : (
                                <span className={styleMain.red}>Потрібна доплата</span>
                            )}
                        </div>

                        <div className={s.mainModuls}>
                            {props.moduls
                                .filter(k => k.id_invoice === p.id)
                                .filter(k => ![ 6].includes(k.status))
                                .map(k => (

                                    <React.Fragment key={k.id}>
                                        <div className={s.threeBlocks}>
                                            <span>{k.moduls_name}<br/>({k.workerName})</span>
                                            <span
                                                onClick={() => k.moduls_coll > 1 && redactionColl(k.moduls_coll, k.id)}>
                                            {k.moduls_coll} шт
                                                {k.moduls_coll > 1 && <FontAwesomeIcon icon={faPencilAlt}/>}
                                        </span>
                                            <span>
                                            <FontAwesomeIcon
                                                icon={k?.comment?.length > 0 ? faComment : faCommentMedical}
                                                onClick={() => handleClickOpen(k.id)}
                                            />
                                        </span>
                                        </div>
                                        <div className={s.main_stage}>
                                            <Status
                                                status={k.status}
                                                modulsId={k.id}
                                                moduls_type_in_production={k.moduls_type_in_production}
                                                personalId={cookies.id}
                                                setModulsStatus={props.setModulsStatus}
                                                setModulsStatusNew={props.setModulsStatusNew}
                                                moduleInfo={k}
                                            />
                                        </div>
                                    </React.Fragment>
                                ))
                            }
                        </div>
                    </div>
                ))}

            {/* Модулі, що на складі, але не прив'язані до рахунків */}
            <div className={s.main}>
                <div>
                    Cклад
                </div>
                <div className={s.mainModuls}>
                    {
                        props.moduls.map(k => {
                            if (k.id_invoice == 0 && ![5, 6].includes(k.status))
                                return (
                                    <React.Fragment
                                        key={"moduls_warehouse_" + k.id + "_" + "_" + k.moduls_type_in_production + "_" + k.status}
                                    >
                                        <div>
                                            <div className={s.threeBlocks}>
                                                <span>
                                                    {k.moduls_name}<br/> (дата монтажа {k.start_date_installation})
                                                </span>
                                                <span onClick={() =>
                                                    k.moduls_coll > 1 ? redactionColl(k.moduls_coll, k.id) : null
                                                }>{k.moduls_coll} шт
                                                    {k.moduls_coll > 1 ? <FontAwesomeIcon
                                                        icon={faPencilAlt}
                                                    /> : null}
                                                </span>
                                                <span>
                                                    <FontAwesomeIcon
                                                        icon={k?.comment?.length > 0 ? faComment : faCommentMedical}
                                                        onClick={() => handleClickOpen(k.id)}
                                                    />
                                                </span>
                                            </div>
                                            <div className={s.main2}>
                                                <div onClick={() => props.changeStorageFromTech(k.id, 1)}>Лабы</div>
                                                <div onClick={() => props.changeStorageFromTech(k.id, 2)}>Программисту
                                                </div>
                                                <div onClick={() => props.changeStorageFromTech(k.id, 3)}>Заготовки
                                                </div>
                                            </div>
                                        </div>
                                        <div className={s.main_stage}>
                                            <Status
                                                status={k.status}
                                                modulsId={k.id}
                                                moduls_type_in_production={k.moduls_type_in_production}
                                                personalId={parseInt(cookies.id)}
                                                setModulsStatus={props.setModulsStatus}
                                                setModulsStatusNew={props.setModulsStatusNew}
                                            /></div>
                                    </React.Fragment>)
                        })
                    }
                </div>
            </div>
        </>
    );
};

export default Production;
