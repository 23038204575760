import React, { useState } from 'react';
import Chart from 'react-apexcharts';

const InvoicePaidStackedBarchart = ({ data }) => {
    const [groupContacts, setGroupContacts] = useState(false);

    const formatStackedBarData = (data) => {
        const categories = groupContacts ? ['Contacts', 'scheta', 'scheta_paid'] : ['companyAgentsMessages', 'salesMeetings', 'technicalConsultations', 'kpMain', 'scheta', 'scheta_paid'];

        const series = (data.managers || []).map(manager => {
            return {
                name: `Менеджер ${manager}`,
                data: categories.map(category => {
                    if (category === 'Contacts') {
                        const contactCategories = ['companyAgentsMessages', 'salesMeetings', 'technicalConsultations', 'kpMain'];
                        const contactSum = contactCategories.reduce((sum, contactCategory) => {
                            const managerData = (data[contactCategory] || []).filter(record => record.manager_id === manager);
                            return sum + managerData.reduce((total, record) => total + parseFloat(record.records_count || 0), 0);
                        }, 0);
                        return contactSum;
                    }
                    const managerData = (data[category] || []).filter(record => record.manager_id === manager);
                    if (category === 'scheta_paid') {
                        const schetaData = (data['scheta'] || []).filter(record => record.manager_id === manager);
                        return schetaData.reduce((sum, record) => sum + parseFloat(record.paid_count || 0), 0);
                    }
                    return managerData.reduce((sum, record) => sum + parseFloat(record.records_count || 0), 0);
                })
            };
        });

        return { categories, series };
    };

    const { categories, series } = formatStackedBarData(data);

    const options = {
        chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true
                },
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
            },
        },
        xaxis: {
            categories: categories,
        },
        tooltip: {
            y: {
                formatter: (value) => value.toFixed(2)
            }
        },
        legend: {
            show: true,
        },
    };

    return (
        <div>
            <label>
                <input
                    type="checkbox"
                    checked={groupContacts}
                    onChange={() => setGroupContacts(!groupContacts)}
                />
                Группировать все контакты в 1 категорию
            </label>
            <Chart options={options} series={series} type="bar" height={350} />
        </div>
    );
};

export default InvoicePaidStackedBarchart;
