import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getReceivablesAdmin } from '../../../../redux/admin-reducer';
import Receivables from './Receivables';

const ReceivablesContainer = (props) => {

    useEffect(() => {
        props.getReceivablesAdmin();
    }, []); // Runs only on component mount

    return (
        <Receivables receivables={props.receivables} />
    );
}

const mapStateToProps = (state) => ({
    receivables: state.admin.receivables,
});

export default compose(
    connect(mapStateToProps, { getReceivablesAdmin })
)(ReceivablesContainer);
