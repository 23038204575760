import React from 'react';
import s from './Repair.module.css';
import main from '../../Main.module.css'
import AnyFormatDate from '../common/AnyFormatDate/AnyFormatDate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faList, faTruck, faPencilAlt, faArrowRight, faUser} from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react';
import Modal from '../common/Modal/Modal';
import { NavLink } from 'react-router-dom';
import AddWorker from './Modal/AddWorker';
import ChangeColl from './Modal/ChangeColl';
import AddModuleFromRepair from './Modal/AddModuleFromRepair';
import AddCompanyFromRepair from './Modal/AddCompanyFromRepair';
import ModulsListContainer from './Modal/ModulsList/ModulsListContainer';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import TTN from './Modal/TTN';

const RepairMain = (props) =>{
    let [collInRepair, setCollInRepair] = useState(false)
    let [moduleFromRepair, setModuleFromRepair] = useState(false)
    let [company, setCompany] = useState(false)
    let [address, setAddress] = useState(false)
    let [modulsList, setModulsList] = useState(false)
    let [ttn, setTTN] = useState(false)
    const getStatus=(status)=>{
        switch(status){
            case "1": return "Гарант"
            case "2": return "Не гарант"
            default: return ""
        }
    }
    const getDelay = (date)=>{
        let now = new Date();let day=now.getDate();let month = now.getUTCMonth()+1;
        if(Number(day)<10){
            day = "0"+day
        }
        if(Number(month)<10){
            month = "0"+month
        }
        now = now.getFullYear()+"-"+month+"-"+day
        if(date < now){
            return true
        }else{
            return false
        }

    }
    const getCountNewRepair = ()=>{
        let count = 0;
        props.repair.map(k=>{
            k.ttn==null && k.date_close==null && count++
        })
        return count
    }
    return(
        <div>
            {collInRepair &&
                <Modal active={collInRepair} setActive={setCollInRepair}>
                    <ChangeColl id={collInRepair} setNewValue={props.setCollInRepair} setShow={setCollInRepair}/>
                </Modal>
            }
            {moduleFromRepair &&
                <Modal active={moduleFromRepair} setActive={setModuleFromRepair}>
                    <AddModuleFromRepair id={moduleFromRepair} addModuleFromRepair={props.addModuleFromRepair} setShow={setModuleFromRepair}/>
                </Modal>
            }
            {company &&
                <Modal active={company} setActive={setCompany}>
                    <AddCompanyFromRepair id={company} addCompanyFromRepair={props.addCompanyFromRepair} setShow={setCompany} companyList={props.companyList}/>
                </Modal>
            }
            {address &&
                <Modal active={address} setActive={setAddress}>
                    <h1>Адрес доставки клиенту</h1>
                    <p>{address}</p>
                </Modal>
            }
            {modulsList &&
                <Modal active={modulsList} setActive={setModulsList}>
                    <ModulsListContainer id={modulsList} setShow={setModulsList}/>
                </Modal>
            }
            {ttn &&
                <Modal active={ttn} setActive={setTTN}>
                    <TTN id={ttn} setNewValue={props.setTTNInRepair} setShow={setTTN}/>
                </Modal>
            }
            <h1 className={s.center}>Ремонт общий список</h1>
            <div><NavLink to="/repair/tech">В тех. отдел</NavLink></div>
            
            <div className={s.main+' '+main.bold}>
                <div>#</div>
                <div>Дата окончания</div>
                <div>Ответственный</div>
                <div>Кол. в посылке</div>
                <div>Заявленая неисправность</div>
                <div>Информация</div>
                <div>Действия</div>
            </div>
            {getCountNewRepair()!==0 && 
            <>
            <h2>Не распределено</h2>
            {
            props.repair.map(k=>{
                return(
                    k.ttn==null && k.date_close==null &&
                    <div className={s.main} key={"repair"+k.id}>
                        <div>{k.id}</div>
                        <div>
                            <AnyFormatDate date={k.date_close}/>
                        </div>
                        <div>{k.responsibleName==null ? "Не назначен" : k.responsibleName}</div>
                        <div>{k.coll} <span className={s.pensil} onClick={()=>setCollInRepair(k.id)}><FontAwesomeIcon icon={faPencilAlt}/></span></div>
                        <div>
                            {k.companyNameFromRequest && <div className={s.fio}><span className={s.m10}><FontAwesomeIcon icon={faBuilding} /></span>{k.companyNameFromRequest}</div>}
                            <div className={s.fio}><span className={s.m10}><FontAwesomeIcon icon={faUser} /></span>{k.fio+" - "+k.tell}</div>
                            <div>{k.defect}</div>
                        </div>
                        <div className={s.miniBlocks}>
                            {k.countModuls!=0 && <div data-tooltip="Модули" className={s.tooltip} onClick={()=>setModulsList(k.id)}><span><FontAwesomeIcon icon={faList} /></span></div>/* Показать модули в заявке */}
                            <div data-tooltip="Адрес" className={s.tooltip} onClick={()=>setAddress(k.address)}><span><FontAwesomeIcon icon={faTruck} /></span></div>{/* Показать адрес возвратный */}
                            {k.companyId == null &&
                            <div data-tooltip="Доб. компанию" className={s.tooltip} onClick={()=>setCompany(k.id)}><span><FontAwesomeIcon icon={faBuilding} /></span></div>
                            }
                            {k.assurance!=null && <div data-tooltip="В тех. отдел" className={s.tooltip} onClick={()=>props.setDateClose(k.id)}><span><FontAwesomeIcon icon={faArrowRight}/></span></div>/* Передаём в тех. отдел повторно */}
                        </div>
                        <div className={s.iconHover}>
                            <div data-tooltip="В тех. отдел" className={s.tooltip} onClick={()=>props.setDateClose(k.id)}><span><FontAwesomeIcon icon={faArrowRight}/></span></div>
                            {k.assurance!=null && <div data-tooltip="Ввести ТТН" className={s.tooltip} onClick={()=>setTTN(k.id)}><span>{getStatus(k.assurance)}</span></div>}
                        </div>
                    </div>
                )
            })}
            </>   
            }
            <h2>Готово к отгрузке</h2>
            {props.repair.map(k=>{
                return(
                    k.ttn==null && k.assurance!=null &&
                    <div className={s.main} key={"repair"+k.id}>
                        <div>{k.id}</div>
                        <div>
                            <AnyFormatDate date={k.date_close}/>
                            {getDelay(k.date_close) && <p className={main.red}>Просрочено</p>}
                        </div>
                        <div>{k.responsibleName==null ? "Не назначен" : k.responsibleName}</div>
                        <div>{k.coll} <span className={s.pensil} onClick={()=>setCollInRepair(k.id)}><FontAwesomeIcon icon={faPencilAlt}/></span></div>
                        <div>
                            {k.companyNameFromRequest && <div className={s.fio}><span className={s.m10}><FontAwesomeIcon icon={faBuilding} /></span>{k.companyNameFromRequest}</div>}
                            <div className={s.fio}><span className={s.m10}><FontAwesomeIcon icon={faUser} /></span>{k.fio+" - "+k.tell}</div>
                            <div>{k.defect}</div>
                        </div>
                        <div className={s.miniBlocks}>
                            {k.countModuls!=0 && <div data-tooltip="Модули" className={s.tooltip} onClick={()=>setModulsList(k.id)}><span><FontAwesomeIcon icon={faList} /></span></div>/* Показать модули в заявке */}
                            <div data-tooltip="Адрес" className={s.tooltip} onClick={()=>setAddress(k.address)}><span><FontAwesomeIcon icon={faTruck} /></span></div>{/* Показать адрес возвратный */}
                            {k.companyId == null &&
                            <div data-tooltip="Доб. компанию" className={s.tooltip} onClick={()=>setCompany(k.id)}><span><FontAwesomeIcon icon={faBuilding} /></span></div>
                            }
                            {k.assurance!=null && <div data-tooltip="В тех. отдел" className={s.tooltip} onClick={()=>props.setDateClose(k.id)}><span><FontAwesomeIcon icon={faArrowRight}/></span></div>/* Передаём в тех. отдел повторно */}
                        </div>
                        <div className={s.iconHover}>
                            {k.assurance!=null && <div data-tooltip="Ввести ТТН" className={s.tooltip} onClick={()=>setTTN(k.id)}><span>{getStatus(k.assurance)}</span></div>}
                        </div>
                    </div>
                )
            })   
            }
            <h2>В тех. отделе</h2>
            {props.repair.map(k=>{
                return(
                    k.ttn==null && k.date_close!==null && k.assurance==null &&
                    <div className={s.main} key={"repair"+k.id}>
                        <div>{k.id}</div>
                        <div><AnyFormatDate date={k.date_close}/>{getDelay(k.date_close) && <p className={main.red}>Просрочено</p>}</div>
                        <div>{k.responsibleName==null ? "Не назначен" : k.responsibleName}</div>
                        <div>{k.coll} <span className={s.pensil} onClick={()=>setCollInRepair(k.id)}><FontAwesomeIcon icon={faPencilAlt}/></span></div>
                        <div>
                            {k.companyNameFromRequest && <div className={s.fio}><span className={s.m10}><FontAwesomeIcon icon={faBuilding} /></span>{k.companyNameFromRequest}</div>}
                            <div className={s.fio}><span className={s.m10}><FontAwesomeIcon icon={faUser} /></span>{k.fio+" - "+k.tell}</div>
                            <div>{k.defect}</div>
                        </div>
                        <div className={s.miniBlocks}>
                            {k.countModuls!=0 && <div data-tooltip="Модули" className={s.tooltip} onClick={()=>setModulsList(k.id)}><span><FontAwesomeIcon icon={faList} /></span></div>/* Показать модули в заявке */}
                            <div data-tooltip="Адрес" className={s.tooltip} onClick={()=>setAddress(k.address)}><span><FontAwesomeIcon icon={faTruck} /></span></div>{/* Показать адрес возвратный */}
                            {k.companyId == null &&
                            <div data-tooltip="Доб. компанию" className={s.tooltip} onClick={()=>setCompany(k.id)}><span><FontAwesomeIcon icon={faBuilding} /></span></div>
                            }
                            {k.assurance!=null && <div data-tooltip="В тех. отдел" className={s.tooltip} onClick={()=>props.setDateClose(k.id)}><span><FontAwesomeIcon icon={faArrowRight}/></span></div>/* Передаём в тех. отдел повторно */}
                        </div>
                        <div className={s.iconHover}>
                            {k.date_close==null && <div data-tooltip="В тех. отдел" className={s.tooltip} onClick={()=>props.setDateClose(k.id)}><span><FontAwesomeIcon icon={faArrowRight}/></span></div>/* Передаём в тех. отдел */}
                            {k.assurance!=null && <div data-tooltip="Ввести ТТН" className={s.tooltip} onClick={()=>setTTN(k.id)}><span>{getStatus(k.assurance)}</span></div>}
                        </div>
                    </div>
                )
            })   
            }
            <h2>Архив</h2>
            {props.repair.map(k=>{
               return(
                k.ttn!=null && 
                <div className={s.main+" "+s.archive} key={"repair"+k.id}>
                    <div>{k.id}</div>
                    <div><AnyFormatDate date={k.date_close}/></div>
                    <div>{k.responsibleName}</div>
                    <div>{k.coll}</div>
                    <div className={s.twoBlocks}>
                        <div><AnyFormatDate date={k.date_delivery}/></div>
                        <div>{k.ttn}</div>
                    </div>
                    <div className={s.miniBlocks}>
                        <div data-tooltip="Модули" className={s.tooltip} onClick={()=>setModulsList(k.id)}><span><FontAwesomeIcon icon={faList} /></span></div>
                        <div data-tooltip="Адрес" className={s.tooltip} onClick={()=>setAddress(k.address)}><span><FontAwesomeIcon icon={faTruck} /></span></div>
                    </div>
                    <div className={s.iconHover}>
                        <div><span>{getStatus(k.assurance)}</span></div>
                    </div>
                </div>
            )})
            }
        </div>
        
    )
}
export default RepairMain;