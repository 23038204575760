import React, { useState } from 'react';
import s from './CheckTest.module.css';

const NewNumberReturnTest = (props) =>{
    const change = ()=>{
        if(newData!==0){
            props.newFunction(props.active, newData)
            props.setActive(false)
        } 
    }
    let [newData, setNewData] = useState(0)
    const setNewDataF = (e)=>{
        setNewData(e.target.value)
    }
    return(
        <div className={s.changeDate}>
           <div><h3>Изменение номера возвратной заявки</h3></div>
           <div><input type="number" onChange={setNewDataF} /></div>
           <div className={s.center}><button onClick={()=>change()}>Изменить</button></div>
        </div>
    )
}
export default NewNumberReturnTest;