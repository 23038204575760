import React, { useState, useMemo } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPencilAlt,
    faCheck,
    faTrash,
    faSortUp,
    faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import s from './Kp.module.css';
import main from '../../Main.module.css';
import AnyFormatDate from '../../Production/common/AnyFormatDate/AnyFormatDate';
import Modal from '../../Production/common/Modal/Modal';
import SetStatusWorkingKp from './Modal/SetStatusWorkingKp';
import ChangeDateReminder from './Modal/ChangeDateReminder';
import Cookies from 'js-cookie';
import DeleteMeeting from '../Meeting/Modal/DeleteMeeting';
import CloseMeeting from '../Meeting/Modal/CloseMeeting';
import DeleteCompanyFromPlanner from './Modal/DeleteCompanyFromPlanner';
import MenuManager from '../common/MenuManager/MenuManager';

const KpMain = (props) => {
    const { idManager } = useParams(); // useParams to access route parameters
    let date = new Date();
    let year = '' + date.getFullYear() + '';
    let month = date.getMonth() + 1;
    month = '' + month;
    month.length === 1 ? (month = '-0' + month) : (month = '-' + month);
    let day = '' + date.getUTCDate();
    day.length === 1 ? (day = '-0' + day) : (day = '-' + day);
    date = year + month + day;

    let [kpId, setKpId] = useState(false);
    let [kpNumber, setKpNumber] = useState(false);
    const setKpIdF = (id, number, type) => {
        setKpId(id);
        setKpNumber(number);
        setType(type);
    };
    let [changeDate, setChangeDate] = useState(false);
    const setChangeDateF = (id, type) => {
        setChangeDate(id);
        setType(type);
    };
    let [newNameForBlock, showModalChangeStatus] = useState(false);
    const showModalChangeStatusF = (id) => {
        let postParams = {
            new_status: text,
            status_id: statusID,
            personalId: props.personalId,
            dateStart: dateStart,
            dateEnd: dateEnd,
        };
        props.editCompanyStatus(postParams);
        setText('');
        showModalChangeStatus(false);
    };
    let [text, setText] = useState('');
    const setNewStatus = (e) => {
        setText(e.target.value);
    };
    let [statusID, setStatusID] = useState('');
    const setNewStatusID = (e) => {
        setStatusID(e.target.value);
    };
    let [type, setType] = useState();
    let [dateStart, setDateStart] = useState(date);
    const setDateStartF = (e) => setDateStart(e.target.value);
    let [dateEnd, setDateEnd] = useState(date);
    const setDateEndF = (e) => setDateEnd(e.target.value);
    const getTypePlan = (type) => {
        switch (type) {
            case 1:
                return 'Счёт';
            case 2:
                return 'КП';
            case 3:
                return 'Встреча';
            case 4:
                return 'Звонок';
            case 6:
                return 'Тест';
            default:
                return 'Не известно';
        }
    };
    const getTypePlanNumber = (type) => {
        switch (type) {
            case 1:
                return 0;
            case 2:
                return 4;
            default:
                return 'Не известно';
        }
    };
    let [deleteMeeting, setDelete] = useState(false);
    let [deleteCompany, setDeleteCompany] = useState(false);
    let [closeMeeting, setCloseMeeting] = useState(false);

    // Стан сортування
    let [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

    const sortedData = useMemo(() => {
        let sortableItems = [...props.kp];
        if (sortConfig !== null && sortConfig.key) {
            sortableItems.sort((a, b) => {
                let aValue = a[sortConfig.key];
                let bValue = b[sortConfig.key];

                if (aValue == null) aValue = '';
                if (bValue == null) bValue = '';

                if (sortConfig.key === 'date_plan') {
                    aValue = new Date(aValue);
                    bValue = new Date(bValue);
                } else {
                    aValue = String(aValue).toLowerCase();
                    bValue = String(bValue).toLowerCase();
                }

                if (aValue < bValue) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        } else {
            sortableItems.sort((a, b) => (a.date_plan > b.date_plan ? 1 : -1));
        }
        return sortableItems;
    }, [props.kp, sortConfig]);

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    return (
        <div>
            <div>
                <div className={s.blockForFilter}>
                    {(Cookies.get('id') === '44' || Cookies.get('id') === '34') && (
                        <MenuManager idNow={idManager} link={'/kp'} />
                    )}
                    <div className={s.blockForDate}>
                        <div className={main.right + ' ' + main.mlr10}>
                            <input
                                type="date"
                                value={dateStart}
                                className={main.input}
                                onChange={setDateStartF}
                            />
                        </div>
                        <div className={main.center + ' ' + main.mlr10}>
                            <input
                                type="date"
                                value={dateEnd}
                                className={main.input}
                                onChange={setDateEndF}
                            />
                        </div>
                        <div className={main.center}>
                            <input
                                type="button"
                                value={'Применить'}
                                className={main.inputAdd + ' ' + main.mt10}
                                onClick={() => props.getKp(props.personalId, dateStart, dateEnd)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={s.dostup}>
                <NavLink to={'/sales/allCompany/' + Cookies.get('id')}>
                    <span>Список всех компаний</span>
                </NavLink>
                <NavLink to={'/sales/meeting/' + Cookies.get('id')}>
                    <span>Запланировать встречу</span>
                </NavLink>
            </div>
            <h1>Планировщик</h1>
            <div className={s.mainKp + ' ' + main.header}>
                <div onClick={() => handleSort('number')} className={s.sortableHeader}>
                    Название
                    {sortConfig.key === 'number' && (
                        <FontAwesomeIcon
                            icon={sortConfig.direction === 'asc' ? faSortUp : faSortDown}
                        />
                    )}
                </div>
                <div onClick={() => handleSort('exchange_rates')} className={s.sortableHeader}>
                    Курс
                    {sortConfig.key === 'exchange_rates' && (
                        <FontAwesomeIcon
                            icon={sortConfig.direction === 'asc' ? faSortUp : faSortDown}
                        />
                    )}
                </div>
                <div onClick={() => handleSort('name_with_akon')} className={s.sortableHeader}>
                    Компания
                    {sortConfig.key === 'name_with_akon' && (
                        <FontAwesomeIcon
                            icon={sortConfig.direction === 'asc' ? faSortUp : faSortDown}
                        />
                    )}
                </div>
                <div onClick={() => handleSort('status_text')} className={s.sortableHeader}>
                    Статус
                    {sortConfig.key === 'status_text' && (
                        <FontAwesomeIcon
                            icon={sortConfig.direction === 'asc' ? faSortUp : faSortDown}
                        />
                    )}
                </div>
                <div onClick={() => handleSort('date_plan')} className={s.sortableHeader}>
                    Плановая дата
                    {sortConfig.key === 'date_plan' && (
                        <FontAwesomeIcon
                            icon={sortConfig.direction === 'asc' ? faSortUp : faSortDown}
                        />
                    )}
                </div>
                <div>Отработка</div>
            </div>
            {sortedData.map((k, index) => (
                <div key={'kp' + index} className={s.mainKp + ' ' + main.borderB}>
                    <div>
                        {k.type_plan === 1 || k.type_plan === 2 ? (
                            <NavLink
                                to={'/check/' + k.id + '/' + getTypePlanNumber(k.type_plan)}
                                target="_blank"
                            >
                                {getTypePlan(k.type_plan)} - {k.number}
                            </NavLink>
                        ) : k.type_plan === 3 ? (
                            <NavLink to={'/sales/meeting/' + Cookies.get('id')} target="_blank">
                                Встреча
                            </NavLink>
                        ) : k.type_plan === 4 ? (
                            'Звонок'
                        ) : k.type_plan === 6 ? (
                            <NavLink to={'/check-t'} target="_blank">
                                Тест №{k.id}
                            </NavLink>
                        ) : (
                            ''
                        )}
                    </div>
                    <div>{k.exchange_rates}</div>
                    <div>
                        <NavLink to={'/company/profile/' + k.company_id} target="_blank">
                            {k.name_with_akon}
                        </NavLink>
                        {props.personalId === '486' && (
                            <div className={s.client_segmentation}>{k.client_segmentation_name}</div>
                        )}
                    </div>
                    <div>
                        {k.status_text ?? ''}
                        {k.type_plan !== 6 && (
                            <FontAwesomeIcon
                                icon={faPencilAlt}
                                onClick={() => {
                                    setText(k.status_text ?? '');
                                    setStatusID(k.id);
                                    showModalChangeStatus(true);
                                }}
                                className={main.icon}
                            />
                        )}
                    </div>
                    {k.type_plan !== 6 && (
                        <div>
                            <AnyFormatDate date={k.date_plan} />
                            <FontAwesomeIcon
                                icon={faPencilAlt}
                                onClick={() => setChangeDateF(k.id, k.type_plan)}
                                className={main.icon}
                            />
                        </div>
                    )}
                    {(k.type_plan === 2 || k.type_plan === 1) && (
                        <div>
                            <input
                                type="button"
                                className={main.inputAdd}
                                value="Отработать"
                                onClick={() => setKpIdF(k.id, k.number, k.type_plan)}
                            />
                        </div>
                    )}
                    {k.type_plan === 3 && (
                        <div className={main.twoBlocksGrid}>
                            <FontAwesomeIcon icon={faCheck} onClick={() => setCloseMeeting(k.id)} className={main.green} />
                            <FontAwesomeIcon icon={faTrash} onClick={() => setDelete(k.id)} className={main.red} />
                        </div>
                    )}
                    {k.type_plan === 4 && (
                        <div>
                            <FontAwesomeIcon icon={faCheck} onClick={() => setDeleteCompany(k.id)} className={main.green} />
                        </div>
                    )}
                    {k.type_plan === 6 && <div></div>}
                </div>
            ))}
            {kpId && (
                <Modal active={kpId} setActive={setKpId}>
                    <SetStatusWorkingKp
                        active={kpId}
                        setActive={setKpId}
                        kpNumber={kpNumber}
                        statusWorkingKp={props.statusWorkingKp}
                        personalId={props.personalId}
                        dateStart={dateStart}
                        dateEnd={dateEnd}
                        type={type}
                    />
                </Modal>
            )}
            {newNameForBlock && (
                <Modal active={newNameForBlock} setActive={showModalChangeStatus}>
                    <div className={s.center}>
                        <h1>Изменение имени блока</h1>
                        <textarea onChange={setNewStatus} value={text} className={s.textarea} maxLength={50} />
                        <div className={s.center}>
                            <button onClick={showModalChangeStatusF} className={s.button}>
                                Изменить
                            </button>
                        </div>
                    </div>
                </Modal>
            )}
            {changeDate && (
                <Modal active={changeDate} setActive={setChangeDate}>
                    <ChangeDateReminder
                        active={changeDate}
                        setActive={setChangeDate}
                        changeDateReminderKp={props.changeDateReminderKp}
                        personalId={props.personalId}
                        dateStart={dateStart}
                        dateEnd={dateEnd}
                        type={type}
                    />
                </Modal>
            )}
            {deleteMeeting && (
                <Modal active={deleteMeeting} setActive={setDelete}>
                    <DeleteMeeting
                        {...props}
                        setActive={setDelete}
                        active={deleteMeeting}
                        deleteMeeting={props.deleteMeeting}
                        idManager={props.personalId}
                    />
                </Modal>
            )}
            {closeMeeting && (
                <Modal active={closeMeeting} setActive={setCloseMeeting}>
                    <CloseMeeting
                        {...props}
                        setActive={setCloseMeeting}
                        active={closeMeeting}
                        сloseMeeting={props.сloseMeeting}
                        idManager={props.personalId}
                    />
                </Modal>
            )}
            {deleteCompany && (
                <Modal active={deleteCompany} setActive={setDeleteCompany}>
                    <DeleteCompanyFromPlanner
                        {...props}
                        setActive={setDeleteCompany}
                        active={deleteCompany}
                        deleteCompanyFromPlanner={props.deleteCompanyFromPlanner}
                        dateStart={dateStart}
                        dateEnd={dateEnd}
                        idManager={props.personalId}
                    />
                </Modal>
            )}
        </div>
    );
};

export default KpMain;
