import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getSentInvoicesForMonth, getSumSentInvoicesForMonth, getSumSentInvoicesForYear, getSentInvoiceForMonthWithDateManager } from '../../../redux/shipment-reducer';
import Shipment from './Shipment';
import MonthBlock from '../../Production/common/MonthBlock/MonthBlock';
import { useParams } from 'react-router-dom';

class ShipmentContainer extends React.Component {
    componentDidMount() {
        const { month } = this.props.params;
        this.props.getSentInvoicesForMonth(month);
        this.props.getSumSentInvoicesForMonth(month);
        this.props.getSumSentInvoicesForYear();
        this.props.getSentInvoiceForMonthWithDateManager(month);
    }

    componentDidUpdate(prevProps) {
        const { month } = this.props.params;
        if (prevProps.params.month !== month) {
            this.props.getSentInvoicesForMonth(month);
            this.props.getSumSentInvoicesForMonth(month);
            this.props.getSentInvoiceForMonthWithDateManager(month);
        }
    }

    render() {
        const { month } = this.props.params;
        return (
            <div>
                <MonthBlock path={"admin/shipment"} month={month} />
                <Shipment
                    sentInvoicesForMonth={this.props.sentInvoicesForMonth}
                    sumSentInvoicesForMonth={this.props.sumSentInvoicesForMonth}
                    sumSentInvoicesForYear={this.props.sumSentInvoicesForYear}
                    invoiceForMonthWithDateManager={this.props.invoiceForMonthWithDateManager}
                />
            </div>
        );
    }
}

let mapStateToProps = (state) => ({
    sentInvoicesForMonth: state.shipment.sentInvoicesForMonth,
    sumSentInvoicesForMonth: state.shipment.sumSentInvoicesForMonth,
    sumSentInvoicesForYear: state.shipment.sumSentInvoicesForYear,
    invoiceForMonthWithDateManager: state.shipment.invoiceForMonthWithDateManager,
});

// Custom withRouter replacement using useParams
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const params = useParams();
        return <Component {...props} params={params} />;
    }

    return ComponentWithRouterProp;
}

export default compose(
    connect(mapStateToProps, {
        getSentInvoicesForMonth,
        getSumSentInvoicesForMonth,
        getSumSentInvoicesForYear,
        getSentInvoiceForMonthWithDateManager
    }),
    withRouter
)(ShipmentContainer);
