import React from 'react';
import { connect } from 'react-redux';
import {getAllChannelChains, addNomenclatureParam, getAllNom, setNewChannelCheck} from '../../../../redux/future-reducer';
import ChannelChains from './ChannelChains';

class ChannelChainsContainer extends React.Component{
    
    componentDidMount(){
        this.props.getAllChannelChains("");
        console.log(this.props.allNomType)
        this.props.getAllNom("");
        console.log(this.props.allNomType)
    }
    
    render(){
        return <ChannelChains {...this.props}/>
    } 
}

let mapStateToProps = (state) =>({
    allChannelChains: state.future.allChannelChains,
    allNomType: state.future.allNomType,
    allNomSignalType: state.future.allNomSignalType,
    allNomRange: state.future.allNomRange,
    allNomWire: state.future.allNomWire,
})
export default connect( mapStateToProps, {getAllChannelChains, addNomenclatureParam, getAllNom, setNewChannelCheck})(ChannelChainsContainer);