import React from 'react';
import { connect } from 'react-redux';
import {getAllProject, addNewProject, removeProject, changeProject} from '../../../redux/project-reducer';
import Project from './Project';

class ProjectContainer extends React.Component{
    componentDidMount(){
        this.props.getAllProject();
    }
    
    render(){
        return (
            <div>
                <Project {...this.props}/>
            </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    allProject: state.project.allProject,
})
export default connect( mapStateToProps, {getAllProject, addNewProject, removeProject, changeProject})(ProjectContainer);