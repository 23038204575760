import { accessoriesAPI } from "../api/api";

const SET_ACCESSORIES_LIST = "SET_PAYD_INVOICE";


let initialState = {
    accessoriesList: []
}

const accessoriesReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_ACCESSORIES_LIST:
            return {
                ...state,
                accessoriesList: action.data
            }
        default:
            return state
    }
}

export const setAccessoriesList= (data) => ({type: SET_ACCESSORIES_LIST, data});

export const getAccessoriesList= () =>{
    return (dispatch) => {
        accessoriesAPI.getAccessoriesList()
        .then(data => {
            dispatch(setAccessoriesList(data));
        });
    }
}


export default accessoriesReducer;

