import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
    getFiltrWants,
    getCompanyWithFiltrWants,
    setShowFieldWants,
    addNewFiltrBlock,
    getFiltrBlock,
    deleteFiltrBlock
} from '../../../redux/sales-reducer';
import FiltrWants from './FiltrWants';
import Cookies from "js-cookie";
import { useParams } from 'react-router-dom';

class FiltrWantsContainer extends React.Component {
    componentDidMount() {
        let isAdmin = !!Cookies.get('adminID');
        let currentPersonalId = this.props.params.personalId;
        if (!isAdmin) {
            if (Cookies.get('id') !== currentPersonalId) {
                currentPersonalId = Cookies.get('id');
            }
        }
        this.props.getFiltrBlock(currentPersonalId);
        this.props.getFiltrWants(currentPersonalId);
    }

    render() {
        return (
            <div>
                <FiltrWants
                    filtrWants={this.props.filtrWants}
                    getCompanyWithFiltrWants={this.props.getCompanyWithFiltrWants}
                    companyWithFiltrWants={this.props.companyWithFiltrWants}
                    setShowFieldWants={this.props.setShowFieldWants}
                    addNewFiltrBlock={this.props.addNewFiltrBlock}
                    filtrBlock={this.props.filtrBlock}
                    deleteFiltrBlock={this.props.deleteFiltrBlock}
                />
            </div>
        );
    }
}

let mapStateToProps = (state) => ({
    filtrWants: state.sales.filtrWants,
    companyWithFiltrWants: state.sales.companyWithFiltrWants,
    filtrBlock: state.sales.filtrBlock,
});

// Custom withRouter replacement using useParams
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const params = useParams();
        return <Component {...props} params={params} />;
    }

    return ComponentWithRouterProp;
}

export default compose(
    connect(mapStateToProps, {
        getFiltrWants,
        getCompanyWithFiltrWants,
        setShowFieldWants,
        addNewFiltrBlock,
        getFiltrBlock,
        deleteFiltrBlock
    }),
    withRouter
)(FiltrWantsContainer);
