import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
    getTasks,
    setNewTask,
    addCommentForTask,
    addNewDateInTask,
    setNewWorkerInTask,
    getTasksComment,
    setTaskWithoutArchive,
    deleteTask,
    setDateReminder,
    changeDateForTask,
    changeTextTask
} from '../../redux/task-reducer';
import { getWorkersListForTasks } from '../../redux/worker-reducer';
import Task from './Task';
import Cookies from "js-cookie";
import { useParams } from 'react-router-dom';

class TaskContainer extends React.Component {
    componentDidMount() {
        let isAdmin = !!Cookies.get('adminID');
        let currentPersonalId = this.props.params.personalId;
        if (!isAdmin) {
            if (Cookies.get('personalId') !== currentPersonalId) {
                currentPersonalId = Cookies.get('id');
            }
        }
        this.props.getTasks(currentPersonalId);
        this.props.getWorkersListForTasks();
        this.props.getTasksComment(currentPersonalId);
    }

    render() {
        return (
            <Task
                tasks={this.props.tasks}
                setNewTask={this.props.setNewTask}
                addCommentForTask={this.props.addCommentForTask}
                workersListTech={this.props.workersList}
                addNewDateInTask={this.props.addNewDateInTask}
                setNewWorkerInTask={this.props.setNewWorkerInTask}
                tasksComment={this.props.tasksComment}
                getTasks={this.props.getTasks}
                setTaskWithoutArchive={this.props.setTaskWithoutArchive}
                personalId={this.props.params.personalId}
                deleteTask={this.props.deleteTask}
                setDateReminder={this.props.setDateReminder}
                changeDateForTask={this.props.changeDateForTask}
                changeTextTask={this.props.changeTextTask}
            />
        );
    }
}

let mapStateToProps = (state) => ({
    tasks: state.techTask.tasks,
    tasksComment: state.techTask.tasksComment,
    workersList: state.worker.workersList,
});

// Custom withRouter replacement using useParams
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const params = useParams();
        return <Component {...props} params={params} />;
    }

    return ComponentWithRouterProp;
}

export default compose(
    connect(mapStateToProps, {
        getTasks,
        setNewTask,
        addCommentForTask,
        getWorkersListForTasks,
        addNewDateInTask,
        setNewWorkerInTask,
        getTasksComment,
        setTaskWithoutArchive,
        deleteTask,
        setDateReminder,
        changeDateForTask,
        changeTextTask
    }),
    withRouter
)(TaskContainer);
