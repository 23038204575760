import { salesAPI } from "../api/api";
import {toast} from "react-toastify";

const SET_ALL_COMPANY_SEGMENTATION = "SET_ALL_COMPANY_SEGMENTATION";
const SET_ALL_COMPANY_OUTDATED_SEGMENTATION = "SET_ALL_COMPANY_OUTDATED_SEGMENTATION";
const SET_ALL_COMPANY_CATEGORY = "SET_ALL_COMPANY_CATEGORY";
const SET_COMPANY_IN_SEGMENTATION_CATEGORIES = "SET_COMPANY_IN_SEGMENTATION_CATEGORIES";
const SET_PAY_MODULS_IN_COMPANY = "SET_PAY_MODULS_IN_COMPANY";
const SET_EMAIL_COMPANY_AGENTS = "SET_EMAIL_COMPANY_AGENTS";
const SET_COMPANY_WITH_REGION = "SET_COMPANY_WITH_REGION";
const SET_ALL_COMPANY_REGION = "SET_ALL_COMPANY_REGION";
const SET_FILTR_WANTS = "SET_FILTR_WANTS";
const SET_COMPANY_WITH_FILTR_WANTS = "SET_COMPANY_WITH_FILTR_WANTS";
const SET_KP = "SET_KP";
const SET_SUM_KP_FOR_MONTH = "SET_SUM_KP_FOR_MONTH";
const SET_SUM_KP_FOR_MONTH_GROUP_STATUS = "SET_SUM_KP_FOR_MONTH_GROUP_STATUS";
const SET_FILTR_BLOCK = "SET_FILTR_BLOCK";
const SET_SUM_KP_PAID_FOR_MONTH = "SET_SUM_KP_PAID_FOR_MONTH";
const SET_CHECK_TEST = "SET_CHECK_TEST";
const SET_CHECK_TEST_MODULS = "SET_CHECK_TEST_MODULS";
const SET_COUNT_OVERDUE_TEST_MODULS = "SET_COUNT_OVERDUE_TEST_MODULS";
const SET_COUNT_OVERDUE_TEST_MODULS_BACK = "SET_COUNT_OVERDUE_TEST_MODULS_BACK";
const SET_KP_PLAN = "SET_KP_PLAN";
const SUM_MONTH_KP_PLAN = "SUM_MONTH_KP_PLAN";
const DISTRIBUTION_NOMENCLATURES = "DISTRIBUTION_NOMENCLATURES";
const DISTRIBUTION = "DISTRIBUTION";
const RETURN_FROM_TEST = "RETURN_FROM_TEST";
const SET_STATUS_KP = "SET_STATUS_KP";
const SET_ALL_COMPANY_WITH_MANAGER = "SET_ALL_COMPANY_WITH_MANAGER";
const SET_ALL_SHARED_COMPANY_WITH_MANAGER = "SET_ALL_SHARED_COMPANY_WITH_MANAGER";
const SET_ALL_MEETING_WITH_MANAGER = "SET_ALL_MEETING_WITH_MANAGER";
const SET_MANAGER_SALARY_TABLE = "SET_MANAGER_SALARY_TABLE";
const SET_ALL_COMPANY_PLANER_IN_THIS_DATE = "SET_ALL_COMPANY_PLANER_IN_THIS_DATE";


let initialState = {
    companySegmentation: [],
    companyOutdatedSegmentation: [],
    companyCategories: [],
    companyInSegmentCategory: [],
    moduls: [],
    emailCompanyAgents: [],
    companyWithRegion: [],
    companyRegion: [],
    filtrWants: [],
    companyWithFiltrWants: [],
    kp: [],
    kpPlan: [],
    sumKpForMonth: [],
    sumKpForMonthGroupStatus: [],
    filtrBlock: [],
    sumKpPaidForMonth: [],
    checkTest: [],
    checkTestModuls: [],
    countOverdueCheckTest: 0,
    countOverdueCheckTestBack: 0,
    sumMonthKpPlan: [],
    allDistribution: [],
    allDistributionNomenclatures: [],
    returnFromTest: [],
    statusKp: [],
    allSharedCompanyWithManager: [],
    allCompanyWithManager: [],
    allSumCompanyWithManager: [],
    allMeetingWithManager: [],
    editCompanyStatus : [],
    relatedPaymentForYearMonth : [],
    countCompanyPlanerInThisDate : 0,
}

const SalesReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_ALL_COMPANY_SEGMENTATION:
            return {
                ...state,
                companySegmentation: action.companySegmentation
            }
        case SET_ALL_COMPANY_OUTDATED_SEGMENTATION:
            return {
                ...state,
                companyOutdatedSegmentation: action.companyOutdatedSegmentation
            }
        case SET_ALL_COMPANY_CATEGORY:
            return {
                ...state,
                companyCategories: action.companyCategories
            }
        case SET_COMPANY_IN_SEGMENTATION_CATEGORIES:
            return {
                ...state,
                companyInSegmentCategory: action.companyInSegmentCategory
            }
        case SET_PAY_MODULS_IN_COMPANY:
            return {
                ...state,
                moduls: action.moduls
            }
        case SET_EMAIL_COMPANY_AGENTS:
            return {
                ...state,
                emailCompanyAgents: action.emailCompanyAgents
            }
        case SET_COMPANY_WITH_REGION:
            return {
                ...state,
                companyWithRegion: action.companyWithRegion
            }
        case SET_ALL_COMPANY_REGION:
            return {
                ...state,
                companyRegion: action.companyRegion
            }
        case SET_FILTR_WANTS:
            return {
                ...state,
                filtrWants: action.filtrWants
            }
        case SET_COMPANY_WITH_FILTR_WANTS:
            return {
                ...state,
                companyWithFiltrWants: action.companyWithFiltrWants
            }
        case SET_KP:
            return {
                ...state,
                kp: action.kp
            }
        case SET_SUM_KP_FOR_MONTH:
            return {
                ...state,
                sumKpForMonth: action.sumKpForMonth
            }
        case SET_SUM_KP_FOR_MONTH_GROUP_STATUS:
            return {
                ...state,
                sumKpForMonthGroupStatus: action.sumKpForMonthGroupStatus
            }
        case SET_FILTR_BLOCK:
            return {
                ...state,
                filtrBlock: action.filtrBlock
            }
        case SET_SUM_KP_PAID_FOR_MONTH:
            return {
                ...state,
                sumKpPaidForMonth: action.sumKpPaidForMonth
            }
        case SET_CHECK_TEST:
            return {
                ...state,
                checkTest: action.checkTest
            }
        case SET_CHECK_TEST_MODULS:
            return {
                ...state,
                checkTestModuls: action.checkTestModuls
            }
        case SET_COUNT_OVERDUE_TEST_MODULS:
            return {
                ...state,
                countOverdueCheckTest: action.countOverdueCheckTest
            }
        case SET_COUNT_OVERDUE_TEST_MODULS_BACK:
            return {
                ...state,
                countOverdueCheckTestBack: action.countOverdueCheckTestBack
            }
        case SET_KP_PLAN:
            return {
                ...state,
                kpPlan: action.kpPlan
            }
        case SUM_MONTH_KP_PLAN:
            return {
                ...state,
                sumMonthKpPlan: action.sumMonthKpPlan
            }
        case DISTRIBUTION_NOMENCLATURES:
            return {
                ...state,
                allDistributionNomenclatures: action.data
            }
        case DISTRIBUTION:
            return {
                ...state,
                allDistribution: action.data
            }
        case RETURN_FROM_TEST:
            return {
                ...state,
                returnFromTest: action.data
            }
        case SET_STATUS_KP:
            return {
                ...state,
                statusKp: action.data
            }
        case SET_ALL_COMPANY_WITH_MANAGER:
            return {
                ...state,
                allCompanyWithManager: action.data[0],
                allSumCompanyWithManager: action.data[1]
            }
        case SET_ALL_SHARED_COMPANY_WITH_MANAGER:
            //console.log(action.data.data)
            return {
                ...state,
                allSharedCompanyWithManager: action.data.data
            }
        case SET_MANAGER_SALARY_TABLE:
            return {
                ...state,
                relatedPaymentForYearMonth: action.data.data
            }
        case SET_ALL_MEETING_WITH_MANAGER:
            return {
                ...state,
                allMeetingWithManager: action.data,
            }
        case SET_ALL_COMPANY_PLANER_IN_THIS_DATE:
            return {
                ...state,
                countCompanyPlanerInThisDate: action.data,
            }
        default:
            return state
    }
}

export const setAllCompanySegmentation = (companySegmentation) => ({type: SET_ALL_COMPANY_SEGMENTATION, companySegmentation});
export const setAllCompanyOutdatedSegmentation = (companyOutdatedSegmentation) => ({type: SET_ALL_COMPANY_OUTDATED_SEGMENTATION, companyOutdatedSegmentation});
export const setAllCompanyCategories = (companyCategories) => ({type: SET_ALL_COMPANY_CATEGORY, companyCategories});
export const setCompanyInSegmentationCategories = (companyInSegmentCategory) => ({type: SET_COMPANY_IN_SEGMENTATION_CATEGORIES, companyInSegmentCategory});
export const setPayModulsInCompany = (moduls) => ({type: SET_PAY_MODULS_IN_COMPANY, moduls});
export const setEmailCompanyAgents = (emailCompanyAgents) => ({type: SET_EMAIL_COMPANY_AGENTS, emailCompanyAgents});
export const setCompanyWithRegion = (companyWithRegion) => ({type: SET_COMPANY_WITH_REGION, companyWithRegion});
export const setAllCompanyRegion = (companyRegion) => ({type: SET_ALL_COMPANY_REGION, companyRegion});
export const setFiltrWants = (filtrWants) => ({type: SET_FILTR_WANTS, filtrWants});
export const setCompanyWithFiltrWants = (companyWithFiltrWants) => ({type: SET_COMPANY_WITH_FILTR_WANTS, companyWithFiltrWants});
export const setKp = (kp) => ({type: SET_KP, kp});
export const setKpPlan = (kpPlan) => ({type: SET_KP_PLAN, kpPlan});
export const setSumKpForMonth = (sumKpForMonth) => ({type: SET_SUM_KP_FOR_MONTH, sumKpForMonth});
export const setSumKpPaidForMonth = (sumKpPaidForMonth) => ({type: SET_SUM_KP_PAID_FOR_MONTH, sumKpPaidForMonth});
export const setSumKpForMonthGroupStatus = (sumKpForMonthGroupStatus) => ({type: SET_SUM_KP_FOR_MONTH_GROUP_STATUS, sumKpForMonthGroupStatus});
export const setFiltrBlock = (filtrBlock) => ({type: SET_FILTR_BLOCK, filtrBlock});
export const setCheckTest = (checkTest) => ({type: SET_CHECK_TEST, checkTest});
export const setCheckTestModuls = (checkTestModuls) => ({type: SET_CHECK_TEST_MODULS, checkTestModuls});
export const setCountOverdueCheckTest = (countOverdueCheckTest) => ({type: SET_COUNT_OVERDUE_TEST_MODULS, countOverdueCheckTest});
export const setCountOverdueCheckTestBack = (countOverdueCheckTestBack) => ({type: SET_COUNT_OVERDUE_TEST_MODULS_BACK, countOverdueCheckTestBack});
export const setSumMonthKpPlan = (sumMonthKpPlan) => ({type: SUM_MONTH_KP_PLAN, sumMonthKpPlan});
export const setAllDistributionNomenclatures = (data) => ({type: DISTRIBUTION_NOMENCLATURES, data});
export const setAllDistribution = (data) => ({type: DISTRIBUTION, data});
export const setReturnFromTest = (data) => ({type: RETURN_FROM_TEST, data});
export const setStatusKp = (data) => ({type: SET_STATUS_KP, data});
export const setAllCompanyWithManager = (data) => ({type: SET_ALL_COMPANY_WITH_MANAGER, data});
export const setAllSharedCompanyWithManager = (data) => ({type: SET_ALL_SHARED_COMPANY_WITH_MANAGER, data});
export const setAllMeetingWithManager = (data) => ({type: SET_ALL_MEETING_WITH_MANAGER, data});
export const setRelatedPaymentForYearMonth = (data) => ({type: SET_MANAGER_SALARY_TABLE, data});
export const setAllCompanyPlanerInThisDate = (data) => ({type: SET_ALL_COMPANY_PLANER_IN_THIS_DATE, data});

export const getAllCompanySegmentation= () =>{
    return (dispatch) => {
        salesAPI.getAllCompanySegmentation()
        .then(data => {
            dispatch(setAllCompanySegmentation(data));
        });
    }
}
export const getAllCompanyPlanerInThisDate= (date, id) =>{
    return (dispatch) => {
        salesAPI.getAllCompanyPlanerInThisDate(date, id)
        .then(data => {
            dispatch(setAllCompanyPlanerInThisDate(data));
        });
    }
}

export const getAllCompanyOutdatedWithManager= (id) =>{
    return (dispatch) => {
        salesAPI.getAllCompanyOutdatedWithManager(id)
        .then(data => {
            dispatch(setAllCompanyOutdatedSegmentation(data));
        });
    }
}

export const getAllCompanyCategories= () =>{
    return (dispatch) => {
        salesAPI.getAllCompanyCategories()
        .then(data => {
            dispatch(setAllCompanyCategories(data));
        });
    }
}
export const getCompanyInSegmentationCategories= (array) =>{
    return (dispatch) => {
        salesAPI.getCompanyInSegmentationCategories(array)
        .then(data => {
            dispatch(setCompanyInSegmentationCategories(data));
        });
    }
}
export const getPayModulsInCompany= (company) =>{
    return (dispatch) => {
        salesAPI.getPayModulsInCompany(company)
        .then(data => {
            dispatch(setPayModulsInCompany(data));
        });
    }
}
export const setCompanyNotActive= (id, array) =>{
    return (dispatch) => {
        salesAPI.setCompanyNotActive(id)
        .then(data => {
            dispatch(getCompanyInSegmentationCategories(array));
        });
    }
}
export const editCompanyStatus= (obj) =>{
    return (dispatch) => {
        salesAPI.editCompanyStatus(obj)
        .then(data => {
            if(data.status === 1 && data.data.personal_id !== null) {
                dispatch(getKp(data.data.personal_id, data.data.dateStart, data.data.dateEnd));
            }else{
                console.log(data)
            }
        });
    }
}
export const getEmailCompanyAgents= () =>{
    return (dispatch) => {
        salesAPI.getEmailCompanyAgents()
        .then(data => {
            dispatch(setEmailCompanyAgents(data));
        });
    }
}

export const getCompanyWithRegion=()=>{
    return (dispatch)=>{
        salesAPI.getCompanyWithRegion()
        .then(data=>{
            dispatch(setCompanyWithRegion(data));
        });
    }
}
export const getAllCompanyRegion=()=>{
    return (dispatch)=>{
        salesAPI.getAllCompanyRegion()
        .then(data=>{
            dispatch(setAllCompanyRegion(data));
        });
    }
}
export const getFiltrWants=(personalId)=>{
    return (dispatch)=>{
        salesAPI.getFiltrWants(personalId)
        .then(data=>{
            dispatch(setFiltrWants(data));
        });
    }
}
export const getCompanyWithFiltrWants=(id, managerId)=>{
    return (dispatch)=>{
        salesAPI.getCompanyWithFiltrWants(id, managerId)
        .then(data=>{
            dispatch(setCompanyWithFiltrWants(data));
        });
    }
}
export const setShowFieldWants=(id, blocksId, personalId)=>{
    return (dispatch)=>{
        salesAPI.setShowFieldWants(id, blocksId, personalId)
        .then(data=>{
            dispatch(getFiltrWants(personalId));
        });
    }
}
export const getKp=(idManager, dateStart="NULL", dateEnd="NULL")=>{
    return (dispatch)=>{
        salesAPI.getKp(idManager, dateStart, dateEnd)
        .then(data=>{
            dispatch(setKp(data));
        });
    }
}
export const getKpPlan=(idManager, dateStart="NULL", dateEnd="NULL")=>{
    return (dispatch)=>{
        salesAPI.getKpPlan(idManager, dateStart, dateEnd)
        .then(data=>{
            dispatch(setKpPlan(data));
        });
    }
}
export const getSumMonthKpPlan=(idManager)=>{
    return (dispatch)=>{
        salesAPI.getSumMonthKpPlan(idManager)
        .then(data=>{
            dispatch(setSumMonthKpPlan(data));
        });
    }
}
export const getSumKpForMonth=(idManager)=>{
    return (dispatch)=>{
        salesAPI.getSumKpForMonth(idManager)
        .then(data=>{
            dispatch(setSumKpForMonth(data));
        });
    }
}
export const getSumKpPaidForMonth=(idManager)=>{
    return (dispatch)=>{
        salesAPI.getSumKpPaidForMonth(idManager)
        .then(data=>{
            dispatch(setSumKpPaidForMonth(data));
        });
    }
}
export const getSumKpForMonthGroupStatus=(idManager)=>{
    return (dispatch)=>{
        salesAPI.getSumKpForMonthGroupStatus(idManager)
        .then(data=>{
            dispatch(setSumKpForMonthGroupStatus(data));
        });
    }
}
export const setPaidInKp=(kpId, idManager, numberNewCheck, type)=>{
    return (dispatch)=>{
        salesAPI.setPaidInKp(kpId, numberNewCheck, type)
        .then(data=>{
            dispatch(getKp(idManager));
        });
    }
}
export const getFiltrBlock=(idManager)=>{
    return (dispatch)=>{
        salesAPI.getFiltrBlock(idManager)
        .then(data=>{
            dispatch(setFiltrBlock(data));
        });
    }
}
export const addNewFiltrBlock=(array, idManager)=>{
    return (dispatch)=>{
        salesAPI.addNewFiltrBlock(array)
        .then(data=>{
            dispatch(getFiltrBlock(idManager));
        });
    }
}
export const deleteFiltrBlock=(blockId, idManager)=>{
    return (dispatch)=>{
        salesAPI.deleteFiltrBlock(blockId)
        .then(data=>{
            dispatch(getFiltrBlock(idManager));
        });
    }
}
export const getCheckTest=(type=1)=>{
    return (dispatch)=>{
        salesAPI.getCheckTest(type)
        .then(data=>{
            dispatch(setCheckTest(data));
        });
    }
}
export const getCheckTestModuls=(type=1)=>{
    return (dispatch)=>{
        salesAPI.getCheckTestModuls(type)
        .then(data=>{
            dispatch(setCheckTestModuls(data));
        });
    }
}
export const getCountOverdueCheckTest=(type)=>{
    return (dispatch)=>{
        salesAPI.getCountOverdueCheckTest(type)
        .then(data=>{
            type==1 ? dispatch(setCountOverdueCheckTest(data)) : dispatch(setCountOverdueCheckTestBack(data));
        });
    }
}
export const changeDateReminder=(idCheckTest, newDate)=>{
    return (dispatch)=>{
        salesAPI.changeDateReminder(idCheckTest, newDate)
        .then(data=>{
            dispatch(getCheckTest());
        });
    }
}
export const buyModulsTest=(idCheckTest, newData)=>{
    return (dispatch)=>{
        salesAPI.buyModulsTest(idCheckTest, newData)
        .then(data=>{
            dispatch(getCheckTest());
        });
    }
}
export const returnModulsTest=(idCheckTest, newData)=>{
    return (dispatch)=>{
        salesAPI.returnModulsTest(idCheckTest, newData)
        .then(data=>{
            dispatch(getCheckTest());
        });
    }
}
export const getReturnModulsTest=(idCheckTest)=>{
    return (dispatch)=>{
        salesAPI.getReturnModulsTest(idCheckTest)
        .then(data=>{
            dispatch(getCheckTest(2));
        });
    }
}
export const changeCommentModulsTest=(idCheckTest, comment)=>{
    return (dispatch)=>{
        salesAPI.changeCommentModulsTest(idCheckTest, comment)
        .then(data=>{
            dispatch(getCheckTest());
        });
    }
}
export const getAllDistribution=(id)=>{
    return (dispatch)=>{
        salesAPI.getAllDistribution(id)
        .then(data=>{
            dispatch(setAllDistribution(data));
        });
    }
}
export const getAllDistributionNomenclatures=(id)=>{
    return (dispatch)=>{
        salesAPI.getAllDistributionNomenclatures(id)
        .then(data=>{
            dispatch(setAllDistributionNomenclatures(data));
        });
    }
}
export const addNewDistribution=(array, id)=>{
    return (dispatch)=>{
        salesAPI.addNewDistribution(array)
        .then(data=>{
            dispatch(getAllDistribution(id));
        });
    }
}
export const addNewNom=(nom, idDistribution, personalId)=>{
    return (dispatch)=>{
        salesAPI.addNewNom(nom, idDistribution, personalId)
        .then(data=>{
            dispatch(getAllDistributionNomenclatures(personalId));
        });
    }
}
export const addNewDistributionStatistics=(nom, idDistribution, personalId, type)=>{
    return (dispatch)=>{
        salesAPI.addNewDistributionStatistics(nom, idDistribution, type)
        .then(data=>{
            dispatch(getAllDistribution(personalId));
        });
    }
}
export const changeDateDistribution=(nom, idDistribution, personalId)=>{
    return (dispatch)=>{
        salesAPI.changeDateDistribution(nom, idDistribution)
        .then(data=>{
            dispatch(getAllDistribution(personalId));
        });
    }
}
export const changeNameDistribution=(nom, idDistribution, personalId)=>{
    return (dispatch)=>{
        salesAPI.changeNameDistribution(nom, idDistribution)
        .then(data=>{
            dispatch(getAllDistribution(personalId));
        });
    }
}
export const deleteDistribution=(idDistribution, personalId)=>{
    return (dispatch)=>{
        salesAPI.deleteDistribution(idDistribution)
        .then(data=>{
            dispatch(getAllDistribution(personalId));
        });
    }
}
export const changeNumberReturnFromTest=(id, newNumber)=>{
    return (dispatch)=>{
        salesAPI.changeNumberReturnFromTest(id, newNumber)
        .then(data=>{
            dispatch(getReturnFromTest());
        });
    }
}
export const getReturnFromTest=()=>{
    return (dispatch)=>{
        salesAPI.getReturnFromTest()
        .then(data=>{
            dispatch(setReturnFromTest(data));
        });
    }
}
export const getStatusKp=()=>{
    return (dispatch)=>{
        salesAPI.getStatusKp()
        .then(data=>{
            dispatch(setStatusKp(data));
        });
    }
}
export const changeDateReminderKp=(id, date, type, managerId, dateStart, dateEnd)=>{
    return (dispatch)=>{
        salesAPI.changeDateReminderKp(id, date, type)
        .then(data=>{
            dispatch(getKp(managerId, dateStart, dateEnd));
            dispatch(getAllMeetingWithManager(managerId));
        });
    }
}
export const statusWorkingKp=(array)=>{
    return (dispatch)=>{
        salesAPI.statusWorkingKp(array)
        .then(data=>{
            if(data.status===1) {
                dispatch(getKp(array?.personalId, array?.dateStart, array?.dateEnd));
                toast.success('Статус КП успешно изменен')
            }else{
                console.error('statusWorkingKp error')
                toast.error('Ошибка при изменении статуса КП. Сообщите админу.')
            }
        });
    }
}
export const setCompanyToPlanner=(companyId, date, managerId)=>{
    //debugger
    return (dispatch)=>{
        salesAPI.setCompanyToPlanner(companyId, date)
        .then(data=>{
            dispatch(getAllCompanyWithManager(companyId.personalId ?? data ));
        });
    }
}
export const getAllCompanyWithManager=(managerId)=>{
    return (dispatch)=>{
        salesAPI.getAllCompanyWithManager(managerId)
        .then(data=>{
            dispatch(setAllCompanyWithManager(data));
        });
    }
}
export const getAllSharedCompanyWithManager=(managerId)=>{
    return (dispatch)=>{
        salesAPI.getAllSharedCompanyWithManager(managerId)
        .then(data=>{
            dispatch(setAllSharedCompanyWithManager(data));
        });
    }
}
export const getRelatedPaymentForYearMonth = (request) =>{
    return (dispatch) => {
        salesAPI.getRelatedPaymentForYearMonth(request)
            .then(data => {
                if(data.status === 1) {
                    dispatch(setRelatedPaymentForYearMonth(data));
                } else {
                    console.error('getRelatedPaymentForYearMonth error')
                    console.error(data.message)
                }
            });
    }
}


export const acceptCompany2ndManager=(request)=>{
    return (dispatch)=>{
        salesAPI.acceptCompany2ndManager(request)
        .then(data=>{
            if(data.status===1) {
                dispatch(getAllSharedCompanyWithManager(request.rawManagerID));//rawManagerID - это id менеджера, со страницы которого был сделан запрос
            }else{
                console.error('acceptCompany2ndManager error')
            }
        });
    }
}
export const rejectCompany2ndManager=(request)=>{
    return (dispatch)=>{
        salesAPI.rejectCompany2ndManager(request)
        .then(data=>{
            if(data.status===1) {
                dispatch(getAllSharedCompanyWithManager(request.rawManagerID));//rawManagerID - это id менеджера, со страницы которого был сделан запрос
            }else{
                console.error('rejectCompany2ndManager error')
            }
        });
    }
}
export const getAllMeetingWithManager=(managerId)=>{
    return (dispatch)=>{
        salesAPI.getAllMeetingWithManager(managerId)
        .then(data=>{
            dispatch(setAllMeetingWithManager(data));
        });
    }
}
export const addNewMeeting=(managerId, array)=>{
    return (dispatch)=>{
        salesAPI.addNewMeeting(array)
        .then(data=>{
            dispatch(getAllMeetingWithManager(managerId));
        });
    }
}
export const changeMeetingText=(id, text, idManager)=>{
    return (dispatch)=>{
        salesAPI.changeMeetingText(id, text)
        .then(data=>{
            dispatch(getAllMeetingWithManager(idManager));
        });
    }
}
export const сloseMeeting=(id, text, idManager, dateStart="NULL", dateEnd="NULL")=>{
    return (dispatch)=>{
        salesAPI.сloseMeeting(id, text)
        .then(data=>{
            dispatch(getAllMeetingWithManager(idManager));
            dispatch(getKp(idManager, dateStart, dateEnd));
        });
    }
}
export const deleteMeeting=(id, idManager, dateStart="NULL", dateEnd="NULL")=>{
    return (dispatch)=>{
        salesAPI.deleteMeeting(id)
        .then(data=>{
            dispatch(getAllMeetingWithManager(idManager));
            dispatch(getKp(idManager, dateStart, dateEnd));
        });
    }
}
export const deleteCompanyFromPlanner=(id, idManager, dateStart="NULL", dateEnd="NULL")=>{
    return (dispatch)=>{
        salesAPI.deleteCompanyFromPlanner(id)
        .then(data=>{
            dispatch(getKp(idManager, dateStart, dateEnd));
        });
    }
}


export default SalesReducer;

