import React, { useEffect, useState } from 'react';
import { dashboardAPI } from "../../../api/api";
import Filters from "./SalesFunnel/Filters";
import BarChartComponent from "./SalesFunnel/BarChartComponent";
import MixedChartComponent from "./SalesFunnel/MixedChartComponent";
import ManagerActivityChart from "./SalesFunnel/ManagerActivityChart";
import { Grid, Typography } from '@mui/material';
import RadarChartComponent from "./SalesFunnel/RadarChartComponent";
import InvoicePaidStackedBarchart from "./SalesFunnel/InvoicePaidStackedBarchart";
import UniqueCompaniesChart from './SalesFunnel/UniqueCompaniesChart';
import MoneyDistributionChart from './SalesFunnel/MoneyDistributionChart';

const SalesFunnel = () => {
    const [data, setData] = useState({
        companyAgentsMessages: [],
        kpMain: [],
        scheta: [],
        salesMeetings: [],
        technicalConsultations: [],
        managers: [],
        categories: [],
        series: [],
        managerNames: {}
    });
    const [filters, setFilters] = useState({
        year: [],
        quarter: [],
        month: [],
        manager: [],
        groupContacts: false
    });

    useEffect(() => {
        fetchData(filters);
    }, [filters]);

    const fetchData = (filters) => {
        console.log('Fetching data with filters:', filters);
        dashboardAPI.getSalesData(filters)
            .then(responseData => {
                console.log('Fetched data:', responseData);
                setData(formatData(responseData, filters.groupContacts));
            })
            .catch(error => {
                console.error('Error fetching funnel data:', error);
            });
    };

    const formatData = (responseData, groupContacts) => {
        if (!responseData) {
            return {};
        }

        const managerNames = {
            44: 'Дунаев 44',
            486: 'Осадчий 486',
            1055: 'Мартыненко 1055',
            1056: 'Кучер 1056',
            1061: 'Сталин 1061'
        };

        const formattedData = {
            companyAgentsMessages: responseData.companyAgentsMessages || [],
            kpMain: responseData.kpMain || [],
            scheta: responseData.scheta || [],
            salesMeetings: responseData.salesMeetings || [],
            technicalConsultations: responseData.technicalConsultations || [],
            managers: [],
            categories: [],
            series: [],
            managerNames,
            uniqueCompanies: {},
            companyMoney: []
        };

        if (groupContacts) {
            formattedData.contacts = [
                ...formattedData.companyAgentsMessages,
                ...formattedData.salesMeetings,
                ...formattedData.technicalConsultations
            ];
        }

        const allManagers = new Set();
        const allCategories = groupContacts
            ? ['contacts', 'kpMain', 'scheta', 'scheta_paid']
            : ['companyAgentsMessages', 'salesMeetings', 'technicalConsultations', 'kpMain', 'scheta', 'scheta_paid'];

        allCategories.forEach(category => {
            if (formattedData[category]) {
                formattedData[category].forEach(record => {
                    if (record && record.manager_id !== undefined && record.manager_id !== null) {
                        allManagers.add(record.manager_id);
                    }
                });
            }
        });

        formattedData.managers = Array.from(allManagers);
        formattedData.categories = allCategories;

        formattedData.series = allCategories.map(category => {
            return {
                name: category,
                data: formattedData?.managers?.map(manager => {
                    const managerData = formattedData?.[category]?.filter(record => record.manager_id == manager);
                    if (category === 'scheta_paid') {
                        return formattedData.scheta
                            .filter(record => record.manager_id == manager)
                            .reduce((sum, record) => sum + parseFloat(record.paid_count || 0), 0);
                    }
                    if (category === 'scheta') {
                        return managerData?.reduce((sum, record) => sum + parseFloat(record.records_count || 0), 0);
                    }
                    return managerData?.reduce((sum, record) => sum + parseFloat(record.records_count || 0), 0);
                })
            };
        });

        // Calculate unique companies and money distribution
        const companyData = {};
        formattedData.scheta.forEach(record => {
            if (!companyData[record.company_id]) {
                companyData[record.company_id] = {
                    manager_id: record.manager_id,
                    total: 0,
                    paid: 0,
                    total_count: 0,
                    paid_count: 0
                };
            }
            companyData[record.company_id].total += parseFloat(record.numeral);
            companyData[record.company_id].paid += parseFloat(record.paid_numeral);
            companyData[record.company_id].total_count += parseInt(record.records_count);
            companyData[record.company_id].paid_count += parseInt(record.paid_count);
        });

        formattedData.uniqueCompanies = {
            unique: Object.keys(companyData).length,
            total: formattedData.scheta.length
        };

        formattedData.companyMoney = Object.keys(companyData).map(company_id => ({
            company_id,
            ...companyData[company_id]
        }));

        return formattedData;
    };

    const onFiltersChange = (newFilters) => {
        setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Filters filters={filters} onChange={onFiltersChange} />
            </Grid>
            {/*<Grid item xs={4}>
                <Typography variant="h6" component="div" gutterBottom>
                    Radar
                </Typography>
                <RadarChartComponent data={data} />
            </Grid>
            */}
            <Grid item xs={12}>
                <Typography variant="h6" component="div" gutterBottom>
                    Общая воронка активности
                </Typography>
                <InvoicePaidStackedBarchart data={data} />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" component="div" gutterBottom>
                    Воронка активности
                </Typography>
                <MixedChartComponent data={data} filters={filters} />
            </Grid>

           {/* <Grid item xs={12}>
                <Typography variant="h6" component="div" gutterBottom>
                    Conversion Rates
                </Typography>
                <BarChartComponent data={data} filters={filters} />
            </Grid>*/}
            <Grid item xs={12}>
                <Typography variant="h6" component="div" gutterBottom>
                    Конверсия счетов в деньгах
                </Typography>
                <MoneyDistributionChart data={data} />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" component="div" gutterBottom>
                    Счета помесячно
                </Typography>
                <BarChartComponent data={data} filters={filters} />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" component="div" gutterBottom>
                    Воронка по уникальным компаниям
                </Typography>
                <UniqueCompaniesChart data={data} />
            </Grid>

        </Grid>
    );
};

export default SalesFunnel;
