import React from 'react';
import Chart from 'react-apexcharts';

const MoneyDistributionChart = ({ data }) => {
    if (!data || !data.scheta || !data.scheta.length || !data.managerNames) {
        console.error('Invalid data:', data);
        return null;
    }

    // Log the raw data to debug
    console.log('Raw data:', data);

    // Aggregate the data for each manager
    const aggregatedData = Object.keys(data.managerNames).map((managerId) => {
        const managerScheta = data.scheta.filter(item => item.manager_id === parseInt(managerId));

        // Ensure that the amount field exists and is a number
        const totalScheta = managerScheta.reduce((sum, item) => {
            const amount = parseFloat(item.numeral) || 0;
            return sum + amount;
        }, 0);

        const totalSchetaPaid = managerScheta.reduce((sum, item) => {
            const amount = parseFloat(item.paid_numeral) || 0;
            return item.paid_count ? sum + amount : sum;
        }, 0);

        const totalSchetaUnpaid = totalScheta - totalSchetaPaid;
        const conversionRate = totalScheta > 0 ? (totalSchetaPaid / totalScheta) * 100 : 0;

        console.log(
            `Manager ${data.managerNames[managerId]}: 
            Total Scheta = ${totalScheta}, 
            Total Scheta Paid = ${totalSchetaPaid}, 
            Conversion Rate = ${conversionRate.toFixed(2)}%
            `);

        return {
            manager: data.managerNames[managerId],
            totalScheta,
            totalSchetaPaid,
            totalSchetaUnpaid,
            conversionRate
        };
    });

    console.log('Aggregated data:', aggregatedData);

    const categories = aggregatedData.map(item => item.manager);
    const series = [
        {
            name: 'Total Scheta Paid',
            data: aggregatedData.map(item => item.totalSchetaPaid)
        },
        {
            name: 'Total Scheta Unpaid',
            data: aggregatedData.map(item => item.totalSchetaUnpaid)
        }
    ];

    console.log('Categories:', categories);
    console.log('Series:', series);

    const chartOptions = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: true
            }
        },
        plotOptions: {
            bar: {
                horizontal: true,
                dataLabels: {
                    position: 'top'
                }
            }
        },
        dataLabels: {
            enabled: true,
            formatter: function (val, opt) {
                const dataPoint = aggregatedData[opt.dataPointIndex];
                if (opt.seriesIndex === 1) {  // If it's the Total Scheta Unpaid series
                    return `${val.toFixed(2)} (${dataPoint.conversionRate.toFixed(2)}%)`;
                }
                return val.toFixed(2);
            },
            offsetX: 0,
            style: {
                fontSize: '12px',
                colors: ['#000']
            }
        },
        xaxis: {
            categories,
            title: {
                text: 'Amount'
            }
        },
        yaxis: {
            title: {
                text: 'Managers'
            }
        },
        tooltip: {
            y: {
                formatter: function (val, { seriesIndex, dataPointIndex }) {
                    const dataPoint = aggregatedData[dataPointIndex];
                    if (seriesIndex === 1) {  // If it's the Total Scheta Unpaid series
                        return `${val.toFixed(2)} (${dataPoint.conversionRate.toFixed(2)}%)`;
                    }
                    return val.toFixed(2);
                }
            }
        }
    };

    return (
        <div>
            <Chart options={chartOptions} series={series} type="bar" height={350} />
        </div>
    );
};

export default MoneyDistributionChart;
