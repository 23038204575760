import React, { useState } from 'react';
import s from './CheckTest.module.css';

const CommentModuls = (props) =>{
    const change = ()=>{
        props.changeCommentModulsTest(props.commentModulsTest, newDate)
        props.setCommentModulsTest(false)
        console.log(newDate)
    }
    let [newDate, setNewDate] = useState(props.comment)
    const setNewDateF = (e)=>{
        setNewDate(e.target.value)
        console.log(e.target.value)
    }
    return(
        <div className={s.changeDate}>
           <div><h3>Изменение комментария по заявке №{props.commentModulsTest}</h3></div>
           <div><textarea  className={s.textarea} onChange={setNewDateF} >{newDate}</textarea></div>
           <div className={s.center}><button onClick={()=>change()}>Сохранить</button></div>
        </div>
    )
}
export default CommentModuls;