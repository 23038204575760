import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const NewSchedule = (props) => {
    let s = [];
    let averageSum = props.sum.map(k => k.sum);
    let averageCost = props.sumCosts.map(k => k.sum);

    const getAverage = (nums) => {
        if (nums.length < 1) return 0;
        return (nums.reduce((a, b) => a + b) / nums.length).toFixed();
    };

    props.sum.forEach(k => {
        const ruItem = props.sumRu.find(p => p.date === k.date);
        const costItem = props.sumCosts.find(j => j.date === k.date);
        if (ruItem && costItem) {
            s.push({
                sum: ruItem.sum + k.sum,
                date: k.date,
                cost: costItem.sum,
                averageSum: getAverage(averageSum),
                averageCost: getAverage(averageCost),
            });
        }
    });

    const data = s.map(k => ({
        name: k.date,
        "Приход": k.sum,
        "Расход": -k.cost,
        "Среднее": k.sum - k.cost,
        "Среднее Приход": k.averageSum,
        "Среднее Расход": -k.averageCost,
    }));

    class CustomizedLabel extends PureComponent {
        render() {
            const { x, y, stroke, value } = this.props;
            return (
                <text x={x} y={y} dy={-2} fill={stroke} fontSize={15} fontWeight="bold" textAnchor="middle">
                    {Number(value).toLocaleString()}
                </text>
            );
        }
    }

    class CustomizedAxisTick extends PureComponent {
        render() {
            const { x, y, payload } = this.props;
            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
                        {payload.value}
                    </text>
                </g>
            );
        }
    }

    return (
        <div>
            <h2>Таблица приходов/расходов</h2>
            <ResponsiveContainer width="95%" height={1050}>
                <LineChart
                    data={data}
                    margin={{ top: 40, right: 60, left: 40, bottom: 20 }}
                >
                    <CartesianGrid strokeDasharray="5 5" />
                    <XAxis dataKey="name" height={60} tick={<CustomizedAxisTick />} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="Приход" stroke="#ffc658" label={<CustomizedLabel />} />
                    <Line type="monotone" dataKey="Среднее" stroke="#fe5000" strokeDasharray="5 5" label={<CustomizedLabel />} />
                    <Line type="monotone" dataKey="Расход" stroke="#8884d8" label={<CustomizedLabel />} />
                    <Line type="monotone" dataKey="Среднее Приход" stroke="#000" strokeDasharray="5 5" />
                    <Line type="monotone" dataKey="Среднее Расход" stroke="#000" strokeDasharray="5 5" />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default NewSchedule;
