import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import AnyFormatDate from '../../common/AnyFormatDate/AnyFormatDate';
import Modal from '../../common/Modal/Modal';
import s from './Shipment.module.css';
import main from '../../../Main.module.css';
import MonthBlock from '../../common/MonthBlock/MonthBlock';
import { useCookies } from 'react-cookie';

const Shipment = (props) => {
    // вычисляем норму дней на работу производства
    let normaPlan = props.invoiceForMonthWithDateManager.reduce(function (p, c) { return Number(p) + Number(c.intervalDaysPlan); }, '');
    let fact = props.invoiceForMonthWithDateManager.reduce(function (p, c) { return Number(p) + Number(c.intervalDaysFact); }, '');
    let timelyPlan = (1 + (normaPlan - fact) / normaPlan) * 100;

    let [filter, setFilter] = useState(false);
    let [dayStart, setDayStart] = useState(0);
    const setDayStartF = (e) => setDayStart(e.target.value);
    let [dayEnd, setDayEnd] = useState(0);
    const setDayEndF = (e) => setDayEnd(e.target.value);

    const sentInvoiceForMonthWithDateManager = () => {
        props.getSentInvoiceForMonthWithDateManager(props.month, props.year, dayStart, dayEnd);
        setFilter(false);
    }

    const setNotFilter = () => {
        props.getSentInvoiceForMonthWithDateManager(props.month, props.year);
        setDayStart(0); setDayEnd(0);
    }

    let [changeDate, setChangeDate] = useState(false);
    let [newDate, setNewDate] = useState(false);
    const setNewDateF = (e) => setNewDate(e.target.value);
    let [changeDateType, setChangeDateType] = useState(false);

    const setChangeDateF = (id, type) => {
        setChangeDate(id);
        setChangeDateType(type);
    }

    const setNewDateManagerInScheta = () => {
        props.setNewDateManagerInScheta(changeDate, newDate, changeDateType, props.month, props.year);
        setChangeDate(false); setNewDate(false); setChangeDateType(false);
    }

    const [cookies] = useCookies();

    console.log("Shipment props", props)
    return (
        <div>
            {filter && (
                <Modal active={filter} setActive={setFilter}>
                    <div className={s.center}><h2>Болел</h2></div>
                    <div className={s.inputMain}><div className={s.p10}>с</div><div><input type="number" value={dayStart} onChange={setDayStartF} /></div></div>
                    <div className={s.inputMain}><div className={s.p10}>по</div><div><input type="number" value={dayEnd} onChange={setDayEndF} /></div></div>
                    <div className={s.button}><button onClick={sentInvoiceForMonthWithDateManager}>Убрать эти дни</button></div>
                </Modal>
            )}

            {changeDate && (
                <Modal active={changeDate} setActive={setChangeDate}>
                    <div className={s.addComment}>
                        <div><h3>Изменить плановую дату</h3></div>
                        <div><input type="date" onChange={setNewDateF} value={newDate} className={main.input} /></div>
                        <div className={s.center}><button onClick={setNewDateManagerInScheta}>Изменить</button></div>
                    </div>
                </Modal>
            )}

            <div onClick={setNotFilter}><MonthBlock path={"chief/statistics/" + props.year} month={props.month} /></div>
            <div className={s.filter}>
                <div>{dayStart !== 0 && dayEnd !== 0 && <button onClick={setNotFilter}>Болел с {dayStart} по {dayEnd}</button>}</div>
                <div className={s.svg}><FontAwesomeIcon icon={faFilter} onClick={() => setFilter(!filter)} /></div>
            </div>

            <div className={s.div3}>
                <div>Норма план: {normaPlan + " дней"}</div>
                <div>Норма факт: {fact + " дней"}</div>
                <div>% своевременного выполнения плана: {Math.round(timelyPlan) + " %"}</div>
            </div>

            <div className={s.main + " " + s.bold}>
                <div>#</div>
                <div>Номер счёта</div>
                <div>Дата оплаты заказа</div>
                <div>Дата Плановая</div>
                <div>Дата Факт</div>
                <div>Срок план</div>
                <div>Срок факт</div>
            </div>

            {props.invoiceForMonthWithDateManager.map((k, index) => (
                <div className={s.main} key={"invoiceForMonthWithDateManager" + index}>
                    <div>{index + 1}</div>
                    <div>{k.number}</div>
                    <div><AnyFormatDate date={k.p_date_main} /></div>
                    <div>
                        <AnyFormatDate date={k.date_manager} />
                        {(cookies.id == 1 || cookies.id == 44 || cookies.id == 34) && <FontAwesomeIcon icon={faPencilAlt} onClick={() => setChangeDateF(k.id, k.type)} />}
                    </div>
                    <div className={(k.intervalDaysPlan - k.intervalDaysFact) < 0 ? s.red : ''}><AnyFormatDate date={k.date_op} /></div>
                    <div>{k.intervalDaysPlan}</div>
                    <div>{k.intervalDaysFact}</div>
                </div>
            ))}
        </div>
    );
}

export default Shipment;
