import React from 'react';
import { useState } from 'react';
import s from './MainSupplier.module.css';

const MainSupplier = (props) =>{
    let [newSupplier, setNewSupplier] = useState("");
    const setNewSupplierF = (e)=>{
        setNewSupplier(e.target.value)
    }
    const setNewMainSupplierF=()=>{
        props.setNewMainSupplier(newSupplier)
        setNewSupplier("");
    }
    const changeMainSupplierF = (id, name, type)=>{
        let array = [];
        array.push(type);
        array.push(id);
        array.push(name);
        props.changeMainSupplier(array)
        console.log(array)
    }
    
    return(
        <div>
           <div className={s.addNewSupplier}>
               <div><span>Добавить новую компанию</span></div>
               <div><input type="text" value={newSupplier} onChange={setNewSupplierF} /></div>
               <div><button onClick={()=>setNewMainSupplierF()}>Добавить</button></div>
           </div>
           <h2>Список всех компаний</h2>
           <div>
               {
                   props.mainSupplier.map((k,index)=>{
                       return(
                            <div className={s.addNewSupplier+" "+s.center} key={"mainSupplier_"+index}>
                                <div>{"#"+k.id}</div>
                                <div>{k.supplier}</div>
                                <div><span className={s.button} onClick={()=>changeMainSupplierF(k.id,k.supplier, 1)}>Удалить</span></div>
                                {/* заготовка под возможность реадктировать название компании */}
                                {/* <div><span className={s.button} onClick={()=>changeMainSupplierF(k.id,k.supplier, 1)}>Удалить</span><span className={s.button}>Изменить</span></div> */}
                            </div>
                       ) 
                   })
               }
           </div>
        </div>
        
    )
}
export default MainSupplier;