import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { EyeIcon } from "primereact/icons/eye";
import s from "../../common/MonthBlock/MonthBlock.module.css";
import DialogContent from "@mui/material/DialogContent";
import { workerAPI as workerApi } from "../../../../api/api";
import CircularProgress from "@mui/material/CircularProgress";
import { DataGrid } from '@mui/x-data-grid';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ListAlt from '@mui/icons-material/ListAlt';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AssemblerStory({ assemblersList }) {
    const [open, setOpen] = React.useState(false);
    const [month, setMonth] = React.useState(new Date().getMonth() + 1);
    const [year, setYear] = React.useState(new Date().getFullYear());
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [selectedAssemblers, setSelectedAssemblers] = React.useState([]);

    const monthArray = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];

    const getYearArray = () => {
        const currentYear = new Date().getFullYear();
        const yearArray = [];
        for (let i = 2022; i <= currentYear; i++) {
            yearArray.push(i);
        }
        return yearArray;
    };

    const assemblerIdToName = (id) => {
        const assembler = assemblersList.find(assembler => assembler.id == id);
        return assembler ? assembler.name : id;
    };

    const statusIdToName = (id) => {
        switch (id) {
            case 0:
                return 'распределение';
            case 1:
                return 'монтаж';
            case 2:
                return 'первичка';
            case 3:
                return 'корпусировка';
            case 4:
                return 'вых.контроль';
            case 5:
                return 'склад';
            default:
                return id;
        }
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const result = await workerApi.getAssembliesForPeriod(selectedAssemblers, year, month);
            const dataWithId = result.data.map((item, index) => ({
                ...item,
                id: index,
                who_make: assemblerIdToName(item.who_make),
                status: statusIdToName(item.status),
            }));
            setData(dataWithId);
        } catch (error) {
            console.error("Error fetching data", error);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        if (open) {
            fetchData();
        }
    }, [year, month, selectedAssemblers, open]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const toggleAssembler = (id) => {
        setSelectedAssemblers(prev => {
            if (prev.includes(id)) {
                return prev.filter(aid => aid !== id);
            } else {
                return [...prev, id];
            }
        });
    };

    const exportToExcel = () => {
        const filteredData = data.map(({ moduls_name, moduls_coll, who_make }) => ({
            Модуль: moduls_name,
            Количество: moduls_coll,
            Монтажник: who_make,
        }));
        const ws = XLSX.utils.json_to_sheet(filteredData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Assemblies');
        XLSX.writeFile(wb, 'Assemblies.xlsx');
    };

    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.text('Отчёт по монтажу', 20, 10);
        const filteredData = data.map(({ moduls_name, moduls_coll, who_make }) => ({
            Модуль: moduls_name,
            Количество: moduls_coll,
            Монтажник: who_make,
        }));
        doc.autoTable({
            head: [['Модуль', 'Количество', 'Монтажник']],
            body: filteredData.map(row => [row['Модуль'], row['Количество'], row['Монтажник']]),
        });
        doc.save('Assemblies.pdf');
    };

    const columns = [
        { field: 'moduls_name', headerName: 'Модуль', width: 300 },
        { field: 'moduls_coll', headerName: 'Количество', width: 100 },
        { field: 'who_make', headerName: 'Монтажник', width: 200 },
    ];

    return (
        <React.Fragment>
            <Button variant="outlined" onClick={handleClickOpen}>
                <EyeIcon />
            </Button>
            <Dialog
                maxWidth='md'
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Сводка активности монтажа
                        </Typography>
                        <Button variant="contained" color="secondary" onClick={exportToExcel} style={{ marginRight: '10px' }} title="Excel">
                            <ListAlt />
                        </Button>
                        {/*<Button variant="contained" color="secondary" onClick={exportToPDF} title="PDF">
                            <PictureAsPdf />
                        </Button>*/}
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <div className={s.main} style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom: '15px' }}>
                        {getYearArray().map((k) => (
                            <div
                                key={"year_" + k}
                                onClick={() => setYear(k)}
                                className={year === k ? s.active : ""}
                                style={{ margin: '5px' }}
                            >
                                {k}
                            </div>
                        ))}
                    </div>
                    <div className={s.main} style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom: '15px' }}>
                        {monthArray.map((k, index) => {
                            const monthIndex = index + 1;
                            return (
                                <div
                                    key={"month_" + monthIndex}
                                    onClick={() => setMonth(monthIndex)}
                                    className={month === monthIndex ? s.active : ""}
                                    style={{ margin: '5px' }}
                                >
                                    {k}
                                </div>
                            );
                        })}
                    </div>
                    <div className={s.main} style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom: '15px' }}>
                        {assemblersList.map((assembler) => (
                            <div
                                key={assembler.id}
                                onClick={() => toggleAssembler(assembler.id)}
                                className={selectedAssemblers.includes(assembler.id) ? s.active : ""}
                                style={{ margin: '5px' }}
                            >
                                {assembler.name}
                            </div>
                        ))}
                    </div>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <>
                            <div style={{ height: 600, width: '100%' }}>
                                <DataGrid rows={data} columns={columns} pageSize={10} />
                            </div>
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
