import React, {useEffect, useState} from 'react';
import {DataGrid} from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import {CheckCircle, Cancel} from '@mui/icons-material';
import UseCaseDialog from './UseCaseDialog';
import {Rating} from '@mui/material';
import {adminAPI} from "../../../api/api";
import AnyFormatDate from "../../Production/common/AnyFormatDate/AnyFormatDate";
import {Link} from "react-router-dom";

const UseCaseTable = () => {
    const [useCases, setUseCases] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedUseCase, setSelectedUseCase] = useState(null);

    useEffect(() => {
        fetchUseCases();
    }, []);

    const fetchUseCases = async () => {
        await adminAPI.getAllUseCases()
            .then(response => {
//.sort((a, b) => b.id - a.id)
                const useCasesArray = Object.entries(response?.data).map(([id, info]) => ({
                    id,
                    ...info,
                }));
                setUseCases(useCasesArray.sort((a, b) => b.id - a.id));
            })
            .catch(error => {
                console.error("Error fetching use cases:", error);
            });
    };

    const handleEdit = (useCase) => {
        setSelectedUseCase(useCase);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setSelectedUseCase(null);
        fetchUseCases(); // Оновлення після редагування
    };

    const renderStatusIcon = (value) => {
        return value ? <CheckCircle style={{color: 'green'}}/> : <Cancel style={{color: 'red'}}/>;
    };

    const updateUseCaseFiles = (useCaseId, updatedFiles) => {
        setUseCases((prevUseCases) =>
            prevUseCases.map((useCase) =>
                useCase.id === useCaseId ? {...useCase, files: updatedFiles} : useCase
            )
        );
    };

    const columns = [
        {
            field: 'type',
            headerName: 'Тип',
            flex: 1,
            renderCell: (params) => (
                <Link to={`/check/` +(params.row.invoice_id ? params.row.invoice_id : params.row.kp_id) + '/' + (params.row.invoice_id ? 0 : 4)}>
                    {params.value}
                </Link>
            )
            ,
        },
        {field: 'manager_name', headerName: 'Менеджер', flex: 1},
        {field: 'original_text', headerName: 'Описание', flex: 3},
        {
            field: 'ukr_text',
            headerName: 'Текст УКР',
            flex: 1,
            renderCell: (params) => renderStatusIcon(params.value)
        },
        {
            field: 'rus_text',
            headerName: 'Текст РУС',
            flex: 1,
            renderCell: (params) => renderStatusIcon(params.value)
        },
        {
            field: 'eng_text',
            headerName: 'Текст АНГЛ',
            flex: 1,
            renderCell: (params) => renderStatusIcon(params.value)
        },
        {
            field: 'files',
            headerName: 'Файли',
            flex: 1,
            renderCell: (params) => renderStatusIcon(Object.keys(params.value).length > 0)
        },
        {
            field: 'show_in_search',
            headerName: 'Поиск',
            flex: 1,
            renderCell: (params) => renderStatusIcon(params.value)
        },
        {
            field: 'is_active',
            headerName: 'Активно',
            flex: 1,
            renderCell: (params) => renderStatusIcon(params.value)
        },
        {
            field: 'quality_score',
            headerName: 'Оцінка',
            flex: 2,
            renderCell: (params) => <Rating value={params.value} readOnly precision={0.5}/>
        },
        {
            field: 'created_at',
            headerName: 'Дата',
            flex: 2,
            renderCell: (params) => <AnyFormatDate date={params.value}></AnyFormatDate>
        },
        {
            field: 'actions',
            headerName: 'Редактор',
            flex: 1,
            renderCell: (params) => (
                <IconButton onClick={() => handleEdit(params.row)}>
                    <SettingsIcon/>
                </IconButton>
            )
        }
    ];

    return (
        <>
            <DataGrid
                rows={useCases}
                columns={columns}
                pageSize={25}
                rowsPerPageOptions={[25]}
                autoHeight
                density={'compact'}
                disableSelectionOnClick
                getRowId={(row) => row.id}  // Задайте правильний ключ для унікального ідентифікатора рядків
            />
            {selectedUseCase && (
                <UseCaseDialog
                    open={openDialog}
                    handleClose={handleDialogClose}
                    useCase={selectedUseCase}
                    updateUseCaseFiles={updateUseCaseFiles} // Передаємо функцію для оновлення файлів
                />
            )}
        </>
    );
};

export default UseCaseTable;
