import React from "react";
import { useState } from "react";
import main from '../../../Main.module.css'
import s from './MessagesCheck.module.css'
import { faPlus, faUser, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from "../../common/Modal/Modal";
import AddMessage from './Modal/AddMessage';
import AddAgents from "./Modal/AddAgents";
import AnyFormatDate from "../../common/AnyFormatDate/AnyFormatDate";
import ChangeAgents from "./Modal/ChangeAgents";

const MessagesCheck = (props)=>{
    let [addMessage, setAddMessage] = useState(false)
    let [addAgent, setAddAgent] = useState(false)
    let [changeAgent, setChangeAgent] = useState(false)
    console.log(props.companyAgents)
    return(
        <div>
            {addMessage &&
                <Modal active={addMessage} setActive={setAddMessage}>
                    <AddMessage active={addMessage} setActive={setAddMessage} companyAgents={props.companyAgents} companyId={props.companyId} checkId={props.checkId} setNewCompanyAgent={props.setNewCompanyAgent} setAddAgent={setAddAgent} setNewKpMessage={props.setNewKpMessage}/>
                </Modal>
            }
            {addAgent &&
                <Modal active={addAgent} setActive={setAddAgent}>
                    <AddAgents active={addAgent} setActive={setAddAgent} companyId={props.companyId} setNewCompanyAgent={props.setNewCompanyAgent} setAddMessage={setAddMessage}/>
                </Modal>
            }
            {changeAgent &&
                <Modal active={changeAgent} setActive={setChangeAgent}>
                    <ChangeAgents active={changeAgent} setActive={setChangeAgent} companyId={props.companyId} changeCompanyAgent={props.changeCompanyAgent} companyAgents={props.companyAgents}/>
                </Modal>
            }
            <h2>История общения <FontAwesomeIcon icon={faPlus} onClick={()=>setAddMessage(true)} className={s.spanPlus}/></h2>
            {props.messagesPersonalKp.map((k,index)=>{
                return( 
                <div key={"messagesPersonalKp"+index} className={s.messages}>
                    <div className={main.twoBlocksGridOneOfTwo}>
                        <div><FontAwesomeIcon icon={faUser} /><span onClick={()=>setChangeAgent(k.agents_id)} className={s.nameAgent}>{k.name}</span></div>
                        <div><FontAwesomeIcon icon={faUserTie} />{k.nameAkon}</div>
                    </div>
                    <div className={s.date}><AnyFormatDate date={k.date}/></div>
                    <div>{k.message}</div>
                </div>
                )
            })

            }
        </div>
    )
} 
export default MessagesCheck;