import React from 'react';
import s from "./Programmer.module.css";
import AnyFormatDate from '../../common/AnyFormatDate/AnyFormatDate';
import CheckNameColl from '../../common/MainTable/CheckNameColl';
import GetStatusProduction from '../../common/GetStatusProduction/GetStatusProduction';

const Programmer = (props) =>{
    console.log(props.newModuleFromProduction)
    return(
        <div className={s.main}>
            <h1>Список номенклатур, которые мы выпускаем первый раз</h1>
            <div className={s.mainTable}>
                <div>№</div>
                <div>Название</div>
                <div>Количество</div>
                <div>Дата отгрузки</div>
                <div>Статус</div>
            </div>
            {
                0<props.newModuleFromProduction.length 
                ? props.newModuleFromProduction.map( module => {
                    return(
                        <div className={s.table} key={"newModuleFromProduction_"+module.id}>
                            <CheckNameColl number={module.number} name={module.moduls_name} coll={module.moduls_coll} moduls_type_in_production={module.moduls_type_in_production} newDevice={module.newDevice}/>
                            <div><AnyFormatDate date={module.date_manager} /></div>
                            <div>
                                <GetStatusProduction status={module.status} />
                            </div>
                        </div>
                    )
                })
                : "Нема ничё"
            }
        </div>
    )
}
export default Programmer;