import React, {useState, useEffect, useRef, useReducer} from 'react';
import s from './CompanyProfile.module.css';
import Cookies from 'js-cookie'
import Modal from "../../../Production/common/Modal/Modal";
import {faBan, faCalendar, faPlus, faShare, faStop} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faEye} from "@fortawesome/free-regular-svg-icons";
import {faInfo} from "@fortawesome/free-solid-svg-icons";
import main from "../../../Main.module.css";

import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

import {InputTextarea} from 'primereact/inputtextarea';

import Card from '@mui/joy/Card';
import ButtonGroup from '@mui/material/ButtonGroup';
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupsIcon from '@mui/icons-material/Groups';
import MessageIcon from '@mui/icons-material/Message';
import AddCommentIcon from '@mui/icons-material/AddComment';
import {styled, ThemeProvider} from '@mui/material/styles';
import Tooltip, {TooltipProps, tooltipClasses} from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import {DataGrid} from '@mui/x-data-grid';

import Select from 'react-select'
import Switch from "../../../global/Switch";
import {addCompanyPartner, unbindNewCategory, unbindNewIndustry} from "../../../../redux/company-reducer";
import LongText from "../../../Production/common/LongText/LongText";
import CurrencyFormatter from "../../common/CurrencyFormatter/CurrencyFormatter";
import PropTypes from "prop-types";
import {Chip, FormControl, FormControlLabel, FormGroup, Grid, TableFooter} from "@mui/material";
import CompanyAgent from "../CompanyAgent/CompanyAgent";
import AnyFormatDate from "../../../Production/common/AnyFormatDate/AnyFormatDate";
import dayjs from "dayjs";
import moment from "moment";
import CopyToClipboardButton from "../../common/CopyToClipboardButton";
import Button from "@mui/joy/Button";
import {PlusIcon} from "primereact/icons/plus";
import LoadingPlaceholder from "../../common/LoadingComponent/LoadingPlaceholder";
import SetCompanyToPlanner from "../../AllCompanyWithManager/Modal/SetCompanyToPlanner";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import {toast} from "react-toastify";
import allowedAudioTypes from "html2pdf.js";
import {NavLink} from 'react-router-dom';
import StatsComponent from "./tabs/StatsComponent";
import Input from "@mui/material/Input";
import Checkbox from "@mui/material/Checkbox";

function TablePaginationActions(props) {


    const {count, page, rowsPerPage, onPageChange} = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{flexShrink: 0, ml: 2.5}}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                <FirstPageIcon/>
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                <KeyboardArrowLeft/>
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight/>
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                <LastPageIcon/>
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

function setPageTitle(data) {
    if (data && data.length > 0) {
        console.log(data ?? this.props)
        document.title = `АКОН - профиль компании ${data.name_with_akon || data.name}`;
    }
}

const CompanyProfile = (props) => {
    function toTitleCase(str) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    const [checkedOwnThoughts, setCheckedOwnThoughts] = useState(false);
    const handleChangeOwnThoughts = (event) => {
        setCheckedOwnThoughts(event.target.checked);
    }

    const ref = useRef();
    const [messages, setMessages] = useState([]);
    const [activeTab, setActiveTab] = useState(3); // Tab managements

    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
    };

    function normalizeManagerID(id_unparsed) {
        let id = parseInt(id_unparsed)
        switch (id) {
            case 1:
                return 44
            case 2:
                return 486
            default:
                return id;
        }
    }

    let company = props.data ?? null

    const agentNameMap = {};
    // eslint-disable-next-line no-unused-expressions
    company?.data?.agents?.forEach(agent => {
        agentNameMap[agent.id] = agent.name;
    });

    const HtmlTooltip = styled(({className, ...props}: TooltipProps) => (
        <Tooltip {...props} classes={{popper: className}}/>
    ))(({theme}) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));


    let messagesList = [];

    if (company?.data?.messages) {
        company.data.messages.map((item, index) => (
            messagesList.push({
                id: item?.id,
                number: index + 1,
                message: item?.message,
                from: company?.additional_data?.user_names[item?.manager_id],
                agent: agentNameMap[item?.agents_id],
                agent_id: item?.agents_id,
                theme: item?.theme,
                own_thoughts: item?.own_thoughts,
                date: item?.date,
                fileUrl: item.file_url !== null
                    ? JSON.parse(item.file_url)
                    : null,
                type: 'message'
            })));
    }
    if (company?.additional_data?.sales_meetings) {
        let startIndex = messagesList.length;
        company?.additional_data?.sales_meetings?.map((item, index) => (
            messagesList.push({
                id: item?.id,
                number: startIndex + index + 1,
                message: item?.message,
                from: company?.additional_data?.user_names[item?.manager_id],
                agent: agentNameMap[item?.agents_id],
                agent_id: item?.agents_id,
                date: item?.date,
                type: 'sales_meeting'
            })));
    }

    messagesList = messagesList.map(obj => {
        return {...obj, date: new Date(obj.date)}
    });
    //сортуємо по даті
    messagesList = messagesList.sort((a, b) => Number(b.date) - Number(a.date));
    //переводимо назад в строку
    messagesList = messagesList.map(obj => {
        return {...obj, date: obj.date.toLocaleDateString()}
    })


    // ТАБЛИЦЯ ЗАМОВЛЕНЬ
    function getStatus(status) {
        switch (status) {
            case 1:
                return "Разведка";
            case 2:
                return "Тендер";
            case 3:
                return "Ожидается счёт";
            case 4:
                return "Проект.перспектива";
            case 5:
                return "Обновление стоимости";
            case 6:
                return "Весь список, пожалуйста";
            default:
                return "";
        }
    }

    function createData(id, name, status, color, summ, date, year, link) {
        return {id, name, status, color, summ, date, year, link};
    }

    let turnDateYYYYMMDD = (date) => {
        if (date === undefined || date === null || date === '') {
            date = "2010-01-01"
        }
        date = date.replaceAll('.', '-');
        if (date.indexOf('-') == 4) {
            return date;
        }

        let datebcp = date;
        date = dayjs(
            date,
            [
                'DD-MM-YYYY',
                'D-MM-YYYY',
                'D-M-YYYY',
                'YYYY-MM-DD',
                'YYYY-M-D',
                'YYYY-MM-D',

            ]).format('YYYY-MM-DD')
        if (date === 'Invalid Date') {
            let splitDate = datebcp.split('-')
            if (splitDate.length === 3) {
                if (splitDate[0].length === 4) {
                    return splitDate[0] + '-' + splitDate[1] + '-' + splitDate[2]
                } else {
                    return splitDate[2] + '-' + splitDate[1] + '-' + splitDate[0]
                }
            }
            return datebcp;
        }
        return date
    }

    //orders
    function createLeadsData(id, name, date, section, link) {
        return {id, name, date, section, link};
    }

    let leadRows = company?.data?.new_leads?.map((lead, i) => {
        return createLeadsData(
            lead?.id,
            lead?.modulsName,
            lead?.date,
            lead?.section,
            lead?.link
        )
    }) ?? [];

    //orders
    let orderRows = company?.data?.orders.map((order, i) => {
        return createData(
            "СЧ " + order?.number,
            order?.name,
            parseInt(order?.paid) > 0 ? "Оплачен" : "Не оплачен",
            parseInt(order?.paid) > 0 ? "green" : "red",
            parseFloat(order?.numeral) ?? 0,
            turnDateYYYYMMDD(order?.date),
            order?.year,
            order?.year > 2020
                ? 'https://master.akon.com.ua/check/' + order?.id + '/0'
                : 'https://www.akon.com.ua/admin/check_info.php?schet=' + order?.number + '&year=' + order?.year + '&type=0'
        )
    }) ?? [];
    //add same logic for company?.additional_data.?final_company_scheta - add and sort by date to orderRows
    let finalCompanySchetaRows = company?.additional_data?.final_company_scheta?.map((schet, i) => {
        return createData(
            "СЧ " + schet?.number + "-К",
            schet?.name,
            parseInt(schet?.paid) > 0 ? "Оплачен" : "Не оплачен",
            parseInt(schet?.paid) > 0 ? "green" : "red",
            parseFloat(schet?.numeral) ?? 0,
            turnDateYYYYMMDD(schet?.date),
            schet?.year,
            schet?.year > 2020
                ? 'https://master.akon.com.ua/check/' + schet?.id + '/0'
                : 'https://www.akon.com.ua/admin/check_info.php?schet=' + schet?.number + '&year=' + schet?.year + '&type=0'
        )
    });
    if (finalCompanySchetaRows && finalCompanySchetaRows?.length > 0) {
        orderRows = orderRows.concat(finalCompanySchetaRows);
    }

    let kpRows = company?.data?.kp.map((kp, i) =>
        //let orderRows = company?.additional_data?.kp_and_orders.map((order, i) =>
    {
        let paidText = "Не оплачено"
        let paidColor = "red"
        let link = ''

        if (parseInt(kp?.paid) > 0) {
            paidText = "Оплачено"
            paidColor = "green"
        }

        if (kp?.year > 2020) {
            //link = '/check/' + kp?.year + kp?.id + '/0';
            link = 'https://master.akon.com.ua/check/' + kp?.id + '/4';
        } else {
            link = 'https://www.akon.com.ua/admin/check_info.php?schet=' + kp?.number + '&year=' + kp?.year + '&type=4';
        }
        return createData(
            "КП " + kp?.number,
            kp?.name,
            paidText,
            paidColor,
            parseFloat(kp?.numeral ?? 0),
            turnDateYYYYMMDD(kp?.date),
            kp?.year,
            link
        )
    }) ?? [];

    let finalCompanyKPRows = company?.additional_data?.final_company_kp?.map((kp, i) => {
        let paidText = "Не оплачено"
        let paidColor = "red"
        let link = ''

        if (parseInt(kp?.paid) > 0) {
            paidText = "Оплачено"
            paidColor = "green"
        }

        if (kp?.year > 2020) {
            link = 'https://master.akon.com.ua/check/' + kp?.id + '/4';
        } else {
            link = 'https://www.akon.com.ua/admin/check_info.php?schet=' + kp?.number + '&year=' + kp?.year + '&type=4';
        }
        return createData(
            "КП " + kp?.number + "-К",
            kp?.name,
            paidText,
            paidColor,
            parseFloat(kp?.numeral ?? 0),
            turnDateYYYYMMDD(kp?.date),
            kp?.year,
            link
        )
    })
    if (finalCompanyKPRows && finalCompanyKPRows?.length > 0) {
        kpRows = kpRows.concat(finalCompanyKPRows);
    }


    let repairmentRows = company?.data?.repairment.map((rep, i) => {
        return createData(
            rep?.id + "-Т",
            rep?.comments,
            rep?.status_sales == null ? 'Не известен'
                : rep?.status_sales === 1
                    ? 'Купили'
                    : "Вернули", //status_sales: 1 =купили 2 = повернули
            rep?.status_sales == null
                ? 'orange'
                : rep?.status_sales === 1
                    ? "brown"
                    : "red",
            rep?.id_check_for_sales,
            turnDateYYYYMMDD(rep?.date_add),
            rep?.year,
            "https://master.akon.com.ua/check-t"
        )
    }) ?? [];
    if (orderRows.length > 0 || kpRows.length > 0 || repairmentRows.length > 0) {
        orderRows = orderRows.concat(kpRows);
        orderRows = orderRows.concat(repairmentRows);
    }

    let [planner, setPlanner] = useState(false) // планувальник
//конвертуємо дати в Date
    orderRows = orderRows.map(obj => {
        return {...obj, date: new Date(obj.date)}
    });
    //сортуємо по даті
    orderRows = orderRows.sort((a, b) => Number(b.date) - Number(a.date));
    //переводимо назад в строку
    orderRows = orderRows.map(obj => {
        return {...obj, date: obj.date.toLocaleDateString()}
    })
    //пейджинація
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderRows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    //КІНЕЦЬ ТАБЛИЦІ ЗАМОВЛЕНЬ

    let mainManagerID = normalizeManagerID(company?.data?.whose_company)
    let secondManagerID = normalizeManagerID(company?.data?.whose_company2)
    let currentManagerID = normalizeManagerID(Cookies.get('id'))

    let canEditAllParams = [34, 44, mainManagerID, secondManagerID].includes(currentManagerID) //if user has full access to edit company params
    let canEditSomeParams = (secondManagerID == currentManagerID) //or just some params

    //let mainManagerName = (company?.additional_data?.user_names[normalizeManagerID(mainManagerID)] ?? null)
    let mainManagerName = company?.additional_data?.managers_list?.find(manager => manager.id === normalizeManagerID(company?.data?.whose_company))?.name ?? null;

    let secondManagerName = company?.additional_data?.managers_list?.find(manager => manager.id === normalizeManagerID(company?.data?.whose_company2))?.name ?? null;


    const unbindCompanyPartnerFunc = (item) => {
        if (!window.confirm("Відв'язати компанію?")) {
            return;
        }
        let request = {
            'main_company': item.main_company,
            'companyPartnerID': item.id_secondary_company,
            'type': item.type,
            'managerID': Cookies.get('id'),
            'companyID': company?.data?.id,
        }
        console.log(request);
        props.unbindCompanyPartner(request)

    }
    //ДОДАТИ НОВЕ ПОВІДОМЛЕННЯ КОМПАНІЇ
    let [newCompanyMessage, setNewCompanyMessage] = useState(false); //modal
    let [newCompanyMessageAgentVal, setNewCompanyMessageAgentVal] = useState(null)
    let [newCompanyMessageVal, setNewCompanyMessageVal] = useState("")
    const setNewCompanyMessageValF = (e) => {
        setNewCompanyMessageVal(e.target.value)
    }
    let [newCompanyMessageThemeVal, setNewCompanyMessageThemeVal] = useState("")
    const setNewCompanyMessageThemeValF = (e) => {
        if (e.target.value.length > 127) {
            toast.error('Тема сообщения слишком длинная! Краткость - сестра таланта.')
            return false;
        }
        setNewCompanyMessageThemeVal(e.target.value)
    }
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const handleFileChange = (files) => {
        // Handle the selected files, you can store them in state
        setUploadedFiles(files);
    };

    const addNewCompanyMessage = () => {

        if (newCompanyMessageVal.length === 0) {
            toast.error('Поле сообщения обязательно к заполению!')
            return false;
        }

        const request = new FormData();

        request.append('message', newCompanyMessageVal);
        request.append('theme', newCompanyMessageThemeVal);
        request.append('agents_id', newCompanyMessageAgentVal?.value ?? 0);
        request.append('ownThoughts', checkedOwnThoughts ? 1 : 0);
        request.append('managerID', parseInt(Cookies.get('id')));
        request.append('companyID', company?.data?.id);

        if (uploadedFiles.length > 0) {
            request.append('attachment', uploadedFiles[0]);
        }
        props.setNewCompanyMessage(request)
        handleFileChange([]);
        setNewCompanyMessage(false)
    }

    //РЕДАГУВАННЯ ПОВІДОМЛЕННЯ КОМПАНІЇ
    let [companyMessage, setCompanyMessage] = useState(false); //modal
    let [companyMessageIDVal, setCompanyMessageIDVal] = useState()
    let [companyMessageVal, setCompanyMessageVal] = useState("")
    const setCompanyMessageValF = (e) => {
        setCompanyMessageVal(e.target.value)
    }
    const editCompanyMessage = () => {
        let request = {
            'id': companyMessageIDVal,
            'text': companyMessageVal,
            'companyID': company?.data?.id,
            'managerID': currentManagerID
        };
        console.log(request)
        //return;
        props.setCompanyMessage(request)
        setCompanyMessage(false)
    }

    //ДОДАТИ НОВИЙ КОНТАКТ
    const setNewCompanyContact = () => {
        if (!window.confirm("Додати контакт?")) {
            return;
        }
        let request = {
            'companyID': company?.data?.id,
            'managerID': currentManagerID
        };
        props.addNewContact(request)
        handleTabClick(2)
    }

    //НАЗВА КОМПАНІЇ
    let [companyContract, setCompanyContract] = useState(false); //modal
    let [companyContractVal, setCompanyContractVal] = useState("") //edrpou value
    const setCompanyContractValF = (e) => {
        setCompanyContractVal(e.target.value)
    }
    const editCompanyContract = () => {
        let request = {
            'contract': companyContractVal,
            'companyID': company?.data?.id,
            'personalId': currentManagerID
        };
        props.setCompanyContract(request)
        setCompanyContract(false)
    }

//РЕГІОН КОМПАНІЇ
    let [companyRegion, setCompanyRegion] = useState(false);
    let [companyRegionVal, setCompanyRegionVal] = useState()
    const editCompanyRegion = () => {
        let request = {
            'regionID': companyRegionVal.value,
            'companyID': company?.data?.id,
            'personalId': currentManagerID
        };
        props.setCompanyRegion(request)
        setCompanyRegion(false)
    }

//МІСТО КОМПАНІЇ
    let [companyCity, setCompanyCity] = useState(false);
    let [companyRegionCode, setCompanyRegionCode] = useState(null);
    let [companyCityVal, setCompanyCityVal] = useState()
    const editCompanyCity = () => {
        let request = {
            'cityID': companyCityVal.value,
            'companyID': company?.data?.id,
            'personalId': currentManagerID
        };
        props.setCompanyCity(request)
        setCompanyCity(false)
    }
//НАЗВА КОМПАНІЇ РЕАЛЬНА
    const [isCompanyNameButtonDisabled, setIsCompanyNameButtonDisabled] = useState(false); // disable button from validation
    let [companyName, setCompanyName] = useState(false); //modal
    let [companyNameVal, setCompanyNameVal] = useState(company?.data?.name) //edrpou value
    const setCompanyNameValF = (e) => {
        setCompanyNameVal(e.target.value)
        if (e.target.value.length > 5) {
            setIsCompanyNameButtonDisabled(false)
        } else {
            setIsCompanyNameButtonDisabled(true)
        }
    }
    const editCompanyName = () => {
        let request = {
            'newName': companyNameVal,
            'companyID': company?.data?.id,
            'personalId': currentManagerID
        };
        props.setCompanyName(request)
        setCompanyName(false)
    }
//НАЗВА КОМПАНІЇ ВНУТРІШНЯ
    let [companyAkonName, setCompanyAkonName] = useState(false); //modal
    let [companyAkonNameVal, setCompanyAkonNameVal] = useState("") //edrpou value
    const setCompanyAkonNameValF = (e) => {
        setCompanyAkonNameVal(e.target.value)
    }
    const editCompanyAkonName = () => {
        let request = {
            'newAkonName': companyAkonNameVal,
            'companyID': company?.data?.id,
            'personalId': currentManagerID
        };
        props.setCompanyAkonName(request)
            .then(() => {
                setCompanyAkonName(false);
            });
    }
    //АДРЕСА НОВОЇ ПОШТИ
    let [companyDeliveryAddress, setCompanyDeliveryAddress] = useState(false); //modal
    let [companyDeliveryAddressVal, setCompanyDeliveryAddressVal] = useState("") //edrpou value
    const setCompanyDeliveryAddressValF = (e) => {
        setCompanyDeliveryAddressVal(e.target.value)
    }
    const editCompanyDeliveryAddress = () => {
        let request = {
            'newDeliveryAddress': companyDeliveryAddressVal,
            'companyID': company?.data?.id,
            'personalId': currentManagerID
        };
        props.setCompanyDeliveryAddress(request)
        setCompanyDeliveryAddress(false)
    }
//ВИДАЛИТИ ЗВ"ЯЗОК КОМПАНІЇ
    let [deleteSupplier, setDeleteSupplier] = useState(false); //modal to delete supplier
    let [supplierIDVal, setSupplierIDVal] = useState("") //supplier ID value. Both for delete and add
    const unbindSupplier = () => {
        let request = {
            'supplier_id': supplierIDVal.id,
            'company_id': company?.data?.id,
            'managerID': currentManagerID
        };
        console.log(request, supplierIDVal)
        props.unbindSupplier(request)
        setDeleteSupplier(false)
    }
//ДОДАТИ ЗВ"ЯЗОК КОМПАНІЇ
    let [addSupplier, setAddSupplier] = useState(false); //modal to delete supplier
    const bindSupplier = () => {
        let request = {
            'supplier_id': supplierIDVal.value,
            'company_id': company?.data?.id,
            'managerID': currentManagerID
        };
        props.bindSupplier(request)
        setAddSupplier(false)
    }

//ВИДАЛИТИ ТЕГ (СТАТУС) КОМПАНІЇ
    let [deleteTag, setDeleteTag] = useState(false); //modal to delete supplier
    let [tagIDVal, setTagIDVal] = useState("") //supplier ID value. Both for delete and add
    const unbindTag = () => {
        let request = {
            'tag_id': parseInt(tagIDVal),
            'company_id': company?.data?.id,
            'managerID': currentManagerID
        };
        props.unbindTag(request)
        setTagIDVal("");
        setDeleteTag(false)
    }

//ДОДАТИ ТЕГ (СТАТУС) КОМПАНІЇ
    let [addTag, setAddTag] = useState(false); //modal to delete supplier
    const bindTag = () => {
        let request = {
            'tag_id': tagIDVal.value,
            'company_id': company?.data?.id,
            'managerID': currentManagerID
        };
        //console.log(request)
        props.bindTag(request)
        setTagIDVal('');
        setAddTag(false)
    }

//ДОДАТИ NEW CATEGORY КОМПАНІЇ
    let [addNewCategory, setAddNewCategory] = useState(false); //modal
    let [deleteCategory, setDeleteCategory] = useState(false); //modal to delete supplier
    let [newCategoryIDVal, setNewCategoryIDVal] = useState("") //comment value
    const bindNewCategory = () => {
        let request = {
            'category_id': newCategoryIDVal.value,
            'company_id': company?.data?.id,
            'managerID': currentManagerID
        };
        //console.log(request)
        props.bindNewCategory(request)
        setNewCategoryIDVal('');
        setAddNewCategory(false)
    }
    const unbindCategory = () => {
        let request = {
            'category_id': parseInt(newCategoryIDVal),
            'company_id': company?.data?.id,
            'managerID': currentManagerID
        };
        props.unbindNewCategory(request)
        setDeleteCategory(false)
        setNewCategoryIDVal("");
    }

//ДОДАТИ NEW INDUSTRY КОМПАНІЇ
    let [addNewIndustry, setAddNewIndustry] = useState(false); //modal
    let [deleteIndustry, setDeleteIndustry] = useState(false); //modal to delete supplier
    let [newIndustryIDVal, setNewIndustryIDVal] = useState("") //comment value
    const bindNewIndustry = () => {
        let request = {
            'industry_id': newIndustryIDVal.value,
            'company_id': company?.data?.id,
            'managerID': currentManagerID
        };
        //console.log(request)
        props.bindNewIndustry(request)
        setNewIndustryIDVal('');
        setAddNewIndustry(false)
    }
    const unbindIndustry = () => {
        let request = {
            'industry_id': parseInt(newIndustryIDVal),
            'company_id': company?.data?.id,
            'managerID': currentManagerID
        };
        props.unbindNewIndustry(request)
        setNewIndustryIDVal("");
        setDeleteIndustry(false)
    }

//КОМЕНТАР ДО КОМПАНІЇ
    let [companyComment, setCompanyComment] = useState(false); //modal
    let [companyCommentVal, setCompanyCommentVal] = useState("") //comment value
    const setCompanyCommentValF = (e) => {
        setCompanyCommentVal(e.target.value)
    }
    const editCompanyComment = () => {
        let request = {
            'newComment': companyCommentVal,
            'companyID': company?.data?.id,
            'personalId': currentManagerID
        };
        props.editCompanyComment(request)
        setCompanyComment(false)
    }


    //ДОДАТИ ПАРТНЕРА \ АЛІАСА КОМПАНІЇ

    //список компаній для select в модалці
    let [companyPartnersList, setCompanyPartnersList] = useState(false); //modal
    let [companyPartnersListVal, setCompanyPartnersListVal] = useState("") //comment value
    const setCompanyPartnersListValF = (e) => {
        setCompanyPartnersListVal(e.target.value)
    }
    const editCompanyPartnersList = () => {
        let request = {
            'newPartner': companyPartnersListVal,
            'companyID': company?.data?.id,
            'personalId': currentManagerID
        };
        console.log(request)
        props.addCompanyPartner(request)
        setCompanyPartnersList(false)
    }


    const optionsRegionsList = props?.regionsList?.map((region) => ({
        value: region?.id,
        label: region?.regions,
    }));


    const optionsCityList = props?.cityList?.map((city) => ({
        value: city?.level_2,
        label: city?.name,
    }));

    const optionsCompaniesList = props?.companyList?.map((company) => ({
        value: company?.id,
        label: company?.id + '. ' + company?.name_with_akon ?? company?.name,
    }));
//company?.p_company_segmentation2
    const optionsSegmentationsList = props?.segmentationsList?.map((segment) => ({
        value: segment?.id,
        label: segment?.name,
    }));
    const optionsClientCompanySegmentation = props?.clientSegmentationsList?.map((segment) => ({
        value: segment.id,
        label: segment.name,
    }));


    let [companyPartner, setCompanyPartner] = useState(false); //modal
    let [companyPartnerTypeVal, setCompanyPartnerTypeVal] = useState(false) //comment value
    let [companyPartnerVal, setCompanyPartnerVal] = useState("") //comment value
    const setCompanyPartnerValF = (e) => {
        setCompanyPartnerVal(e.target.value)
    }
    const addCompanyPartner = () => {
        let request = {
            'newPartner': companyPartnerVal.value,
            'isAlias': !companyPartnerTypeVal,
            'companyID': company?.data?.id,
            'personalId': currentManagerID
        };
        console.log(request)
        props.addCompanyPartner(request)
        setCompanyPartner(false)
    }

    //ВІДВ"ЯЗАТИ ОСНОВНОГО МАНАГЕРА (першого)
    const unbindCompanyMainManagerFunc = () => {
        let request = {
            'managerID': null,
            'companyID': company?.data?.id,
            'personalId': currentManagerID, //@
        };
        props.editCompanyManager1(request)
    }

//Обрати/змінити першого манагера
    let [companyManager1, setCompanyManager1] = useState(false); //modal
    const [selected1Manager, setSelected1Manager] = useState(null);

    const editCompanyManager1 = () => {
        let request = {
            'managerID': selected1Manager?.value,
            'companyID': company?.data?.id,
            'personalId': currentManagerID
        };
        console.log(request, selected1Manager)
        props.editCompanyManager1(request)
        setCompanyManager1(false)
    }

    const optionsContactForNewMessage = company?.data?.agents?.map((agent) => ({
        value: agent.id,
        label: agent.name + ' (' + (agent?.tell ?? agent?.tell2 ?? agent?.tell3 ?? '') + ')',
    }));

    const options = company?.additional_data?.managers_list
        ?.filter((manager) => manager.id !== mainManagerID)
        ?.map((manager) => ({
            value: manager.id,
            label: manager.name,
        }));


    const markedSuppliers = company?.data?.suppliers.map((supplier) => supplier?.supplier?.supplier);
    const suppliersList = company?.additional_data?.suppliers_list?.filter(supplier => !markedSuppliers.includes(supplier.name)).map((supplier) => ({
        value: supplier.id,
        label: supplier.name,
    }));

    const markedTags = company?.data?.p_company_segmentation_wants?.split(',');
    const uniqueTagIds = new Set();

    const tagsList = company?.additional_data?.tags_list?.filter(tag => {
        const id = tag.id.toString();
        if (!markedTags?.includes(id) && !uniqueTagIds?.has(id)) {
            uniqueTagIds.add(id);
            return true;
        }
        return false;
    }).map((tag) => ({
        value: tag.id,
        label: tag.name,
    }));

    const newCategoriesList = company?.additional_data?.categories_list?.map((category) => ({
        value: category.id,
        label: category.name,
    }));
    const newIndustriesList = company?.additional_data?.industries_list?.map((industry) => ({
        value: industry.id,
        label: industry.name,
    }));


//Обрати/змінити другого манагера
    let [companyManager2, setCompanyManager2] = useState(false); //modal
    let [selected2Manager, setSelected2Manager] = useState("") //comment value

    const editCompanyManager2 = () => {
        let request = {
            'whose_company2': selected2Manager.value,
            'companyID': company?.data?.id,
            'managerID': currentManagerID
        };
        console.log(request);
        props.setCompanyManager2(request)
        setCompanyManager2(false)
    }
//Обрати/змінити Client Company Segmentation
    let [clientCompanySegmentation, setClientCompanySegmentation] = useState(false); //modal
    let [clientCompanySegmentationVal, setClientCompanySegmentationVal] = useState(null) //comment value

    const editClientCompanySegmentation = () => {
        if (clientCompanySegmentationVal === null) return
        let request = {
            'client_segmentation': parseInt(clientCompanySegmentationVal.value),
            'companyID': company?.data?.id,
            'personalId': currentManagerID
        };
        props.setClientCompanySegmentation(request)
        setClientCompanySegmentation(false)
    }
//Обрати/змінити Company Segmentation
    let [companySegmentation, setCompanySegmentation] = useState(false); //modal
    let [companySegmentationVal, setCompanySegmentationVal] = useState("") //comment value

    const editCompanySegmentation = () => {
        let request = {
            'segmentation': parseInt(companySegmentationVal.value),
            'companyID': company?.data?.id,
            'personalId': currentManagerID
        };
        //console.log(request);
        props.setCompanySegmentation(request)
        setCompanySegmentation(false)
    }

//Обрати/змінити Company Segmentation2
    let [companySegmentation2, setCompanySegmentation2] = useState(false); //modal
    let [companySegmentation2Val, setCompanySegmentation2Val] = useState("") //comment value

    const editCompanySegmentation2 = () => {
        let request = {
            'segmentation2': parseInt(companySegmentation2Val.value),
            'companyID': company?.data?.id,
            'personalId': currentManagerID
        };
        //console.log(request);
        props.setCompany2Segmentation(request)
        setCompanySegmentation2(false)
    }

//Сайт компании
    let [site, setSite] = useState(false); //modal
    let [siteVal, setSiteVal] = useState("") //comment value
    //setSiteValF
    const setSiteValF = (e) => {
        setSiteVal(e.target.value)
    }
    const isWebsiteValid = (url) => {
        return url.startsWith('http://') || url.startsWith('https://');
    };
    const editSite = () => {
        let request = {
            'site': siteVal,
            'companyID': company?.data?.id,
            'personalId': currentManagerID
        };
        //console.log(request);
        props.setCompanySite(request)
        setSite(false)
    }

//ЗНИЖКА КОМПАНІЇ
    let [companyDiscount, setCompanyDiscount] = useState(false); //modal
    let [companyDiscountPercentVal, setCompanyDiscountPercentVal] = useState("") //discount percent
    let [companyDiscountDateVal, setCompanyDiscountDateVal] = useState("") //discount before
    const setCompanyDiscountPercentValF = (e) => {
        setCompanyDiscountPercentVal(e.target.value)
    }
    const setCompanyDiscountDateValF = (e) => {
        setCompanyDiscountDateVal(e.target.value)
    }
    const editCompanyDiscount = () => {
        let request = {
            'discount_percent': companyDiscountPercentVal,
            'discount_date': companyDiscountDateVal,
            'companyID': company?.data?.id,
            'personalId': currentManagerID
        };
        props.editCompanyDiscount(request)
        setCompanyDiscount(false)
    }

    //setCancelShareWindow
    const rejectCompany = (id) => {
        let request = {
            'managerID': Cookies.get('id'),
            'companyID': id,
            'rawManagerID': currentManagerID,
        };
        props.forceRejectCompanySecondManager(request)
        setOpenShareWindow(false);
        secondManagerName = null;
    }

    const [openShareWindow, setOpenShareWindow] = React.useState(false);
    const handleClickOpenShareWindow = () => {
        setOpenShareWindow(true);
    };


    const handleCloseShareWindow = () => {
        setOpenShareWindow(false);
    };


    const cancelCompanyDiscount = () => {
        if (!window.confirm("Точно хотите досрочно обнулить скидку Компании?")) {
            return;
        }
        let request = {
            'discount_percent': null,
            'discount_date': null,
            'companyID': company?.data?.id,
            'personalId': currentManagerID
        };
        props.editCompanyDiscount(request)
        setCompanyDiscount(false)
    }

    //ВИДАЛЕННЯ ПОВІДОМЛЕННЯ ВІД АГЕНТА
    const deleteMessage = (id) => {
        if (!window.confirm("Точно хотите отправить сообщение в архив?")) {
            return;
        }
        let request = {
            'messageID': id,
            'companyID': company?.data?.id,
            'id': currentManagerID
        };
        props.deleteMessageFromAgent(request)
    }

    //companyPartnersList

    let [companyPartnersListModal, setСompanyPartnersListModal] = useState(false); //modal
    let [companyAliasListModal, setСompanyAliasListModal] = useState(false); //modal

    //ЄДРПОУ
    const [isEdrpouButtonDisabled, setEdrpouButtonDisabled] = useState(false); // disable button from validation
    let [edrpou, setEdrpou] = useState(false); //modal
    let [edrpouVal, setEdrpouVal] = useState("") //edrpou value
    const setEdrpouValF = (e) => {
        setEdrpouVal(e.target.value)
        if (e.target.value.length === 8 || e.target.value.length === 0 || e.target.value.length === 10) {
            setEdrpouButtonDisabled(false)
        } else {
            setEdrpouButtonDisabled(true)
        }
    }

    const addEdrpou = () => {
        let request = {
            'active': props.active,
            'edrpou': edrpouVal.length > 0 ? edrpouVal : null,
            'companyID': company?.data?.id,
            'personalId': currentManagerID
        };
        props.setCompanyEdrpou(request)
        setEdrpou(false)
    }


    if (!company || !company.data) {
        return <LoadingPlaceholder/>;
    } else {
        document.title = `АКОН - профиль компании ` + company.data.name_with_akon || company.data.name;
    }


    let allowedAudioTypes = [
        'mp3',
        'wav',
        'ogg',
        'm4a'
    ];

    //stats company?.additional_data?.stats


    return (
        <div>
            <Dialog
                open={openShareWindow}
                onClose={handleCloseShareWindow}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Отмена шаринга компании"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Точно хочешь отменить шаринг компании?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => rejectCompany(company?.data?.id ?? 0)}>Хочу разрушить чью-то судьбу</Button>
                </DialogActions>
            </Dialog>
            {planner &&
                <Modal active={planner} setActive={setPlanner}>
                    <SetCompanyToPlanner
                        active={planner}
                        setActive={setPlanner}
                        setCompanyToPlanner={props.setCompanyToPlannerFromCompanyProfile}
                        personalId={currentManagerID}
                        countCompanyPlanerInThisDate={props.countCompanyPlanerInThisDate}
                        getAllCompanyPlanerInThisDate={props.getAllCompanyPlanerInThisDate}
                    />
                </Modal>
            }
            {edrpou &&
                <Modal active={edrpou} setActive={setEdrpou}>
                    <h3 className={main.center}>Редактирование ЕДРПОУ компании</h3>
                    <div><input type="number" className={main.input} value={edrpouVal} onChange={setEdrpouValF}/></div>
                    <div className={main.center}>
                        <input
                            type="button"
                            value="Сохранить"
                            onClick={() => addEdrpou()}
                            className={main.inputAdd}
                            disabled={isEdrpouButtonDisabled}
                        />
                    </div>
                </Modal>
            }
            {companyName &&
                <Modal active={companyName} setActive={setCompanyName}>
                    <h3 className={main.center}>Редактирование названия компании</h3>
                    <div>
                        <input type="text" className={main.input} value={companyNameVal} onChange={setCompanyNameValF}/>
                    </div>
                    <div className={main.center}>
                        <input
                            type="button"
                            id={'saveCompanyNameBtn'}
                            value="Сохранить"
                            onClick={() => editCompanyName()}
                            className={main.inputAdd}
                            disabled={isCompanyNameButtonDisabled}
                        />
                    </div>
                </Modal>
            }
            {companyAkonName &&
                <Modal active={companyAkonName} setActive={setCompanyAkonName}>
                    <h3 className={main.center}>Редактирование названия компании</h3>
                    <div>
                        <input type="text" className={main.input} value={companyAkonNameVal}
                               onChange={setCompanyAkonNameValF}/>
                    </div>
                    <div className={main.center}>
                        <input
                            type="button"
                            value="Сохранить"
                            onClick={() => editCompanyAkonName()}
                            className={main.inputAdd}
                        />
                    </div>
                </Modal>
            }

            {companyDeliveryAddress &&
                <Modal active={companyDeliveryAddress} setActive={setCompanyDeliveryAddress}>
                    <h3 className={main.center}>Редактирование адреса доставки компании</h3>
                    <div>
                        <textarea
                            className={main.input}
                            value={companyDeliveryAddressVal}
                            onChange={setCompanyDeliveryAddressValF}
                            rows={5}
                        />
                    </div>
                    <div className={main.center}>
                        <input
                            type="button"
                            value="Сохранить"
                            onClick={() => editCompanyDeliveryAddress()}
                            className={main.inputAdd}
                        />
                    </div>
                </Modal>
            }
            {companyComment &&
                <Modal active={companyComment} setActive={setCompanyComment}>
                    <h3 className={main.center}>Редактирование описания компании</h3>
                    <div>
                        <InputTextarea
                            autoResize
                            value={companyCommentVal}
                            onChange={setCompanyCommentValF}
                            rows={5}
                            style={{width: '100%', textAlign: 'left'}}
                        />
                    </div>
                    <div className={main.center}>
                        <input
                            type="button"
                            value="Сохранить"
                            onClick={() => editCompanyComment()}
                            className={main.inputAdd}
                        />
                    </div>
                </Modal>
            }
            {deleteSupplier &&
                <Modal active={deleteSupplier} setActive={setDeleteSupplier}>
                    <h3 className={main.center}>Вы действительно хотите отвязать поставщика от Компании?</h3>
                    <div className={main.center}>
                        <input
                            type="button"
                            value="Отвязать"
                            onClick={() => unbindSupplier()}
                            className={main.inputDelete}
                        />
                    </div>
                </Modal>
            }
            {deleteTag &&
                <Modal active={deleteTag} setActive={setDeleteTag}>
                    <h3 className={main.center}>Вы действительно хотите отвязать тег от Компании?</h3>
                    <div className={main.center}>
                        <input
                            type="button"
                            value="Отвязать"
                            onClick={() => unbindTag()}
                            className={main.inputDelete}
                        />
                    </div>
                </Modal>
            }
            {deleteIndustry &&
                <Modal active={deleteIndustry} setActive={setDeleteIndustry}>
                    <h3 className={main.center}>Вы действительно хотите отвязать отрасль от Компании?</h3>
                    <div className={main.center}>
                        <input
                            type="button"
                            value="Отвязать"
                            onClick={() => unbindIndustry()}
                            className={main.inputDelete}
                        />
                    </div>
                </Modal>
            }
            {deleteCategory &&
                <Modal active={deleteCategory} setActive={setDeleteCategory}>
                    <h3 className={main.center}>Вы действительно хотите отвязать категорию от Компании?</h3>
                    <div className={main.center}>
                        <input
                            type="button"
                            value="Отвязать"
                            onClick={() => unbindCategory()}
                            className={main.inputDelete}
                        />
                    </div>
                </Modal>
            }
            {companyManager1 && (
                <Modal active={companyManager1} setActive={setCompanyManager1}>
                    <h3 className={main.center}>Редактирование основного менеджера Компании</h3>
                    <div
                        style={{marginBottom: '40%'}}
                    >
                        <Select
                            ref={ref}
                            value={selected1Manager}
                            onChange={(e) => setSelected1Manager(e ?? null)}
                            options={options}
                            searchable={true}
                            searchPromptText={'Выбор жертвы'}

                        />
                    </div>
                    <div className={main.center}>
                        <input
                            type="button"
                            value="Сохранить"
                            onClick={editCompanyManager1}
                            className={main.inputAdd}
                        />
                    </div>
                </Modal>
            )}
            {addSupplier && (
                <Modal active={addSupplier} setActive={setAddSupplier}>
                    <h3 className={main.center}>Добавление поставщика Компании</h3>
                    <div
                        style={{marginBottom: '40%'}}
                    >
                        <Select
                            ref={ref}
                            value={supplierIDVal}
                            onChange={(e) => setSupplierIDVal(e ?? null)}
                            options={suppliersList}
                            searchable={true}
                            searchPromptText={'Выбор Поставщика'}

                        />
                    </div>
                    <div className={main.center}>
                        <input
                            type="button"
                            value="Сохранить"
                            onClick={bindSupplier}
                            className={main.inputAdd}
                        />
                    </div>
                </Modal>
            )}
            {addTag && (
                <Modal active={addTag} setActive={setAddTag}>
                    <h3 className={main.center}>Добавление тега Компании</h3>
                    <div
                        style={{marginBottom: '60%'}}
                    >
                        <Select
                            ref={ref}
                            value={tagIDVal}
                            onChange={(e) => setTagIDVal(e ?? null)}
                            options={tagsList}
                            searchable={true}
                            searchPromptText={'Выбор тега'}

                        />
                    </div>
                    <div className={main.center}>
                        <input
                            type="button"
                            value="Сохранить"
                            onClick={bindTag}
                            className={main.inputAdd}
                        />
                    </div>
                </Modal>
            )}
            {addNewCategory && (
                <Modal active={addNewCategory} setActive={setAddNewCategory}>
                    <h3 className={main.center}>Добавление новой категории Компании</h3>
                    <div
                        style={{marginBottom: '60%'}}
                    >
                        <Select
                            ref={ref}
                            value={newCategoryIDVal}
                            onChange={(e) => setNewCategoryIDVal(e ?? null)}
                            options={newCategoriesList}
                            searchable={true}
                            searchPromptText={'Выбор категории'}

                        />
                    </div>
                    <div className={main.center}>
                        <input
                            type="button"
                            value="Сохранить"
                            onClick={bindNewCategory}
                            className={main.inputAdd}
                        />
                    </div>
                </Modal>
            )}
            {addNewIndustry && (
                <Modal active={addNewIndustry} setActive={setAddNewIndustry}>
                    <h3 className={main.center}>Добавление новой отрасли Компании</h3>
                    <div
                        style={{marginBottom: '60%'}}
                    >
                        <Select
                            ref={ref}
                            value={newIndustryIDVal}
                            onChange={(e) => setNewIndustryIDVal(e ?? null)}
                            options={newIndustriesList}
                            searchable={true}
                            searchPromptText={'Выбор отрасли'}

                        />
                    </div>
                    <div className={main.center}>
                        <input
                            type="button"
                            value="Сохранить"
                            onClick={bindNewIndustry}
                            className={main.inputAdd}
                        />
                    </div>
                </Modal>
            )}
            {companyManager2 && (
                <Modal active={companyManager2} setActive={setCompanyManager2}>
                    <h3 className={main.center}>Редактирование второго менеджера Компании</h3>
                    <div
                        style={{marginBottom: '40%'}}
                    >

                        <label>
                            Кому передаём Компанию?
                            <Select
                                ref={ref}
                                value={selected2Manager}
                                onChange={(e) => setSelected2Manager(e ?? null)}
                                options={options}
                                searchable={true}
                                searchPromptText={'Выбор жертвы'}/>
                        </label>


                    </div>
                    <div className={main.center}>
                        <input
                            type="button"
                            value="Сохранить"
                            onClick={editCompanyManager2}
                            className={main.inputAdd}
                        />
                    </div>
                </Modal>
            )}
            {companyDiscount &&
                <Modal active={companyDiscount} setActive={setCompanyDiscount}>
                    <h3 className={main.center}>Редактирование скидки компании</h3>
                    <div>
                        <input
                            type="text"
                            className={main.input}
                            value={companyDiscountPercentVal}
                            onChange={setCompanyDiscountPercentValF}
                        />
                    </div>
                    <div>
                        <input
                            type="date"
                            className={main.input}
                            value={companyDiscountDateVal}
                            placeholder={'% скидки'}
                            onChange={setCompanyDiscountDateValF}
                        />
                    </div>
                    <div className={main.center}>
                        <input
                            type="button"
                            value="Отменить скидку"
                            placeholder={'Действительна до'}
                            onClick={() => cancelCompanyDiscount()}
                            className={main.inputAdd}
                            disabled={company?.data?.discount == 0}
                        />
                        <input
                            type="button"
                            value="Сохранить"
                            placeholder={'Действительна до'}
                            onClick={() => editCompanyDiscount()}
                            className={main.inputAdd}
                        />
                    </div>
                </Modal>
            }

            {companyContract &&
                <Modal active={companyContract} setActive={setCompanyContract}>
                    <h3 className={main.center}>Редактирование контракта компании</h3>
                    <div><input type="text" className={main.input} value={companyContractVal}
                                onChange={setCompanyContractValF}/></div>
                    <div className={main.center}>
                        <input
                            type="button"
                            value="Сохранить"
                            onClick={() => editCompanyContract()}
                            className={main.inputAdd}
                        />
                    </div>
                </Modal>
            }
            {companyMessage &&
                <Modal active={companyMessage} setActive={setCompanyMessage}>
                    <h3 className={main.center}>Редактирование сообщения</h3>
                    <div>
                        <InputTextarea
                            autoResize
                            value={companyMessageVal}
                            onChange={setCompanyMessageValF}
                            rows={5}
                            style={{width: '100%', textAlign: 'left'}}
                        />
                    </div>
                    <div className={main.center}>
                        <input
                            type="button"
                            value="Сохранить"
                            onClick={() => editCompanyMessage()}
                            className={main.inputAdd}
                        />
                    </div>
                </Modal>
            }
            {newCompanyMessage &&
                <Modal active={newCompanyMessage} setActive={setNewCompanyMessage}>
                    <h3 className={main.center}>Добавление нового сообщения</h3>
                    <div>
                        <Select
                            value={newCompanyMessageAgentVal}
                            onChange={(item) => {
                                setNewCompanyMessageAgentVal(item ?? null)
                            }}
                            options={optionsContactForNewMessage}
                            searchable={true}
                            searchPromptText={'С кем общались?'}
                        />
                    </div>

                    <div>
                        <Input
                            autoResize
                            value={newCompanyMessageThemeVal}
                            onChange={setNewCompanyMessageThemeValF}
                            rows={1}
                            placeholder={'Тема'}
                            style={{width: '100%', textAlign: 'left'}}
                            //className={main.input}
                        />
                    </div>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox
                            checked={checkedOwnThoughts}
                            onChange={handleChangeOwnThoughts}
                        />} label="Свои мысли"/>
                    </FormGroup>

                    <div>
                        <InputTextarea
                            autoResize
                            value={newCompanyMessageVal}
                            onChange={setNewCompanyMessageValF}
                            rows={5}
                            // style={{width: '100%', textAlign: 'left'}}
                            className={main.textarea}
                        />
                    </div>
                    <div>
                        <input
                            type="file"
                            onChange={(e) => handleFileChange(e.target.files)}
                        />
                    </div>
                    <div className={main.center}>

                        <input
                            type="button"
                            value="Сохранить"
                            onClick={() => addNewCompanyMessage()}
                            className={main.inputAdd}
                        />
                    </div>
                </Modal>
            }
            {site &&
                <Modal active={site} setActive={setSite}>
                    <h3 className={main.center}>Редактирование сайта компании</h3>
                    <div><input type="text" className={main.input} value={siteVal}
                                onChange={setSiteValF}/></div>
                    <div className={main.center}>
                        <input
                            type="button"
                            value="Сохранить"
                            onClick={() => editSite()}
                            className={main.inputAdd}
                        />
                    </div>
                </Modal>
            }
            {companyPartnersListModal && (
                <Modal active={companyPartnersListModal} setActive={setСompanyPartnersListModal}>
                    <h3 className={main.center}>Cписок таких же компаний</h3>
                    <div>
                        {company?.data?.company_support?.filter(item => item.type === 2).map((item, index) => (
                            <div key={index}>
                                <label className={s.partnerLabel}>
                                    <a href={item.id_secondary_company}>{company?.additional_data?.support_company_names?.[item.id_secondary_company]}</a>
                                    <button onClick={() => unbindCompanyPartnerFunc(item)}>x</button>
                                </label>
                            </div>
                        ))}
                    </div>
                </Modal>
            )}
            {companyAliasListModal && (
                <Modal active={companyAliasListModal} setActive={setСompanyAliasListModal}>
                    <h3 className={main.center}>Cписок партнёров</h3>
                    <div>
                        {company?.data?.company_support?.filter(item => item.type === 1).map((item, index) => (
                            <div key={index}>
                                <label className={s.partnerLabel}>
                                    <a href={item.id_secondary_company}>{company?.additional_data?.support_company_names?.[item.id_secondary_company]}</a>
                                    <button onClick={() => unbindCompanyPartnerFunc(item)}>x</button>
                                </label>
                            </div>
                        ))}
                    </div>
                </Modal>
            )}


            {companyRegion &&
                <Modal active={companyRegion} setActive={setCompanyRegion}>
                    <h3 className={main.center}>Редактирование региона компании</h3>
                    <div
                        style={{marginBottom: '40%'}}
                    >
                        <Select
                            ref={ref}
                            onChange={(item) => setCompanyRegionVal(item ?? null)}
                            options={optionsRegionsList}
                            searchable={true}
                            value={companyRegionVal}
                            searchPromptText={'Выбор региона компании'}
                        />
                    </div>
                    <div className={main.center}>
                        <input
                            type="button"
                            value="Сохранить"
                            onClick={() => editCompanyRegion()}
                            className={main.inputAdd}
                        />
                    </div>
                </Modal>
            }

            {companyCity &&
                <>
                    <Modal active={companyCity} setActive={setCompanyCity}>
                        <h3 className={main.center}>Редактирование населенного пункта компании</h3>
                        <div
                            style={{marginBottom: '40%'}}
                        >
                            <Select
                                ref={ref}
                                onChange={(item) => setCompanyCityVal(item ?? null)}
                                options={optionsCityList}
                                searchable={true}
                                value={companyCityVal}
                                searchPromptText={'Выбор нас.пункта компании'}
                            />
                        </div>
                        <div className={main.center}>
                            <input
                                type="button"
                                value="Сохранить"
                                onClick={() => editCompanyCity()}
                                className={main.inputAdd}
                            />
                        </div>
                    </Modal>
                </>
            }

            {clientCompanySegmentation &&
                <Modal active={clientCompanySegmentation} setActive={setClientCompanySegmentation} big={true}>
                    <h3 className={main.center}>Редактирование отрасли компании</h3>
                    <div
                        style={{marginBottom: '40%'}}
                    >
                        <Select
                            ref={ref}
                            onChange={(e) => setClientCompanySegmentationVal(e ?? null)}
                            options={optionsClientCompanySegmentation}
                            searchable={true}
                            value={clientCompanySegmentationVal}
                            searchPromptText={'Выбор отрасли компании'}

                        />
                    </div>
                    <div className={main.center}>
                        <input
                            type="button"
                            value="Сохранить"
                            onClick={() => editClientCompanySegmentation()}
                            className={main.inputAdd}
                        />
                    </div>
                </Modal>
            }
            {companySegmentation &&
                <Modal active={companySegmentation} setActive={setCompanySegmentation} big={true}>
                    <h3 className={main.center}>Редактирование основной категории компании</h3>
                    <div
                        style={{marginBottom: '40%'}}
                    >
                        <Select
                            ref={ref}
                            onChange={(e) => setCompanySegmentationVal(e ?? '')}
                            options={optionsSegmentationsList}
                            searchable={true}
                            value={companySegmentationVal}
                            searchPromptText={'Выбор категории компании'}
                        />
                    </div>
                    <div className={main.center}>
                        <input
                            type="button"
                            value="Сохранить"
                            onClick={() => editCompanySegmentation()}
                            className={main.inputAdd}
                        />
                    </div>
                </Modal>
            }

            {companyPartner &&
                <Modal active={companyPartner} setActive={setCompanyPartner}>
                    <h3 className={main.center}>Добавление партнёра компании</h3>
                    <div
                        style={{marginBottom: '40%'}}
                    >
                        <Select
                            ref={ref}
                            onChange={(e) => setCompanyPartnerVal(e ?? '')}
                            options={optionsCompaniesList}
                            searchable={true}
                            value={companyPartnerVal}
                            searchPromptText={'Выбор компании-партнёра'}
                            style={{marginBottom: '40%'}}

                        />
                        <Switch
                            isOn={companyPartnerTypeVal}
                            label={'Такая же компания'}
                            onColor="#EF476F"
                            handleToggle={() => setCompanyPartnerTypeVal(!companyPartnerTypeVal)}
                        />
                    </div>
                    <br/>
                    <div className={main.center}>
                        <input
                            type="button"
                            value="Добавить"
                            onClick={() => addCompanyPartner()}
                            className={main.inputAdd}
                        />
                    </div>
                </Modal>
            }

            <div className={main.center}><h1>{company?.data?.name}</h1></div>
            <div className="tab-content">
                <div>
                    <div className={s.main}>
                        <div className={s.mainBorder}>
                            <div>
                                <b>Название: </b>
                                <LongText text={company?.data?.name} maxLength={20}/>
                                {canEditAllParams ? (
                                    company?.data?.name !== null ? (
                                        <FontAwesomeIcon
                                            icon={faEdit}
                                            color='green'
                                            onClick={() => {
                                                setCompanyNameVal(company?.data?.name);
                                                setCompanyName(1);
                                            }}
                                            pull="right"
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={faPlus}
                                            color='green'
                                            onClick={() => setCompanyName(1)}
                                            pull="right"
                                        />
                                    )
                                ) : (
                                    ""
                                )}
                            </div>

                            <div>
                                <b>Название внутреннее: </b>
                                {company?.data?.name_with_akon}
                                {canEditAllParams ? (
                                    company?.data?.name_with_akon !== null ? (
                                        <FontAwesomeIcon
                                            icon={faEdit}
                                            color='green'
                                            onClick={() => {
                                                setCompanyAkonNameVal(company?.data?.name_with_akon);
                                                setCompanyAkonName(1);
                                            }}
                                            pull="right"
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={faPlus}
                                            color='green'
                                            onClick={() => setCompanyAkonName(1)}
                                            pull="right"
                                        />
                                    )
                                ) : (
                                    ""
                                )}
                            </div>
                            <div>
                                <b>Ответственные менеджеры: </b>
                                <div>
                                    {secondManagerName === null && company?.data?.whose_company2_expire_date == null
                                        ? <b>Главный: </b>
                                        : company?.data?.whose_company2_confirmation_date === null
                                            ? <b>Предложено передать: </b>
                                            : <b>Ведущий: </b>
                                    }


                                    {mainManagerName ? (
                                        <>{mainManagerName}
                                            {secondManagerName === null && company?.data?.whose_company2_expire_date == null
                                                ? ''
                                                : <>({company?.data?.whose_company2_date_add}) </>
                                            }

                                            {(canEditAllParams && secondManagerName === null) ? (
                                                    <>
                                                        <button onClick={() => unbindCompanyMainManagerFunc()}
                                                                style={{marginLeft: '5px'}}>x
                                                        </button>
                                                        <FontAwesomeIcon
                                                            icon={faShare}
                                                            color='green'
                                                            onClick={() => {
                                                                setSelected2Manager('');
                                                                setCompanyManager2(1);
                                                            }}
                                                            pull="right"
                                                        />
                                                    </>
                                                ) :
                                                (canEditAllParams && secondManagerName !== null && company?.data?.whose_company2_confirmation_date) ? (
                                                        <>
                                                            <button onClick={() => unbindCompanyMainManagerFunc()}
                                                                    style={{marginLeft: '5px'}}>x
                                                            </button>
                                                        </>
                                                    )
                                                    : secondManagerName !== null && company?.data?.whose_company2_confirmation_date == null && currentManagerID === 44
                                                        ? (
                                                            <FontAwesomeIcon
                                                                icon={faBan}
                                                                color='red'
                                                                onClick={handleClickOpenShareWindow}
                                                                pull="right"
                                                            />
                                                        ) : ''
                                            }
                                        </>
                                    ) : canEditAllParams ? (
                                        <FontAwesomeIcon
                                            icon={faPlus}
                                            color="green"
                                            onClick={() => {
                                                setSelected1Manager(mainManagerName);
                                                setCompanyManager1(1);
                                            }}
                                            pull="right"
                                        />
                                    ) : ''
                                    }
                                </div>
                                {secondManagerName ?
                                    company?.data?.whose_company2_expire_date ?

                                        <div>
                                            <b>Главный: </b> {secondManagerName}
                                        </div>
                                        : <div><b>Пока ведущий: </b> {secondManagerName}
                                        </div>
                                    : ''}
                            </div>
                            {company.data.edrpou !== null ? (
                                <div>
                                    <b>ЕДРПОУ: </b>
                                    {company.data.edrpou}
                                    <CopyToClipboardButton textToCopy={company.data.edrpou}/>


                                    {canEditAllParams ? (
                                        <FontAwesomeIcon
                                            icon={faEdit}
                                            color='green'
                                            onClick={() => {
                                                setEdrpouVal(company.data.edrpou);
                                                setEdrpou(1);
                                            }}
                                            pull="right"
                                            className="s.faIcon"
                                        />
                                    ) : null}
                                    <FontAwesomeIcon
                                        icon={faInfo}
                                        color='blue'
                                        onClick={() => {
                                            window.open('https://youcontrol.com.ua/catalog/company_details/' + company.data.edrpou)
                                        }}
                                        pull="right"
                                        className="s.faIcon"
                                        style={{marginRight: '5px'}}
                                    />
                                </div>
                            ) : canEditAllParams ? (
                                <div>
                                    <b>ЕДРПОУ: </b>
                                    <FontAwesomeIcon
                                        icon={faPlus}
                                        color='green'
                                        onClick={() => setEdrpou(1)}
                                        pull="right"
                                    />
                                </div>
                            ) : ""}
                            <div>
                                <b>Сайт: </b>
                                {company?.data?.site != ''
                                    ? (
                                        <>
                                            {isWebsiteValid(company?.data?.site) ? (
                                                <a href={company?.data?.site} target="_blank" rel="noopener noreferrer">
                                                    {company?.data?.site}
                                                </a>
                                            ) : (
                                                company?.data?.site
                                            )}
                                            {canEditAllParams ? (
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                    color='green'
                                                    onClick={() => {
                                                        setSiteVal(company?.data?.site);
                                                        setSite(1);
                                                    }}
                                                    pull="right"
                                                    className="s.faIcon"
                                                />
                                            ) : null}
                                        </>
                                    )
                                    : (
                                        <>
                                            {canEditAllParams ? (

                                                    <FontAwesomeIcon
                                                        icon={faPlus}
                                                        color='green'
                                                        onClick={() => setSite(1)}
                                                        pull="right"
                                                    />
                                                )
                                                : ''
                                            }
                                        </>
                                    )
                                }
                            </div>
                            <div>
                                <b>Скидка: </b>
                                {company?.data?.discount ? (
                                    <>
                                        {`${company.data.discount}%`}
                                        {company.data.discount_date_end ? ` (до ${company.data.discount_date_end})` : ""}
                                        {canEditAllParams ?
                                            (
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                    color="green"
                                                    onClick={() => {
                                                        setCompanyDiscountPercentVal(company.data.discount);
                                                        setCompanyDiscountDateVal(company.data.discount_date_end ?? '');
                                                        setCompanyDiscount(1);
                                                    }}
                                                    pull="right"
                                                />
                                            ) : ''}
                                    </>
                                ) : (canEditAllParams ?
                                        (
                                            <FontAwesomeIcon
                                                icon={faPlus}
                                                color='green'
                                                onClick={() => setCompanyDiscount(1)}
                                                pull="right"
                                            />
                                        ) : ''
                                )}
                            </div>
                            <div>
                                <b>Отрасль: </b>

                                {(company?.data?.company_segmentation != null && company?.data?.company_segmentation !== 0)
                                    ? <>
                                        {company?.data?.company_segmentation?.name}
                                        {canEditAllParams ?
                                            <FontAwesomeIcon
                                                icon={faEdit}
                                                color="green"
                                                onClick={() => {
                                                    setCompanySegmentationVal(company?.data?.company_segmentation);
                                                    setCompanySegmentation(1);
                                                }
                                                }
                                                pull="right"
                                            />
                                            : ''
                                        }
                                    </>
                                    : canEditAllParams
                                        ? (
                                            <FontAwesomeIcon
                                                icon={faPlus}
                                                color='green'
                                                onClick={() => setCompanySegmentation(1)}
                                                pull="right"
                                            />
                                        )
                                        : ""
                                }

                            </div>
                            <div>
                                <b>Категория: </b>
                                {(company?.data?.p_client_segmentation != null && company?.data?.p_client_segmentation != 0)
                                    ? <>
                                        {company?.data?.client_segmentation?.name}
                                        {canEditAllParams ?
                                            <FontAwesomeIcon
                                                icon={faEdit}
                                                color="green"
                                                onClick={() => {
                                                    setClientCompanySegmentationVal(company?.data?.p_client_segmentation);
                                                    setClientCompanySegmentation(1);
                                                }
                                                }
                                                pull="right"
                                            />
                                            : ''
                                        }
                                    </>
                                    : canEditAllParams
                                        ? (
                                            <FontAwesomeIcon
                                                icon={faPlus}
                                                color='green'
                                                onClick={() => setClientCompanySegmentation(1)}
                                                pull="right"
                                            />
                                        )
                                        : ""
                                }

                            </div>

                            <div>
                                <b>Новые отрасли: </b>
                                {company?.additional_data?.new_industries ? (
                                    <>
                                        {company?.additional_data?.new_industries?.map((item, index) => {

                                            return (
                                                <React.Fragment key={index}>
                                                    {canEditAllParams ? (
                                                        <>
                                                            <Chip
                                                                label={item?.name ?? ''}
                                                                onClick={() => {
                                                                    console.log(item);
                                                                }}
                                                                onDelete={() => {
                                                                    setNewIndustryIDVal(item.id);
                                                                    setDeleteIndustry(true);
                                                                }}
                                                                style={{margin: '1px'}}
                                                            />
                                                        </>
                                                    ) : (
                                                        <Chip
                                                            label={item?.name ?? ''}
                                                            onClick={() => {
                                                                console.log(item);
                                                            }}
                                                            style={{margin: '1px'}}
                                                        />
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <></>
                                )}
                                {canEditAllParams && (
                                    <Chip
                                        icon={<PlusIcon/>}
                                        label="Добавить"
                                        onClick={() => {
                                            setAddNewIndustry(true);
                                        }}
                                        style={{margin: '2px'}}
                                        color="success"
                                        variant="outlined"
                                    />
                                )}
                            </div>
                            <div>
                                <b>Новые категории: </b>
                                {company?.additional_data?.new_categories ? (
                                    <>
                                        {company?.additional_data?.new_categories?.map((item, index) => {

                                            return (
                                                <React.Fragment key={index}>
                                                    {canEditAllParams ? (
                                                        <>
                                                            <Chip
                                                                label={item?.name ?? ''}
                                                                onClick={() => {
                                                                    console.log(item);
                                                                }}
                                                                onDelete={() => {
                                                                    setNewCategoryIDVal(item?.id);
                                                                    setDeleteCategory(true);
                                                                }}
                                                                style={{margin: '1px'}}
                                                            />
                                                        </>
                                                    ) : (
                                                        <Chip
                                                            label={item?.name ?? ''}
                                                            onClick={() => {
                                                                console.log(item);
                                                            }}
                                                            style={{margin: '1px'}}
                                                        />
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <></>
                                )}
                                {canEditAllParams && (
                                    <Chip
                                        icon={<PlusIcon/>}
                                        label="Добавить"
                                        onClick={() => {
                                            setAddNewCategory(true);
                                        }}
                                        style={{margin: '2px'}}
                                        color="success"
                                        variant="outlined"
                                    />
                                )}
                            </div>

                            <div>
                                <b>Связи компании: </b>
                                {canEditAllParams ? (
                                        <FontAwesomeIcon
                                            icon={faPlus}
                                            color='green'
                                            onClick={() => setCompanyPartner(1)}
                                            pull="right"
                                        />
                                    )
                                    : ""
                                }
                                <div title={"Такие же компании"}><b>Партнёры: </b>
                                    {company?.data?.company_support?.filter(item => item.type === 1).length} шт.
                                    <FontAwesomeIcon icon={faEye} color='green'
                                                     onClick={() => setСompanyAliasListModal(1)}
                                                     pull="right"/>

                                </div>
                                <div>
                                    <b>Такие же: </b>
                                    {company?.data?.company_support?.filter(item => item.type === 2).length} шт.
                                    <FontAwesomeIcon icon={faEye} color='green'
                                                     onClick={() => setСompanyPartnersListModal(1)}
                                                     pull="right"/>

                                </div>
                            </div>
                            <div>
                                <b>Область: </b>
                                {
                                    company?.data?.region_id == null
                                        ? ''
                                        : company?.data?.region?.regions
                                }

                                {canEditAllParams ?
                                    (company?.data?.region_id != null
                                        ? (<FontAwesomeIcon icon={faEdit} color='green' onClick={() => {
                                            setCompanyRegionVal(company?.data?.region_id);
                                            setCompanyRegion(1);
                                        }} pull="right"/>)
                                        : (<FontAwesomeIcon icon={faPlus} color='green'
                                                            onClick={() => setCompanyRegion(1)}
                                                            pull="right"/>)) : ''
                                }

                            </div>
                            {company?.data?.region?.koatuu_id ?
                                <div>
                                    <b>Город: </b>
                                    {
                                        company?.data?.city == null
                                            ? ''
                                            : toTitleCase(company?.data?.city?.name)
                                    }

                                    {canEditAllParams ?
                                        (company?.data?.city != null
                                            ? (<FontAwesomeIcon icon={faEdit} color='green' onClick={() => {
                                                props.getCityList(company?.data?.region?.koatuu_id)
                                                setCompanyCity(1);
                                            }} pull="right"/>)
                                            : (<FontAwesomeIcon icon={faPlus} color='green'
                                                                onClick={() => {
                                                                    props.getCityList(company?.data?.region?.koatuu_id)
                                                                    setCompanyCity(1)
                                                                }
                                                                }
                                                                pull="right"/>)) : ''
                                    }

                                </div>
                                : ''}
                            <div><b>Договор: </b>
                                {company?.data?.contract}
                                {canEditAllParams ?
                                    (company?.data?.contract
                                        ? (<FontAwesomeIcon icon={faEdit} color='green' onClick={() => {
                                            setCompanyContractVal(company.data.contract);
                                            setCompanyContract(1);
                                        }} pull="right"/>)
                                        : (<FontAwesomeIcon icon={faPlus} color='green'
                                                            onClick={() => setCompanyContract(1)}
                                                            pull="right"/>)) : ''
                                }
                            </div>
                            <div>
                                <b>Адрес доставки НП: </b>
                                {canEditAllParams ?
                                    (
                                        company?.data?.delivery_address
                                            ? (<FontAwesomeIcon icon={faEdit} color='green' onClick={() => {
                                                setCompanyDeliveryAddressVal(company.data.delivery_address);
                                                setCompanyDeliveryAddress(1);
                                            }} pull="right"/>)
                                            : (<FontAwesomeIcon icon={faPlus} color='green'
                                                                onClick={() => setCompanyDeliveryAddress(1)}
                                                                pull="right"/>)
                                    ) : ''
                                }
                                <LongText text={company?.data?.delivery_address} maxLength={50}/>

                            </div>

                            <div><b>Комментарий: </b>
                                {company?.data?.description?.message
                                    ? (
                                        <>
                                            <LongText text={company?.data?.description?.message} maxLength={50}/>
                                            {canEditAllParams ?
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                    color="green"
                                                    onClick={() => {
                                                        setCompanyCommentVal(company?.data?.description?.message);
                                                        setCompanyComment(1);
                                                    }}
                                                    pull="right"
                                                />
                                                : ''}
                                        </>
                                    )
                                    : canEditAllParams ? (
                                        <FontAwesomeIcon
                                            icon={faPlus}
                                            color='green'
                                            onClick={() => setCompanyComment(1)}
                                            pull="right"
                                        />
                                    ) : ""
                                }
                            </div>
                            <div><b>Поставщики: </b>
                                {company?.data?.suppliers
                                    ? (
                                        <
                                        >
                                            {company?.data?.suppliers?.map((item, index) => (

                                                canEditAllParams
                                                    ?
                                                    <Chip
                                                        key={index}
                                                        label={item?.supplier?.supplier}
                                                        onClick={() => {
                                                            console.log(item)
                                                        }}
                                                        onDelete={() => {
                                                            setSupplierIDVal(item?.supplier)
                                                            setDeleteSupplier(true)
                                                        }}
                                                        style={{margin: '1px'}}
                                                    />
                                                    :
                                                    <Chip
                                                        key={index}
                                                        label={item?.supplier?.supplier}
                                                        onClick={() => {
                                                            console.log(item)
                                                        }}
                                                        style={{margin: '1px'}}
                                                    />

                                            ))
                                            }
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                        </>
                                    )

                                }
                                {canEditAllParams
                                    ? <Chip
                                        icon={<PlusIcon/>}
                                        label='Добавить'
                                        onClick={() => {
                                            setAddSupplier(true)
                                        }}
                                        style={{margin: '2px'}}
                                        color="success"
                                        variant="outlined"
                                    />
                                    : ''
                                }
                            </div>
                            <div>
                                <b>Теги: </b>
                                {company?.data?.p_company_segmentation_wants ? (
                                    <>
                                        {company?.data?.p_company_segmentation_wants?.split(',')?.map((item, index) => {
                                            const tag = company?.additional_data?.tags_list?.find(tag => tag.id.toString() === item.trim());

                                            return (
                                                <React.Fragment key={index}>
                                                    {canEditAllParams ? (
                                                        <>
                                                            <Chip
                                                                label={tag?.name ?? ''}
                                                                onClick={() => {
                                                                    console.log(item);
                                                                }}
                                                                onDelete={() => {
                                                                    setTagIDVal(item);
                                                                    setDeleteTag(true);
                                                                }}
                                                                style={{margin: '1px'}}
                                                            />
                                                        </>
                                                    ) : (
                                                        <Chip
                                                            label={tag?.name ?? ''}
                                                            onClick={() => {
                                                                console.log(item);
                                                            }}
                                                            style={{margin: '1px'}}
                                                        />
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <></>
                                )}
                                {canEditAllParams && (
                                    <Chip
                                        icon={<PlusIcon/>}
                                        label="Добавить"
                                        onClick={() => {
                                            setAddTag(true);
                                        }}
                                        style={{margin: '2px'}}
                                        color="success"
                                        variant="outlined"
                                    />
                                )}
                            </div>

                        </div>
                        <div style={{border: '0px !important'}}>
                            <div className={s.tabContainer} style={{textAlign: 'center'}}>
                                <ButtonGroup variant="contained" aria-label="outlined primary button group">

                                    <button
                                        className={s.tabButton + `${activeTab === 3 ? 'active' : ''}`}
                                        style={{marginRight: '5px'}}
                                        onClick={() => handleTabClick(3)}
                                    >
                                        История общения ({messagesList?.length ?? 0} шт.)
                                    </button>
                                    <button
                                        className={s.tabButton + `${activeTab === 1 ? 'active' : ''}`}
                                        style={{marginRight: '5px'}}
                                        onClick={() => handleTabClick(1)}
                                    >
                                        Активность ({(orderRows?.length) ?? 0} шт.)
                                    </button>
                                    <button
                                        className={s.tabButton + `${activeTab === 2 ? 'active' : ''}`}
                                        style={{marginRight: '5px'}}
                                        onClick={() => handleTabClick(2)}
                                    >
                                        Контакты ({company?.data?.agents?.length ?? 0} шт.)
                                    </button>
                                    <button
                                        className={s.tabButton + `${activeTab === 5 ? 'active' : ''}`}
                                        style={{marginRight: '5px'}}
                                        onClick={() => handleTabClick(5)}
                                    >
                                        Статистика
                                    </button>
                                    {leadRows.length > 0 ?
                                        <button
                                            className={s.tabButton + `${activeTab === 4 ? 'active' : ''}`}
                                            style={{marginRight: '5px'}}
                                            onClick={() => handleTabClick(4)}
                                        >
                                            Покупки оВна ({leadRows?.length ?? 0} шт.)
                                        </button>
                                        : ''
                                    }
                                    <button onClick={() => {
                                        setNewCompanyMessageVal('Недозвон')
                                        setNewCompanyMessage(1)
                                    }
                                    }>Недозвон
                                    </button>
                                    <IconButton variant="outlined" color="neutral" sx={{mr: 'auto'}}
                                                style={{marginRight: '5px'}}>
                                        <AddCommentIcon onClick={() => {
                                            setNewCompanyMessage(1)
                                        }
                                        }/>
                                    </IconButton>
                                    <IconButton
                                        variant="outlined" color="neutral" sx={{mr: 'auto'}}
                                        style={{marginRight: '5px'}}
                                    >
                                        {company?.data?.checkToPlanner == null
                                            ? <FontAwesomeIcon icon={faCalendar}
                                                               onClick={() => setPlanner(company?.data?.id)}/>
                                            :
                                            <span
                                                onClick={() => setPlanner(company?.data?.id)}>
                                                <FontAwesomeIcon icon={faCalendar}
                                                                 onClick={() => setPlanner(company?.data?.id)}
                                                                 style={{marginRight: '3px'}}/>
                                                <AnyFormatDate date={company?.data?.date_plan}/>
                                            </span>
                                        }
                                    </IconButton>


                                    <IconButton variant="outlined" color="neutral" sx={{mr: 'auto'}}
                                                style={{marginRight: '5px'}}>
                                        <PersonAddAltIcon onClick={() => {
                                            setNewCompanyContact(1)
                                        }
                                        }/>
                                    </IconButton>
                                    {/*<a href="https://www.akon.com.ua/admin/addNewCheck/index.php" target="_blank"><IconButton variant="outlined" color="neutral" sx={{mr: 'auto'}}
                                                style={{marginRight: '5px'}}>
                                        Счёт
                                    </IconButton></a>*/}
                                    <NavLink to={"/kp/add/" + currentManagerID} target="_blank"><IconButton
                                        variant="outlined" color="neutral" sx={{mr: 'auto'}}
                                        style={{marginRight: '5px'}}>
                                        Счет/КП
                                    </IconButton></NavLink>
                                    <NavLink to={"/sales/meeting/" + currentManagerID} target="_blank"><IconButton
                                        variant="outlined" color="neutral" sx={{mr: 'auto'}}
                                        style={{marginRight: '5px'}}>
                                        Встреча
                                    </IconButton>
                                    </NavLink>

                                </ButtonGroup>
                            </div>

                            {activeTab === 1 &&
                                <>

                                    <TableContainer component={Paper}>
                                        <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Код</TableCell>
                                                    <TableCell align="right">Название</TableCell>
                                                    <TableCell align="right">Статус оплаты</TableCell>
                                                    <TableCell align="right">Сумма</TableCell>
                                                    <TableCell align="right">Дата</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>


                                                {(rowsPerPage > 0
                                                        ? orderRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        : orderRows
                                                ).map((row, index) => (

                                                    <TableRow
                                                        key={index}
                                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {row?.link !== null
                                                                ? <a href={row?.link} target={'_blank'}>{row?.id}</a>
                                                                : row?.id
                                                            }
                                                        </TableCell>
                                                        <TableCell align="right"><a href={row?.link}
                                                                                    target={'_blank'}>{row?.name}</a></TableCell>
                                                        <TableCell align="right"><span
                                                            style={{color: row?.color}}>{row?.status}</span></TableCell>
                                                        <TableCell align="right">
                                                            <CurrencyFormatter
                                                                amount={row?.summ ?? 0}
                                                                withSpaces={true}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="right">{row?.date}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                            <TableFooter className={s.disableBorder}>
                                                <TableRow>
                                                    <TablePagination
                                                        rowsPerPageOptions={[15, 25, {label: 'Все', value: -1}]}
                                                        colSpan={3}
                                                        count={orderRows.length}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        SelectProps={{
                                                            inputProps: {
                                                                'aria-label': 'rows per page',
                                                            },
                                                            native: true,
                                                        }}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                        ActionsComponent={TablePaginationActions}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </TableContainer>
                                </>

                            }
                            {activeTab === 2 && <>
                                <Grid container spacing={{xs: 1, sm: 1, md: 2}} columnSpacing={{xs: 1, sm: 1, md: 2}}>
                                    {company?.data?.agents.map((agent, index) => (
                                        <Grid item key={index} md={12} xl={4} xs={12} sm={12}>
                                            <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                                                <CompanyAgent
                                                    agentID={agent?.id}
                                                    agentName={agent?.name}
                                                    agentPhone={agent?.tell}
                                                    agentPhone2={agent?.tell2}
                                                    agentPhone3={agent?.tell3}
                                                    canEdit={canEditAllParams}
                                                    agentEmail={agent?.email}
                                                    agentSubscribed={agent?.email_active}
                                                    agentVacancy={agent?.position}
                                                    additionalPhones={agent?.additional_phones}
                                                    currentManagerID={currentManagerID}
                                                    {...props}
                                                />

                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>

                            </>
                            }
                            {activeTab === 3 &&
                                <>
                                    {messagesList.map((message, i) => (
                                        <Card key={i} variant='outlined' style={{
                                            margin: '0px',
                                            backgroundColor: (message.type === 'message' ? "white" : 'lightcyan'),
                                            textAlign: message?.own_thoughts === 1 ? 'end' : 'left'
                                        }}>
                                            <CardContent>
                                                <Typography level="title-lg">
                                                    {message?.own_thoughts === 1
                                                        ? <>
                                                            мысли <b> {message?.from ?? 'xx'}</b>
                                                        </>
                                                        :
                                                        <>
                                                            Общался
                                                            <b> {message?.from ?? 'xx'}</b> <>с <b>
                                                            {message?.agent && <HtmlTooltip
                                                                title={<React.Fragment>
                                                                    <em>
                                                                        {company?.data?.agents?.filter(
                                                                            (item) => item.id === message.agent_id
                                                                        ).map((matchingAgent) => {
                                                                            return (
                                                                                <>
                                                                                    <br/>
                                                                                    <b>Имя:</b> {message.agent} <br/>
                                                                                    <b>email:</b> {matchingAgent?.email}
                                                                                    <br/>
                                                                                    <b>Телефон:</b> {matchingAgent?.tell}
                                                                                    <br/>
                                                                                    {matchingAgent?.tell2 ? <><b>Телефон
                                                                                        2:</b> matchingAgent?.tell2 <br/></> : ''}
                                                                                    {matchingAgent?.tell3 ? <><b>Телефон
                                                                                        3:</b> matchingAgent?.tell3 <br/></> : ''}
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </em>
                                                                </React.Fragment>}
                                                            >{message.agent}</HtmlTooltip>
                                                            }

                                                        </b>
                                                        </>
                                                            ({message.date})
                                                            {message.type === 'message'
                                                                ? <MessageIcon/>//icon for message
                                                                : <GroupsIcon/> //icon for meeting
                                                            }
                                                            {message?.theme?.length > 0 ? <><br/><b>Тема:</b> {message.theme} <br/></> : ''}

                                                        </>
                                                    }
                                                </Typography>
                                                <Typography level="body-lg">
                                                    <LongText text={message.message} maxLength={150}/> <br/>
                                                    {message?.fileUrl !== null && message?.fileUrl !== undefined && message?.fileUrl !== ''
                                                        ? (allowedAudioTypes.includes(message?.fileUrl?.extension)) //mp3, wav, ogg formats
                                                            ?
                                                            <audio controls>
                                                                <source src={message?.fileUrl.url} type="audio/mp3"/>
                                                                Обновите браузер
                                                            </audio>
                                                            : <a href={message?.fileUrl?.url} target="_blank"
                                                                 rel="noopener noreferrer">
                                                                Скачать файл {message?.fileUrl?.extension}
                                                            </a>
                                                        : ""}
                                                </Typography>
                                            </CardContent>
                                            <CardActions buttonFlex="0 0 80px">
                                                {canEditAllParams && message.type === 'message' ? (
                                                    <ButtonGroup variant="contained"
                                                                 aria-label="outlined primary button group">
                                                        <IconButton variant="outlined" color="neutral"
                                                                    sx={{mr: 'auto'}}>
                                                            <EditIcon onClick={() => {
                                                                setCompanyMessage(1)
                                                                setCompanyMessageIDVal(message.id)
                                                                setCompanyMessageVal(message.message)
                                                            }
                                                            }/>
                                                        </IconButton>
                                                        <IconButton variant="outlined" color="neutral"
                                                                    sx={{mr: 'auto'}}>
                                                            <DeleteIcon
                                                                onClick={() => deleteMessage(message.id)}
                                                            />
                                                        </IconButton>
                                                    </ButtonGroup>
                                                ) : (
                                                    ""
                                                )}
                                            </CardActions>
                                        </Card>
                                    ))
                                    }
                                </>
                            }
                            {
                                activeTab === 4 &&
                                <>
                                    <TableContainer component={Paper}>
                                        <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="right">Название</TableCell>
                                                    <TableCell align="right">Раздел</TableCell>
                                                    <TableCell align="right">Дата</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {(rowsPerPage > 0
                                                        ? leadRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        : leadRows
                                                ).map((row, index) => (

                                                    <TableRow
                                                        key={index}
                                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {row?.link !== null && row?.link !== ''
                                                                ? <><LongText
                                                                    text={row?.name}
                                                                    maxLength={60}
                                                                />
                                                                    &nbsp;&nbsp;<b><a href={row?.link}
                                                                                      target={'_blank'}>
                                                                        САЙТ
                                                                    </a></b>
                                                                </>
                                                                : row?.id
                                                            }
                                                        </TableCell>
                                                        <TableCell align="right">{row?.section}</TableCell>
                                                        <TableCell align="right">{row?.date}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                            <TableFooter className={s.disableBorder}>
                                                <TableRow>
                                                    <TablePagination
                                                        rowsPerPageOptions={[15, 25, {label: 'Все', value: -1}]}
                                                        colSpan={3}
                                                        count={leadRows.length}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        SelectProps={{
                                                            inputProps: {
                                                                'aria-label': 'записей на странице',
                                                            },
                                                            native: true,
                                                        }}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                        ActionsComponent={TablePaginationActions}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </TableContainer>
                                </>
                            }
                            {
                                activeTab === 5 &&
                                <>
                                    <StatsComponent
                                        stats={company?.additional_data?.stats}
                                    />
                                </>
                            }

                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default CompanyProfile;
