import React from 'react';
import { connect } from 'react-redux';
import {getPersonalRepairsList} from '../../../../../redux/repair-reducer';
import ModulsList from './ModulsList';

class ModulsListContainer extends React.Component{
    componentDidMount(){
        this.props.getPersonalRepairsList(this.props.id);
    }
    
    render(){
        return (
            <div>
                <ModulsList personalRepairsList={this.props.personalRepairsList} setShow={this.props.setShow} />
            </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    personalRepairsList: state.repair.personalRepairsList,
})
export default connect( mapStateToProps, {getPersonalRepairsList})(ModulsListContainer);