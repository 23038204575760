import React, { useState } from 'react';
import s from '../s.module.css';
import main from '../../../Main.module.css'

const Form = (props) =>{

    let [name, setName] = useState("")
    const setNameF=(e)=>{setName(e.target.value)}
    let [description, setDescription] = useState("")
    const setDescriptionF=(e)=>{setDescription(e.target.value)}
    const add=()=>{
        let array = []
        array.push(name, description)
        props.addNewProject(array)
        props.setActive(false)
    }
    return(
        <div className={main.center}>
            <h2>Добавляем новый проект</h2>
            <input type="text" placeholder='Название проекта' value={name} onChange={setNameF} className={main.input} />
            <textarea value={description} onChange={setDescriptionF} placeholder='Описание проекта' className={main.textarea}></textarea>
            <input type="button" value={"Добавить"} onClick={()=>add()} className={main.inputAdd} />
        </div>
        
    )
}
export default Form;