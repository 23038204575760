import React, { useState, useEffect } from 'react';
import s from './ChannelChains.module.css';
import main from '../../../Main.module.css';
import { NavLink } from 'react-router-dom';
import AddNomenclatureParam from './common/AddNomenclatureParam'
import Modal from '../../../Production/common/Modal/Modal';
import Menu from '../Menu/Menu';

const ChannelChains = (props) =>{
    console.log(props.allNomType)
    let [allChannelChainsMain, setAllChannelChainsMain] = useState([])
    let [allType, setAllType] = useState([])
    useEffect(() => {
        setAllType([...new Set(props.allNomType.map(k=>k.name_ua))])
    }, [props.allNomType])
    let [allSignalType, setAllSignalType] = useState([])
    let [allRange, setAllRange] = useState([])
    let [allWire, setAllWire] = useState([])
    useEffect(() => {
        setAllChannelChainsMain(props.allChannelChains);
        // setAllType([...new Set(props.allChannelChains.map(k=>k.typeName))])
    }, [props.allChannelChains])
    //выбранные значения в выпадающем списке
    // let [allTypeSelect, setAllTypeSelect] = useState("-1")
    let [allSignalTypeSelect, setAllSignalTypeSelect] = useState("-1")
    let [allRangeSelect, setAllRangeSelect] = useState("-1")
    let [allWireSelect, setAllWireSelect] = useState("-1")

    //выбранные параметры цепочки
    let [typeNow, setTypeNow] = useState(false)
    const setTypeNowF = (name)=>{
        setAllSignalType([]);setAllRange([]);setAllWire([]);//скидываем все массивы по цепочке после выбранного элемента
        setSignalTypeNow(false);setRangeNow(false);setWireNow(false)//скидываем все выбранные параметры
        setChannelCheck("disabled")//скидываем параметр на кнопке записи цепочки
        setAllSignalTypeSelect("-1");setAllRangeSelect("-1");setAllWireSelect("-1")//сбрасываем выпадающие списки
        setTypeNow(name)
        let newArray = []
        console.log(allChannelChainsMain)
        newArray = allChannelChainsMain?.filter(obj => obj?.typeName === name);
        newArray = [...new Set(newArray?.map(k=>k.signalTypeName))];
        setAllSignalType(newArray)
    }
    let [signalTypeNow, setSignalTypeNow] = useState(false)
    const setSignalTypeNowF = (name)=>{
        setAllRange([]);setAllWire([])//скидываем все массивы по цепочке после выбранного элемента
        setSignalTypeNow(false);setRangeNow(false);setWireNow(false)//скидываем все выбранные параметры
        setChannelCheck("disabled")//скидываем параметр на кнопке записи цепочки
        setAllRangeSelect("-1");setAllWireSelect("-1")//сбрасываем выпадающие списки
        setSignalTypeNow(name)
        let newArray = []
        newArray = allChannelChainsMain.filter(obj => obj.signalTypeName === name && obj.typeName === typeNow);
        newArray = [...new Set(newArray.map(k=>k.rangeName))];
        setAllRange(newArray)
    }
    //если выбор был из списка
    const setSignalTypeNowFNewValue = (e)=> {
        setSignalTypeNowF(e.target.value)
        setAllSignalTypeSelect(e.target.value)
    }
    let [rangeNow, setRangeNow] = useState(false)
    const setRangeNowF = (name)=>{
        setAllWire([])//скидываем все массивы по цепочке после выбранного элемента
        setWireNow(false)//скидываем все выбранные параметры
        setChannelCheck("disabled")//скидываем параметр на кнопке записи цепочки
        setAllWireSelect("-1")//сбрасываем выпадающие списки
        setRangeNow(name)
        let newArray = []
        newArray = allChannelChainsMain.filter(obj => obj.rangeName === name && obj.signalTypeName === signalTypeNow && obj.typeName === typeNow);
        newArray = [...new Set(newArray.map(k=>k.wireName))];
        setAllWire(newArray)
    }
    //если выбор был из списка
    const setRangeNowFNewValue = (e)=> {
        setRangeNowF(e.target.value)
        setAllRangeSelect(e.target.value)
    }
    let [wireNow, setWireNow] = useState(false)
    const setWireNowF = (name)=>{
        setWireNow(name)
        //проверяем цепочку, если такая есть выводим сообщение, если нет даём возможность записать
        channelChainCheck(name)
    }
    //если выбор был из списка
    const setWireNowFNewValue = (e)=> {
        setWireNowF(e.target.value)
        setAllWireSelect(e.target.value)
    }
    //проверяем цепочку, если такой нет, активируем кнопку
    const channelChainCheck=(name)=>{
        let res = props.allChannelChains.find(k=>k.typeName === typeNow && k.signalTypeName === signalTypeNow && k.rangeName === rangeNow && k.wireName === name)
        if(!res){
            setChannelCheck("")
        }else{
            setChannelCheck("disabled")
        }
    }
    //всплывающее окно добавления нового параметра
    let [showAdd, setShowAdd] = useState(false)
    //сообщение после проверки цепочки на наличие
    let [channelCheck, setChannelCheck] = useState("disabled")

    const setNewChannelCheckF = ()=>{
        let newType = props.allNomType.find(k=>k.name_ua===typeNow).id
        let newSignalType = props.allNomSignalType.find(k=>k.NAME_UA===signalTypeNow).ID
        let newRange = props.allNomRange.find(k=>k.NAME_UA===rangeNow).ID
        let newWire = props.allNomWire.find(k=>k.name_ua===wireNow).id
        let array = []; array.push(newType, newSignalType, newRange, newWire)
        props.setNewChannelCheck(array)
        setAllSignalType([]);setAllRange([]);setAllWire([])//скидываем все массивы по цепочке после выбранного элемента
        setSignalTypeNow(false);setRangeNow(false);setWireNow(false)//скидываем все выбранные параметры
        setChannelCheck("disabled")//скидываем параметр на кнопке записи цепочки
        setAllSignalTypeSelect("-1");setAllRangeSelect("-1");setAllWireSelect("-1")//сбрасываем выпадающие списки
    }
    return(
        <div>
            {showAdd &&
            <Modal active={showAdd} setActive={setShowAdd}>
                <AddNomenclatureParam active={showAdd} setActive={setShowAdd} addNomenclatureParam={props.addNomenclatureParam}/>
            </Modal>
            }
            <Menu />
            <h1>Цепочки каналов фьючер АКОН</h1>
            <div className={channelCheck!="disabled" ? s.saveButtonActive : s.saveButtonNonActive}><button disabled={channelCheck} onClick={()=>setNewChannelCheckF()}>Записать</button></div>
            <div className={s.main+" "+main.bold}>
                <div>Тип канала <span onClick={()=>setShowAdd(1)} className={s.plus}>+</span></div>
                <div>Тип сигнала <span onClick={()=>setShowAdd(2)} className={s.plus}>+</span></div>
                <div>Диапазон <span onClick={()=>setShowAdd(3)} className={s.plus}>+</span></div>
                <div>Схема подключения <span onClick={()=>setShowAdd(4)} className={s.plus}>+</span></div>
            </div>
            <div className={s.main}>
                <div>
                    {allType.map((k,index)=>
                        <div key={"allType"+index} onClick={()=>setTypeNowF(k)} className={typeNow===k ? s.active : ""}>{k}</div>)
                    }
                </div>
                <div>
                    {typeNow && allSignalType.map(k=> 
                        <div key={"allSignalType"+k} onClick={()=>setSignalTypeNowF(k)} className={signalTypeNow===k ? s.active : ""}>{k}</div>)
                    }
                    {typeNow && <select value={allSignalTypeSelect} onChange={setSignalTypeNowFNewValue}>
                        <option value={"-1"}>----</option>
                        {props.allNomSignalType.map(k=><option id={k.ID} key={"allNomSignalTypeOption"+k.ID}>{k.NAME_UA}</option>)}
                    </select>}
                </div>
                <div>
                    {signalTypeNow && allRange.map(k=> <div key={"allRange"+k} onClick={()=>setRangeNowF(k)} className={rangeNow===k ? s.active : ""}>{k}</div>)
                    }
                    {signalTypeNow && <select value={allRangeSelect} onChange={setRangeNowFNewValue}>
                        <option value={"-1"}>----</option>
                        {props.allNomRange.map(k=><option id={k.ID} key={"allNomSignalTypeOption"+k.ID}>{k.NAME_UA}</option>)}
                    </select>}
                </div>
                <div>
                    {rangeNow && allWire.map(k=> <div key={"allWire"+k} onClick={()=>setWireNowF(k)} className={wireNow===k ? s.active : ""}>{k}</div>)
                    }
                    {rangeNow && <select value={allWireSelect} onChange={setWireNowFNewValue}>
                        <option value={"-1"}>----</option>
                        {props.allNomWire.map(k=><option id={k.id} key={"allNomSignalTypeOption"+k.id}>{k.name_ua}</option>)}
                    </select>}
                </div>
            </div>
        </div>
    )
    
}
export default ChannelChains;