import React, { useState } from 'react';
import s from '../Task.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faComment } from '@fortawesome/free-regular-svg-icons'
import { faArrowUp, faPencilAlt, faProjectDiagram, faTrash } from '@fortawesome/free-solid-svg-icons'
import AnyFormatDate from '../../Production/common/AnyFormatDate/AnyFormatDate';
import Modal from '../../Production/common/Modal/Modal';
import ChangeDate from '../Modal/ChangeDate';
import ChangeTextTask from '../Modal/ChangeTextTask';

const ShowTask = (props) =>{
    let id = props.id
    const getStyle = (type)=>{
        switch(type){
            case "2": return s.main+" "+s.dopMain;
            case "3": return s.main+" "+s.archive;
            default: return s.main;
        }
    }
    //установить напоминание на определённую дату
    let [reminder, setReminder] = useState(false);
    let [dateReminder, setDateReminder] = useState("");
    const setDateReminderF = (e)=>{
        setDateReminder(e.target.value)
        console.log(dateReminder)
    }
    const dateReminderF = ()=>{
        props.setDateReminder(reminder, dateReminder, props.personalId)
        setReminder(false)
    }
    let [changeDate, setChangeDate] = useState(false)
    let [changeDateType, setChangeDateType] = useState(false)
    const setChangeDateF=(id,type)=>{
        setChangeDate(id)
        setChangeDateType(type)
    }
    let [changeTextTask, setChangeTextTask] = useState(false)
    return(<>
        {reminder &&
        <Modal active={reminder} setActive={setReminder}>
            <div>
                <h3 className={s.center}>Дата напоминания задачи №{reminder}</h3>
                <div className={s.twoBlock}>
                    <div className={s.mt10}>Напомнить: </div>
                    <div><input type="date" className={s.input} value={dateReminder} onChange={setDateReminderF} /></div>
                </div>
                <div className={s.center}><button onClick={()=>dateReminderF()}>Отправить</button></div>
            </div>
        </Modal>
        } 
        {changeDate &&
        <Modal active={changeDate} setActive={setChangeDate}>
            <ChangeDate active={changeDate} setActive={setChangeDate} changeDateType={changeDateType} changeDateForTask={props.changeDateForTask}/>
        </Modal>
        } 
        {changeTextTask &&
        <Modal active={changeTextTask} setActive={setChangeTextTask}>
            <ChangeTextTask active={changeTextTask} setActive={setChangeTextTask} id={id} changeTextTask={props.changeTextTask}/>
        </Modal>
        } 
        {/* <div className={props.type!=3 ? s.main : s.main+" "+s.archive} key={"tsks_"+id} > */}
        <div className={getStyle(props.type)} key={"tsks_"+id} >
            <div className={s.center}>{id}</div>
            <div className={s.twoBlockMin} onClick={()=>props.setShowCommentF(id)}>
                <div className={s.hover+" "+s.twoBlockMin}>
                    <div>{props.task}<FontAwesomeIcon icon={faPencilAlt} onClick={()=>setChangeTextTask(props.task)} className={s.iconSmall} /></div>
                    <div>
                        {props.date_reminder != "NULL" && props.date_reminder != "0000-00-00" 
                        ? <p className={s.dateReminder+" "+s.tooltip} data-tooltip="Напомнить на почту" onClick={()=>setReminder(id)}>{<AnyFormatDate date={props.date_reminder} />}</p>
                        : <button onClick={()=>setReminder(id)} data-tooltip="Напомнить на почту" className={s.tooltip} ><FontAwesomeIcon icon={faBell} /></button>
                        }
                    </div>
                    
                </div>
                <div className={s.hover}><FontAwesomeIcon icon={faComment} />{props.getCountComment(id)}</div>
            </div>
            <div className={s.center}>
                <h4>{props.getWorkerName(props.responsible_id)}<FontAwesomeIcon icon={faPencilAlt} onClick={()=>props.setShowAddManagerF(id)} /></h4>
            </div>
            <div className={s.twoBlock}>
                <div>
                    {props.provisional_date
                    ?<><AnyFormatDate date={props.provisional_date} /><FontAwesomeIcon icon={faPencilAlt} onClick={()=>setChangeDateF(id, 1)} /></>
                    :<input type='date' onBlur={props.setDate} name="3" id={id} />
                    }
                </div>
                {/* <div>
                    {props.date_start 
                    ? <><AnyFormatDate date={props.date_start} /><FontAwesomeIcon icon={faPencilAlt} onClick={()=>setChangeDateF(id, 2)} /></>
                    : <input type='date' onBlur={props.setDate} name="1" id={id} />
                    }
                </div> */}
                <div>{props.date_end ? <AnyFormatDate date={props.date_end} /> : <input type='date' onChange={props.setDate} name="2" id={id} />}</div>
            </div>
            <div className={s.fourBlock}>
                <div><button onClick={()=>props.setShowAddCommentF(id)} data-tooltip="Комент" className={s.tooltip}><FontAwesomeIcon icon={faComment} /></button></div>
                {props.type != 2 && props.type != 3
                ? <div><button onClick={()=>props.setShowTaskF(id)} data-tooltip="Подзадача" className={s.tooltip}><FontAwesomeIcon icon={faProjectDiagram} /></button></div>
                : <div></div>
                }
                {props.type == 3
                ? <div><button onClick={()=>props.setTaskWithoutArchive(id, props.personalId)} data-tooltip="Из архива" className={s.tooltip}><FontAwesomeIcon icon={faArrowUp} /></button></div>
                : <div></div>
                }
                <div><button onClick={()=>props.setShowDeleteTaskF(id, props.type)} data-tooltip="Удалить" className={s.tooltip}><FontAwesomeIcon icon={faTrash} /></button></div>
            </div>
        </div>
        <div>
            {id==props.showComment && props.tasksComment.map((j, index)=>{
                if(id==j.tasks_id){
                    return(
                        <div className={s.mainComment} key={"tasksComment"+index}>
                            <div className={s.center}><FontAwesomeIcon icon={faComment} /></div>
                            <div>{j.comment}</div>
                            <div className={s.center}>{props.getWorkerName(j.tech_id)}</div>
                            <div className={s.center}><AnyFormatDate date={j.date} /></div>
                            <div></div>
                        </div>
                    )
                }
            })   
            }
        </div>
        </>
    )
}
export default ShowTask;