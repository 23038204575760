import React from 'react';

const Plan = (props) => {
    return (
        <div>
            план
        </div>
    );
}

export default Plan;
