import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
    getAllCompanyWithManager,
    setCompanyToPlanner,
    getAllCompanyPlanerInThisDate,
} from '../../../redux/sales-reducer';
import AllCompanyWithManager from './AllCompanyWithManager';
import Cookies from 'js-cookie';

const AllCompanyWithManagerContainer = (props) => {
    const { manager } = useParams();
    let currentPersonalId = manager;

    useEffect(() => {
        let isAdmin = !!Cookies.get('adminID');
        if (!isAdmin && Cookies.get('id') !== currentPersonalId) {
            currentPersonalId = Cookies.get('id');
        }
        props.getAllCompanyWithManager(currentPersonalId);
        console.log(currentPersonalId);
    }, [manager, props]);

    return (
        <div>
            <AllCompanyWithManager {...props} />
        </div>
    );
};

let mapStateToProps = (state) => ({
    allCompanyWithManager: state.sales.allCompanyWithManager,
    allSumCompanyWithManager: state.sales.allSumCompanyWithManager,
    countCompanyPlanerInThisDate: state.sales.countCompanyPlanerInThisDate,
});

export default compose(
    connect(mapStateToProps, {
        getAllCompanyWithManager,
        setCompanyToPlanner,
        getAllCompanyPlanerInThisDate,
    })
)(AllCompanyWithManagerContainer);
