import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
    getMoneyDelivery,
    getAllArticlesSubentriesForMoneyDelivery,
    setNewMoneyDelivery
} from '../../../redux/admin-reducer';
import MoneyDelivery from './MoneyDelivery';

class MoneyDeliveryContainer extends React.Component {
    componentDidMount() {
        this.props.getMoneyDelivery();
        this.props.getAllArticlesSubentriesForMoneyDelivery();
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        return (
            <div>
                <MoneyDelivery
                    sumMoneyDelivery={this.props.sumMoneyDelivery}
                    articlesSubentriesForMoneyDelivery={this.props.articlesSubentriesForMoneyDelivery}
                    setNewMoneyDelivery={this.props.setNewMoneyDelivery}
                />
            </div>
        );
    }
}

let mapStateToProps = (state) => ({
    sumMoneyDelivery: state.admin.sumMoneyDelivery,
    articlesSubentriesForMoneyDelivery: state.admin.articlesSubentriesForMoneyDelivery
});

export default compose(
    connect(mapStateToProps, {
        getMoneyDelivery,
        getAllArticlesSubentriesForMoneyDelivery,
        setNewMoneyDelivery
    })
)(MoneyDeliveryContainer);
