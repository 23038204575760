import React from 'react';
import Chart from 'react-apexcharts';

const UniqueCompaniesChart = ({ data }) => {
    console.log('Received data:', data);

    if (!data) {
        console.error('Invalid data:', data);
        return <div>No data available</div>;
    }

    // Extracting selected managers locally if not provided
    const managers = data.selectedManagers || data.managers.map(id => ({
        id,
        name: data.managerNames[id] || `Manager ${id}`
    }));

    const getUniqueCompanies = (dataset) => {
        return dataset ? [...new Set(dataset.map(item => item.company_id))] : [];
    };

    const calculateKPMainQuantity = (kpMain, managerId) => {
        const uniqueCompanies = getUniqueCompanies(kpMain ? kpMain.filter(item => item.manager_id === managerId) : []);
        return uniqueCompanies.length;
    };

    const calculateUniqueCompanies = (dataset, managerId) => {
        const uniqueCompanies = getUniqueCompanies(dataset ? dataset.filter(item => item.manager_id === managerId) : []);
        return uniqueCompanies.length;
    };

    const calculateConversionRate = (numerator, denominator) => {
        return denominator ? ((numerator / denominator) * 100).toFixed(2) : 0;
    };

    const chartData = managers.map(manager => {
        const totalCompanies = calculateUniqueCompanies(data.companyAgentsMessages, manager.id);
        const kpMainQuantity = calculateKPMainQuantity(data.kpMain, manager.id);
        const schetaCompanies = calculateUniqueCompanies(data.scheta, manager.id);
        const schetaPaidCompanies = calculateUniqueCompanies(data.scheta ? data.scheta.filter(item => item.paid_count > 0) : [], manager.id);

        const totalConversion = calculateConversionRate(schetaPaidCompanies, totalCompanies);
        const schetaConversion = calculateConversionRate(schetaCompanies, totalCompanies);
        const schetaPaidConversion = calculateConversionRate(schetaPaidCompanies, schetaCompanies);

        return {
            manager: manager.name,
            totalCompanies,
            kpMainQuantity,
            schetaCompanies,
            schetaPaidCompanies,
            totalConversion,
            schetaConversion,
            schetaPaidConversion
        };
    });

    console.log('Chart data:', chartData);

    const series = [
        {
            name: 'Total Companies',
            data: chartData.map(item => item.totalCompanies)
        },
        {
            name: 'KP Main Quantity',
            data: chartData.map(item => item.kpMainQuantity)
        },
        {
            name: 'Scheta Companies',
            data: chartData.map(item => item.schetaCompanies)
        },
        {
            name: 'Scheta Paid Companies',
            data: chartData.map(item => item.schetaPaidCompanies)
        }
    ];

    const options = {
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: true,
                columnWidth: '55%',
                endingShape: 'rounded'
            }
        },
        dataLabels: {
            enabled: true,
            offsetX: 10,
            formatter: (val) => {
                return val;
            },
            style: {
                fontSize: '12px',
                colors: ['#000']
            }
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: chartData.map(item => item.manager)
        },
        yaxis: {
            title: {
                text: 'Companies'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: (val, { series, seriesIndex, dataPointIndex, w }) => {
                    const conversionRates = chartData[dataPointIndex];
                    if (seriesIndex === 0) return `${val} (Total Conversion: ${conversionRates.totalConversion}%)`;
                    if (seriesIndex === 1) return `${val}`;
                    if (seriesIndex === 2) return `${val} (Scheta Conversion: ${conversionRates.schetaConversion}%)`;
                    if (seriesIndex === 3) return `${val} (Scheta Paid Conversion: ${conversionRates.schetaPaidConversion}%)`;
                    return val;
                }
            }
        }
    };

    return (
        <div id="chart">
            <Chart options={options} series={series} type="bar" height={350} />
        </div>
    );
};

export default UniqueCompaniesChart;
