import React from 'react';
import s from './ModulsList.module.css';

const ModulsList = (props) =>{
    return(
        <div>
            <h2>Список модулей в заявке</h2>
            <div className={s.main}>
                <div>#</div>
                <div>Позиция</div>
                <div>Серийный номер</div>
                <div>Реальная причина неисправности</div>
            </div>
            {props.personalRepairsList.length>0
            ? props.personalRepairsList.map((k,index)=>{
                return(
                    <div className={s.main} key={k.id}>
                        <div>{index+1}</div>
                        <div>{k.moduls_name+"-"+k.nomenclatures}</div>
                        <div>SN: {k.serial_number}</div>
                        <div>{k.defects}</div>
                    </div>
                )
            })
            : "Нема ничё"

            }
        </div>
    )
}
export default ModulsList;