import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    getSpecificationList,
    getNomenclaturesData,
    getALLNomenclatures
} from '../../../redux/specification-reducer';
import { getAllComponentsFromStorage } from '../../../redux/addStorage-reducer';
import Specifications from './Specifications';

class SpecificationsContainer extends React.Component {
    componentDidMount() {
        const { productId } = this.props.params;
        this.props.getSpecificationList(productId);
        this.props.getAllComponentsFromStorage();
        this.props.getNomenclaturesData(productId);
        this.props.getALLNomenclatures();
    }

    componentDidUpdate(prevProps) {
        const { productId } = this.props.params;
        if (prevProps.params.productId !== productId) {
            this.props.getSpecificationList(productId);
            this.props.getNomenclaturesData(productId);
            this.props.getALLNomenclatures();
        }
    }

    render() {
        return (
            <div>
                <Specifications
                    specificationList={this.props.specificationList}
                    productId={this.props.params.productId}
                    allComponentsFromStorage={this.props.allComponentsFromStorage}
                    nomenclaturesData={this.props.nomenclaturesData}
                    allNomenclatures={this.props.allNomenclatures}
                />
            </div>
        );
    }
}

let mapStateToProps = (state) => ({
    specificationList: state.specification.specificationList,
    nomenclaturesData: state.specification.nomenclaturesData,
    allNomenclatures: state.specification.allNomenclatures,
    allComponentsFromStorage: state.addStorage.allComponentsFromStorage
});

// Custom withRouter replacement using useParams
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const params = useParams();
        return <Component {...props} params={params} />;
    }

    return ComponentWithRouterProp;
}

export default compose(
    connect(mapStateToProps, {
        getSpecificationList,
        getAllComponentsFromStorage,
        getNomenclaturesData,
        getALLNomenclatures
    }),
    withRouter
)(SpecificationsContainer);
