import React from 'react';
import s from './MainSupplierList.module.css';

const MainSupplierList = (props) =>{
    return(
        <div>
            {
                props.supplierList.map(k=>{
                    return(<>
                        {k.company_id==props.id &&
                            <div className={s.main}>{k.supplier}</div>
                        }
                    </>)
                })
            }
        </div>
    )
}
export default MainSupplierList;