import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ShipmentStatistics from './ShipmentStatistics';
import { getModuleSalesStatistics } from '../../../redux/statistics-reducer';
import { getAllBomNomenclatures, getAllComponentFromNomenclatures, getStorageAndAveragePrice } from '../../../redux/admin-reducer';
import { getAllComponentsFromStorage } from '../../../redux/addStorage-reducer';
import { useParams } from 'react-router-dom';

class ShipmentStatisticsContainer extends React.Component {
    componentDidMount() {
        const { year } = this.props.params;
        this.props.getModuleSalesStatistics(year);
        this.props.getAllBomNomenclatures();
        this.props.getAllComponentFromNomenclatures();
        this.props.getStorageAndAveragePrice();
        this.props.getAllComponentsFromStorage();
    }

    componentDidUpdate(prevProps) {
        const { year } = this.props.params;
        if (year !== prevProps.params.year) {
            this.props.getModuleSalesStatistics(year);
            this.props.getAllBomNomenclatures();
            this.props.getAllComponentFromNomenclatures();
            this.props.getStorageAndAveragePrice();
        }
    }

    render() {
        return (
            <div>
                <ShipmentStatistics
                    moduleSalesStatistics={this.props.moduleSalesStatistics}
                    nomenclatures={this.props.nomenclatures}
                    allComponentFromNomenclatures={this.props.allComponentFromNomenclatures}
                    storageAndAveragePrice={this.props.storageAndAveragePrice}
                    getModuleSalesStatistics={this.props.getModuleSalesStatistics}
                    allComponentsFromStorage={this.props.allComponentsFromStorage}
                />
            </div>
        );
    }
}

let mapStateToProps = (state) => ({
    moduleSalesStatistics: state.statistics.moduleSalesStatistics,
    nomenclatures: state.admin.nomenclatures,
    allComponentFromNomenclatures: state.admin.allComponentFromNomenclatures,
    storageAndAveragePrice: state.admin.storageAndAveragePrice,
    allComponentsFromStorage: state.addStorage.allComponentsFromStorage
});

// Custom withRouter replacement using useParams
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const params = useParams();
        return <Component {...props} params={params} />;
    }

    return ComponentWithRouterProp;
}

export default compose(
    connect(mapStateToProps, {
        getModuleSalesStatistics,
        getAllBomNomenclatures,
        getAllComponentFromNomenclatures,
        getStorageAndAveragePrice,
        getAllComponentsFromStorage
    }),
    withRouter
)(ShipmentStatisticsContainer);
