import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getAccessoriesList } from '../../../../redux/accessories-reducer';
import AccessoriesList from './AccessoriesList';
import {compose} from "redux";

const AccessoriesListContainer = (props) => {
    useEffect(() => {
        props.getAccessoriesList();
    }, [props]);

    return (
        <div>
            <AccessoriesList {...props} />
        </div>
    );
};

const mapStateToProps = (state) => ({
    accessoriesList: state.accessories.accessoriesList,
});

export default compose(
    connect(mapStateToProps, {
        getAccessoriesList,
    })
)(AccessoriesListContainer);
