import React from 'react';
import s from './Distribution.module.css';
import main from '../../Main.module.css';
import { useState } from 'react';

const AddDistribution = (props) =>{
        
        let [type, setType] = useState(1)
        const setTypeF = (e)=>{
            setType(e.target.value)
        }
        let [name, setName] = useState("")
        const setNameF = (e)=>{
            setName(e.target.value)
        }
        let [dateReminder, setDateReminder] = useState("")
        const setDateReminderF = (e)=>{
            setDateReminder(e.target.value)
        }
        let [description, setDescription] = useState("")
        const setDescriptionF = (e)=>{
            setDescription(e.target.value)
        }
        const AddNewDistribution = ()=>{
            if(name!=""){
            if(dateReminder!=""){
            let array = [];
            array.push(props.personalId)
            array.push(type)
            array.push(name)
            array.push(dateReminder)
            array.push(description)
            props.addNewDistribution(array, props.personalId)
            props.setActive(false)
            }}
        }
        return(
            <div className={s.addDesc}>
                <h2 className={main.center}>Новая рассылка</h2>
                <div className={s.twoBlock}>
                    <div>Выбрать тип:* </div>
                    <div>
                        <select onChange={setTypeF}>
                            {props.typeDistribution.map(k=>{
                                return <option value={k.id} key={k.id}>{k.name}</option>
                            })}
                        </select>
                    </div>
                    <div>Название рассылки:*</div>
                    <div><input type="text" onChange={setNameF} value={name} /></div>
                    <div>Плановая дата:* </div>
                    <div><input type="date" value={dateReminder} onChange={setDateReminderF} /></div>
                    {/* <div>Описание:</div> */}
                </div>
                {/* <textarea onChange={setDescriptionF} value={description} className={s.textarea} /> */}
                <div className={main.center}><button onClick={()=>AddNewDistribution()}>Создать</button></div>
            </div>
        )
}
export default AddDistribution;