import React, { useState, useEffect} from 'react';
import main from '../../../Main.module.css'
import Cookies from 'js-cookie'
import {getCompanyByEdrpou, setLastIdCompany} from "../../../../redux/company-reducer";
import { connect } from 'react-redux';

const AddNewCompany = (props) =>{
    //console.log(props.lastIdCompany)
    let [companyCategories, setCompanyCategories] = useState(props.companyCategories)
    let [companySegmentation, setCompanySegmentation] = useState(props.companySegmentation)
    useEffect(() => {
        setCompanyCategories(props.companyCategories)
    }, [props.companyCategories]);
    useEffect(() => {
        setCompanySegmentation(props.companySegmentation)
    }, [props.companySegmentation]);
    let [name, setName] = useState("")
    const setNameF = (e)=>setName(e.target.value)
    let [nameWithAkon, setNameWithAkon] = useState("")
    const setNameWithAkonF = (e)=>setNameWithAkon(e.target.value)
    let [edrpou, setEdrpou] = useState("")
    const setEdrpouF = (e) => {
        e.persist();
        setLastIdCompany(null);
        setEdrpou((prevEdrpou) => {
            const newEdrpou = e.target.value;
            if (newEdrpou.length > 7) {
                //37130116
                //console.log('before: ',props.lastIdCompany)
                props.getCompanyByEdrpou(newEdrpou);
                //console.log('after: ',props.lastIdCompany)
            }
            return newEdrpou;
        });
    };

    let [categoryNow, setCategoryNow] = useState("")
    const setCategoryNowF = (e)=>setCategoryNow(e.target.value)
    let [segmentationNow, setSegmentationNow] = useState("")
    const setSegmentationNowF = (e)=>setSegmentationNow(e.target.value)
    let [status, setStatus] = useState("1")
    const setStatusF = (e)=>setStatus(e.target.value)
    const go = ()=>{
        let go = true
        if(name=="") go=false
        if(nameWithAkon=="") go=false
        if(go){
            let array = []
            if(categoryNow!=""){
                categoryNow = companyCategories.find(k=>k.name.trim()==categoryNow.trim())
                if(categoryNow){
                    categoryNow = categoryNow.id
                }else{
                    categoryNow = ""
                }
            }
            if(segmentationNow!=""){
                segmentationNow = companySegmentation.find(k=>k.name.trim()==segmentationNow.trim())
                if(segmentationNow){
                    segmentationNow = segmentationNow.id
                }else{
                    segmentationNow = ""
                }
            }
            array.push(
                name,
                nameWithAkon,
                edrpou,
                categoryNow,
                segmentationNow,
                Cookies.get('id'),
                status
            )
            props.addNewCompany(array)
            //props.setActive(false)
        }
    }

    const isLastIdCompanyNull = props.lastIdCompany === null;

    return(
        <div id="addcompany">
            <h2 className={main.center}>Добавляем новую компанию</h2>
            <h3>Название полное (из 1С)*:</h3>
            <input type="text" value={name} onChange={setNameF} className={main.input} />
            <h3>Короткое название*:</h3>
            <input type="text" value={nameWithAkon} onChange={setNameWithAkonF} className={main.input} />
            <h3>Откуда контакт:</h3>
            <select onChange={setStatusF} className={main.select+" "+main.w100}>
                <option name="1" value={"1"}>Холодный клиент</option>
                <option name="2" value={"2"}>Сам пришёл</option>
            </select>
            <h3>ЕДРПОУ:</h3>
            <input type="number" value={edrpou} onChange={setEdrpouF} className={main.input} />
            <h3>Отрасль:</h3>
            <select onChange={setCategoryNowF} className={main.select+" "+main.w100}>
                <option>...</option>
                {companySegmentation.map(k=><option name={k.name} key={"companyCategories"+k.id}>{k.name}</option>)}
            </select>
            <h3>Категория:</h3>
            <select onChange={setSegmentationNowF} className={main.select+" "+main.w100}>
                <option>...</option>
                {companyCategories.map(k=><option name={k.name} key={"companySegmentation"+k.id}>{k.name}</option>)}
            </select>
            <div className={main.center}>
                <input
                    type="button"
                    className={main.inputAdd}
                    value="Создать"
                    onClick={()=>go()}
                    disabled={!isLastIdCompanyNull}
                />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        lastIdCompany: state.company.lastIdCompany,
    };
};

export default connect(mapStateToProps, { getCompanyByEdrpou })(AddNewCompany);
