import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getAllActivity } from '../../../redux/salesPersonal-reducer';
import SalesPersonalStatistics from './SalesPersonalStatistics';
import Cookies from 'js-cookie';

const SalesPersonalStatisticsContainer = (props) => {
    const { personalId } = useParams();
    const currentPersonalId = useRef(personalId); // Використовуємо useRef для збереження значення між рендерами

    useEffect(() => {
        let isAdmin = !!Cookies.get('adminID');
        if (!isAdmin && Cookies.get('id') !== currentPersonalId.current) {
            currentPersonalId.current = Cookies.get('id'); // Оновлюємо реф, а не змінну
        }
        props.getAllActivity(currentPersonalId.current, "2023-01-01");
    }, [personalId, props]);

    useEffect(() => {
        let isAdmin = !!Cookies.get('adminID');
        if (!isAdmin && Cookies.get('id') !== currentPersonalId.current) {
            currentPersonalId.current = Cookies.get('id');
        }
        if (props.personalId !== personalId) {
            props.getAllActivity(currentPersonalId.current);
        }
    }, [personalId, props]);

    return (
        <div>
            <SalesPersonalStatistics {...props} personalId={currentPersonalId.current} />
        </div>
    );
};

const mapStateToProps = (state) => ({
    allActivity: state.salesPersonal.allActivity,
});

export default compose(
    connect(mapStateToProps, { getAllActivity })
)(SalesPersonalStatisticsContainer);
