import React, {useState} from 'react';
import DescriptionPersonalModuleContainer
    from '../Production/common/DescriptionPersonalModule/DescriptionPersonalModuleContainer';
import s from './PersonalModule.module.css';
import main from '../../components/Main.module.css';
import {withRouter} from 'react-router-dom';
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LineChart,
    Line, Area
} from 'recharts';
import PersonalModuleCategoryContainer from './PersonalModuleCategory/PersonalModuleCategoryContainer';
import PersonalModuleCategoryCompanyContainer
    from './PersonalModuleCategoryCompany/PersonalModuleCategoryCompanyContainer';
import Cookies from "js-cookie";


const PersonalModule = (props) => {
    console.log(props.personalBalancesInStock)
    //получение информации на базе введенной в поисковую строку номенклатуры
    let [seacrhNomenclatures, setSeacrhNomenclatures] = useState(props.nom)
    const setSearchNomenclaturesF = () => {
        setSeacrhNomenclatures(textFromMainInput);
        props.getStatisticsPaidFromModulesNomenclature(textFromMainInput)
        props.getPersonalBalancesInStock(textFromMainInput)
        window.location.href = `${textFromMainInput}`
        //textFromMainInput !== "" && props?.history?.push("" + textFromMainInput)
    }

    const getTotalSoldItems = () => {
        let total = 0;
        props.statisticsPaidFromModulesNomenclature.map((k) => {
            total += parseInt(k.moduls_coll)
        })
        return total
    }
    //блок для ввода номенклатуры в поисковую строку
    let [textFromMainInput, setTextFromMainInput] = useState(props.nom)
    const setTextFromMainInputF = (e) => {
        setTextFromMainInput(e.target.value);
    }
    //поиск по нажатию энтера
    let handleKeyPress = (e) => {
        if (e.charCode === 13) { // Клавиша Enter
            setSearchNomenclaturesF();
        }
    }


    const data = props.canSeeSelfcost
        ? [
            {
                name: 0,
                moduls_coll: 0,
                yearly_selfcost: 0
            }
        ]
        : [
            {
                name: 0,
                moduls_coll: 0
            }
        ];
    let calculateYearlySelfcost = (year) => {
        let selfcost = 0
        if (props.modulesFinanceInfo.yearly_selfcost?.[year] === undefined) {
            return 0
        }
        let moduls_coll = 0;
        props.modulesFinanceInfo.yearly_selfcost[year].map((k) => {
            selfcost += k.item_selfcost
            moduls_coll++
        })
        return (selfcost / moduls_coll).toFixed(2)
    }

    props.statisticsPaidFromModulesNomenclature.map((k, index) => {
        if (k.moduls_coll == 0 && calculateYearlySelfcost(k.year) == 0 || k.year == 0) return
        if (props.canSeeSelfcost) {
            data.push({
                name: k.year,
                moduls_coll: Number(k.moduls_coll),
                yearly_selfcost: calculateYearlySelfcost(k.year)
            })
        } else {
            data.push({
                name: k.year,
                moduls_coll: Number(k.moduls_coll),

            })
        }
    })

    let totalModulesProduced = data.reduce((acc, item) => acc + item.moduls_coll, 0);

    let [category, setCategory] = useState(0)

    let getMaxYearNum = () => {
        if (props.modulesFinanceInfo.yearly_selfcost === undefined) return 0
        return Object.keys(props.modulesFinanceInfo.yearly_selfcost)
            .map(key => parseInt(key))
            .reduce((max, current) => current > max ? current : max, 0);
    }

    console.log(props.modulesFinanceInfo);

    function compareFloatValues(oldValue, newValue) {
        if (oldValue === newValue) {
            return "без изменений";
        } else if (oldValue < newValue) {
            const growth = ((newValue - oldValue) / oldValue) * 100;
            return `+${Math.abs(growth.toFixed(2))}%`;
        } else {
            const fall = ((oldValue - newValue) / oldValue) * 100;
            return `-${Math.abs(fall.toFixed(2))}%`;
        }
    }

    return (
        <div className={s.search}>
            <div className={s.header}>
                <h1 className={main.center}>Персональная страница модуля по номенклатурному номеру</h1>
                <div className={s.searchBlock}>
                    <input type="number" value={textFromMainInput} onChange={setTextFromMainInputF}
                           placeholder="Введите номер номенклатуры" className={s.input} onKeyPress={handleKeyPress}/>
                    <input type="button" value={"Go"} onClick={() => setSearchNomenclaturesF()}
                           className={s.searchButton}/>
                </div>
            </div>
            {totalModulesProduced > 0 &&
                <div className={s.inStock}>Остаток на складе: {props.personalBalancesInStock} штук</div>
            }

            {seacrhNomenclatures !== "" && <DescriptionPersonalModuleContainer productId={seacrhNomenclatures}/>}
            {seacrhNomenclatures !== "" &&
                <div>
                    {props.canSeeSelfcost && totalModulesProduced > 0 &&
                        <>
                            <h2>Основные цифры</h2>
                            <ul>
                                <li>Всего выпущено модулей: {getTotalSoldItems()} шт.</li>
                                <li>Всего выпущено модулей с посчитаной с/с за отчётный
                                    период: {props.modulesFinanceInfo?.list?.length} шт.
                                </li>

                                <li>Всего с/с модулей за отчётный
                                    период: {props.modulesFinanceInfo?.selfcost_total} грн
                                </li>
                                <li>Средняя с/с модуля за всё
                                    время: {(props.modulesFinanceInfo?.selfcost_total / props.modulesFinanceInfo?.list?.length).toFixed(2)} грн
                                </li>
                                <li>Средняя с/с модуля за последний год:
                                    ({getMaxYearNum()}): {calculateYearlySelfcost(getMaxYearNum())} грн
                                    (
                                    {compareFloatValues(
                                        calculateYearlySelfcost(getMaxYearNum()),
                                        (props.modulesFinanceInfo?.selfcost_total / props.modulesFinanceInfo?.list?.length).toFixed(2)
                                    )
                                    })
                                </li>
                            </ul>
                        </>
                    }
                    {totalModulesProduced > 0 &&
                        <>

                            <h2>Сколько купили в год</h2>

                            <ResponsiveContainer width="95%" height={350}>
                                <BarChart
                                    width={500}
                                    height={200}
                                    data={data}
                                    domain={['dataMin', 'dataMax']}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <XAxis dataKey="name"/>
                                    <YAxis
                                        dataKey="moduls_coll"
                                        type="number"
                                        domain={[0, 'dataMax']}
                                        yAxisId="left"
                                        name="Total items per year"
                                        allowDataOverflow={true}

                                        label={{position: 'top'}}
                                    />
                                    {props.canSeeSelfcost &&
                                        <YAxis
                                            dataKey="yearly_selfcost"
                                            orientation="right"
                                            type="number"
                                            yAxisId="right"
                                            domain={[0, 'dataMax']}
                                            name="Avg yearly selfcost"

                                            label={{position: 'top'}}
                                            allowDataOverflow={true}
                                        />
                                    }
                                    <Tooltip/>
                                    {<Legend
                                        verticalAlign="top"
                                        height={36}
                                    />}

                                    <Bar dataKey="moduls_coll" barSize={20} fill="#8884d8" yAxisId="left"
                                         label={{position: 'top'}}/>
                                    {props.canSeeSelfcost &&
                                        <Bar
                                            type="monotone"
                                            dataKey="yearly_selfcost"
                                            fill="#82ca9d"
                                            yAxisId="right"
                                            barSize={20}
                                            label={{position: 'right'}}
                                        />
                                    }
                                </BarChart>
                            </ResponsiveContainer>


                            <h2>Фильтр компаний, которые покупали модуль, по категориям</h2>
                            <div className={s.filtr_header}>
                                <div>Выбрать категорию</div>
                                <div>Название компании</div>
                                <div>Купленых модулей этой модификации c 2020 года</div>
                            </div>
                            <div className={s.filtr}>
                                <div><PersonalModuleCategoryContainer id={seacrhNomenclatures} setCategory={setCategory}
                                                                      category={category}/></div>
                                <div><PersonalModuleCategoryCompanyContainer id={seacrhNomenclatures}
                                                                             category={category}/>
                                </div>
                            </div>
                        </>
                    }
                </div>
            }
        </div>
    )
}
export default PersonalModule;