import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getStatisticsBranch, getStatisticsBranchSum, getPaidChecksYear } from '../../../redux/statistics-reducer';
import StatisticsBranch from './StatisticsBranch';

class StatisticsBranchContainer extends React.Component {
    componentDidMount() {
        this.props.getStatisticsBranch();
        this.props.getStatisticsBranchSum();
        this.props.getPaidChecksYear();
    }

    render() {
        return (
            <div>
                <StatisticsBranch
                    statisticsBranch={this.props.statisticsBranch}
                    statisticsBranchSum={this.props.statisticsBranchSum}
                    paidChecksYear={this.props.paidChecksYear}
                    getStatisticsBranch={this.props.getStatisticsBranch}
                    getStatisticsBranchSum={this.props.getStatisticsBranchSum}
                />
            </div>
        );
    }
}

let mapStateToProps = (state) => ({
    statisticsBranch: state.statistics.statisticsBranch,
    statisticsBranchSum: state.statistics.statisticsBranchSum,
    paidChecksYear: state.statistics.paidChecksYear
});

export default compose(
    connect(mapStateToProps, { getStatisticsBranch, getStatisticsBranchSum, getPaidChecksYear })
)(StatisticsBranchContainer);
