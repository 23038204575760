import React from 'react';
import ReactApexChart from 'react-apexcharts';

const MixedChartComponent = ({ data, filters }) => {
    if (!data || !data.managers || !data.categories || !data.series) {
        return <div>Loading...</div>;
    }

    const calculateTotals = (series) => {
        const totals = {};
        series.forEach(category => {
            category.data.forEach((value, index) => {
                if (!totals[index]) {
                    totals[index] = 0;
                }
                totals[index] += value;
            });
        });
        return totals;
    };

    const totals = calculateTotals(data.series);

    // Sort managers based on the total values
    const sortedManagers = data.managers.map((manager, index) => ({
        manager,
        total: totals[index]
    })).sort((a, b) => b.total - a.total);

    // Create sorted series based on the sorted managers
    const sortedSeries = data.series.map(category => ({
        name: category.name,
        data: sortedManagers.map(sortedManager => {
            const managerIndex = data.managers.indexOf(sortedManager.manager);
            return category.data[managerIndex];
        })
    }));

    const categories = sortedManagers.map(sortedManager => {
        const managerName = data.managerNames[sortedManager.manager];
        return managerName ? managerName : `Manager ${sortedManager.manager}`;
    });

    const options = {
        chart: {
            type: 'bar',
            stacked: true,
            zoom: {
                enabled: true
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        xaxis: {
            categories: categories,
        },
        tooltip: {
            shared: true,
            intersect: false
        },
        colors: [
            '#1270ea',
            '#2d7ee5',
            '#12a9ea',
            '#3dd7d1',
            '#3ee0b2',
            '#82eab0'
        ]
    };

    return (
        <div id="chart">
            <ReactApexChart options={options} series={sortedSeries} type="bar" height={350} />
        </div>
    );
};

export default MixedChartComponent;

