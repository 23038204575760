import React, {useEffect, useState} from 'react';
import AllModulsPaid2 from './AllModulsPaid2/AllModulsPaid2';
import s from './Chief.module.css';
import allModulsPaidCss from './AllModulsPaid2/AllModulsPaid.module.css';
import {setWhoMakeWithSpecs} from "../../../redux/modulsPaid-reducer";
import Cookies from "js-cookie";
import Button from "@mui/material/Button";
import {toast} from "react-toastify";
import {dashboardAPI, workerAPI} from "../../../api/api";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {EyeIcon} from "primereact/icons/eye";
import AssemblerStory from "./AssemblerStory/AssemblerStory";

const Chief = (props) => {
    let [nomenclaturesModuleForAkon, setNomenclaturesModuleForAkonForState] = useState("");
    let [nameModuleForAkon, setNameModuleForAkonForState] = useState("");
    let [collModuleForAkon, setCollModuleForAkonForState] = useState("");
    let [buttonAddForAKON, showFormAddForAKON] = useState("none");

    let nomenclaturesModuleForAkonFunc = (e) => {
        setNomenclaturesModuleForAkonForState(e.target.value);
    }
    let nameModuleForAkonFunc = (e) => {
        setNameModuleForAkonForState(e.target.value);
    }
    let collModuleForAkonFunc = (e) => {
        setCollModuleForAkonForState(e.target.value);
    }

    let setModuleForAkonTest = () => {
        props.setModulsForAkon("WAD-P340-BUS-314", 6, "314");
    }

    let setModuleForAkon = () => {
        if (nomenclaturesModuleForAkon != "" && collModuleForAkon != "" && collModuleForAkon != "") {
            props.setModulsForAkon(nameModuleForAkon, collModuleForAkon, nomenclaturesModuleForAkon);
            showFormAddForAKON("none");
            setCollModuleForAkonForState("");
            setNameModuleForAkonForState("");
            setNomenclaturesModuleForAkonForState("");
        } else {
            alert("Поля должны быть заполнены!");
        }
    }
    let showFrom = () => {
        buttonAddForAKON === "none" ? showFormAddForAKON("grid") : showFormAddForAKON("none");
    }

    const [workerStats, setWorkerStats] = useState([]);
    const fetchData = () => {
        //get current month number
        const date = new Date();
        //workerAPI.getWorkersModulsPerMonth(date.getMonth() + 1, date.getFullYear())
        workerAPI.getWorkersActualStats()
            .then(responseData => {
                setWorkerStats(responseData.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };
    useEffect(() => {
        fetchData();
    }, []);

    const countWorkerModulesActive = (workerId) => {
        // Sum all modules with status 1
        let sum = workerStats?.in_production?.filter(m => m.who_make===workerId)?.[0]?.sum_moduls_coll ?? 0;
        return sum;
    };

    const countWorkerModulesTotal = (workerId) => {
        let sum =  workerStats?.current_month?.filter(m => m.who_make===workerId)?.[0]?.sum_moduls_coll ?? 0;
        return sum;
    };

    return (<>
            {[1, 34, 44].includes(Cookies.get('id'))
                ?
                <>
                    <Button
                        variant="contained"
                        onClick={setModuleForAkonTest}
                    >
                        Добавить тестовый модуль для внутренних работ (WAD-P340-BUS-314, 6 шт, на склад)
                    </Button>
                </>
                : null}
            <div className={s.add}>
                <button onClick={showFrom}>{buttonAddForAKON === "none" ? "Добавить" : "Убрать"}</button>
            </div>


            <div className={s.addForAkon} style={{display: buttonAddForAKON}}>
                <input id="nomenclatures" placeholder="Спец номер" onChange={nomenclaturesModuleForAkonFunc}
                       value={nomenclaturesModuleForAkon}/>
                <input id="name" placeholder="Название модуля с номенклатурным номером" onChange={nameModuleForAkonFunc}
                       value={nameModuleForAkon}/>
                <input id="coll" placeholder="Количество" onChange={collModuleForAkonFunc} value={collModuleForAkon}/>
                <button onClick={setModuleForAkon}>Добавить модуль для внутренних работ</button>
            </div>

            <h3>Загруженность монтажников <AssemblerStory assemblersList={props?.workers?.filter((w)=>w.id != 7) ?? []} /> </h3>
            <Table sx={{minWidth: 650}} aria-label="simple table" size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Сейчас в работе </TableCell>
                        <TableCell align="right">Всего за текущий месяц </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {props.workers.filter(w=>w.id != 7).map(w => (
                        <TableRow
                            key={w.id}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell component="th" scope="row">{w.name}</TableCell>
                            <TableCell
                                align="right">{countWorkerModulesActive(parseInt(w.id))}</TableCell>
                            <TableCell
                                align="right">{countWorkerModulesTotal(parseInt(w.id))}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div className={s.main}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>

            <div className={s.main}>
                <div>№ счёта</div>
                <div>Название</div>
                <div>Количество (на складе)</div>
                <div>Ответственный</div>
                <div>Дата отгрузки</div>
                <div>Другие действия</div>
            </div>
            {/* <AllModulsPaid modulsPaid={props.modulsPaid} modulsReady={props.modulsReady} workers={props.workers} setWhoMake={props.setWhoMake} setOtherProducts={props.setOtherProducts} checkingAvailabilityComponents={props.checkingAvailabilityComponents} missingComponents={props.missingComponents}/> */}
            <AllModulsPaid2 modulsPaid={props.modulsPaid}
                            modulsReady={props.modulsReady}
                            workers={props.workers}
                            setWhoMakeWithSpecs={props.setWhoMakeWithSpecs}
                            setWhoMakeWithoutSpecs={props.setWhoMakeWithoutSpecs}
                            setOtherProducts={props.setOtherProducts}
                            checkingAvailabilityComponents={props.checkingAvailabilityComponents}
                            missingComponents={props.missingComponents}
                            setSeparatorInBase={props.setSeparatorInBase}
                            key={props.modulsPaid.id}
            />
        </>
    )
}
export default Chief;