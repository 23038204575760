import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import s from './sales.module.css';

const Sales = (props) => {
    let [personalManagerId, setPersonalManagerId] = useState(0);
    let [filterYear, setFilterYear] = useState(2);

    console.log(props.listWithCompanyUnpin);

    const getUnpin = (arrayMain) => {
        let now = new Date();
        let day = ("0" + (new Date()).getDate()).slice(-2);
        let month = now.getUTCMonth() + 1;
        now = now.getFullYear() + "-" + month + "-" + day;
        console.log(now);

        let array = [];

        const plusTwoYear = (date) => {
            let now = new Date(date);
            let day = ("0" + (new Date(date)).getDate()).slice(-2);
            let month = now.getUTCMonth() + 1;
            let year = now.getFullYear() + filterYear;
            now = year + "-" + month + "-" + day;
            return now;
        };

        arrayMain.map(k => {
            if (k.date_add === "0000-00-00") k.date_add = "2018-01-01";
            if (k.lostCheck === "0000-00-00") k.lostCheck = "2018-01-01";
            if (k.lostMessage === "0000-00-00") k.lostMessage = "2018-01-01";

            if (plusTwoYear(k.date_add) < now) {
                if (plusTwoYear(k.lostCheck) < now) {
                    if (plusTwoYear(k.lostMessage) < now) {
                        array.push(k);
                    }
                }
            }
        });

        return array;
    };

    const personalManager = (arrayMain) => {
        let array = [];
        arrayMain.map(k => {
            if (personalManagerId !== 0) {
                if (k.whose_company === personalManagerId) {
                    array.push(k);
                }
            } else {
                array.push(k);
            }
        });
        return array;
    };

    const getWhoseCompany = (id) => {
        switch (id) {
            case "1": return "Алексей";
            case "2": return "Олег";
            case "4": return "Евгений";
            case "5": return "Валерий";
            default: return "Не распределено";
        }
    };

    return (
        <div>
            <h1>Открепить компании от текущего менеджера</h1>
            <div className={s.filter}>
                <div onClick={filterYear === 2 ? () => setFilterYear(1) : () => setFilterYear(2)} className={filterYear === 2 ? s.active : ""}>2 года</div>
                <div onClick={filterYear === 1 ? () => setFilterYear(2) : () => setFilterYear(1)} className={filterYear === 1 ? s.active : ""}>1 год</div>
            </div>
            <div className={s.filter}>
                <div onClick={personalManagerId === 2 ? () => setPersonalManagerId(0) : () => setPersonalManagerId(2)} className={personalManagerId === 2 ? s.active : ""}>Олег</div>
                <div onClick={personalManagerId === 1 ? () => setPersonalManagerId(0) : () => setPersonalManagerId(1)} className={personalManagerId === 1 ? s.active : ""}>Алексей</div>
                <div onClick={personalManagerId === 4 ? () => setPersonalManagerId(0) : () => setPersonalManagerId(4)} className={personalManagerId === 4 ? s.active : ""}>Евгений</div>
                <div onClick={personalManagerId === 5 ? () => setPersonalManagerId(0) : () => setPersonalManagerId(5)} className={personalManagerId === 5 ? s.active : ""}>Валерий</div>
                <div onClick={() => setPersonalManagerId(0)} className={personalManagerId === 0 ? s.active : ""}>Все</div>
            </div>
            <div className={s.main}>
                <div>#</div>
                <div>Название компании</div>
                <div>Чья компания</div>
                <div>Последнее взаимодействие</div>
            </div>
            {getUnpin(personalManager(props.listWithCompanyUnpin)).map((k, index) => {
                return (
                    <div className={s.main} key={"listWithCompanyUnpin_" + k.id}>
                        <div>{index + 1}</div>
                        <div><NavLink to={"/company/profile/" + k.id} target="_blank">{k.name_with_akon}</NavLink></div>
                        <div>{getWhoseCompany(k.whose_company)}</div>
                        <div>
                            <div className={s.twoBlock}>
                                <div>Дата добавления компании</div>
                                <div>{k.date_add == null ? "-" : k.date_add}</div>
                            </div>
                            <div className={s.twoBlock}>
                                <div>Дата последнего счёта</div>
                                <div>{k.lostCheck === "2018-01-01" ? "-" : k.lostCheck}</div>
                            </div>
                            <div className={s.twoBlock}>
                                <div>Дата последнего сообщения</div>
                                <div>{k.lostMessage == null ? "-" : k.lostMessage}</div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Sales;
