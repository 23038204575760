import React, {useState, useEffect} from 'react';
import {BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer} from 'recharts';
import {Grid, ToggleButtonGroup, ToggleButton, Select, MenuItem} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";

const StatsComponent = ({stats}) => {
    const [selectedYear, setSelectedYear] = useState('');
    const [dataType, setDataType] = useState('name');
    const [moduleStats, setModuleStats] = useState({});
    const [activeTab, setActiveTab] = React.useState(1);

    // Filter stats based on selected year
    useEffect(() => {
        const filteredStats = stats?.filter(stat => !selectedYear || stat?.year === selectedYear);
        const newModuleStats = calculateModuleStats(filteredStats ?? {});
        setModuleStats(newModuleStats);
    }, [stats, selectedYear]);
    if(stats === null || stats === undefined) return null;

    // Calculate module stats
    const calculateModuleStats = (filteredStats) => {
        const moduleStats = {name: {}, series: {}};

        filteredStats.forEach(stat => {
            if(stat === null || stat === undefined || stat.moduls_name === null || stat.moduls_name===undefined) return;

            const moduleName = stat.moduls_name;
            const moduleSeries = moduleName.includes('-')
                ? moduleName.split('-').slice(0, -1).join('-')
                : moduleName;

            moduleStats.name[moduleName] = (moduleStats.name[moduleName] || 0) + stat.moduls_coll;
            moduleStats.series[moduleSeries] = (moduleStats.series[moduleSeries] || 0) + stat.moduls_coll;
        });

        return moduleStats;
    };

    // Draw bar chart
    const generateSortedData = () => {
//        const dataKey = dataType;
        let data = Object.entries(moduleStats[dataType] || []);

        // Sort data by value in descending order
        data = data.sort((a, b) => b[1] - a[1]);
        console.log(data)
        return data;
    };
    const drawBarChart = () => {
        //const dataKey = dataType;
        //sortedData = {};
        //let data = Object.entries(moduleStats[dataKey] || []);

        // Sort data by value in descending order
        let data = generateSortedData();

        const maxValue = Math.max(...data.map(entry => entry[1]));

        return (
            <ResponsiveContainer width='80%' height={400}>
                <BarChart data={data} layout="vertical">
                    <XAxis type="number" domain={[0, 'dataMax']} hide/>
                    <YAxis
                        type="category"
                        dataKey="0"
                        tick={{fontSize: 12}}
                        tickLine={false}
                        axisLine={false}
                        width={200} // Adjust the width as needed
                        interval={0} // Display all labels without skipping
                    />
                    <Tooltip content={<CustomTooltip/>}/>
                    <Bar dataKey="1" fill="#8884d8" label={{position: 'insideRight', fontSize: 12, fill: '#666'}}>
                        {data.map((entry, index) => (
                            <text key={`label-${index}`} x={entry[1] > 0 ? entry[1] + 5 : 0} y={entry[0]} dy={13}
                                  textAnchor="start">
                                {`${entry[1]}`}
                            </text>
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        );
    };


    // Custom tooltip component
    const CustomTooltip = ({active, payload}) => {
        if (active && payload && payload.length) {
            return (
                <div style={{backgroundColor: 'white', padding: '5px'}}>
                    <p>{payload[0].payload[0]}</p>
                    <p>{`Quantity: ${payload[0].value}`} </p>
                </div>
            );
        }
        return null;
    };

    // Handle year selection
    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    // Handle data type selection
    const handleDataTypeChange = (event, newDataType) => {
        if (newDataType !== null) {
            setDataType(newDataType);
        }
    };


    return (
        <div>
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={3}>
                    <ToggleButtonGroup value={dataType} exclusive onChange={handleDataTypeChange}>
                        <ToggleButton value="name">Модули</ToggleButton>
                        <ToggleButton value="series">Серии</ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item xs={3}>
                    <Select value={selectedYear} onChange={handleYearChange}>
                        <MenuItem value="">За весь период</MenuItem>
                        <MenuItem value="" disabled>Выберите год</MenuItem>
                        {[...new Set(stats.map(stat => stat.year))].map(year => (
                            <MenuItem key={year} value={year}>{year}</MenuItem>
                        ))}
                        {/* Placeholder when no year is selected */}
                    </Select>
                </Grid>
                <Grid item xs={6}>
                    {/* Empty grid item for spacing */}
                </Grid>
            </Grid>
            <h2>{dataType === 'name' ? 'Модули' : 'Серии'}</h2>

            <ButtonGroup variant="text" aria-label="text button group">
                <Button
                    onClick={() => setActiveTab(1)}
                >График</Button>
                <Button
                    onClick={() => setActiveTab(2)}
                >Данные</Button>
            </ButtonGroup>
            Куплено модулей за выбранный период ({selectedYear || 'всего'})
            {
                activeTab === 1 &&
                drawBarChart()
            }
            {
                activeTab === 2 &&

                <TableContainer>
                    <Table sx={{minWidth: 650}} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="right">Модуль</TableCell>
                                <TableCell align="right">Кол-во</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {generateSortedData().map((element) => (
                                <TableRow key={element[0] + element[1]}>
                                    <TableCell align="right">{element[0]}</TableCell>
                                    <TableCell align="right">{element[1]}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }

        </div>
    );
};

export default StatsComponent;
