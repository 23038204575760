import React, { useState } from 'react';
import s from './MoneyDelivery.module.css';

const MoneyDelivery = (props) => {
    let [sum, setSum] = useState(0);
    const setSumF = (e) => {
        setSum(e.target.value);
    };

    let [articles, setArticles] = useState("");
    const setArticlesF = (id) => {
        setArticles(id);
    };

    const setNewMoneyDeliveryF = () => {
        if (sum !== 0 && articles !== "") {
            props.setNewMoneyDelivery(sum, articles);
            setSum(0);
            setArticles("");
        } else {
            console.log("не заполненны все поля");
        }
    };

    return (
        <div>
            <div><h2 className={s.title + " " + s.center}>{"Не разнесено " + props.sumMoneyDelivery + " грн"}</h2></div>
            <div className={s.main + " " + s.center}>
                <div className={s.weight}>Сумма</div>
                <div className={s.weight}>Подстатья</div>
                <div className={s.weight}>Действие</div>
                <div><input type="number" value={sum} onChange={setSumF} className={s.center} /></div>
                <div className={s.mainArticles}>
                    {
                        props.articlesSubentriesForMoneyDelivery.map((j, index) => {
                            return <div key={"articlesSubentriesForMoneyDelivery" + index} onClick={() => setArticlesF(j.id)} className={articles == j.id ? s.active : ""}>{j.subentries}</div>;
                        })
                    }
                </div>
                <div><button onClick={setNewMoneyDeliveryF}>Разнести</button></div>
            </div>
        </div>
    );
};

export default MoneyDelivery;
