import React, { useState } from 'react';
import main from '../../../Main.module.css';

const Comment = (props) =>{
    console.log(props.mainData)
    let [comment, setComment] = useState("")
    const setCommentF = (e) => setComment(e.target.value)
    const go = () =>{
        props.mainData.push(comment)
        let array = props.mainData
        console.log(array)
        props.setNewNomenclature(array)
        props.setArrayMasive([])
        props.setNameNow("0")
        props.setActive(false)
    }
    return(
        <div>
            <h3 className={main.center}>Комментарий к номенклатуре</h3>
            <div><textarea className={main.textarea} value={comment} onChange={setCommentF} placeholder='Опционально' /></div>
            <div className={main.center}><input type="button" value="Записать новую номенклатуру" onClick={()=>go()} className={main.inputAdd} /></div>
        </div>
    )
}
export default Comment;