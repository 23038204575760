import React from 'react';
import Check from './Check';
import KP from './KP';
import Message from './Message';
import Meeting from './Meeting';
import TestCheck from './TestCheck';
import TechConsultation from './TechConsultation';
import UseCaseShow from "./UseCaseShow";

const WhatType = (props) =>{

    const whatType=(type)=>{
        if(type==1 && props.showCheck){
            return <Check {...props}/>
        }else if(type==2 && props.showKp){
            return <KP {...props}/>
        }else if(type==3 && props.showMessage){
            return <Message {...props}/>
        }else if(type==4 && props.showTech){
            return <TechConsultation {...props} />
        }else if(type==5 && props.showMeeting){
            return <Meeting {...props} />
        }else if(type==6 && props.showTestCheck){
            return <TestCheck {...props} />
        }else if(type==666 && props.showUseCases){
            return <UseCaseShow {...props} />
        }else{
            return ""
        }
    }

    return whatType(props.type)
}
export default WhatType;