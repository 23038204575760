import React, {useRef, useState} from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import s from './AddNewKp.module.css';
import main from '../../../Main.module.css';
import signature_with_seal from "./signature_with_seal.png";
import logo_akon from "./logo_akon.png";
import Button from "@mui/material/Button";
import {Switch} from "@mui/material";
import CurrencyFormatter from "../../common/CurrencyFormatter/CurrencyFormatter";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

const PrintKp = React.forwardRef((props, ref) => {
    const {longVersion} = props;

    //get sum on notNDS
    let notNDSsum = Array.isArray(props?.newKp)
        ? Math.round(props?.newKp.
            reduce(
                (sum, item) => parseFloat(sum) + parseFloat(item.notNDS || 0.0) * item.coll, 0.0)
            * 100.0) / 100.0
        : 0;

    console.log(notNDSsum);

    console.log('notNDSsum', notNDSsum);

    //get sum of NDS
    let NDSSum = parseFloat((parseFloat(props?.sum) - parseFloat(notNDSsum)).toFixed(2));

    let totalModules = Array.isArray(props?.newKp) ? props?.newKp.reduce((sum, item) => parseInt(sum) + parseInt(item.coll), 0) : 0;

    return (
        <div ref={ref}>
            <Table>
                <TableHead className={s.h2Header}>
                    <TableCell>
                        <img src={logo_akon} className={s.logoImg} alt="Logo"/>
                    </TableCell>
                    <TableCell>
                        <p>ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ "АКОН-ГРУП"</p>
                        <p>tel: {props.workerData[0].tell} {props.workerData[0].name}, email: <a
                            href={"mailto:" + props.workerData[0].email}>{props.workerData[0].email}</a></p>
                        <p>04201, м. Київ, вулиця Полярна, будинок № 5А</p>
                    </TableCell>
                    <TableCell>
                        <p>Код за ЄДРПОУ 43379865</p>
                        <p>ІПН 433798626549</p>
                        <p>п/р UA903510050000026000878987235 у банку ПАТ "УКРСИББАНК", м.Київ</p>
                    </TableCell>
                </TableHead>
                {longVersion && <><br/><br/></>}
                <TableBody>
                    <TableRow>
                        <TableCell align="center" colSpan={3}>
                            <h2>Комерційна пропозиція #{props.lastIdKp}</h2>
                            <h3 className={main.center}>ДОБРИДЕНЬ! МИ РАДІ, ЩО ВИ ЗВЕРНУЛИСЯ ДО НАС.<br/>ПРОПОНУЄМО
                                РОЗГЛЯНУТИ
                                НАШУ ПРОПОЗИЦІЮ ЗГІДНО ВАШОГО ЗАПИТУ.</h3>
                            <h3>{props.company}</h3>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Table className={s.mainCheckDivPrint} size="small">
                {longVersion && <><br/><br/></>}
                <TableHead className={main.header}>
                    <TableRow align='center'>
                        <TableCell>#</TableCell>
                        <TableCell>Найменування</TableCell>
                        <TableCell>Опис</TableCell>
                        <TableCell>К-сть</TableCell>
                        <TableCell>Ціна шт. без ПДВ</TableCell>
                        <TableCell>Ціна шт. з ПДВ</TableCell>
                        <TableCell>Разом з ПДВ</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.newKp.map((k, index) => {
                        return (
                            <TableRow key={"newKp" + index} className={s.mainCheckDivPrint}>
                                <TableCell className={main.center}>{index + 1}</TableCell>
                                <TableCell>{k.name}</TableCell>
                                <TableCell className={main.left}>{k.desc}</TableCell>
                                <TableCell>{k.coll}</TableCell>
                                <TableCell><CurrencyFormatter amount={parseFloat(k.notNDS)}
                                                              withSpaces={true}/></TableCell>
                                <TableCell><CurrencyFormatter amount={parseFloat(k.withNds)}
                                                              withSpaces={true}/></TableCell>
                                <TableCell><CurrencyFormatter amount={parseFloat(k.withCollAndNds)} withSpaces={true}/></TableCell>
                            </TableRow>
                        )
                    })}

                    <TableRow className={s.mainCheckDivPrint}>
                        <TableCell className={main.right + " " + main.bold} colspan='3'>Модулів:</TableCell>
                        <TableCell className={main.left + " " + main.bold} >{totalModules}</TableCell>
                        <TableCell className={main.right + " " + main.bold} colspan='2'>Сума без ПДВ:</TableCell>
                        <TableCell><CurrencyFormatter amount={notNDSsum} withSpaces={true}/></TableCell>
                    </TableRow>

                    <TableRow className={s.mainCheckDivPrint}>
                        <TableCell className={main.right + " " + main.bold} colspan='6'>ПДВ 20%:</TableCell>
                        <TableCell><CurrencyFormatter amount={NDSSum} withSpaces={true}/></TableCell>
                    </TableRow>

                    <TableRow className={s.mainCheckDivPrint}>
                        <TableCell className={main.right + " " + main.bold} colspan='6'>Всього з ПДВ:</TableCell>
                        {/*<div>{props.collSum} </div>*/}
                        <TableCell><CurrencyFormatter amount={parseFloat(props.sum.toFixed(2))}
                                                      withSpaces={true}/></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Table>
                <TableRow sx={{marginTop: '20px'}}>
                    <TableCell>
                        <span className={main.red}>Умови:</span><br/> Варіант оплати: 100%
                        передплата. {props.discount !== 0 && props.discount !== "" && "Знижка: " + props.discount + "%."}
                        <br/>

                        <span>Термін постачання після оплати: {props.productionTime} днів.</span><br/>
                        <span className={main.red}>ЦЕЙ ДОКУМЕНТ НЕ Є ПІДСТАВОЮ ДЛЯ ОПЛАТИ. ТЕРМІН ДІЇ ПРОПОЗИЦІЇ СКЛАДАЄ НЕ
                            БІЛЬШЕ
                            3-Х РОБОЧИХ ДНІВ.</span>
                    </TableCell>
                </TableRow>
            </Table>
        </div>
    );
});

const PrintContainer = (props) => {
    const componentRef = useRef();
    const [longVersion, setLongVersion] = useState(false);

    const handlePrint = async () => {
        const element = componentRef.current;
        const pdf = new jsPDF('landscape', 'mm', 'a4');
        const pageHeight = pdf.internal.pageSize.getHeight();
        const margin = 10;
        const pdfWidth = pdf.internal.pageSize.getWidth() - margin * 2;

        const canvas = await html2canvas(element, {
            scale: 1,
            useCORS: true
        });

        const imgData = canvas.toDataURL('image/png', 0.4);
        const imgProps = pdf.getImageProperties(imgData);
        const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

        let heightLeft = imgHeight;
        let position = 0;

        // If the content height is greater than page height, split into multiple pages
        while (heightLeft > 0) {
            pdf.addImage(imgData, 'PNG', margin, position, pdfWidth, imgHeight);
            heightLeft -= pageHeight;
            if (heightLeft > 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
            }
        }

        pdf.save(`Commercial_Proposal_${props.lastIdKp}.pdf`);
    };

    return (
        <div>
            <Button color='success' variant='outlined' onClick={handlePrint}>Сохранить PDF</Button>
            <Switch checked={longVersion} onChange={() => setLongVersion(!longVersion)}/> Добавить пустые строки
            <br/>
            <PrintKp ref={componentRef} longVersion={longVersion} {...props} />
        </div>
    );
};

export default PrintContainer;
