import React, { useState } from 'react';
import s from './CheckTest.module.css';

const BuyModuls = (props) =>{
    const change = ()=>{
        if(newData!==0){
            props.buyModulsTest(props.showBuyModulsTest, newData)
            props.setShowBuyModulsTest(false)
        } 
    }
    let [newData, setNewData] = useState(0)
    const setNewDataF = (e)=>{
        setNewData(e.target.value)
    }
    return(
        <div className={s.changeDate}>
           <div><h3>Модули купили по заявке №{props.showBuyModulsTest}</h3></div>
           <div><p>Введите номер счёта</p></div>
           <div><input type="number" onChange={setNewDataF} placeholder="Введите номер счёта" value={newData} /></div>
           <div className={s.center}><button onClick={()=>change()}>Купили</button></div>
        </div>
    )
}
export default BuyModuls;