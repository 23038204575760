import { salesAPI } from "../api/api";

const SET_ALL_MAIN_SUPPLIER = "SET_ALL_MAIN_SUPPLIER";
const SET_MAIN_SUPPLIER_LIST = "SET_MAIN_SUPPLIER_LIST";


let initialState = {
    mainSupplier: [],
    supplierList: []
}

const mainSupplierReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_ALL_MAIN_SUPPLIER:
            return {
                ...state,
                mainSupplier: action.mainSupplier
            }
        case SET_MAIN_SUPPLIER_LIST:
            return {
                ...state,
                supplierList: action.supplierList
            }
        default:
            return state
    }
}

export const setAllMainSupplier = (mainSupplier) => ({type: SET_ALL_MAIN_SUPPLIER, mainSupplier});
export const setMainSupplierList = (supplierList) => ({type: SET_MAIN_SUPPLIER_LIST, supplierList});

export const getAllMainSupplier= () =>{
    return (dispatch) => {
        salesAPI.getAllMainSupplier()
        .then(data => {
            dispatch(setAllMainSupplier(data));
        });
    }
}
export const setNewMainSupplier = (name)=>{
    return (dispatch) => {
        salesAPI.setNewMainSupplier(name)
        .then(data => {
            dispatch(getAllMainSupplier());
        });
    }
}
export const changeMainSupplier = (change)=>{
    return (dispatch) => {
        salesAPI.changeMainSupplier(change)
        .then(data => {
            dispatch(getAllMainSupplier());
        });
    }
}
export const getMainSupplierList = ()=>{
    return (dispatch) => {
        salesAPI.getMainSupplierList()
        .then(data => {
            dispatch(setMainSupplierList(data));
        });
    }
}


export default mainSupplierReducer;

