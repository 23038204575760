import React from 'react';

const GetStatusProduction = (props) => {
    const getStatus=(status)=>{
        switch(Number(status)){
            case 0: return "распределение"
            case 1: return "монтаж"
            case 2: return "первичка"
            case 3: return "корпусировка"
            case 4: return "вых. контроль"
            case 5: return "склад"
            default: return "фиг знает"
        }
    }
    return getStatus(props.status)
}
export default GetStatusProduction;
