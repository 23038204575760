import React from 'react';
import MenuStatistics from './MenuStatistics';
import s from './Statistics.module.css';

const Statistics = (props) =>{
    
    return(
        <div className={s.mainPageStatistics}>
            <MenuStatistics />
        </div>
    )
}
export default Statistics;