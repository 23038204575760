import React from 'react';
import Programmer from './Programmer';
import { connect } from 'react-redux';
import {getNewModuleFromProduction} from '../../../../redux/productionMain-reducer';

class ProgrammerContainer extends React.Component{
    componentDidMount(){
        this.props.getNewModuleFromProduction();
    }
    render(){
        
        return(
            <Programmer newModuleFromProduction={this.props.newModuleFromProduction}/>
        )
    }
    
}

let mapStateToProps = (state) =>{
    return{
        newModuleFromProduction: state.productionMain.newModuleFromProduction,
    }  
}

export default connect(mapStateToProps, {getNewModuleFromProduction})(ProgrammerContainer);