import React, { useState } from 'react';
import s from './CheckTest.module.css';

const ChangeDate = (props) =>{
    const change = ()=>{
        if(newData.length==14){
            props.returnModulsTest(props.showReturnModulsTest, newData)
            props.setShowReturnModulsTest(false)
        }else{
            setError(true)
            console.log(newData.length)
        } 
    }
    let [error, setError] = useState(false)
    let [newData, setNewData] = useState(0)
    const setNewDataF = (e)=>{
        setNewData(e.target.value)
    }
    return(
        <div className={s.changeDate}>
           <div><h3>Модули вернули по заявке №{props.showReturnModulsTest}</h3></div>
           <div><p>Введите номер ТТН </p></div>
           <div><input type="number" onChange={setNewDataF} value={newData} /></div>
           <div className={s.center}><button onClick={()=>change()}>Вернуть</button></div>
           {error && <div className={s.center+" "+s.red}>Длинна должна быть 14 символов</div>}
        </div>
    )
}
export default ChangeDate;