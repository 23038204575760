import React from 'react';
import { useState, useEffect } from 'react';
import s from './AddNewNomenclatures.module.css';

const AddNewNomenclatures = (props) =>{
    let [text, addText] = useState("")
    let [status, setStatus] = useState(props.statusNomenclatures)
    useEffect(() => {
        setStatus([...props.statusNomenclatures]);
    }, [props.statusNomenclatures])
    const addTextF=(e)=>{addText(e.target.value);setStatus("")}
    //по нажатию энтера
    let handleKeyPress = (e) =>{
        if (e.charCode === 13){ // Клавиша Enter
            props.addNewNomenclatures(text)
            addText("")
        }
    }
    return(
        <div>
            <input type="text" value={text} onChange={addTextF} onKeyPress={handleKeyPress} />
            {0<status.length && <p>{status}</p>}
        </div>
    )
}

export default AddNewNomenclatures;