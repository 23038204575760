import React, { useState } from 'react';
import main from '../../../Main.module.css';
import { NavLink } from 'react-router-dom';
import s from '../Future.module.css';

const Menu = (props) =>{
    return(
        <div className={s.menu}>
            <NavLink to="/tech/futuredev"><div>Фьючер</div></NavLink>
            <NavLink to="/tech/futuredev/channelchains"><div>Цепочки каналов</div></NavLink>
            <NavLink to="/tech/nomenclaturesName"><div>Названия устройств</div></NavLink>
        </div>
    )
}
export default Menu;