import React, { useState } from 'react';
import s from '../SalesCheck.module.css';
import main from '../../../Main.module.css';
import AnyFormatDate from '../../../Production/common/AnyFormatDate/AnyFormatDate';
import { faTrash, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AllComment = (props) =>{
    console.log(props.workersList)
    const setAddCommentF=()=>{
        props.setAddComment(props.id)
        props.setAllComment(false)
    }
    const getName = (whos)=>{
        let name = props.workersList.find(k=>{ return k.login_id==whos })
        return name.name
    }
    return(
        <div>
            <h2>Комментарии</h2>
            <div><button onClick={()=>setAddCommentF()} className={main.inputAdd}>Добавить</button></div>
            {props.salesInvoceComment.map((k,index)=>{
                return(
                    k.check_id==props.id &&
                    <div className={s.comment} key={"salesInvoceComment"+index}>
                        <div className={s.blocksGrid}>
                            <div><FontAwesomeIcon icon={faUser} />{getName(k.whos_comment)}</div>
                            <div className={main.right+" "+s.date}><AnyFormatDate date={k.date_add} /></div>
                            <div className={main.right+" "+main.red}><FontAwesomeIcon icon={faTrash} onClick={()=>props.deleteCommentForSalesCheck(k.id)} /></div>
                        </div>
                        <div>{k.comment}</div>
                        
                    </div>
                    
                )
            })}
        </div>
    )
}
export default AllComment;