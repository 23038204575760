import React from 'react';
const Admin = (props) => {
    return (
        <div>
            Главная страница
        </div>
    );
}

export default Admin;
