import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getCvoMoney } from '../../../../redux/admin-reducer';
import Balance from './Balance';

class BalanceContainer extends React.Component {

    componentDidMount() {
        let month;
        if (this.props.month) {
            month = this.props.month;
        } else {
            let date = new Date();
            month = date.getMonth() + 1;
        }
        this.props.getCvoMoney(month);
    }

    componentDidUpdate(prevProps) {
        if (this.props.month !== prevProps.month) {
            this.props.getCvoMoney(this.props.month);
        }
    }

    render() {
        return (
            <div>
                <Balance cvoMoney={this.props.cvoMoney} result={this.props.result} />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    cvoMoney: state.admin.cvoMoney
});

export default compose(
    connect(mapStateToProps, { getCvoMoney })
)(BalanceContainer);
