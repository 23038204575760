import React, { useState } from 'react';
import s from './List.module.css';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import { productionAPI } from "../../../../../api/api";
import { toast } from "react-toastify";

let List = (props) => {
    let [listState, setlistState] = useState("-");
    let [dialogOpen, setDialogOpen] = useState(false);
    let [articles, setArticles] = useState([]);
    let [subArticles, setSubArticles] = useState([]);
    let [filteredSubArticles, setFilteredSubArticles] = useState([]); // To store filtered subarticles
    let [selectedArticle, setSelectedArticle] = useState('');
    let [selectedSubArticle, setSelectedSubArticle] = useState('');

    let changelistState = () => {
        (listState === "-") ? setlistState("+") : setlistState("-")
    }

    let openDialog = async () => {
        try {
            setDialogOpen(true);
            const response = await productionAPI.getDelistingReasons();
            if (response?.status === 1) {
                setArticles(response?.data.articles);
                setSubArticles(response?.data?.subarticles);
            } else {
                toast.error("Ошибка при загрузке данных");
            }
        } catch (err) {
            console.error("Error fetching delisting reasons:", err);
        }
    };

    let closeDialog = () => {
        setDialogOpen(false);
    }

    // Handle article selection and filter subarticles based on the selected article's ID
    let handleArticleChange = (articleId) => {
        setSelectedArticle(articleId);

        // Filter subarticles based on the selected article
        const filteredSubs = subArticles.filter(subArticle => subArticle.parent_article_id === articleId);
        setFilteredSubArticles(filteredSubs);

        // Clear selected sub-article when article changes
        setSelectedSubArticle('');
    }

    let handleSave = () => {
        saveComponentsColl(selectedArticle, selectedSubArticle);
    }

    let saveComponentsColl = (article, subArticle) => {
        let mainList = [];
        let saveNewComponent = true;
        let coll = 0;
        if(selectedArticle === '') {
            toast.info("Выберите статью списания");
            return;
        }
        if(selectedSubArticle === '') {
            toast.info("Выберите подстатью списания");
            return;
        }
        //console.log("article", article);
        //console.log("subArticle", subArticle);
        // return;

        props.list.map(g => {
            if (props.addOrTakeProducts == 1) {
                g.coll = Number(g.coll) + Number(g.value)
                mainList.push({ id: g.id, coll: g.coll, code: g.code });
            } else {
                coll = g.coll - g.value;
                if (coll < 0) {
                    saveNewComponent = false;
                } else {
                    g.coll = coll;
                    mainList.push({ id: g.id, coll: g.coll, code: g.code });
                }
            }
        });

        if (saveNewComponent) {
            props.changeCollComponent(JSON.stringify(mainList), props.sectionURL, props.personalId, props.addOrTakeProducts, article, subArticle);
            props.setList([]);
            props.setCollComponents(0);
            setDialogOpen(false);
        } else {
            alert("Значение не может быть меньше 0");
        }
    }

    return (
        <>
            {
                (props.list.length !== 0 && listState !== "+")
                    ? <div className={s.main}>
                        <div className={s.list_h3}>{(props.addOrTakeProducts == 1) ? "Добавление" : "Списание"}
                            <div className={s.listState} onClick={changelistState}>{listState}</div>
                        </div>
                        <div className={s.line}>
                            {
                                props.list.map(p => {
                                    return (
                                        <>
                                            <div className={s.name}>{p.name}</div>
                                            <div className={s.coll}>
                                                {
                                                    (props.addOrTakeProducts == 1)
                                                        ? Number(p.coll) + Number(p.value)
                                                        : p.coll - p.value
                                                }</div>
                                        </>
                                    )
                                })
                            }
                        </div>
                        <div className={s.saveList}>
                            <button onClick={openDialog}>Сохранить</button>
                        </div>
                    </div>
                    : <div className={s.main}>
                        <div className={s.list_h3}>{(props.addOrTakeProducts == 1) ? "Добавление" : "Списание"}
                            <div className={s.listState} onClick={changelistState}>{listState}</div>
                        </div>
                    </div>
            }

            {/* Dialog for selecting article and sub-article */}
            <Dialog open={dialogOpen} onClose={closeDialog}>
                <DialogTitle>Выберите статью и подстатью списания</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth sx={{ marginTop: '20px' }}>
                        <InputLabel>Статья</InputLabel>
                        <Select
                            value={selectedArticle}
                            onChange={(e) => handleArticleChange(e.target.value)}
                        >
                            {articles?.map((article) => (
                                <MenuItem key={article.id} value={article.id}>{article.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ marginTop: '20px' }} disabled={!selectedArticle}>
                        <InputLabel>Подстатья</InputLabel>
                        <Select
                            value={selectedSubArticle}
                            onChange={(e) => setSelectedSubArticle(e.target.value)}
                        >
                            {filteredSubArticles?.map((subArticle) => (
                                <MenuItem key={subArticle.id} value={subArticle.id}>{subArticle.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSave} color="primary">Вперёд!</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default List;
