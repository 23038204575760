import { suppliersAPI } from "../api/api";

const SET_ALL_SUPPLIERS = "SET_ALL_SUPPLIERS";
const SET_COMPONENT_ID = "SET_COMPONENT_ID";
const SET_COMPONENT_SUPPLIERS = "SET_COMPONENT_SUPPLIERS";
const SET_COMPONENT_LINK_FOR_BUY = "SET_COMPONENT_LINK_FOR_BUY";
const SET_STORAGE_COMPONENT_PERSONAL_DATA = "SET_STORAGE_COMPONENT_PERSONAL_DATA";
const SET_PURCHASED_COMPONENTS = "SET_PURCHASED_COMPONENTS";


let initialState = {
    allSuppliers: [],
    componentSuppliers: [],
    componentId: 0,
    linkForBuy: [],
    storageComponentPersonalData: [],
    purchasedComponents: []
}

const suppliersReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_ALL_SUPPLIERS:
            return {
                ...state,
                allSuppliers: action.suppliers
            }
        case SET_COMPONENT_SUPPLIERS:
            return {
                ...state,
                componentSuppliers: action.suppliers
            }
        case SET_COMPONENT_ID:
            return {
                ...state,
                componentId: action.componentId
            }
        case SET_COMPONENT_LINK_FOR_BUY:
            return {
                ...state,
                linkForBuy: action.linkForBuy
            }
        case SET_STORAGE_COMPONENT_PERSONAL_DATA:
            return {
                ...state,
                storageComponentPersonalData: action.data
            }
        case SET_PURCHASED_COMPONENTS:
            return {
                ...state,
                purchasedComponents: action.purchasedComponents
            }
        default:
            return state
    }
}

export const setAllSuppliers= (suppliers) => ({type: SET_ALL_SUPPLIERS, suppliers});
export const setComponentId= (componentId) => ({type: SET_COMPONENT_ID, componentId});
export const setComponentSuppliersInState = (suppliers) => ({type: SET_COMPONENT_SUPPLIERS, suppliers});
export const setComponentLinkForBuy = (linkForBuy) => ({type: SET_COMPONENT_LINK_FOR_BUY, linkForBuy});
export const setStorageComponentPersonalData = (data) => ({type: SET_STORAGE_COMPONENT_PERSONAL_DATA, data});
export const setPurchasedComponents = (purchasedComponents) => ({type: SET_PURCHASED_COMPONENTS, purchasedComponents});

export const getAllSuppliers= () =>{
    return (dispatch) => {
        suppliersAPI.getAllSuppliers()
        .then(data => {
            dispatch(setAllSuppliers(data));
        });
    }
}
export const getComponentSuppliers= (componentId) =>{
    return (dispatch) => {
        suppliersAPI.getComponentSuppliers(componentId)
        .then(data => {
            dispatch(setComponentSuppliersInState(data));
        });
    }
}
export const getPurchasedComponents= (componentId) =>{
    return (dispatch) => {
        suppliersAPI.getPurchasedComponents(componentId)
        .then(data => {
            dispatch(setPurchasedComponents(data));
        });
    }
}
export const getStorageComponentPersonalData= (componentId) =>{
    return (dispatch) => {
        suppliersAPI.getStorageComponentPersonalData(componentId)
        .then(data => {
            dispatch(setStorageComponentPersonalData(data));
        });
    }
}
export const setComponentSuppliers = (companyId, checked, componentId) =>{
    return (dispatch) => {
        suppliersAPI.setComponentSuppliers(companyId, checked, componentId)
        .then(data => {
            dispatch(getComponentSuppliers(componentId));
        });
    }
}
export const setNewLinkForBy = (companyId, link, componentId) =>{
    return (dispatch) => {
        suppliersAPI.setNewLinkForBy(companyId, link, componentId)
        .then(data => {
            dispatch(getComponentLinkForBuy(componentId));
        });
    }
}
export const getComponentLinkForBuy = (componentId) =>{
    return (dispatch) => {
        suppliersAPI.getComponentLinkForBuy(componentId)
        .then(data => {
            dispatch(setComponentLinkForBuy(data));
        });
    }
}


export default suppliersReducer;