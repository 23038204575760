import React, { useState } from 'react';
import main from '../../../Main.module.css';
import AddAgents from '../../../Production/Check/Messages/Modal/AddAgents';
import Modal from '../../../Production/common/Modal/Modal';
import CompanyAndAgentsContainer from '../../common/CompanyAndAgents/CompanyAndAgentsContainer';

const AddMeeting = (props) =>{
    let [companyId, setCompanyId] = useState(0)
    let [agent, setAgent] = useState("")
    let [addAgent, setAddAgent] = useState(false)
    let [date, setDate] = useState("")
    const setDateF = (e)=>setDate(e.target.value)
    let [text, setText] = useState("")
    const setTextF = (e)=>setText(e.target.value)
    const go = () =>{
        let array = [];
        array.push(props.idManager, companyId, agent, date, text)
        props.addNewMeeting(props.idManager, array)
        // console.log(props.idManager, array)
        props.setActive(false)
    }
    return(
        <div>
            {addAgent &&
                <Modal active={addAgent} setActive={setAddAgent}>
                    <AddAgents active={addAgent} setActive={setAddAgent} companyId={companyId} setNewCompanyAgent={props.setNewCompanyAgent}/>
                </Modal>
            }
            <h2 className={main.center}>Новая встреча</h2>
            <CompanyAndAgentsContainer setCompanyId={setCompanyId} companyId={companyId} setAddAgent={setAddAgent} setContact={setAgent}/>
            {/* <div>
                <input type="text" value={company} list="companyList" onChange={setCompanyF}/>
                <datalist  id="companyList">
                    {props.companyList.map(k=>{ return <option value={k.name_with_akon} id={k.id}>{k.name_with_akon}</option>})}
                </datalist>
            </div>
            <div>
                <select>
                    {props.allAgents.map(k=>{return k.companyId==companyId && <option value={k.name_with_akon}>{k.name_with_akon}</option>})}
                </select>
            </div> */}
            <div className={main.bold+" "+main.center}>Плановая дата</div>
            <div><input type="date" className={main.input} value={date} onChange={setDateF} /></div>
            <div className={main.bold+" "+main.center}>Задачи на встречу</div>
            <div><textarea value={text} onChange={setTextF} className={main.textarea} /></div>
            <div className={main.center}><input type="button" value="Добавить" onClick={()=>go()} className={main.inputAdd} /></div>
        </div>
    )
}
export default AddMeeting;