import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import s from '../SalesPersonal.module.css';

const SetPeriod = (props) => {
    // Get current date
    const getCurrentDate = () => {
        const currentDate = new Date();
        return currentDate.toISOString().split('T')[0];
    };

    // Get past date by subtracting days
    const getPastDate = (days) => {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - days);
        return currentDate.toISOString().split('T')[0];
    };

    const now = getCurrentDate();
    const end = getPastDate(14);

    // Use the useParams hook to get personalId from the URL
    const { personalId: paramPersonalId } = useParams();
    const personalId = (Cookies.get('id') === "44" || Cookies.get('id') === "34")
        ? paramPersonalId
        : Cookies.get('id');

    // State for start and end date
    const [dateStart, setDateStart] = useState(end);
    const [dateEnd, setDateEnd] = useState(now);

    // State for search query
    const [search, setSearch] = useState("");

    // Function to fetch activity
    const fetchActivity = () => {
        props.getAllActivity(personalId, dateStart, dateEnd, search);
    };

    // Handle Enter key press
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            fetchActivity();
        }
    };

    return (
        <div className={s.searchAndPeriod}>
            <div>
                <input
                    type="text"
                    placeholder="Введите название компании"
                    value={search}
                    className={s.search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
            </div>
            <div className={s.period}>
                <div>
                    <input
                        type="date"
                        value={dateStart}
                        onChange={(e) => setDateStart(e.target.value)}
                        max={dateEnd}
                    />
                </div>
                <div>
                    <input
                        type="date"
                        value={dateEnd}
                        onChange={(e) => setDateEnd(e.target.value)}
                        min={dateStart}
                    />
                </div>
                <div>
                    <button onClick={fetchActivity}>Выбрать</button>
                </div>
            </div>
        </div>
    );
};

export default SetPeriod;
