import React from 'react';
import { connect } from 'react-redux';
import {getRepair, setNewWorkerInRepair, addModuleFromRepair, addCompanyFromRepair, setAssurance, getPersonalRepairsList, setNewDefects} from '../../../../redux/repair-reducer';
import RepairTech from './RepairTech';

class RepairTechContainer extends React.Component{
    componentDidMount(){
        this.props.getRepair();
        this.props.getPersonalRepairsList(null);
    }
    
    render(){
        return (
            <div>
                <RepairTech repair={this.props.repair} setNewWorkerInRepair={this.props.setNewWorkerInRepair} addModuleFromRepair={this.props.addModuleFromRepair} setAssurance={this.props.setAssurance} personalRepairsList={this.props.personalRepairsList} setNewDefects={this.props.setNewDefects}/>
            </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    repair: state.repair.repair,
    personalRepairsList: state.repair.personalRepairsList,
})
export default connect( mapStateToProps, {getRepair, setNewWorkerInRepair, addModuleFromRepair, addCompanyFromRepair, setAssurance, getPersonalRepairsList, setNewDefects})(RepairTechContainer);