import React from 'react';
import AnyFormatDate from '../../../Production/common/AnyFormatDate/AnyFormatDate';
import s from '../SalesPersonal.module.css';
import {NavLink} from "react-router-dom";

const Meeting = (props) =>{
    return(
        <div className={s.main}>
            <div>Встреча</div>
            <div>
                <p><NavLink to={"/company/profile/"+props.company_id} target="_blank" rel="noopener noreferrer">{props.name_with_akon}</NavLink></p>
                <p>{props.name}</p>
            </div>
            <div><AnyFormatDate date={props.dateMain}/></div>
            
        </div>
    )
}
export default Meeting;