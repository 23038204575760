import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import s from './Production.module.css';

const AddTTN = (props) =>{
    const [cookies, setCookie] = useCookies();
    const change = ()=>{
        if(newData.length==14 || newData==0){
            let array = [];
            array.push(props.active)
            array.push(newDate)
            array.push(newData)
            array.push(props.typeCheck)
            props.setNewTTN(array, cookies.id)
            props.setActive(false)
        }else{
            setError(true)
        }
    }
    let [newData, setNewData] = useState("")
    const setNewDataF = (e)=>{
        setNewData(e.target.value)
    }
    let [newDate, setNewDate] = useState("")
    const setNewDateF = (e)=>{
        setNewDate(e.target.value)
    }
    let [error, setError] = useState(false)
    return(
        <div>
            <h2>Ввести ТТН и дату по счёту</h2>
            <div className={s.ttn}>
                <div><p>Введите номер ТТН </p></div>
                <div><input type="number" onChange={setNewDataF} value={newData} /></div>
                <div><p>Введите дату ТТН </p></div>
                <div><input type="date" onChange={setNewDateF} /></div>
                <div><button onClick={()=>change()}>Отправить</button></div>
                {error && <div className={s.center+" "+s.red}>Длинна ТТН должна быть 14 символов</div>}
            </div>
        </div>
    )
}
export default AddTTN;