import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getSumSubentriesGrupMonth } from '../../../redux/admin-reducer';
import { getAllArticles } from '../../../redux/cash-reducer';
import DDCNew from './DDCNew';
import { useParams } from 'react-router-dom';

class DDCNewContainer extends React.Component {
    componentDidMount() {
        const { year } = this.props.params;
        this.props.getSumSubentriesGrupMonth(year);
        this.props.getAllArticles();
    }

    render() {
        return (
            <div>
                <DDCNew {...this.props} />
            </div>
        );
    }
}

let mapStateToProps = (state) => ({
    sumSubentriesGrupMonth: state.admin.sumSubentriesGrupMonth,
    articles: state.cash.articles,
});

// Custom withRouter replacement using useParams
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const params = useParams();
        return <Component {...props} params={params} />;
    }

    return ComponentWithRouterProp;
}

export default compose(
    connect(mapStateToProps, { getSumSubentriesGrupMonth, getAllArticles }),
    withRouter
)(DDCNewContainer);
