import React, {useState} from 'react';
import s from "./AddComponent.module.css";
import {Autocomplete, Button, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import {productionAPI} from "../../../../api/api";

const AddComponent = (props) => {
    console.log(props.sectionURL)
    let [nameNewComponent, setNameNewComponent] = useState("");
    let [specificationIdNewComponent, setSpecificationIdNewComponent] = useState("");
    let [section, setSection] = useState(props.sectionURL);
    let [sectionSelector, setSectionSelector] = useState();

    let setName = (e) => {
        setNameNewComponent(e.target.value);
    }
    let setSpecificationId = (e) => {
        setSpecificationIdNewComponent(e.target.value.toUpperCase());
    }
    const setSectionF = (value) => {
        console.log(value);
        setSectionSelector(value);
        setSection(value.value);
    }
    let addNewComponent = () => {
        if (nameNewComponent != "" && specificationIdNewComponent != "") {
            //console.log(section, nameNewComponent, specificationIdNewComponent)
            props.setComponentToSection(section,nameNewComponent, 0,specificationIdNewComponent)
        } else {
            alert("Заполните все поля!");
        }

    }
    let handleSubmit = (event) => {
        event.preventDefault();
    }
const [storageSectionsList, setStorageSectionsList] = useState([]);
    const fetchStorageSectionsList = async () => {
        try {
            const response = await productionAPI.getSectionNew();
            const data = response.data;
            setStorageSectionsList(data);
            console.log(data);
        } catch (error) {
            console.error("Error fetching storage sections list:", error);
        }
    };

    useState(() => {
        fetchStorageSectionsList();
    },[]);

    const sectionsList = storageSectionsList?.map(k => {
        return {label: k.section, value: k.id}
    })

    return (
        <>
            {<>
                <Autocomplete
                    disablePortal
                    options={sectionsList}
                    onChange={(event, value) => setSectionF(value)}
                    renderInput={(params) => <TextField {...params} label="Тип элемента"/>}
                    style={{marginBottom: "10px"}}
                />
                <TextField
                    id="standard-basic"
                    label="Спец. номер"
                    variant="standard"
                    onChange={setSpecificationId}
                    value={specificationIdNewComponent}
                    style={{marginRight: "10px"}}
                />
                <TextField
                    id="standard-basic"
                    label="Название компонента"
                    variant="standard"
                    onChange={setName}
                    value={nameNewComponent}
                    style={{marginLeft: "10px"}}
                />
                <Box
                    style={{
                        marginTop: "10px",
                        marginLeft: "20%",
                        marginRight: "20%",
                }}
                >
                    <Button
                        variant="contained"
                        color="success"
                        onClick={addNewComponent}
                        style={{width: "100%"}}
                    >
                        Добавить компонент
                    </Button>
                </Box>
            </>
            }

        </>);
}
export default AddComponent;