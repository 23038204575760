import React, { useState } from 'react';
import MenuStatistics from '../MenuStatistics';
import s from './StatisticsBranch.module.css';

const StatisticsBranch = (props) =>{
    let getProcent = (companySum) =>{
        let sum = Number(companySum)*100/Number(props.statisticsBranchSum)
        return sum.toFixed(2)+" %";
    }
    let getNewStatisticsWithYear = (year)=>{
        props.getStatisticsBranch(year)
        props.getStatisticsBranchSum(year)
    }
    return(
        <div>
            <MenuStatistics />
            <h1>Статистика по отраслям</h1>
            <div className={s.year}>
                {
                    props.paidChecksYear.map(p=>{
                        return <div onClick={()=>getNewStatisticsWithYear(p.year)}>{p.year}</div>
                    })
                }
                <div onClick={()=>getNewStatisticsWithYear("0")}>За все года</div>
            </div>
            <div>
                
                <div className={s.main+" "+s.header}>
                    <div>Название</div>
                    <div>Кол. шт.</div>
                </div>
            {
                props.statisticsBranch.map((p, i)=>{
                    return(
                        <div className={s.main} key={i}>
                            <div>{p.name}</div>
                            <div>{getProcent(p.sum)}</div>
                        </div>
                    )
                })
            }
            
            </div>
        </div>
    )
}
export default StatisticsBranch;