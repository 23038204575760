import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getAllActivity, addNewNomenclatures } from '../../../redux/salesPersonal-reducer';
import SalesPersonal from './SalesPersonal';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';

class SalesPersonalContainer extends React.Component {
    componentDidMount() {
        let isAdmin = !!Cookies.get('adminID');
        let currentPersonalId = this.props.params.personalId;
        if (!isAdmin) {
            if (Cookies.get('id') !== currentPersonalId) {
                currentPersonalId = Cookies.get('id');
            }
        }
        this.props.getAllActivity(currentPersonalId);
    }

    render() {
        return (
            <div>
                <SalesPersonal
                    allActivity={this.props.allActivity}
                    getAllActivity={this.props.getAllActivity}
                    personalId={this.props.params.personalId}
                    addNewNomenclatures={this.props.addNewNomenclatures}
                    statusNomenclatures={this.props.statusNomenclatures}
                    lastIdCompany={this.props.lastIdCompany}
                />
            </div>
        );
    }
}

let mapStateToProps = (state) => ({
    allActivity: state.salesPersonal.allActivity,
    statusNomenclatures: state.salesPersonal.statusNomenclatures,
    lastIdCompany: state.company.lastIdCompany,
});

// Custom withRouter replacement using useParams
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const params = useParams();
        return <Component {...props} params={params} />;
    }

    return ComponentWithRouterProp;
}

export default compose(
    connect(mapStateToProps, { getAllActivity, addNewNomenclatures }),
    withRouter
)(SalesPersonalContainer);
