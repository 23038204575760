import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
    getAllMeetingWithManager,
    addNewMeeting,
    changeMeetingText,
    deleteMeeting,
    сloseMeeting,
    changeDateReminderKp
} from '../../../redux/sales-reducer';
import { getAllAgents } from '../../../redux/company-reducer';
import { getCompanyList } from '../../../redux/other-reducer';
import Meeting from './Meeting';
import Cookies from 'js-cookie';

const MeetingContainer = (props) => {
    const { idManager } = useParams();
    let currentPersonalId = idManager;

    useEffect(() => {
        let isAdmin = !!Cookies.get('adminID');
        if (!isAdmin && Cookies.get('id') !== currentPersonalId) {
            currentPersonalId = Cookies.get('id');
        }
        props.getAllMeetingWithManager(currentPersonalId);
        props.getAllAgents();
        props.getCompanyList();
    }, [idManager, props]);

    return (
        <div>
            <Meeting {...props} />
        </div>
    );
};

const mapStateToProps = (state) => ({
    allMeetingWithManager: state.sales.allMeetingWithManager,
    allAgents: state.company.allAgents,
    companyList: state.other.companyList,
});

export default compose(
    connect(mapStateToProps, {
        getAllMeetingWithManager,
        getAllAgents,
        getCompanyList,
        addNewMeeting,
        changeMeetingText,
        deleteMeeting,
        сloseMeeting,
        changeDateReminderKp
    })
)(MeetingContainer);
