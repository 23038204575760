import React from 'react';
import { connect } from 'react-redux';
import {getNomenclaturesName, changeNomenclaturesNames} from '../../../../redux/future-reducer';
import NomenclaturesName from './NomenclaturesName';

class NomenclaturesNameContainer extends React.Component{
    
    componentDidMount(){
        this.props.getNomenclaturesName();
    }
    
    render(){
        return <NomenclaturesName {...this.props}/>
    } 
}

let mapStateToProps = (state) =>({
    nomenclaturesName: state.future.nomenclaturesName,
})
export default connect( mapStateToProps, {getNomenclaturesName, changeNomenclaturesNames})(NomenclaturesNameContainer);