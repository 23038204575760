import React from 'react';
import s from '../Task.module.css';

const DeleteTask = (props) => {
    const handleDelete = () => {
        props.setShowDeleteTask(false);
        props.deleteTask(props.tasksId, props.statusDeleteTask, props.personalId);
    };

    return (
        <div className={s.addComment}>
            <div><h3>Удаление задачи №{props.tasksId}</h3></div>
            <div className={s.center}><button onClick={handleDelete}>Удалить</button></div>
        </div>
    );
};

export default DeleteTask;
