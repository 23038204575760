const ManagerName = ({ managerID }) => {
    const managersList = [
        { id: 1, name: 'Дунаев Алексей' },
        { id: 44, name: 'Дунаев Алексей' },
        { id: 2, name: 'Осадчий Олег' },
        { id: 297, name: 'Марченко Андрей' },
        { id: 486, name: 'Осадчий Олег' },
        { id: 1055, name: 'Мартыненко Евгений' },
        { id: 1056, name: 'Кучер Евгений' },
        { id: 1061, name: 'Сталин Дмитрий' },
    ];

    const manager = managersList.find((item) => item.id === parseInt(managerID));

    if (manager) {
        return manager.name;
    }

    return '';
};

export default ManagerName;
