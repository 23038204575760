import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom'; // Замість withRouter
import { getNomenclaturesData } from '../../../../redux/specification-reducer';
import DescriptionPersonalModule from './DescriptionPersonalModule';

const DescriptionPersonalModuleContainer = (props) => {
    const { nom } = useParams(); // Отримуємо параметр productId

    useEffect(() => {
        props.getNomenclaturesData(nom);
    }, [nom]); // Виконується при зміні productId

    return props?.nomenclaturesData?.NAME
        ?<DescriptionPersonalModule nomenclaturesData={props.nomenclaturesData} productId={nom} />
        : <center><h1>НЕТ ИНФОРМАЦИИ О МОДУЛЕ</h1></center>;
};

let mapStateToProps = (state) => ({
    nomenclaturesData: state.specification.nomenclaturesData,
});

export default compose(
    connect(mapStateToProps, { getNomenclaturesData })
)(DescriptionPersonalModuleContainer);
