import React from 'react';
import { connect } from 'react-redux';
import {getAllNomenclaturesData, getNomenclaturesName} from '../../../redux/future-reducer';
import Future from './Future';

class FutureContainer extends React.Component{
    
    componentDidMount(){
        this.props.getAllNomenclaturesData("");
        this.props.getNomenclaturesName();
    }
    
    render(){
        return <Future {...this.props}/>
    } 
}

let mapStateToProps = (state) =>({
    allNomenclaturesData: state.future.allNomenclaturesData,
    allChannelsData: state.future.allChannelsData,
    nomenclaturesName: state.future.nomenclaturesName,
})
export default connect( mapStateToProps, {getAllNomenclaturesData, getNomenclaturesName})(FutureContainer);