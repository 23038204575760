import React from 'react';
import { NavLink } from 'react-router-dom';
import AnyFormatDate from '../../../Production/common/AnyFormatDate/AnyFormatDate';
import s from '../SalesPersonal.module.css';

const KP = (props) =>{
    return(
        <div className={s.main}>
            <div>КП {props.paid!=null ? <span className={s.green}>- оплачен</span> : <span className={s.red}>- не оплачен</span>}</div>
            <div><NavLink to={"/check/"+props.id+"/4"} target="_blank">{props.name}</NavLink></div>
            <div><AnyFormatDate date={props.dateMain}/></div>
            
        </div>
    )
}
export default KP;