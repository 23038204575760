import React, { useState } from 'react';
import s from './TemplateTable.module.css';
import List from './List/List';
import { NavLink } from 'react-router-dom';
import SelectChangeSectionComponent from './SelectChangeSectionComponent';
import ChangeMinColl from './ChangeMinColl/ChangeMinColl';
import Modal from '../Modal/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import StorageComponentChangeName from "../../Storage/StorageComponentChangeName";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import ModulesWithComponentContainer from "../../Moduls/ModulesWithComponentContainer";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

const TemplateTable = (props) => {
    const [addOrTakeProducts, setAddOrTakeProducts] = useState(0);
    const [inputCollComponents, setCollComponents] = useState({});
    const [list, setList] = useState([]);
    const [sectionId, setSectionId] = useState(0);
    const [showChangeMinColl, setShowChangeMinColl] = useState(false);

    const rawComponent = Array.isArray(props?.component) ? props.component : [];

    const component = rawComponent.sort((a, b) => {
        let aSort = a?.specification_id.slice(0, -3);
        let bSort = b?.specification_id.slice(0, -3);
        return aSort - bSort;
    });

    const addOrTake = (addOrTakeProducts) => {
        setAddOrTakeProducts(addOrTakeProducts);
    };

    const onComponentCollChange = (e) => {
        const { id, value } = e.target;
        setCollComponents((prev) => ({
            ...prev,
            [id]: value,
        }));

        const componentItem = component.find(item => item.id.toString() === id);

        if (!componentItem) {
            console.error(`Component with id ${id} not found`);
            return;
        }

        const updatedList = list.map((item) =>
            item.id.toString() === id ? { ...item, value: value } : item
        );

        if (!updatedList.some(item => item.id.toString() === id)) {
            updatedList.push({
                id: id,
                code: componentItem.specification_id,
                name: componentItem.name,
                coll: componentItem.coll,
                value: value
            });
        }

        setList(updatedList);
    };

    const findValue = (id) => {
        const foundItem = list.find(item => item.id.toString() === id);
        return foundItem ? foundItem.value : "";
    };

    const setSectionIdFunc = (e) => {
        setSectionId(e.target.value);
    };

    const changeComponentSection = (e) => {
        if (sectionId !== 0) {
            props.changeComponentSection(e.target.name, sectionId);
        }
    };

    const getCount = (count) => {
        if (!count) return 0;
        return count;
    };

    const [open, setOpen] = useState(false);
    const [modulesWindowProps, setModulesWindowProps] = useState(null);

    const handleClickOpen = (p) => {
        setModulesWindowProps(p);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Модули с компонентом {modulesWindowProps && modulesWindowProps.name}</DialogTitle>
                <DialogContent>
                    {modulesWindowProps && <ModulesWithComponentContainer id={modulesWindowProps.id} />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={s.table}>
                <div className={s.header}>Спец. номер</div>
                <div className={s.header}>Название</div>
                <div className={s.header}>Количество</div>
                <div className={s.header}>Мин. колл</div>
                <div className={s.header}>
                    {addOrTakeProducts ? (
                        <>
                            <button disabled="disabled">Добавить</button>
                            <button onClick={() => addOrTake(0)}>Взять</button>
                        </>
                    ) : (
                        <>
                            <button onClick={() => addOrTake(1)}>Добавить</button>
                            <button disabled="disabled">Взять</button>
                        </>
                    )}
                </div>
                <div className={s.header}>Поставщики</div>
            </div>
            {component.length !== 0 ? (
                component.map(p => (
                    <div
                        key={p.id}
                        className={Number(p.coll) < Number(p.min_coll) ? `${s.table} ${s.red}` : s.table}>
                        <div>
                            {p.specification_id} (
                            <NavLink to="#" onClick={() => handleClickOpen(p)}>
                                {getCount(p.count) > 50 ? (
                                    <span className={s.orange}>{getCount(p.count)}</span>
                                ) : (
                                    getCount(p.count)
                                )}
                            </NavLink>)
                        </div>
                        <div>
                            {p.name}
                            <StorageComponentChangeName
                                p={p}
                                updateComponentName={props.updateComponentName}
                            />
                        </div>
                        <div>{p.coll}</div>
                        <div>
                            {p.min_coll}
                            <span onClick={() => setShowChangeMinColl(p.id)} className={s.pencil}>
                                <FontAwesomeIcon icon={faPencilAlt} />
                            </span>
                        </div>
                        <div>
                            <input
                                className={s.collInput}
                                id={p.id}
                                onChange={onComponentCollChange}
                                type="text"
                                value={findValue(p.id.toString())}
                            />
                        </div>
                        <div>
                            <NavLink to={`../suppliers/${p.id}`}>
                                <div>Поставщики</div>
                            </NavLink>
                        </div>
                    </div>
                ))
            ) : (
                <h2 className={s.error}>Ничего не найдено</h2>
            )}
            <div>
                <List
                    addOrTakeProducts={addOrTakeProducts}
                    list={list}
                    setList={setList}
                    changeCollComponent={props.changeCollComponent}
                    sectionURL={props.sectionURL}
                    setCollComponents={setCollComponents}
                    personalId={props.personalId}
                />
            </div>

            {showChangeMinColl && (
                <Modal active={showChangeMinColl} setActive={setShowChangeMinColl}>
                    <ChangeMinColl
                        active={showChangeMinColl}
                        setActive={setShowChangeMinColl}
                        changeMinColl={props.changeMinColl}
                        section={props.sectionURL}
                    />
                </Modal>
            )}
        </>
    );
};

export default TemplateTable;
