import React, { useState } from 'react';
import {
    Card,
    CardContent,
    IconButton,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import EditIcon from '@mui/icons-material/Edit';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { PlusIcon } from "primereact/icons/plus";

const chartTypes = [
    { label: 'Bar', value: 'bar' },
    { label: 'Line', value: 'line' },
    { label: 'Pie', value: 'pie' },
    { label: 'Doughnut', value: 'doughnut' },
    { label: 'Radar', value: 'radar' },
    { label: 'Polar Area', value: 'polarArea' },
    { label: 'Bubble', value: 'bubble' },
    { label: 'Scatter', value: 'scatter' },
    { label: 'Area', value: 'area' },
    { label: 'Mixed', value: 'mixed' }
];

const xAxisOptions = [
    { label: 'Managers', value: 'managers' },
    { label: 'Years', value: 'years' }
];

const yAxisOptions = [
    { label: 'Total Revenue', value: 'totalRevenue' },
    { label: 'Total Modules Sold', value: 'totalModulesSold' }
];

const CardComponent = ({ card, removeCard, updateCard, updateCardChildren }) => {
    const [open, setOpen] = useState(false);
    const [editingChildIndex, setEditingChildIndex] = useState(null);
    const [tempChild, setTempChild] = useState({ type: 'chart', chartType: '', xAxis: '', yAxis: '', showLegend: true });

    const handleClickOpen = (index) => {
        setEditingChildIndex(index);
        if (index !== null) {
            setTempChild({ ...card.children[index] });
        } else {
            setTempChild({ type: 'chart', chartType: '', xAxis: '', yAxis: '', showLegend: true });
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setEditingChildIndex(null);
    };

    const handleSave = () => {
        const newChildren = [...card.children];
        if (editingChildIndex !== null) {
            newChildren[editingChildIndex] = tempChild;
        } else {
            newChildren.push(tempChild);
        }
        updateCardChildren(card.i, newChildren);
        setTempChild({ type: 'chart', chartType: '', xAxis: '', yAxis: '', showLegend: true });
        setEditingChildIndex(null);
        setOpen(false);
    };

    const handleTempChange = (key, value) => {
        setTempChild({ ...tempChild, [key]: value });
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(card.children);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        updateCardChildren(card.i, items);
    };

    return (
        <Card>
            <CardContent>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <TextField
                        variant="standard"
                        value={card.title}
                        onChange={(e) => updateCard(card.i, 'title', e.target.value)}
                        style={{ marginBottom: '10px', width: '50%' }}
                    />
                    <div>
                        <IconButton onClick={() => removeCard(card.i)}>
                            <DeleteIcon />
                        </IconButton>
                        <IconButton onClick={() => handleClickOpen(null)}>
                            <PlusIcon />
                        </IconButton>
                        <IconButton>
                            <DragHandleIcon style={{ cursor: 'move' }} className="dragHandle" />
                        </IconButton>
                    </div>
                </div>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {card.children.map((child, index) => (
                                    <Draggable key={index} draggableId={index.toString()} index={index}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{ ...provided.draggableProps.style, marginBottom: '10px' }}
                                            >
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {child.type === 'chart' ? (
                                                        <Typography variant="body1" style={{ flex: 1 }}>
                                                            {`Chart Type: ${chartTypes.find(option => option.value === child.chartType)?.label}, X-Axis: ${xAxisOptions.find(option => option.value === child.xAxis)?.label}, Y-Axis: ${yAxisOptions.find(option => option.value === child.yAxis)?.label}`}
                                                        </Typography>
                                                    ) : (
                                                        <Typography variant="body1" style={{ flex: 1 }}>
                                                            {`Text: ${child.text}`}
                                                        </Typography>
                                                    )}
                                                    <IconButton onClick={() => handleClickOpen(index)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton onClick={() => updateCardChildren(card.i, card.children.filter((_, i) => i !== index))}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <Dialog open={open} onClose={handleClose} fullWidth>
                    <DialogTitle>Настройки элемента</DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth style={{ marginTop: '10px' }}>
                            <InputLabel>Тип элемента</InputLabel>
                            <Select
                                value={tempChild.type}
                                onChange={(e) => handleTempChange('type', e.target.value)}
                            >
                                <MenuItem value="chart">График</MenuItem>
                                <MenuItem value="text">Текст</MenuItem>
                            </Select>
                        </FormControl>
                        {tempChild.type === 'chart' ? (
                            <>
                                <FormControl fullWidth style={{ marginTop: '10px' }}>
                                    <InputLabel>Тип графика</InputLabel>
                                    <Select
                                        value={tempChild.chartType}
                                        onChange={(e) => handleTempChange('chartType', e.target.value)}
                                    >
                                        {chartTypes.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth style={{ marginTop: '10px' }}>
                                    <InputLabel>Ось X</InputLabel>
                                    <Select
                                        value={tempChild.xAxis}
                                        onChange={(e) => handleTempChange('xAxis', e.target.value)}
                                    >
                                        {xAxisOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth style={{ marginTop: '10px' }}>
                                    <InputLabel>Ось Y</InputLabel>
                                    <Select
                                        value={tempChild.yAxis}
                                        onChange={(e) => handleTempChange('yAxis', e.target.value)}
                                    >
                                        {yAxisOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={tempChild.showLegend}
                                                onChange={(e) => handleTempChange('showLegend', e.target.checked)}
                                            />
                                        }
                                        label="Показать легенду"
                                    />
                                </FormGroup>
                            </>
                        ) : (
                            <TextField
                                variant="standard"
                                fullWidth
                                label="Текст"
                                value={tempChild.text || ''}
                                onChange={(e) => handleTempChange('text', e.target.value)}
                                style={{ marginTop: '10px' }}
                            />
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">Отмена</Button>
                        <Button onClick={handleSave} color="primary">Сохранить</Button>
                    </DialogActions>
                </Dialog>
            </CardContent>
        </Card>
    );
};

export default CardComponent;
