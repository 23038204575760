import { productionAPI, workerAPI } from "../api/api";

const SET_MODULS = "SET_MODULS";
const SET_LIST_PRODUCTION = "SET_LIST_PRODUCTION";
const SET_WORKERS = "SET_WORKERS";
const NEW_MODULE_FROM_PRODUCTION = "NEW_MODULE_FROM_PRODUCTION";


let initialState = {
    message: "Good",
    workers: [],
    moduls:[],
    listProduction:[],
    newModuleFromProduction: []
}

const productionMainReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_MODULS:
            return {
                ...state,
                moduls: action.moduls
            }
        case SET_LIST_PRODUCTION:
            return {
                ...state,
                listProduction: action.listProduction
            }
        case SET_WORKERS:
            return {
                ...state,
                workers: action.workers
            }
        case NEW_MODULE_FROM_PRODUCTION:
            return {
                ...state,
                newModuleFromProduction: action.newModuleFromProduction
            }
        default:
            return state
    }
}

export const setModuls = (moduls) => ({type: SET_MODULS, moduls});
export const setListProduction = (listProduction) => ({type: SET_LIST_PRODUCTION, listProduction});
export const setWorkersList = (workers) => ({type: SET_WORKERS, workers});
export const setNewModuleFromProduction = (newModuleFromProduction) => ({type: NEW_MODULE_FROM_PRODUCTION, newModuleFromProduction});

export const getWorkersList= () =>{
    return (dispatch) => {
        workerAPI.getWorkersList()
        .then(data => {
            dispatch(setWorkersList(data));
        });
    }
}
export const getModulsPaid= () =>{
    return (dispatch) => {
        productionAPI.getModulsPaid()
        .then(data => {
            dispatch(setModuls(data));
        });
    }
}
export const getlistProduction= (id) =>{
    return (dispatch) => {
        productionAPI.getlistProduction(id)
        .then(data => {
            dispatch(setListProduction(data));
        });
    }
}
export const changeStorageFromTech= (modulsId, status) =>{
    return (dispatch) => {
        productionAPI.changeStorageFromTech(modulsId, status)
        .then(data => {
            dispatch(getModulsPaid());
        });
    }
}
export const setNewTTN= (data, id) =>{
    return (dispatch) => {
        productionAPI.setNewTTN(data)
        .then(data => {
            dispatch(getlistProduction(id));
        });
    }
}
export const setModulsStatus= (modulsStatus, moduleId, moduls_type_in_production) =>{
    return (dispatch) => {
        productionAPI.setModulsStatus(modulsStatus, moduleId, moduls_type_in_production)
        .then(data => {
            dispatch(getModulsPaid());
        });
    }
}
export const setModulsStatusNew= (request) =>{
    return (dispatch) => {
        productionAPI.setModulsStatusNew(request)
        .then(data => {
            dispatch(getModulsPaid());
        });
    }
}

export const getNewModuleFromProduction= () =>{
    return (dispatch) => {
        productionAPI.getNewModuleFromProduction()
        .then(data => {
            dispatch(setNewModuleFromProduction(data));
        });
    }
}

export default productionMainReducer;

