import React, {useState, useEffect} from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {Button, TextField, Box, Grid} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'; // Import this
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'; // Import this for dayjs support
import {DatePicker} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import {productionAPI as ProductionAPI} from '../../../../api/api';
import {toast} from 'react-toastify';

const OperationsTable = () => {
    const [operations, setOperations] = useState([]);
    const [delistingReasons, setDelistingReasons] = useState([]);
    const [startDate, setStartDate] = useState(dayjs().subtract(7, 'days')); // default 1 week ago
    const [endDate, setEndDate] = useState(dayjs());

    // Fetch operations from API
    useEffect(() => {
        fetchOperations();
    }, [startDate, endDate]);

    const fetchOperations = async () => {
        try {
            ProductionAPI.getDelistedItemsList('/api/operations', {
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD')
            }).then(response => {
                if (response?.status === 1) {
                    setDelistingReasons(response?.data?.reasons);
                    setOperations(response?.data?.list);
                } else {
                    toast.error('Помилка при завантаженні операцій ' + response?.message);
                }
            });
        } catch (error) {
            console.error('Помилка при завантаженні операцій:', error);
        }
    };

    const columns = [
        {field: 'id', headerName: 'ID', width: 90},
        {
            field: 'operation_type',
            headerName: 'Тип операції',
            width: 150,
            //якщо кількість від'ємна - це видалення, якщо додатня - додавання
            valueGetter: (params) => {
                return params.row.components_qty < 0 ? 'Списал' : 'Надыбал';
            }
        },
        {field: 'storage_component_code', headerName: 'Код компонента', width: 150},
        {field: 'components_qty', headerName: 'Кол-во', width: 130},
        {
            field: 'created_at', headerName: 'Дата', width: 180,
            valueGetter: (params) => {
                return dayjs(params.row.created_at).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        {
            field: 'delisting_reason_id', headerName: 'Причина списания', width: 500,
            valueGetter: (params) => {
                return delistingReasons.find(reason => reason.id === params.row.delisting_reasons_id)?.name;
            }
        }
    ];

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}> {/* Wrap your component here */}
            <Box>
                <Grid container spacing={2} marginBottom={2}>
                    <Grid item xs={12} md={4}>
                        <DatePicker
                            label="Початкова дата"
                            value={startDate}
                            onChange={(newValue) => setStartDate(newValue)}
                            renderInput={(params) => <TextField {...params} fullWidth/>}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DatePicker
                            label="Кінцева дата"
                            value={endDate}
                            onChange={(newValue) => setEndDate(newValue)}
                            renderInput={(params) => <TextField {...params} fullWidth/>}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Button variant="contained" onClick={fetchOperations} fullWidth>
                            Показати операції
                        </Button>
                    </Grid>
                </Grid>

                <DataGrid
                    rows={operations}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    autoHeight
                />
            </Box>
        </LocalizationProvider>
    );
};

export default OperationsTable;
