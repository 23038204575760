import React, { useState } from 'react';
import s from './AccountsPayable.module.css';
import AddAccountsPayable from './AddAccountsPayable';
import AnyFormatDate from '../../../Production/common/AnyFormatDate/AnyFormatDate';

const AccountsPayable = (props) => {
    console.log(props.course)
    let [showAddWindow, setShowAddWindow] = useState(false)
    let mainSum = props.accountsPayable.reduce((p, c) => Number(p) + Number(c.sum), 0);
    // let sumUAH = mainSum*Number(props.course) - убрал пока эту строку, потому что меняю переменную course, теперь там не просто бакс, а массив вместе с евро
    let sumUAH = mainSum;

    return (
        <>
            <h2>Кредиторская задолженность</h2>
            {showAddWindow
                ? <AddAccountsPayable suppliers={props.suppliers} addAccountsPayable={props.addAccountsPayable} setShowAddWindow={setShowAddWindow} />
                : <input type="button" value="Добавить новую запись" onClick={() => setShowAddWindow(true)} />
            }

            <div className={s.main + " " + s.weight}>
                <div className={s.border}>Всего: {mainSum.toLocaleString()} долл</div>
                <div className={s.border}>Всего: {Math.round(sumUAH).toLocaleString()} грн</div>
                <div className={s.border}>Всего просрочено 0 грн</div>
            </div>

            <div className={s.main + " " + s.weight}>
                <div>Название поставщика</div>
                <div>Сумма в долл</div>
                <div>Дата добавления записи</div>
            </div>

            {props.accountsPayable.length > 0 && props.accountsPayable.map((k, index) => (
                <div className={s.main} key={"accountsPayable" + index}>
                    <div>{k.name}</div>
                    <div>{k.sum}</div>
                    <div><AnyFormatDate date={k.date_add} /></div>
                    <div><input type="button" value="Отдали" onClick={() => props.deleteAccountsPayable(k.id)} /></div>
                </div>
            ))}
        </>
    );
}

export default AccountsPayable;
