import { taskAPI } from "../api/api";

const SET_TASKS = "SET_TASKS";
const SET_TASKS_COMMENT = "SET_TASKS_COMMENT";


let initialState = {
    tasks: [],
    tasksComment: [],
}

const techTaskReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_TASKS:
            return {
                ...state,
                tasks: action.tasks
            }
        case SET_TASKS_COMMENT:
            return {
                ...state,
                tasksComment: action.tasksComment
            }
        default:
            return state
    }
}

export const setTasks = (tasks) => ({type: SET_TASKS, tasks});
export const setTasksComment = (tasksComment) => ({type: SET_TASKS_COMMENT, tasksComment});

export const getTasks= (idWorker=0) =>{
    return (dispatch) => {
        taskAPI.getTasksAll(idWorker)
        .then(data => {
            dispatch(setTasks(data));
        });
    }
}
export const getTasksComment= (idWorker) =>{
    return (dispatch) => {
        taskAPI.getTasksCommentAll(idWorker)
        .then(data => {
            dispatch(setTasksComment(data));
        });
    }
}
export const setNewTask = (array, id)=>{
    return (dispatch)=>{
        taskAPI.setNewTaskAll(array)
        .then(data => {
            dispatch(getTasks(id));
        });
    }
}
export const addCommentForTask = (array, id)=>{
    return (dispatch)=>{
        taskAPI.addCommentForTaskAll(array)
        .then(data => {
            dispatch(getTasksComment(id));
        });
    }
}
export const addNewDateInTask = (id,date, status, personalId)=>{
    return (dispatch)=>{
        taskAPI.addNewDateInTaskAll(id,date, status)
        .then(data => {
            dispatch(getTasks(personalId));
        });
    }
}
export const setNewWorkerInTask = (idTask, idWorker, id)=>{
    return (dispatch)=>{
        taskAPI.setNewWorkerInTaskAll(idTask, idWorker)
        .then(data => {
            dispatch(getTasks(id));
        });
    }
}
export const setTaskWithoutArchive = (idTask, personalId)=>{
    return (dispatch)=>{
        taskAPI.setTaskWithoutArchiveAll(idTask)
        .then(data => {
            dispatch(getTasks(personalId));
        });
    }
}
export const deleteTask = (idTask, status, personalId)=>{
    return (dispatch)=>{
        taskAPI.deleteTask(idTask, status)
        .then(data => {
            dispatch(getTasks(personalId));
        });
    }
}
export const setDateReminder = (idTask, date, personalId)=>{
    return (dispatch)=>{
        taskAPI.setDateReminder(idTask, date)
        .then(data => {
            dispatch(getTasks(personalId));
        });
    }
}
export const changeDateForTask = (date, id, type, personalId)=>{
    return (dispatch)=>{
        taskAPI.changeDateForTask(id, date, type)
        .then(data => {
            dispatch(getTasks(personalId));
        });
    }
}
export const changeTextTask = (array, personalId)=>{
    return (dispatch)=>{
        taskAPI.changeTextTask(array)
        .then(data => {
            dispatch(getTasks(personalId));
        });
    }
}

export default techTaskReducer;

