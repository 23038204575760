import React from 'react';
import { NavLink } from 'react-router-dom';
import AnyFormatDate from '../../../Production/common/AnyFormatDate/AnyFormatDate';
import s from '../SalesPersonal.module.css';


const TestCheck = (props) =>{
    const getStatus = (status, paymentDate)=>{
        switch(status){
            case "1": return <span className={s.greenText}>- купили (<AnyFormatDate date={paymentDate} />)</span>
            case "2": return <span className={s.greenText}>- вернули</span>
            default: return <span className={s.redText}>- ещё на тесте</span>
        }
    }
    return(
        <div className={s.main}>
            <div>Тест #{props.id} {getStatus(props.paid, props.paymentDate)}
            {props.ttn!=="" && <div className={s.ttn}>Отгружен (<AnyFormatDate date={props.date_op}/>) - <span className={s.greenText}>{props.ttn}</span></div>}
            </div>
            {/* <div>Тест {props.paid!=null ? <span className={s.greenText}>- купили (<AnyFormatDate date={props.paymentDate} />)</span> : <span className={s.redText}>- ещё на тесте</span>}
            {props.ttn!=="" && <div className={s.ttn}>Отгружен (<AnyFormatDate date={props.date_op}/>) - <span className={s.greenText}>{props.ttn}</span></div>}
            </div> */}
            <div><NavLink to={"/check-t"} target="_blank">{props.name}</NavLink></div>
            <div><AnyFormatDate date={props.dateMain}/></div>
        </div>
    )
}
export default TestCheck;