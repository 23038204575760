import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import AddComponentContainer from '../Storage/AddComponent/AddComponentContainer';
import AddNewElemFromStorage from './AddNewElemFromStorage/AddNewElemFromStorage';
import s from './AddNewSpecification.module.css';
import { FormControlLabel, FormGroup, Grid, Switch, TextField } from "@mui/material";
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from "@mui/icons-material";
import SettingsIcon from '@mui/icons-material/Settings';
import DialogActions from "@mui/material/DialogActions";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FileUpload from "../../global/FileUpload/FileUpload";
import { specificationAPI } from "../../../api/api";
import Cookies from "js-cookie";
import Modal from "../common/Modal/Modal";
import main from "../../Main.module.css";

const AddNewSpecification = (props) => {
    const navigate = useNavigate();
    const { id } = useParams();

    let [mainArrayInfoForNewSpec, setMainArrayInfoForNewSpec] = useState([]);
    let [mainArrayForNewSpec, setMainArrayForNewSpec] = useState([]);
    let [specsName, setSpecsName] = useState("");
    let [specsDescription, setSpecsDescription] = useState("");

    useEffect(() => {
        setMainArrayForNewSpec(props?.specificationElements?.elements ?? []);
        setMainArrayInfoForNewSpec(props?.specificationElements?.info ?? []);
        setSpecsName(props?.specificationElements?.info?.name ?? "");
        setSpecsDescription(props?.specificationElements?.info?.description ?? "");
    }, [props.specificationElements]);

    let [numberInput, setNumberInput] = useState(0);
    let [alternativeNumberInput, setAlternativeNumberInput] = useState(0);

    const setNumberInputF = (e) => {
        setNumberInput(parseInt(e.target.value));
        if (Number(e.target.value) !== 0) {
            let res = props.allNomenclatures.find(k => k.ID == Number(e.target.value));
            setnewSpecNumber(!res);
        }
    };

    let [newSpecNumber, setnewSpecNumber] = useState(false);

    const addNewElem = (elem, coll) => {
        setMainArrayForNewSpec([{"idElem": elem, "collElem": coll}, ...mainArrayForNewSpec]);
    };

    const deleteElem = (index) => {
        const mainArray = [...mainArrayForNewSpec];
        mainArray.splice(index, 1);
        setMainArrayForNewSpec(mainArray);
    };

    const changeElem = (index, e) => {
        const mainArray = [...mainArrayForNewSpec];
        mainArray[index][e.target.name] = e.target.value;
        setMainArrayForNewSpec(mainArray);
    };

    let [type, setType] = useState(null);

    const setNewSpec = (propType) => {
        let arrayForNewSpecification = [];
        let unknownElements = [];
        mainArrayForNewSpec.forEach(k => {
            let idComponent = props.allComponentsFromStorage.find(i => i.specification_id === k.idElem);
            if (idComponent) {
                arrayForNewSpecification.push({
                    storage_component_name: k.idElem,
                    storage_component_id: idComponent.id,
                    coll: k.collElem
                });
            } else {
                unknownElements.push(k.idElem);
            }
        });
        if (unknownElements.length > 0) {
            toast.error('Не найдены элементы в базе, спецификация не может быть сохранена: ' + unknownElements.join(', '));
            return false;
        }

        if (!numberInput || parseInt(numberInput) != numberInput) {
            if (propType === 'copy' && parseInt(alternativeNumberInput) != alternativeNumberInput) {
                toast.error('Ошибка в номере целевой номенклатуры');
                return false;
            } else {
                toast.error('Ошибка в номере номенклатуры');
            }
            return false;
        }

        if (!specsName) {
            toast.error('Ошибка в названии спецификации');
            return false;
        }
        if (!specsDescription) {
            toast.error('Ошибка в описании спецификации');
            return false;
        }
        if (arrayForNewSpecification.length === 0) {
            toast.error('Добавьте как минимум 1 элемент в спецификацию');
            return false;
        }

        props.setNewSpecification({
            'info': {
                'manager_id': Cookies.get('id'),
                'name': specsName,
                'description': specsDescription,
                'type': propType,
                'nomenclature': numberInput,
                'another_nomenclature': alternativeNumberInput,
                'version': currentVersion,
            },
            'specs': arrayForNewSpecification
        }).then(() => {
            if (propType === 'copy') {
                setNumberInput(alternativeNumberInput);
                getSpecificationsElementsF();
                props.getALLNomenclatures();
            } else {
                getSpecificationsElementsF();
            }
            setAlternativeNomenclatureWindow(false);
        }).catch(error => {
            toast.error(error.message);
        });
    };

    let [version, setVersion] = useState([]);
    useEffect(() => {
        setVersion([...props.versionSpecification]);
    }, [props.versionSpecification]);

    const dispatch = useDispatch();
    const getSpecificationsElementsF = (selectVersion = '0') => {
        props.getSpecificationsElements(alternativeNumberInput !== 0 ? alternativeNumberInput : numberInput, selectVersion === "" ? 0 : selectVersion)
            .then(() => {
                setMainArrayForNewSpec(selectVersion === "" ? [] : props.specificationElements?.elements);
                setMainArrayInfoForNewSpec(selectVersion === "" ? [] : props.specificationElements?.info);
                props.getVersionSpecifications(alternativeNumberInput !== 0 ? alternativeNumberInput : numberInput);
                setVersion(selectVersion === "" ? [] : [...props.versionSpecification]);
                setAlternativeNumberInput(0);
            })
            .catch(error => {
                toast.error(error.message);
            });
    };

    let [currentVersion, setCurrentVersion] = useState(0);
    const setCurrentVersionF = (selectVersion) => {
        setCurrentVersion(selectVersion);
        getSpecificationsElementsF(selectVersion);
    };

    const getNameElem = (number) => {
        let name = props.allComponentsFromStorage.find(k => k.specification_id == number)?.name || "";
        return name;
    };

    const handleKeyPress = (e) => {
        if (e.charCode === 13) {
            getSpecificationsElementsF();
        }
    };

    let [showAddComponent, setShowAddComponent] = useState(false);
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleFileUploadResponse = (parseResponse) => {
        if (parseResponse?.status === 1) {
            if (parseResponse?.parse_results?.parsed_items?.length > 0) {
                setMainArrayForNewSpec([]);

                toast.success('Найдено элементов: ' + parseResponse?.parse_results?.parsed_items?.length + ', загружены в черновик спецификации. ' +
                    'Не забудьте сохранить спецификацию!');

                setCurrentVersion(0);
                setSpecsName('');
                setSpecsDescription('');
                let newElements = parseResponse?.parse_results?.parsed_items?.map(k => ({
                    "idElem": k.spare_part_id,
                    "collElem": k.spare_part_qty
                }));
                setMainArrayForNewSpec(newElements);
            }
        } else {
            toast.error('Ошибка загрузки файла');
        }
    };

    let [alternativeNomenclatureWindow, setAlternativeNomenclatureWindow] = useState(false);

    let [specificationsPropsSettingsWindow, setSpecificationsPropsSettingsWindow] = useState(false);

    let [isSpecActual, setIsSpecActual] = useState(false);
    let [isSpecMain, setIsSpecMain] = useState(false);

    let [currentVersionParam, setCurrentVersionParam] = useState("");

    let [currentSpecsSettings, setCurrentSpecsSettings] = useState({});
    const handleChangeActual = (event) => {
        setIsSpecActual(event.target.checked);
    };

    const handleChangeMain = (event) => {
        setIsSpecMain(event.target.checked);
    };

    const countMainVersions = () => {
        return version.filter(k => k.is_main).length;
    };

    const changeProps = () => {
        if (isSpecMain && countMainVersions() > 0) {
            toast.error('Нельзя сделать несколько спецификаций основными, сначала снимите признак основной с другой спецификации');
            return;
        }

        let newProps = {
            "manager_id": Cookies.get('id'),
            "nomenclature": numberInput,
            "version": currentVersionParam,
            "is_active": isSpecActual,
            "is_main": isSpecMain
        };

        if (version !== '' && numberInput !== 0 && numberInput == parseInt(numberInput)) {
            props.changeSpecificationProps(newProps)
                .then(() => {
                    toast.success('Свойства спецификации изменены');
                    setSpecificationsPropsSettingsWindow(false);
                    getSpecificationsElementsF(currentVersionParam);
                })
                .catch(error => {
                    toast.error(error.message);
                });
        }
    };

    return (
        <div>
            {alternativeNomenclatureWindow &&
                <Modal active={alternativeNomenclatureWindow} setActive={setAlternativeNomenclatureWindow}>
                    <h3 className={main.center}>Номер номенклатуры для создания в неё копии</h3>
                    <div>
                        <input
                            type="number"
                            className={main.input}
                            value={alternativeNumberInput}
                            onChange={e => setAlternativeNumberInput(e.target.value)}
                        />
                    </div>
                    <div className={main.center}>
                        <input
                            type="button"
                            value="Создать дубликат"
                            onClick={setNewSpec}
                            className={main.inputAdd}
                            disabled={alternativeNumberInput > 0 && (parseInt(alternativeNumberInput) === alternativeNumberInput)}
                        />
                    </div>
                </Modal>
            }

            {specificationsPropsSettingsWindow &&
                <Modal active={specificationsPropsSettingsWindow} setActive={setSpecificationsPropsSettingsWindow}>
                    <h3 className={main.center}>Свойства спецификации</h3>
                    <div>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={isSpecActual}
                                        onChange={handleChangeActual}
                                    />
                                }
                                label="Актуальная (можно собирать)"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={isSpecMain}
                                        onChange={handleChangeMain}
                                    />
                                }
                                label="Основная (приоритет при сборке)"
                            />

                        </FormGroup>
                    </div>

                    <div className={main.center}>
                        <input
                            type="button"
                            value="Изменить свойства"
                            onClick={changeProps}
                            className={main.inputAdd}
                            disabled={alternativeNumberInput > 0 && (parseInt(alternativeNumberInput) === alternativeNumberInput)}
                        />
                    </div>
                </Modal>
            }

            <div className={s.header}>
                <div><h3>Поиск номенклатуры:</h3></div>
                <div><input type="number" onChange={setNumberInputF} value={numberInput} onKeyPress={handleKeyPress}/>
                    <button
                        onClick={() => getSpecificationsElementsF("")}
                        style={{
                            color: newSpecNumber ? "orange" : "green",
                            display: numberInput === 0 ? "none" : ""
                        }}
                        disabled={numberInput === 0}

                    >
                        {
                            newSpecNumber
                                ? "Создать"
                                : "Загрузить"
                        }
                    </button>
                </div>
                <div>
                    <FileUpload
                        label="Перетащите файл со спецификацией или нажмите для выбора в стандартном окне"
                        accept=".xlsx"
                        multiple={false}
                        uploadHandler={specificationAPI.uploadXLSXFile}
                        uploadHandlerParams={{
                            nomenclature: numberInput
                        }}
                        handleResponse={handleFileUploadResponse} // Pass the callback function
                    />
                </div>
                <div>
                    <h3>

                    </h3>
                </div>
            </div>
            {(version?.length > 0 || mainArrayForNewSpec?.length > 0 || newSpecNumber) &&
                <Grid container spacing={2}>
                    <Grid item xs={6} md={4}>
                        <div className={s.description}>
                            <TextField
                                id="outlined-basic"
                                label="Название спецификации"
                                variant="outlined"
                                style={{width: '90%', textAlign: 'left'}}
                                value={specsName}
                                onChange={(e) => setSpecsName(e.target.value)}
                                multiline
                                rows={2}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6} md={8}>
                        <div className={s.description}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Описание изменений. Только для технарей."
                                multiline
                                value={specsDescription}
                                onChange={(e) => setSpecsDescription(e.target.value)}
                                rows={2}
                                fullWidth

                                style={{width: '90%', textAlign: 'left'}}
                            />
                        </div>
                    </Grid>
                </Grid>
            }
            <div className={s.mainSpec}>
                <div>
                    <div>{props.flag && <h1>Готово</h1>}</div>
                    {(version?.length > 0 || mainArrayForNewSpec?.length > 0 || newSpecNumber) &&
                        <>
                            <div className={s.main + " " + s.weight}>
                                <div>#</div>
                                <div>Спец. номер</div>
                                <div>Название</div>
                                <div>Количество</div>
                                <div>Удалить</div>
                            </div>
                            <div align="center" style={{border: '1px dashed green'}}>
                                <React.Fragment>
                                    <Button variant="outlined" onClick={handleClickOpen}
                                            style={{margin: '10px', padding: '10px'}}>
                                        Добавить элемент
                                    </Button>
                                    <Dialog open={open} fullWidth={true} sx={{minHeight: '80%'}} onClose={handleClose}>
                                        <DialogTitle>
                                            {showAddComponent
                                                ? "Добавление элемента в базу"
                                                : "Выбор элемента из списка"
                                            }
                                        </DialogTitle>
                                        <DialogContent>
                                            {showAddComponent
                                                ? <DialogContent>
                                                    <AddComponentContainer/>
                                                </DialogContent>
                                                : <>
                                                    <DialogContentText>
                                                        Выберите элемент из списка для добавления его в спецификацию.
                                                        Если компонент новый - воспользуйтесь кнопкой "Добавить новый
                                                        элемент"
                                                    </DialogContentText>
                                                    <AddNewElemFromStorage
                                                        allComponentsFromStorage={props.allComponentsFromStorage}
                                                        addNewElem={addNewElem}
                                                        currentVersion={currentVersion}
                                                        curID={numberInput}
                                                        handleClose={handleClose}
                                                    />
                                                </>
                                            }
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleClose} color='warning'>Отмена</Button>
                                            <Button onClick={() => setShowAddComponent(!showAddComponent)} color="info">
                                                {showAddComponent
                                                    ? "Выбрать из списка"
                                                    : "Добавить новый элемент"
                                                }
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </React.Fragment>

                            </div>


                            {mainArrayForNewSpec?.map((k, index) => {
                                return (
                                    <div key={"mainArrayForNewSpec_" + index} className={s.main}>
                                        <div>{index + 1}</div>
                                        <div>{k.idElem}</div>
                                        <div>{getNameElem(k.idElem)}</div>
                                        <div><input type="number"
                                                    value={parseFloat(k.collElem)}
                                                    step={0.01}
                                                    onChange={e => changeElem(index, e)}
                                                    name="collElem" className={s.collElem}/></div>
                                        <div><span onClick={() => deleteElem(index)} className={s.delete}>Х</span></div>
                                    </div>
                                )
                            })
                            }
                        </>
                    }
                </div>
                <div className={s.leftBlock}>
                    <Grid container spacing={1} justifyContent="center" style={{
                        margin: "5px",
                    }}>
                        <h2>Сохранить как</h2>
                        <Grid item l={12} md={12} xs={12}>
                            <div
                                className={s.saveBlock + " " +
                                    (newSpecNumber ? s.disabled : s.active)}
                                onClick={
                                    () => {
                                        if (!newSpecNumber) {
                                            console.log('edit version');
                                            //setAlternativeNumberInput(0);
                                            setType('edit');
                                            setNewSpec('edit');
                                        }
                                    }
                                }>
                                Изменения
                            </div>

                        </Grid>
                        <Grid item l={12} md={12}>
                            <div
                                className={s.saveBlock + " " + s.active}
                                onClick={
                                    () => {
                                        //if (newSpecNumber) {
                                            console.log('new version');
                                        //setAlternativeNumberInput(0);
                                        setType('new');
                                        //setCurrentVersionF(0)
                                        setNewSpec('new');
                                        //}
                                    }

                                    //setNewSpec()
                                }>
                                Новую версию
                            </div>
                        </Grid>
                        <Grid item l={12} md={12}>
                            <div
                                className={s.saveBlock + " " +
                                    (newSpecNumber ? s.disabled : s.active)}
                                onClick={
                                    () => {
                                        if (!newSpecNumber) {
                                            console.log('copy to new nomenclature');
                                            setType('copy');
                                            //setAlternativeNumberInput(0);
                                            setAlternativeNomenclatureWindow(true);
                                        }
                                    }
                                }>
                                Другую номенклатуру
                            </div>
                        </Grid>
                    </Grid>

                    <div style={{textAlign:'center'}}><h2>Модификации</h2></div>
                    <Grid container spacing={1} style={{
                        margin: "5px",
                    }}>

                        <Grid item l={1} md={1} xs={1}> </Grid>
                        <Grid item l={5} md={5} xs={5} align="center">
                                            <span
                                                title="Дата последнего изменения спецификации"
                                            >
                                                Дата изменения
                                            </span>
                        </Grid>
                        <Grid item l={4} md={4} xs={4} align="center">
                                            <span
                                                title="Название спецификации"
                                            >
                                                Название
                                            </span>
                        </Grid>
                        <Grid item l={1} md={1} xs={1} align="center">
                                        <span
                                            title="Актуальная"
                                        >
                                            А
                                        </span>
                        </Grid>
                        <Grid item l={1} md={1} xs={1} align="center">
                                        <span
                                            title="Основная"
                                        >
                                            О
                                        </span>
                        </Grid>

                    </Grid>

                    {version.length > 0 && version.map((k, index) => {
                        return (
                            <Grid container spacing={1} key={"version_" + index}
                                  style={{
                                      border: "1px solid black",
                                      margin: "5px",
                                      ...(countMainVersions()>1 && k.is_main ? {color: "red"} : {}),
                                      ...(currentVersion === k.dateVersion ? {
                                          fontWeight: "bold",
                                          color: 'green'
                                      } : {}),
                                  }}
                            >
                                <Grid item l={1} md={1}>
                                    <SettingsIcon
                                        onClick={() => {
                                            setCurrentSpecsSettings(k);
                                            setIsSpecActual(k.is_active);
                                            setIsSpecMain(k.is_main);
                                            setCurrentVersionParam(k.dateVersion);
                                            setSpecificationsPropsSettingsWindow(true)
                                        }
                                        }
                                    />
                                </Grid>
                                <Grid item l={5} md={5}>

                                            <span
                                                onClick={
                                                    () =>
                                                        setCurrentVersionF(k.dateVersion)
                                                }
                                            >
                                                {k.dateVersion}
                                            </span>
                                </Grid>

                                <Grid item l={4} md={4}>
                                    {k.name}
                                </Grid>


                                <Grid item l={1} md={1}>
                                    {k.is_active
                                        ? <CheckBoxOutlined/>
                                        : <CheckBoxOutlineBlankOutlined/>
                                    }
                                </Grid>

                                <Grid item l={1} md={1}>
                                    {k.is_main
                                        ? <CheckBoxOutlined/>
                                        : <CheckBoxOutlineBlankOutlined/>
                                    }
                                </Grid>


                            </Grid>
                        )
                    })
                    }

                </div>
            </div>
        </div>
    );
}
export default AddNewSpecification;