import React, {useState, useEffect} from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, TextField, Button, Typography, Grid, Dialog, DialogTitle,
    DialogContent, DialogActions
} from '@mui/material';
import {toast} from "react-toastify";
import Cookies from "js-cookie";
import {NavLink, useParams} from "react-router-dom";
import {loginAPI, privateOfficeAPI} from "../../api/api";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faUser, faUsers, faChartLine, faTachometerAlt, faCog, faFileInvoiceDollar,
    faTasks, faEnvelope, faBell, faComments, faClipboardList, faCalendarAlt, faFileAlt,
    faDatabase, faBriefcase, faMoneyBillWave, faShoppingCart, faWarehouse, faChartPie,
    faSitemap, faAddressBook, faGlobe, faHandshake, faListAlt, faPhone,
    faProjectDiagram, faCalendar
} from '@fortawesome/free-solid-svg-icons';
import {faClipboard} from "@fortawesome/free-regular-svg-icons/faClipboard";
import {faClock} from "@fortawesome/free-regular-svg-icons/faClock";
import {faCreditCard} from "@fortawesome/free-regular-svg-icons/faCreditCard";
import {
    faChrome, faFirefoxBrowser, faSafari, faInternetExplorer
} from '@fortawesome/free-brands-svg-icons';
import DeleteIcon from "@mui/icons-material/Delete";

const getBrowserIcon = (userAgent) => {
    if (userAgent.includes('Chrome')) {
        return <FontAwesomeIcon icon={faChrome}/>;
    } else if (userAgent.includes('Firefox')) {
        return <FontAwesomeIcon icon={faFirefoxBrowser}/>;
    } else if (userAgent.includes('Safari')) {
        return <FontAwesomeIcon icon={faSafari}/>;
    } else {
        return <FontAwesomeIcon icon={faInternetExplorer}/>;
    }
};

const getBrowserVersion = (userAgent) => {
    let version = '';
    if (userAgent.includes('Chrome')) {
        version = userAgent.split('Chrome/')[1].split(' ')[0];
    } else if (userAgent.includes('Firefox')) {
        version = userAgent.split('Firefox/')[1].split(' ')[0];
    } else if (userAgent.includes('Safari')) {
        version = userAgent.split('Version/')[1].split(' ')[0];
    } else {
        version = ''; // Обработка других браузеров или возвращение значения по умолчанию
    }
    return version;
};

const iconList = [
    {name: 'faCalendar', icon: faCalendar},
    {name: 'faClipboard', icon: faClipboard},
    {name: 'faClock', icon: faClock},
    {name: 'faCreditCard', icon: faCreditCard},
    {name: 'faUser', icon: faUser},
    {name: 'faUsers', icon: faUsers},
    {name: 'faChartLine', icon: faChartLine},
    {name: 'faTachometerAlt', icon: faTachometerAlt},
    {name: 'faCog', icon: faCog},
    {name: 'faFileInvoiceDollar', icon: faFileInvoiceDollar},
    {name: 'faTasks', icon: faTasks},
    {name: 'faEnvelope', icon: faEnvelope},
    {name: 'faBell', icon: faBell},
    {name: 'faComments', icon: faComments},
    {name: 'faClipboardList', icon: faClipboardList},
    {name: 'faCalendarAlt', icon: faCalendarAlt},
    {name: 'faFileAlt', icon: faFileAlt},
    {name: 'faDatabase', icon: faDatabase},
    {name: 'faBriefcase', icon: faBriefcase},
    {name: 'faMoneyBillWave', icon: faMoneyBillWave},
    {name: 'faShoppingCart', icon: faShoppingCart},
    {name: 'faWarehouse', icon: faWarehouse},
    {name: 'faChartPie', icon: faChartPie},
    {name: 'faSitemap', icon: faSitemap},
    {name: 'faAddressBook', icon: faAddressBook},
    {name: 'faGlobe', icon: faGlobe},
    {name: 'faHandshake', icon: faHandshake},
    {name: 'faListAlt', icon: faListAlt},
    {name: 'faPhone', icon: faPhone},
    {name: 'faProjectDiagram', icon: faProjectDiagram},
];

const ProfilePage = () => {
    const [currentSessions, setCurrentSessions] = useState([]);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordChangeError, setPasswordChangeError] = useState('');
    const is_admin = !!Cookies.get('adminID');
    const currentUserID = parseInt(useParams().userID);
    const checkSelfProfile = currentUserID === parseInt(Cookies.get('id'));

    const getCurrentSessionsList = async () => {
        if (is_admin || checkSelfProfile) {
            try {
                const response = await loginAPI.currentSessionsList(currentUserID);

                if (response?.should_logout && response?.should_logout === 1) {
                    toast.error('Ваша сессия была принудительно завершена. Пожалуйста, войдите снова.');
                    Cookies.remove('token');
                    Cookies.remove('id');
                    Cookies.remove('adminID');
                    window.location.href = '/';
                }
                if (response?.status === 1) {
                    setCurrentSessions(response.sessions);
                } else {
                    toast.error('Ошибка при получении списка сессий: ' + response?.message);
                }

            } catch (error) {
                console.error('Возникла ошибка:', error);
                toast.error('Ошибка при получении списка сессий.');
            }
        } else {
            toast.error('Нет доступа к этой странице.');
        }
    }

    const [sideMenuList, setSideMenuList] = useState([]);
    const getSideMenuList = async () => {
        try {
            const response = await loginAPI.getSideMenuList();

            if (response?.status === 1) {
                setSideMenuList(response?.data);
            } else {
                toast.error('Ошибка при получении списка меню: ' + response?.message);
            }

        } catch (error) {
            console.error('Возникла ошибка:', error);
            toast.error('Ошибка при получении списка меню.');
        }
    }

    const [userMenuList, setUserMenuList] = useState([]);
    const getUserMenuList = async () => {
        try {
            const response = await privateOfficeAPI.getPrivateOffice(is_admin ? currentUserID : Cookies.get('id'));
            if (response) {
                setUserMenuList(response);
            } else {
                toast.error('Ошибка при получении списка ссылок');
            }
        } catch (error) {
            console.error('Возникла ошибка:', error);
            toast.error('Ошибка при получении меню.');
        }
    }

    useEffect(() => {
        getCurrentSessionsList();
        getSideMenuList();
        getUserMenuList();
    }, []);

    const handleChangePassword = async () => {
        if (is_admin || checkSelfProfile) {
            if (newPassword !== confirmPassword) {
                setPasswordChangeError('Пароли не совпадают');
                return;
            }
            if (newPassword.length < 6) {
                setPasswordChangeError('Пароль должен быть не менее 6 символов');
                return;
            }
            if (!newPassword.match(/^(?=.*[A-Z])(?=.*\d).+$/)) {
                setPasswordChangeError('Пароль должен содержать хотя бы одну заглавную букву и одну цифру');
                return;
            }

            try {
                const response = await loginAPI.changePassword({
                    current_pass: currentPassword,
                    new_pass: newPassword,
                    user_id: currentUserID
                });
                if (response?.status === 1) {
                    setPasswordChangeError('');
                    setCurrentPassword('');
                    setNewPassword('');
                    setConfirmPassword('');
                    toast.success('Пароль успешно изменен');
                } else {
                    setPasswordChangeError('Ошибка при смене пароля: ' + response?.message);
                    toast.error(response?.message)
                }
            } catch (error) {
                setPasswordChangeError('Ошибка при смене пароля. Пожалуйста, попробуйте еще раз.');
                console.error('Ошибка при смене пароля:', error);
            }
        } else {
            toast.error('Как ты попал сюда?');
        }
    };

    const [dialogOpen, setDialogOpen] = useState(false);
    const [faIconName, setFaIconName] = useState('');
    const [selectedMenuItem, setSelectedMenuItem] = useState(null);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
        setFaIconName('');
        setSelectedMenuItem(null);
    }

    const handleAddMenuItem = async () => {
        if (!faIconName) {
            toast.error('Пожалуйста, выберите иконку.');
            return;
        }
        if (!selectedMenuItem) {
            toast.error('Пожалуйста, выберите ссылку.');
            return;
        }

        try {
            const response = await loginAPI.addSideMenuItem({
                link: selectedMenuItem.id,
                fa_icon_name: faIconName
            });
            if (response?.status === 1) {
                toast.success('Пункт меню успешно добавлен');
                getSideMenuList();
                handleDialogClose();
            } else {
                toast.error('Ошибка при добавлении пункта меню: ' + response?.message);
            }
        } catch (error) {
            console.error('Ошибка при добавлении пункта меню:', error);
            toast.error('Ошибка при добавлении пункта меню.');
        }
    }

    const getIconByName = (name) => {
        const iconItem = iconList.find(item => item.name === name);
        return iconItem ? iconItem.icon : null;
    };

    const getFinalLink = (menuItem) => {
        let finalLink = "/"+menuItem.link;
        if (menuItem.personalLink) {
            finalLink += '/' + Cookies.get("id");
        }
        return finalLink;
    };

    const removeItem = async (id) => {
        try {
            const response = await loginAPI.removeSideMenuItem({record_id: id});
            if (response?.status === 1) {
                toast.success('Пункт меню успешно удален');
                getSideMenuList();
            } else {
                toast.error('Ошибка при удалении пункта меню: ' + response?.message);
            }
        } catch (error) {
            console.error('Ошибка при удалении пункта меню:', error);
            toast.error('Ошибка при удалении пункта меню.');
        }
    }

    return (
        <div>
            <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="lg" fullWidth>
                <DialogTitle>Добавить пункт меню</DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1">1. Выберите иконку:</Typography>
                    <Grid container spacing={1}>
                        {iconList.map((iconItem, index) => (
                            <Grid item xs={1.2} key={index}>
                                <div
                                    style={{
                                        textAlign: 'center',
                                        padding: '8px',
                                        border: faIconName === iconItem.name ? '2px solid blue' : '1px solid gray',
                                        borderRadius: '4px',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => setFaIconName(iconItem.name)}
                                >
                                    <FontAwesomeIcon icon={iconItem.icon} size="2x"/>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                    <Typography variant="subtitle1" style={{marginTop: '16px'}}>2. Выберите ссылку:</Typography>
                    <Grid container spacing={1}>
                        {userMenuList.map((menuItem, index) => (
                            <Grid item xs={1.2} key={index}>
                                <Button
                                    variant={selectedMenuItem && selectedMenuItem.id === menuItem.id ? 'contained' : 'outlined'}
                                    color="primary"
                                    fullWidth
                                    onClick={() => setSelectedMenuItem(menuItem)}
                                    style={{textTransform: 'none'}}
                                >
                                    {menuItem.name}
                                </Button>
                            </Grid>
                        ))}
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddMenuItem} color="primary">
                        Добавить
                    </Button>
                    <Button onClick={handleDialogClose} color="secondary">
                        Отмена
                    </Button>
                </DialogActions>
            </Dialog>

            {(currentSessions?.session_device_info || currentSessions?.session2_device_info || currentSessions?.session3_device_info) &&
                <>
                    <Typography variant="h4">Текущие сессии</Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>IP</TableCell>
                                    <TableCell>Браузер</TableCell>
                                    <TableCell>Дата входа</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{currentSessions?.session_device_info ? JSON.parse(currentSessions?.session_device_info).ip : ''}</TableCell>
                                    <TableCell>
                                        {getBrowserIcon(currentSessions?.session_device_info ? JSON.parse(currentSessions?.session_device_info).browser : '')}
                                        &nbsp;&nbsp;
                                        {getBrowserVersion(currentSessions?.session_device_info ? JSON.parse(currentSessions?.session_device_info).browser : '')}
                                    </TableCell>
                                    <TableCell>{currentSessions?.session_login_time}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{currentSessions?.session2_device_info ? JSON.parse(currentSessions?.session2_device_info).ip : ''}</TableCell>
                                    <TableCell>
                                        {getBrowserIcon(currentSessions?.session2_device_info ? JSON.parse(currentSessions?.session2_device_info).browser : '')}
                                        &nbsp;&nbsp;
                                        {getBrowserVersion(currentSessions?.session2_device_info ? JSON.parse(currentSessions?.session2_device_info).browser : '')}
                                    </TableCell>
                                    <TableCell>{currentSessions?.session2_login_time}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{currentSessions?.session3_device_info ? JSON.parse(currentSessions?.session3_device_info).ip : ''}</TableCell>
                                    <TableCell>
                                        {getBrowserIcon(currentSessions?.session3_device_info ? JSON.parse(currentSessions?.session3_device_info).browser : '')}
                                        &nbsp;&nbsp;
                                        {getBrowserVersion(currentSessions?.session3_device_info ? JSON.parse(currentSessions?.session3_device_info).browser : '')}
                                    </TableCell>
                                    <TableCell>{currentSessions?.session3_login_time}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            }

            <Typography variant="h4">Настройка быстрого меню</Typography>
            <Typography variant={'body1'}>Максимальное количество пунктов меню: 5</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width={'10%'}>№</TableCell>
                            <TableCell width={'20%'}>Иконка</TableCell>
                            <TableCell width={'60%'}>Ссылка</TableCell>
                            <TableCell width={'10%'}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sideMenuList.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>
                                    {item?.fa_icon_name && (
                                        <FontAwesomeIcon icon={getIconByName(item.fa_icon_name)}/>
                                    )}
                                </TableCell>
                                <TableCell>
                                    {item?.link.startsWith('https')
                                        ? <a href={(item?.link + (item?.personalLink ? '/' + currentUserID : ''))} target={'_blank'}>{item?.name}</a>
                                        : <NavLink to={getFinalLink(item)} target={'_blank'}>{item?.name}</NavLink>
                                    }


                                </TableCell>
                                <TableCell><Button onClick={(e) => removeItem(item?.id)}><DeleteIcon/>
                                </Button></TableCell>

                            </TableRow>
                        ))}
                        {sideMenuList?.length < 5 ?
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <Button color='success' variant='outlined'
                                            onClick={handleDialogOpen}>Добавить</Button>
                                </TableCell>
                            </TableRow>
                            : null
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <Typography variant="h4">Изменение пароля</Typography>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextField
                        label="Текущий пароль"
                        type="password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Новый пароль"
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Подтверждение нового пароля"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleChangePassword}
                    >
                        Сменить пароль
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" color="error">{passwordChangeError}</Typography>
                </Grid>
            </Grid>
        </div>
    );
};

export default ProfilePage;
