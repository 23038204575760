// DynamicChartBuilder.jsx
import React, { useState } from 'react';
import { CssBaseline, Container, Button } from '@mui/material';
import DynamicGrid from "./DynamicGrid";
import RenderGrid from "./RenderGrid";

const DynamicChartBuilder = () => {
    const [layout, setLayout] = useState(null);
    const [showRender, setShowRender] = useState(false);

    const saveLayout = (cards) => {
        setLayout(cards);
        setShowRender(false);
    };

    const renderLayout = () => {
        setShowRender(true);
    };

    return (
        <Container>
            <CssBaseline />
            <DynamicGrid onSave={saveLayout} />
            {layout && (
                <>
                    <Button variant="contained" color="primary" onClick={renderLayout} style={{ marginTop: '20px' }}>
                        Render Saved Layout
                    </Button>
                    {showRender && <RenderGrid layout={layout} />}
                </>
            )}
        </Container>
    );
};

export default DynamicChartBuilder;
