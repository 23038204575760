import React from 'react';
import s from '../../../Task/Task.module.css';
import { useState } from 'react';

const AddModuleFromRepair = (props) =>{
    let [newValue, setNewValue] = useState("")
    const setNewValueF = (e)=>{
        setNewValue(e.target.value)
    }
    let [newValueNumber, setNewValueNumber] = useState("")
    const setNewValueNumberF = (e)=>{
        setNewValueNumber(e.target.value)
    }
    let [newValueSerial, setNewValueSerial] = useState("")
    const setNewValueSerialF = (e)=>{
        setNewValueSerial(e.target.value)
    }
    const setNew = ()=>{
        let array = [props.id, newValue, newValueNumber, newValueSerial]
        props.addModuleFromRepair(array)
        props.setShow(false)
    }
    return(
        <div className={s.taskMain}>
            <h2>Добавляем позицию</h2>
            <div className={s.twoBlock}>
                <div>Каталожное название</div>
                <div><input type="input" value={newValue} onChange={setNewValueF} className={s.input} placeholder="WAD-A-MAX"/></div>
                <div>Номенклатурный номер</div>
                <div><input type="number" value={newValueNumber} onChange={setNewValueNumberF} className={s.input} placeholder="32"/></div>
                <div>Серийный номер</div>
                <div><input type="number" value={newValueSerial} onChange={setNewValueSerialF} className={s.input} placeholder="398670822"/></div>
            </div>
            <div><button onClick={()=>setNew()}>Добавить</button></div>
        </div>
    )
}
export default AddModuleFromRepair;