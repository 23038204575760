import React from 'react';
import s from './Distribution.module.css';
import main from '../../Main.module.css';
import { useState } from 'react';

const AddDistributionStatistics = (props) =>{
        let [nom, setNom] = useState(0)
        const setNomF = (e)=>{
            setNom(e.target.value)
        }
        const AddNewDistributionStatistics = ()=>{
            if(nom!="" && nom!=0){
                props.addNewDistributionStatistics(nom, props.active, props.personalId, props.type)
                props.setActive(false)
            }
        }
        const getType=()=>{
            switch(props.type){
                case 1: return "отправлено"
                case 2: return "доставлено"
                case 3: return "прочитано"
                case 4: return "переходы"
            }
        }
        return(
            <div className={s.addDesc}>
                <h2 className={main.center}>Статистика по {getType()}</h2>
                <div className={s.twoBlock}>
                    <div>Цифра {getType()}:* </div>
                    <div><input type="number" value={nom} onChange={setNomF} /></div>
                </div>
                <div className={main.center}><button onClick={()=>AddNewDistributionStatistics()}>Добавить</button></div>
            </div>
        )
}
export default AddDistributionStatistics;