import React, { useState } from 'react';
import s from '../../../Task/Task.module.css';

const AddCompanyFromRepair = (props) =>{
    let [newWorkers, setNewWorkers] = useState("")
    const setNewWorkersF = (e)=>{
        setNewWorkers(e.target.value)
    }
    const setNewWorker = ()=>{
        props.addCompanyFromRepair(props.id, newWorkers)
        props.setShow(false)
    }
    return(
        <div className={s.taskMain}>
            <h2>Привязать компанию</h2>
            <div>
                <input type='text' list="data" className={s.input+" "+s.w95} onChange={setNewWorkersF}/>
                <datalist id="data">
                    {props.companyList.map(k=>{
                        return <option value={k.id} key={"companyList"+k.id}>{k.name_with_akon}</option>
                    })}
                </datalist>
            </div>
            <div className={s.center+' '+s.mt20}><button onClick={()=>setNewWorker()}>Привязать</button></div>
        </div>
    )
}
export default AddCompanyFromRepair;