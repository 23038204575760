import React from 'react';
import { connect } from 'react-redux';
import {getSalesCheckArchive} from '../../../../redux/salesCheck-reducer';
import SalesCheckArchive from './SalesCheckArchive';

class SalesCheckArchiveContainer extends React.Component{
    componentDidMount(){
        this.props.getSalesCheckArchive();
    }
    
    render(){
        return (
            <div>
                <SalesCheckArchive {...this.props}/>
            </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    salesCheckArchive: state.salesCheck.salesCheckArchive,
})
export default connect( mapStateToProps, {getSalesCheckArchive})(SalesCheckArchiveContainer);