import React, { useState } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    TextField, Button, Grid, Switch, FormControlLabel, Rating
} from '@mui/material';
import { adminAPI, companyAPI } from "../../../api/api";
import { toast } from 'react-toastify';
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/joy";
import { Cancel } from "@mui/icons-material";

const UseCaseDialog = ({ open, handleClose, useCase, updateUseCaseFiles }) => {
    const [formData, setFormData] = useState({
        id: useCase?.id,
        original_text: useCase?.original_text,
        ukr_text: useCase?.ukr_text || '',
        rus_text: useCase?.rus_text || '',
        eng_text: useCase?.eng_text || '',
        display: useCase?.show_in_search,
        is_active: useCase?.is_active,
        quality_score: useCase?.quality_score,
    });

    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState(useCase?.files || {});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSwitchChange = (e) => {
        const { name, checked } = e.target;
        setFormData(prev => ({ ...prev, [name]: checked }));
    };

    const handleRatingChange = (event, newValue) => {
        setFormData(prev => ({ ...prev, quality_score: newValue }));
    };

    const handleGenerateAIResponse = async () => {
        setLoading(true);
        try {
            const response = await adminAPI.requestAIUseCaseFullfill({
                original_text: formData.original_text,
                id: formData.id
            });
            if (response?.status === 1) {
                const { ukr_text, rus_text, eng_text } = response.data;
                setFormData(prev => ({ ...prev, ukr_text, rus_text, eng_text }));
            } else {
                toast.error('Ошибка на стороне ЧатГПТ. С нашей стороны всё ок!☺');
            }
        } catch (error) {
            console.error("Возникла ошибка: ", error);
            toast.error("Возникла ошибка: ", error);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async () => {
        await adminAPI.updateUseCase(useCase.id, formData)
            .then(response => {
                if(response?.status === 1) {
                    toast.success('Обновлено!');
                    handleClose();
                } else {
                    toast.error(response?.message);
                }
            })
            .catch(error => {
                console.error("Ошибка при сохранении: ", error);
            });
    };

    const handleDeleteFile = async (fileId) => {
        try {
            const response = await companyAPI.deleteFile({ fileID: fileId });
            if (response?.status === 1) {
                const updatedFiles = { ...files };
                delete updatedFiles[fileId]; // Видаляємо файл із локального стану
                setFiles(updatedFiles);

                // Оновлюємо список файлів у батьківському компоненті
                updateUseCaseFiles(useCase.id, updatedFiles);

                toast.success('Файл успешно удалён');
            } else {
                toast.error('Ошибка при удалении файла: ' + response?.message);
            }
        } catch (error) {
            toast.error('Ошибка при удалении файла: ' + error);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
            <DialogTitle>Редактировать Use Case</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Оригинальный текст"
                            name="original_text"
                            value={formData?.original_text}
                            onChange={handleChange}
                            fullWidth
                            multiline
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            onClick={handleGenerateAIResponse}
                            variant="contained"
                            color="primary"
                            disabled={loading}
                        >
                            {loading ? 'Ожидаем ответ...' : 'Перевести ЧатГПТ'}
                        </Button>
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            label="Текст УКР"
                            name="ukr_text"
                            value={formData?.ukr_text}
                            onChange={handleChange}
                            fullWidth
                            multiline
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Текст РУС"
                            name="rus_text"
                            value={formData?.rus_text}
                            onChange={handleChange}
                            fullWidth
                            multiline
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Текст АНГЛ"
                            name="eng_text"
                            value={formData?.eng_text}
                            onChange={handleChange}
                            fullWidth
                            multiline
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {files && Object.keys(files).length > 0 && (
                            <div>
                                <Typography variant="h6">Файлы:</Typography>
                                {Object.values(files).map((file, index) => (
                                    <Stack direction="row" spacing={1} key={index}>
                                        <a href={file.file_path} target="_blank" rel="noreferrer">
                                            {file.file_name}
                                        </a>
                                        <Cancel onClick={() => handleDeleteFile(file.id)} />
                                    </Stack>
                                ))}
                            </div>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={formData?.display}
                                            onChange={handleSwitchChange}
                                            name="display"
                                            color="primary"
                                        />
                                    }
                                    label="Доступно при поиске"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={formData?.is_active}
                                            onChange={handleSwitchChange}
                                            name="is_active"
                                            color="primary"
                                        />
                                    }
                                    label="Активно"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Rating
                                    name="quality_score"
                                    value={formData?.quality_score}
                                    onChange={handleRatingChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Отмена</Button>
                <Button onClick={handleSubmit} variant="contained" color="primary">Сохранить</Button>
            </DialogActions>
        </Dialog>
    );
};

export default UseCaseDialog;
