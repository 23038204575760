import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    getComponentSuppliers,
    getAllSuppliers,
    setComponentSuppliers,
    setComponentId,
    getComponentLinkForBuy,
    setNewLinkForBy,
    getStorageComponentPersonalData,
    getPurchasedComponents
} from '../../../redux/suppliers-reducer';
import Suppliers from './Suppliers';

const SuppliersContainer = (props) => {
    const { suppliers } = useParams();  // Отримуємо параметр з URL

    useEffect(() => {
        props.getAllSuppliers();
        if (suppliers) {
            props.setComponentId(suppliers);
            props.getComponentSuppliers(suppliers);
            props.getComponentLinkForBuy(suppliers);
            props.getStorageComponentPersonalData(suppliers);
            props.getPurchasedComponents(suppliers);
        }
    }, [suppliers]);  // Викликаємо при зміні параметра `suppliers`

    return (
        <Suppliers
            allSuppliers={props.allSuppliers}
            componentSuppliers={props.componentSuppliers}
            setComponentSuppliers={props.setComponentSuppliers}
            componentId={props.componentId}
            linkForBuy={props.linkForBuy}
            setNewLinkForBy={props.setNewLinkForBy}
            storageComponentPersonalData={props.storageComponentPersonalData}
            purchasedComponents={props.purchasedComponents}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        allSuppliers: state.suppliers.allSuppliers,
        componentSuppliers: state.suppliers.componentSuppliers,
        componentId: state.suppliers.componentId,
        linkForBuy: state.suppliers.linkForBuy,
        storageComponentPersonalData: state.suppliers.storageComponentPersonalData,
        purchasedComponents: state.suppliers.purchasedComponents,
    };
};

export default compose(
    connect(mapStateToProps, {
        getComponentSuppliers,
        getAllSuppliers,
        setComponentSuppliers,
        setComponentId,
        getComponentLinkForBuy,
        setNewLinkForBy,
        getStorageComponentPersonalData,
        getPurchasedComponents
    })
)(SuppliersContainer);
