import React, { useState } from 'react';

const LongText = ({ text, maxLength,shawAllText =  'показать всё'}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <>
            {text?.length <= maxLength || isExpanded ? (
                <span style={{ whiteSpace: isExpanded ? "pre-wrap" : "pre-line" }}>{text === "Недозвон" ? <center><span style={{color: 'red'}}><i><b>Недозвон</b></i></span></center> : text}</span>
            ) : (
                <>
                    <span>{`${text?.substring(0, maxLength)}...`}
                        <a onClick={toggleExpand}>
                            {isExpanded ? '' : shawAllText}
                        </a>
                    </span>

                </>
            )}
        </>
    );
};

export default LongText;
