import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, CartesianGrid, Legend, ResponsiveContainer } from 'recharts';
import AnyFormatDate from '../../Production/common/AnyFormatDate/AnyFormatDate';
import s from './Shipment.module.css';

const Shipment = (props) => {
    console.log(props.invoiceForMonthWithDateManager);

    // Calculate the total planned and actual days
    const normaPlan = props.invoiceForMonthWithDateManager.reduce((p, c) => p + Number(c.intervalDaysPlan), 0);
    const fact = props.invoiceForMonthWithDateManager.reduce((p, c) => p + Number(c.intervalDaysFact), 0);

    // Calculate the timely plan percentage
    const timelyPlan = (1 + (normaPlan - fact) / normaPlan) * 100;

    // Format number to include thousand separators
    const getRightSum = (sum) => {
        return Number(Math.round(sum)).toLocaleString('ru-RU');
    };

    // Calculate the total sum for the current month
    const mainSum = getRightSum(Number(props.sumSentInvoicesForMonth[0]) + Number(props.sumSentInvoicesForMonth[1]));

    // Prepare data for the chart
    const data = [
        { name: 0, "сумма": 0 },
        ...props.sumSentInvoicesForYear.map((sum, index) => ({
            name: index + 1,
            "сумма": sum,
        })),
    ];

    return (
        <div>
            <ResponsiveContainer width="95%" height={250}>
                <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
                >
                    <CartesianGrid strokeDasharray="2 2" />
                    <XAxis dataKey="name" height={60} tick={<CustomizedAxisTick />} />
                    <Legend />
                    <Line
                        type="monotone"
                        dataKey="сумма"
                        stroke="#8884d8"
                        label={<CustomizedLabel />}
                    />
                </LineChart>
            </ResponsiveContainer>

            <div className={s.div3}>
                <div>Норма план: {normaPlan} дней</div>
                <div>Норма факт: {fact} дней</div>
                <div>% своевременного выполнения плана: {Math.round(timelyPlan)}%</div>
            </div>

            <h1>Отгрузки - {mainSum} грн</h1>

            <div className={s.main + " " + s.bold}>
                <div>Номер счёта</div>
                <div>Дата оплаты заказа</div>
                <div>Дата Плановая</div>
                <div>Дата Факт</div>
                <div>Срок план</div>
                <div>Срок факт</div>
            </div>

            {props.invoiceForMonthWithDateManager.map((invoice, index) => (
                <div className={s.main} key={`invoiceForMonthWithDateManager_${index}`}>
                    <div>{invoice.number}</div>
                    <div><AnyFormatDate date={invoice.p_date_main} /></div>
                    <div><AnyFormatDate date={invoice.date_manager} /></div>
                    <div className={(invoice.intervalDaysPlan - invoice.intervalDaysFact) < 0 ? s.red : ''}>
                        <AnyFormatDate date={invoice.date_op} />
                    </div>
                    <div>{invoice.intervalDaysPlan}</div>
                    <div>{invoice.intervalDaysFact}</div>
                </div>
            ))}
        </div>
    );
};

export default Shipment;

class CustomizedLabel extends PureComponent {
    render() {
        const { x, y, stroke, value } = this.props;
        return (
            <text x={x} y={y} dy={-4} fill={stroke} textAnchor="middle" fontSize={16} fontWeight={900}>
                {value.toLocaleString()}
            </text>
        );
    }
}

class CustomizedAxisTick extends PureComponent {
    render() {
        const { x, y, payload } = this.props;
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
                    {payload.value}
                </text>
            </g>
        );
    }
}
