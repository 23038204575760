import React from 'react';
import { useState } from 'react';
import s from './Costs.module.css';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList} from '@fortawesome/free-solid-svg-icons'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Costs = (props) =>{
    let mainSum = props.sumCosts.reduce(function(p,c){return Number(p)+Number(c.sum);},'');
    //для вывода расходов по статьям
    let [articles, setArticles] = useState(0);
    const setArticlesF = (id)=>{
        if(id === articles){
            setArticles(0)
        }else{
            props.getSumAndNameSubetriesForMonth(id, props.month, props.year)
            setArticles(id)
        }
    }
    //для вывода расходов по подстатьям
    let [subentries, setSubentries] = useState(0);
    const setSubentriesF = (id)=>{
        if(id === subentries){
            setSubentries(0)
        }else{
            props.getAllPaidCostsForMonthFromSubetries(id, props.month, props.year)
            setSubentries(id)
        }
    }
    let [incomeList,setIncomeList] = useState(false);
    const setIncomeListF = () =>{
        setIncomeList(!incomeList)
    }
    let turnDate = (date) =>{
        if(date){
            date = date.split("-");
            date = (date[2]+"-"+date[1]+"-"+date[0]);
        }else{
            date = "не указана"
        }
        return date;
    }
    const data = [];
    props.sumCosts.map(k=>{
        data.push({
            name: k.date,
            "Укр": Number(k.sum),
            "План": Number(k.plan),
            amt: 0,
        })
    })
    let sum = props.sumCosts.map(k=>k.sum);
    let num = Number(props.month)-1
    sum = Number(sum[num]).toLocaleString()
    return(
        <div>
            <h2 onClick={()=>setIncomeListF()}>
                <span className={s.title}>Расход: {sum+" грн"}</span>
                <NavLink to={"/admin/costslist/"+props.year+"/"+props.month}><FontAwesomeIcon icon={faList}/></NavLink>
            </h2>
            <h3><span className={s.title}>Всего за текущий год: {mainSum.toLocaleString()+" грн"}</span></h3>
            <ResponsiveContainer width="95%" height={300}>
                <BarChart width={150} height={40} data={data}>
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Укр" fill="#ffc658" label={{ position: 'center' }} isAnimationActive={false}/>
                    <Bar dataKey="План" fill="#eee" label={{ position: 'center' }} isAnimationActive={false}/>
                </BarChart>
            </ResponsiveContainer>
            {incomeList &&
                props.articles.map((k,index)=>{
                    return(
                        <div key={"articles_"+index}>
                            <div className={s.main} onClick={()=>setArticlesF(k.id)}>
                                <div>{k.articles}</div>
                                <div>{k.sum}</div>
                            </div>
                            {k.id == articles &&
                                props.subentries.map((k,index)=>{
                                    return(
                                        <div key={"subentries_"+index} >
                                            <div className={s.mainSubentries} onClick={()=>setSubentriesF(k.articles_subentries_id)}>
                                                <div>{k.subentries}</div>
                                                <div>{k.sum}</div>
                                            </div>
                                            {k.articles_subentries_id == subentries &&
                                                props.checkCosts.map((j, index)=>{
                                                    return(
                                                        <div className={s.mainSubentries2+' '+s.subentriesLine} key={"checkCosts_"+index}>
                                                            <div>{j.name}</div>
                                                            <div>{turnDate(j.date)}</div>
                                                            <div>{j.numeral}</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}
export default Costs;