import { privateOfficeAPI } from "../api/api";

const SET_PRIVATE_OFFICE = "SET_PRIVATE_OFFICE";
const SET_PRIVATE_OFFICE_BLOCKS = "SET_PRIVATE_OFFICE_BLOCKS";
const SET_COUNT_REPAIR_FROM_SITE = "SET_COUNT_REPAIR_FROM_SITE";


let initialState = {
    privateOffice: [],
    privateOfficeBlocks: [],
    countRepairFromSite: 0,
}

const privateOffice = (state = initialState, action) =>{
    switch(action.type){
        case SET_PRIVATE_OFFICE:
            return {
                ...state,
                privateOffice: action.privateOffice
            }
        case SET_PRIVATE_OFFICE_BLOCKS:
            return {
                ...state,
                privateOfficeBlocks: action.privateOfficeBlocks
            }
        case SET_COUNT_REPAIR_FROM_SITE:
            return {
                ...state,
                countRepairFromSite: action.countRepairFromSite
            }
        default:
            return state
    }
}

export const setPrivateOffice = (privateOffice) => ({type: SET_PRIVATE_OFFICE, privateOffice});
export const setPrivateOfficeBlocks = (privateOfficeBlocks) => ({type: SET_PRIVATE_OFFICE_BLOCKS, privateOfficeBlocks});
export const setCountRepairFromSite = (countRepairFromSite) => ({type: SET_COUNT_REPAIR_FROM_SITE, countRepairFromSite});

export const getPrivateOffice= (personalId) =>{
    return (dispatch) => {
        privateOfficeAPI.getPrivateOffice(personalId)
        .then(data => {
            dispatch(setPrivateOffice(data));
        });
    }
}
export const getPrivateOfficeBlocks= (personalId) =>{
    return (dispatch) => {
        privateOfficeAPI.getPrivateOfficeBlocks(personalId)
        .then(data => {
            dispatch(setPrivateOfficeBlocks(data));
        });
    }
}
export const addNewPersonalFiltrBlock=(array, personalId)=>{
    return (dispatch)=>{
        privateOfficeAPI.addNewPersonalFiltrBlock(array)
        .then(data=>{
            dispatch(getPrivateOfficeBlocks(personalId));
        });
    }
}
export const editPersonalFiltrBlockName=(array)=>{
    return (dispatch)=>{
        privateOfficeAPI.editPersonalFiltrBlockName(array)
        .then(data=>{
            //console.log(array)
            dispatch(getPrivateOffice(array.personalId));
            dispatch(getPrivateOfficeBlocks(array.personalId));
        });
    }
}
export const deletePersonalFiltrBlock=(blockId, type, personalId)=>{
    return (dispatch)=>{
        privateOfficeAPI.deletePersonalFiltrBlock(blockId, type)
        .then(data=>{
            parseInt(type) ===2 ? dispatch(getPrivateOffice(personalId)) : dispatch(getPrivateOfficeBlocks(personalId));
        });
    }
}
export const setShowPersonalOfficeField=(id, blocksId, personalId)=>{
    return (dispatch)=>{
        privateOfficeAPI.setShowPersonalOfficeField(id, blocksId, personalId)
        .then(data=>{
            dispatch(getPrivateOffice(personalId));
        });
    }
}
export const setColorBgInPersonalFilter=(id, color, personalId)=>{
    return (dispatch)=>{
        privateOfficeAPI.setColorBgInPersonalFilter(id, color)
        .then(data=>{
            dispatch(getPrivateOffice(personalId));
        });
    }
}
export const getCountRepairFromSite=()=>{
    return (dispatch)=>{
        privateOfficeAPI.getCountRepairFromSite()
        .then(data=>{
            dispatch(setCountRepairFromSite(data));
        });
    }
}

export default privateOffice;

