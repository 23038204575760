import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { productionAPI } from "../../../../api/api";
import MonthSelector from "./MonthSelector";
import Workers from "./Workers";
import axios from 'axios';
import { toast } from 'react-toastify';

function AssemblerSallary() {
    const [modules, setModules] = useState([]);
    const [total, setTotal] = useState({ coll: 0, mainPrice: 0 });
    const [date, setDate] = useState((new Date().getMonth() + 1).toString().padStart(2, '0'));
    const [engineerId, setEngineerId] = useState(0);
    const [assemblerName, setAssemblerName] = useState('');
    const [monthName, setMonthName] = useState('');

    useEffect(() => {
        async function fetchModules() {
            try {
                const response = await productionAPI.getProducesModulesList({
                    params: { engineer: engineerId, date_div: date },
                });
                setModules(response.modules || []);
                setTotal(response.total || { coll: 0, mainPrice: 0 });
            } catch (error) {
                console.error("Error fetching modules:", error);
            }
        }

        if (engineerId) {
            fetchModules();
        }
    }, [engineerId, date]);

    const handleDownloadDocx = async () => {
        const today = new Date();
        const currentMonth = today.getMonth() + 1;
        const period =
            parseInt(date, 10) === currentMonth
                ? `${monthName} ${today.getDate()}, ${today.getFullYear()}`
                : `${monthName} ${today.getFullYear()}`;

        const jsonRequest = {
            template_id: 5,
            //filename: `Отчет_${assemblerName}_${period.replace(/\s+/g, '_')}.docx`,
            data: modules.map((module) => ({
                table: {
                    number: {type: 'text', value: module.number},
                    moduls_name: {type: 'text', value: module.moduls_name},
                    moduls_coll: {type: 'text', value: module.moduls_coll},
                    price_engineer: {type: 'text', value: module.price_engineer},
                    coefficient: {type: 'text', value: module.coefficient},
                    moika: {type: 'text', value: module.moika},
                    rubbing: {type: 'text', value: module.rubbing},
                    dopTime: {type: 'text', value: module.dopTime},
                    calculated_main_price: {type: 'text', value: module.calculated_main_price
                    },
                }
            })),
            //assemblerName: assemblerName,
            //period: period,
            //modulesTotal: total?.coll,
            //totalPayUSD: total?.mainPrice,
        };

        try {
            const response = await axios.post("https://passports.akon.com.ua/api/build-document", jsonRequest, {
                headers: { "Content-Type": "application/json" }
            });

            if (response?.data && response?.data?.url) {
                const downloadUrl = response.data.url;
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', jsonRequest.filename);
                document.body.appendChild(link);
                link.click();
                link.remove();
                toast.success("Документ успешно отправлен");
            } else {
                toast.error('Ошибка при отправке документа: ' + response?.data?.message);
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("Ошибка при отправке документа: " + error.message);
        }
    };

    const replaceDotWithComma = (value) => {
        return value.toString().replace('.', ',');
    }

    return (
        <>
            <MonthSelector
                selectedMonth={date}
                onSelect={setDate}
                onSelectName={setMonthName}
            />
            <Workers
                engineerId={engineerId}
                onChange={setEngineerId}
                onSelectName={setAssemblerName}
            />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Название модуля</TableCell>
                            <TableCell>Количество</TableCell>
                            <TableCell>Цена</TableCell>
                            <TableCell>Коэффициент</TableCell>
                            <TableCell>Мойка</TableCell>
                            <TableCell>БП</TableCell>
                            <TableCell>Urethan</TableCell>
                            <TableCell>Plastic</TableCell>
                            <TableCell>Протирка Bus</TableCell>
                            <TableCell>Доп работы</TableCell>
                            <TableCell>Центы</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {modules.map((module, index) => (
                            <TableRow key={index}>
                                <TableCell>{module.number}</TableCell>
                                <TableCell>{module.moduls_name}</TableCell>
                                <TableCell>{module.moduls_coll}</TableCell>
                                <TableCell>{module.price_engineer}</TableCell>
                                <TableCell>{replaceDotWithComma(module.coefficient)}</TableCell>
                                <TableCell>{module.moika}</TableCell>
                                <TableCell>50</TableCell>
                                <TableCell>5</TableCell>
                                <TableCell>10</TableCell>
                                <TableCell>{module.rubbing}</TableCell>
                                <TableCell>{module.dopTime}</TableCell>
                                <TableCell>{replaceDotWithComma(module.calculated_main_price)}</TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell colSpan={2}>Всего:</TableCell>
                            <TableCell>{total?.coll}</TableCell>
                            <TableCell colSpan={8}></TableCell>
                            <TableCell>{replaceDotWithComma(total?.mainPrice)} $</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            {/*<Button variant="contained" color="primary" onClick={handleDownloadDocx} style={{ marginTop: '20px' }}>*/}
            {/*    Скачать docx*/}
            {/*</Button>*/}
        </>
    );
}

export default AssemblerSallary;
