import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getKpPlan, getSumMonthKpPlan, getStatusKp } from '../../../../redux/sales-reducer';
import KpPlan from './KpPlan';
import Cookies from "js-cookie";
import { useParams } from 'react-router-dom';

class KpPlanContainer extends React.Component {
    componentDidMount() {
        let isAdmin = !!Cookies.get('adminID');
        let currentPersonalId = this.props.params.idManager;
        if (!isAdmin) {
            if (Cookies.get('id') !== currentPersonalId) {
                currentPersonalId = Cookies.get('id');
            }
        }

        this.props.getKpPlan(currentPersonalId);
        this.props.getSumMonthKpPlan(currentPersonalId);
        this.props.getStatusKp();
    }

    render() {
        return (
            <div>
                <KpPlan {...this.props} />
            </div>
        );
    }
}

let mapStateToProps = (state) => ({
    kpPlan: state.sales.kpPlan,
    sumMonthKpPlan: state.sales.sumMonthKpPlan,
    statusKp: state.sales.statusKp,
});

// Custom withRouter replacement using useParams
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const params = useParams();
        return <Component {...props} params={params} />;
    }

    return ComponentWithRouterProp;
}

export default compose(
    connect(mapStateToProps, { getKpPlan, getSumMonthKpPlan, getStatusKp }),
    withRouter
)(KpPlanContainer);
