import { invoiceAPI } from "../api/api";

const SET_PAYD_INVOICE = "SET_PAYD_INVOICE";


let initialState = {
    invoice: []
}

const invoiceReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_PAYD_INVOICE:
            return {
                ...state,
                invoice: action.invoice
            }
        default:
            return state
    }
}

export const setInvoice= (invoice) => ({type: SET_PAYD_INVOICE, invoice});

export const getInvoice= (params) =>{
    return (dispatch) => {
        invoiceAPI.getInvoice(params)
        .then(data => {
            dispatch(setInvoice(data));
        });
    }
}


export default invoiceReducer;

