import React from 'react';
import { NavLink } from 'react-router-dom';
import s from './Statistics.module.css';
import { useCookies } from 'react-cookie';



const MenuStatistics = (props) =>{
    const [cookies, setCookie] = useCookies();
    return(
        <div className={s.menu}>
            <div><NavLink to="/statisticsModules">Статистика по номенклатурам</NavLink></div>
            <div><NavLink to="/statisticsModelModules">Статистика по модулям</NavLink></div>
            <div><NavLink to="/statisticsBranch">Статистика по отраслям</NavLink></div>
            {/* <div><NavLink to={"/personalarea/"+cookies.id}>Личный кабинет</NavLink></div> */}
        </div>
    )
}
export default MenuStatistics;