import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Admin from './Admin';
import Menu from './Menu/Menu';

class AdminContainer extends React.Component {
    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        return (
            <div>
                <Admin />
                <Menu />
            </div>
        );
    }
}

let mapStateToProps = (state) => ({});

export default compose(
    connect(mapStateToProps, {})
)(AdminContainer);
