import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Collapse,
    IconButton,
    Box,
    Typography,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Checkbox,
    ListItemText,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp, FilterList } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import { companyAPI } from '../../../../api/api';

const UnhandledCompanies = () => {
    const [companies, setCompanies] = useState([]);
    const [segmentationClient, setSegmentationClient] = useState([]);
    const [segmentationCompany, setSegmentationCompany] = useState([]);
    const [open, setOpen] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedClientSegmentation, setSelectedClientSegmentation] = useState([]);
    const [selectedCompanySegmentation, setSelectedCompanySegmentation] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);

    const loadCompanies = async () => {
        await companyAPI.getUnhandledCompanies()
            .then(data => {
                setCompanies(data?.data);
                setSegmentationCompany(data?.segmentations?.company);
                setSegmentationClient(data?.segmentations?.client);
                console.log(data);
            });
    };

    useEffect(() => {
        loadCompanies();
    }, []);

    const handleClick = (id) => {
        setOpen(prev => ({ ...prev, [id]: !prev[id] }));
    };

    const getSegmentationName = (id, segmentations) => {
        const segmentation = segmentations.find(seg => seg.id === id);
        return segmentation ? segmentation.name : '';
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleClientSegmentationChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedClientSegmentation(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleCompanySegmentationChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedCompanySegmentation(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const filteredCompanies = Object.keys(companies).filter(key => {
        const company = companies[key][0];
        const matchesSearchQuery = company.name.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesClientSegmentation = selectedClientSegmentation.length
            ? selectedClientSegmentation.includes(company.p_client_segmentation?.toString())
            : true;
        const matchesCompanySegmentation = selectedCompanySegmentation.length
            ? selectedCompanySegmentation.includes(company.p_company_segmentation?.toString())
            : true;
        return matchesSearchQuery && matchesClientSegmentation && matchesCompanySegmentation;
    });

    return (
        <TableContainer component={Paper}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">Ничейные компании (всего: {Reflect.ownKeys(companies).length})</Typography>
                <IconButton onClick={handleDialogOpen}>
                    <FilterList />
                </IconButton>
            </Box>
            <TextField
                label="Поиск по названию компании"
                variant="outlined"
                fullWidth
                margin="normal"
                value={searchQuery}
                onChange={handleSearch}
            />
            <Dialog open={dialogOpen} onClose={handleDialogClose}  maxWidth="md">
                <DialogTitle>Фильтры категорий</DialogTitle>
                <DialogContent>
                    <FormControl variant="outlined" fullWidth margin="normal">
                        <InputLabel>Client Segmentation</InputLabel>
                        <Select
                            multiple
                            value={selectedClientSegmentation}
                            onChange={handleClientSegmentationChange}
                            renderValue={(selected) => selected.join(', ')}
                            label="Client Segmentation"
                        >
                            {segmentationClient.map((seg) => (
                                <MenuItem key={seg.id} value={seg.id.toString()}>
                                    <Checkbox checked={selectedClientSegmentation.indexOf(seg.id.toString()) > -1} />
                                    <ListItemText primary={seg.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" fullWidth margin="normal">
                        <InputLabel>Company Segmentation</InputLabel>
                        <Select
                            multiple
                            value={selectedCompanySegmentation}
                            onChange={handleCompanySegmentationChange}
                            renderValue={(selected) => selected.join(', ')}
                            label="Company Segmentation"
                        >
                            {segmentationCompany.map((seg) => (
                                <MenuItem key={seg.id} value={seg.id.toString()}>
                                    <Checkbox checked={selectedCompanySegmentation.indexOf(seg.id.toString()) > -1} />
                                    <ListItemText primary={seg.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Company Name</TableCell>
                        <TableCell>Client Segmentation</TableCell>
                        <TableCell>Company Segmentation</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredCompanies.map(key => (
                        <React.Fragment key={key}>
                            <TableRow>
                                <TableCell>
                                    {companies[key].length > 1 ? (
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => handleClick(key)}
                                        >
                                            {open[key] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                        </IconButton>
                                    ) : null}
                                </TableCell>
                                <TableCell>
                                    <NavLink to={`/company/profile/${key}`}>
                                        {companies[key][0].name}
                                    </NavLink>
                                </TableCell>
                                <TableCell>
                                    {getSegmentationName(companies[key][0].p_client_segmentation, segmentationClient)}
                                </TableCell>
                                <TableCell>
                                    {getSegmentationName(companies[key][0].p_company_segmentation, segmentationCompany)}
                                </TableCell>
                            </TableRow>
                            {companies[key].length > 1 && (
                                <TableRow>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                        <Collapse in={open[key]} timeout="auto" unmountOnExit>
                                            <Box margin={1}>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Кому предоставлялось</TableCell>
                                                            <TableCell>Дата</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {companies[key].map((row, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell>{row.shared_with}</TableCell>
                                                                <TableCell>{row.shared_at}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            )}
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default UnhandledCompanies;
