import React from 'react';
import { Navigate } from 'react-router-dom'; // Replacing Redirect with Navigate
import Cookies from 'js-cookie';

export const withAuthRedirect = (Component) => {
    class RedirectComponent extends React.Component {
        render() {
            if (!Cookies.get('id')) return <Navigate to="/login" />; // Use Navigate instead of Redirect

            return <Component {...this.props} />;
        }
    }
    return RedirectComponent;
};
