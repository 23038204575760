import React from 'react';
import { useState } from 'react';
import AnyFormatDate from '../../Production/common/AnyFormatDate/AnyFormatDate';
import Modal from '../../Production/common/Modal/Modal';
import s from './Application.module.css';
import ApplicationPersonal from './ApplicationPersonal/ApplicationPersonalContainer';

const Application = (props) =>{
    const getDate = (date, whatYouDo)=>{
        date = date.split(' ');
        let dateNew = date[0]
        let timeNew = date[1]
        if(whatYouDo==1) return dateNew
        if(whatYouDo==2) return timeNew
    }
    let [showModal, setShowModal] = useState(false)
    return(
        <div>
            <h1>Заявки из конструктора на сайте</h1>
            <div className={s.main+" "+s.weight}>
                <div>#</div>
                <div>Дата, время</div>
                <div>ФИО</div>
                <div>Тел.</div>
                <div>Email</div>
            </div>
           {props.allApplication.map((k, index)=>{
            return(
                <div className={s.main} key={"app_"+index} onClick={()=>setShowModal(k.id)}>
                    <div>{k.id}</div>
                    <div>{<AnyFormatDate date={getDate(k.date_add,1)}/>}, {getDate(k.date_add,2)}</div>
                    <div>{k.fio}</div>
                    <div>{k.tell}</div>
                    <div>{k.email}</div>
                </div>
            )
           })
           }
           {showModal &&
            <Modal active={showModal} setActive={setShowModal}>
                <ApplicationPersonal id={showModal}/>
            </Modal>
            }
        </div>
    )
}
export default Application;