import React from 'react';
import style from './menu.module.css';
import {NavLink, Redirect} from 'react-router-dom';
import {withAuthRedirect} from '../hoc/withAuthRedirect';
import {useCookies} from 'react-cookie';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faKiwiBird, faUser, faGhost} from '@fortawesome/free-solid-svg-icons'
import Search from "../global/Search/Search";
import Button from "@mui/joy/Button";
import {loginAPI} from "../../api/api";
import Cookies from "js-cookie";
import {toast} from "react-toastify";
import SearchEquipment from "../global/SearchEquipment/SearchEquipment";

const logutbtn = async () => {

    try {
        const response = await loginAPI.logout({
            token: Cookies.get('token'),
        });

        if (response && response?.status === 1) {
            Cookies.remove('id');
            Cookies.remove('adminID');
            Cookies.remove('token');

            window.location.reload();
        } else {
            toast.error(response.message);
        }
    } catch (error) {
        console.error(error.message);
    }

}

const Menu = (props) => {
    const [cookies, setCookie] = useCookies();
    return (
        <div className={style.menu}>
            {cookies.id == 34 || cookies.id == 44 || cookies.id == 1
                ? <a href="https://master.akon.com.ua/admin/check.php">
                    <div><FontAwesomeIcon icon={faGhost}/>На старый сайт</div>
                </a>
                : <a href="https://www.akon.com.ua">
                    <div><FontAwesomeIcon icon={faGhost}/>На сайт</div>
                </a>
            }

            <NavLink to="/check">
                <div><FontAwesomeIcon icon={faKiwiBird}/>Список счетов</div>
            </NavLink>
            <NavLink to={"/privateOffice/" + cookies.id}>
                <div><FontAwesomeIcon icon={faUser}/>Личный кабинет</div>
            </NavLink>
            <Search {...props} />
            <SearchEquipment {...props} />
            <NavLink to={"/profile/" + cookies.id}><div>Профиль</div></NavLink>
            <div>
                <a href='#' onClick={logutbtn}>Выход</a>
            </div>
        </div>
    )

}
let Asd = withAuthRedirect(Menu)
export default Asd;