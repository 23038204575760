import { futureAPI } from "../api/api";

const SET_ALL_APPLICATION = "SET_ALL_APPLICATION";
const SET_APPLICATION_PERSONAL = "SET_APPLICATION_PERSONAL";
const SET_NOM_NAME = "SET_NOM_NAME";
const SET_ALL_DATA = "SET_ALL_DATA";
const SET_ALL_DATA_DEV = "SET_ALL_DATA_DEV";
const SET_ALL_CHANNEL_CHAINS = "SET_ALL_CHANNEL_CHAINS";
const SET_ALL_NOM = "SET_ALL_NOM";


let initialState = {
    allApplication: [],
    applicationPersonal: [],
    nomenclaturesName: [],
    allNomenclaturesData: [],
    allNomenclaturesDataDev: [],
    allChannelsData: [],
    allChannelsDataDev: [],
    allChannelChains: [],
    allNomType: [],
    allNomSignalType: [],
    allNomRange: [],
    allNomWire: [],
}

const FutureReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_ALL_APPLICATION:
            return {
                ...state,
                allApplication: action.data
            }
        case SET_APPLICATION_PERSONAL:
            return {
                ...state,
                applicationPersonal: action.data
            }
        case SET_NOM_NAME:
            return {
                ...state,
                nomenclaturesName: action.data
            }
        case SET_ALL_DATA:
            return {
                ...state,
                allNomenclaturesData: action.data.data,
                allChannelsData: action.data.channels
            }
        case SET_ALL_DATA_DEV:
            return {
                ...state,
                allNomenclaturesDataDev: action.data.data,
                allChannelsDataDev: action.data.channels
            }
        case SET_ALL_CHANNEL_CHAINS:
            return {
                ...state,
                allChannelChains: action.data,
            }
        case SET_ALL_NOM:
            return {
                ...state,
                allNomType: action.data.type,
                allNomSignalType: action.data.signalType,
                allNomRange: action.data.ranges,
                allNomWire: action.data.wires,
            }
        default:
            return state
    }
}

export const setAllApplication = (data) => ({type: SET_ALL_APPLICATION, data});
export const setApplicationPersonal = (data) => ({type: SET_APPLICATION_PERSONAL, data});
export const setNomenclaturesName = (data) => ({type: SET_NOM_NAME, data});
export const setAllData = (data) => ({type: SET_ALL_DATA, data});
export const setAllDataDev = (data) => ({type: SET_ALL_DATA_DEV, data});
export const setAllChannelChains = (data) => ({type: SET_ALL_CHANNEL_CHAINS, data});
export const setAllNom = (data) => ({type: SET_ALL_NOM, data});


export const getAllNomenclaturesData= (name) =>{
    return (dispatch) => {
        futureAPI.getAllNomenclaturesData(name)
        .then(data => {
            dispatch(setAllData(data));
        });
    }
}
export const getAllNomenclaturesDataDev= (name) =>{
    return (dispatch) => {
        futureAPI.getAllNomenclaturesDataDev(name)
        .then(data => {
            dispatch(setAllDataDev(data));
        });
    }
}
export const getNomenclaturesName= () =>{
    return (dispatch) => {
        futureAPI.getPersonalNomenclaturesName()
        .then(data => {
            dispatch(setNomenclaturesName(data));
        });
    }
}
export const changeNomenclaturesNames= (array) =>{
    return (dispatch) => {
        futureAPI.changeNomenclaturesNames(array)
        .then(data => {
            dispatch(getNomenclaturesName());
        });
    }
}
export const getApplicationPersonal= (id) =>{
    return (dispatch) => {
        futureAPI.getApplicationPersonal(id)
        .then(data => {
            dispatch(setApplicationPersonal(data));
        });
    }
}
export const getAllApplication= () =>{
    return (dispatch) => {
        futureAPI.getAllApplication()
        .then(data => {
            dispatch(setAllApplication(data));
        });
    }
}
export const getAllChannelChains= () =>{
    return (dispatch) => {
        futureAPI.getAllChannelChains()
        .then(data => {
            dispatch(setAllChannelChains(data));
        });
    }
}
export const getAllNom= () =>{
    return (dispatch) => {
        futureAPI.getAllNom()
        .then(data => {
            dispatch(setAllNom(data));
        });
    }
}
export const addNomenclatureParam= (type, nameUkr, nameRus) =>{
    return (dispatch) => {
        futureAPI.addNomenclatureParam(type, nameUkr, nameRus)
        .then(data => {
            dispatch(getAllNom());
        });
    }
}
export const setNewChannelCheck= (array) =>{
    return (dispatch) => {
        futureAPI.setNewChannelCheck(array)
        .then(data => {
            dispatch(getAllChannelChains());
        });
    }
}
export const setNewNomenclature= (array) =>{
    return (dispatch) => {
        futureAPI.setNewNomenclature(array)
        .then(data => {
            dispatch(getAllNomenclaturesDataDev(""));
        });
    }
}


export default FutureReducer;

