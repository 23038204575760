import React, { useState } from 'react';
import s from './AccountsPayable.module.css';

const AddAccountsPayable = (props) => {
    let [idCompany, setIdcompany] = useState("");
    const setIdcompanyF = (e) => {
        setIdcompany(e.target.value);
    };
    let [coll, setColl] = useState("");
    const setCollF = (e) => {
        setColl(e.target.value);
    };
    const addAccountsPayable = () => {
        if (coll !== "" && idCompany !== "") {
            props.addAccountsPayable(idCompany, coll);
            props.setShowAddWindow(false);
        }
    };

    return (
        <div className={s.main}>
            <div>
                <input type="text" list="sup" onChange={setIdcompanyF} placeholder="Кому должны?" />
                <datalist id="sup">
                    {props.suppliers.map(k => {
                        return <option value={k.id} key={"suppliers_" + k.id}>{k.name_with_akon}</option>;
                    })}
                </datalist>
            </div>
            <div>
                <input type="text" placeholder="Сколько должны?" value={coll} onChange={setCollF} />
            </div>
            <div>
                <input type="button" value="Добавить" onClick={() => addAccountsPayable()} />
            </div>
            <div>
                <input type="button" value="Скрыть форму" onClick={() => props.setShowAddWindow(false)} />
            </div>
        </div>
    );
};

export default AddAccountsPayable;
