import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import s from './PersonalKp.module.css';

const ChangeDatePlanKp = (props) =>{
    const change = ()=>{
        if(newDate != ""){
            props.changeAnything(props.active, newDate, props.personalId)
            props.setActive(false)
        }
    }
    let [newDate, setNewDate] = useState("")
    const setNewDateF = (e)=>{
        setNewDate(e.target.value)
    }
    return(
        <div>
            <h2>Новая плановая дата</h2>
            <div className={s.modal}>
                <div><input type="date" onChange={setNewDateF} /></div>
                <div><button onClick={()=>change()}>Изменить</button></div>
            </div>
        </div>
    )
}
export default ChangeDatePlanKp;