import React from 'react';
import AnyFormatDate from '../../../Production/common/AnyFormatDate/AnyFormatDate';
import MainRecPaya from '../MainRecPaya/MainRecPaya';
import s from './ReceivablesMain.module.css';

const ReceivablesMain = (props) => {
    const dateNow = new Date();

    // Calculate total and overdue receivables
    const { mainSumReceivables, mainSumReceivablesOff } = props.receivables.reduce(
        (acc, current) => {
            const remaining = Number(current.numeral) - Number(current.paid);
            const dateCheck = new Date(current.date_receivables);

            acc.mainSumReceivables += remaining;

            if (dateNow.getTime() > dateCheck.getTime()) {
                acc.mainSumReceivablesOff += remaining;
            }

            return acc;
        },
        { mainSumReceivables: 0, mainSumReceivablesOff: 0 }
    );

    // Calculate percentage of overdue receivables
    const overduePercentage =
        mainSumReceivables > 0
            ? ((mainSumReceivablesOff * 100) / mainSumReceivables).toFixed(2)
            : 0;

    return (
        <>
            <h2>Дебиторская задолженность</h2>
            <div className={s.main + ' ' + s.weight}>
                <div className={s.border}>
                    Всего {mainSumReceivables.toLocaleString()} грн
                </div>
                <div className={s.border}>
                    Просрочена {mainSumReceivablesOff.toLocaleString()} грн
                </div>
                <div className={s.border}>
                    Процент просрочки {overduePercentage} %
                </div>
            </div>
            <div className={s.main + ' ' + s.weight}>
                <div>Название счёта</div>
                <div>Сумма счёта</div>
                <div>Дата постоплаты</div>
            </div>
            {props.receivables.map((receivable, index) => (
                <div className={s.main} key={`receivables_${index}`}>
                    <div>{receivable.name}</div>
                    <div>{Number(receivable.numeral).toLocaleString()} грн</div>
                    <div>
                        <AnyFormatDate date={receivable.date_receivables} />
                    </div>
                </div>
            ))}
            <MainRecPaya />
        </>
    );
};

export default ReceivablesMain;
