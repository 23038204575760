import React, { useState } from 'react';
import main from '../../../Main.module.css';
import s from '../AllCompanyWithManager.module.css';
import Cookies from 'js-cookie';

const SetCompanyToPlanner = (props) => {
    let [dateReminder, setDateReminder] = useState("");
    const setDateReminderF = (e) => {
        props.getAllCompanyPlanerInThisDate(e.target.value, Cookies.get('id'));
        setDateReminder(e.target.value);
    }
    let [textReminder, setTextReminder] = useState("");
    const setTextReminderF = (e) => setTextReminder(e.target.value);

    const go = async (deleteRecord = false) => {
        if (deleteRecord) {
            let request = {
                'active': props.active,
                'personalId': props.personalId,
                'delete': true
            };
            await props.setCompanyToPlanner(request);
            props.setActive(false);
            props.onPlanCompany(props.active, null);  // Trigger the update in the parent component
            return;
        }
        if (dateReminder !== "") {
            let request = {
                'active': props.active,
                'dateReminder': dateReminder,
                'textReminder': textReminder,
                'personalId': props.personalId
            };
            await props.setCompanyToPlanner(request);
            props.setActive(false);
            props.onPlanCompany(props.active, dateReminder);  // Trigger the update in the parent component
        }
    }

    return (
        <div>
            <h3 className={main.center}>Добавляем компанию в планировщик</h3>
            <div>
                <input type="date" className={main.input} value={dateReminder} onChange={setDateReminderF} />
            </div>
            <div className={s.countDate}>
                {"На эту дату запланировано звонков: " + props.countCompanyPlanerInThisDate}
            </div>
            <div>
                <input type="textarea" className={main.input} value={textReminder} onChange={setTextReminderF} placeholder={"Короткое описание"} />
            </div>
            <div className={main.center}>
                <input type="button" value="Добавить" onClick={() => go()} className={main.inputAdd} />
            </div>
            <div className={main.center}>
                <input type="button" value="Удалить с планировщика" onClick={() => go(true)} className={main.inputDelete} />
            </div>
        </div>
    );
}

export default SetCompanyToPlanner;
