// MonthSelector.js
import React from 'react';
import { Button, ButtonGroup } from '@mui/material';

const MONTHS = [
    'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
    'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
];

function MonthSelector({ selectedMonth, onSelect, onSelectName }) {
    const handleSelect = (month, monthName) => {
        onSelect(month);        // Прокидаємо значення місяця (формат MM)
        onSelectName(monthName); // Прокидаємо ім’я місяця російською мовою
    };

    return (
        <ButtonGroup variant="outlined" color="primary" aria-label="month selector">
            {MONTHS.map((monthName, index) => {
                const monthNumber = (index + 1).toString().padStart(2, '0');
                return (
                    <Button
                        key={monthNumber}
                        variant={selectedMonth === monthNumber ? 'contained' : 'outlined'}
                        onClick={() => handleSelect(monthNumber, monthName)}
                    >
                        {monthName}
                    </Button>
                );
            })}
        </ButtonGroup>
    );
}

export default MonthSelector;
