import React from 'react';
import { connect } from 'react-redux';
import {getSalesCheck, setDateInSalesCheck, getSalesInvoceComment, addCommentForSalesCheck, deleteCommentForSalesCheck} from '../../../redux/salesCheck-reducer';
import {getWorkersListForTasks} from '../../../redux/worker-reducer';
import SalesCheck from './SalesCheck';
import Cookies from 'js-cookie'

class SalesCheckContainer extends React.Component{
    componentDidMount(){
        this.props.getSalesCheck();
        this.props.getSalesInvoceComment();
        this.props.getWorkersListForTasks();
    }
    
    render(){
        return (
            <div>
                <SalesCheck {...this.props}/>
            </div>
        )
    } 
}

let mapStateToProps = (state) =>({
    salesCheck: state.salesCheck.salesCheck,
    salesInvoceComment: state.salesCheck.salesInvoceComment,
    workersList: state.worker.workersList,
})
export default connect( mapStateToProps, {getSalesCheck, setDateInSalesCheck, getSalesInvoceComment, addCommentForSalesCheck,getWorkersListForTasks, deleteCommentForSalesCheck})(SalesCheckContainer);