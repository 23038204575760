import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
    editCompanyStatus,
    getKp,
    getSumKpForMonth,
    setPaidInKp,
    getStatusKp,
    changeDateReminderKp,
    statusWorkingKp,
    deleteMeeting,
    сloseMeeting,
    deleteCompanyFromPlanner
} from '../../../redux/sales-reducer';
import KpMain from './KpMain';
import Cookies from "js-cookie";
import { useParams } from 'react-router-dom';

class KpContainer extends React.Component {
    currentPersonalId = 0;

    componentDidMount() {
        let isAdmin = !!Cookies.get('adminID');
        this.currentPersonalId = this.props.params.idManager;
        if (!isAdmin) {
            if (Cookies.get('id') !== this.currentPersonalId) {
                this.currentPersonalId = Cookies.get('id');
            }
        }
        this.props.getKp(this.currentPersonalId);
        this.props.getStatusKp();
    }

    render() {
        return (
            <div>
                <KpMain
                    {...this.props}
                    personalId={this.currentPersonalId}
                    editCompanyStatus={this.props.editCompanyStatus}
                />
            </div>
        );
    }
}

let mapStateToProps = (state) => ({
    kp: state.sales.kp,
    statusKp: state.sales.statusKp,
});

// Custom withRouter replacement using useParams
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const params = useParams();
        return <Component {...props} params={params} />;
    }

    return ComponentWithRouterProp;
}

export default compose(
    connect(mapStateToProps, {
        editCompanyStatus,
        getKp,
        getSumKpForMonth,
        setPaidInKp,
        getStatusKp,
        changeDateReminderKp,
        statusWorkingKp,
        deleteMeeting,
        сloseMeeting,
        deleteCompanyFromPlanner
    }),
    withRouter
)(KpContainer);
