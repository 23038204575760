import React from 'react';
import { Grid, Paper, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const Statistics = ({ data }) => {
    const renderChangeWithArrow = (value) => {
        return (
            <>
                {value > 0 ? (
                    <span style={{ color: 'green' }}>
                        <ArrowDropUpIcon /> {Math.abs(value).toFixed(2)}%
                    </span>
                ) : (
                    <span style={{ color: 'red' }}>
                        <ArrowDropDownIcon /> {Math.abs(value).toFixed(2)}%
                    </span>
                )}
            </>
        );
    };

    const statisticsTypes = [
        {
            title: 'Записи за весь час та за поточний місяць',
            key: 'records',
            columns: ['Прізвище менеджера', 'Записи за весь час', 'Записи за поточний місяць'],
            dataKey: ['total_records', 'monthly_records'],
        },
        {
            title: 'Середній бал за весь час та за останній місяць',
            key: 'scores',
            columns: ['Прізвище менеджера', 'Середній бал за весь час', 'Середній бал за останній місяць'],
            dataKey: ['average_score', 'monthly_average_score'],
        },
        {
            title: 'Записи за тижні',
            key: 'weekly',
            columns: ['Прізвище менеджера', 'Записи за минулий тиждень', 'Записи за поточний тиждень (Зміна %)'],
            dataKey: ['weekly_previous', 'weekly_current', 'weekly_change'],
            renderChange: true,
        },
        {
            title: 'Записи з файлами',
            key: 'files',
            columns: ['Прізвище менеджера', 'Записи з файлами за весь час', 'Записи з файлами за поточний місяць (Зміна %)'],
            dataKey: ['total_with_files', 'monthly_with_files', 'files_change'],
            renderChange: true,
        }
    ];

    return (
        <Grid container spacing={2} marginBottom={4}>
            {statisticsTypes.map((statType) => (
                <Grid item xs={12} sm={12} md={6} lg={6} key={statType.key}>
                    <Paper elevation={3} style={{ padding: '16px' }}>
                        <Typography variant="h6" gutterBottom>{statType.title}</Typography>
                        <Table size="small"> {/* Робимо таблиці компактними */}
                            <TableHead>
                                <TableRow>
                                    {statType.columns.map((col, idx) => (
                                        <TableCell key={idx}>{col}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((managerStat, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell>{managerStat.manager_name}</TableCell>
                                        <TableCell>{managerStat[statType.dataKey[0]]}</TableCell>
                                        <TableCell>
                                            {statType.renderChange
                                                ? (
                                                    <>
                                                        {managerStat[statType.dataKey[1]]}
                                                        {renderChangeWithArrow(managerStat[statType.dataKey[2]])}
                                                    </>
                                                )
                                                : managerStat[statType.dataKey[1]]
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
            ))}
        </Grid>
    );
};

export default Statistics;
