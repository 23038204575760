import React from 'react';
import { useState } from 'react';
import AnyFormatDate from '../Production/common/AnyFormatDate/AnyFormatDate';
import s from './Cash.module.css';

const Cash = (props) =>{
    let [cash, setCash] = useState(0);
    const setCashF = (e)=>{
        setCash(e.target.value)
    }
    const getSubentries = (idArticle)=>{
        props.getSubentries(idArticle.target.value)
    }
    let [subentries, setSubentries] = useState(0);
    const setSubentriesF = (e)=>{
        setSubentries(e.target.value)
    }
    let [text, setText] = useState("");
    const setTextF = (e)=>{
        setText(e.target.value)
    }
    const addNewCashF = () =>{
        let newCash = [];
        newCash.push(subentries);
        newCash.push(cash);
        newCash.push(text);
        newCash.push(outCash);
        newCash.push(dateNewCash);
        props.addNewCash(newCash, props.month)
        setCash(0)
        setText("")
    }
    let [outCash, setOutCash] = useState(false)
    //формируем сегодняшнюю дату
    let date = new Date()
    let year = ""+date.getFullYear()+""
    let month = date.getMonth()+1;
    month = ""+month
    month.length==1 ? month = "-0"+month : month = "-"+month
    let day = ""+date.getUTCDate()
    day.length==1 ? day = "-0"+day : day = "-"+day
    date = year+month+day
    let [dateNewCash, setDateNewCash] = useState(date)
    console.log(props.month)
    const setDateNewCashF=(e)=>{
        setDateNewCash(e.target.value)
    }
    return(
        <div className={s.mainCash}>
            <h2>В  кассе сейчас {props.cash+" грн"}</h2>
            <h2>Потрачено за месяц (касса): {props.sumCashFlowForMonth+" грн"}</h2>
            <h2 className={s.center}>Движение наличных</h2>
            <p className={s.center}>Сумма <input type="number" value={cash} onChange={setCashF}/><label onChange={()=>setOutCash(!outCash)}><input type="checkbox" checked={outCash ? "checked" : ""} />Не касса</label></p>    
            {cash !== 0 && cash !== "" &&
            <div className={s.mainAdd}>
                <div>
                    <p>Статья</p> 
                    <select id="article" onChange={getSubentries}>
                        <option value="" hidden="hidden" disabled="disabled" selected="selected">Выбрать</option>
                        {
                            props.articles.map(k=>{
                                return <option value={k.id} key={"article_"+k.id}>{k.articles}</option>
                            })
                        }
                    </select>
                    <p>Подстатья</p> 
                    <select id="subentries" onChange={setSubentriesF}>
                        <option value="" hidden="hidden" disabled="disabled" selected="selected">Выбрать</option>
                        {
                            props.subentries && props.subentries.map(k=>{
                                return <option value={k.id} key={"subentries_"+k.id}>{k.subentries}</option>
                            })
                        }
                    </select>
                    <p>Дата</p>
                    <input type="date" className={s.date} defaultValue={dateNewCash} onChange={setDateNewCashF} />
                </div>
                <div>
                    <p>Комментарий</p>
                    <textarea id="text" cols="100" rows="10" onChange={setTextF}></textarea>
                </div>
                <div>
                    <button onClick={()=>addNewCashF()} className={s.button}>Добавить</button>
                </div>
                <div>
                    
                </div>
                <div>
                    
                </div>
                {/* <div><input type="button" value="Добавить" onClick={()=>addNewCash()} className={s.button}/></div> */}
            </div>
            }
            <div>
                <h2 className={s.center}>Тумбочка</h2>
                <div className={s.main +' '+s.font}>
                    <div>Дата</div>
                    <div>Сумма</div>
                    <div>Комментарий</div>
                    <div>Подстатья</div>
                </div>
                {
                    props.cashFlowForMonth.map((k, index)=>{
                        return(
                            !k.safe_deposit &&
                            <div className={k.articles_subentries_id != 97 ? s.main : s.main+' '+s.green} key={"main_"+index}>
                                <div><AnyFormatDate date={k.date_added}/></div>
                                <div>{k.numeral}</div>
                                <div>{k.comments}</div>
                                <div>{k.subentries}</div>
                            </div>
                        )
                    })
                }
                <h2 className={s.center}>В не тумбы</h2>
                <div className={s.main +' '+s.font}>
                    <div>Дата</div>
                    <div>Сумма</div>
                    <div>Комментарий</div>
                    <div>Подстатья</div>
                </div>
                {
                    props.cashFlowForMonth.map((k, index)=>{
                        return(
                            k.safe_deposit &&
                            <div className={k.articles_subentries_id != 97 ? s.main : s.main+' '+s.green} key={"main_"+index}>
                                <div><AnyFormatDate date={k.date_added}/></div>
                                <div>{k.numeral}</div>
                                <div>{k.comments}</div>
                                <div>{k.subentries}</div>
                            </div>
                        )
                    })
                }
            </div>
            
        </div>
    )
}
export default Cash;