import React, { useState, useEffect } from 'react';
import s from '../ChannelChains.module.css';
import main from '../../../../Main.module.css';
import { NavLink } from 'react-router-dom';

const AddNomenclatureParam = (props) =>{
    let [nameUkr, setNameUkr] = useState("")
    const setNameUkrF = (e)=>setNameUkr(e.target.value)
    let [nameRu, setNameRu] = useState("")
    const setNameRuF = (e)=>setNameRu(e.target.value)
    const addNomenclatureParam = ()=>{
        if(nameUkr!=="" && nameRu!==""){
            props.addNomenclatureParam(props.active,nameUkr,nameRu)
            props.setActive(false)
        } 
    }
    const getTypeName = (type)=>{
        switch(type){
            case 1: return "Тип канала"
            case 2: return "Тип сигнала"
            case 3: return "Диапазон"
            case 4: return "Схема подключения"
            default: return "ошибка"
        }
    }
    return(
        <div className={main.center}>
            <h2>Добавление параметра: {getTypeName(props.active)}</h2>
            <div>
                <div>Укр название</div>
                <div><input type="text" value={nameUkr} onChange={setNameUkrF} className={main.input} /></div>
            </div>
            <div>
                <div>Росс название</div>
                <div><input type="text" value={nameRu} onChange={setNameRuF} className={main.input} /></div>
            </div>
            <div><input type="button" value="Записать" onClick={()=>addNomenclatureParam()} className={main.inputAdd} /></div>
        </div>
    )
    
}
export default AddNomenclatureParam;