import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import s from '../Task.module.css';
import main from '../../Main.module.css';

const ChangeDate = (props) => {
    const [cookies] = useCookies();
    let [date, setDate] = useState("");
    const setDateF = (e) => {
        setDate(e.target.value);
    };

    const сhangeDate = () => {
        props.changeDateForTask(date, props.active, props.changeDateType, cookies.id);
        props.setActive(false);
    };

    return (
        <div className={s.addComment}>
            <div><h3>Изменить дату в заявке №{props.active}</h3></div>
            <div><input type="date" onChange={setDateF} value={date} className={main.input} /></div>
            <div className={s.center}><button onClick={сhangeDate}>Изменить</button></div>
        </div>
    );
};

export default ChangeDate;
