import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Use useParams to access route parameters
import { connect } from 'react-redux';
import { addSectionURL, setComponentToSection, getSection } from '../../../../redux/addStorage-reducer';
import AddComponent from './AddComponent';

const AddComponentContainer = ({ addSectionURL, getSection, section, sectionURL, setComponentToSection }) => {
    const { section: sectionParam } = useParams(); // Destructure the section param from the URL

    useEffect(() => {
        // Fetch the section URL and section data on mount or when the section param changes
        addSectionURL(sectionParam);
        getSection();
    }, [sectionParam, addSectionURL, getSection]);

    return (
        <div>
            <AddComponent
                section={section}
                sectionURL={sectionURL}
                setComponentToSection={setComponentToSection}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    sectionURL: state.addStorage.sectionURL,
    section: state.addStorage.section,
});

export default connect(mapStateToProps, {
    addSectionURL,
    setComponentToSection,
    getSection,
})(AddComponentContainer);
