import React, {useState} from 'react';
import s from './CheckValue.module.css';
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LineChart,
    Line
} from 'recharts';

const CheckValue = (props) => {
    //Среднее количество модулей в счетах за год
    const data = [];
    props.averageCollModulsInCheck.map((k) => {
        data.push({
            name: k.year,
            "Среднее": k.average,
            // "План": Number(k.plan),
            // "Росс": sumRu[index],
        })
    })
    //Среднее количество позиций в счетах за год
    const data2 = [];
    props.averageCollPositionModulsInCheck.map((k) => {
        data2.push({
            name: k.year,
            "Среднее": Number(k.average),
            // "План": Number(k.plan),
            // "Росс": sumRu[index],
        })
    })
    //Средний чек за год
    const data3 = [];
    props.averageCheck.map((k) => {
        data3.push({
            name: k.year,
            "Среднее": Number(k.average),
            // "План": Number(k.plan),
            // "Росс": sumRu[index],
        })
    })
    //Количество совершенно новых компаний или которые не покупали 2 года
    const data4 = [];
    props.newCustomer.map((k) => {
        data4.push({
            name: k.year,
            "Количество": Number(k.coll),
        })
    })
    //Количество уникальных компаний за год
    const data6 = [];
    props.customerForYear.map((k) => {
        data6.push({
            name: k.year,
            "Количество": Number(k.coll),
        })
    })
    // //Количество компаний с которыми мы хотябы раз соприкасались
    // const data7 = [];
    // props.activeSalesWithCompany.map((k, index)=>{
    //     data7.push({
    //         name: k.year,
    //         "Всего": Number(k.coll),
    //         "Оплаченных": Number(props.activeSalesWithPaidCompany[index].coll),
    //     })
    // })
    // //Количество компаний с которыми мы хотябы раз соприкасались в процентах
    // const data8 = [];
    // props.activeSalesWithCompany.map((k, index)=>{
    //     data8.push({
    //         name: k.year,
    //         "Процент": Math.round(Number(props.activeSalesWithPaidCompany[index].coll)*100/Number(k.coll))
    //         // "Оплаченных": Number(props.activeSalesWithPaidCompany[index].coll),
    //     })
    // })
    //количество старых компаний в год, которые купили хотя бы раз
    let paidModulsInYear = []
    paidModulsInYear.push({name: 2016, coll: 2485})
    paidModulsInYear.push({name: 2017, coll: 1927})
    paidModulsInYear.push({name: 2018, coll: 3240})
    paidModulsInYear.push({name: 2019, coll: 2400})
    paidModulsInYear.push({name: 2020, coll: 2031})
    //paidModulsInYear.push({name: 2021, coll: 2089})
    //paidModulsInYear.push({name: 2022, coll: 2465})

    //Количество модулей проданых за год
    const data5 = [];
    paidModulsInYear.map((k) => {
        data5.push({
            name: k.name,
            "Количество": Number(k.coll),
        })
    })
    console.log(props?.modulesForYear)
    props?.modulesForYear?.map((k) => {
        if (k?.production_year >= 2021) {
            data5.push({
                name: k?.production_year,
                "Количество": Number(k?.total_modules_qty),
            })
        }
    })

    return (
        <div>
            <h2 className={s.center}>Разнообразная статистика по счетам</h2>
            <div className={s.twoBlock}>
                <div>
                    <h3>Среднее количество модулей в счетах за год (шт.)</h3>
                    <ResponsiveContainer width="95%" height={350}>
                        <LineChart
                            data={data}
                            margin={{
                                top: 40,
                                right: 20,
                                left: 40,
                                bottom: 10,
                            }}
                        >
                            <CartesianGrid strokeDasharray="5 5"/>
                            <XAxis dataKey="name"/>
                            <Tooltip/>
                            <Legend/>
                            <Line type="monotone" dataKey="Среднее" stroke="#1281ca" label={{position: 'top'}}
                                  isAnimationActive={false}/>
                        </LineChart>
                    </ResponsiveContainer>
                </div>
                <div>
                    <h3>Среднее количество позиций в счетах за год (шт.)</h3>
                    <ResponsiveContainer width="95%" height={350}>
                        <LineChart
                            data={data2}
                            margin={{
                                top: 40,
                                right: 20,
                                left: 40,
                                bottom: 10,
                            }}
                        >
                            <CartesianGrid strokeDasharray="5 5"/>
                            <XAxis dataKey="name"/>
                            {/* <YAxis/> */}
                            <Tooltip/>
                            <Legend/>
                            <Line type="monotone" dataKey="Среднее" stroke="#1281ca" label={{position: 'top'}}
                                  isAnimationActive={false}/>
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
            <div className={s.twoBlock}>
                <div>
                    <h3>Средний чек в счетах за год (грн)</h3>
                    <ResponsiveContainer width="95%" height={350}>
                        <LineChart
                            data={data3}
                            margin={{
                                top: 40,
                                right: 10,
                                left: 40,
                                bottom: 10,
                            }}
                        >
                            <CartesianGrid strokeDasharray="5 5"/>
                            <XAxis dataKey="name"/>
                            {/* <YAxis/> */}
                            <Tooltip/>
                            <Legend/>
                            <Line type="monotone" dataKey="Среднее" stroke="#44912c" label={{position: 'top'}}
                                  isAnimationActive={false}/>
                        </LineChart>
                    </ResponsiveContainer>
                </div>
                <div>
                    <h3>Количество новых компаний или которые не покупали 2 года</h3>
                    <ResponsiveContainer width="95%" height={350}>
                        <LineChart
                            data={data4}
                            margin={{
                                top: 40,
                                right: 10,
                                left: 40,
                                bottom: 10,
                            }}
                        >
                            <CartesianGrid strokeDasharray="5 5"/>
                            <XAxis dataKey="name"/>
                            <Tooltip/>
                            <Legend/>
                            <Line type="monotone" dataKey="Количество" stroke="#ffc658" label={{position: 'top'}}
                                  isAnimationActive={false}/>
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
            <div className={s.twoBlock}>
                <div>
                    <h3>Количество модулей проданых за год (шт.)</h3>
                    <ResponsiveContainer width="95%" height={350}>
                        <LineChart
                            data={data5}
                            margin={{
                                top: 40,
                                right: 20,
                                left: 40,
                                bottom: 10,
                            }}
                        >
                            <CartesianGrid strokeDasharray="5 5"/>
                            <XAxis dataKey="name"/>
                            <Tooltip/>
                            <Legend/>
                            <Line type="monotone" dataKey="Количество" stroke="#ffc658" label={{position: 'top'}}
                                  isAnimationActive={false}/>
                        </LineChart>
                    </ResponsiveContainer>
                </div>
                <div>
                    <h3>Количество компаний за год купивших хотя бы раз</h3>
                    <ResponsiveContainer width="95%" height={350}>
                        <LineChart
                            data={data6}
                            margin={{
                                top: 40,
                                right: 20,
                                left: 40,
                                bottom: 10,
                            }}
                        >
                            <CartesianGrid strokeDasharray="5 5"/>
                            <XAxis dataKey="name"/>
                            <Tooltip/>
                            <Legend/>
                            <Line type="monotone" dataKey="Количество" stroke="#ffc658" label={{position: 'top'}}
                                  isAnimationActive={false}/>
                        </LineChart>
                    </ResponsiveContainer>
                </div>
                {/* =================================================== Выводили для шефини сравнение продаж Олега и шефа */}
                {/* <div>
                        <h3>Количество компаний с которыми менеджер хотя бы раз соприкасался</h3>
                        <ResponsiveContainer width="95%" height={350}>
                        <LineChart
                            data={data7}
                            margin={{
                            top: 40,
                            right: 20,
                            left: 40,
                            bottom: 10,
                            }}
                        >
                            <CartesianGrid strokeDasharray="5 5" />
                            <XAxis dataKey="name"   />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="Всего" stroke="#ffc658" label={{ position: 'top'}}  isAnimationActive={false} />
                            <Line type="monotone" dataKey="Оплаченных" stroke="#44912c" label={{ position: 'top'}}  isAnimationActive={false} />
                        </LineChart>
                        </ResponsiveContainer>
                    </div>
                    <div>
                        <h3>Количество компаний с которыми менеджер хотя бы раз соприкасался в процентах</h3>
                        <ResponsiveContainer width="95%" height={350}>
                        <LineChart
                            data={data8}
                            margin={{
                            top: 40,
                            right: 20,
                            left: 40,
                            bottom: 10,
                            }}
                        >
                            <CartesianGrid strokeDasharray="5 5" />
                            <XAxis dataKey="name"   />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="Процент" stroke="#ffc658" label={{ position: 'top'}}  isAnimationActive={false} />
                        </LineChart>
                        </ResponsiveContainer>
                    </div> */}
            </div>


        </div>
    )
}
export default CheckValue;