import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import main from '../../../Main.module.css';

const AddComment = (props) => {
    const [cookies] = useCookies();
    const [text, setText] = useState("");

    const setTextF = (e) => {
        setText(e.target.value);
    };

    const setAllCommentF = () => {
        props.setAddComment(false);
        props.setAllComment(props.id);
    };

    const AddComment = () => {
        if (text !== "") {
            const array = [props.id, text, worker];
            props.addCommentForSalesCheck(array);
            setAllCommentF();
        }
    };

    const worker = cookies.id;

    return (
        <div>
            <div><h3>Добавление комментария к счёту</h3></div>
            <div><textarea rows="10" onChange={setTextF} value={text} className={main.textarea} /></div>
            <div className={main.twoBlocksGrid}>
                <div>
                    <button onClick={() => setAllCommentF()} className={main.backInputAdd}>Назад к комментариям</button>
                </div>
                <div>
                    <button onClick={() => AddComment()} className={main.inputAdd}>Добавить</button>
                </div>
            </div>
        </div>
    );
};

export default AddComment;
