import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    getCompanyList,
    getAgentsPersonalCompany,
    setNewCompanyAgent
} from '../../../../redux/company-reducer';
import {
    getLastIdKp,
    editKp,
    deleteKp,
    getKpById,
} from '../../../../redux/check-reducer';
import { getCourse } from '../../../../redux/admin-reducer';
import { getWorkersData } from '../../../../redux/worker-reducer';
import { getStatusKp } from '../../../../redux/sales-reducer';
import AddNewKp from './AddNewKp';
import Cookies from 'js-cookie';

const AddNewKpContainer = ({
                               getCompanyList,
                               getCourse,
                               getLastIdKp,
                               getStatusKp,
                               getKpById,
                               getWorkersData,
                               ...props
                           }) => {
    const [isLoading, setIsLoading] = useState(true);
    const { idManager, idKp, isInvoice } = useParams();

    let isAdmin = !!Cookies.get('adminID');
    let currentPersonalId = idManager ?? 0;
    if (!isAdmin && Cookies.get('id') !== currentPersonalId) {
        currentPersonalId = Cookies.get('id');
    }

    let invoiceNumber = isInvoice === '1' ? idKp : null;

    useEffect(() => {
        async function loadData() {
            const apiCalls = [
                getCompanyList(),
                getCourse(),
                idKp !== undefined && idKp !== null
                    ? getKpById({
                        idManager: currentPersonalId,
                        idKp,
                        isInvoice,
                    })
                    : getLastIdKp({
                        idManager: currentPersonalId,
                        isInvoice,
                    }),
                getStatusKp(),
                getWorkersData(currentPersonalId),
            ];

            await Promise.all(apiCalls);
            setIsLoading(false);
        }

        loadData();
    }, [getCompanyList, getCourse, getLastIdKp, getStatusKp, idKp, getKpById, getWorkersData]);

    return isLoading ? (
        'Загрузка...'
    ) : (
        <AddNewKp
            {...props}
            companyList={props.companyList}
            personalId={currentPersonalId}
            course={props.course}
            invoiceID={invoiceNumber}
            is_invoice={isInvoice === 'true'}
            is_invoice_edit={isInvoice === 'true' && idKp !== undefined}
            invoice_number={invoiceNumber}
            idKP={idKp}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        companyList: state.company.companyList?.data,
        companyAgents: state.company.companyAgents,
        course: state.admin.course,
        lastIdKp: state.check.lastIdKp,
        statusKp: state.sales.statusKp,
        kpInfoByID: state.check.kpInfoByID,
        workerData: state.worker.workerData,
    };
};


export default connect(mapStateToProps, {
    getCompanyList,
    getAgentsPersonalCompany,
    getCourse,
    setNewCompanyAgent,
    getLastIdKp,
    getKpById,
    editKp,
    deleteKp,
    getStatusKp,
    getWorkersData,
})(AddNewKpContainer);
