import { productionAPI } from "../api/api";
import { storageAPI } from "../api/api";
import { toast } from 'react-toastify';

// const CHANGE_COLL_COMPONENT = "CHANGE_COLL_COMPONENT";
const SET_SECTION = "SET_SECTION";
const SET_COMPONENT = "SET_COMPONENT";
const SET_SECTION_URL = "SET_SECTION_URL";
const SET_COMPONENT_FROM_SECTION = "SET_COMPONENT_FROM_SECTION";
const SET_MISSING_COMPONENTS = "SET_MISSING_COMPONENTS";
const SET_ALL_COMPONENTS_FROM_STORAGE = "SET_ALL_COMPONENTS_FROM_STORAGE";
const SET_TECH_STORAGE = "SET_TECH_STORAGE";

let initialState = {
    component: [],
    sectionURL: null,
    section: [],
    missingComponents: [],
    allComponentsFromStorage: [],
    techStorage: [],
}

const addStorageReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_SECTION:
            return {...state, section: action.section} 
        case SET_COMPONENT_FROM_SECTION:
            return {...state, component: action.component} 
        case SET_COMPONENT:
            return {...state, component: action.component} 
        case SET_SECTION_URL:
            return {...state, sectionURL: action.sectionURL} 
        case SET_MISSING_COMPONENTS:
            return {...state, missingComponents: action.missingComponents} 
        case SET_ALL_COMPONENTS_FROM_STORAGE:
            return {...state, allComponentsFromStorage: action.allComponentsFromStorage} 
        case SET_TECH_STORAGE:
            return {...state, techStorage: action.techStorage} 
        default:
            return state
    }
}

export const setSection = (section) => ({type: SET_SECTION, section});
export const setComponentFromSection = (component) => ({type: SET_COMPONENT_FROM_SECTION, component});
export const setComponent = (component) => ({type: SET_COMPONENT, component});
export const setCheckingAvailabilityComponents = (missingComponents) => ({type: SET_MISSING_COMPONENTS, missingComponents});
export const addSectionURL = (sectionURL=19) => ({type: SET_SECTION_URL, sectionURL});
export const setAllComponentsFromStorage = (allComponentsFromStorage) => ({type: SET_ALL_COMPONENTS_FROM_STORAGE, allComponentsFromStorage});
export const setTechStorage = (techStorage) => ({type: SET_TECH_STORAGE, techStorage});

export const getSection = () => {
    return (dispatch) => {
        productionAPI.getSectionNew()
            .then(data => {
                console.log(data);
                const sections = data.data.component;
                const uniqueSections = JSON.parse(sections);
                console.log("Unique sections data:", uniqueSections); // Log the unique sections
                dispatch(setSection(uniqueSections));
            })
            .catch(error => {
                console.error("Error fetching sections:", error); // Log any errors
            });
    }
}



export const getComponentForOrder = () =>{
    return (dispatch) => {
        productionAPI.getComponentForOrder()
        .then(data => {
            dispatch(setComponentFromSection(data));
        });
    }
}
export const setCommentToStorageComponent = (componentId, comment) =>{
    return (dispatch) => {
        productionAPI.setCommentToStorageComponent(componentId, comment)
        .then(data => {
            dispatch(getComponentForOrder());
        });
    }
}
export const getComponentFromSection = (sectionId=19) =>{
    return (dispatch) => {
        productionAPI.getComponentFromSection(sectionId)
        .then(data => {
            dispatch(setComponent(data));
        });
    }
}
export const changeCollComponent = (componentColl, sectionId, personalId, addOrTakeProducts, article, subarticle) =>{
    return (dispatch) => {
        productionAPI.changeCollComponent(componentColl, personalId, addOrTakeProducts, article, subarticle)
        .then(data => {
            dispatch(getComponentFromSection(sectionId));
        });
    }
}
export const setComponentToSection = (sectionId, nameComponent, collComponent, specificationId) =>{
    return (dispatch) => {
        productionAPI.setComponentToSection(sectionId, nameComponent, collComponent, specificationId)
        .then(data => {
            if(data.status === 1){
                toast.success("Компонент успешно добавлен")
                dispatch(getAllComponentsFromStorage());
            }else{
                toast.warning("Возникла ошибка при добавлении компонента. Сделайте скриншот ошибки и отправьте Алексею.",  {autoClose: 10000})
                toast.warning(data.message, {autoClose: 10000})
            }
            //dispatch(getComponentFromSection(sectionId));
        });
    }
}
export const searchInSection = (value, section, searchByCode, searchByName) =>{
    return (dispatch) => {
        productionAPI.searchInSection(value, section, searchByCode, searchByName)
        .then(data => {
            dispatch(setComponent(data));
        });
    }
}

export const getStorageModulsReady = () =>{
    return (dispatch) => {
        storageAPI.getStorageModulsReady()
        .then(data => {
            dispatch(setComponent(data));
        });
    }
}
export const changeComponentSection = (componentId, sectionId) =>{
    return (dispatch) => {
        storageAPI.changeComponentSection(componentId, sectionId)
        .then(data => {
            getSection();
        });
    }
}
export const checkingAvailabilityComponents = (componentId) =>{
    return (dispatch) => {
        storageAPI.checkingAvailabilityComponents(componentId)
        .then(data => {
            dispatch(setCheckingAvailabilityComponents(data));
        });
    }
}
export const getAllComponentsFromStorage = () =>{
    return (dispatch) => {
        storageAPI.getAllComponentsFromStorage()
        .then(data => {
            dispatch(setAllComponentsFromStorage(data));
        });
    }
}
export const getTechStorage = () =>{
    return (dispatch) => {
        storageAPI.getTechStorage()
        .then(data => {
            dispatch(setTechStorage(data));
        });
    }
}
export const changeMinColl = (id, newValue, section) =>{
    return (dispatch) => {
        storageAPI.changeMinColl(id, newValue)
        .then(data => {
            dispatch(getComponentFromSection(section));
        });
    }
}




export default addStorageReducer;

