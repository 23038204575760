import React, {useState} from 'react';
import s from './MenuManager.module.css';
import salarycss from '../../ManagerSalaryMonthly/ManagerSalary.module.css';
import {NavLink} from "react-router-dom";

const MenuManager = (props) => {

    if (props.managerSalary === true) {
        return (
            <>

                <NavLink to={props.link + "/486/" + props.year + '/' + props.month}>
                    <div className={props.idNow == 486 ? salarycss.active : ""}>
                        Олег
                    </div>
                </NavLink>

                <NavLink to={props.link + "/44/" + props.year + '/' + props.month}>
                    <div className={props.idNow == 44 ? salarycss.active : ""}>
                        Алексей
                    </div>
                </NavLink>


                <NavLink to={props.link + "/1055/" + props.year + '/' + props.month}>
                    <div className={props.idNow == 1055 ? salarycss.active : ""}>
                        Мартиненко Євгеній
                    </div>
                </NavLink>


                <NavLink to={props.link + "/1056/" + props.year + '/' + props.month}>
                    <div className={props.idNow == 1056 ? salarycss.active : ""}>
                        Кучер Євген
                    </div>
                </NavLink>

                <NavLink to={props.link + "/1061/" + props.year + '/' + props.month}>
                    <div className={props.idNow == 1061 ? salarycss.active : ""}>
                        Сталин Дмитрий
                    </div>
                </NavLink>
                <NavLink to={props.link + "/297/" + props.year + '/' + props.month}>
                    <div className={props.idNow == 297 ? salarycss.active : ""}>
                        Марченко Андрей
                    </div>
                </NavLink>
            </>
        )
    }

    return (
        <div className={s.dostup}>
            {props.link === "/sales/allCompany" ||props.link === "/sales/allCompanyNew" || props.link == "/sales/allCompanyOutdated" ?
                <a href={props.link + "/0"}><span className={props.idNow == 0 ? s.active : ""}>Все</span></a>
                : ''
            }
            <a href={props.link + "/44"}><span className={props.idNow == 44 ? s.active : ""}>Алексей</span></a>
            <a href={props.link + "/486"}><span className={props.idNow == 486 ? s.active : ""}>Олег</span></a>
            <a href={props.link + "/297"}><span className={props.idNow == 297 ? s.active : ""}>Марченко Андрей</span></a>
            <a href={props.link + "/1055"}><span
                className={props.idNow == 1055 ? s.active : ""}>Мартиненко Євгеній</span></a>
            <a href={props.link + "/1056"}><span
                className={props.idNow == 1056 ? s.active : ""}>Кучер Євген</span></a>
            <a href={props.link + "/1061"}><span
                className={props.idNow == 1061 ? s.active : ""}>Сталин Дмитрий</span></a>
        </div>
    )
}
export default MenuManager;
