// Workers.js
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { productionAPI } from "../../../../api/api";

function Workers({ engineerId, onChange, onSelectName }) {
    const [assemblers, setAssemblers] = useState([]);

    useEffect(() => {
        async function fetchAssemblers() {
            try {
                const response = await productionAPI.getAssemblersList();
                setAssemblers(response || []);
            } catch (error) {
                console.error("Error fetching assemblers:", error);
            }
        }
        fetchAssemblers();
    }, []);

    const handleSelect = (id, name) => {
        onChange(id);        // Прокидаємо ID монтажника
        onSelectName(name);  // Прокидаємо ім’я монтажника
    };

    return (
        <ButtonGroup variant="outlined" color="primary" aria-label="worker selector">
            {assemblers.map((assembler) => (
                <Button
                    key={assembler.id}
                    variant={engineerId === assembler.id ? 'contained' : 'outlined'}
                    onClick={() => handleSelect(assembler.id, assembler.name)}
                >
                    {assembler.name}
                </Button>
            ))}
        </ButtonGroup>
    );
}

export default Workers;
