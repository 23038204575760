import React, { useState } from 'react';
import { Grid } from "@mui/material";
import s from './AddNewElemFromStorage.module.css';

const AddNewElemFromStorage = (props) => {
    let [elem, setElem] = useState("");
    let [coll, setColl] = useState(1);

    const setElemF = (e) => {
        setElem(e.target.value);
    };

    const setCollF = (e) => {
        setColl(e.target.value);
    };

    const addNewElemF = () => {
        props.addNewElem(elem, coll);
        setElem("");
        setColl(1);
        props.handleClose();
    };

    return (
        <div className={s.main} align="center">
            <Grid container>
                <Grid item l={12} md={12}>
                    <input
                        type="text"
                        list="component"
                        name="idElem"
                        value={elem}
                        onChange={setElemF}
                        placeholder="Выбрать элемент"
                    />
                    <datalist id="component">
                        {props.allComponentsFromStorage.map((j, index) => (
                            <option key={"allComponentsFromStorage_" + index} value={j.specification_id}>
                                {j.name}
                            </option>
                        ))}
                    </datalist>
                </Grid>
                <Grid item l={12} md={12}>
                    <input type="number" value={coll} onChange={setCollF} />
                </Grid>
                <Grid item l={12} md={12}>
                    <button
                        onClick={() => addNewElemF()}
                        disabled={props.curID === 0 || elem.length === 0}
                    >
                        Добавить
                    </button>
                </Grid>
            </Grid>
        </div>
    );
};

export default AddNewElemFromStorage;
