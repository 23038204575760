import {workerAPI, productionAPI} from "../api/api";
import {toast} from 'react-toastify';

const SET_WORKERS_MODULS = "SET_WORKERS_MODULS";
const SET_WORKERS_LIST_TECH = "SET_WORKERS_LIST_TECH";
const SET_WORKERS_LIST_FOR_TASKS = "SET_WORKERS_LIST_FOR_TASKS";
const SET_WORKER_DATA = "SET_WORKER_DATA";

let initialState = {
    workersModuls:[],
    workersListTech:[],
    workersList:[],
    workerData:[],
}

const modulsPaidReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_WORKERS_MODULS:
            return {
                ...state,
                workersModuls: action.workersModuls
            }
        case SET_WORKERS_LIST_TECH:
            return {
                ...state,
                workersListTech: action.workersListTech
            }
        case SET_WORKERS_LIST_FOR_TASKS:
            return {
                ...state,
                workersList: action.workersList
            }
        case SET_WORKER_DATA:
            return {
                ...state,
                workerData: action.workerData
            }
        default:
            return state
    }
}

export const setWorkersModuls = (workersModuls) => ({type: SET_WORKERS_MODULS, workersModuls});
export const setWorkersListTech = (workersListTech) => ({type: SET_WORKERS_LIST_TECH, workersListTech});
export const setWorkersListForTasks = (workersList) => ({type: SET_WORKERS_LIST_FOR_TASKS, workersList});
export const setWorkersData = (workerData) => ({type: SET_WORKER_DATA, workerData});

export const getWorkersModuls = () => {
    return (dispatch) => {
        workerAPI.getWorkersModuls()
            .then(data => {
                dispatch(setWorkersModuls(data));
            });
    }
}

export const getWorkersModulsOld = () => {
    return (dispatch) => {
        workerAPI.getWorkersModulsOld()
            .then(data => {
                dispatch(setWorkersModuls(data));
            });
    }
}

export const getWorkersListTech = () => {
    return (dispatch) => {
        workerAPI.getWorkersListTech()
            .then(data => {
                dispatch(setWorkersListTech(data));
            });
    }
}
export const getWorkersListForTasks = () => {
    return (dispatch) => {
        workerAPI.getWorkersListForTasks()
            .then(data => {
                dispatch(setWorkersListForTasks(data));
            });
    }
}
export const getPersonalWorkersModuls = (workerId) => {
    return (dispatch) => {
        workerAPI.getPersonalWorkersModuls(workerId)
            .then(data => {
                dispatch(setWorkersModuls(data));
            });
    }
}
export const getWorkersData= (workerId) =>{
    return (dispatch) => {
        workerAPI.getWorkersData(workerId)
        .then(data => {
            dispatch(setWorkersData(data));
        });
    }
}

export const setModulsStatus = (modulsStatus, moduleId, who_make = 0, moduls_type_in_production) => {
    return (dispatch) => {
        productionAPI.setModulsStatus(modulsStatus, moduleId, moduls_type_in_production)
            .then(data => {
                if (who_make === 0) {
                    dispatch(getWorkersModuls());
                } else {
                    dispatch(getPersonalWorkersModuls(who_make));
                }

            });
    }
}

export const cancelAssembly = (moduleInfo) => {
    return (dispatch) => {
        productionAPI.cancelAssembly(moduleInfo)
            .then(data => {
                    dispatch(getWorkersModuls());
            });
    }
}

export const changeAssembler = (request) => {
    return (dispatch) => {
        productionAPI.changeAssembler(request)
            .then(data => {
                    dispatch(getWorkersModuls());
            });
    }
}

export const setModulsStatusNew = (request) => {
    return (dispatch) => {
        productionAPI.setModulsStatusNew(request)
            .then(data => {
                if (data?.status === 1) {
                    dispatch(getWorkersModuls());
                    toast.success(data?.message);
                } else {
                    toast.error(data?.message);
                }

            });
    }
}

export default modulsPaidReducer;

