import { planAPI } from "../api/api";

const SET_PLAN = "SET_PLAN";


let initialState = {
    plan: [],
}

const planReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_PLAN:
            return {
                ...state,
                plan: action.plan
            }
        default:
            return state
    }
}

export const setPlan = (plan) => ({type: SET_PLAN, plan});

export const getPlan= () =>{
    return (dispatch) => {
        planAPI.getPlan()
        .then(data => {
            dispatch(setPlan(data));
        });
    }
}


export default planReducer;

