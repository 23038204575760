import React from 'react';
import { connect } from 'react-redux';
import {getRepair, setBindRepairToCheck} from '../../../../redux/repair-reducer';
import BindRepairToCheck from './BindRepairToCheck';

class BindRepairToCheckContainer extends React.Component{
    componentDidMount(){
        this.props.getRepair();
    }
    render(){
        return <BindRepairToCheck repair={this.props.repair} checkId={this.props.checkId} setActive={this.props.setActive} setBindRepairToCheck={this.props.setBindRepairToCheck}/>
    } 
}

let mapStateToProps = (state) =>({
    repair: state.repair.repair,
})
export default connect( mapStateToProps, {getRepair, setBindRepairToCheck})(BindRepairToCheckContainer);