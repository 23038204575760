import { techTaskAPI } from "../api/api";

const SET_TASKS = "SET_TASKS";
const SET_TASKS_COMMENT = "SET_TASKS_COMMENT";


let initialState = {
    tasks: [],
    tasksComment: [],
}

const techTaskReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_TASKS:
            return {
                ...state,
                tasks: action.tasks
            }
        case SET_TASKS_COMMENT:
            return {
                ...state,
                tasksComment: action.tasksComment
            }
        default:
            return state
    }
}

export const setTasks = (tasks) => ({type: SET_TASKS, tasks});
export const setTasksComment = (tasksComment) => ({type: SET_TASKS_COMMENT, tasksComment});

export const getTasks= (idWorker=0) =>{
    return (dispatch) => {
        techTaskAPI.getTasks(idWorker)
        .then(data => {
            dispatch(setTasks(data));
        });
    }
}
export const getTasksComment= () =>{
    return (dispatch) => {
        techTaskAPI.getTasksComment()
        .then(data => {
            dispatch(setTasksComment(data));
        });
    }
}
export const setNewTask = (array)=>{
    return (dispatch)=>{
        techTaskAPI.setNewTask(array)
        .then(data => {
            dispatch(getTasks());
        });
    }
}
export const addCommentForTask = (array)=>{
    return (dispatch)=>{
        techTaskAPI.addCommentForTask(array)
        .then(data => {
            dispatch(getTasksComment());
        });
    }
}
export const addNewDateInTask = (id,date, status)=>{
    return (dispatch)=>{
        techTaskAPI.addNewDateInTask(id,date, status)
        .then(data => {
            dispatch(getTasks());
        });
    }
}
export const setNewWorkerInTask = (idTask, idWorker)=>{
    return (dispatch)=>{
        techTaskAPI.setNewWorkerInTask(idTask, idWorker)
        .then(data => {
            dispatch(getTasks());
        });
    }
}
export const setTaskWithoutArchive = (idTask)=>{
    return (dispatch)=>{
        techTaskAPI.setTaskWithoutArchive(idTask)
        .then(data => {
            dispatch(getTasks());
        });
    }
}

export default techTaskReducer;

