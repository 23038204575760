import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom"; // Removed withRouter
import { compose } from "redux";
import { connect } from "react-redux";
import { getSearchResults } from "../../../redux/other-reducer";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

const Search = ({
                    getSearchResults,
                    ...props
                }) => {
    const [openSearchWindow, setOpenSearchWindow] = React.useState(false);

    const handleClickOpenSearchWindow = () => {
        setOpenSearchWindow(true);
    };

    const handleClose = () => {
        setOpenSearchWindow(false);
    };

    let [searchRequest, setSearchRequest] = React.useState(''); // поисковый запрос
    function handleSearchChange(value) {
        setSearchRequest(value);
        if (value.length > 2) {
            getSearchResults({ 'search': value });
            console.log(props.searchResults);
        }
    }

    return (
        <>
            <NavLink to="#" onClick={handleClickOpenSearchWindow}>
                <div><FontAwesomeIcon icon={faSearch} /> Поиск по сайту</div>
            </NavLink>
            <Dialog
                fullWidth={true}
                maxWidth={'xl'}
                open={openSearchWindow}
                onClose={handleClose}
            >
                <DialogTitle>Акон. Если не найдём - спаяем.</DialogTitle>
                <DialogContent>
                    <TextField
                        value={searchRequest}
                        onChange={(e) => handleSearchChange(e.currentTarget.value)}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Введите поисковый запрос."
                        type="text"
                        fullWidth
                        variant="standard"
                    />

                    <DialogContentText>
                        {searchRequest.length > 2 ? <h3>Компании: </h3> : ''}
                        {searchRequest.length > 0 && props?.searchResults?.companies?.length > 0
                            ? ""
                            : searchRequest.length > 2 ? "Нет совпадений" : ''
                        }
                    </DialogContentText>

                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {searchRequest.length > 0 && props?.searchResults?.companies?.map((item, index) => {
                            return (
                                <div key={index}>
                                    <div key={index} style={{ marginLeft: '15%' }}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemText
                                                primary={item?.name_with_akon ?? item?.name}
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                            sx={{ display: 'inline' }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        />
                                                        <NavLink to={'/company/profile/' + item.id} target="_blank">
                                                            {item?.name_with_akon
                                                                ? <> {item?.name} <br /></>
                                                                : item?.name
                                                                    ? item?.name_with_akon
                                                                    : ''
                                                            }
                                                        </NavLink>
                                                        {item.edrpou
                                                            ? <><b>ЄДРПОУ: </b>{item.edrpou}<br /></>
                                                            : ''
                                                        }
                                                        {item.date_plan
                                                            ? <><b>Календарь: </b>{item?.date_plan}<br /></>
                                                            : ''
                                                        }
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                        <Divider variant="inset" component="li" />
                                    </div>
                                </div>
                            )
                        })}
                    </List>

                    <DialogContentText>
                        {searchRequest.length > 2 ? <h3>Контакты: </h3> : ''}
                        {searchRequest.length > 0 && props?.searchResults?.agents?.length > 0
                            ? ""
                            : searchRequest.length > 2 ? "Нет совпадений" : ''
                        }
                    </DialogContentText>

                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {searchRequest.length > 0 && props?.searchResults?.agents?.map((item, index) => {
                            return (
                                <div key={index} style={{ marginLeft: '15%' }}>
                                    <ListItem alignItems="flex-start">
                                        <ListItemText
                                            primary={item.agent_name}
                                            secondary={
                                                <React.Fragment>
                                                    <Typography
                                                        sx={{ display: 'inline' }}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary"
                                                    />
                                                    <NavLink to={'/company/profile/' + item.id} target="_blank">{item.company_name}</NavLink><br />
                                                    {item.tell
                                                        ? <>Телефон: {item.tell}<br /></>
                                                        : ''
                                                    }
                                                    {item.tell2
                                                        ? <>Телефон: {item.tell2}<br /></>
                                                        : ''
                                                    }
                                                    {item.tell3
                                                        ? <>Телефон: {item.tell3}<br /></>
                                                        : ''
                                                    }
                                                    {item.email
                                                        ? <>email: {item.email}<br /></>
                                                        : ''
                                                    }
                                                    {item.position
                                                        ? <>Должность: {item.position}<br /></>
                                                        : ''
                                                    }
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                </div>
                            )
                        })}
                    </List>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>Закрыть</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default compose(
    connect((state) => ({
        searchResults: state.other.searchResults, // результат поиска
    }), {
        getSearchResults, // результат поиска
    })
)(Search);
