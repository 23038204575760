import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip as RechartsTooltip, Legend, Treemap } from 'recharts';
import { dashboardAPI } from '../../../api/api';
import CurrencyFormatter from "../../Sales/common/CurrencyFormatter/CurrencyFormatter";

const Dashboard = () => {
    const [totalComponents, setTotalComponents] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
    const [awaitingSupply, setAwaitingSupply] = useState({});
    const [belowLimitItems, setBelowLimitItems] = useState([]);
    const [selfProducedList, setSelfProducedList] = useState({});
    const [totalComponentsArray, setTotalComponentsArray] = useState({});
    const [itemsSelfProducedQty, setItemsSelfProducedQty] = useState([]);
    const [belowLimitDialogOpen, setBelowLimitDialogOpen] = useState(false);
    const [awaitingSupplyDialogOpen, setAwaitingSupplyDialogOpen] = useState(false);
    const [selfProducedDialogOpen, setSelfProducedDialogOpen] = useState(false);

    const fetchWarehouseData = async (force = false) => {
        try {
            const response = await dashboardAPI.getWarehouseData(force);
            const data = response;
            setTotalComponents(data.totalComponents);
            setTotalCost(data.totalCost);
            setAwaitingSupply(data.awaitingSupply);
            setBelowLimitItems(Object.values(data.belowLimitItems)); // Ensure it's an array
            setSelfProducedList(data.selfProducedList);
            setTotalComponentsArray(data.totalComponentsArray);
            setItemsSelfProducedQty(data.itemsSelfProducedQty);
        } catch (error) {
            console.error('Error fetching warehouse data:', error);
        }
    };

    const fetchWarehouseDataForce = () => {
        fetchWarehouseData(true);
    };

    useEffect(() => {
        fetchWarehouseData();
    }, []);

    const handleBelowLimitDialogOpen = () => {
        setBelowLimitDialogOpen(true);
    };

    const handleBelowLimitDialogClose = () => {
        setBelowLimitDialogOpen(false);
    };

    const handleAwaitingSupplyDialogOpen = () => {
        setAwaitingSupplyDialogOpen(true);
    };

    const handleAwaitingSupplyDialogClose = () => {
        setAwaitingSupplyDialogOpen(false);
    };

    const handleSelfProducedDialogOpen = () => {
        setSelfProducedDialogOpen(true);
    };

    const handleSelfProducedDialogClose = () => {
        setSelfProducedDialogOpen(false);
    };

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF8042', '#FF8042']; // Add more colors as needed
    const getColor = (qty, section) => {
        if (section === 34) return '#FF0000';
        const greenIntensity = Math.min(255, Math.max(0, 255 - qty * 2));
        return `rgb(0, ${greenIntensity}, 0)`;
    };

    const isAllUppercaseEnglishLetters = (input) => {
        const regex = /^[A-Z]+$/;
        return regex.test(input);
    };

    const preparePieData = (totalComponentsArray) => {
        const groupedData = {};

        Object.entries(totalComponentsArray).forEach(([key, value]) => {
            const group = key.slice(-3); // Get the last 3 characters of the component code
            if (!isAllUppercaseEnglishLetters(group)) return;
            if (!groupedData[group]) {
                groupedData[group] = { name: group, value: 0 };
            }
            groupedData[group].value += value.qty;
        });

        return Object.values(groupedData);
    };

    const pieData = preparePieData(totalComponentsArray);
    const sortPieData = (data) => data.sort((a, b) => b.value - a.value);

    const prepareTreemapData = (totalComponentsArray) => {
        const entries = Object.entries(totalComponentsArray)
            .map(([name, data]) => ({
                name,
                size: data.qty,
                color: getColor(data.qty, data.storage_section),
            }))
            .sort((a, b) => b.size - a.size); // Sort by size in descending order

        const top100 = entries.slice(0, 50);
        const others = entries.slice(50);

        const othersSize = others.reduce((acc, item) => acc + item.size, 0);

        if (othersSize > 0) {
            top100.push({
                name: 'остальные',
                size: othersSize,
                color: 'violet',
            });
        }

        return top100;
    };

    const treemapData = prepareTreemapData(totalComponentsArray);

    return (
        <div>
            <Button variant="contained" color="primary" onClick={fetchWarehouseDataForce}>
                Загрузить свежие данные (до часа погрешности)
            </Button>
            <Grid container spacing={2} style={{ marginTop: '16px', display: 'flex', alignItems: 'stretch' }}>
                {/* Card for Total Warehouse Components */}
                <Grid item xs={12} style={{ display: 'flex' }}>
                    <Card style={{ flex: 1 }}>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Компонентов на складе: {totalComponents}
                                        (<CurrencyFormatter amount={totalCost} withSpaces={true} />)
                                    </Typography>
                                    <Typography variant="h6">Собственного производства: {itemsSelfProducedQty} шт ({(itemsSelfProducedQty / totalComponents * 100).toFixed(2)}%)</Typography>
                                    <Typography variant="h6">
                                        Ожидается поставка: {Object.keys(awaitingSupply).length}
                                        <Button variant="text" color="primary" onClick={handleAwaitingSupplyDialogOpen} style={{ marginLeft: '10px' }}>
                                            Список
                                        </Button>
                                    </Typography>
                                    <Typography variant="h6">
                                        Компонентов ниже лимита: {belowLimitItems.length}
                                        <Button variant="text" color="primary" onClick={handleBelowLimitDialogOpen} style={{ marginLeft: '10px' }}>
                                            Список
                                        </Button>
                                    </Typography>
                                    <Typography variant="h6">
                                        Собственного производства: {Object.keys(selfProducedList).length}
                                        <Button variant="text" color="primary" onClick={handleSelfProducedDialogOpen} style={{ marginLeft: '10px' }}>
                                            Список
                                        </Button>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Card for Pie Chart */}
                <Grid item xs={12} style={{ display: 'flex' }}>
                    <Card style={{ flex: 1 }}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={9}>
                                    <PieChart width={700} height={400}>
                                        <Pie
                                            data={sortPieData(pieData)} // Sort the data before rendering
                                            cx="40%"
                                            cy="50%"
                                            innerRadius={60}
                                            outerRadius={150}
                                            fill="#8884d8"
                                            paddingAngle={5}
                                            dataKey="value"
                                            label
                                        >
                                            {pieData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                        <RechartsTooltip />
                                    </PieChart>
                                </Grid>
                                <Grid item xs={3}>
                                    <ul>
                                        {sortPieData(pieData).map((entry, index) => (
                                            <li key={`legend-${index}`} style={{ color: COLORS[index % COLORS.length] }}>
                                                {entry.name}: {entry.value}
                                            </li>
                                        ))}
                                    </ul>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Treemap for Total Components */}
                <Grid item xs={12} style={{ display: 'flex' }}>
                    <Card style={{ flex: 1 }}>
                        <CardContent>
                            <Treemap
                                width={800}
                                height={400}
                                data={treemapData}
                                dataKey="size"
                                stroke="#fff"
                                fill="#8884d8"
                                content={<CustomTooltip />}
                                animationBegin={100}
                                animationDuration={500}
                                animationEasing="ease-out"
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/* Dialog with Below Limit Details */}
            <Dialog open={belowLimitDialogOpen} onClose={handleBelowLimitDialogClose} maxWidth="md" fullWidth>
                <DialogTitle>Компоненты ниже лимита</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Список компонентов, количество которых на складе ниже минимального лимита.
                    </DialogContentText>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Название компонента</TableCell>
                                    <TableCell align="right">Текущий запас</TableCell>
                                    <TableCell align="right">Нижний лимит</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {belowLimitItems.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{item.component_name}</TableCell>
                                        <TableCell align="right">{item.current_stock}</TableCell>
                                        <TableCell align="right">{item.lower_limit}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleBelowLimitDialogClose} color="primary">
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog with Awaiting Supply Details */}
            <Dialog open={awaitingSupplyDialogOpen} onClose={handleAwaitingSupplyDialogClose} maxWidth="md" fullWidth>
                <DialogTitle>Ожидается поставка</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Список компонентов, которые ожидаются на поставку.
                    </DialogContentText>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Код компонента</TableCell>
                                    <TableCell align="right">Количество</TableCell>
                                    <TableCell align="right">Стоимость</TableCell>
                                    <TableCell align="right">Ожидаемая дата</TableCell>
                                    <TableCell align="right">Количество ожиданий</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.values(awaitingSupply).map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{item.component_code}</TableCell>
                                        <TableCell align="right">{item.quantity !== null ? item.quantity : 'N/A'}</TableCell>
                                        <TableCell align="right">{item.cost !== null ? item.cost : 'N/A'}</TableCell>
                                        <TableCell align="right">{item.expected_date !== null ? item.expected_date : 'N/A'}</TableCell>
                                        <TableCell align="right">{item.waiting_count}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAwaitingSupplyDialogClose} color="primary">
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog with Self Produced List Details */}
            <Dialog open={selfProducedDialogOpen} onClose={handleSelfProducedDialogClose} maxWidth="md" fullWidth>
                <DialogTitle>Собственное производство</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Список компонентов, которые произведены самостоятельно.
                    </DialogContentText>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Код компонента</TableCell>
                                    <TableCell align="right">Количество</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.entries(selfProducedList).map(([component_code, quantity], index) => (
                                    <TableRow key={index}>
                                        <TableCell>{component_code}</TableCell>
                                        <TableCell align="right">{quantity}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSelfProducedDialogClose} color="primary">
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

const CustomTooltip = ({ payload, active }) => {
    if (active && payload && payload.length) {
        const { name, size, color } = payload[0].payload;
        return (
            <div style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
                <p>{name}</p>
                <p>Quantity: {size}</p>
                <p style={{ color }}>Color: {color}</p>
            </div>
        );
    }
    return null;
};

export default Dashboard;
