import React, { useEffect } from 'react';
import "./Modal.css";

const Modal = ({ active, setActive, children, big }) => {
    const modalClassName = `modal ${active ? "active" : ""} ${big ? "big" : ""}`;

    const closeModalOnEscape = (e) => {
        if (e.key === "Escape") {
            setActive(false);
        }
    };

    useEffect(() => {
        // Add event listener when the modal becomes active
        if (active) {
            document.addEventListener("keydown", closeModalOnEscape);
        }

        // Remove event listener when the modal is not active (unmounted)
        return () => {
            document.removeEventListener("keydown", closeModalOnEscape);
        };
    }, [active]);

    return (
        <div className={modalClassName}>
            <div className={active ? "modal__content active" : "modal__content"} onClick={e => e.stopPropagation()}>
                <div onClick={() => setActive(false)} className="close"><span>X</span></div>
                {children}
            </div>
        </div>
    )
}

export default Modal;
