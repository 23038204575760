import React from 'react';
import s from './MainRecPaya.module.css';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const MainRecPaya = (props) => {
    let receivable, payable;
    receivable = [
        { month: "Январь", sum: 0 },
        { month: "Февраль", sum: 0 },
        { month: "Март", sum: 0 },
        { month: "Апрель", sum: 0 },
        { month: "Май", sum: 0 },
        { month: "Июнь", sum: 0 },
        { month: "Июль", sum: 0 },
        { month: "Август", sum: 0 },
        { month: "Сентябрь", sum: 0 },
        { month: "Октябрь", sum: 0 },
        { month: "Ноябрь", sum: 0 },
        { month: "Декабрь", sum: 2500000 },
        { month: "Январь 2022", sum: 2500000 },
    ];
    payable = [
        { month: "Январь", sum: 0 },
        { month: "Февраль", sum: 0 },
        { month: "Март", sum: 0 },
        { month: "Апрель", sum: 0 },
        { month: "Май", sum: 0 },
        { month: "Июнь", sum: 64000 },
        { month: "Июль", sum: 64000 },
        { month: "Август", sum: 64000 },
        { month: "Сентябрь", sum: 64000 },
        { month: "Октябрь", sum: 64000 },
        { month: "Ноябрь", sum: 305000 },
        { month: "Декабрь", sum: 305000 },
        { month: "Январь 2022", sum: 305000 },
    ];

    const data = [];
    receivable.map((k, index) => {
        data.push({
            name: k.month,
            "Дебиторка": Number(k.sum),
            "Кредиторка": Number(payable[index].sum),
        });
    });

    return (
        <>
            <ResponsiveContainer width="95%" height={250}>
                <BarChart width={150} height={40} data={data}>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Дебиторка" fill="#ffc658" label={{ position: 'center' }} stackId="a" isAnimationActive={false} />
                    <Bar dataKey="Кредиторка" fill="#8884d8" label={{ position: 'top' }} isAnimationActive={false} />
                </BarChart>
            </ResponsiveContainer>
            <div className={s.main + " " + s.weight}>
                <div>Месяц</div>
                <div>Дебиторка</div>
                <div>Кредиторка</div>
            </div>
            {receivable.map((k, index) => {
                return (
                    <div key={"payable_" + index} className={s.main}>
                        <div>{k.month}</div>
                        <div>{k.sum}</div>
                        <div>{payable[index].sum}</div>
                    </div>
                );
            })}
        </>
    );
};

export default MainRecPaya;
