import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faMinus} from '@fortawesome/free-solid-svg-icons';
import s from './Separator.module.css';
import {setSeparatorInBase} from "../../../../redux/modulsPaid-reducer";
import {productionAPI} from "../../../../api/api";
import {toast} from "react-toastify";

const Separator = (props) => {
    const [number1, setNumber1] = useState(Number(props.number) - 1);
    const [number2, setNumber2] = useState(1);

    const handlePlus = () => {
        if (number1 < Number(props.number)) {
            setNumber1(number1 + 1);
            setNumber2(number2 - 1);
        }
    };
    const handleSplit = async (id, number1, number2) => {
        await productionAPI.setSeparatorInBase(id, number1, number2)
            .then(data => {
                if (data.status === 1) {
                    toast.success('Задача успешно разделена');
                    window.location.reload();
                }
            });
    }

    const handleMinus = () => {
        if (number2 < Number(props.number)) {
            setNumber1(number1 - 1);
            setNumber2(number2 + 1);
        }
    };

    const handleNumber1Change = (e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && value > 0 && value <= Number(props.number) - 1) {
            setNumber1(value);
            setNumber2(Number(props.number) - value);
        }
    };

    const handleNumber2Change = (e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && value > 0 && value <= Number(props.number) - 1) {
            setNumber2(value);
            setNumber1(Number(props.number) - value);
        }
    };

    const setSeparatorInBaseF = (id, number1, number2) => {
        if (number1 !== 0 && number2 !== 0) {
            handleSplit(id, number1, number2);
        }
        props.setSeparator(0);
    };

    return (
        <div>
            <div className={s.overlay} onClick={() => props.setSeparator(0)}></div>
            <div className={`${s.main} ${s.pd10}`}>
                <div className={s.mainColl}>
                    <div onClick={handlePlus} className={s.shadow}><FontAwesomeIcon icon={faPlus}/></div>
                    <input
                        type="number"
                        value={number1}
                        onChange={handleNumber1Change}
                        className={s.inputField}
                    />
                    <input
                        type="number"
                        value={number2}
                        onChange={handleNumber2Change}
                        className={s.inputField}
                    />
                    <div onClick={handleMinus} className={s.shadow}><FontAwesomeIcon icon={faMinus}/></div>
                </div>
                <div className={s.mainButton}>
                    <div
                        className={`${s.bgGreen} ${s.pd10}`}
                        onClick={() => setSeparatorInBaseF(props.id, number1, number2)}
                    >
                        Сохранить
                    </div>
                    <div className={`${s.bgRed} ${s.pd10}`} onClick={() => props.setSeparator(0)}>Отмена</div>
                </div>
            </div>
        </div>
    );
};

export default Separator;
