import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getRelatedPaymentForYearMonth } from '../../../redux/sales-reducer';
import Cookies from 'js-cookie';
import ManagerSalary from "./ManagerSalary";

function updateDataRequest(year, month, idManager) {
    let personalId = Cookies.get('id');
    if (Cookies.get('id') === "44" || Cookies.get('id') === "34") {
        personalId = idManager;
    }
    return {
        'managerID': personalId,
        'controlID': Cookies.get('id'),
        'year': year,
        'month': month,
    };
}

const ManagerSalaryContainer = (props) => {
    const { year, month, idManager: paramIdManager } = useParams();

    // Фіксуємо поточний idManager
    const currentPersonalId = React.useMemo(() => {
        let personalId = paramIdManager;
        let isAdmin = !!Cookies.get('adminID');
        if (!isAdmin && Cookies.get('id') !== paramIdManager) {
            personalId = Cookies.get('id');
        }
        return personalId;
    }, [paramIdManager]);

    useEffect(() => {
        const request = updateDataRequest(year, month, currentPersonalId);
        props.getRelatedPaymentForYearMonth(request);
    }, [year, month, currentPersonalId]); // Тільки ці параметри як залежності

    if (props.relatedPaymentForYearMonth.length === 0) return <div>Загрузка...</div>;

    return (
        <div>
            <ManagerSalary {...props} />
        </div>
    );
};

const mapStateToProps = (state) => ({
    relatedPaymentForYearMonth: state.sales.relatedPaymentForYearMonth,
});

export default compose(
    connect(mapStateToProps, { getRelatedPaymentForYearMonth })
)(ManagerSalaryContainer);
