import React from 'react';
import s from './Distribution.module.css';
import main from '../../Main.module.css';
import { useState } from 'react';

const DeleteDistribution = (props) =>{
        const AddNewNom = ()=>{
            props.deleteDistribution(props.active, props.personalId)
            props.setActive(false)
        }
        return(
            <div className={s.addDesc}>
                <h2 className={main.center}>Вы действительно хотите удалить рассылку №{props.active}</h2>
                {/* <div className={s.twoBlock}>
                    <div>Номенклатура:* </div>
                    <div><input type="number" value={nom} onChange={setNomF} /></div>
                </div> */}
                <div className={main.center}><button onClick={()=>AddNewNom()}>Удалить</button></div>
            </div>
        )
}
export default DeleteDistribution;