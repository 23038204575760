import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {
    getStatisticsPaidFromModulesNomenclature,
    getPersonalBalancesInStock,
    getModulesFinanceInfo
} from '../../redux/moduls-reducer';
import PersonalModule from './PersonalModule';
import Cookies from "js-cookie";

const PersonalModuleContainer = ({
                                     getStatisticsPaidFromModulesNomenclature,
                                     getPersonalBalancesInStock,
                                     getModulesFinanceInfo,
                                     statisticsPaidFromModulesNomenclature,
                                     personalBalancesInStock,
                                     modulesFinanceInfo
                                 }) => {
    const {nom} = useParams(); // Використовуємо useParams з React Router v6
    const [canSeeSelfCost, setCanSeeSelfCost] = useState(false);

    useEffect(() => {
        // Виклик асинхронних дій при монтуванні компонента
        getStatisticsPaidFromModulesNomenclature(nom);
        getPersonalBalancesInStock(nom);

        let managerID = parseInt(Cookies.get('id'));

        if (managerID === 34 || managerID === 44) {
            getModulesFinanceInfo(nom);
            setCanSeeSelfCost(true); // Оновлюємо стан для можливості бачити собівартість
        }
    }, [nom, getStatisticsPaidFromModulesNomenclature, getPersonalBalancesInStock, getModulesFinanceInfo]);

    //console.log(nom)

    return (
        <>
            <PersonalModule
                statisticsPaidFromModulesNomenclature={statisticsPaidFromModulesNomenclature}
                getStatisticsPaidFromModulesNomenclature={getStatisticsPaidFromModulesNomenclature}
                nom={nom}
                getPersonalBalancesInStock={getPersonalBalancesInStock}
                personalBalancesInStock={personalBalancesInStock}
                modulesFinanceInfo={modulesFinanceInfo}
                canSeeSelfcost={canSeeSelfCost}
            />
        </>
    );

};

const mapStateToProps = (state) => {
    return {
        statisticsPaidFromModulesNomenclature: state.moduls.statisticsPaidFromModulesNomenclature,
        personalBalancesInStock: state.moduls.personalBalancesInStock,
        modulesFinanceInfo: state.moduls.modulesFinanceInfo
    };
};


export default connect(mapStateToProps, {
    getStatisticsPaidFromModulesNomenclature,
    getPersonalBalancesInStock,
    getModulesFinanceInfo
})(PersonalModuleContainer);
