import React, { useState,useEffect } from 'react';
import s from '../NomenclaturesName.module.css';
import main from '../../../../Main.module.css'

const ChangePosition = (props) =>{
    let [text, setText] = useState("Создание позиции")
    let [name, setName] = useState("")
    let [minColl, setMinColl] = useState(0)
    let [defColl, setDefColl] = useState(0)
    let [maxColl, setMaxColl] = useState(0)
    let [textUkr, setTextUkr] = useState("")
    let [textRu, setTextRu] = useState("")
    let [error, setError] = useState(false)
    const handleInputChange = (event)=> {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        setError(false)
        switch (name) {
          case 'name':
            setName(value);
            break;
          case 'minColl':
            setMinColl(value);
            break;
          case 'defColl':
            setDefColl(value);
            break;
          case 'maxColl':
            setMaxColl(value);
            break;
          case 'textUkr':
            setTextUkr(value);
            break;
          case 'textRu':
            setTextRu(value);
            break;
          default:
            break;
        }
      }
    useEffect(() => {
        if(props.active!=="0"){
            const match = props.nomenclaturesName.find(k => k.ID === props.active);
            console.log(match)
            setName(match.NAME)
            setMinColl(match.CHANNELSMIN)
            setDefColl(match.CHANNELSDEFAULT)
            setMaxColl(match.CHANNELSMAX)
            setTextUkr(match.DESCRIPTION_UA)
            setTextRu(match.DESCRIPTION)
            setText("Изменение позиции")
        }
    }, [props.active])
    const go=()=>{
        let go = true
        if(name === "" || textUkr === "" || textRu === "") go=false
        if(minColl === 0 || defColl === 0 || maxColl === 0) go=false
        if(go){
            let array = []
            array.push(props.active, name, minColl, defColl, maxColl, textUkr, textRu)
            props.changeNomenclaturesNames(array)
            props.setActive(false)
        }else{
            setError("Заполните все данные")
        }
    }
    return(
        <div>
            <h2 className={main.center}>{text}</h2>
            <div>
                <div className={main.bold}>Название позиции:</div>
                <input type="text" value={name} name="name" onChange={handleInputChange} className={main.input}/>
            </div>
            <div>
                <div>
                    <div className={main.bold}>Минимальное кол. каналов:</div>
                    <input type="number" name="minColl" value={minColl} onChange={handleInputChange} className={main.input}/>
                </div>
                <div>
                    <div className={main.bold}>Стандартное кол. каналов:</div>
                    <input type="number" name="defColl" value={defColl} onChange={handleInputChange} className={main.input}/>
                </div>
                <div>
                    <div className={main.bold}>Максимальное кол. каналов:</div>
                    <input type="number" name="maxColl" value={maxColl} onChange={handleInputChange} className={main.input}/>
                </div>
            </div>
            <div>
                <div className={main.bold}>Описание на укр. языке:</div>
                <textarea value={textUkr} name="textUkr" onChange={handleInputChange} className={main.textarea}/>
            </div>
            <div>
                <div className={main.bold}>Описание на росс. языке:</div>
                <textarea value={textRu} name="textRu" onChange={handleInputChange} className={main.textarea}/>
            </div>
            {error && <div className={main.error}>{error}</div>}
            <div className={main.center}><button className={main.inputAdd} onClick={()=>go()}>Сохранить</button></div>
        </div>
    )
    
}
export default ChangePosition;