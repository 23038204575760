import React from 'react';
import Chart from 'react-apexcharts';

const BarChartComponent = ({ data, filters }) => {
    if (!data || !data.companyAgentsMessages) {
        console.error('Invalid data.companyAgentsMessages:', data ? data.companyAgentsMessages : 'data is undefined');
        return <div>No data available</div>;
    }

    // Extract unique manager IDs and categories (year-month)
    const uniqueManagerIds = [...new Set(data.companyAgentsMessages.map(item => item.manager_id))];
    const categories = [...new Set(data.companyAgentsMessages.map(item => `${item.year}-${item.month}`))].sort();

    // Initialize series data
    const seriesData = uniqueManagerIds.map(managerId => {
        const managerData = data.companyAgentsMessages.filter(item => item.manager_id === managerId);
        const dataPoints = categories.map(category => {
            const [year, month] = category.split('-').map(Number);
            const recordsCount = managerData
                .filter(item => item.year === year && item.month === month)
                .reduce((sum, item) => sum + item.records_count, 0);
            return recordsCount;
        });
        return {
            name: data.managerNames[managerId] || `Manager ${managerId}`,
            data: dataPoints
        };
    });

    const options = {
        chart: {
            type: 'bar',
            zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: true
            },
            toolbar: {
                show: true,
                tools: {
                    download: true,
                },
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        dataLabels: {
            enabled: true,
            style: {
                colors: ['#000'] // Set value color to black
            }
        },
        xaxis: {
            categories: categories,
        },
        colors: [
            '#1270ea',
            '#2d7ee5',
            '#12a9ea',
            '#3dd7d1',
            '#3ee0b2',
            '#82eab0'
        ]
    };

    return (
        <div>
            <Chart
                options={options}
                series={seriesData}
                type="bar"
                height="350"
            />
        </div>
    );
};

export default BarChartComponent;
