import React, { useState, PureComponent, useEffect } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import AnyFormatDate from '../../Production/common/AnyFormatDate/AnyFormatDate';
import main from '../../Main.module.css'
import s from './SalesPersonalStatistics.module.css'

const SalesPersonalStatistics = (props) =>{
    console.log(props)
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    console.log(props.allActivity)
    let [dateStart, setDateStart] = useState("")
    const setDateStartF=(e)=>setDateStart(e.target.value)
    let [dateEnd, setDateEnd] = useState("")
    const setDateEndF=(e)=>setDateEnd(e.target.value)
    let [activity, setActivity] = useState(props.allActivity)
    let [allCheck, setAllCheck] = useState([])
    let [allKp, setAllKp] = useState([])
    useEffect(() => {
        setActivity([...props.allActivity]);
        setAllCheck(props.allActivity.filter(obj => obj.type === 1))
        setAllKp(props.allActivity.filter(obj => obj.type === 2))
        getDataWithFilter(2)
    }, [props.allActivity])
    const getCheckEveryDay = ()=>{
        const uniqueDates = {};
        allCheck.forEach(item => {
            const date = item.dateMain;
            if (uniqueDates[date]) {
                uniqueDates[date].count++;
                if (item.paid !== null) {
                    uniqueDates[date].paidNotNull++;
                }
                if (item.paid === null) {
                    uniqueDates[date].paidNull++;
                }
            } else {
                uniqueDates[date] = {
                    count: 1,
                    paidNotNull: item.paid !== null ? 1 : 0,
                    paidNull: item.paid === null ? 1 : 0
                };
            }
        });

        return Object.keys(uniqueDates)
            .map(date => ({
                date,
                count: uniqueDates[date].count,
                paidNotNull: uniqueDates[date].paidNotNull,
                paidNull: uniqueDates[date].paidNull,
            }))
            .sort((a, b) => new Date(a.date) - new Date(b.date));
    }
    const getCheckEveryMonth = ()=>{
        const groupedData = new Map();
        allCheck.forEach(item => {
            const date = new Date(item.dateMain);
            const month = date.getMonth() + 1; // Получаем месяц (1-январь, 2-февраль, и т.д.)
            const year = date.getFullYear();

            const key = `${year}-${month}`; // Создаем ключ в формате "год-месяц"
            if (groupedData.has(key)) {
                groupedData.get(key).push(item);
            } else {
                groupedData.set(key, [item]);
            }
        });

        const newArray = Array.from(groupedData, ([key, value]) => {
            const [year, month] = key.split('-');
            return {
                date: `${year}-${month}`,
                count: value.length,
                paidNotNull: value.filter(item => item.paid !== null).length,
                paidNull: value.filter(item => item.paid === null).length
            };
        });
        newArray.sort((a, b) => {
            const [yearA, monthA] = a.date.split('-');
            const [yearB, monthB] = b.date.split('-');
            const dateA = new Date(yearA, monthA - 1);
            const dateB = new Date(yearB, monthB - 1);
            return dateA - dateB;
        });
        return newArray
    }
    const getDataWithFilter=(filterA)=>{
        let newArray; let dataNew = []
        switch(filterA){
            case 1: newArray = getCheckEveryDay();break;
            case 2: newArray = getCheckEveryMonth();break;
            default: newArray = getCheckEveryMonth();
        }
        console.log(newArray)
        newArray.map((k,index)=>{
            dataNew.push({
                name: k.date,
                "Да": k.paidNotNull,
                "Нет": Number(k.count)-Number(k.paidNotNull),
                "Всего": k.count,
            })
        })
        setData(dataNew)
    }
    
    return(
        <div>
            <div className={s.blockFilter}>
                <div><input type="date" value={dateStart} className={main.input} onChange={setDateStartF} /></div>
                <div><input type="date" value={dateEnd} className={main.input} onChange={setDateEndF} /></div>
                <div className={main.m20}><span className={main.inputAdd} onClick={()=>props.getAllActivity(props.personalId, dateStart, dateEnd)}>Принять</span></div>
                <div className={main.m20+" "+main.right}><span className={main.inputAdd+" "+main.greyBg} onClick={()=>getDataWithFilter(1)}>По дням</span></div>
                <div className={main.m20}><span className={main.inputAdd+" "+main.greyBg} onClick={()=>getDataWithFilter(2)}>По месяцам</span></div>
            </div>
            <h1>Персональная статистика менеджера</h1>
            
            <h2>Счета</h2>
            <ResponsiveContainer width="95%" height={250}>
                <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Нет" fill="#8884d8" label={{ position: 'top' }} />
                <Bar dataKey="Да" fill="#82ca9d" label={{ position: 'top' }} />
                <Bar dataKey="Всего" fill="#ffc658" label={{ position: 'top' }} isAnimationActive={false} />
                </BarChart>
            </ResponsiveContainer>
            <h2>КП</h2>
            <ResponsiveContainer width="95%" height={250}>
                <BarChart
                width={500}
                height={300}
                data={data2}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Нет" fill="#8884d8" label={{ position: 'top' }} />
                <Bar dataKey="Да" fill="#82ca9d" label={{ position: 'top' }} />
                <Bar dataKey="Всего" fill="#ffc658" label={{ position: 'top' }} isAnimationActive={false} />
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}
export default SalesPersonalStatistics;