import React from 'react';
import s from "../Engineer.module.css";
import WhoMake from '../../common/MainTable/WhoMake';
import CheckNameColl from '../../common/MainTable/CheckNameColl';

const TechEngineer = (props) =>{
    console.log(props.workersModuls)
    let handleSubmit = (event) => {
        event.preventDefault();
    }
    let workersModuls =[];
    props.workersModuls.map( module => {
        if(module.status == 2 || module.status == 4){
            workersModuls.push(module);
        }
    })
    //сортируем по дате оплаты, для выставления правильного порядка для работы монтажников
    let asd = (arr) =>{
        return arr.sort((a, b) => a.main_date > b.main_date ? 1 : -1);
    }
    console.log(workersModuls)
    return(
        <div className={s.main}>
            <div className={s.mainTable}>
                <div>№</div>
                <div>Название</div>
                <div>Количество</div>
                <div>Ответственный</div>
                <div>Статус</div>
            </div>
            {
                asd(workersModuls).map( module => {
                    return(
                        <div className={s.table}  key={module.id + module.number + module.moduls_name + module.moduls_coll + module.moduls_type_in_production +module.who_make}>
                            <CheckNameColl
                                number={module.number}
                                name={module.moduls_name}
                                coll={module.moduls_coll}
                                moduls_type_in_production={module.moduls_type_in_production}
                                newDevice={module.newDevice}
                            />
                            <WhoMake
                                whoMake={module.who_make}
                                module={module}
                            />
                            <div>
                                <form onSubmit={handleSubmit}>
                                    <button onClick={() => props.setModulsStatus(module.status, module.id, 0, module.moduls_type_in_production)}>
                                    {module.status == 2 ? "Первичка готова" : "ВХ готов"}
                                    </button>
                                </form>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}
export default TechEngineer;