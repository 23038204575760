import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getAllCompanyOutdatedWithManager } from '../../../redux/sales-reducer';
import AllCompanyOutdatedWithManager from './AllCompanyOutdatedWithManager';
import Cookies from 'js-cookie';

const AllCompanyOutdatedWithManagerContainer = (props) => {
    const { manager } = useParams();
    let currentPersonalId = manager;

    useEffect(() => {
        let isAdmin = !!Cookies.get('adminID');
        if (!isAdmin && Cookies.get('id') !== currentPersonalId) {
            currentPersonalId = Cookies.get('id');
        }
        props.getAllCompanyOutdatedWithManager(currentPersonalId);
    }, [manager, props]);

    return (
        <div>
            <AllCompanyOutdatedWithManager {...props} managerID={manager} />
        </div>
    );
};

let mapStateToProps = (state) => ({
    allCompanyOutdatedWithManager: state.sales.companyOutdatedSegmentation,
});

export default compose(
    connect(mapStateToProps, { getAllCompanyOutdatedWithManager })
)(AllCompanyOutdatedWithManagerContainer);
