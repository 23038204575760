import React from 'react';
import s from './PersonalModuleCategory.module.css';


const PersonalModuleCategory = (props) =>{
    console.log(props.category)
    return(
        <div className={s.main}>
            <div onClick={()=>props.setCategory(0)} className={props.category==0 ? s.active : ""}>Все компании</div>
            {props.personalModuleCategory.map((k,index)=>{
                return <div key={"personalModuleCategory_"+index} onClick={()=>props.setCategory(k.id)} className={props.category==k.id ? s.active : ""}>{k.name}</div>
            })}
            
        </div>
    )
}
export default PersonalModuleCategory;