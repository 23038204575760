import React from 'react';
import { useParams } from 'react-router-dom';
import s from '../DDC/DDC.module.css';

const DDCNew = (props) => {
  console.log(props.articles);
  console.log(props.sumSubentriesGrupMonth);

  // Группировка данных по articles.id и месяцам
  const groupedData = props.sumSubentriesGrupMonth.reduce((acc, entry) => {
    const key = `${entry.articles_subentries_id}-${entry.articles_id}`;
    if (!acc[key]) {
      acc[key] = {
        articles_id: entry.articles_id,
        subentries_id: entry.articles_subentries_id,
        subentries: entry.subentries,
        totals: {},
      };
    }

    const monthYearKey = `${entry.year}-${entry.month}`;
    if (!acc[key].totals[monthYearKey]) {
      acc[key].totals[monthYearKey] = 0;
    }

    acc[key].totals[monthYearKey] += parseFloat(entry.total_subentry_numeral);

    return acc;
  }, {});

  // Создание объекта для сумм по месяцам для строки Articles
  const articlesTotal = {};
  props.articles.forEach((article) => {
    articlesTotal[article.id] = {};
    props.sumSubentriesGrupMonth.forEach((entry) => {
      if (entry.articles_id === article.id) {
        const monthYearKey = `${entry.year}-${entry.month}`;
        if (!articlesTotal[article.id][monthYearKey]) {
          articlesTotal[article.id][monthYearKey] = 0;
        }
        articlesTotal[article.id][monthYearKey] += parseFloat(entry.total_subentry_numeral);
      }
    });
  });

  // Получение уникальных и отсортированных месяцев
  const uniqueMonths = Array.from(
      new Set(
          props.sumSubentriesGrupMonth.map((entry) => `${entry.year}-${entry.month}`)
      )
  ).sort((a, b) => {
    const [aYear, aMonth] = a.split('-').map(Number);
    const [bYear, bMonth] = b.split('-').map(Number);

    if (aYear !== bYear) {
      return aYear - bYear;
    }

    return aMonth - bMonth;
  });

  const formatNumber = (number) => {
    // Если число не определено или NaN, возвращаем пустую строку
    if (number === undefined || isNaN(number)) {
      return '';
    }

    // Преобразование числа в строку, замена точки на запятую и удаление пробелов
    return number.toLocaleString('ru-RU', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).replace(/\s/g, '');
  };

  return (
      <table>
        <thead>
        <tr>
          <th>Articles / Subentries</th>
          {uniqueMonths.map((month) => (
              <th key={month}>{month}</th>
          ))}
        </tr>
        </thead>
        <tbody>
        {props.articles.map((article, index) => {
          const hasSubentries = Object.values(groupedData).some(
              (entry) => entry.articles_id === article.id
          );

          return hasSubentries ? (
              <React.Fragment key={article.id}>
                <tr>
                  <td>{article.articles}</td>
                  {uniqueMonths.map((month) => (
                      <td key={month}>
                        {index === 0
                            ? formatNumber(articlesTotal[article.id][month])
                            : // Проверка на первую строку
                            groupedData[article.id]?.totals[month] !== undefined
                                ? formatNumber(groupedData[article.id]?.totals[month])
                                : ''}
                      </td>
                  ))}
                </tr>
                {Object.values(groupedData).map((entry) => (
                    entry.articles_id === article.id && (
                        <tr key={entry.subentries_id}>
                          <td>&nbsp;&nbsp;&nbsp;&nbsp;{entry.subentries}</td>
                          {uniqueMonths.map((month) => (
                              <td key={month}>
                                {entry.totals[month] !== undefined
                                    ? formatNumber(entry.totals[month])
                                    : ''}
                              </td>
                          ))}
                        </tr>
                    )
                ))}
              </React.Fragment>
          ) : null;
        })}
        </tbody>
      </table>
  );
};

export default DDCNew;
