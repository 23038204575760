import { adminAPI } from "../api/api";

const SET_ALL_ARTICLES = "SET_ALL_ARTICLES";
const SET_ALL_SUBENTRIES = "SET_ALL_SUBENTRIES";
const SET_CASH = "SET_CASH";
const SET_CASHFLOW_FOR_MONTH = "SET_CASHFLOW_FOR_MONTH";
const SET_SUM_CASHFLOW_FOR_MONTH = "SET_SUM_CASHFLOW_FOR_MONTH";


let initialState = {
    articles: [],
    subentries: [],
    cash: 0,
    cashFlowForMonth:[],
    sumCashFlowForMonth: 0
}

const cashReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_ALL_ARTICLES:
            return {
                ...state,
                articles: action.articles
            }
        case SET_ALL_SUBENTRIES:
            return {
                ...state,
                subentries: action.subentries
            }
        case SET_CASH:
            return {
                ...state,
                cash: action.cash
            }
        case SET_CASHFLOW_FOR_MONTH:
            return {
                ...state,
                cashFlowForMonth: action.cashFlowForMonth
            }
        case SET_SUM_CASHFLOW_FOR_MONTH:
            return {
                ...state,
                sumCashFlowForMonth: action.sumCashFlowForMonth
            }
        default:
            return state
    }
}

export const setAllArticles = (articles) => ({type: SET_ALL_ARTICLES, articles});
export const setSubentries = (subentries) => ({type: SET_ALL_SUBENTRIES, subentries});
export const setMoneyCash = (cash) => ({type: SET_CASH, cash});
export const setCashFlowForMonth = (cashFlowForMonth) => ({type: SET_CASHFLOW_FOR_MONTH, cashFlowForMonth});
export const setSumCashFlowForMonth = (sumCashFlowForMonth) => ({type: SET_SUM_CASHFLOW_FOR_MONTH, sumCashFlowForMonth});

export const getMoneyCash= () =>{
    return (dispatch) => {
        adminAPI.getMoneyCash()
        .then(data => {
            dispatch(setMoneyCash(data));
        });
    }
}
export const getCashFlowForMonth= (month) =>{
    return (dispatch) => {
        adminAPI.getCashFlowForMonth(month)
        .then(data => {
            dispatch(setCashFlowForMonth(data));
        });
    }
}
export const getSumCashFlowForMonth= (month) =>{
    return (dispatch) => {
        adminAPI.getSumCashFlowForMonth(month)
        .then(data => {
            dispatch(setSumCashFlowForMonth(data));
        });
    }
}
export const getAllArticles= () =>{
    return (dispatch) => {
        adminAPI.getAllArticles()
        .then(data => {
            dispatch(setAllArticles(data));
        });
    }
}
export const getSubentries= (idArticles) =>{
    return (dispatch) => {
        adminAPI.getSubentries(idArticles)
        .then(data => {
            dispatch(setSubentries(data));
        });
    }
}
export const addNewCash= (newCash, month) =>{
    return (dispatch) => {
        adminAPI.addNewCash(newCash)
        .then(data => {
            dispatch(getSumCashFlowForMonth(month));
            dispatch(getMoneyCash());
        });
    }
}


export default cashReducer;

