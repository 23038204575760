import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Circle } from 'react-leaflet';
import L from 'leaflet';

const SalesMapCompanies = () => {
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        fetch('/api/companies')  // Adjust the endpoint as needed
            .then(response => response.json())
            .then(data => setCompanies(data))
            .catch(error => console.log(error));
    }, []);

    return (
        <MapContainer center={[50.4501, 30.5234]} zoom={6} style={{ height: '600px', width: '100%' }}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {companies.map(company => (
                <Circle
                    key={company.id}
                    center={[company.latitude, company.longitude]} // Make sure your data includes latitude and longitude
                    radius={company.money * 100} // Adjust scaling factor as needed
                    fillColor="blue"
                    color="red"
                />
            ))}
        </MapContainer>
    );
};

export default SalesMapCompanies;
