import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from "react";
import { useState } from "react";
import main from '../../../../Main.module.css'
import s from '../MessagesCheck.module.css'
import Cookies from 'js-cookie'

const AddMessage = (props)=>{
    let [addMessage, setAddMessage] = useState("")
    const setAddMessageF = (e)=>setAddMessage(e.target.value)
    let [agentsId, setAgentsId] = useState("")
    const setAgentsIdF = (e)=>{
        setError(false)
        setAgentsId(e.target.value)
    }
    const plus = ()=>{
        props.setActive(false)
        props.setAddAgent(true)
    }
    const go = ()=>{
        let array = []
        if(agentsId!==""){
            array.push(props.companyId)
            array.push(agentsId)
            array.push(Cookies.get('id'))
            array.push(props.checkId)
            array.push(addMessage)
            props.setNewKpMessage(array, props.checkId)
            props.setActive(false)
        }else{
            setError("Выберите контакт")
        }
    }
    let [error, setError] = useState(false)
    return(
        <div>
            <h2 className={main.center}>Добавить сообщение</h2>
            <div className={main.twoBlocksGridOneOfTwo}>
                <div className={main.p20+" "+main.bold}>С кем говорили?</div>
                <div className={main.twoBlocksGridSixOfOne}>
                    <div>
                        <select onChange={setAgentsIdF} className={main.input}>
                        <option value={""}>Не выбрано</option>
                        {props.companyAgents.map(k=>{
                            return <option value={k.id} key={"companyAgents"+k.id} id={k.id}>{k.name}</option>
                        })
                        }
                        </select>
                    </div>
                    <div className={main.p20+" "+main.bold}><FontAwesomeIcon icon={faPlus} onClick={()=>plus()} className={s.spanPlus}/></div>
                </div>
            </div>
            <textarea onChange={setAddMessageF} value={addMessage} className={main.textarea} />
            {error && <div>{error}</div>}
            <div className={main.center}><input type="button" value="Записать" className={main.inputAdd} onClick={()=>go()} /></div>
        </div>
    )
} 
export default AddMessage;