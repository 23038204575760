import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/joy/IconButton";



const WhoMake = (props) =>{
    let getAssemblerName = (whoMake) =>{
        switch(parseInt(whoMake)){
            case 0:
                return "Не распределено";
            case 1:
                return "Гаевая";
            case 3:
                return "Колесов";
            case 5:
                return "Ступина";
            case 6:
                return "Юлия";
            case 7:
                return "Со склада";
            default:
                return "Кто ты?";
        }
    }
    //console.log(parseInt(props.whoMake), getAssemblerName(props.whoMake))
    //console.log(props.module)
    return getAssemblerName(props.module?.who_make) //changeAssemblerWindow(props.module);
}
export default WhoMake;