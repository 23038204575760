import { salesPesonalAPI } from "../api/api";

const SET_ALL_ACTIVITY = "SET_ALL_ACTIVITY";
const SET_STATUS = "SET_STATUS";


let initialState = {
    allActivity: [],
    statusNomenclatures: "",
}

const SalesPersonalReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_ALL_ACTIVITY:
            return {
                ...state,
                allActivity: action.array
            }
        case SET_STATUS:
            return {
                ...state,
                statusNomenclatures: action.array
            }
        default:
            return state
    }
}

export const setAllActivity = (array) => ({type: SET_ALL_ACTIVITY, array});
export const setStatus = (array) => ({type: SET_STATUS, array});

export const getAllActivity= (idManager, dateStart="", dateEnd="", search="") =>{
    return (dispatch) => {
        salesPesonalAPI.getAllActivity(idManager, dateStart, dateEnd, search)
        .then(data => {
            dispatch(setAllActivity(data));
        });
    }
}
export const addNewNomenclatures= (text) =>{
    return (dispatch) => {
        salesPesonalAPI.addNewNomenclatures(text)
        .then(data => {
            dispatch(setStatus(data));
        });
    }
}

export default SalesPersonalReducer;

