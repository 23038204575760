import React, { useState } from 'react';
import s from './BindRepairToCheck.module.css';
import main from '../../../Main.module.css'


const BindRepairToCheck = (props) =>{
    console.log(props.repair)
    let [repairId, setRepairId] = useState(false)
    const setRepairIdF = (e)=>{
        setRepairId(e.target.value)
    }
    const BindRepairToCheck = ()=>{
        props.setBindRepairToCheck(props.checkId, repairId)
        props.setActive(false)
    }
    return(
        <div className={s.main}>
            <h2>Ремонт</h2>
            <div>
                <select onChange={setRepairIdF} className={main.input}>
                    <option>Не выбран</option>
                    {props.repair.map(k=>{
                        return(
                            k.assurance==2 && k.date_delivery==null && <option value={k.id} key={k.id}>{k.id}</option>
                        ) 
                    })}
                </select>
                <button onClick={()=>BindRepairToCheck()} className={main.inputAdd}>Привязать</button>
            </div>
        </div>
    )
}
export default BindRepairToCheck;