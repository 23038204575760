import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
    getAllInfoFromCheck,
    getAllModulsFromCheck,
    setNewCourseAndSumCheck,
    setDaysManagerFromCheck,
    setPlanDateFromCheck,
    setCommentForCheck,
    setDontShowKp,
    changeCollModulsInCheck,
    requestForKpStatus,
    deleteCheck
} from '../../../redux/check-reducer';
import { getAllProject } from '../../../redux/project-reducer';
import Check from './Check';
import { useParams } from 'react-router-dom';

class CheckContainer extends React.Component {
    componentDidMount() {
        const { idCheck, typeCheck } = this.props.params;
        this.props.getAllInfoFromCheck(idCheck, typeCheck);
        this.props.getAllModulsFromCheck(idCheck, typeCheck);
        this.props.getAllProject();
    }

    render() {
        return (
            <div>
                <Check
                    info={this.props.allInfoFromCheck}
                    moduls={this.props.allModulesFromCheck}
                    type={this.props.params.typeCheck}
                    setNewCourseAndSumCheck={this.props.setNewCourseAndSumCheck}
                    setDaysManagerFromCheck={this.props.setDaysManagerFromCheck}
                    setPlanDateFromCheck={this.props.setPlanDateFromCheck}
                    setCommentForCheck={this.props.setCommentForCheck}
                    setDontShowKp={this.props.setDontShowKp}
                    changeCollModulsInCheck={this.props.changeCollModulsInCheck}
                    requestForKpStatus={this.props.requestForKpStatus}
                    allProject={this.props.allProject}
                    deleteCheck={this.props.deleteCheck}
                    idCheck={this.props.params.idCheck}
                />
            </div>
        );
    }
}

let mapStateToProps = (state) => ({
    allInfoFromCheck: state.check.allInfoFromCheck,
    allModulesFromCheck: state.check.allModulesFromCheck,
    allProject: state.project.allProject,
});

// Custom withRouter replacement using useParams
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const params = useParams();
        return <Component {...props} params={params} />;
    }

    return ComponentWithRouterProp;
}

export default compose(
    connect(mapStateToProps, {
        getAllInfoFromCheck,
        getAllModulsFromCheck,
        setNewCourseAndSumCheck,
        setDaysManagerFromCheck,
        setPlanDateFromCheck,
        setCommentForCheck,
        setDontShowKp,
        changeCollModulsInCheck,
        requestForKpStatus,
        getAllProject,
        deleteCheck
    }),
    withRouter
)(CheckContainer);
