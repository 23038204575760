import { otherAPI } from "../api/api";

const SET_PRODUCTS_LIST = "SET_PRODUCTS_LIST";
const SET_COMPANY_LIST = "SET_COMPANY_LIST";
const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS";


let initialState = {
    productsList: [],
    companyList: [],
    searchResult: [],
}

const otherReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_PRODUCTS_LIST:
            return {
                ...state,
                productsList: action.productsList
            }
        case SET_COMPANY_LIST:
            console.log("SET_COMPANY_LIST", action.companyList);
            return {
                ...state,
                companyList: action.companyList
            }
        case SET_SEARCH_RESULTS:
            return {
                ...state,
                searchResults: action.searchResults.data
            }
        default:
            return state
    }
}

export const setProductsList = (productsList) => ({type: SET_PRODUCTS_LIST, productsList});
export const setCompanyList = (companyList) => ({type: SET_COMPANY_LIST, companyList});
export const setSearchResults = (searchResults) => ({type: SET_SEARCH_RESULTS, searchResults});

export const getProductsList= () =>{
    return (dispatch) => {
        otherAPI.getProductsList()
        .then(data => {
            dispatch(setProductsList(data));
        });
    }
}
export const getCompanyList = () => {
    return async (dispatch) => {
        return new Promise(async (resolve, reject) => {
            try {
                const data = await otherAPI.getCompanyList();
                dispatch(setCompanyList(data));
                resolve(data);
            } catch (error) {
                console.error("Error:", error);
                reject(error);
            }
        });
    };
};
export const getSearchResults= (request) =>{
    return (dispatch) => {
        otherAPI.getSearchResults(request)
        .then(data => {
            dispatch(setSearchResults(data));
        });
    }
}


export default otherReducer;

