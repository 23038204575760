import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getAllSuppliers } from '../../../../redux/suppliers-reducer';
import { addAccountsPayable, getAccountsPayable, deleteAccountsPayable, getCourse } from '../../../../redux/admin-reducer';
import AccountsPayable from './AccountsPayable';

class AccountsPayableContainer extends React.Component {
    componentDidMount() {
        this.props.getAllSuppliers();
        this.props.getAccountsPayable();
        this.props.getCourse();
    }

    render() {
        return (
            <AccountsPayable
                suppliers={this.props.suppliers}
                addAccountsPayable={this.props.addAccountsPayable}
                accountsPayable={this.props.accountsPayable}
                deleteAccountsPayable={this.props.deleteAccountsPayable}
                course={this.props.course}
            />
        );
    }
}

let mapStateToProps = (state) => ({
    suppliers: state.suppliers.allSuppliers,
    accountsPayable: state.admin.accountsPayable,
    course: state.admin.course,
});

export default compose(
    connect(mapStateToProps, {
        getAllSuppliers,
        addAccountsPayable,
        getAccountsPayable,
        deleteAccountsPayable,
        getCourse
    })
)(AccountsPayableContainer);
