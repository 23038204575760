import React from 'react';
import s from "./Engineer.module.css";
import {NavLink} from 'react-router-dom';
import WhoMake from '../common/MainTable/WhoMake';
import CheckNameColl from '../common/MainTable/CheckNameColl';
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {productionAPI} from "../../../api/api";
import {toast} from "react-toastify";
import DialogContentText from "@mui/material/DialogContentText";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import DialogActions from "@mui/material/DialogActions";
import {Dangerous} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import DangerousIcon from '@mui/icons-material/Dangerous';
import Cookies from "js-cookie";
import IconButton from "@mui/joy/IconButton";
import EditIcon from "@mui/icons-material/Edit";

const Engineer = (props) => {

    let packagingModuls = [];
    let handleSubmit = (event) => {
        event.preventDefault();
    }
    let workersModuls = [];
    props.workersModuls.map(module => {
        if (module.status == 1) {
            workersModuls.push(module);
        } else if (module.status == 3) {
            packagingModuls.push(module);
        }
    })
    //сортируем по дате оплаты, для выставления правильного порядка для работы монтажников
    let asd = (arr) => {
        return arr.sort((a, b) => a.main_date > b.main_date ? 1 : -1);
    }


    let getWorkerModulesCount = (workerID) => {
        props.workersModuls.filter(module => module.who_make == workerID)
        let sum = 0;
        props.rawWorkersModuls.map(module => {
            if (module.who_make == workerID && module.status === 1) {
                sum += module?.count_same_records == 0 ? module.moduls_coll : module?.count_same_records;
            }
        })
        return sum;
    };

    let getTotalModulesInProduction = () => {
        let sum = 0;
        props.rawWorkersModuls.map(module => {
            if (module.status !== 1)
                return;
            sum += module?.count_same_records == 0 ? module.moduls_coll : module?.count_same_records;
        })
        return sum;
    }

    const tryMoveDeficitItems = async (params) => {
        try {
            return await productionAPI.tryCloseDeficitItems(params);
        } catch (error) {
            toast.warning('Возникла ошибка в обработке ответа сервера. Скиньте Алексею скриншот текста ошибки.');
            toast.warning(error.message);
        }
    };
    const getComponentsCurrentStock = async (params) => {
        try {
            return await productionAPI.getComponentsCurrentStock(params);
        } catch (error) {
            toast.warning('Возникла ошибка в обработке ответа сервера. Скиньте Алексею скриншот текста ошибки.');
            toast.warning(error.message);
        }
    };

    const [openDeficitWindow, setOpenDeficitWindow] = React.useState(false);
    const [itemsStock, setItemsStock] = React.useState([]);
    const [currentModule, setCurrentModule] = React.useState(null);
    const checkIfDeficit = (moduleDeficit) => {
        if (moduleDeficit === null) return true;
        let deficitObj = JSON.parse(moduleDeficit?.deficit);
        if (deficitObj === null) return false;
        let isDeficit = false;

        Object.keys(deficitObj).map(item => {

            if ((itemsStock?.[item] - parseInt(moduleDeficit?.count_same_records) * parseInt(deficitObj?.[item])) < 0) {
                isDeficit = true;
            }
        });

        return isDeficit;
    };
    const handleClickOpenDeficitWindow = (module) => {
        setCurrentModule(module);
        getComponentsCurrentStock({items: module?.deficit, modulesInfo: module})
            .then(response => {
                //console.log(response)
                if (response?.status === 1) {
                    setItemsStock(response?.data);
                } else {
                    toast.warning('Ошибка получения данных с сервера:' + response?.message);
                    setItemsStock([]);
                }
            });
        setOpenDeficitWindow(true);
    };
    const handleCloseDeficitWindow = () => {
        setOpenDeficitWindow(false);
        setCurrentModule(null);
    };

    function getRandomInt() {
        return Math.floor(Math.random() * 666);
    }


    const isSelfcostCalculated = (count_same_records) => {
        return count_same_records > 0;
    };
    const cancelAssembly = (module) => {
        module.managerID = Cookies.get('id');
        console.log(module)
        props.cancelAssembly(module);
    };

    const [assemblerChangeWindow, setAssemblerChangeWindowOpen] = React.useState(false);

    const handleAssemblerChangeDialogClickOpen = () => {
        setAssemblerChangeWindowOpen(true);
    };

    const handleAssemblerChangeDialogClickClose = () => {
        setAssemblerChangeWindowOpen(false);
    };


    return (
        <>
            <Dialog onClose={handleCloseDeficitWindow} open={openDeficitWindow}>
                <DialogTitle>Компоненты, которые необходимо догрузить монтажнику</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Код</TableCell>
                                        <TableCell align="right">Кол-во дефицита (на 1 шт)</TableCell>
                                        <TableCell align="right">Кол-во дефицита (на заказ)</TableCell>
                                        <TableCell align="right">Текущий остаток</TableCell>
                                        <TableCell align="right">Хватает?</TableCell>
                                    </TableRow>
                                </TableHead>
                                {currentModule?.deficit &&
                                    Object.entries(JSON.parse(currentModule.deficit)).map(([key, value], index) => (
                                        Object.entries(value).map(([innerKey, innerValue]) => (
                                            <TableRow key={getRandomInt()  +`${innerKey}-${innerValue}-${index}`} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                <TableCell component="th" scope="row">
                                                    {innerKey}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {innerValue}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {currentModule.count_same_records * innerValue}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {itemsStock?.[innerKey]}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {
                                                        itemsStock?.[innerKey] - currentModule.count_same_records * innerValue >= 0
                                                            ? <AddIcon color="success"/>
                                                            : <Dangerous color="error"/>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ))
                                }

                            </Table>
                        </TableContainer>
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    {
                        checkIfDeficit(currentModule)
                            ?
                            <Button
                                color="error"
                                variant="outlined"
                            >Недостаточно компонентов для погашения дефицита
                            </Button>
                            :
                            <Button onClick={() => {

                                tryMoveDeficitItems(
                                    {
                                        items: currentModule?.deficit,
                                        modulesInfo: currentModule
                                    }
                                )
                                    .then(response => {
                                            if (response?.status === 1) {
                                                toast.success('Дефицит погашен');
                                                console.log(response?.data)
                                                handleCloseDeficitWindow();
                                            } else {
                                                toast.warning('Ошибка получения данных с сервера:' + response?.message);
                                            }
                                        }
                                    );
                                //handleCloseDeficitWindow();
                            }} autoFocus>
                                Погасить дефицит
                            </Button>
                    }
                </DialogActions>
            </Dialog>

            <div className={s.main}>
                <h3>Сотрудники:</h3>
                <NavLink exact to={"/engineer"}>
                    <button>Все ({getTotalModulesInProduction()})</button>
                </NavLink>
                {
                    props.workers.map(p => {
                        return (
                            <NavLink to={"/engineer/" + p.id} key={getRandomInt() +p.id + p.name}>
                                <button id={p.id} key={getRandomInt() + p.id}
                                        className={props.workerId == p.id ? s.activeWorker : ""}>{p.name} ({getWorkerModulesCount(p.id)})
                                </button>
                            </NavLink>
                        )
                    })
                }
                <h3>Монтаж</h3>
                <div className={s.mainTable}>
                    <div>№</div>
                    <div>Название</div>
                    <div>Количество</div>
                    <div>Ответственный</div>
                    <div>Действия</div>
                </div>
                {

                    workersModuls.map(module => {
                        return (
                            <div className={s.table}
                                 style={{
                                     backgroundColor: !isSelfcostCalculated(module.count_same_records)
                                         ? 'lightyellow'
                                         : ''
                                 }}

                                 key={getRandomInt() + module.id + module.number + module.moduls_name + module.moduls_coll + module.who_make + module?.count_same_records + module.all_bom_dateversion}>
                                {console.log(module)}
                                <CheckNameColl
                                    number={module.number}
                                    name={module.moduls_name}
                                    coll={module?.count_same_records == 0 ? module.moduls_coll : module?.count_same_records}
                                    moduls_type_in_production={module.moduls_type_in_production}
                                    newDevice={module.newDevice ?? 0}
                                />

                                <>

                                    <IconButton
                                        onClick={() => handleAssemblerChangeDialogClickOpen(module)}
                                        color="primary"
                                        component="span"
                                    >
                                        <WhoMake module={module}/><EditIcon/>
                                    </IconButton>
                                    <Dialog
                                        open={assemblerChangeWindow}
                                        onClose={handleAssemblerChangeDialogClickClose}
                                    >
                                        <DialogTitle>
                                            {"Изменение ответственного монтажника"}
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                Пожалуйста, выберите новую жертву и освободите Добби.
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            {
                                                props.workers.map(worker => {
                                                    if(worker.id == module.who_make || worker.id == 7) return null;
                                                    return (
                                                        <Button
                                                            onClick={() => {
                                                                props.changeAssembler(
                                                                    {
                                                                        'assemblyID': module.id,
                                                                        'who_make_old': module.who_make,
                                                                        'who_make_new': worker.id,
                                                                        'managerID': Cookies.get('id'),
                                                                    }
                                                                );
                                                                handleAssemblerChangeDialogClickClose();
                                                            }}
                                                        >
                                                            {worker.name}
                                                        </Button>
                                                    )
                                                })
                                            }
                                        </DialogActions>
                                    </Dialog>

                                </>
                                <div>
                                    {module?.deficit?.length > 0
                                        ?
                                        <Button onClick={() => handleClickOpenDeficitWindow(module)}>
                                            Погасить дефицит
                                        </Button>
                                        :
                                        <form onSubmit={handleSubmit}>
                                            <button
                                                onClick={() => props.setModulsStatusNew(
                                                    {
                                                        'modulsStatus': module.status,
                                                        'moduleId': module.id,
                                                        'status': module.status,
                                                        'who_make': module.who_make,
                                                        'moduls_type_in_production': module.moduls_type_in_production,
                                                        'whoAction': Cookies.get('id'),
                                                        'same_modules': module?.count_same_records,
                                                        'modules_in_invoice_coll': module.moduls_coll
                                                    }
                                                )
                                                }>
                                                Монтаж готов
                                            </button>
                                        </form>

                                    }
                                    <Button
                                        onClick={() => cancelAssembly(module)}
                                        variant='outlined'
                                        color='error'
                                    >
                                        Отмена сборки</Button>

                                </div>
                            </div>
                        )
                    })
                }
                <h3>Корпусировка</h3>
                <div className={s.mainTable}>
                    <div>№</div>
                    <div>Название</div>
                    <div>Количество</div>
                    <div>Ответственный</div>
                    <div>Статус</div>
                </div>
                {
                    asd(packagingModuls).map(module => {
                        return (
                            <div className={s.table}
                                 key={getRandomInt() + module.number + module.moduls_name + module.moduls_coll + module.who_make + module?.count_same_records}>
                                <CheckNameColl number={module.number} name={module.moduls_name}
                                               coll={module.moduls_coll}
                                               moduls_type_in_production={module.moduls_type_in_production}/>
                                <div>Колесов</div>
                                <div>
                                    <form onSubmit={handleSubmit}>
                                        <button
                                            onClick={() => props.setModulsStatus(module.status, module.id, props.workerId, module.moduls_type_in_production)}>
                                            Сборка готова
                                        </button>
                                    </form>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}
export default Engineer;